import React, { RefObject, useRef, useEffect } from 'react';
import { Dialog, Grid, makeStyles, Icons } from '@onehope/design-system-v2';
import ReactPlayer from 'react-player';

interface FullScreenVideoProps {
  onDialogClose: () => void;
  playbackId: string;
  openDialog: boolean;
}

const styles = makeStyles(() => ({
  player: ({ ratio }: { ratio: string }) => ({
    width: ratio === '9:16' ? '360px' : '640px',
    height: ratio === '9:16' ? '640px !important' : '360px',
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    overflowY: 'hidden !important',
    overflowX: 'hidden !important',
    '& .react-player__preview': {
      borderRadius: '12px 12px 0px 0px !important',
      height: '640px !important',
    },
  }),
  close: {
    position: 'absolute',
    margin: '10px',
    cursor: 'pointer',
    zIndex: 10,
  },
}));

const FullScreenVideo = (props: FullScreenVideoProps) => {
  const { playbackId, onDialogClose, openDialog } = props;

  const classes = styles({ ratio: '9:16' });

  const buttonRef = useRef() as RefObject<HTMLButtonElement>;
  useEffect(() => {
    setTimeout(() => buttonRef.current?.click(), 100);
  }, []); // Runs only once because of empty dependency array

  return (
    <Dialog
      open={openDialog}
      onClose={onDialogClose}
      fullScreen
      onTouchMove={onDialogClose}
    >
      <Icons.Close className={classes.close} onClick={onDialogClose} />
      {playbackId && (
        <Grid>
          <ReactPlayer
            className={classes.player}
            url={`https://stream.mux.com/${playbackId}.m3u8`}
            width="100%"
            height="100%"
            light={`https://image.mux.com/${playbackId}/thumbnail.jpg?fit_mode=smartcrop&width=600&height=200`}
            controls={true}
            playing={true}
            muted={false}
            onEnded={onDialogClose}
            playIcon={
              <button ref={buttonRef}>
                <Icons.CardPlayIcon />
              </button>
            }
          />
        </Grid>
      )}
    </Dialog>
  );
};

export default FullScreenVideo;
