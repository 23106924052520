import * as React from 'react';

import { styles as s } from '@onehope/design-system-ohw';
import {
  useTheme,
  useMediaQuery,
  TablePagination,
} from '@onehope/design-system-v2';

const { cssConstants } = s;

interface PaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Pagination(props: PaginationProps) {
  const {
    count,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <TablePagination
      style={{ marginTop: 16, fontFamily: theme.bodyFontFamily }}
      rowsPerPageOptions={isMobile ? [] : [10, 25, 100]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
