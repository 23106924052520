import React from 'react';

import {
  useTheme,
  useMediaQuery,
  Dialog as DialogRoot,
} from '@onehope/design-system-v2';

import { useContactsContext } from '../../ContactsContext';
import AccountInfoForm from './AccountInfoForm';

export default function AddContactDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { accountInfoOpen, toggleAccountInfoState } = useContactsContext();

  return (
    <div>
      <DialogRoot
        fullScreen={fullScreen}
        disableEnforceFocus
        open={accountInfoOpen}
        onClose={() => toggleAccountInfoState(false)}
      >
        <AccountInfoForm onClose={() => toggleAccountInfoState(false)} />
      </DialogRoot>
    </div>
  );
}
