import React from 'react';

export const SvgInvite = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#FFF"
          d="M14.683 51.041L48.148 51.041 48.148 14.995 14.683 14.995z"
          transform="translate(9 8)"
        />
        <g stroke="#000" strokeLinecap="round" strokeLinejoin="round">
          <path
            d="M0 8.825L6.529 15.357M17.958 9.051L14.34 0M46.979 0L43.361 9.05M60 8.825L53.472 15.357"
            transform="translate(9 8) translate(1.173 .57)"
          />
        </g>
        <path
          fill="#E5F4FF"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31.832 8.395L0.777 27.082 0.616 63.315 61.889 63.315 61.889 27.105z"
          transform="translate(9 8)"
        />
        <path
          fill="#FFF"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.683 53.395L48.148 53.395 48.148 14.995 14.683 14.995z"
          transform="translate(9 8)"
        />
        <path
          fill="#0091FF"
          fillOpacity=".7"
          stroke="#000"
          d="M41.067 33.472v2.64H19.96v-2.64h21.107zM20.162 20.58l17.88.08v10.172H19.959l.203-10.252zM19.96 41.39L41.067 41.39 41.067 38.751 19.96 38.751z"
          transform="translate(9 8)"
        />
        <path
          fill="#E5F4FF"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.73 40.856L19.524 52.579.73 63.292zM61.889 40.231L42.73 52.045 61.889 63.315z"
          transform="translate(9 8)"
        />
        <path
          fill="#E5F4FF"
          d="M0.73 63.292L31.253 45.739 61.889 63.316z"
          transform="translate(9 8)"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M0.73 63.292L31.253 45.739 61.889 63.316z"
          transform="translate(9 8)"
        />
      </g>
    </g>
  </svg>
);
