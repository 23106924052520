import React from 'react';
import { Grid, Image, Text, TEXT_DS } from '@onehope/design-system-v2';
import { useStyles, ProductText } from '../WinePackItems';
import { urlProductImage } from '../../../utils/constants';
import { WineClub_AutoshipNextShipmentAddons } from '../../../queries/generatedTypes/WineClub_AutoshipNextShipmentAddons';

/**
 * Wine Club has support for add-ons that aren't part of the WinePack. They are ad-hoc and can be handled separately.
 */
export default function AddOns(props: {
  nextShipmentAddons: WineClub_AutoshipNextShipmentAddons;
}) {
  const classes = useStyles();

  const addonProducts =
    props.nextShipmentAddons.viewer?.user?.autoShip?.nextShipmentAddons;
  if (!addonProducts) {
    return null;
  }

  return (
    <Grid container spacing={0} className={classes.container}>
      {addonProducts.map((item, index) => {
        const { productName, productId, finalPrice, quantity, images } = item;

        return (
          <Grid
            wrap="nowrap"
            key={`${productId}${index}`}
            container
            direction="row"
            className={classes.productContainer}
          >
            <Grid>
              <Image
                alt="addons-image"
                src={`${urlProductImage}${
                  images?.squareFront ?? images?.squareBack
                }`}
                className={classes.productImage}
              />
            </Grid>
            <Grid container direction="column">
              <ProductText container item>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                  {productName}
                  {!productName.match('Add On') && <> Add On</>}
                </Text>
              </ProductText>
              <ProductText container item>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                  {(!finalPrice || finalPrice === 0) && <>FREE</>}
                  {!!finalPrice && <>${finalPrice.toFixed(2)}</>}
                </Text>
              </ProductText>
              <Grid container item>
                <Text variant="custom" default={TEXT_DS.OVERLINE_REGULAR_11}>
                  QTY: {quantity}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
