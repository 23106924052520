import gql from 'graphql-tag';
import { EventFragment } from '../../queries/Event';
export default gql`
  mutation PreApprovedDonationAddMutation(
    $input: PreApprovedDonationAddInput!
  ) {
    preApprovedDonationAdd(input: $input) {
      event {
        ...Event_event
      }
    }
  }
  ${EventFragment}
`;
