import React from 'react';

function NoEventsSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g fill="none" stroke="#1A1A1A">
        <path
          fill="#FFF"
          d="M24.684 13.775v-4.52c0-.969.78-1.755 1.745-1.755h6.224a1.75 1.75 0 011.745 1.755v4.52h8.96v-4.52c0-.969.78-1.755 1.744-1.755h6.225a1.75 1.75 0 011.744 1.755v4.52h15.684a1.75 1.75 0 011.745 1.754v46.483c0 5.783-4.668 10.488-10.41 10.488H18.91c-5.742 0-10.41-4.705-10.41-10.488V15.53c0-.968.78-1.754 1.745-1.754h14.439z"
        />
        <path fill="#DDD" d="M43.357 31.343h4.735v4.765h-4.735z" />
        <path fill="#0091EA" d="M43.357 43.892h4.735v4.765h-4.735z" />
        <path
          fill="#DDD"
          d="M30.908 31.343h4.735v4.765h-4.735zm24.898 25.098h4.735v4.765h-4.735zm-12.449 0h4.735v4.765h-4.735zm12.449-12.549h4.735v4.765h-4.735zm-24.898 0h4.735v4.765h-4.735zm24.898-12.549h4.735v4.765h-4.735zm-37.347 0h4.735v4.765h-4.735zm0 25.098h4.735v4.765h-4.735zm12.449 0h4.735v4.765h-4.735zM18.459 43.892h4.735v4.765h-4.735z"
        />
        <path
          fill="#0091EA"
          fillOpacity="0.25"
          d="M68.01 16.284v7.275H10.99v-7.275h57.02z"
        />
        <path d="M46.347 10.51h3.735v3.765h-3.735zm-18.674 0h3.735v3.765h-3.735z" />
      </g>
    </svg>
  );
}

export default NoEventsSVG;
