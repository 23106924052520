import React, { Fragment, Dispatch, SetStateAction } from 'react';
import EventsTable from './EventsTable';
import {
  CEDash_EventsV2Query_viewer_user_eventsV2 as Events,
  CEDash_EventsV2Query_viewer_user as UserType,
} from '../../queries/generatedTypes/CEDash_EventsV2Query';

interface EventsPageWrapperProps {
  events: Events;
  pageNumber: number;
  filterBy: string | null;
  setPageNumber: Dispatch<SetStateAction<string | string[]>>;
  handleSortEvents: (sortBy: string) => void;
  setFilterBy: any;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  count: number;
  setSearchBy: any;
  user: UserType;
  sortBy: string | null;
}

function EventsPageWrapper(props: EventsPageWrapperProps) {
  const {
    pageNumber,
    filterBy,
    setPageNumber,
    handleSortEvents,
    setFilterBy,
    rowsPerPage,
    setRowsPerPage,
    count,
    setSearchBy,
    events,
    user,
    sortBy,
  } = props;
  const eventsEdges = events?.edges;

  return (
    <Fragment>
      <EventsTable
        events={eventsEdges}
        page={Number(pageNumber)}
        filterBy={filterBy ? filterBy : 'all'}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setFilterBy={setFilterBy}
        setRowsPerPage={setRowsPerPage}
        handleSortEvents={handleSortEvents}
        count={count}
        sortBy={sortBy}
        setSearchBy={setSearchBy}
        user={user}
      />
    </Fragment>
  );
}

export default EventsPageWrapper;
