import React from 'react';

export const Close = (props: any) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <path
        id="close__a"
        d="M21.88 19.999l5.728-5.715c.523-.523.523-1.37 0-1.892-.522-.523-1.37-.523-1.892 0l-5.715 5.728-5.715-5.728c-.523-.523-1.37-.523-1.892 0-.522.522-.522 1.37 0 1.892l5.729 5.715-5.729 5.715c-.252.25-.394.59-.394.946 0 .355.142.696.394.946.25.252.59.394.946.394.355 0 .696-.142.946-.394L20 21.877l5.715 5.729c.25.252.591.394.946.394.356 0 .696-.142.946-.394.252-.25.394-.59.394-.946 0-.355-.142-.696-.394-.946L21.88 20z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="close__b" fill="#fff">
        <use href="#close__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" href="#close__a" />
      <g fill="#1A1A1A" mask="url(#close__b)">
        <path d="M0 0L40 0 40 40 0 40z" />
      </g>
    </g>
  </svg>
);
