import React from 'react';
import { isEmpty } from 'lodash';
import {
  styled,
  Grid,
  Icons,
  Text,
  TEXT_DS,
  Input,
  Button,
  theme,
  makeStyles,
} from '@onehope/design-system-v2';
import { PhoneMask } from '../../Contacts/AddContactDialog/PhoneMask';
import DateMask from '../../../utils/DateMask';
import { FormikProps } from 'formik';
import { MyFormValues } from './MyFormTypes';
import CustomerCard from '../../CustomerCard';
const { Close } = Icons;
import { ContactType } from '../../ContactsV3/ContactsContext';

interface AddCustomerProps {
  formTitle: string;
  buttonText: string;
  values: MyFormValues;
  onClose: () => void;
  existingContact: ContactType;
  addCustomerButtonText: string;
  errorNotification: string | null;
  mutationLoading: boolean;
  callback: (value: ContactType) => void | null;
}

const Form = styled('div')`
  margin: 0 auto;
  width: 100%;
  background-color: ${theme.palette.white.main};
  padding: 32px 16px;
  @media all and (min-width: 968px) {
    padding: 48px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    width: 600px;
  }
`;

const InputContainer = styled('div')`
  margin: 0 auto;
  width: 100%;
  @media all and (min-width: 968px) {
    max-width: 100%;
    width: 600px;
  }
`;

const CloseDialog = styled('div')`
  width: 30px;
  height: 30px;
  position: absolute;
  padding-top: 11.4px;
  right: 0;
  cursor: pointer;
  @media all and (min-width: 968px) {
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ErrorNotification = styled('div')`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #ff4d4f;
  background-color: #fff5f5;
  padding: 14px 22px;
  margin-bottom: 40px;
`;

const useStyles = makeStyles(() => ({
  formTitle: {
    paddingBottom: '24px',
  },
  formItem: {
    paddingBottom: '24px',
  },
  informationalText: {
    paddingBottom: '24px',
  },
}));

export default function AddCustomer({
  values,
  errors,
  touched,
  onClose,
  formTitle,
  buttonText,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldTouched,
  mutationLoading,
  errorNotification,
  existingContact,
  callback,
}: FormikProps<MyFormValues> & AddCustomerProps) {
  const { firstName, lastName, email, phone, dateOfBirth } = values;

  const classes = useStyles();

  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const customerCardClick = () => {
    return callback(existingContact);
  };

  const disableSubmit = mutationLoading || !isEmpty(errors) || isEmpty(touched);

  return (
    <form onSubmit={handleSubmit}>
      <CloseDialog onClick={onClose}>
        <Close />
      </CloseDialog>
      <Form>
        <Text
          variant="custom"
          default={TEXT_DS.TITLE_LIGHT_32}
          className={classes.formTitle}
        >
          {formTitle}
        </Text>
        <InputContainer>
          <div className={classes.formItem}>
            <Input
              label={'First name'}
              placeholder="Jane"
              id="firstName"
              name="firstName"
              value={firstName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.firstName && Boolean(errors.firstName)
              }
              hasSuccess={
                !isSubmitting && touched.firstName && !Boolean(errors.firstName)
              }
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              onChange={(e: React.SyntheticEvent) => change('firstName', e)}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label={'Last name'}
              placeholder="Austen"
              id="lastName"
              name="lastName"
              value={lastName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.lastName && Boolean(errors.lastName)
              }
              hasSuccess={
                !isSubmitting && touched.lastName && !Boolean(errors.lastName)
              }
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              onChange={(e: React.SyntheticEvent) => change('lastName', e)}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label="Email address"
              placeholder="jane@gmail.com"
              id="email"
              name="email"
              value={email}
              fullWidth
              validate
              hasError={!isSubmitting && touched.email && Boolean(errors.email)}
              hasSuccess={
                !isSubmitting && touched.email && !Boolean(errors.email)
              }
              error={touched.email && Boolean(errors.email)}
              helperText={errors.email && touched.email && errors.email}
              onChange={(e: React.SyntheticEvent) => change('email', e)}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label="Birthday"
              placeholder="MM / DD / YYYY"
              defaultChecked={false}
              id="dateOfBirth"
              name="dateOfBirth"
              fullWidth
              onChange={(e: React.SyntheticEvent) => change('dateOfBirth', e)}
              value={dateOfBirth}
              error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
              helperText={
                errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
              }
              hasSuccess={
                !isSubmitting &&
                touched.dateOfBirth &&
                !Boolean(errors.dateOfBirth)
              }
              hasError={
                !isSubmitting &&
                touched.dateOfBirth &&
                Boolean(errors.dateOfBirth)
              }
              mask={DateMask}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label="Phone Number"
              placeholder="(555) 123-4567"
              id="phone"
              name="phone"
              value={phone}
              fullWidth
              validate
              hasError={!isSubmitting && touched.phone && Boolean(errors.phone)}
              hasSuccess={
                !isSubmitting && touched.phone && !Boolean(errors.phone)
              }
              mask={PhoneMask}
              error={touched.phone && Boolean(errors.phone)}
              helperText={errors.phone && touched.phone && errors.phone}
              onChange={(e: React.SyntheticEvent) => change('phone', e)}
            />
          </div>
          {errorNotification && (
            <ErrorNotification>{errorNotification}</ErrorNotification>
          )}
          {existingContact && (
            <div style={{ paddingBottom: '24px' }}>
              <Text
                variant="custom"
                default={TEXT_DS.BODY_REGULAR_16}
                className={classes.informationalText}
              >
                A contact with this email and/or phone number already exisits.
                Did you mean to select this contact?
              </Text>
              <CustomerCard
                title={'Contact details'}
                name={`${existingContact.firstName} ${existingContact.lastName}`}
                email={existingContact.emailAddress}
                dateOfBirth={existingContact.dateOfBirth}
                phone={existingContact.phoneNumber}
                cardClick={customerCardClick}
              />
            </div>
          )}
          <ButtonContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  type="secondary"
                  disableRipple
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  type="primary"
                  disableRipple
                  fullWidth
                  disabled={disableSubmit}
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </ButtonContainer>
        </InputContainer>
      </Form>
    </form>
  );
}
