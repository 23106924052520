import React from 'react';

const SvgAlert = (props: any) => (
  <svg width="80" height="80" viewBox="0 0 80 80" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#1A1A1A">
      <path strokeLinecap="round" d="M22 69h36" />
      <path
        fill="#FFB600"
        fillOpacity=".2"
        fillRule="nonzero"
        d="M72.89 64.343c-.233.411-.913.657-1.821.657H8.929c-.906 0-1.587-.246-1.82-.657-.233-.414-.088-1.125.388-1.901l30.83-50.177C38.821 11.46 39.43 11 40 11c.57 0 1.179.461 1.673 1.265l30.83 50.177c.476.776.621 1.486.387 1.9z"
      />
      <path fill="#FFF" fillRule="nonzero" d="M38.5 22.5h3.73v26.872H38.5z" />
      <ellipse
        cx="40.365"
        cy="58.362"
        fill="#FFF"
        fillRule="nonzero"
        rx="1.865"
        ry="1.862"
      />
    </g>
  </svg>
);
export default SvgAlert;
