import React, { Dispatch, SetStateAction } from 'react';
import { findSelectedAddress } from './AddressesSelectView';
import AddressDialog from '../AddressDialog';
import { Account_AddressesGetQuery_viewer_user_addressBook_entries_edges_node_address as AddressSummaryType } from '../../../queries/generatedTypes/Account_AddressesGetQuery';

export interface AddressType {
  node: {
    id: string;
    address: AddressSummaryType;
  };
}

interface AddressEditViewProps {
  addresses: Array<AddressType>;
  editAddressId: string | null;
  setView: (input: string) => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  setSelectedAddress: Dispatch<SetStateAction<AddressSummaryType | null>>;
  contactId?: string;
}

const AddressEditView = (props: AddressEditViewProps) => {
  const { setView, addresses, setSelectedAddress, editAddressId } = props;
  const address = findSelectedAddress(editAddressId, addresses)?.node?.address;

  const handleClose = () => {
    setView('SELECT');
  };
  return (
    <>
      <AddressDialog
        open={true}
        showDialog={false}
        address={address}
        onClose={handleClose}
        setSelectedAddress={setSelectedAddress}
        isEditing
        key="Edit Address"
        headerText="Edit Address"
        hasDefaultShippingAddressId={true}
        contactId={props.contactId}
      />
    </>
  );
};

export default AddressEditView;
