import React, { useCallback } from 'react';
// material-ui
import {
  GridV2 as Grid,
  Box,
  styled,
  Text,
  Button,
  useTheme,
  LinearProgress,
  TEXT_DS,
} from '@onehope/design-system-v2';
import MainCard from '../MainCard';

// types
import { CEDash_DashboardQuery_viewer_user } from '../../../queries/generatedTypes/CEDash_DashboardQuery';

interface FastStartWidgetProps {
  user: CEDash_DashboardQuery_viewer_user;
}

export function getProgressPercentage(user: CEDash_DashboardQuery_viewer_user) {
  const trinityEnrollDate = user?.trinityEnrollDate;
  const fastStartEndDate = user?.fastStartEndDate;
  if (!trinityEnrollDate || !fastStartEndDate) {
    return {
      percentage: -1,
      daysPassed: -1,
      daysLeft: -1,
    };
  }
  const currentDate = new Date();
  const startDate = new Date(trinityEnrollDate);
  const endDate = new Date(fastStartEndDate);
  const totalDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const totalDays = Math.ceil(totalDiff / (1000 * 60 * 60 * 24));
  const currentDiff = Math.abs(currentDate.getTime() - startDate.getTime());
  const currentDays = Math.ceil(currentDiff / (1000 * 60 * 60 * 24));
  if (endDate < startDate || endDate < currentDate) {
    return {
      percentage: -1,
      daysPassed: -1,
      daysLeft: -1,
    };
  }
  return {
    percentage: (currentDays / totalDays) * 100,
    daysPassed: currentDays,
    daysLeft: totalDays - currentDays,
  };
}

const LinearBarText = styled(Text)(() => ({
  paddingTop: 8,
}));

const FastStartButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  backgroundColor: theme.palette.forestGreen.main,
  '&:hover': {
    opacity: 0.9,
    backgroundColor: theme.palette.forestGreen.main,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 314,
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 16,
  height: 24,
  borderRadius: 12,
}));
const FastStartWidget = ({ user }: FastStartWidgetProps) => {
  const theme = useTheme();
  const fastStartResourceClicked = useCallback(() => {
    window.open(
      'https://training.onehopewine.com/training/a7107ed2-3dc2-11eb-b208-067360dfb065/overview',
      '_blank',
    );
  }, []);

  const { percentage, daysPassed, daysLeft } = getProgressPercentage(user);
  if (percentage >= 0) {
    return (
      <Grid item xs={12}>
        <MainCard>
          <Grid container>
            <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
              Fast Start Tracker
            </Text>
            <Grid item xs={12}>
              <BorderLinearProgress
                variant="determinate"
                value={percentage}
                progressColor={theme.palette.goldStar.main}
                backgroundBarColor={theme.palette.gold.light}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs>
                  <LinearBarText
                    variant="custom"
                    default={TEXT_DS.BODY_SEMIBOLD_16}
                  >
                    {daysPassed} / {daysLeft + daysPassed} days passed
                  </LinearBarText>
                </Grid>
                <Grid item>
                  <LinearBarText
                    variant="custom"
                    default={TEXT_DS.BODY_SEMIBOLD_16}
                  >
                    {daysLeft} days left
                  </LinearBarText>
                </Grid>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <FastStartButton
                fullWidth
                theme={theme}
                onClick={fastStartResourceClicked}
              >
                Fast Start Resources
              </FastStartButton>
            </Box>
          </Grid>
        </MainCard>
      </Grid>
    );
  }
  return null;
};

export default FastStartWidget;
