import {
  theme,
  styled,
  Text,
  TEXT_DS,
  Button,
} from '@onehope/design-system-v2';

export const CustomerCardContainer = styled('div')<{ onClick }>`
  max-height: 200px;
  border-radius: 4px;
  border: solid 1px ${theme.palette.primary.main};
  background-color: ${theme.palette.white.main};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const CardInfo = styled('div')`
  position: relative;
  padding: 16px;
  padding-right: 60px;
`;

export const formatPhone = (value: string) => {
  if (!value) return null;
  let i = 0;
  const number = value.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, _ => number[i++]);
};

interface CustomerCardProps {
  title: string;
  name?: string;
  email?: string;
  dateOfBirth?: string;
  phone?: string;
  button?: string;
  buttonClick?: () => void;
  cardClick?: () => void;
}

function CustomerCard(props: CustomerCardProps) {
  const {
    title,
    name,
    email,
    dateOfBirth,
    phone,
    button,
    buttonClick,
    cardClick,
  } = props;
  return (
    <div>
      <CustomerCardContainer onClick={cardClick}>
        <CardInfo>
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
            {title}
          </Text>
          {name && (
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              {name}
            </Text>
          )}
          {email && (
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              {email}
            </Text>
          )}
          {dateOfBirth && (
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              {dateOfBirth}
            </Text>
          )}
          {phone && phone !== 'null' && (
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              {formatPhone(phone)}
            </Text>
          )}
          {button && buttonClick && (
            <Button onClick={buttonClick}>{button}</Button>
          )}
        </CardInfo>
      </CustomerCardContainer>
    </div>
  );
}

export default CustomerCard;
