import gql from 'graphql-tag';

const GET_PRE_APPROVED_NON_PROFITS_QUERY = gql`
  query CEDash_NonProfitsQuery {
    viewer {
      id
      user {
        id
        nonProfits(first: 1000, status: "preApproved") {
          edges {
            node {
              id
              nonProfitId
              nonProfitName
              nonProfitDescription
              isPreApproved
            }
          }
        }
      }
    }
  }
`;

export default GET_PRE_APPROVED_NON_PROFITS_QUERY;
