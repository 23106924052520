import * as React from 'react';
import styled from '@emotion/styled';
import { Icons, Text, Button } from '@onehope/design-system-v2';

const NoOrdersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  @media all and (min-width: 768px) {
    padding: 40px 16px;
  }
`;

const NoOrdersText = styled(Text)`
  padding: 16px 0;
`;

const MuiButton = styled(Button)`
  max-width: 288px;
  width: 100%;
  && {
    background: #3c5d62;
  }
`;

export default function NoOrders({
  openDialog,
  openForOrders,
  contactName,
  noOrdersText,
  handleOrderForCustomer,
}: {
  openDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  openForOrders: boolean;
  contactName: string | null | undefined;
  handleOrderForCustomer?: () => void;
  noOrdersText?: string;
}) {
  const openOrderDialog = () => {
    if (openDialog) {
      openDialog(true);
    }
    if (handleOrderForCustomer) {
      handleOrderForCustomer();
    }
  };
  const text = noOrdersText
    ? noOrdersText
    : contactName
    ? `There are no orders yet for ${contactName}.`
    : 'There are no orders yet for this event.';

  return (
    <NoOrdersContainer>
      <NoOrdersText>{text}</NoOrdersText>
      {openForOrders && (
        <MuiButton
          type="primary"
          fullWidth
          disableRipple
          onClick={openOrderDialog}
        >
          +Start New Order
        </MuiButton>
      )}
    </NoOrdersContainer>
  );
}
