import ActivityEntry from '../../types/ActivityEntry';
import extractDataFields from './extractDataFields';
import { FieldType } from './fieldType';
import GQLNode from './GQLNode';

interface ValidationResult {
  invalidResourceTypes: string[];
  invalidFields: {
    [resourceType: string]: string[];
  };
  incompleteFields: {
    [resourceType: string]: string[];
  };
  knownErrors: {
    [resourceType: string]: string[];
  };
}

/**
 * Given a Card template and the result of a GraphQL introspection query on the underlying data source, return a
 * structured list of invalid data references.
 */
export default function validateDataTags(
  activity: ActivityEntry,
  gqlFields: FieldType[],
): ValidationResult {
  const validationResult: ValidationResult = {
    invalidResourceTypes: [],
    invalidFields: {},
    incompleteFields: {},
    knownErrors: {},
  };

  const tokenizedFields = extractDataFields(activity);

  Object.entries(tokenizedFields).forEach(([resourceName, gqlNode]) => {
    const trueResourceFields = gqlFields.find(
      (field) => field.name === resourceName,
    );
    if (typeof trueResourceFields === 'undefined') {
      validationResult.invalidResourceTypes.push(resourceName);
      return;
    }

    const {
      invalidFields,
      incompleteFields,
      knownErrors,
    } = getInvalidFieldNames(trueResourceFields, gqlNode);
    if (invalidFields.length > 0) {
      validationResult.invalidFields[resourceName] = invalidFields;
    }
    if (incompleteFields.length > 0) {
      validationResult.incompleteFields[resourceName] = incompleteFields;
    }
    if (knownErrors.length > 0) {
      validationResult.knownErrors[resourceName] = knownErrors;
    }
  });

  return validationResult;
}

export const getInvalidFieldNames = (
  trueResourceField: FieldType,
  gqlNode: GQLNode,
) => {
  const invalidFields: string[] = [];
  const incompleteFields: string[] = [];
  const knownErrors: string[] = [];

  if (!trueResourceField?.type) {
    knownErrors.push(
      `..Type not found. Perhaps ActionEngineFieldsQuery needs to be updated`,
    );
    return { invalidFields, incompleteFields, knownErrors };
  }

  if (!trueResourceField?.type?.fields) {
    throw new Error('Fields missing in GQL payload!');
  }

  const { fields } = trueResourceField.type;
  const childNodes = gqlNode.getChildNodes();
  gqlNode.getFieldNames().forEach((fieldName) => {
    const foundResource = fields.find((field) => field.name === fieldName);
    if (!foundResource) {
      // field isn't found in gql schema
      invalidFields.push(fieldName);
    } else {
      // Field exists but does/should it have child nodes?
      const childNode = childNodes[fieldName];

      // Need to make sure we get all the way to a leaf node
      if (foundResource?.type?.fields && !childNode) {
        incompleteFields.push(fieldName);
      }

      // Need to repeat for all the child nodes
      if (childNode) {
        const {
          invalidFields: missingChildFieldNames,
          incompleteFields: incompleteChildFieldNames,
          knownErrors: knownErrorsChildFieldNames,
        } = getInvalidFieldNames(foundResource, childNode);

        // prepend the full string name so we can differentiate between things like
        // user.address.firstName
        // user.firstName
        missingChildFieldNames.forEach((childField) =>
          invalidFields.push(`${fieldName}.${childField}`),
        );
        incompleteChildFieldNames.forEach((childField) => {
          incompleteFields.push(`${fieldName}.${childField}`);
        });
        knownErrorsChildFieldNames.forEach((childField) => {
          knownErrors.push(`${fieldName}.${childField}`);
        });
      }
    }
  });
  return { invalidFields, incompleteFields, knownErrors };
};
