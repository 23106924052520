import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import _sortBy from 'lodash/sortBy';
import {
  Dialog,
  Grid,
  makeStyles,
  ThemeType,
  useTheme,
  useMediaQuery,
  ButtonV2,
} from '@onehope/design-system-v2';

import PaymentsSelectView, { findSelectedPayment } from './PaymentsSelectView';
import PaymentEditView from './PaymentEditView';
import PaymentAddView from './PaymentAddView';
import useToggle from '../../../utils/useToggleHook';

import PAYMENTS_GET_QUERY from '../../../queries/PaymentsGetQuery';

import { Account_GetAutoShip as SubscriptionType } from '../../../queries/fragments/generatedTypes/Account_GetAutoShip';
import { Account_PaymentsGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment as PaymentStripeType } from '../../../queries/generatedTypes/Account_PaymentsGetQuery';

import { CloseIcon } from '../../_shared/formStyles';
import CancelMembership from '../../CancelMembership';
import { useCommonStyles } from '../../_shared/styles';

export interface PaymentType {
  node: {
    id: string;
    stripePayment: PaymentStripeType;
  };
}

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: { width: '600px' },
  },
}));

function getSortedPayments(
  payments: Array<PaymentType>,
  paymentId: string | null,
) {
  return _sortBy(payments, (payment: any) => {
    return payment?.node?.stripePayment?.paymentId !== paymentId;
  });
}

const PaymentsWalletWrapper = ({
  currentPaymentId,
  subscription,
  isEditingCeSubscription,
  contactId = '',
  sendToTrackEventV2,
}: {
  currentPaymentId: string | null;
  subscription: SubscriptionType;
  isEditingCeSubscription?: boolean;
  contactId?: string;
  sendToTrackEventV2?: any;
}) => {
  const { data, loading } = useQuery(PAYMENTS_GET_QUERY, {
    variables: { contactId },
  });
  const payments = contactId
    ? data?.viewer?.userInfo?.stripePaymentCollection
    : data?.viewer?.user?.stripePaymentCollection;
  const defaultPaymentId = contactId
    ? data?.viewer?.userInfo?.paymentIdDefault
    : data?.viewer?.user?.paymentIdDefault;

  const [editPaymentId, setEditPaymentId] = useState(currentPaymentId);
  const [selectedPayment, setSelectedPayment] =
    useState<PaymentStripeType | null>(null);
  const classes = useCommonStyles();
  const dialogClasses = useDialogStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [view, setView] = useState('SELECT');
  const { value: open, setTrue: setOpen, setFalse: setClose } = useToggle();
  const {
    value: isLoading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useToggle();
  const handleClose = () => {
    if (!isLoading) {
      setClose();
      setSelectedPayment(null);
      setView('SELECT');
    }
  };

  return (
    <>
      <Grid container>
        <ButtonV2 type="textLink" className={classes.button} onClick={setOpen}>
          Edit
        </ButtonV2>
        {isEditingCeSubscription && (
          <CancelMembership
            subscriptionStatus={subscription?.subscriptionStatus}
            isCeSubscription={true}
            actionText={'Cancel membership'}
            actionType={'link'}
          />
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={dialogClasses}
        fullScreen={isMobile}
      >
        {!loading && (
          <Grid container>
            <CloseIcon onClick={handleClose} />
            {view === 'SELECT' && (
              <PaymentsSelectView
                isEditingCeSubscription={isEditingCeSubscription}
                setLoadingTrue={setLoadingTrue}
                setLoadingFalse={setLoadingFalse}
                setView={setView}
                selectedPayment={
                  selectedPayment ||
                  findSelectedPayment(
                    currentPaymentId,
                    payments?.entries?.edges,
                  )?.node?.stripePayment
                }
                setSelectedPayment={setSelectedPayment}
                setEditPaymentId={setEditPaymentId}
                closeDialog={handleClose}
                subscription={subscription}
                currentPaymentId={currentPaymentId}
                payments={getSortedPayments(
                  payments?.entries?.edges,
                  currentPaymentId,
                )}
                sendToTrackEventV2={sendToTrackEventV2}
              />
            )}
            {view === 'EDIT' && (
              <PaymentEditView
                payments={payments?.entries?.edges}
                setView={setView}
                defaultPaymentId={defaultPaymentId}
                setSelectedPayment={setSelectedPayment}
                editPaymentId={editPaymentId}
                setLoadingTrue={setLoadingTrue}
                setLoadingFalse={setLoadingFalse}
                contactId={contactId}
              />
            )}
            {view === 'ADD' && (
              <PaymentAddView
                setView={setView}
                defaultPaymentId={defaultPaymentId}
                setSelectedPayment={setSelectedPayment}
                setLoadingTrue={setLoadingTrue}
                setLoadingFalse={setLoadingFalse}
                contactId={contactId}
              />
            )}
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default PaymentsWalletWrapper;
