import * as React from 'react';
import CauseOfChoiceNetworkLayer, {
  DataProps,
} from './../stateManagement/networkLayer';
import CauseOfChoiceProvider from './../stateManagement/context';

import Modal from './Modal';

type CauseOfChoiceProps = DataProps;

export default function CauseOfChoice({
  open,
  app,
  user,
  buttons,
  assetUrl,
  modalView,
  onContinue,
  modalLocation,
  urlProfileImg,
  initialScreen,
  handleGoToShop,
  handleCloseModal,
  selectedNonProfit,
  urlEventImgUploads,
  handleViewFundraiser,
  handleNonProfitSelected,
  trackUpdateCauseOfChoice,
}: CauseOfChoiceProps) {
  return (
    <CauseOfChoiceNetworkLayer
      open={open}
      user={user}
      app={app}
      buttons={buttons}
      assetUrl={assetUrl}
      modalView={modalView}
      onContinue={onContinue}
      modalLocation={modalLocation}
      urlProfileImg={urlProfileImg}
      initialScreen={initialScreen}
      handleGoToShop={handleGoToShop}
      handleCloseModal={handleCloseModal}
      selectedNonProfit={selectedNonProfit}
      urlEventImgUploads={urlEventImgUploads}
      handleViewFundraiser={handleViewFundraiser}
      handleNonProfitSelected={handleNonProfitSelected}
      trackUpdateCauseOfChoice={trackUpdateCauseOfChoice}
    >
      <CauseOfChoiceProvider initialScreen={initialScreen}>
        <Modal />
      </CauseOfChoiceProvider>
    </CauseOfChoiceNetworkLayer>
  );
}
