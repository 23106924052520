// material-ui
import React from 'react';
import Navigation from './Navigation';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  return <Navigation />;
};

export default DrawerContent;
