import queryString, { ParsedQuery } from 'query-string';
import { SegmentDefaultInput } from './segment';

export const getWindowParams = () => {
  let pathname;
  let search;

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname;
    search = window.location.search;
  }
  return { pathname, search };
};

export const isSegmentEnabled = (
  segmentEnabled: string | boolean | undefined,
) => {
  if (typeof segmentEnabled === 'boolean') {
    return segmentEnabled;
  }
  if (typeof segmentEnabled === 'string') {
    return segmentEnabled === 'true';
  }
  return false;
};

/**
 * Helper function to determine if the current page is either the Host Kit Builder
 * (1) Select Wines (Mixed Pack) or (2) Build Your Own
 */
export const isHostKitBuilderMainPage = () => {
  const query: ParsedQuery<string> = queryString.parse(window.location.search);

  if (query.hostKitOrderLinkId) {
    const queryList = Object.keys(query);
    // if hostKitOrderLinkId is the only query parameter, then current page is Select Wines
    const isMixedPackPage = queryList.length === 1;
    // otherwise, check explicitly for BYO page
    const isBuildYourOwnPage = query.page === 'BUILD_YOUR_OWN';

    return isMixedPackPage || isBuildYourOwnPage;
  }

  return false;
};

const isFunnel = () => {
  switch (window.location.pathname) {
    case '/wine-club-builder':
      return true;
  }
  return false;
};

const excludePageName = ({ isCeDashboard }: SegmentDefaultInput) => {
  if (isCeDashboard || isFunnel()) {
    return true;
  }
  return false;
};

export const getPageName = ({ isCeDashboard }: SegmentDefaultInput) => {
  if (excludePageName({ isCeDashboard })) {
    return undefined;
  }

  if (!document.title) {
    return undefined;
  }

  return document.title;
};
