import * as yup from 'yup';
import { statesAbbrev } from '../../../utils/statesAbbrev';
const validationSchema = yup.object({
  addressLineOne: yup
    .string()
    .min(4, 'Address line one should be min 4 characters')
    .required('Address line one required'),
  city: yup
    .string()
    .min(2, 'City should be min 2 characters')
    .required('City is required'),
  zip: yup
    .string()
    .matches(/^(?!0{3})[0-9]{3,5}$/, 'Enter valid zip code')
    .required('Zip code required'),
  state: yup
    .mixed()
    .oneOf(
      statesAbbrev,
      'Not a valid state, use 2 capital letters, certain states are disallowed',
    )
    .required('State required'),
  nonProfitTaxId: yup
    .string()
    .matches(/^\d{2}\-?\d{7}$/, 'Enter a valid Tax ID/EIN#')
    .required('A valid Tax ID/EIN# is required'),
  customNonProfit: yup
    .string()
    .min(4, 'Nonprofit name should be more than 4 characters')
    .max(40, 'Nonprofit name should be less than 40 characters')
    .required('Nonprofit name is required'),
});

export default validationSchema;
