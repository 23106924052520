import React from 'react';
import { ReactMuiMask } from '@onehope/design-system-v2';

export default function DateMask(props: any) {
  return (
    <ReactMuiMask
      mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      {...props}
    />
  );
}
