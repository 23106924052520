import { gql } from '@apollo/client';

const ACCOUNT_CAN_USE_EXIGO_BACK_OFFICE = gql`
  query CEDash_AccountCanUseExigoBackOfficeQuery {
    viewer {
      id
      user {
        id
        canAccessExigoBackOffice
      }
    }
  }
`;

export default ACCOUNT_CAN_USE_EXIGO_BACK_OFFICE;
