import { gql } from '@apollo/client';

const CONTACT_AUTO_SHIPS_GET = gql`
  query CEDash_AutoshipsGetQuery($byContactId: String) {
    viewer {
      id
      user {
        id
        autoShips(byContactId: $byContactId) {
          autoShipId
          winePackId
          accountId
          ceoAccountId
          ceoAccountFullName
          isEditable
          stripePlanId
          autoShipId
          autoShipName
          hasBeenCustomized
          nextPaymentDate
          stripePlanId
          stripeSubscriptionId
          paymentStatus
          shippingCost
          subscriptionStatus
          billingIntervalUnit
          billingIntervalCount
          isCuratedWineClub
          curatedPackName
          pendingCuratedPack
          timesRenewed
          nextShipmentIncludesPwP
          isDefaultPwP
          pendingPwP
          winePackCount
          price
          shippingAddress {
            addressLineOne
          }
        }
      }
    }
  }
`;

export default CONTACT_AUTO_SHIPS_GET;
