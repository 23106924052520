import React from 'react';

const SvgBag = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C7.17143 0 4.85714 1.80816 4.85714 4.01323V5.66703H0.857143C0.371429 5.66703 0 5.95369 0 6.32856V16.6924C0 18.5226 1.91429 20 4.28571 20H15.7143C18.0857 20 20 18.5226 20 16.6924V6.32856C20 5.95369 19.6286 5.66703 19.1429 5.66703H15.1429V4.01323C15.1429 1.80816 12.8286 0 10 0ZM6.57146 4.01328C6.57146 2.53588 8.11431 1.32309 10 1.32309C11.8857 1.32309 13.4286 2.53588 13.4286 4.01328V5.66708H6.57146V4.01328ZM18.2857 16.7145V7.01218H15.1428V8.15881C15.1428 8.53368 14.7714 8.82034 14.2857 8.82034C13.8 8.82034 13.4285 8.53368 13.4285 8.15881V7.01218H6.57139V8.15881C6.57139 8.53368 6.19996 8.82034 5.71424 8.82034C5.22853 8.82034 4.8571 8.53368 4.8571 8.15881V7.01218H1.71424V16.7145C1.71424 17.817 2.8571 18.6991 4.28567 18.6991H15.7142C17.1428 18.6991 18.2857 17.817 18.2857 16.7145Z"
      fill="#373634"
    />
  </svg>
);

export default SvgBag;
