export const ButtonText = {
  watchNow: 'Watch Now',
  markRead: 'Mark Read',
  redeemPoints: 'Redeem Points',
} as const;

export const ButtonActions = {
  inAppLink: 'inAppLink',
  website: 'website',
  textMessage: 'textMessage',
  email: 'email',
  phoneCall: 'phoneCall',
} as const;

export const ButtonActionNames = {
  viewFFResults: 'View F&F Results',
} as const;
