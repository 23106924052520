import React, { useState } from 'react';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';
import OrdersWrapper from './OrdersWrapper';

import GET_ORDERS_V2_QUERY from '../../queries/OrdersPaginated';

function Orders() {
  let { page } = queryString.parse(window.location.search);
  if (!page) page = '1';
  const [pageNumber, setPageNumber] = useState(page);
  const [filterBy, setFilterBy] = useState('open');
  const [sort, setSort] = useState({ sortBy: 'dateCreated', desc: true });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState(null);
  const { data, error } = useQuery(GET_ORDERS_V2_QUERY, {
    variables: {
      page: Number(pageNumber),
      filterBy: filterBy,
      sortBy: sort.sortBy,
      desc: sort.desc,
      rowsPerPage,
      searchBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSortOrders = (sortBy: string) => {
    setSort({
      sortBy,
      desc: !sort.desc,
    });
  };

  if (error) return null;
  if (data?.viewer?.user?.customerOrdersV2) {
    const { customerOrdersV2, customerOrdersV2Count } = data.viewer.user;
    return (
      <OrdersWrapper
        orders={customerOrdersV2}
        pageNumber={Number(pageNumber)}
        filterBy={filterBy ? filterBy : 'open'}
        setPageNumber={setPageNumber}
        setFilterBy={setFilterBy}
        handleSortOrders={handleSortOrders}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={customerOrdersV2Count}
        setSearchBy={setSearchBy}
      />
    );
  }
  return null;
}

export default Orders;
