import gql from 'graphql-tag';
export const mutation = gql`
  mutation CEDash_ImagePresignedUrlGetMutation(
    $input: ImagePresignedUrlGetInput!
  ) {
    imagePresignedUrlGet(input: $input) {
      urls
    }
  }
`;

export default mutation;
