import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Grid,
  Text,
  AutoCompleteV2 as AutoComplete,
} from '@onehope/design-system-v2';

/* UTILS */
import useToggle from '../../../utils/useToggleHook';

import GET_AUTOCOMPLETE_DATA_QUERY from '../../../queries/AutoCompleteDataGet';

import CART_UPDATE_EVENT_ID_MUTATION from '../../../mutations/CartUpdateEventIdMutation';
import {
  CauseOfChoiceScreenDictionary,
  CauseOfChoiceTypeKeys,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCommonStyles } from '../../../utils/commonStyles';
import { getCurrentUser } from '../../../utils/helper';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
import { eventSources } from '../../../utils/constants';

export default function FundraiserSearchWrapper() {
  const commonStyles = useCommonStyles();
  const [events, setEvents] = useState([]);
  const [, setSelectedEvent] = useState(null);
  const [, dispatch] = useCauseOfChoiceContextValue();
  const {
    user: parentUser,
    urlEventImgUploads,
    modalLocation,
    trackUpdateCauseOfChoice,
  } = useCauseOfChoiceNetworkLayerValue();
  const isCE = !!parentUser?.permissions?.oneCommerce?.ceo?.access;
  const user = getCurrentUser(parentUser);

  // always default first to the Wine Rep the user is shopping for (if any)
  const ceId =
    user?.shoppingWithCeoUser?.userId ?? (isCE ? parentUser.userId : 'none');

  const [getAutoCompleteData] = useLazyQuery(GET_AUTOCOMPLETE_DATA_QUERY, {
    onCompleted: (response: any) => {
      const data = response?.viewer?.user?.autoCompleteData?.edges;
      setEvents(data);
    },
  });

  const handleLoadingState = (loadingState: boolean) => {
    dispatch({
      type: TypeKeys.UPDATE_LOADING,
      loading: loadingState,
    });
  };

  const [cartUpdateEventIdMutation] = useMutation(
    CART_UPDATE_EVENT_ID_MUTATION,
  );

  const {
    value: menuOpen,
    setTrue: setMenuOpen,
    setFalse: setMenuClosed,
  } = useToggle(false);

  const handleInputChange = (search: string) => {
    const variables = {
      ceoId: ceId,
      withAllData: true,
      count: 10,
      inputValue: search,
      searchCEOS: false,
      searchEvents: true,
      searchProducts: false,
    };
    if (search === '') {
      setMenuClosed();
      return setSelectedEvent(null);
    }
    getAutoCompleteData({ variables });
    setMenuOpen();
  };

  const handleOnFocus = () => {
    // If a searching for a Wine Rep's events, display all of them initially (capped at 25)
    if (ceId && ceId !== 'none') {
      const variables = {
        ceoId: ceId,
        withAllData: true,
        count: 25,
        inputValue: '',
        searchCEOS: false,
        searchEvents: true,
        searchProducts: false,
      };
      getAutoCompleteData({ variables });
      setMenuOpen();
    }
  };

  const handleGotoConfirmationPage = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.FUNDRAISER_CONFIRMATION_SCREEN,
    });
  };

  const handleEventSelected = (event: any) => {
    const eventId = event?.node?.eventId;
    if (eventId) {
      handleLoadingState(true);
      return cartUpdateEventIdMutation({
        variables: {
          input: { eventId, eventSource: eventSources.causeSelection },
        },
      }).then((res) => {
        handleLoadingState(false);
        handleGotoConfirmationPage();
        if (trackUpdateCauseOfChoice) {
          trackUpdateCauseOfChoice({
            selectedCause: res?.data?.cartUpdateEventId?.user?.selectedCause,
            guestCause:
              res?.data?.cartUpdateEventId?.user?.cartV2?.guestNonProfit,
            causeType: CauseOfChoiceTypeKeys.FUNDRAISER,
            updateLocation: modalLocation,
            shoppingWithCeoUser:
              res?.data?.causeUpdateSelected?.user?.shoppingWithCeoUser,
          });
        }
      });
    } else {
      return;
    }
  };
  return (
    <Grid container spacing={0} direction="column">
      <Formik
        initialValues={{
          fullName: '',
        }}
        onSubmit={() => {}}
      >
        {({ values, isSubmitting, touched, setFieldValue, handleChange }) => (
          <Form className={commonStyles.form}>
            <Grid container direction="row" className={commonStyles.formItem}>
              <Text className={commonStyles.labelText}>
                Search by ONEHOPE fundraiser or Host name
              </Text>
              <AutoComplete
                id="eventDropDown"
                autoFocus={true}
                inputId="fullName"
                isWithinDialog={true}
                placeholder="Search"
                value={values.fullName}
                touched={touched.fullName}
                isSubmitting={isSubmitting}
                isMenuOpen={menuOpen}
                urlEventImgUploads={urlEventImgUploads}
                setFieldValue={setFieldValue}
                handleCeSelected={handleEventSelected}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                handleOuterClick={setMenuClosed}
                objectPredictions={events}
                showLocationIcon={false}
                handleOnFocus={handleOnFocus}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
