import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';

import { styles } from '@onehope/design-system';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Icons,
  Dialog as DialogRoot,
  Text,
  TEXT_DS,
  styled,
  ButtonV2,
  theme,
} from '@onehope/design-system-v2';

import { MyFormValues } from './MyFormTypes';
import SearchOrAddCustomer from './SearchOrAddCustomer';
import {
  ContactType,
  useContactsContext,
} from '../../ContactsV3/ContactsContext';
import AddContactForm from '../../ContactsV3/Header/AddContactDialog/AddContactForm';

const { cssConstants } = styles;
const { Close } = Icons;

interface AddCustomerProps {
  selectCustomer?: boolean;
  onSubmit: any;
  onClose: () => void;
  eventId?: string;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

const CloseDialog = styled('div')(() => ({
  cursor: 'pointer',
}));

const ButtonContainer = styled('div')(() => ({
  paddingTop: '16px',
}));

const FormContainer = styled('div')(() => ({
  padding: '48px',
}));

const ChangeTextButton = styled(Text)(() => ({
  cursor: 'pointer',
}));

const ChangeTextButtonContainer = styled(Grid)(() => ({
  marginLeft: 'auto',
}));

const CustomerCard = styled('div')(() => ({
  maxHeight: '200px',
  borderRadius: '4px',
  border: `solid 1px ${theme.palette.greyMedium.main}`,
  backgroundColor: `${cssConstants.neutralBackgroundColor}`,
}));

const CustomerCardWide = styled(CustomerCard)(() => ({
  minWidth: '288px',
  marginBottom: '40px',
}));

const CardInfo = styled('div')(() => ({
  position: 'relative',
  padding: '16px',
}));

const TitleContainer = styled(Grid)(() => ({
  paddingBottom: '32px',
}));

const ShoppingForContainer = styled(Grid)(() => ({
  paddingBottom: '8px',
}));

export default function AddOrder({
  touched,
  onClose,
  selectCustomer,
  isSubmitting,
  eventId,
}: FormProps & FormikProps<MyFormValues> & AddCustomerProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { handleOrderPermissions } = useContactsContext();
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Partial<ContactType>>(
    {},
  );

  useEffect(() => {
    if (eventId) {
      const queryParams = queryString.parse(window?.location?.search);
      const arrayedParams = Object.keys(queryParams);
      const onlyContainsEventIdParams =
        !!queryParams['eventId'] && arrayedParams.length === 1;
      // if there are no existing params or the only existing param is eventId
      if (!window?.location?.search || onlyContainsEventIdParams) {
        window.history.pushState({}, '', `?eventId=${eventId}`);
      } else {
        const updatedQueryParams = arrayedParams.reduce((acc, p) => {
          if (p !== 'eventId') {
            acc += `&${p}=${queryParams[p]}`;
          }
          return acc;
        }, '');

        window.history.pushState(
          {},
          '',
          `?eventId=${eventId}${updatedQueryParams}`,
        );
      }
    }
  }, [eventId]);

  const toggleIsAddingNew = () => {
    setIsAddingNew(!isAddingNew);
  };

  interface AutoCompleteContact {
    contactId: string;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    organizationName: string;
    accountId: string;
    ownerAccountId: string;
    isCe: boolean;
    canShopFor: boolean;
  }

  const handleAutoCompleteContactSelected = (
    inputId: string,
    contact: AutoCompleteContact,
  ) => {
    const { phone, email } = contact;
    setSelectedContact({
      ...contact,
      phoneNumber: phone,
      emailAddress: email,
    });
  };

  const handleCustomerSelected = (contact: AutoCompleteContact) => {
    const { phone, email } = contact;
    setSelectedContact({
      ...contact,
      phoneNumber: phone,
      emailAddress: email,
    });
  };

  const handleSubmitOrder = (e: Event) => {
    if (isEmpty(selectedContact)) {
      setIsAddingNew(true);
      e.preventDefault();
      return;
    }
    handleOrderPermissions(selectedContact, eventId);
  };

  function handleHostReset() {
    setSelectedContact({});
  }

  const formatPhone = (value: string) => {
    if (!value) return null;
    let i = 0;
    const number = value.replace(/[- )(+]/g, '');
    const pattern =
      number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
    return pattern.replace(/#/g, _ => number[i++]);
  };

  const contactIsSelected = !!Object.keys(selectedContact).length;

  return (
    <>
      {isAddingNew ? (
        <DialogRoot
          fullScreen={fullScreen}
          disableEnforceFocus
          open={isAddingNew}
          onClose={toggleIsAddingNew}
        >
          <AddContactForm
            onClose={toggleIsAddingNew}
            callback={selectCustomer ? handleCustomerSelected : null}
            defaultValues={{
              firstName: '',
              lastName: '',
              emailAddress: '',
              phoneNumber: '',
              dateOfBirth: '',
            }}
          />
        </DialogRoot>
      ) : (
        <FormContainer>
          <form onSubmit={handleSubmitOrder}>
            <TitleContainer
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_32}>
                  New Order
                </Text>
              </Grid>
              <Grid item>
                <CloseDialog onClick={onClose}>
                  <Close />
                </CloseDialog>
              </Grid>
            </TitleContainer>
            {contactIsSelected ? (
              <CustomerCardWide>
                <CardInfo>
                  <Grid container alignItems="flex-end">
                    <Grid item>
                      <ShoppingForContainer item>
                        <Text
                          variant="custom"
                          default={TEXT_DS.BODY_SEMIBOLD_18}
                        >
                          Shopping for:
                        </Text>
                      </ShoppingForContainer>
                      <Grid item>
                        <Text
                          variant="custom"
                          default={TEXT_DS.BODY_REGULAR_16}
                        >
                          {selectedContact?.firstName}{' '}
                          {selectedContact.lastName}
                        </Text>
                      </Grid>
                      <Grid item>
                        <Text
                          variant="custom"
                          default={TEXT_DS.BODY_REGULAR_16}
                        >
                          {selectedContact?.emailAddress}
                        </Text>
                      </Grid>
                      {selectedContact?.dateOfBirth &&
                        selectedContact?.dateOfBirth !== 'null' && (
                          <Grid item>
                            <Text
                              variant="custom"
                              default={TEXT_DS.BODY_REGULAR_16}
                            >
                              {DateTime.fromJSDate(
                                new Date(selectedContact?.dateOfBirth),
                              ).toFormat('LLL dd, yyyy')}
                            </Text>
                          </Grid>
                        )}
                      {selectedContact?.phoneNumber &&
                        selectedContact?.phoneNumber !== 'null' && (
                          <Grid item>
                            <Text
                              variant="custom"
                              default={TEXT_DS.BODY_REGULAR_16}
                            >
                              {formatPhone(selectedContact?.phoneNumber)}
                            </Text>
                          </Grid>
                        )}
                    </Grid>
                    <ChangeTextButtonContainer item>
                      <ChangeTextButton
                        variant="custom"
                        default={TEXT_DS.BODY_LINK_14}
                        onClick={handleHostReset}
                      >
                        Change
                      </ChangeTextButton>
                    </ChangeTextButtonContainer>
                  </Grid>
                </CardInfo>
              </CustomerCardWide>
            ) : (
              <SearchOrAddCustomer
                touched={touched}
                isSubmitting={isSubmitting}
                handleCustomerSelected={handleAutoCompleteContactSelected}
                toggleAddCustomer={toggleIsAddingNew}
              />
            )}
            <ButtonContainer>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ButtonV2
                    type={'secondary'}
                    disableRipple
                    fullWidth
                    variant="outlined"
                    onClick={onClose}
                  >
                    CANCEL
                  </ButtonV2>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ButtonV2
                    disableRipple
                    fullWidth
                    disabled={!contactIsSelected}
                    onClick={handleSubmitOrder}
                  >
                    START
                  </ButtonV2>
                </Grid>
              </Grid>
            </ButtonContainer>
          </form>
        </FormContainer>
      )}
    </>
  );
}
