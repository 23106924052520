import React from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../common/Loading';
import EventDetailsPage from './EventDetailsPage';
import EVENT_DETAILS_QUERY from '../../queries/Event';
import get from 'lodash/get';
import { ContactsContextProvider } from '../ContactsV3/ContactsContext';
import {
  CEDash_EventDetailsPage,
  CEDash_EventDetailsPageVariables,
} from '../../queries/generatedTypes/CEDash_EventDetailsPage';

//#region Helpers

function goBack() {
  navigate('/events');
  return null;
}

//#region Event Page Component

interface EventPageProps {
  eventId: string | string[] | null | undefined;
  tab: string | string[];
  contactId?: string | string[] | null | undefined;
  openNewOrder?: boolean;
}

function EventPage(props: EventPageProps) {
  const { eventId } = props;

  const { data, loading, error } = useQuery<
    CEDash_EventDetailsPage,
    CEDash_EventDetailsPageVariables
  >(EVENT_DETAILS_QUERY, {
    variables: { eventId: String(eventId) },
    pollInterval: 10000,
  });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    console.error('Event not found');
    return goBack();
  }

  if (error) {
    return goBack();
  }

  if (get(data.viewer, 'event.id')) {
    const { tab, contactId, openNewOrder } = props;
    const { event, user } = data.viewer;

    const isLoggedInAsAdmin =
      user?.permissions?.oneCommerce?.admin?.access ?? false;

    if (!isLoggedInAsAdmin && user.userId !== event.accountId) {
      return goBack();
    }

    //#region HTML

    return (
      <ContactsContextProvider contacts={[]}>
        <EventDetailsPage
          event={event}
          tab={tab}
          contactId={contactId}
          user={user}
          openNewOrder={openNewOrder}
        />
      </ContactsContextProvider>
    );
  }
  return null;
}

export default EventPage;
