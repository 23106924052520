import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  memo,
} from 'react';
import {
  Grid,
  Container,
  Text,
  Icons,
  useTheme,
  useMediaQuery,
} from '@onehope/design-system-v2';
import { FeedTypes } from '../utils/constants';
import { useStyles } from './helpers/playbookHeaderStyles';

function useOutsideClicker({
  wrapperRef,
  setOpenMoreDropdown,
  openMoreDropdown,
}: {
  wrapperRef: MutableRefObject<any>;
  setOpenMoreDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  openMoreDropdown: boolean;
}) {
  useEffect(() => {
    /**
     * Close "More" dropdown menu if clicked on outside of it
     */
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        openMoreDropdown
      ) {
        setOpenMoreDropdown(false);
      }
    }
    if (document) {
      // Bind the event listener
      document.addEventListener('click', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickOutside);
      };
    }
    return;
  }, [wrapperRef, openMoreDropdown]);
}

type PlaybookHeaderProps = {
  handleSetOpenCardDialog?: (feedType: FeedTypes) => void;
  cardCount: number;
  newTabSelected: boolean;
  setNewTab: () => void;
  setSavedTab: () => void;
};

function PlaybookHeader(props: PlaybookHeaderProps) {
  const {
    handleSetOpenCardDialog,
    cardCount,
    newTabSelected,
    setNewTab,
    setSavedTab,
  } = props;
  const classes = useStyles({ hasFeeds: true });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [openMoreDropdown, setOpenMoreDropdown] = useState(false);

  const wrapperRef = useRef<any>(null);
  useOutsideClicker({ wrapperRef, setOpenMoreDropdown, openMoreDropdown });

  const toggleOpenMoreDropdown = () => {
    setOpenMoreDropdown(!openMoreDropdown);
  };

  const newTabClass = newTabSelected
    ? isMobile
      ? classes.tabActiveMobile
      : classes.tabActive
    : isMobile
    ? classes.tabInactiveMobile
    : classes.tabInactive;
  const savedTabClass = !newTabSelected
    ? isMobile
      ? classes.tabActiveMobile
      : classes.tabActive
    : isMobile
    ? classes.tabInactiveMobile
    : classes.tabInactive;

  return (
    <>
      <div className={classes.tabContainer}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.cardsHeader}
        >
          <Grid className={classes.inbox}>
            <div className={classes.tabContainer}>
              <div className={newTabClass} onClick={setNewTab}>
                <div className={classes.tabContainer}>
                  New
                  {cardCount > 0 && (
                    <div className={classes.alert}>{cardCount}</div>
                  )}
                </div>
              </div>
              <div className={savedTabClass} onClick={setSavedTab}>
                Saved
              </div>
            </div>
          </Grid>
          {!!handleSetOpenCardDialog && isMobile && (
            <Grid onClick={toggleOpenMoreDropdown} ref={wrapperRef}>
              <Icons.MoreHorizontal />
              {openMoreDropdown && (
                <Container className={classes.moreDropdownMobile}>
                  <Text
                    className={classes.moreDropdownItem}
                    onClick={() => handleSetOpenCardDialog(FeedTypes.archived)}
                  >
                    View Dismissed Cards
                  </Text>
                  <Text
                    className={classes.moreDropdownItem}
                    onClick={() => handleSetOpenCardDialog(FeedTypes.completed)}
                  >
                    View Completed Cards
                  </Text>
                </Container>
              )}
            </Grid>
          )}
        </Grid>
      </div>
      {!!handleSetOpenCardDialog && !isMobile && (
        <div
          onClick={toggleOpenMoreDropdown}
          ref={wrapperRef}
          className={classes.deskMore}
        >
          <Icons.MoreHorizontal />
          {openMoreDropdown && (
            <Container className={classes.moreDropdown}>
              <Text
                className={classes.moreDropdownItem}
                onClick={() => handleSetOpenCardDialog(FeedTypes.archived)}
              >
                View Dismissed Cards
              </Text>
              <Text
                className={classes.moreDropdownItem}
                onClick={() => handleSetOpenCardDialog(FeedTypes.completed)}
              >
                View Completed Cards
              </Text>
            </Container>
          )}
        </div>
      )}
    </>
  );
}

export default memo(PlaybookHeader);
