import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import {
  Grid,
  Text,
  Icons,
  AutoCompleteV2 as AutoComplete,
} from '@onehope/design-system-v2';

/* COMPONENTS */
import EditNonProfitButton from './EditNonProfitButton';

/* QUERIES */
import NONPROFIT_AUTOCOMPLETE_QUERY from '../../../queries/NonProfitsQuery';
import UPDATE_SELECTED_CAUSE_MUTATION from '../../../mutations/UpdateSelectedCauseMutation';

/* HELPERS */
import {
  TypeKeys,
  CauseOfChoiceScreenDictionary,
  CauseOfChoiceTypeKeys,
} from '../../../stateManagement/types';
import useToggle from '../../../utils/useToggleHook';
import {
  formatIRSNonProfits,
  isEventCeDashPage,
  isOHFoundationNpo,
} from '../../../utils/helper';
import { nonProfitFormSchema } from './validationSchema';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

/* STYLES */
import { useCommonStyles } from '../../../utils/commonStyles';

export default function NonProfitSearchWrapper() {
  const [, dispatch] = useCauseOfChoiceContextValue();
  const {
    handleCloseModal,
    handleNonProfitSelected,
    modalLocation,
    trackUpdateCauseOfChoice,
    selectedNonProfit,
  } = useCauseOfChoiceNetworkLayerValue();
  const [nonProfits, setNonProfits] = useState([]);
  const commonStyles = useCommonStyles();

  const isOnEventPage = isEventCeDashPage();
  const isOHFoundation = isOHFoundationNpo(selectedNonProfit?.nonProfitTaxId);
  const showEditNonProfitButton =
    isOnEventPage && !!selectedNonProfit && !isOHFoundation;

  const {
    value: isNonProfitMenuOpen,
    setTrue: setIsNonProfitMenuOpen,
    setFalse: setIsNonProfitMenuClosed,
  } = useToggle();

  const handleLoadingState = (loadingState: boolean) => {
    dispatch({
      type: TypeKeys.UPDATE_LOADING,
      loading: loadingState,
    });
  };

  const [updateSelectedCauseMutation] = useMutation(
    UPDATE_SELECTED_CAUSE_MUTATION,
  );

  const [getNonProfitAutoCompleteData] = useLazyQuery(
    NONPROFIT_AUTOCOMPLETE_QUERY,
    {
      onCompleted: (res) => {
        const formattedNonProfits = formatIRSNonProfits(res);
        setNonProfits(formattedNonProfits);
      },
    },
  );

  const handleAddManualNonProfit = () => {
    setIsNonProfitMenuClosed();
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.MANUAL_NON_PROFIT_SCREEN,
    });
  };

  const handleGotoConfirmationPage = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_CONFIRMATION_SCREEN,
    });
  };

  const handleNonProfitSearch = (search: string) => {
    if (search && search !== '') {
      const variables = {
        keyword: search,
        includeEdits: isOnEventPage,
      };
      setIsNonProfitMenuOpen();
      getNonProfitAutoCompleteData({ variables });
    } else {
      setIsNonProfitMenuClosed();
    }
  };

  const handleCustomNonProfitSelected = ({
    nonProfitId,
    nonProfitEditId,
  }: {
    nonProfitId: string;
    nonProfitEditId?: string;
  }) => {
    // custom behavior for event NPO selection
    if (handleNonProfitSelected) {
      handleNonProfitSelected({ nonProfitId, nonProfitEditId });
      return handleCloseModal();
    }
    handleLoadingState(true);
    const variables = {
      input: {
        nonProfitId,
      },
    };
    return updateSelectedCauseMutation({ variables }).then((res) => {
      const user = res?.data?.causeUpdateSelected?.user;
      handleLoadingState(false);
      if (user) {
        handleGotoConfirmationPage();
      }
      if (trackUpdateCauseOfChoice) {
        trackUpdateCauseOfChoice({
          selectedCause: user?.selectedCause,
          guestCause: user?.cartV2?.guestNonProfit,
          causeType: CauseOfChoiceTypeKeys.NON_PROFIT,
          updateLocation: modalLocation,
          shoppingWithCeoUser: user?.shoppingWithCeoUser,
        });
      }
    });
  };
  return (
    <Grid container>
      <Formik
        initialValues={{
          customNonProfit: '',
          nonProfitId: '',
          nonProfitEditId: '',
        }}
        validationSchema={nonProfitFormSchema}
        onSubmit={(values) => {
          const nonProfitId = values?.nonProfitId;
          const nonProfitEditId = values?.nonProfitEditId;
          if (nonProfitId) {
            return handleCustomNonProfitSelected({
              nonProfitId,
              nonProfitEditId,
            });
          } else {
            return;
          }
        }}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          errors,
          touched,
          handleChange,
          setFieldValue,
        }) => (
          <Form className={commonStyles.form}>
            <Grid container direction="row" className={commonStyles.formItem}>
              <Text className={commonStyles.labelText}>
                Search by nonprofit name, location or EIN.
              </Text>
              <AutoComplete
                validate={false}
                autoFocus={true}
                id="customNonProfitDropDown"
                inputId="customNonProfit"
                isWithinDialog={true}
                placeholder="Search"
                isSubmitting={isSubmitting}
                value={values.customNonProfit}
                error={errors.customNonProfit}
                touched={touched.customNonProfit}
                isMenuOpen={isNonProfitMenuOpen}
                setFieldValue={setFieldValue}
                handleCustomNonProfitSelected={submitForm}
                toggleAddManualNonProfit={handleAddManualNonProfit}
                handleChange={handleChange}
                handleInputChange={handleNonProfitSearch}
                handleOuterClick={setIsNonProfitMenuClosed}
                objectPredictions={nonProfits}
                showLocationIcon={false}
              />
            </Grid>
          </Form>
        )}
      </Formik>
      {showEditNonProfitButton ? (
        <EditNonProfitButton />
      ) : (
        <Grid container direction="column">
          <Text className={commonStyles.labelTextTop}>
            Need some inspiration? You can fundraise for...
          </Text>
          <Grid
            item
            xs
            container
            alignItems="center"
            className={commonStyles.listContainerUnderline}
          >
            <Icons.COCEducation />
            <Text className={commonStyles.listText}>
              School PTA or sports team
            </Text>
          </Grid>
          <Grid
            item
            xs
            container
            alignItems="center"
            className={commonStyles.listContainerUnderline}
          >
            <div className={commonStyles.iconHungerWrapper}>
              <Icons.COCHunger />
            </div>
            <Text className={commonStyles.listText}>Your local food bank</Text>
          </Grid>
          <Grid
            item
            xs
            container
            alignItems="center"
            className={commonStyles.listContainer}
          >
            <Icons.COCHeart />
            <Text className={commonStyles.listText}>Animal shelters</Text>
          </Grid>
          <Text className={commonStyles.labelText}>
            ANY 501(c)3  organization currently recognized by the IRS is eligible
          </Text>
        </Grid>
      )}
    </Grid>
  );
}
