// @ts-ignore
import * as Yup from 'yup';

export const CharityTypeSchema = {
  charityType: Yup.string().required('Required'),
};

export const EventCharityFormSchema = {
  preApprovedNonProfit: Yup.string().when('charityType', {
    is: 'OHW-PARTNER',
    then: Yup.string().required('Required'),
  }),
  customNonProfit: Yup.string().when('charityType', {
    is: 'CUSTOM',
    then: Yup.string().required('Required'),
  }),
  addressLineOne: Yup.string().when('charityType', {
    is: 'CUSTOM',
    then: Yup.string().required('Required'),
  }),
  state: Yup.string().when('charityType', {
    is: 'CUSTOM',
    then: Yup.string().required('Required'),
  }),
  city: Yup.string().when('charityType', {
    is: 'CUSTOM',
    then: Yup.string().required('Required'),
  }),
  zip: Yup.string().when('charityType', {
    is: 'CUSTOM',
    then: Yup.string().required('Required'),
  }),
  nonProfitTaxId: Yup.string().when('charityType', {
    is: 'CUSTOM',
    then: Yup.string().required('Required'),
  }),
  charityDonationGoal: Yup.string().when('showDonationGoal', {
    is: 'true',
    then: Yup.string().required('Required'),
  }),
};

export function getCharitySchema() {
  return Yup.object().shape({ ...EventCharityFormSchema });
}
