// material-ui
import {
  Drawer,
  styled,
  ThemeType,
  CssObject,
} from '@onehope/design-system-v2';

// project import
import { DRAWER_WIDTH } from './index';

const openedMixin = (theme: ThemeType): CssObject => ({
  width: DRAWER_WIDTH,
  borderRight: `0px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
});

const closedMixin = (theme: ThemeType): CssObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: theme.spacing(7.5),
  width: 0,
  borderRight: 'none',
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  opacity: 1,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default MiniDrawerStyled;
