import * as React from 'react';
import { SelectProps } from '@material-ui/core/Select';

import { InputSelect, Label, Error } from './input.styles';

type InputSelectContainer = SelectProps & {
  label?: string;
  error?: string;
  helperText?: string;
};

export default function InputSelectContainer(props: InputSelectContainer) {
  const { label, error, helperText, ...rest } = props;
  return (
    <React.Fragment>
      {label && (
        <div>
          <Label htmlFor={props.name}>{label}</Label>
        </div>
      )}
      <InputSelect {...rest} />
      {error && <Error>{helperText}</Error>}
    </React.Fragment>
  );
}
