import produce from 'immer';
import { TypeKeys, StateType, ActionTypes } from './types';

export default function reducer(state: StateType, action: ActionTypes) {
  return produce<StateType>(state, draft => {
    switch (action.type) {
      case TypeKeys.TOGGLE_DRAWER:
        draft.drawerOpen = action.drawerOpen;
        break;
      default:
        break;
    }
  });
}
