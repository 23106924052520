import React from 'react';
import {
  Grid,
  Text,
  TEXT_DS,
  Icons,
  ChunkyButton,
  styled,
  ThemeType,
  useTheme,
} from '@onehope/design-system-v2';
import { formatNonProfitAddress } from '@onehope/utils';

/* HELPERS */
import {
  TypeKeys,
  CauseOfChoiceScreenDictionary,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

const HorizontalLine = styled('div')(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: '#C2C6C9',
}));

const OrContainer = styled(Grid)(() => ({ margin: '8px 0' }));

const HorizontalLineContainer = styled('div')(() => ({
  width: '100%',
}));

const OrText = styled(Text)(({ theme }: { theme: ThemeType }) => ({
  textAlign: 'center',
  color: theme.palette.greyDark.main,
  margin: '0 16px',
}));

const TitleText = styled(Text)(() => ({
  margin: '16px 0',
}));

export default function EditNonProfitButton() {
  const [, dispatch] = useCauseOfChoiceContextValue();
  const { selectedNonProfit } = useCauseOfChoiceNetworkLayerValue();
  const theme = useTheme();

  const handleGotoEditNonProfitPage = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_EDIT_SCREEN,
    });
  };

  return (
    <Grid container direction="column">
      <OrContainer
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
        <OrText
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_16}
          theme={theme}
        >
          OR
        </OrText>
        <HorizontalLineContainer>
          <HorizontalLine />
        </HorizontalLineContainer>
      </OrContainer>
      <TitleText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
        Edit details of selected nonprofit
      </TitleText>
      <ChunkyButton
        fullWidth
        hideImage
        onChange={handleGotoEditNonProfitPage}
        onChangeText="Edit"
        primaryText={`${selectedNonProfit?.nonProfitName}`}
        secondaryText={formatNonProfitAddress(
          selectedNonProfit?.nonProfitAddress,
        )}
        tertiaryText={`EIN: ${selectedNonProfit?.nonProfitTaxId}`}
        headerIcon={
          selectedNonProfit?.isIRSVerified && !selectedNonProfit?.isEdited ? (
            <Icons.COCVerified />
          ) : (
            <Icons.COCDatabaseEdit />
          )
        }
      />
    </Grid>
  );
}
