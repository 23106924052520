import { List, ListItem, styled, ThemeType } from '@onehope/design-system-v2';

interface Props {
  theme: ThemeType;
}

export const CustomList = styled(List)(() => ({
  '&.MuiList-root': {
    padding: 0,
  },
}));

export const CustomListItem = styled(ListItem)(({ theme }: Props) => ({
  '&.MuiListItem-root': {
    height: 48,
    paddingLeft: 24,
    '.icon': {
      opacity: 0.8,
    },
    '.text': {
      fontWeight: 420,
      opacity: 0.8,
    },
  },
  '&.MuiListItem-root:hover': {
    '.icon': {
      opacity: 1,
    },
    '.text': {
      opacity: 1,
    },
  },
  '&.MuiListItem-root.Mui-selected': {
    backgroundColor: theme.palette.forestGreen.main,
    '.icon': {
      opacity: 1,
    },
    '.text': {
      opacity: 1,
    },
  },
  '&.MuiListItem-root.Mui-selected:hover': {
    backgroundColor: theme.palette.forestGreen.main,
    '.icon': {
      opacity: 1,
    },
    '.text': {
      opacity: 1,
    },
  },
  svg: {
    '.icon:hover': {
      opacity: 1,
    },
  },
}));
