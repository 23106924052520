import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import {
  Icons,
  makeStyles,
  ThemeType,
  useTheme,
  useMediaQuery,
  Grid,
  Text,
  Box,
} from '@onehope/design-system-v2';

import Counter from './counterAnimation';

interface NavHeaderProps {
  toggle: () => void;
  firstName: string;
  pointBalance: number | null;
  rewardBadgeAnimationAmount: number;
  triggerRewardBadgeAnimation: boolean;
  setTriggerRewardBadgeAnimation: React.Dispatch<React.SetStateAction<boolean>>;
}

const getStyles = makeStyles((theme: ThemeType) => ({
  greeting: {
    fontFamily: theme.headerFontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.04em',
    marginLeft: '16px',
    color: theme.palette.primary.main,
  },
  rewardContainer: {
    fontFamily: theme.bodyFontFamily,
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    height: '36px',
    backgroundColor: '#EDE0BF',
    borderRadius: '40px',
    cursor: 'pointer',
  },
  rewardContent: {
    display: 'flex',
  },
  iconContainer: {
    marginTop: '-16px',
    marginLeft: '8px',
  },
  textContainer: {
    fontSize: '12px',
    marginTop: '-19px',
    marginLeft: '3px',
  },
}));

const goToAccountsRewardsPage = () => {
  window.location.href = `${process.env.GATSBY_MEGALITH_URL}/account/rewards`;
};

const badge = {
  rest: { scale: 1 },
  expand: { scale: 1.3 },
};

const NavHeader: React.FunctionComponent<NavHeaderProps> = ({
  toggle,
  firstName,
  pointBalance,
  rewardBadgeAnimationAmount,
  triggerRewardBadgeAnimation,
  setTriggerRewardBadgeAnimation,
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const classes = getStyles();
  const [from, setFrom] = useState(pointBalance);
  const [to, setTo] = useState(pointBalance);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (rewardBadgeAnimationAmount && triggerRewardBadgeAnimation) {
      const newBalance = from + rewardBadgeAnimationAmount;
      setTo(newBalance);
      setExpand(true);
      // delay badge animation - let card animation finish
      setTimeout(() => {
        setExpand(false);
        setTriggerRewardBadgeAnimation(false);
        setFrom(newBalance);
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerRewardBadgeAnimation,
    rewardBadgeAnimationAmount,
    setTriggerRewardBadgeAnimation,
  ]); // Exclude from because it's being set here

  const getRewardComponent = () => {
    return (
      <motion.div
        className={classes.rewardContainer}
        variants={badge}
        animate={expand ? 'expand' : 'rest'}
        initial="rest"
        onClick={goToAccountsRewardsPage}
      >
        <Grid container alignItems="center">
          <Icons.Star />
          <Counter from={from} to={to} />
        </Grid>
      </motion.div>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        height: '70px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={toggle}
      >
        <Icons.HamburgerMenu />
        {matchDownMD && (
          <Text className={classes.greeting}>Hi, {firstName}!</Text>
        )}
      </Box>
      {getRewardComponent()}
    </Box>
  );
};
export default NavHeader;
