import React, { useState } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

import {
  ButtonV2,
  Grid,
  Icons,
  styled,
  Text,
  TEXT_DS,
  AutoCompleteV2,
  theme,
} from '@onehope/design-system-v2';

import GET_CONTACTS_V2_QUERY from '../../../queries/ContactsV2';
import { ContactType } from '../ContactsContext';

const { AddCircleIcon } = Icons;

const NewCustomerButton = styled('div')(() => ({
  width: '100%',
  height: '100px',
  paddingTop: '8px',
  marginBottom: '40px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const CustomerTextContainer = styled('div')(() => ({
  width: '432px',
  height: '88px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const AutoCompleteContainer = styled(Grid)(() => ({
  width: '100%',
  paddingBottom: '24px',
}));

const HorizontalLine = styled('div')(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: '#F2F2F2',
}));

const HorizontalLineContainer = styled(Grid)(() => ({
  minWidth: '45%',
}));

const StyledAddNewButton = styled(ButtonV2)(() => ({
  border: '1px dashed',
  borderRadius: '4px',
  marginTop: '20px',
  height: '100px',
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
  cursor: 'pointer',
}));

const OrText = styled(Text)(() => ({
  textAlign: 'center',
}));

const formatCustomers = (data: any) => {
  return (
    get(data, 'viewer.v2.contactsV2.contacts.data') &&
    get(data, 'viewer.v2.contactsV2.contacts.data').map(
      (customer: ContactType) => {
        const result = customer
          ? {
              organizationName: customer.organizationName || '',
              fullName:
                customer.firstName && customer.lastName
                  ? `${customer.firstName} ${customer.lastName}`
                  : '',
              firstName: customer.firstName || '',
              lastName: customer.lastName || '',
              emailAddress: customer.emailAddress,
              phoneNumber: customer.phoneNumber,
              accountId: customer.accountId,
              ownerAccountId: customer.ownerAccountId,
              dateOfBirth: customer.dateOfBirth || '',
              contactId: customer.contactId,
              canShopFor: customer.canShopFor,
              isCe: customer.isCe,
              shippingAddress: customer.shippingAddress,
            }
          : '';

        return result;
      },
    )
  );
};

export default function SearchOrAddCustomer({
  touched,
  isSubmitting,
  handleCustomerSelected,
  toggleAddCustomer,
}: any) {
  const { refetch: getContacts } = useQuery(GET_CONTACTS_V2_QUERY, {
    skip: true,
  });

  const [contacts, setContacts] = useState([]);
  const [isHostNameMenuOpen, setIsHostNameMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = async (search: string) => {
    if (search && search !== '') {
      setIsHostNameMenuOpen(true);
      try {
        const results = await getContacts({
          fullName: search,
        });
        if (get(results, 'data.viewer')) {
          setContacts(formatCustomers(results.data));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsHostNameMenuOpen(false);
      setContacts([]);
    }
  };

  const handleHostNameChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    const search = event.target.value;
    handleInputChange(search);
  };

  const formatForAutoComplete = (contacts: ContactType[]) => {
    const formattedContacts = contacts.map(
      ({
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        accountId,
        ownerAccountId,
        dateOfBirth,
        contactId,
        organizationName,
        isCe,
        canShopFor,
        shippingAddress,
      }) => ({
        fullName: `${firstName} ${lastName}`,
        firstName,
        lastName,
        phone: phoneNumber,
        email: emailAddress,
        dateOfBirth,
        accountId,
        ownerAccountId,
        contactId,
        organizationName,
        canShopFor,
        isCe,
        shippingAddress,
      }),
    );
    return formattedContacts;
  };

  const handleOuterClick = (field: string) => {
    setIsHostNameMenuOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <AutoCompleteContainer item>
        <AutoCompleteV2
          validate={false}
          autoFocus={true}
          id="contactNameDropDown"
          inputId="fullName"
          isWithinDialog={true}
          placeholder="Search existing contacts"
          isSubmitting={isSubmitting}
          value={searchValue}
          touched={touched.fullName}
          isMenuOpen={isHostNameMenuOpen}
          handleInputChange={handleInputChange}
          handleSelectChange={handleCustomerSelected}
          handleChange={handleHostNameChange}
          handleOuterClick={handleOuterClick}
          objectPredictions={formatForAutoComplete(contacts)}
          handleNewCustomerOpen={toggleAddCustomer}
        />
      </AutoCompleteContainer>
      <Grid item container>
        <Grid container alignItems="center" spacing={1} justifyContent="center">
          <HorizontalLineContainer item>
            <HorizontalLine />
          </HorizontalLineContainer>

          <Grid item>
            <OrText variant="custom" default={TEXT_DS.TITLE_UPPER_20}>
              OR
            </OrText>
          </Grid>
          <HorizontalLineContainer item>
            <HorizontalLine />
          </HorizontalLineContainer>
        </Grid>
      </Grid>
      <Grid container item>
        <NewCustomerButton>
          <StyledAddNewButton
            type="secondary"
            fullWidth
            disableRipple
            onClick={toggleAddCustomer}
          >
            <CustomerTextContainer>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <AddCircleIcon />
                </Grid>
                <Grid item>ADD NEW CONTACT</Grid>
              </Grid>
            </CustomerTextContainer>
          </StyledAddNewButton>
        </NewCustomerButton>
      </Grid>
    </Grid>
  );
}
