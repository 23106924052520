import React from 'react';

import { theme } from '@onehope/design-system-v2';

const urlInTemplateStyles = {
  color: theme.palette.forestGreen.main,
  fontSize: '18px',
  fontFamily: theme.bodyFontFamily,
  fontStyle: 'normal',
  fontWeight: 420,
  lineHeight: '27px',
  letterSpacing: '0.01em',
  textDecorationLine: 'underline',
  paddingTop: '16px',
};

const ReachOutCarouselBodyDisplay = ({ body }: { body: string }) => {
  const cardBodyTextArray = body.split(`{{ $REACHOUT_URL }}`);

  return (
    <>
      {cardBodyTextArray.map(
        (text, index): React.ReactElement => {
          return (
            <>
              {index !== cardBodyTextArray.length - 1 ? (
                <>
                  {text.replace(/{{ .* }}/g, `[____]`)}
                  <div style={urlInTemplateStyles}>[Link]</div>
                </>
              ) : (
                <>{text.replace(/{{ .* }}/g, `[____]`)}</>
              )}
            </>
          );
        },
      )}
    </>
  );
};

export default ReachOutCarouselBodyDisplay;
