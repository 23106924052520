import React from 'react';
import { Button, Grid, makeStyles } from '@onehope/design-system-v2';

const useStyles = makeStyles(() => ({
  image: { maxWidth: '100%', paddingBottom: '40px' },
  buttonContainer: {
    width: '288px',
  },
  button: {
    marginBottom: '24px',
  },
}));

export default function CropImage(props: any) {
  const { canvas, setImageUploadOpen, onCancel } = props;
  const classes = useStyles();

  return (
    <div>
      <img src={canvas} alt="event" className={classes.image} />
      <Grid
        container
        wrap="nowrap"
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            className={classes.button}
            onClick={setImageUploadOpen}
          >
            crop
          </Button>
          <Button fullWidth type="tertiary" onClick={onCancel}>
            cancel
          </Button>
        </div>
      </Grid>
    </div>
  );
}
