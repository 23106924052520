import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { FormValues } from './FormTypes';
import { EventHostingTypeEnum } from '../../../EventsV2/eventTypes';

interface CreditOption {
  id: string;
  key: string;
  label: string;
}

export const hasAvailableCredits = ({
  numberOfAvailableCredits,
  numCreditsUsed,
}: {
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
}): boolean => {
  const creditsAvailableForEvent = numberOfAvailableCredits + numCreditsUsed;
  return creditsAvailableForEvent > 0;
};

export const optionsMap = {
  '0': {
    hostKitQuantity: 1,
    hostKitCredits: 0,
    starterKitCredits: 0,
  },
  '1': {
    hostKitQuantity: 2,
    hostKitCredits: 0,
    starterKitCredits: 0,
  },
  '2': {
    hostKitQuantity: 1,
    hostKitCredits: 1,
    starterKitCredits: 0,
  },
  '3': {
    hostKitQuantity: 2,
    hostKitCredits: 1,
    starterKitCredits: 0,
  },
  '4': {
    hostKitQuantity: 2,
    hostKitCredits: 2,
    starterKitCredits: 0,
  },
  NO_WINE: {
    hostKitQuantity: 0,
    hostKitCredits: 0,
    starterKitCredits: 0,
  },
  'STARTER-KIT': {
    hostKitQuantity: 0,
    hostKitCredits: 0,
    starterKitCredits: 1,
  },
};

export const getCreditOptions = ({
  numberOfAvailableCredits,
  numCreditsUsed,
  useCredit,
  starterKitCredits,
}: {
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
  useCredit: string;
  starterKitCredits?: number;
}): CreditOption[] | null => {
  const bottlesPriceStart6 = 49;
  const bottlesPriceStart12 = 98;
  const bottlesPriceStart12WithCredit = 49;

  if (
    !Number.isInteger(numberOfAvailableCredits) ||
    !Number.isInteger(numCreditsUsed) ||
    !useCredit
  ) {
    return [
      {
        id: '0',
        key: '2',
        label: `6 bottles (price starts at $${bottlesPriceStart6})`,
      },
      {
        id: '1',
        key: '3',
        label: `12 bottles (price starts at $${bottlesPriceStart12})`,
      },
      {
        id: 'NO_WINE',
        key: '0',
        label: `I don't need wine`,
      },
    ];
  }

  const creditsAvailableForEvent = numberOfAvailableCredits + numCreditsUsed;

  if (creditsAvailableForEvent === 1) {
    if (useCredit === 'NO') {
      return [
        {
          id: '0',
          key: '0',
          label: `6 bottles (price starts at $${bottlesPriceStart6})`,
        },
        {
          id: '1',
          key: '1',
          label: `12 bottles (price starts at $${bottlesPriceStart12})`,
        },
      ];
    }
    return [
      {
        id: '2',
        key: '2',
        label: '6 bottles (1 credit - tasting is free!)',
      },
      {
        id: '3',
        key: '3',
        label: `12 bottles (1 credit - price starts at $${bottlesPriceStart12WithCredit})`,
      },
    ];
  }
  if (creditsAvailableForEvent > 1) {
    if (useCredit === 'NO') {
      return [
        {
          id: '0',
          key: '0',
          label: `6 bottles (price starts at $${bottlesPriceStart6})`,
        },
        {
          id: '1',
          key: '1',
          label: `12 bottles (price starts at $${bottlesPriceStart12})`,
        },
      ];
    }
    return [
      { id: '2', key: '2', label: '6 bottles (1 credit - tasting is free!)' },
      {
        id: '3',
        key: '3',
        label: `12 bottles (1 credit - price starts at $${bottlesPriceStart12WithCredit})`,
      },
      { id: '4', key: '4', label: '12 bottles (2 credits - tasting is free!)' },
    ];
  }
  return [
    {
      id: '0',
      key: '2',
      label: `6 bottles (price starts at $${bottlesPriceStart6})`,
    },
    {
      id: '1',
      key: '3',
      label: `12 bottles (price starts at $${bottlesPriceStart12})`,
    },
    {
      id: 'NO_WINE',
      key: '0',
      label: `I don't need wine`,
    },
  ];
};

export const getCreditText = (
  creditOptions: CreditOption[] | null,
  hostKitCredits: string,
): string => {
  if (!hostKitCredits || !creditOptions) return 'None';
  const option = creditOptions.filter((option) => option.id === hostKitCredits);
  return option.length ? option[0].label : '';
};

export function getWineDetailsToSubmit(
  values: FormValues,
  trinityPartyType: string,
  createDate: string | null,
): any {
  const { wineNeeded, useCredit } = values;

  // if (values.hostKitCredits === 'STARTER-KIT') {
  //   return {
  //     hostKitQuantity: 0,
  //     hostKitCredits: 0,
  //     hostKitPurchaser: null,
  //     starterKitCredits: 1,
  //     isPurchasingTastingFlights: null,
  //   };
  // }
  if (
    wineNeeded === 'YES' ||
    useCredit !== 'NO_WINE' ||
    values.hostKitCredits === 'NO_WINE'
  ) {
    const { hostKitCredits, hostKitQuantity, starterKitCredits } = optionsMap[
      values.hostKitCredits
    ];
    return {
      hostKitQuantity,
      hostKitCredits,
      hostKitPurchaser:
        values.hostKitCredits === 'NO_WINE' || starterKitCredits == 1
          ? null
          : values?.hostKitPurchaser
          ? values.hostKitPurchaser
          : 'CE',
      starterKitCredits,
      isPurchasingTastingFlights: null,
    };
  }
  if (wineNeeded === 'NO' || useCredit === 'NO_WINE') {
    return {
      hostKitQuantity: 0,
      hostKitCredits: 0,
      hostKitPurchaser: null,
      starterKitCredits: 0,
      isPurchasingTastingFlights: null,
    };
  }

  return {
    hostKitQuantity: null,
    hostKitCredits: null,
    hostKitPurchaser: null,
    starterKitCredits: null,
    isPurchasingTastingFlights: null,
  };
}

export const getWineNeeded = (
  hostKitQuantity: number | null,
  starterKitCredits: number | null,
  hostKitPurchaser: string | null,
) => {
  // If they entered any hostKitQuantity before launch,
  // hostKitPurchaser would be null and we'd show
  // "already ordered" screen. After launch, if hostKitQuantity
  // is entered, hostKitPurchaser would never be null.
  if (hostKitQuantity && !hostKitPurchaser) {
    return 'ALREADY-ORDERED';
  }
  switch (hostKitQuantity) {
    case 0:
      if (starterKitCredits === 1) {
        return 'STARTER-KIT';
      }
      return 'NO';
    case 1:
      return 'YES';
    case 2:
      return 'YES';
    default:
      return 'SKIP';
  }
};

const getHostKitQuantity = (
  hostKitQuantity: number | null,
  hostKitPurchaser: string | null,
) => {
  // Clear the hostKitQuantity if they entered any hostKitQuantity
  // before launch date for when they edit Wine details
  if (!hostKitQuantity || (hostKitQuantity && !hostKitPurchaser)) return '';
  return (hostKitQuantity * 6).toString();
};

const getHostKitPurchaser = () => {
  if (localStorage.getItem('hostType') === EventHostingTypeEnum.Self)
    return 'CE';
  return localStorage.getItem('hostKitPurchaser') || '';
};

export const getDefaultValues = (event: EventType) => {
  return {
    wineNeeded: getWineNeeded(
      event.hostKitQuantity,
      event.starterKitCredits,
      event.hostKitPurchaser,
    ),
    hostKitQuantity: getHostKitQuantity(
      event.hostKitQuantity,
      event.hostKitPurchaser,
    ),
    hostKitCredits:
      event?.hostKitCredits || event?.hostKitCredits === 0
        ? event.hostKitCredits?.toString()
        : '',
    hostKitPurchaser: event?.hostKitPurchaser ?? '',
  };
};

export const getInitialValues = () => {
  return {
    wineNeeded: localStorage.getItem('wineNeeded') || '',
    hostKitQuantity: localStorage.getItem('hostKitQuantity') || '',
    hostKitCredits: localStorage.getItem('hostKitCredits') || '',
    hostKitPurchaser: getHostKitPurchaser(),
    useCredit: localStorage.getItem('useCredit') || '',
  };
};
