// material-ui
import { styled, Box, ThemeType } from '@onehope/design-system-v2';

// ==============================|| DRAWER HEADER - STYLED ||============================== //

interface Props {
  theme: ThemeType;
  open: boolean;
}

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme }: Props) => ({
  display: 'flex',
  alignItems: 'center',
  height: 96,
  justifyContent: 'center',
}));

export default DrawerHeaderStyled;
