import Layout from 'antd/lib/layout';
import 'antd/lib/layout/style/index.css';
import styled from '@emotion/styled';

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const MainLayout = styled(Layout)`
  background-color: white;
` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Content = styled(Layout.Content)`
  background-color: white;
` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Sider = styled(Layout.Sider)`` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Header = styled(Layout.Header)`` as any;
