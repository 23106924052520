import React, { useState } from 'react';
import styled from '@emotion/styled';
import AntCollapse from 'antd/lib/collapse';
import { ExpandIconPosition } from 'antd/lib/collapse/Collapse';
import 'antd/lib/collapse/style/index.css';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { primaryColor, font, textColor, neutral13 },
  antDesignStyles,
} = styles;
const {
  icon: { Icon },
} = antDesignStyles;
const { Panel } = AntCollapse;

interface CollapseProps {
  header: string;
  children: any;
  panelKey?: string;
  defaultActive?: boolean;
  accordion?: boolean;
  destroyInactivePanel?: boolean;
  onChange?: (key: string | string[]) => void;
  style?: React.CSSProperties;
  className?: string;
  bordered?: boolean;
  prefixCls?: string;
  expandIcon?: (panelProps: any) => React.ReactNode;
  expandIconPosition?: ExpandIconPosition;
}

export default function Collapse(props: CollapseProps) {
  const { defaultActive = false } = props;
  const panelKey = props.panelKey || '1';
  const panelKeys = {
    on: panelKey,
    off: '0',
  };
  const defaultActiveKey = defaultActive ? panelKeys.on : panelKeys.off;
  const [active, setActive] = useState(defaultActive);
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const toggle = () => {
    if (active) {
      setActiveKey(panelKeys.off);
    } else {
      setActiveKey(panelKeys.on);
    }
    setActive(!active);
  };

  const { children, header, ...rest } = props;

  type CollapseWrapperProps = {
    active?: boolean;
    onClick: () => void;
  };

  const CollapseWrapper = styled.div<CollapseWrapperProps>`
    font-family: ${font};
    color: ${textColor};
    z-index: 1000;
    &:hover {
      cursor: pointer;
    }
    .ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      border-bottom: ${({ active }: CollapseWrapperProps) =>
        active ? `1px solid ${primaryColor}` : `1px solid ${neutral13}`};
      padding-left: 0;
    }
    .ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      font-family: ${font};
      color: ${primaryColor};
      font-size: 14px;
      line-height: 1.5;
      padding-left: 0;
      margin-top: 24px;
    }
    .ant-collapse-borderless > .ant-collapse-item {
      border: none;
    }
    ul {
      margin-top: 0;
      margin-left: 16px;
    }
  `;
  return (
    <CollapseWrapper active={active} onClick={toggle}>
      <AntCollapse
        {...rest}
        bordered={false}
        expandIconPosition="right"
        activeKey={activeKey}
        expandIcon={() => (
          <Icon type={active ? 'minus' : 'plus'} rotate={active ? 360 : 0} />
        )}
      >
        <Panel header={header} key={panelKey}>
          {children}
        </Panel>
      </AntCollapse>
    </CollapseWrapper>
  );
}
