import * as React from 'react';
import styled from '@emotion/styled';
import { Text } from '@onehope/design-system-v2';

import EventCreateDialog from '../../../Dialog/EventCreateDialog/EventCreateDialog';
import { CEDash_EventsV2Query_viewer_user as UserType } from '../../../../queries/generatedTypes/CEDash_EventsV2Query';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 0 16px;
  flex-direction: column;
  text-align: center;
  @media all and (min-width: 768px) {
    padding: 24px 40px 0 40px;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  padding-bottom: 24px;
  @media all and (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const HeaderTitle = styled(Text)`
  && {
    font-weight: 300;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.03em;
  }
`;

function isEventPage() {
  if (typeof window === 'undefined') return false;
  return window.location.pathname.includes('event');
}

interface HeaderProps {
  handleEventCreateOpen: () => void;
  handleEventCreateClose: () => void;
  isEventCreateOpen: boolean;
  createEventStep: string | string[] | null | undefined;
  user: UserType;
}
export default function Header(props: HeaderProps) {
  const {
    createEventStep,
    isEventCreateOpen,
    handleEventCreateOpen,
    handleEventCreateClose,
  } = props;

  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderTitle variant="h3">All Events</HeaderTitle>
        <ButtonsWrapper>
          <ButtonContainer>
            <EventCreateDialog
              user={props?.user}
              createEventStep={createEventStep}
              isEventCreateOpen={isEventCreateOpen}
              handleEventCreateOpen={handleEventCreateOpen}
              handleEventCreateClose={handleEventCreateClose}
            />
          </ButtonContainer>
        </ButtonsWrapper>
      </HeaderContainer>
    </React.Fragment>
  );
}
