/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import {
  makeStyles,
  Text,
  ThemeType,
  GridV2 as Grid,
  TEXT_DS,
  Icons,
} from '@onehope/design-system-v2';
// Use old styles to match rest of event creation flow
import { MuiToolTip } from '@onehope/design-system-ohw';
import useToggle from '../../../utils/useToggleHook';

import ImageUploadDialog from '../../Dialog/ImageUploadDialog';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';

import UpdateImage from './UpdateImage';
import CropImage from './CropImage';
import DragAndDrop from './DragAndDrop';
import { getDefaultEventImage } from '../../../utils/utils';
import TIPS from './tipsConfig';

const textConfig = {
  upload: {
    header: 'Choose a fundraiser photo',
    body: 'Add an image that tells the story of your event.',
  },
  crop: {
    header: 'Crop photo',
    body: 'Please use the cropping tool to crop your photo.',
  },
};

const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  tabContent: {
    maxWidth: '432px',
    width: '100%',
    margin: 'auto',
    padding: '0 0 156px 0',
    ['@media (min-width: 768px)']: {
      padding: '40px 0 156px 0',
    },
  },
  subtext: {
    padding: '18px 0 24px 0',
    [theme.breakpoints.up('md')]: {
      padding: '40px 0',
    },
  },
  centeredText: { textAlign: 'center' },
  toolTipStyles: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageUploadTipsContainer: {
    marginTop: 50,
    width: '100%',
    padding: 24,
    textAlign: 'center',
    backgroundColor: theme.palette.bronzeTint.main,
    borderRadius: 4,
  },
}));

export default function UploadImageForm(props: any) {
  const {
    handlePreviousStepChange,
    editing,
    event,
    isWineTasting,
    loading,
    loadingEventCreation,
    setIsImageUpdated,
    isOnline,
  } = props;
  const [image, setImage] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [error, setError] = useState('');
  const [canvas, setCanvas] = useState('');
  const eventType = isWineTasting
    ? isOnline
      ? 'virtual'
      : 'inPerson'
    : 'fundraiser';
  const defaultImageUrl = getDefaultEventImage({ eventType });
  const classes = useStyles();

  const {
    value: imageUploadOpen,
    setTrue: setImageUploadOpen,
    setFalse: setImageUploadClosed,
  } = useToggle();

  const onCancel = () => {
    setImage('');
    setCanvas('');
    setMimeType('');
  };

  const onFileDrop = useCallback(
    (acceptedFiles: any, fileRejections: any) => {
      if (acceptedFiles?.[0]) {
        const imageFile = acceptedFiles[0];
        const fileType = imageFile?.type?.split('/')[1];
        setMimeType(fileType);
        setImage(imageFile);
        return setImageUploadOpen();
      }

      if (fileRejections?.[0]) {
        return setError(fileRejections?.[0]?.errors?.[0]?.message);
      }

      setError('Something went wrong, please try again');
    },
    [setImageUploadOpen],
  );

  const handleNextStepChange = async () =>
    await props.handleNextStepChange({ imageCanvas: canvas, mimeType });

  const onUpload = (imageCanvas: string) =>
    props.onUpload({ imageCanvas, mimeType });

  return (
    <div>
      <ImageUploadDialog
        open={imageUploadOpen}
        onClose={setImageUploadClosed}
        image={image}
        mimeType={mimeType}
        setImage={setImage}
        setCanvas={setCanvas}
        onUpload={editing ? onUpload : undefined}
      />
      {editing ? (
        <UpdateImage
          onFileDrop={onFileDrop}
          event={event}
          loading={loading}
          setIsImageUpdated={setIsImageUpdated}
        />
      ) : (
        <div className={classes.container}>
          <div className={classes.tabContent}>
            <Text variant="h3">
              {canvas ? textConfig.crop.header : textConfig.upload.header}
            </Text>
            <Grid container justifyContent="space-between" alignItems="center">
              <div className={classes.subtext}>
                {canvas ? textConfig.crop.body : textConfig.upload.body}
              </div>
              <MuiToolTip
                title={'We recommend using a photo that is 720 x 442 pixels.'}
                arrow
                placement="top"
                enterDelay={300}
                leaveDelay={200}
                className={classes.toolTipStyles}
                style={{
                  marginTop: -2,
                  marginRight: 8,
                }}
              />
            </Grid>
            <div className={classes.centeredText}>
              {canvas ? (
                <CropImage
                  canvas={canvas}
                  onCancel={onCancel}
                  setImageUploadOpen={setImageUploadOpen}
                />
              ) : (
                <DragAndDrop
                  defaultImageUrl={defaultImageUrl}
                  onFileDrop={onFileDrop}
                  handleNextStepChange={handleNextStepChange}
                />
              )}
            </div>
            <Grid
              container
              justifyContent="center"
              className={classes.imageUploadTipsContainer}
            >
              <Grid item xs={12} sx={{ pb: 1 }}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item sx={{ pr: 1 }}>
                    <Icons.CeDashStars />
                  </Grid>
                  <Text variant="custom" default={TEXT_DS.OVERLINE_SEMIBOLD_14}>
                    Image Upload Tips:
                  </Text>
                  <Grid item sx={{ pl: 1 }}>
                    <Icons.CeDashStars />
                  </Grid>
                </Grid>
              </Grid>
              {TIPS.map(tip => (
                <Grid key={tip.key} item xs={12} sx={{ pb: 1 }}>
                  <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                    {tip.text}
                  </Text>
                </Grid>
              ))}
            </Grid>
            {error && <div>{error}</div>}
            <FooterButtons
              prevStepButtonText="BACK"
              nextStepButtonText={isWineTasting ? 'NEXT' : 'BOOK EVENT'}
              handleNextStepChange={handleNextStepChange}
              loadingEventCreation={loadingEventCreation}
              handlePrevStepChange={handlePreviousStepChange}
              disableNext={loadingEventCreation || (!!image && !canvas)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
