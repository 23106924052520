import gql from 'graphql-tag';

const REACH_OUT_V2 = gql`
  query CEDash_ReachOutV2($id: String!) {
    viewer {
      user {
        id
        userId
      }
      v2 {
        reachOutTemplate(id: $id) {
          id
          reachOutId
          previewUrl
          name
          fullTitle
          coverImageUrl
          verticalImageUrl
          muxVideo {
            playbackId
          }
          expirationDate
          description
          isActive
          resultsAvailable
          isEligible
          helpCenterArticleUrl
          allowCopyLink
          isFeatured
          allowSaveAssets
          downloadUrls {
            featuredImage
            verticalImage
            muxVideo
          }
          category {
            name
            contentfulEntryId
            templateCount
          }
          shareConfigurations {
            medium
            body
            subject
            url
          }
          viewCounts {
            medium
            viewCount
          }
        }
      }
    }
  }
`;

export default REACH_OUT_V2;
