import gql from 'graphql-tag';

const mutation = gql`
  mutation LoginTrinitySsoMutation($input: TrinitySsoUrlGetInput!) {
    loginTrinitySso(input: $input) {
      redirectUrl
    }
  }
`;

export default mutation;
