import React from 'react';
import { Text, makeStyles, Grid, ThemeType } from '@onehope/design-system-v2';

const styles = makeStyles((theme: ThemeType) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '500px',
    [theme.breakpoints.down('md')]: {
      height: '480px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '482px',
    },
  },
  loadingWrapper: {
    font: theme.bodyFontFamily,
    fontSize: '24px',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
}));

const CardsLoading = () => {
  const classes = styles();
  return (
    <Grid className={classes.wrapper}>
      <Text className={classes.loadingWrapper}>Loading...</Text>
    </Grid>
  );
};

export default CardsLoading;
