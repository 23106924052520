import Decimal from 'decimal.js';
import React from 'react';
import {
  GridV2 as Grid,
  styled,
  Text,
  useTheme,
  TEXT_DS,
} from '@onehope/design-system-v2';

// utils
import { CEDash_FastStartQuery_viewer_v3_fastStart23 as FastStartData } from '../../../queries/generatedTypes/CEDash_FastStartQuery';
import { fNumber, fCurrency } from '../../../utils/formatNumber';
import { Currency } from '@onehope/design-system-ohw';
interface Props {
  fastStartData: FastStartData;
}

const PrimaryHeading = styled(Text)(({ theme }) => ({
  color: theme.palette.greyDark.main,
}));

export default function Footer({ fastStartData }: Props) {
  const theme = useTheme();
  const newCes = fNumber(fastStartData?.sponsorshipTotal);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <PrimaryHeading
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
          theme={theme}
        >
          Sales Total
        </PrimaryHeading>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_20}>
          <Currency
            rawValue={new Decimal(fastStartData?.salesTotal ?? 0)
              .dividedBy(100)
              .toNumber()}
          />
        </Text>
      </Grid>
      <Grid item>
        <PrimaryHeading
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
          theme={theme}
        >
          New CEs
        </PrimaryHeading>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_20}>
          {newCes}
        </Text>
      </Grid>
      <Grid item>
        <PrimaryHeading
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
          theme={theme}
        >
          Bonus Total
        </PrimaryHeading>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_20}>
          <Currency
            rawValue={new Decimal(fastStartData?.bonusTotal ?? 0)
              .dividedBy(100)
              .toNumber()}
          />
        </Text>
      </Grid>
    </Grid>
  );
}
