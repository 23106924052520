import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { styles as s } from '@onehope/design-system';
import { Text, theme } from '@onehope/design-system-v2';
const { cssConstants } = s;

//#region Styles

const Container = styled(Text)`
  padding: 0 16px;
  color: ${theme.palette.greyDark.main};
  font-weight: 420;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  a {
    text-decoration: none;
    color: ${theme.palette.greyDark.main};
  }
  a:hover {
    text-decoration: underline;
  }
  @media only screen and (min-width: 768px) {
    padding: 0 40px 16px 40px;
    margin-top: -16px;
  }
`;

const Current = styled.span`
  color: ${cssConstants.textColor};
  font-weight: bold;
`;

/** 🥕 */
const Carrot = styled.span`
  padding: 0 8px;
`;

//#region HTML

const getBreadCrumbs = (
  path: string,
  contactId?: string | string[] | null | undefined,
) => {
  switch (path) {
    case 'events':
      return <Container>Events</Container>;
    case 'event':
      return (
        <Container>
          <Link to="/events">Events</Link>
          <Carrot>&gt;</Carrot>
          <Current>Manage Event</Current>
        </Container>
      );
    case 'contact':
      return (
        <Container>
          <Link to="/contacts">Contacts</Link>
          <Carrot>&gt;</Carrot>
          <Current>Contact details</Current>
        </Container>
      );
    case 'fourBottleSubscribers':
      return (
        <Container>
          <Link to="/contacts">Contacts</Link>
          <Carrot>&gt;</Carrot>
          <Current>4-bottle subscribers</Current>
        </Container>
      );
    case 'eventFromContact':
      return (
        <Container>
          <Link to="/contacts">Contacts</Link>
          <Carrot>&gt;</Carrot>
          <Link to={`/contact?contactId=${contactId}`}>Contact details</Link>
          <Carrot>&gt;</Carrot>
          <Current>Manage Event</Current>
        </Container>
      );
    default:
      return <div />;
  }
};

//#region Component

export default function BreadCrumbs({
  location,
  contactId,
}: {
  location: string;
  contactId?: string | string[] | null | undefined;
}) {
  return getBreadCrumbs(location, contactId);
}
