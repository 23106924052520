import { css } from '@emotion/core';

export const myMentorComingSoonLogo = css`
  width: 79px;
  height: 79px;
  object-fit: contain;
  @media only screen and (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
`;
