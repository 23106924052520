import styled from '@emotion/styled';
import { Text } from '@onehope/design-system-v2';

export const BalanceContainer = styled.div<{ editing: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${props => (props.editing ? '24px' : '40px')};
  margin-bottom: 40px;
`;

export const BalanceHeader = styled(Text)`
  margin-right: 4px;
`;
