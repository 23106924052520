import React, { useEffect, Fragment, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Grid,
  LoaderDark,
  makeStyles,
  ThemeType,
} from '@onehope/design-system-v2';
import { NeedSomeHelp } from '@onehope/account';
import { PwpOffer, PwpOptions } from '@onehope/pwp-offer';

import ChangeShipmentDate from '../PreferencesView/ChangeShipmentDate';
import AddOns from './AddOns';
import WinePackItems from './WinePackItems';
import WinePackSummary from './WinePackSummary';
import WarningCard from './WarningCard';

import { getWinePackItems } from '../SwapWinesDialog';
import { saveProductOrdering } from '../../utils/helpers';
import useToggle from '../../utils/useToggleHook';
import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../utils/segment';

import GET_DISCOUNTS_QUERY from '../../queries/Discounts';
import NEXT_SHIPMENT_ADDONS from '../../queries/AutoshipNextShipmentAddons';
import AUTOSHIP_UPDATE_MUTATION from '../../mutations/AutoshipUpdateMutation';
import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_userInfo,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import { WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify } from '../../queries/generatedTypes/WineClub_SegmentIdentifyGetQuery';
import {
  WineClub_DiscountsQuery,
  WineClub_DiscountsQueryVariables,
} from '../../queries/generatedTypes/WineClub_DiscountsQuery';
import {
  WineClub_AutoShipUpdateMutation,
  WineClub_AutoShipUpdateMutationVariables,
} from '../../mutations/generatedTypes/WineClub_AutoShipUpdateMutation';
import {
  WineClub_AutoshipNextShipmentAddons,
  WineClub_AutoshipNextShipmentAddonsVariables,
} from '../../queries/generatedTypes/WineClub_AutoshipNextShipmentAddons';

const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    maxWidth: 288,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 460,
    },
  },
  loader: {
    paddingTop: theme.spacing(2),
  },
  bronzeTintBar: {
    borderBottom: `1px solid ${theme.palette.bronzeTint.main}`,
    marginBottom: 24,
  },
}));

export const getAutoShipPwpSelection = (
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip,
) => {
  if (subscription?.isDefaultPwP) {
    return PwpOptions.default;
  }
  if (!subscription?.isDefaultPwP && subscription?.nextShipmentIncludesPwP) {
    return PwpOptions.oneTime;
  }
  return null;
};

export const handleAutoShipPwpUpdate = ({
  autoShipId,
  autoShipPwP,
  AutoShipUpdateMutation,
  conciergeMode,
  currentAutoShipPwP,
  user,
  refetchSubscription,
  sendToTrackEventV2,
  setUpdateMutationComplete,
  setUpdateMutationLoading,
}: {
  autoShipId: string | null;
  autoShipPwP: PwpOptions | null;
  AutoShipUpdateMutation: any;
  conciergeMode: boolean;
  currentAutoShipPwP: PwpOptions | null;
  user: WineClub_AutoShipGetQuery_viewer_user;
  refetchSubscription: () => void;
  sendToTrackEventV2?: SendToTrackEventV2;
  setUpdateMutationComplete: () => void;
  setUpdateMutationLoading: () => void;
}) => {
  setUpdateMutationLoading();
  const variables = {
    autoShipId,
    input: {
      autoShip: {
        autoShipId,
        isDefaultPwP: autoShipPwP === 'default',
        nextShipmentIncludesPwP: !!autoShipPwP,
        pendingPwP: !!autoShipPwP,
      },
    },
  };
  return AutoShipUpdateMutation({
    variables,
  })
    .then(() => {
      // send to segment
      sendToTrackEventV2 &&
        sendToTrackEventV2({
          event: SubscriptionSegmentEvents.wineClubModified,
          properties: {
            concierge: conciergeMode,
            autoShipId,
            accountId: user?.userId,
            ceAccountId: conciergeMode ? user?.userId : '',
            propertyUpdated: SubscriptionSegmentProperty.addPwP,
            values: {
              previousValue: currentAutoShipPwP
                ? currentAutoShipPwP === PwpOptions.oneTime
                  ? 'Next Shipment'
                  : 'Default'
                : null,
              newValue: autoShipPwP
                ? autoShipPwP === PwpOptions.oneTime
                  ? 'Next Shipment'
                  : 'Default'
                : null,
            },
          },
        });
      setUpdateMutationComplete();
      refetchSubscription();
    })
    .catch(() => {
      setUpdateMutationComplete();
    });
};

type NextOrderViewProps = {
  segmentUser:
    | WineClub_AutoShipGetQuery_viewer_user
    | WineClub_AutoShipGetQuery_viewer_userInfo;
  segmentPersonas: WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify;
  user: WineClub_AutoShipGetQuery_viewer_user;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  refetchSubscription: () => void;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
};

export default function NextOrderView(props: NextOrderViewProps) {
  const { user, subscription, conciergeMode, sendToTrackEventV2 } = props;
  const classes = useStyles();
  const { pendingCuratedPack, nextPaymentDate } = subscription;
  const hasWinePackItems = !!subscription.winePackItemsSavings;
  const winePackEntries = subscription.winePackItemsSavings?.edges;

  const { data: discountData, loading } = useQuery<
    WineClub_DiscountsQuery,
    WineClub_DiscountsQueryVariables
  >(GET_DISCOUNTS_QUERY, {
    variables: {
      date: nextPaymentDate,
    },
  });

  // Wine Club add-ons
  const { data: addOnsData, loading: addOnsLoading } = useQuery<
    WineClub_AutoshipNextShipmentAddons,
    WineClub_AutoshipNextShipmentAddonsVariables
  >(NEXT_SHIPMENT_ADDONS, {
    variables: {
      autoShipId: subscription.autoShipId,
    },
  });

  const [AutoShipUpdateMutation] = useMutation<
    WineClub_AutoShipUpdateMutation,
    WineClub_AutoShipUpdateMutationVariables
  >(AUTOSHIP_UPDATE_MUTATION);

  const {
    setTrue: setUpdateMutationLoading,
    setFalse: setUpdateMutationComplete,
  } = useToggle();
  const [pwpQty, setPwpQty] = useState(
    getAutoShipPwpSelection(subscription) ? 1 : 0,
  );
  const [autoShipPwP, setAutoshipPwP] = useState(
    getAutoShipPwpSelection(subscription),
  );

  useEffect(() => {
    // save initial ordering of products
    if (!subscription.autoShipId) {
      return;
    }
    saveProductOrdering(
      getWinePackItems(winePackEntries),
      subscription.autoShipId,
    );
  }, []); // Runs only once because of empty dependency array

  useEffect(() => {
    // Scroll to the target element
    const urlParams = new URLSearchParams(window.location.search);
    const anchor = urlParams.get('anchor');
    if (anchor) {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  const showLoader = loading || addOnsLoading;

  const discounts = discountData?.viewer?.v2?.discounts;
  const wineClubAddonCount =
    addOnsData?.viewer?.user?.autoShip?.nextShipmentAddons.length ?? 0;

  const validPwpProduct = subscription?.pwpForShipmentDate?.edges?.length
    ? subscription.pwpForShipmentDate.edges[0]?.node
    : null;

  // PwP is locked when an invoice has been created for the current renewal period.
  // once the renewal period rolls over (~1 hour after the invoice is created), it will be editable again.
  const pwpOfferLocked = !subscription?.canChangePwP;

  const dispatchHandleAutoShipPwPUpdate = (selection: PwpOptions | null) => {
    handleAutoShipPwpUpdate({
      setUpdateMutationLoading,
      autoShipId: subscription.autoShipId,
      currentAutoShipPwP: getAutoShipPwpSelection(subscription),
      autoShipPwP: selection,
      AutoShipUpdateMutation,
      setUpdateMutationComplete,
      user,
      refetchSubscription: props.refetchSubscription,
      conciergeMode,
      sendToTrackEventV2,
    });
  };

  const handleAddPwP = () => {
    const newQty = pwpQty + 1;
    setPwpQty(newQty);
  };

  const handleRemovePwp = () => {
    const newQty = pwpQty - 1;
    setPwpQty(newQty);
    if (newQty === 0) {
      setAutoshipPwP(null);
      dispatchHandleAutoShipPwPUpdate(null);
    }
  };

  const handleUpdateAutoShipPwp = (selection: PwpOptions) => {
    setAutoshipPwP(selection);
    dispatchHandleAutoShipPwPUpdate(selection);
  };

  return (
    <Grid>
      {showLoader ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.loader}
        >
          <LoaderDark size={36} />
        </Grid>
      ) : (
        <Grid>
          <ChangeShipmentDate
            user={user}
            subscription={subscription}
            conciergeMode={conciergeMode}
            sendToTrackEventV2={sendToTrackEventV2}
          />
          <div className={classes.bronzeTintBar}></div>
          {pendingCuratedPack && (
            <Grid
              id="pwp-offer"
              className={autoShipPwP ? classes.container : ''}
            >
              <PwpOffer
                pwpProduct={validPwpProduct}
                handleAddPwpToCart={handleAddPwP}
                handleRemovePwpToCart={handleRemovePwp}
                quantity={pwpQty}
                setAutoShipPwP={handleUpdateAutoShipPwp}
                autoShipPwP={autoShipPwP}
                pwpOfferLocked={pwpOfferLocked}
                placeholderOffer={!validPwpProduct}
              />
              <WarningCard />
            </Grid>
          )}
          {hasWinePackItems && !pendingCuratedPack && (
            <Fragment>
              <WinePackItems
                segmentUser={props.segmentUser}
                segmentPersonas={props.segmentPersonas}
                user={user}
                addOnQty={wineClubAddonCount}
                refetchSubscription={props.refetchSubscription}
                subscription={subscription}
                // @ts-ignore
                autoShipId={subscription.autoShipId}
                handleUpdateAutoShipPwp={handleUpdateAutoShipPwp}
                handleAddPwP={handleAddPwP}
                handleRemovePwp={handleRemovePwp}
                validPwpProduct={validPwpProduct}
                autoShipPwP={autoShipPwP}
                pwpQty={pwpQty}
                conciergeMode={conciergeMode}
                sendToTrackEventV2={sendToTrackEventV2}
              />

              {addOnsData && !addOnsLoading && (
                <AddOns nextShipmentAddons={addOnsData} />
              )}
              <WinePackSummary
                subscription={subscription}
                discounts={discounts ?? undefined}
              />
              <NeedSomeHelp />
            </Fragment>
          )}
        </Grid>
      )}
    </Grid>
  );
}
