import gql from 'graphql-tag';

const GET_REACH_OUTS_INVITES = gql`
  query CEDash_ReachOutsV2InviteQuery(
    $limit: Int
    $offset: Int
    $reachOutId: String!
    $fullName: String
    $requireEmail: Boolean
    $requirePhone: Boolean
  ) {
    viewer {
      id
      user {
        userId
      }
      v2 {
        reachOutTemplate(id: $reachOutId) {
          id
          reachOutId
          name
          fullTitle
          coverImageUrl
          expirationDate
          description
          isActive
          category {
            name
            contentfulEntryId
            templateCount
          }
          shareConfigurations {
            medium
          }
          followupConfigurations {
            medium
          }
        }
        reachOutTemplates {
          shareList(
            reachOutId: $reachOutId
            limit: $limit
            offset: $offset
            nameSearch: $fullName
            requireEmail: $requireEmail
            requirePhone: $requirePhone
          ) {
            simpleContact {
              contactId
              firstName
              lastName
              emailAddress
              phoneNumber
            }
            reachOutRequest {
              reachOutId
              requestId
              lastModifiedDate
              shareMedium
              status
              viewCount
            }
          }
        }
      }
    }
  }
`;

export default GET_REACH_OUTS_INVITES;
