// import React from 'react';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';
const { cssConstants: { font, primaryColor, textColor, neutral34 } } = styles;

export const baseStyle = `color: #000000;
  font-family: ${font};`;

export const EmojiHeader = styled.div`
  ${baseStyle};
  text-align: center;
  font-family: AppleColorEmoji;
  font-size: 80px;
  line-height: 1.31;
  margin-bottom: 24px;
`;

export const SpacerButtonHorizontal = styled.span`padding: 0 8px;`;
export const SpacerButtoVertical = styled.div`padding: 0 8px;`;

export const LoginHeader = styled.div`
  ${baseStyle};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.31;
  margin-bottom: 24px;
`;

export const LoginText = styled.div`
  ${baseStyle};
  color: ${textColor};
  font-family: ${font};
  font-size: 16px;
  font-weight: 300;
  line-height: 1.44;
  margin-bottom: 24px;
`;

export const InputHeader = styled.label`
  ${baseStyle};
  color: ${neutral34};
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.64;
  display: block;
`;

export const InputForgotPasswordLink = styled.span`
  ${baseStyle};
  color: ${primaryColor};
  font-size: 11px;
  line-height: 1.64;
  font-weight: 300;
  float: right;
  text-decoration: underline;
  padding-top: 6px;
  &:hover {
    cursor: pointer;
  }
`;

export const InputCheckShowPassword = styled.span`
  ${baseStyle};
  color: ${primaryColor};
  font-size: 14px;
  line-height: 1.57;
  font-weight: 300;
  float: left;
  padding-top: 6px;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.div`margin-top: 40px;`;

export const InputVerticalSpacer = styled.div`height: 20px;`;
