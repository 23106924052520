import { UserType } from '../stateManagement/types';
import queryString from 'query-string';
import { OH_FOUNDATION_TAX_ID } from './constants';

export const formatIRSNonProfits = (data: any) => {
  return data?.viewer?.v2?.nonProfits?.edges?.map(
    (nonProfit: any) => nonProfit.node,
  );
};

export const formatNonProfitEdits = (data: any) => {
  return data?.viewer?.v2?.nonProfitEdits?.edges?.map(
    (nonProfitEdit: any) => nonProfitEdit.node,
  );
};

export function ceProfileImage(image?: string, urlProfileImg?: string) {
  return image && urlProfileImg ? `${urlProfileImg}/${image}` : '';
}

export function getCurrentUser(parentUser: UserType) {
  const isConciergeMode = !!parentUser?.conciergeCustomerAccountId;
  return isConciergeMode ? parentUser?.conciergeUser : parentUser;
}

export const getEventImage = (
  photo: {
    groupName: string;
    fileName: string;
  },
  urlEventImgUploads: string,
) => {
  if (!photo) return null;
  return `${urlEventImgUploads}/${photo.groupName}/${photo.fileName}`;
};

export function isEventCeDashPage() {
  if (typeof window === 'undefined') return false;
  const { createEventStep, tab } = queryString.parse(window.location.search);
  return createEventStep === 'details' || tab === 'charity';
}

export function isOHFoundationNpo(nonProfitTaxId: string | null | undefined) {
  return nonProfitTaxId === OH_FOUNDATION_TAX_ID;
}
