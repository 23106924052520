// material-ui
import React from 'react';
import { useTheme, Icons } from '@onehope/design-system-v2';
// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
const { OHDashLogo } = Icons;
// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <a href="https://www.onehopewine.com" target="_blank">
      <DrawerHeaderStyled theme={theme} open={open}>
        <OHDashLogo />
      </DrawerHeaderStyled>
    </a>
  );
};

export default DrawerHeader;
