import React from 'react';
import {
  ButtonBase,
  Grid,
  Icons,
  Text,
  Button,
  ChunkyButton,
} from '@onehope/design-system-v2';

import {
  CauseOfChoiceAppDictionary,
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

import { useCommonStyles } from '../../../utils/commonStyles';
import { getCurrentUser } from '../../../utils/helper';

export default function NonProfitConfirmation() {
  const commonStyles = useCommonStyles();
  const {
    handleCloseModal,
    user: parentUser,
    onContinue,
    app,
    handleGoToShop,
  } = useCauseOfChoiceNetworkLayerValue();
  const [, dispatch] = useCauseOfChoiceContextValue();
  const user = getCurrentUser(parentUser);
  const guestModeNonProfit = user?.cartV2?.guestNonProfit;
  const nonProfit = guestModeNonProfit || user?.selectedCause?.nonProfit;

  const isMegalith = app === CauseOfChoiceAppDictionary.OHW_MEGALITH;
  const buttonText = isMegalith ? 'Shop' : onContinue ? 'Continue' : 'Close';
  const buttonOnClick = isMegalith
    ? handleGoToShop
    : onContinue
    ? onContinue
    : handleCloseModal;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN,
    });
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={commonStyles.navigation}
      >
        <ButtonBase onClick={handleGoBack}>
          <Grid xs item container alignItems="center" direction="row">
            <Icons.SmallLeftArrow />
            <Text className={commonStyles.backButtonText}>Back</Text>
          </Grid>
        </ButtonBase>
        <ButtonBase onClick={handleCloseModal}>
          <Icons.Close />
        </ButtonBase>
      </Grid>
      <Grid container>
        <Text className={commonStyles.labelText}>Your cause of choice is:</Text>
      </Grid>
      <ChunkyButton
        avatar={<Icons.COCHeartHands />}
        onChange={handleGoBack}
        onChangeText="Change"
        primaryText={`${nonProfit?.nonProfitName}`}
        secondaryText={`${nonProfit?.nonProfitAddress?.city}, ${nonProfit?.nonProfitAddress?.state}`}
        tertiaryText={`EIN: ${nonProfit?.nonProfitTaxId}`}
        headerIcon={nonProfit?.isIRSVerified ? <Icons.COCVerified /> : null}
      />
      <Grid container justifyContent="center">
        <Button
          fullWidth
          type="primary"
          className={commonStyles.saveAndClose}
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
        <Text className={commonStyles.italicText}>
          You can change your cause of choice at any point.
        </Text>
      </Grid>
    </Grid>
  );
}
