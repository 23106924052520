// @ts-ignore
import React from 'react';
import {
  SelectRegular,
  OptionRegular,
} from '../styles/ant-design-styles/select';

interface DropDownProps {
  kind: string;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  overlay: React.ReactNode | (() => React.ReactNode);
  onVisibleChange?: (visible: boolean) => void;
  visible?: boolean;
  disabled?: boolean;
  align?: object;
  getPopupContainer?: (triggerNode: Element) => HTMLElement;
  prefixCls?: string;
  className?: string;
  transitionName?: string;
  placement?: string;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  forceRender?: boolean;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  items: any;
  style: any;
  width: any;
}

const getOptions = (items: Array<any>) => {
  return items.map(item => <OptionRegular value={item}>{item}</OptionRegular>);
};

export default function Select(props: DropDownProps) {
  const { kind, items, style, width, ...rest } = props;
  const newStyle = { ...style, width };
  switch (kind) {
    case 'regular': {
      return (
        <SelectRegular style={newStyle} {...rest} className="deuce">
          {getOptions(items)}
        </SelectRegular>
      );
    }
    default:
      return (
        <SelectRegular style={newStyle} {...rest}>
          {getOptions(items)}
        </SelectRegular>
      );
  }
}
