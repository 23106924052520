/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactPlayer from 'react-player';

import {
  ActivityEntry,
  ButtonActionModal,
  CardDialog,
  FeedTypes,
  SegmentEvents as AESegmentEvents,
  getPlaybookSegmentProperties,
} from '@onehope/action-engine';

import {
  Dialog,
  GridV2 as Grid,
  useTheme,
  useMediaQuery,
  makeStyles,
  ThemeType,
  styled,
} from '@onehope/design-system-v2';

import MainCard from './MainCard';
import Greeting from './ActionEngine/Greeting';

import { useTrackingContextValue } from '../../contexts/TrackingContext';
import { segmentEvents } from '../../utils/segment/constants';
import { PlayBookActivityProps } from './ActionEngine/Cards';
import navigationHandler from './ActionEngine/navigationHandler';

import MyMentor from './MyMentor';
import MyTraining from './MyTraining';
import Cards from './ActionEngine/Cards';
import StatisticsContainer from './Statistics';
import CauseImpact from './CauseImpact';
import FastStartBonus from './FastStartBonus';
import MyCareTeam from './MyCareTeam';

import { CEDash_DashboardQuery_viewer_user } from '../../queries/generatedTypes/CEDash_DashboardQuery';
import getClient from '../../apollo-client';
import Iframe from './Iframe/Iframe';
import { widgetContainer, widgetContainerDouble } from './DashboardStyles';
import { getProgressPercentage } from './FastStartWidget';
import { DateTime, Interval } from 'luxon';

const SIDEBAR_WIDTH = 442;

export interface DashboardInfo {
  user: CEDash_DashboardQuery_viewer_user;
  ceImpactData: any;
  fastStartCheck: any;
}

interface DashboardProps {
  dashboardInfo: DashboardInfo;
  setRewardBadgeAnimationAmount: React.Dispatch<React.SetStateAction<number>>;
  setTriggerRewardBadgeAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  goToCardId?: string;
}

const styles = makeStyles((theme: ThemeType) => ({
  cardsMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      gridRow: 1,
    },
  },
  greetingMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  splitWidgets: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(360px, 1fr))',
    gridGap: '24px',
    flexFlow: 'row wrap',
    margin: '24px',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, minmax(320px, 1fr))',
      margin: '0px',
      rowGap: '24px',
    },
    marginBottom: '80px',
  },
  player: ({ ratio }: any) => ({
    width: ratio === '9:16' ? '360px' : '640px',
    height: ratio === '9:16' ? '640px' : '360px',
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    overflowY: 'hidden !important' as any,
    overflowX: 'hidden !important' as any,
  }),
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '640px !important',
    },
  },
  buttonActionModal: {
    height: 'auto',
    width: '460px',
  },
}));

const SideBarGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: SIDEBAR_WIDTH,
  },
}));

const WidgetContainerDouble = ({ Widget }: any) => (
  <div css={[widgetContainer, widgetContainerDouble]}>
    <Widget />
  </div>
);

const showFastStart = (end: string) => {
  const startDate = DateTime.now();
  const endDate = DateTime.fromISO(end);

  if (!startDate.isValid || !endDate.isValid) {
    console.error('invalid fast start dates found');
    return false;
  }

  const activeDiff = Interval.fromDateTimes(startDate, endDate);
  const inactiveDiff = Interval.fromDateTimes(endDate, startDate);

  const fastStartIsActive = activeDiff.isValid;
  const fastStartIsInActive = inactiveDiff.isValid;

  const activeSecondsLeft = fastStartIsActive
    ? Math.floor(activeDiff.length('seconds'))
    : null;

  const inactiveDaysLeft = fastStartIsInActive
    ? Math.floor(inactiveDiff.length('days'))
    : null;

  // return true if fast start is active or if fast start has expired no longer than 7 days (so that we can show a notification)
  return (
    (fastStartIsActive && activeSecondsLeft > 0) ||
    (fastStartIsInActive && inactiveDaysLeft < 7)
  );
};

export default function Dashboard({
  dashboardInfo,
  setRewardBadgeAnimationAmount,
  setTriggerRewardBadgeAnimation,
  goToCardId,
}: DashboardProps) {
  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const gridSpacing = isTablet ? 3 : 2;
  const [openDialog, setOpenDialog] = useState(false);
  const [playbackId, setPlaybackId] = useState('');
  const [buttonAction, setButtonAction] = useState<
    ActivityEntry['buttonAction'] | undefined
  >(undefined);
  const [ratio, setRatio] = useState('9:16');
  const [handleCardAction, setHandleCardAction] = useState(false);
  const [activityId, setActivityId] = useState('');

  const [feedType, setFeedType] = useState<FeedTypes>(FeedTypes.new);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  // hold originalUrl in memory so we can set back after potentially adding params to it
  const [originalUrl, setUrl] = useState(new URL(window.location.href));

  const handleSetClose = () => {
    window.scrollTo(0, 0);
    setOpenCardDialog(false);

    // handle programatic and broswer back buttons
    const params = new URLSearchParams(originalUrl.search);
    // clearParams are for cases where the originalUrl has unwanted params attached when clicking the programatic back button
    // i.e. linking from account/rewards to the dashboard
    const clearParams = params.get('clearParams');

    const replaceStateUrl = clearParams
      ? `${originalUrl.origin}${originalUrl.pathname}`
      : originalUrl.toString();
    window.history.replaceState({}, '', replaceStateUrl);

    // remove onpopstate listener
    window.onpopstate = null;
  };

  const { trackEventV2 } = useTrackingContextValue();

  const sendToTrackEventV2 = useCallback(
    ({
      event,
      activity,
      segmentActivityProps,
    }: {
      event: segmentEvents & AESegmentEvents;
      activity: ActivityEntry;
      segmentActivityProps?: PlayBookActivityProps;
    }) => {
      if (!activity) {
        return;
      }
      const properties =
        segmentActivityProps || getPlaybookSegmentProperties(activity);
      trackEventV2({ event, properties });
    },
    [trackEventV2],
  );

  const onDialogClose = useCallback(() => {
    setOpenDialog(false);
    setButtonAction({});
    setPlaybackId('');
    setHandleCardAction(true);
  }, [setOpenDialog, setButtonAction, setPlaybackId]);

  const onOpenVideoDialog = useCallback(
    (playbackId?: string, ratio?: string, activityId?: string) => {
      setOpenDialog(true);
      if (playbackId) setPlaybackId(playbackId);
      if (ratio) setRatio(ratio);
      if (activityId) setActivityId(activityId);
    },
    [],
  );

  const onOpenButtonActionDialog = useCallback(
    (button: ActivityEntry['buttonAction']) => {
      if (typeof button !== 'undefined') {
        setButtonAction(button);
        setOpenDialog(true);
      }
    },
    [],
  );

  const { user, ceImpactData, fastStartCheck } = dashboardInfo;

  useEffect(() => {
    if (!openCardDialog && goToCardId) {
      setOpenCardDialog(true);
      setFeedType(FeedTypes.completed);
    }
  }, []); // Runs only once because of empty dependency array

  const classes = styles({ ratio, buttonAction });
  const cards = useMemo(
    () => (
      <Cards
        activityId={activityId}
        setFeedType={setFeedType}
        handleSetClose={handleSetClose}
        openVideoDialog={onOpenVideoDialog}
        handleCardAction={handleCardAction}
        setOpenCardDialog={setOpenCardDialog}
        setHandleCardAction={setHandleCardAction}
        openButtonActionDialog={onOpenButtonActionDialog}
        setRewardBadgeAnimationAmount={setRewardBadgeAnimationAmount}
        setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
      />
    ),
    [],
  );

  const timelyFeed = useMemo(
    () => (
      <WidgetContainerDouble
        Widget={() => (
          <Iframe
            src="https://events.timely.fun/r7gc62mt/stream"
            title="Events Timely"
            id="timely_script"
            name="timely-script"
          />
        )}
      />
    ),
    [],
  );

  const statisticsContainer = useMemo(
    () => (
      <Grid item xs={12}>
        <StatisticsContainer gridSpacing={gridSpacing} />
      </Grid>
    ),
    [],
  );

  const { percentage } = getProgressPercentage(user);
  const showFastStartDisplay = showFastStart(user?.fastStartEndDate);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onDialogClose}
        className={classes.dialog}
      >
        {playbackId && (
          <Grid className={classes.player}>
            <ReactPlayer
              url={`https://stream.mux.com/${playbackId}.m3u8`}
              width="100%"
              height="100%"
              light={`https://image.mux.com/${playbackId}/thumbnail.jpg?fit_mode=smartcrop&width=600&height=200`}
              controls={true}
              playing={true}
              muted={false}
              onEnded={onDialogClose}
            />
          </Grid>
        )}
        {buttonAction?.action && (
          <Grid className={classes.buttonActionModal}>
            <ButtonActionModal
              button={buttonAction}
              onDialogClose={onDialogClose}
            />
          </Grid>
        )}
      </Dialog>
      {openCardDialog ? (
        <CardDialog
          openCardDialog={openCardDialog}
          handleSetClose={handleSetClose}
          apolloClient={getClient()}
          feedType={feedType}
          sendToTrackEventV2={sendToTrackEventV2}
          goToCardId={goToCardId}
          externalConfig={{ navigationHandler }}
        />
      ) : (
        <Grid container rowSpacing={gridSpacing} columnSpacing={gridSpacing}>
          {/*Main container*/}
          <Grid
            item
            sx={{
              maxWidth: isTablet ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%',
            }}
          >
            <Grid container rowSpacing={gridSpacing}>
              {!isTablet && (
                <Grid item xs={12}>
                  <MainCard
                    sx={{
                      pt: { xs: 0, md: 0 },
                      minHeight: 616,
                      backgroundColor: theme.palette.cream.main,
                      boxShadow: 'none',
                    }}
                  >
                    {cards}
                  </MainCard>
                </Grid>
              )}
              <Grid item xs={12}>
                <MainCard
                  sx={{
                    p: { xs: 2, lg: 5 },
                    boxShadow: 'none',
                  }}
                >
                  <Greeting user={user} />
                </MainCard>
              </Grid>
              {showFastStartDisplay ? (
                <FastStartBonus ceAccountId={user?.ce?.ceId} />
              ) : (
                statisticsContainer
              )}
              <Grid item xs={12}>
                <MainCard>
                  <CauseImpact ceImpactData={ceImpactData} user={user} />
                </MainCard>
              </Grid>
              {isTablet && (
                <Grid item xs={12}>
                  <MainCard
                    sx={{
                      p: { xs: 0, md: 0 },
                    }}
                  >
                    {timelyFeed}
                  </MainCard>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/*Side Container*/}
          <SideBarGrid item xs={12} theme={theme}>
            <Grid container rowSpacing={gridSpacing}>
              {isTablet && (
                <Grid item xs={12}>
                  <MainCard
                    sx={{
                      pt: { xs: 0, md: 0 },
                      minHeight: 640,
                      boxShadow: 'none',
                    }}
                  >
                    {cards}
                  </MainCard>
                </Grid>
              )}
              {user?.ce?.mentorInfo && (
                <Grid item xs={12}>
                  <MainCard>
                    <MyMentor user={user} />
                  </MainCard>
                </Grid>
              )}
              <Grid item xs={12}>
                <MainCard>
                  <MyCareTeam />
                </MainCard>
              </Grid>
              <Grid item xs={12}>
                <MainCard>
                  <MyTraining inFastStart={fastStartCheck} />
                </MainCard>
              </Grid>
              {!isTablet && (
                <Grid item xs={12}>
                  <MainCard
                    sx={{
                      p: { xs: 0, md: 0 },
                    }}
                  >
                    {timelyFeed}
                  </MainCard>
                </Grid>
              )}
            </Grid>
          </SideBarGrid>
        </Grid>
      )}
    </>
  );
}
