import { gql } from '@apollo/client';
import { CEDash_CauseOfChoiceSelectCause } from './SelectedCauseFragment';

export const CEDash_UserFragment = gql`
  fragment CEDash_UserFragment on User {
    id
    conciergeCustomerAccountId
    publicId
    userId
    firstName
    lastName
    email
    role
    accountRole {
      id
      accountRoleTitle
    }
    permissions {
      id
      oneCommerce {
        id
        ceo {
          access
        }
        subscriber {
          access
        }
        collective {
          access
        }
        employee {
          access
        }
        wineryEmployee {
          access
        }
      }
    }
    profile {
      id
      image
      city
      state
      zip
      summary
    }
    profileImages {
      id
      thumb
      medium
    }
    selectedCause {
      ...CEDash_CauseOfChoiceSelectCause
    }
  }
  ${CEDash_CauseOfChoiceSelectCause}
`;
