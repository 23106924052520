import React from 'react';
import {
  makeStyles,
  ThemeType,
  Container,
  Grid,
  Icons,
  Text,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';
import { isMobile as isMobileDevice, isIOS } from 'react-device-detect';

import ActivityEntry from '../types/ActivityEntry';

const useStyles = makeStyles((theme: ThemeType) => ({
  wrapper: {
    marginTop: '30px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '37px',
    },
  },
  iOSWrapper: {
    marginTop: '30px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '41px',
    },
  },
  itemContainer: {
    width: '500px',
    margin: 'auto',
    boxShadow: '0px 4px 8px rgba(26, 26, 26, 0.4)',
    borderRadius: '12px',
    padding: '10px',
    marginTop: '15px',
    cursor: 'pointer',
    ['@media (max-width: 599px)']: {
      width: '100%',
    },
    ['@media (min-width: 600px) and (max-width: 959px)']: {
      width: '75%',
    },
  },
  image: {
    borderRadius: '4px',
    height: '48px !important',
    width: '84px',
    objectFit: 'cover',
  },
  activityText: {
    marginLeft: '20px',
    ['@media (max-width: 378px)']: {
      marginLeft: '47px',
    },
    ['@media (min-width: 378px) and (max-width: 550px)']: {
      marginLeft: '36px',
    },
  },
  arrow: {
    float: 'right',
    marginTop: '5px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-11px',
    },
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginLeft: '0px',
    },
  },
}));

type CardDialogItemsProps = {
  activities: ActivityEntry[];
  handleSetActivity: (activity: ActivityEntry) => void;
};

function CardDialogItems(props: CardDialogItemsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { activities, handleSetActivity } = props;

  return (
    <Container
      className={isMobileDevice && isIOS ? classes.iOSWrapper : classes.wrapper}
    >
      {activities.map(activity => {
        return (
          <Grid
            container
            spacing={0}
            className={classes.itemContainer}
            onClick={() => handleSetActivity(activity)}
          >
            <Grid item xs={2}>
              {activity.image && (
                <img
                  className={classes.image}
                  alt={activity.image.alt}
                  src={activity.image.src}
                />
              )}
            </Grid>
            <Grid item xs={isMobile ? 9 : 8}>
              <Text className={classes.activityText}>{activity.title}</Text>
            </Grid>
            <Grid item xs={isMobile ? 1 : 2}>
              <Icons.RightArrow className={classes.arrow} />
            </Grid>
          </Grid>
        );
      })}
    </Container>
  );
}

export default CardDialogItems;
