import React from 'react';
import { Grid } from '@onehope/design-system-v2';
import { CancelMembership, NeedSomeHelp } from '@onehope/account';
import ChangeShipmentDate from './ChangeShipmentDate';
import FrequencyDropDown from './Frequency';
import BottleQuantitySelector from './ChangeBottleQuantity/BottleQuantitySelector';
import ShippingAddress from './Address';
import PaymentMethod from './Payment';

import { SendToTrackEventV2 } from '../../utils/segment';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
  WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';

type PreferencesViewProps = {
  user: WineClub_AutoShipGetQuery_viewer_user;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  paymentMethod:
    | WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment
    | null
    | undefined;
  conciergeMode: boolean;
  contactId?: string;
  sendToTrackEventV2?: SendToTrackEventV2;
  wineClub923?: boolean;
};

export default function PreferencesView(props: PreferencesViewProps) {
  const { subscription, paymentMethod, wineClub923 } = props;
  const { shippingAddress, subscriptionStatus, curatedPackName } = subscription;
  const isNotCurated = curatedPackName === 'none';

  return (
    <Grid container direction="column">
      {/* Shipment */}
      <ChangeShipmentDate
        user={props.user}
        subscription={subscription}
        conciergeMode={props.conciergeMode}
        sendToTrackEventV2={props.sendToTrackEventV2}
      />

      {/* Frequency */}
      {isNotCurated && (
        <FrequencyDropDown
          user={props.user}
          subscription={subscription}
          conciergeMode={props.conciergeMode}
          sendToTrackEventV2={props.sendToTrackEventV2}
        />
      )}

      {/* Quantity */}
      <BottleQuantitySelector
        user={props.user}
        subscription={subscription}
        conciergeMode={props.conciergeMode}
        sendToTrackEventV2={props.sendToTrackEventV2}
        wineClub923={wineClub923}
      />

      {shippingAddress?.addressId && (
        <ShippingAddress
          user={props.user}
          address={shippingAddress}
          subscription={subscription}
          conciergeMode={props.conciergeMode}
          contactId={props.contactId}
          sendToTrackEventV2={props.sendToTrackEventV2}
        />
      )}

      {paymentMethod?.paymentId && (
        <PaymentMethod
          user={props.user}
          payment={paymentMethod}
          subscription={subscription}
          conciergeMode={props.conciergeMode}
          contactId={props.contactId}
          sendToTrackEventV2={props.sendToTrackEventV2}
        />
      )}

      <CancelMembership
        subscriptionStatus={subscriptionStatus}
        isCeSubscription={false}
        actionText={'Need To Cancel?'}
        actionType={'button'}
      />

      <NeedSomeHelp />
    </Grid>
  );
}
