import * as React from 'react';
import ReactInputMask from 'react-input-mask';
import { InputPrimary } from '../styles/ant-design-styles/inputs';

// https://github.com/sanniassin/react-input-mask
// InputMask is used for instance creating phone number formatting
interface InputMaskProps {
  label?: string;
  mask: string;
  maskChar?: string | null;
  formatChars?: { [key: string]: string } | undefined;
  alwaysShowMask?: boolean;
  disabled?: boolean;
  // functions
  beforeMaskedValueChange?: any;
  children?: any;
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: 'on' | 'off';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function InputMask(props: InputMaskProps) {
  const { ...rest } = props;
  return (
    <ReactInputMask {...rest}>
      {(inputProps: any) => (
        <InputPrimary
          {...inputProps}
          disabled={rest.disabled ? rest.disabled : null}
        />
      )}
    </ReactInputMask>
  );
}
