import { Text, styled, theme, TEXT_DS, Icons } from '@onehope/design-system-v2';
import Decimal from 'decimal.js';

import { CEDash_FastStartQuery_viewer_v3_fastStart23 as FastStartData } from '../../../queries/generatedTypes/CEDash_FastStartQuery';

const ThermometerContainer = styled('div')`
  margin-top: 8px;
`;

const ThermometerBackground = styled('div')`
  background: ${theme.palette.greenTintColor.dark};
  border-radius: 8px;
  height: 6px;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 5px 2px ${theme.palette.greyLight.main};
`;

const ThermometerFill = styled('div')`
  position: relative;
  background: linear-gradient(to right, #3c5d62 0%, #4cb9c3 100%);
  border-radius: 8px 0 0 8px;
  height: 6px;
`;

const TickContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -14px;
`;

const TickTextContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -14px;
`;

const ThermometerTickText = styled(Text)`
  color: ${theme.palette.greyDark.main};
  display: block;
  width: 50px;
  text-align: center;
  margin: 0;

  &:nth-child(2) {
    position: relative;
    left: 11px;
  }

  &:nth-child(4) {
    position: relative;
    left: -15px;
  }

  &:last-child {
    text-align: right;
    width: 50px;
    margin-right: -50px;
    position: relative;
    left: -50px;
  }
`;

const ThermometerTickBumper = styled('div')`
  width: 50px;
  text-align: center;

  &:first-child {
    width: 50px;
    margin-left: -50px;
    text-align: left;
  }

  &:nth-child(2) {
    position: relative;
    left: 11px;
  }

  &:nth-child(4) {
    position: relative;
    left: -15px;
  }

  &:last-child {
    text-align: right;
    width: 50px;
    margin-right: -50px;
    position: relative;
    left: -50px;
  }
`;

interface Props {
  fastStartData: FastStartData;
}

export default function RetailSalesThermometer({ fastStartData }: Props) {
  const getCircleCheck = (index: number) => {
    if (
      new Decimal(index + 1)
        .times(fastStartData?.salesRewards?.[0]?.salesGoal ?? 50000)
        .greaterThan(fastStartData?.salesTotal ?? Infinity)
    ) {
      return <Icons.FastStartInactiveCheckCircle />;
    }
    return <Icons.FastStartActiveCheckCircle />;
  };

  /**
   * To avoid e.g. 99% appearing to be complete because the end of the bar is rendered under the checkmark icon, bias the percentage a bit in either direction.
   */
  const getThermometerWidth = (data?: FastStartData) => {
    const PIXEL_OFFSET = new Decimal(25);

    const salesAmount = new Decimal(data?.salesTotal ?? 0);
    let pixelOffset: Decimal;

    // if we're approaching a checkbox but not exactly at the goal, subtract the offset so it's obvious they haven't achieved it
    if (
      salesAmount
        .mod(50000)
        .greaterThanOrEqualTo(new Decimal(50000).dividedBy(2))
    ) {
      pixelOffset = PIXEL_OFFSET.times(-1);
    } else if (salesAmount.mod(50000).equals(0)) {
      pixelOffset = new Decimal(0);
    } else {
      // if we have just barely exceeded a checkbox, add the offset so it's obvious they have exceeded it
      pixelOffset = PIXEL_OFFSET;
    }

    return `calc(${salesAmount
      .dividedBy(2000)
      .toNumber()}% + ${pixelOffset.toNumber()}px)`;
  };

  return (
    <ThermometerContainer className="ThermometerContainer">
      <ThermometerBackground>
        <ThermometerFill
          style={{
            width: getThermometerWidth(fastStartData),
          }}
        />
      </ThermometerBackground>
      <TickContainer>
        <ThermometerTickBumper className="ThermometerTickBumper" key={-1} />
        {[...Array(4)].map((_, index) => (
          <ThermometerTickBumper className="ThermometerTickBumper" key={index}>
            {getCircleCheck(index)}
          </ThermometerTickBumper>
        ))}
      </TickContainer>
      <TickTextContainer>
        <ThermometerTickBumper />
        <ThermometerTickText
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
        >
          $500
        </ThermometerTickText>
        <ThermometerTickText
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
        >
          $1,000
        </ThermometerTickText>
        <ThermometerTickText
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
        >
          $1,500
        </ThermometerTickText>
        <ThermometerTickText
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
        >
          $2,000
        </ThermometerTickText>
      </TickTextContainer>
    </ThermometerContainer>
  );
}
