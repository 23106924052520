import * as React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import ArchiveDialog from '../ArchiveWarningDialog/index';
import { navigate } from 'gatsby';
import ArchiveMultiContactsMutation from '../../../mutations/Contacts/ArchiveMultiContactsMutation';
import ArchiveAllContactsMutation from '../../../mutations/Contacts/ArchiveAllContactsMutation';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';

const ArchiveModalContainer = styled.div`
  width: 100px;
`;

const goToContacts = (e?: React.MouseEvent) => {
  if (e && typeof e.preventDefault === 'function') {
    e.preventDefault();
  }
  navigate('/contacts');
  return null;
};

export function ArchiveContactModal(props: any) {
  const [archiveMultiContacts, { loading }] = useMutation(
    ArchiveMultiContactsMutation,
  );
  const [archiveAllContacts, { loading: loadingAll }] = useMutation(
    ArchiveAllContactsMutation,
  );
  const { trackContacts } = useTrackingContextValue();
  const {
    allContacts,
    contactIds,
    isArchiveDialogOpen,
    setIsArchiveDialogClose,
    contactOrContacts,
  } = props;

  const DIALOG_TEXT = `Are you sure you want to archive ${
    contactIds.length === 1 ? 'this contact' : 'these contacts'
  }?`;

  const DIALOG_CONTENT = `You are about to archive ${
    allContacts > 1 ? allContacts : contactIds.length
  } of your ${contactOrContacts} This action will unlink you as the ${
    contactIds.length === 1 ? "contact's" : 'their'
  }  Wine Rep. This cannot be undone. Please confirm below.`;

  const saveChange = React.useCallback(() => {
    archiveMultiContacts({ variables: { contactIds } }).then(() => {
      trackContacts({
        eventName: 'CE Archived Contact',
        totalArchived: 1,
      });
      setIsArchiveDialogClose();
      goToContacts();
    });
  }, [archiveMultiContacts, contactIds, setIsArchiveDialogClose]);

  const saveAllChange = React.useCallback(() => {
    // archive all mutation
    archiveAllContacts({ variables: { contactIds } }).then(() => {
      trackContacts({
        eventName: 'CE Archived Contact',
        totalArchived: 1,
      });
      setIsArchiveDialogClose();
      goToContacts();
    });
  }, [archiveAllContacts, contactIds, setIsArchiveDialogClose]);

  return (
    <ArchiveModalContainer>
      <ArchiveDialog
        dialogText={contactOrContacts ? DIALOG_TEXT : ''}
        dialogContent={contactOrContacts ? DIALOG_CONTENT : ''}
        open={isArchiveDialogOpen}
        setClose={setIsArchiveDialogClose}
        saveChange={allContacts < 0 ? saveChange : saveAllChange}
        mutationLoading={loading}
      />
    </ArchiveModalContainer>
  );
}
