import React from 'react';
import { ButtonV2 as MuiButton } from '@onehope/design-system-v2';
import { ContactType, useContactsContext } from '../ContactsContext';

interface ConciergeButtonProps {
  contact: ContactType;
}

// self-contained concierge mode kick-off button
const ConciergeButton = ({ contact }: ConciergeButtonProps) => {
  const { handleOrderPermissions } = useContactsContext();

  return (
    <MuiButton
      type="primary"
      size="small"
      onClick={() => handleOrderPermissions(contact)}
    >
      Order
    </MuiButton>
  );
};

export default ConciergeButton;
