import React, { useState } from 'react';
import EventsTable from '../../../../Events/EventsTable';
import { handleFilterByTab } from '../../../../Events/EventsTable/helpers';
import { CEDash_EventsQuery_viewer_user_events as EventsType } from '../../../../../queries/generatedTypes/CEDash_EventsQuery';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../../../queries/generatedTypes/CEDash_CustomerDetailsPage';

interface EventsPageWrapperProps {
  events: EventsType;
  contact: ContactType;
}

function EventsPageWrapper(props: EventsPageWrapperProps) {
  const eventEdges = props.events.edges;
  const openEvents = handleFilterByTab(eventEdges, 'open');
  const [events, setEvents] = useState(openEvents);
  const [allEvents] = useState(eventEdges);

  return (
    <EventsTable
      contact={props.contact}
      showHeader={false}
      events={events}
      setEvents={setEvents}
      allEvents={allEvents}
    />
  );
}

export default EventsPageWrapper;
