export const timeZones = [
  // 'AST',
  { key: 'EST', value: 'America/New_York' },
  // 'EDT',
  { key: 'CST', value: 'America/Chicago' },
  // 'CDT',
  { key: 'MST', value: 'America/Denver' },
  // 'MDT',
  { key: 'PST', value: 'America/Los_Angeles' },
  // 'PDT',
  { key: 'AKST', value: 'America/Anchorage' },
  // 'AKDT',
  // 'HST',
  // 'HAST',
  // 'HADT',
  // 'SST',
  // 'SDT',
  // 'CHST',
];
