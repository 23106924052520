import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import {
  theme,
  DialogV2,
  Icons,
  TEXT_DS,
  Snackbar,
  Box,
  IconButton,
  Grid,
} from '@onehope/design-system-v2';
import {
  CopyTextCard,
  CardText,
  CopyTextContainer,
  CopyText,
  DialogButton,
  socialSharingButtons,
  CopiedTextContainer,
  SnackbarCopiedText,
  SnackbarGridContainer,
} from './sharedComponents';
import { ShareMedium } from '../../../../generatedTypes/globalTypes';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { CEDash_ReachOutRequest_registerReachOutV2_shareConfig as ShareConfig } from '../../../mutations/ReachOuts/generatedTypes/CEDash_ReachOutRequest';
import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplate } from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import { segmentEvents } from '../../../utils/segment/constants';
import { DescriptionText } from '../StyledComponents/Text';

const OpenFacebookButton = styled(FacebookShareButton)(socialSharingButtons);
const OpenLinkedInButton = styled(LinkedinShareButton)(socialSharingButtons);

type Props = {
  ceAccountId: string;
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  loading: boolean;
  shareConfig: ShareConfig;
  reachOutTemplate: ReachOutTemplate;
};

/**
 * Display a modal with suggested content for sharing a reach out to social media
 */
export function SocialShareModal({
  ceAccountId,
  dialogOpen,
  handleCloseDialog,
  loading,
  shareConfig,
  reachOutTemplate,
}: Props) {
  const { trackEventV2 } = useTrackingContextValue();
  const [copied, setCopied] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [textToCopy, setTextToCopy] = useState('');

  useEffect(() => {
    if (!shareConfig) {
      return;
    }

    setTextToCopy(
      shareConfig.medium === ShareMedium.MANUAL_LINK
        ? shareConfig.url
        : shareConfig.body,
    );
  }, [shareConfig]);

  const handleTrackEvent = () => {
    let deliveryType: string;
    switch (shareConfig.medium) {
      case ShareMedium.FACEBOOK_FEED_POST:
        deliveryType = 'fbFeed';
        break;
      case ShareMedium.LINKED_IN_POST:
        deliveryType = 'linkedinFeed';
        break;
      case ShareMedium.MANUAL_LINK:
        deliveryType = 'manualLink';
        break;
    }

    trackEventV2({
      event: segmentEvents.reachOutSent,
      properties: {
        name: reachOutTemplate.name,
        reachOutId: reachOutTemplate.reachOutId,
        contactId: null,
        deliveryType,
        ceAccountId,
        isReminder: false,
        category: reachOutTemplate.category?.name,
      },
    });
  };

  const handleCopyToClipBoard = () => {
    // there's no second step for MANUAL_LINK, so we can track the event here
    if (shareConfig.medium === ShareMedium.MANUAL_LINK) {
      handleTrackEvent();
    }

    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setSnackbarOpen(true);
  };

  const onClose = () => {
    // reset states after dialog becomes invisible
    setTimeout(() => {
      setCopied(false);
      setSnackbarOpen(false);
    }, 200);

    handleCloseDialog();
  };

  if (shareConfig === null) {
    return null;
  }

  let dialogTitle = 'Share';
  switch (shareConfig.medium) {
    case ShareMedium.FACEBOOK_FEED_POST:
      dialogTitle = 'Share to Facebook';
      break;

    case ShareMedium.LINKED_IN_POST:
      dialogTitle = 'Share to LinkedIn';
      break;

    case ShareMedium.MANUAL_LINK:
      dialogTitle = 'Copy link';
      break;
  }

  let customButtonComponent: JSX.Element | undefined;
  switch (shareConfig.medium) {
    case ShareMedium.FACEBOOK_FEED_POST:
      customButtonComponent = (
        <OpenFacebookButton url={shareConfig.url} onClick={handleTrackEvent}>
          <DialogButton>{dialogTitle}</DialogButton>
        </OpenFacebookButton>
      );
      break;

    case ShareMedium.LINKED_IN_POST:
      customButtonComponent = (
        <OpenLinkedInButton url={shareConfig.url} onClick={handleTrackEvent}>
          <DialogButton>{dialogTitle}</DialogButton>
        </OpenLinkedInButton>
      );
      break;

    case ShareMedium.MANUAL_LINK:
      customButtonComponent = (
        <DialogButton onClick={onClose}>Close</DialogButton>
      );
      break;
  }

  const closeSnackbarCopied = () => setSnackbarOpen(false);

  return (
    <DialogV2
      open={dialogOpen}
      onClose={onClose}
      title={dialogTitle}
      customButtonComponent={customButtonComponent}
      onSaveLoading={loading}
    >
      <>
        <DescriptionText text="Copy the details below to your clipboard before sharing." />
        <CopyTextCard>
          <CardText>{textToCopy}</CardText>
          {!copied && (
            <CopyTextContainer onClick={handleCopyToClipBoard}>
              <Icons.Copy color={theme.palette.forestGreen.main} />
              <CopyText variant="custom" default={TEXT_DS.OVERLINE_SEMIBOLD_12}>
                {shareConfig.medium === ShareMedium.MANUAL_LINK && 'copy link'}
                {shareConfig.medium !== ShareMedium.MANUAL_LINK && 'copy text'}
              </CopyText>
            </CopyTextContainer>
          )}
          {copied && (
            <>
              <CopiedTextContainer>
                <Icons.GreenCheck color={theme.palette.forestGreen.main} />
                <CopyText
                  variant="custom"
                  default={TEXT_DS.OVERLINE_SEMIBOLD_12}
                >
                  copied!
                </CopyText>
              </CopiedTextContainer>
              <Snackbar
                open={snackbarOpen}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '272px',
                    height: '40px',
                    background: theme.palette.blue0.main,
                    boxShadow: '0px 4px 8px rgba(26, 26, 26, 0.4)',
                    borderRadius: '8px',
                    margin: 'auto',
                  }}
                >
                  <SnackbarGridContainer
                    container
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    <Grid item>
                      <SnackbarCopiedText
                        component="div"
                        variant="custom"
                        default={TEXT_DS.BODY_SEMIBOLD_16}
                      >
                        Successfully copied to clipboard
                      </SnackbarCopiedText>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="close"
                        onClick={closeSnackbarCopied}
                      >
                        <Icons.CloseCircleWhite />
                      </IconButton>
                    </Grid>
                  </SnackbarGridContainer>
                </Box>
              </Snackbar>
            </>
          )}
        </CopyTextCard>
      </>
    </DialogV2>
  );
}
