import { makeStyles, ThemeType } from '@onehope/design-system-v2';

type SharedStyles = {
  [key: string]: string | number | object;
};

const dialogTitleBase = (theme: ThemeType) => {
  return {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    height: '40px',
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.greyLight.main}`,
    display: 'flex',
  } as SharedStyles;
};

const detailsTextBase = () => {
  return {
    fontFamily: 'brandon-grotesque',
    fontSize: '17px',
    textAlign: 'center',
    fontWeight: 200,
  } as SharedStyles;
};

export const useStyles = makeStyles((theme: ThemeType) => ({
  dialogTitleList: {
    ...dialogTitleBase(theme),
    justifyContent: 'center',
  },
  dialogTitleDetails: {
    ...dialogTitleBase(theme),
  },
  centerText: {
    textAlign: 'center',
  },
  detailsBackContainer: {
    width: '135px',
  },
  closeIcon: {
    float: 'right',
    marginTop: '-35px',
    marginRight: '-5px',
  },
  dismissedText: {
    ...detailsTextBase(),
    marginTop: '-9px',
  },
  pointsCompletedText: {
    ...detailsTextBase(),
  },
  pointsCompletedContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
  restoreButton: {
    borderRadius: '12px',
    padding: '7px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px rgb(26 26 26 / 25%)',
    border: 'none',
    display: 'flex',
    paddingRight: '12px',
    width: '103px',
    height: '40px',
    margin: '18px auto',
    cursor: 'pointer',
  },
  restoreText: {
    color: '#335E65',
    letterSpacing: '1px',
    marginTop: '5.1px',
    fontFamily: theme.bodyFontFamily,
    fontSize: '12px',
  },
  restoreIconContainer: {
    marginTop: '2px',
    marginRight: '4px',
  },
  successToastContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  successToast: {
    width: '500px',
    height: '56px',
    border: '1px solid #46789E',
    backgroundColor: '#F9FCFF',
    borderRadius: '12px',
    padding: '17px',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '20px',
    zIndex: 10,
    fontFamily: theme.bodyFontFamily,
    ['@media (max-width: 599px)']: {
      width: '92%',
      padding: '17px 10px 17px 10px',
      marginTop: '30px',
    },
    ['@media (min-width: 600px) and (max-width: 959px)']: {
      width: '70%',
    },
  },
  blueCloseIcon: {
    cursor: 'pointer',
    float: 'right',
    marginTop: '4px',
  },
  blueSuccessIcon: {
    cursor: 'pointer',
    float: 'left',
    marginTop: '2px',
    marginRight: '8px',
  },
  backArrow: {
    cursor: 'pointer',
    float: 'left',
    marginTop: '3px',
    marginRight: '4px',
  },
  container: {
    backgroundColor: 'white',
    height: '100vh',
  },
  backContainer: {
    float: 'left',
    display: 'flex',
    cursor: 'pointer',
    marginTop: '-30px',
    marginLeft: '10px',
  },
  backContainerSelected: {
    float: 'left',
    display: 'flex',
    cursor: 'pointer',
  },
  titleText: {
    margin: '0 auto',
  },
  loader: {
    textAlign: 'center',
  },
}));
