import { gql } from '@apollo/client';

const DISCOUNTS_QUERY = gql`
  query WineClub_DiscountsQuery($date: String) {
    viewer {
      id
      v2 {
        id
        discounts(date: $date, isAutoshipRenewal: true) {
          id
          bottleDiscount4
          bottleDiscount6
          bottleDiscount12
        }
      }
    }
  }
`;

export default DISCOUNTS_QUERY;
