import { isAndroid, mobileVendor } from 'react-device-detect';

export const formatPhone = (value?: string) => {
  if (!value || (value.length !== 10 && value.length !== 11)) {
    return null;
  }
  let i = 0;
  const number = value.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, _ => number[i++]);
};

export const handleAmpersandForAndroid = (text: string) => {
  const urlRegex = /https?:\/\/[^\s]*(&)[^\s)]*/g;
  const fullStringContainingAmpersand = /([^ "]*&[^ "]*)/g; //** ex. Friends & family => &, hello.com?test=123&world=321 => hello.com?test=123&world=321 */
  return text.replace(fullStringContainingAmpersand, str => {
    // replace all urls with encoded urls
    if (str.match(urlRegex)) {
      return encodeURIComponent(str);
    }
    // replace the rest of '&'s with 'and'
    return 'and';
  });
};

const convertEmailBreakpoints = (body: string) =>
  body.replace(/<br\s*\/?>/gi, '\n');

export const sendEmail = ({
  body,
  subject,
  email,
}: {
  body: string;
  subject: string;
  email: string;
}) => {
  return {
    email: `mailto:${email}?subject=${encodeURI(
      subject,
    )}&body=${encodeURIComponent(convertEmailBreakpoints(body))}`,
  };
};

export const sendText = ({
  body,
  phoneNumber,
}: {
  body: string;
  phoneNumber: string;
}) => {
  const isSamsung = mobileVendor.toLowerCase() === 'samsung';
  const textBody = isSamsung ? handleAmpersandForAndroid(body) : body;

  const separator = isAndroid ? '?' : '&';
  return {
    sms: `sms:${phoneNumber}${separator}body=${encodeURIComponent(textBody)}`,
  };
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export interface LabelType {
  labels: string[];
}

export interface AnswerType {
  choices: LabelType;
  choice: {
    label: string;
  };
  type: string;
  text: string;
}

export interface FieldType {
  title: string;
}

export interface DefinitionType {
  fields: FieldType[];
  title: string;
}
