import gql from 'graphql-tag';

export const HostRewardsFragment = gql`
  fragment HostRewards_event on Event {
    id
    hostRewards {
      id
      code
      promoCodeId
      loadedBalance
      storeBalance
      usableByAccountId
      usableByEmails
      expiresOn
    }
    status
    manualCloseDate
    hostRewardPointsIssued
  }
`;

export const GET_HOST_REWARDS_QUERY = gql`
  query CEDash_HostRewardsQuery($eventId: String) {
    viewer {
      id
      event(eventId: $eventId) {
        ...HostRewards_event
      }
    }
  }
  ${HostRewardsFragment}
`;

export const GET_HOST_REWARDS_ORDERS_QUERY = gql`
  query CEDash_HostRewardsOrdersQuery($orderIds: [String]) {
    viewer {
      id
      user {
        id
        orders(first: 1000, byOrderIds: $orderIds) {
          edges {
            node {
              id
              orderId
              orderNumber
              accountFullName
              createDate
              orderStatus
              orderTotal
              shipments {
                shipmentInformation {
                  trackingLink
                }
              }
            }
          }
        }
      }
    }
  }
`;
