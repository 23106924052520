import { gql } from '@apollo/client';
import { UserCauseOfChoice } from '../queries/UserGetQuery';

export const mutation = gql`
  mutation CauseOChoiceCartUpdateEventIdMutation(
    $input: CartUpdateEventIdInput!
  ) {
    cartUpdateEventId(input: $input) {
      user {
        ...UserCauseOfChoice
      }
    }
  }
  ${UserCauseOfChoice}
`;

export default mutation;
