import { Tabs, theme, styled } from '@onehope/design-system-v2';

export const Container = styled('div')`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  @media all and (min-width: 960px) {
    padding: 0 24px 0 24px;
    flex-direction: row;
  }
`;

export const TabsContainer = styled(Tabs)`
  overflow: auto;
  & .MuiTabs-indicator {
    display: none;
    background-color: transparent;
  }
  & button {
    text-transform: capitalize;
    font-family: ${theme.bodyFontFamily};
    color: ${theme.palette.greyDark.main};
    font-weight: 420;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: 0.01em;
    opacity: 1;
    //width: 100px;
    min-width: 100px;
    height: 40px;
    min-height: 40px;
    border-top: 1px solid ${theme.palette.greyDark.main};
    border-bottom: 1px solid ${theme.palette.greyDark.main};
  }
  & button:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid ${theme.palette.greyDark.main};
    border-bottom: 1px solid ${theme.palette.greyDark.main};
    border-left: 1px solid ${theme.palette.greyDark.main};
    border-right: 1px solid ${theme.palette.greyDark.main};
  }
  & button:nth-of-type(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid ${theme.palette.greyDark.main};
    border-bottom: 1px solid ${theme.palette.greyDark.main};
    border-right: 1px solid ${theme.palette.greyDark.main};
    border-left: 1px solid ${theme.palette.greyDark.main};
  }
  & button:nth-of-type(4) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid ${theme.palette.greyDark.main};
    border-bottom: 1px solid ${theme.palette.greyDark.main};
    border-right: 1px solid ${theme.palette.greyDark.main};
  }
  // & button:last-of-type {
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  //   border-top: 1px solid ${theme.palette.greyDark.main};
  //   border-bottom: 1px solid ${theme.palette.greyDark.main};
  //   border-right: 1px solid ${theme.palette.greyDark.main};
  //   border-left: 1px solid ${theme.palette.greyDark.main};
  // }
  & button.Mui-selected {
    color: white;
    font-weight: 420;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font-family: ${theme.bodyFontFamily};
    background-color: ${theme.palette.primary.main};
    border-top: 1px solid ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.primary.main};
  }
  @media all and (min-width: 960px) {
    padding: 0 24px;
  }
`;
