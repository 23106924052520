import React from 'react';
import {
  Container,
  Dialog,
  Grid,
  Icons,
  makeStyles,
  useMediaQuery,
  useTheme,
  Text,
  Button,
  ThemeType,
} from '@onehope/design-system-v2';

import { useFormStyles } from '../_shared/formStyles';

interface CancelSubscriptionDialogProps {
  open: boolean;
  onClose: () => void;
  isCeSubscription?: boolean;
}

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: { width: '460px' },
  },
}));

const useStyles = makeStyles((theme: ThemeType) => ({
  summaryText2: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    lineHeight: '24px',
    paddingBottom: '8px',
  },
  boldText: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    fontWeight: 500,
    paddingBottom: 2,
  },
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    paddingBottom: 2,
  },
  link: {
    color: theme.palette.mud.main,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    fontWeight: 500,
    paddingBottom: 2,
  },
  textGroup: {
    padding: '8px 0',
  },
}));

const CancelSubscriptionDialog = (props: CancelSubscriptionDialogProps) => {
  const { onClose, open, isCeSubscription } = props;
  const classes = useFormStyles();
  const dialogClasses = useDialogStyles();
  const componentClasses = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const headerText = isCeSubscription
    ? `Sorry to See You Go!`
    : `Steps to Cancel Your Subscription`;
  const summaryText = isCeSubscription
    ? `Thank you for being a member of our Wine Rep community. To cancel your memership, please get in touch with our support team.`
    : `Did you know that you can change the frequency of your subscription? Also, if you would like to postpone your next shipment, you can contact our support department and we'd be happy to help you!`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={dialogClasses}
      fullScreen={isMobile}
    >
      <Container className={classes.main}>
        <Grid container>
          <Icons.Close className={classes.close} onClick={onClose} />
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            alignContent="flex-start"
            className={classes.container}
          >
            <Text variant="h2" className={classes.headerText}>
              {headerText}
            </Text>
            <Text variant="body1" component="p" className={classes.summaryText}>
              {summaryText}
            </Text>
            {!isCeSubscription && (
              <Text
                variant="body1"
                component="p"
                className={componentClasses.summaryText2}
              >
                If you still want to cancel, please get in touch with our
                support team.
              </Text>
            )}
            <div className={componentClasses.textGroup}>
              <Text
                variant="body1"
                component="p"
                className={componentClasses.boldText}
              >
                Email
              </Text>
              <a
                className={componentClasses.link}
                href="mailto:support@onehopewine.com"
              >
                support@onehopewine.com
              </a>
            </div>
            <div className={classes.button}>
              <Button type="primary" fullWidth onClick={onClose}>
                Close
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
