import React from 'react';

type PhoneNumberProps = {
  rawValue: string | undefined;
};

export default function PhoneNumber({ rawValue }: PhoneNumberProps) {
  const formatPhoneNumber = (value: string | undefined) => {
    const cleaned = ('' + value).replace(/\D/g, '');

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return value;
  };
  const formattedPhoneNumber = formatPhoneNumber(rawValue);
  return <>{formattedPhoneNumber}</>;
}
