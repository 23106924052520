import * as React from 'react';
import { useState, Dispatch, SetStateAction } from 'react';
import { Button } from '@onehope/design-system-v2';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { styles as s } from '@onehope/design-system';
import { CEDash_EventDetailsPage_viewer_event } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import EVENT_CANCEL_MUTATION from '../../../mutations/Event/EventUpdateMutation';
import CancelEventDialog from '../../Dialog/AlertDialog';
import useToggle from '../../../utils/useToggleHook';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { SegmentTrackEventProps } from '../../../utils/segment/tracking';
import { EventStatusDictionary } from '../../../utils/enums';
const { cssConstants } = s;
const EventCloseContainer = styled.div`
  width: 100px;
  margin-bottom: 40px;
`;

const CancelEventButton = styled.div`
  /* font-family: ProximaNova; */
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: ${cssConstants.neutral34};
  margin: 16px 0;
  cursor: pointer;
`;

interface EventCancelButtonProps {
  event: CEDash_EventDetailsPage_viewer_event;
}

const DIALOG_TEXT =
  'Are you sure you want to cancel this event? It will be deleted from your event list';
const handleCancelEvent = ({
  event,
  eventCancel,
  trackEventsV2,
  setErrorNotification,
}: {
  event: CEDash_EventDetailsPage_viewer_event;
  eventCancel: any;
  trackEventsV2: (props: SegmentTrackEventProps) => void;
  setErrorNotification: Dispatch<SetStateAction<string>>;
}) => {
  return () => {
    const { eventId } = event;
    const input = {
      form: {
        eventId,
        isArchived: true,
        status: EventStatusDictionary.CANCELLED,
        trinityPartyStatus: EventStatusDictionary.CANCELLED,
      },
    };
    return eventCancel({
      variables: { input: input },
    })
      .then(() => {
        navigate(`/events`);
        trackEventsV2({ eventName: 'Event Cancelled', newEvent: event });
      })
      .catch((errors: any) => {
        console.log('errors', errors);
        const errorMessage = errors?.graphQLErrors?.[0]?.message;
        if (errorMessage.includes('Bad Request') && setErrorNotification) {
          setErrorNotification(
            'This event can no longer be cancelled - please refresh the page for updated options.',
          );
        }
        return;
      });
  };
};

export default function EventCancelButton(props: EventCancelButtonProps) {
  const { event } = props;
  const { trackEventsV2 } = useTrackingContextValue();
  const [errorNotification, setErrorNotification] = useState('');

  const {
    value: isCancelDialogOpen,
    setTrue: setIsCancelDialogOpen,
    setFalse: setIsCancelDialogClose,
  } = useToggle();

  const [eventCancel, { loading }] = useMutation(EVENT_CANCEL_MUTATION);

  const dispatchCancelEvent = handleCancelEvent({
    event,
    eventCancel,
    trackEventsV2,
    setErrorNotification,
  });
  if (
    event.status === EventStatusDictionary.CANCELLED ||
    event.status === EventStatusDictionary.CLOSED
  )
    return null;
  return (
    <>
      <CancelEventDialog
        dialogText={DIALOG_TEXT}
        open={isCancelDialogOpen}
        setClose={setIsCancelDialogClose}
        saveChange={dispatchCancelEvent}
        mutationLoading={loading}
        errorNotification={errorNotification}
      />
      <Button
        type="tertiary"
        disabled={loading}
        onClick={setIsCancelDialogOpen}
      >
        Cancel event
      </Button>
    </>
  );
}
