import React, { useRef, useEffect } from 'react';
import { Grid } from '@onehope/design-system-v2';
import EventInfo from './Info';

import Wine from '../Wine';
import HostRewards from '../HostRewards';
import EventOrders from './EventOrders';
import EventCancelButton from '../../../Events/EventDetailsForm/EventCancelButton';
import { EventTypeEnum } from '../../../EventsV2/eventTypes';
// import {
//   eventIsAcceptingOrders,
//   eventIsCancelled,
// } from '../../../Events/EventsTable/helpers';

function Summary(props) {
  const { event, user } = props;
  const searchParams = new URLSearchParams(window.location.search);
  const shouldScroll = searchParams.get('scroll') === 'true';
  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    myRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const isNotVirtual = event?.trinityPartyType !== 'EPARTY';
  const isWineTasting = event?.eventType === EventTypeEnum.WineTasting;
  // const isVirtualWineTasting =
  //   event?.trinityPartyType === 'EPARTY' && isWineTasting;
  // const isAcceptingOrders = eventIsAcceptingOrders(event);
  // const cancelledEvent = eventIsCancelled(event);

  // In this case, if the Wine Rep skipped or selected no, show
  // the wine form so they can change their selection
  const hostRewardsQualifiedSubTotal = event?.hostRewardsQualifiedSubTotal || 0;
  const ordersPlaced = event?.ordersCount > 0;
  const donationSubtotal = event?.donationInfo?.donationAmount || 0;
  const showCancelButton =
    donationSubtotal <= 0 && hostRewardsQualifiedSubTotal <= 0;
  const showWineComponent = isNotVirtual;

  useEffect(() => {
    if (shouldScroll) {
      executeScroll();
    }
  }, [shouldScroll]);

  return (
    <Grid>
      <EventInfo event={event} />
      <div ref={myRef} />
      {isWineTasting && (
        <>{showWineComponent && <Wine event={event} user={user} />}</>
      )}

      <EventOrders event={event} user={user} />
      <HostRewards event={event} user={user} />
      {(!ordersPlaced || showCancelButton) && (
        <Grid container justifyContent="center">
          <EventCancelButton event={props.event} />
        </Grid>
      )}
    </Grid>
  );
}

export default Summary;
