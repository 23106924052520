import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';

interface StripeWrapperProps {
  children: any;
}

const StripeWrapper = (props: StripeWrapperProps) => {
  const { children } = props;
  const stripeApiKey =
    process.env.NEXT_PUBLIC_STRIPE_API_KEY || process.env.GATSBY_STRIPE_API_KEY;
  return (
    // @ts-ignore
    <StripeProvider apiKey={stripeApiKey}>
      {/* @ts-ignore */}
      <Elements>{children}</Elements>
    </StripeProvider>
  );
};

export default StripeWrapper;
