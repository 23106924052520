import React from 'react';
import { Icons } from '@onehope/design-system-v2';
import { useCauseOfChoiceNetworkLayerValue } from '../../stateManagement/networkLayer';

import { useImageStyles } from '../../utils/commonStyles';
import { ceProfileImage, getCurrentUser } from '../../utils/helper';

//#region Profile Image Component

export default function ProfileImage() {
  const {
    user: parentUser,
    urlProfileImg,
  } = useCauseOfChoiceNetworkLayerValue();

  const user = getCurrentUser(parentUser);
  const ce = user?.shoppingWithCeoUser;
  const hasUserImage = !!ce?.profileImages?.thumb;

  const imageClasses = useImageStyles(
    ceProfileImage(ce?.profileImages?.thumb, urlProfileImg),
  )();

  //#region HTML

  return (
    <>
      {hasUserImage ? (
        <div className={imageClasses.image} id="CauseOfChoice_ProfileImage" />
      ) : (
        <Icons.DefaultProfile width={48} height={48} />
      )}
    </>
  );
}
