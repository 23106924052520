import { gql } from '@apollo/client';

export type FeedQueryParams = {
  dealerId?: string;
  userId?: string;
  limit?: number;
  offset?: number;
  feedType: 'new' | 'saved' | 'archived' | 'completed';
};

/**
 * Fetch the Playbook feed for a given user, including all fields needed to render the full Playbook experience.
 *
 * If `userId` is passed in, that user's feed will be returned.
 * If `userId` is not passed in, but `dealerId` is, the user will be looked up by `dealerId`.
 * If neither is passed in, `null` will be returned.
 */
const FEED_QUERY = gql`
  query ActionEngineFeedQuery(
    $dealerId: String
    $userId: String
    $limit: Int
    $offset: Int
    $feedType: String
  ) {
    viewer {
      id
      v2 {
        id
        actionEngineFeed(
          dealerId: $dealerId
          userId: $userId
          limit: $limit
          offset: $offset
          feedType: $feedType
        ) {
          id
          cardTemplateId
          title
          message
          dateCreated
          dateDeleted
          dateSucceeded
          dateArchived
          dateSaved
          taskSucceeded
          rewardPointBounty
          rewardPointsEarned
          isDeleted
          category
          buttonText
          rewardPointBounty
          rewardPointRedemptionMode
          buttonAction {
            action
            appUrl
            name
            phoneNumber
            body
            emailAddress
            subject
            url
            firstName
            lastName
          }
          image {
            src
            alt
          }
          muxVideo {
            assetId
            playbackId
            ratio
          }
          createContext {
            changeType
            templateName
            triggerType
            userName
            cardRuleContext {
              audienceDirection
              audienceName
            }
          }
          deleteContext {
            changeType
            templateName
            triggerType
            userName
            cardRuleContext {
              audienceDirection
              audienceName
            }
          }
          dynamicDataParams {
            userId
            subjectUserId
          }
        }
      }
    }
  }
`;

export default FEED_QUERY;
