import React from 'react';
import HostRewardsDetails from './HostRewardsDetails';
import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { ContactsContextProvider } from '../../../ContactsV3/ContactsContext';

interface HostRewardsProps {
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
}

function HostRewards({ event, user }: HostRewardsProps) {
  return (
    <ContactsContextProvider contacts={[]}>
      <HostRewardsDetails event={event} user={user} />
    </ContactsContextProvider>
  );
}

export default HostRewards;
