import * as React from 'react';
import styled from '@emotion/styled';
import {
  useTheme,
  useMediaQuery,
  Text,
  Button,
} from '@onehope/design-system-v2';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 0 16px;
  flex-direction: column;
  text-align: center;
  @media all and (min-width: 768px) {
    padding: 24px 40px 0 40px;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  @media all and (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const HeaderTitle = styled(Text)`
  && {
    font-weight: 300;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.03em;
  }
`;

interface HeaderProps {
  setIsOrderDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function Header(props: HeaderProps) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const handlePlaceOrder = () => {
    if (props.setIsOrderDialogOpen) {
      props.setIsOrderDialogOpen(true);
    }
  };
  return (
    <React.Fragment>
      <HeaderContainer>
        <HeaderTitle variant="h3">Customer Orders</HeaderTitle>
        <ButtonsWrapper>
          <ButtonContainer>
            <Button onClick={handlePlaceOrder}>
              {isTablet ? 'New Order' : 'Create New Order'}
            </Button>
          </ButtonContainer>
        </ButtonsWrapper>
      </HeaderContainer>
    </React.Fragment>
  );
}
