import React, { useContext, createContext } from 'react';
import {
  UserType,
  CauseOfChoiceButtonDictionary,
  CauseOfChoiceScreenDictionary,
  CauseOfChoiceViewsDictionary,
  CauseOfChoiceLocationKeys,
  CauseOfChoiceAppDictionary,
  CauseOfChoiceTypeKeys,
  NonProfitType,
  NonProfitWithEditType,
} from './types';

export type DataProps = {
  open: boolean;
  user: UserType;
  app: CauseOfChoiceAppDictionary;
  modalView: CauseOfChoiceViewsDictionary;
  urlProfileImg: string; // url for image profile uploads
  urlEventImgUploads: string; //url for event image uploads
  assetUrl?: string; // url for OH assets
  selectedNonProfit?: NonProfitWithEditType; // the selected NPO, with any applicable edit; for Event Creation/Management flow
  modalLocation: CauseOfChoiceLocationKeys;
  handleCloseModal: () => void;
  handleGoToShop?: () => void;
  handleViewFundraiser?: () => void;
  initialScreen: CauseOfChoiceScreenDictionary; // set starting screen, ex. Load Non Profits Screen first ex:
  //   const initialScreen = CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN, defaults to CauseOfChoiceScreenDictionary.HOME_SCREEN;
  buttons: Array<CauseOfChoiceButtonDictionary>; // buttons to show on main home screen, ex. you may want to hide non-profit search
  //   const buttons = [
  //     CauseOfChoiceButtonDictionary.CE_CAUSE,
  //     CauseOfChoiceButtonDictionary.NON_PROFIT,
  //     CauseOfChoiceButtonDictionary.FUNDRAISER,
  //     CauseOfChoiceButtonDictionary.NOT_SURE,
  //   ];
  onContinue?: () => void; // For checkout use case
  handleNonProfitSelected?: ({
    nonProfitId,
    nonProfitEditId,
  }: {
    nonProfitId: string | null;
    nonProfitEditId?: string | null;
  }) => void;
  trackUpdateCauseOfChoice?: (trackProps: {
    selectedCause: UserType['selectedCause'];
    guestCause: NonProfitType;
    causeType: CauseOfChoiceTypeKeys;
    updateLocation: CauseOfChoiceLocationKeys;
    shoppingWithCeoUser: UserType['shoppingWithCeoUser'];
  }) => void;
};

export type CauseOfChoiceNetworkLayerProps = DataProps & {
  children: React.ReactNode;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const CauseOfChoiceNetworkLayerContext = createContext<DataProps>();

export const useCauseOfChoiceNetworkLayerValue = () =>
  useContext<DataProps>(CauseOfChoiceNetworkLayerContext);

export default function CauseOfChoiceNetworkLayer({
  open,
  app,
  user,
  buttons,
  assetUrl,
  children,
  modalView,
  onContinue,
  modalLocation,
  urlProfileImg,
  initialScreen,
  handleGoToShop,
  handleCloseModal,
  selectedNonProfit,
  urlEventImgUploads,
  handleViewFundraiser,
  handleNonProfitSelected,
  trackUpdateCauseOfChoice,
}: CauseOfChoiceNetworkLayerProps) {
  return (
    <CauseOfChoiceNetworkLayerContext.Provider
      value={{
        open,
        user,
        app,
        buttons,
        assetUrl,
        modalView,
        onContinue,
        modalLocation,
        initialScreen,
        urlProfileImg,
        handleGoToShop,
        handleCloseModal,
        selectedNonProfit,
        urlEventImgUploads,
        handleViewFundraiser,
        handleNonProfitSelected,
        trackUpdateCauseOfChoice,
      }}
    >
      {children}
    </CauseOfChoiceNetworkLayerContext.Provider>
  );
}
