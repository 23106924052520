// Copied and modified from megalith; removed 4 bottle option
import Decimal from 'decimal.js';
import { WineClub_DiscountConfigWCQuery_viewer_v2_discounts } from '../../queries/generatedTypes/WineClub_DiscountConfigWCQuery';

export function getPriceDiscount(
  salePrice: number | null | undefined,
  productPrice: number | null | undefined,
  wineBottleQuantity: number | null | undefined,
  discounts:
    | WineClub_DiscountConfigWCQuery_viewer_v2_discounts
    | null
    | undefined,
) {
  let discountPrice = salePrice || productPrice;

  if (
    discountPrice &&
    (wineBottleQuantity === 6 || wineBottleQuantity === 12)
  ) {
    let dollarsOff;
    // discounts are returned as whole numbers (5, 10. 20 etc) so we need to convert to price by multiplying with 0.01
    switch (wineBottleQuantity) {
      case 6:
        dollarsOff = new Decimal(discountPrice)
          .times(discounts?.bottleDiscount6 || 0)
          .times(0.01);
        break;
      case 12:
        dollarsOff = new Decimal(discountPrice)
          .times(discounts?.bottleDiscount12 || 0)
          .times(0.01);
        break;
      default:
        break;
    }
    if (dollarsOff) {
      discountPrice = new Decimal(discountPrice).minus(dollarsOff).toNumber();
    }
  }

  return discountPrice;
}
