import { gql } from '@apollo/client';

export const ADDRESS_AUTOCOMPLETE = gql`
  mutation CauseOfChoiceAddressAutoCompleteMutation(
    $input: AddressAutoCompleteInput!
  ) {
    addressAutoComplete(input: $input) {
      addressPredictions {
        id
        placeId
        addressLineOne
        addressLineTwo
        city
        state
        zip
        addressId
        country
      }
    }
  }
`;

export default ADDRESS_AUTOCOMPLETE;
