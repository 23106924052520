import {
  Grid,
  makeStyles,
  ThemeType,
  Icons,
  Checkbox as MuiCheckbox,
  styled,
  theme,
} from '@onehope/design-system-v2';
const { Close } = Icons;

export const CloseIcon = styled(Close)({
  position: 'absolute',
  top: '40px',
  right: '40px',
  cursor: 'pointer',
  [theme.breakpoints.down('xs')]: { top: '16px', right: '16px' },
});

export const ContainerGrid = styled(Grid)({
  padding: '48px',
  [theme.breakpoints.down('xs')]: { padding: '24px' },
});

export const ButtonsContainer = styled(Grid)({
  gap: '16px',
});

export const Checkbox = styled(MuiCheckbox)({
  paddingLeft: 0,
});

export const useFormStyles = makeStyles((theme: ThemeType) => ({
  main: {
    padding: 0,
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
  },
  close: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    cursor: 'pointer',
  },
  container: {
    padding: '40px 40px',
    [theme.breakpoints.down('xs')]: { padding: '24px 16px' },
  },
  centerContainer: {
    textAlign: 'center',
    padding: '40px 40px',
    [theme.breakpoints.down('xs')]: { padding: '24px 16px' },
  },
  headerText: {
    padding: '8px 0 0 0',
    fontSize: '24px',
    lineHeight: '36px',
  },
  summaryText: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: '32px 0 16px 0',
  },
  form: {
    paddingTop: '24px',
    width: '100%',
  },
  formItem: {
    paddingBottom: '16px',
  },
  button: {
    paddingTop: '16px',
    width: '100%',
  },
  secondaryButton: {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    fontSize: 12,
    lineHeight: '16px',
    paddingBottom: 16,
  },
}));
