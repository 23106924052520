import React, { useMemo } from 'react';

// material-ui
import {
  useTheme,
  Box,
  Drawer,
  useMediaQuery,
} from '@onehope/design-system-v2';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { useAppGlobalContextValue } from '../../../contexts/AppGlobalContext/context';

export const DRAWER_WIDTH = 250;
// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  window?: () => Window;
  handleDrawerToggle?: () => void;
}

const MainDrawer = ({ handleDrawerToggle, window }: Props) => {
  const theme = useTheme();
  const [{ drawerOpen }] = useAppGlobalContextValue();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  // responsive drawer container
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={drawerOpen} />, [
    drawerOpen,
  ]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}>
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" theme={theme} open={drawerOpen}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          transitionDuration={{
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
          }}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', width: drawerOpen ? DRAWER_WIDTH : 0 },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              color: 'white',
              borderRight: `0px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit',
            },
          }}
        >
          {drawerHeader}
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default MainDrawer;
