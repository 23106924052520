export enum EventTypeEnum {
  WineTasting = 'WINE_TASTING',
  Fundraiser = 'FUNDRAISER',
}

export enum EventHostingTypeEnum {
  Contact = 'CONTACT',
  NonProfit = 'NONPROFIT',
  Self = 'SELF_HOSTED',
}

export enum EventVersionTypeEnum {
  v2 = '2.0',
  v31 = '3.1',
}
