import * as React from 'react';
import { PageRendererProps } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../layout';
import Credits from '../components/Credits';

import { getCurrentUserId } from '../utils/utils';

const Container = styled.div`
  background-color: white;
  height: 100%;
`;

function CreditsPage(props: PageRendererProps) {
  return (
    <Layout>
      <Container>
        <Credits />
      </Container>
    </Layout>
  );
}

export default CreditsPage;
