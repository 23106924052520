import React from 'react';
import styled from '@emotion/styled';

import CreditsCard from '../../Credits/CreditsTable/CardListView/CreditsCard';
import ContactCard from '../../Contacts/CardListView/ContactCard';
import OrdersCard from '../../../common/Orders/CardListView/OrderCard';
import EventCard from '../../Events/EventsTable/CardListView/EventCard';

import { CEDash_EventsV2Query_viewer_user_eventsV2_edges as EventsEdges } from '../../../queries/generatedTypes/CEDash_EventsV2Query';
import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { CEDash_CustomersQuery_viewer_user_customers_edges as Contacts } from '../../../queries/generatedTypes/CEDash_CustomersQuery';
import { CEDash_CreditsQuery_viewer_user_credits_edges as CreditsEdges } from '../../../queries/generatedTypes/CEDash_CreditsQuery';

const GridList = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 20px;
  grid-auto-flow: dense;
  max-width: ${({ count }) => (count < 3 ? '756px' : '100%')};
  @media all and (min-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
  }
`;

interface CardProps {
  listItems: (any | null)[] | null;
  baseRoute: string;
  callbackFn?: () => void;
  cardSpecificProps?: any;
}

export default function Cards(props: CardProps) {
  const { listItems, baseRoute, callbackFn, cardSpecificProps } = props;
  const listItemCount = (listItems && listItems.length) || 0;

  return (
    <GridList count={listItemCount}>
      {baseRoute === 'credits' &&
        // eslint-disable-next-line react/no-multi-comp
        listItems?.map((credit: CreditsEdges) => (
          <CreditsCard
            key={`${credit?.node?.creditId}`}
            credit={credit?.node}
            creditCount={listItemCount}
          />
        ))}
      {baseRoute === 'contacts' &&
        !!callbackFn &&
        // eslint-disable-next-line react/no-multi-comp
        listItems?.map((contact: Contacts) => (
          <ContactCard
            key={`${contact?.node?.id}`}
            contact={contact?.node}
            contactCount={listItemCount}
            handleOrderAdd={callbackFn}
          />
        ))}
      {baseRoute === 'orders' &&
        // eslint-disable-next-line react/no-multi-comp
        listItems?.map((order: OrdersEdges) => (
          <OrdersCard order={order?.node} orderCount={listItemCount} />
        ))}
      {baseRoute === 'events' &&
        // eslint-disable-next-line react/no-multi-comp
        listItems?.map((event: EventsEdges) => (
          <EventCard
            event={event?.node}
            eventCount={listItemCount}
            contactId={cardSpecificProps.contactId}
            setSelectedEventId={cardSpecificProps.setSelectedEventId}
            handleOrderAdd={cardSpecificProps.handleOrderAdd}
            toggleEventCopyUrlDialog={
              cardSpecificProps.toggleEventCopyUrlDialog
            }
          />
        ))}
    </GridList>
  );
}
