import React from 'react';
import { PhoneNumber } from '@onehope/design-system-ohw';
import { Grid, makeStyles, Text } from '@onehope/design-system-v2';

import Props from '../types/ButtonActionContentProps';
import { ButtonActions } from './constants';

const useStyles = makeStyles(() => ({
  headline: {
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '32px',
  },
  subheader: {
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.05em',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: '8px',
  },
  actionMessage: {
    fontSize: '14px',
    lineHeight: '21px',
    marginBottom: '16px',
    fontWeight: 'normal',
  },
  body: {
    lineHeight: '24px',
    fontSize: '16px',
    marginBottom: '32px',
  },
  subjectName: {
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '8px',
  },
  subjectPhone: {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '32px',
  },
  textWrapper: {
    textAlign: 'inherit',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const formatFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
) => {
  if (!firstName && !lastName) return;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else {
    return firstName ? firstName : lastName;
  }
};

export default function ButtonActionContent({ button }: Props) {
  const classes = useStyles();
  const actionMessagePrompt =
    'Copy and paste the text below and send via text message:';
  const formattedName = formatFullName(button.firstName, button.lastName);

  return (
    <Grid className={classes.textWrapper}>
      {button.action === ButtonActions.textMessage && (
        <>
          {/** For text actions, styling and verbiage of the body's header changes if it's for a contact (includes the phone number) or a mass group */}
          {button.phoneNumber ? (
            <Text variant={'body1'} className={classes.subheader}>
              Message
            </Text>
          ) : (
            <Text variant={'body1'} className={classes.actionMessage}>
              {actionMessagePrompt}
            </Text>
          )}
          {button.body && (
            <Text className={classes.body} variant={'body1'}>
              {button.body}
            </Text>
          )}
        </>
      )}
      {button.phoneNumber && (
        <>
          <Text className={classes.subheader} variant={'body1'}>
            {/** phoneCall and textMessage button actions will render different subheaders */}
            {button.action === ButtonActions.phoneCall
              ? 'Contact'
              : 'Recipient'}
          </Text>
          {formattedName && (
            <Text className={classes.subjectName} variant={'body1'}>
              {formattedName}
            </Text>
          )}
          <Text className={classes.subjectPhone} variant={'body1'}>
            <PhoneNumber rawValue={button.phoneNumber} />
          </Text>
        </>
      )}
    </Grid>
  );
}
