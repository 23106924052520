import * as React from 'react';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider, DatePickerProps } from '@material-ui/pickers';
import { Label, MuiDatePicker, DateIcon } from './input.styles';

type DatePickerContainerProps = DatePickerProps & {
  label?: string;
};

export default function DatePickerContainer(props: DatePickerContainerProps) {
  const { label, ...rest } = props;
  return (
    <React.Fragment>
      {label && (
        <div>
          <Label htmlFor={props.name}>{label}</Label>
        </div>
      )}
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <MuiDatePicker keyboardIcon={<DateIcon />} {...rest} />
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}
