import gql from 'graphql-tag';

const ShopWithAccountChangeCreateMutation = gql`
  mutation CreateShoppingWithAccountChange(
    $customerAccountId: String!
    $ceAccountId: String!
  ) {
    shopWithAccountChangeCreate(
      input: {
        customerAccountId: $customerAccountId
        ceAccountId: $ceAccountId
      }
    ) {
      shoppingWithAccountChange {
        shoppingWithAccountChangeId
      }
    }
  }
`;

export default ShopWithAccountChangeCreateMutation;
