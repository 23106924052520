import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ThemeProvider, theme } from '@onehope/design-system-v2';

import IMAGE_ADD_MUTATION from '../../../mutations/Image/ImageAddMutation';
import GET_IMAGE_PRESIGNED_URL_MUTATION from '../../../mutations/Image/ImagePresignedUrlGetMutation';

import { CEDash_EventDetailsPage_viewer_event } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import UploadImageForm from '../UploadImageForm';
import { uploadImage } from './submitHelpers';
import { ImageValuesType } from '../../Dialog/EventCreateDialog/UploadImage';

interface EditImageProps {
  event: CEDash_EventDetailsPage_viewer_event;
  setIsImageUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EditImage(props: EditImageProps) {
  const { setIsImageUpdated } = props;
  const [imageAddMutation] = useMutation(IMAGE_ADD_MUTATION);
  const [getPresignedUrl] = useMutation(GET_IMAGE_PRESIGNED_URL_MUTATION);
  const [loading, setLoading] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <UploadImageForm
        {...props}
        editing={true}
        loading={loading}
        /* eslint-disable react/jsx-no-bind */
        onUpload={async (imageValues: ImageValuesType) => {
          uploadImage({
            imageAddMutation,
            getPresignedUrl,
            values: imageValues,
            groupName: `events/${process.env.GATSBY_IMAGE_UPLOAD_PATH}`,
            authorId: props?.event?.eventId,
            imageType: 'event',
            setLoading,
          });
          if (setIsImageUpdated) {
            setIsImageUpdated(true);
          }
        }}
      />
    </ThemeProvider>
  );
}
