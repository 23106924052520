import { navigate } from 'gatsby';

/**
 * This function will be invoked by `@onehope/action-engine` any time a button is pressed.
 * If special behavior should be applied (Gatsby navigation, etc.), execute that behavior and return true.
 * Otherwise, return false and `@onehope/action-engine` will perform its default navigation behavior.
 * @param url
 */
export default function navigationHandler(rawUrl: string): boolean {
  const url = rawUrl.trim();

  /**
   * Map an iOS in-app link such as `onehope://importContacts` to an equivalent URL for the web app.
   */

  // === these links will open in the same browser tab (via Gatsby `navigate()`) ===
  const sameWindowLinks = new Map<string, string>();
  sameWindowLinks.set('onehope://importContacts', '/contacts/?showImport');
  sameWindowLinks.set('onehope://winequiz', '/reach-outs');
  // Reach outs v2 in-app link
  sameWindowLinks.set('onehope://reach-out', '/reach-outs');

  // === these links will open in a new tab (via `window.open()`) ===
  const newWindowLinks = new Map<string, string>();
  newWindowLinks.set(
    'onehope://rewards',
    `${process.env.GATSBY_MEGALITH_URL}/account/rewards`,
  );

  if (sameWindowLinks.has(url)) {
    navigate(sameWindowLinks.get(url));
    return true;
  }

  if (newWindowLinks.has(url)) {
    window.open(newWindowLinks.get(url), '_blank');
    return true;
  }

  // wine quiz results are displayed in the megalith, so we'll open them in a new tab
  // the URL in Contentful is onehope://winequiz/results?contactId={{ $contactId }}&requestId={{ $requestId }}&reachoutId={{ $reachoutId }}
  if (url.includes('onehope://winequiz/results?')) {
    const [_, paramsString] = url.split('?');
    const newUrl = `${process.env.GATSBY_CE_DASH_URL}/reach-outs/results/contact-results?${paramsString}`;
    window.open(newUrl, '_blank');
    return true;
  }

  // the URL in Contentful is onehope://reach-out?reachoutId={{ $reachoutId }}
  if (url.includes('onehope://reach-out?')) {
    const [_, paramsString] = url.split('?');
    const newUrl = `${process.env.GATSBY_CE_DASH_URL}/reach-outs?${paramsString}`;
    window.open(newUrl, '_blank');
    return true;
  }

  return false;
}
