import React, { useCallback } from 'react';
import { Text, Grid, TEXT_DS, theme, Icons } from '@onehope/design-system-v2';

const ReachOutCarouselPagination = ({
  currentViewIndex,
  setCurrentViewIndex,
  numberOfSlides,
}: {
  currentViewIndex: number;
  setCurrentViewIndex: React.Dispatch<React.SetStateAction<number>>;
  numberOfSlides: number;
}) => {
  const onChangeIndex = useCallback(
    (index: number, indexLatest: number) => {
      setCurrentViewIndex(index);
    },
    [setCurrentViewIndex],
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        <Text
          style={{ color: '#88898A' }}
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_ITALIC_16}
        >
          Swipe for more previews
        </Text>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {numberOfSlides &&
            Array.apply(null, Array(numberOfSlides)).map((value, index) =>
              index === currentViewIndex ? (
                <Grid item>
                  <Icons.Circle
                    sx={{
                      width: '10px',
                      color: theme.palette.forestGreen.main,
                    }}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <Icons.CircleOutlined
                    onClick={() => onChangeIndex(index, currentViewIndex)}
                    sx={{
                      width: '10px',
                      color: theme.palette.forestGreen.main,
                    }}
                  />
                </Grid>
              ),
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReachOutCarouselPagination;
