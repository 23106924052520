import * as React from 'react';
import styled from '@emotion/styled';
import { Text, TEXT_DS } from '@onehope/design-system-v2';

// import SvgTimes from '../../../../assets/times';

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 12px;
  margin-bottom: 24px;
  border: solid 1px #d19400;
  background-color: #fffcf0;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
`;

const CloseSvgContainer = styled.span`
  cursor: pointer;
  margin-top: -10px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

type WarningCardProps = {
  message: string;
  setClose: () => void;
};

export default function WarningCard(props: WarningCardProps) {
  return (
    <WarningContainer>
      <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
        <BoldText>WARNING: </BoldText>
        {props.message}
      </Text>
      {/*<CloseSvgContainer onClick={props.setClose}>*/}
      {/*  <SvgTimes />*/}
      {/*</CloseSvgContainer>*/}
    </WarningContainer>
  );
}
