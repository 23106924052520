import { css } from 'emotion';
import { colors } from './colors.style';

// Atom base styles

export const fabCircle = css`
  && {
    height: 56px;
    width: 56px;
    border-radius: 100px;
    background-color: ${colors.blackBrand};
  }
`;

export const fabPill = css`
  && {
    height: 56px;
    width: 111px;
    border-radius: 100px;
    background-color: ${colors.blueBrand};
  }
`;

export const fabSquare = css`
  && {
    height: 56px;
    width: 56px;
    background-color: ${colors.blackBrand};
  }
`;
