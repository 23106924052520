import { gql } from '@apollo/client';
// a798c4f0-759e-4dfb-a3eb-49af5702f2f0 is the wineclub role id.
// It didn't like when I used a variable for some reason.

const GET_WC_DISCOUNT_CONFIG = gql`
  query WineClub_DiscountConfigWCQuery {
    viewer {
      id
      v2 {
        discounts(roles: ["a798c4f0-759e-4dfb-a3eb-49af5702f2f0"]) {
          bottleDiscount6
          bottleDiscount12
        }
      }
    }
  }
`;

export default GET_WC_DISCOUNT_CONFIG;
