import _orderBy from 'lodash/orderBy';
import * as React from 'react';
import Decimal from 'decimal.js';

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersType } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { evaluateOrderStatus } from '../../utils/utils';
import { OrderStatus } from '../../utils/orderConstants';

import OrderProcessingSvg from '../../assets/orderProcessing';
import OrderErrorSvg from '../../assets/orderError';
import OrderSuccessSvg from '../../assets/orderSuccess';

export function handleSortByFullName(
  orders: (OrdersType | null)[] | null,
  nameSortDirection: boolean,
) {
  if (!nameSortDirection) {
    return _orderBy(orders, [e => e?.node?.accountFullName], ['desc']);
  } else {
    return _orderBy(orders, [e => e?.node?.accountFullName]);
  }
}

export function handleSortByStatus(
  orders: (OrdersType | null)[] | null,
  statusSortDirection: boolean,
) {
  if (!statusSortDirection) {
    return _orderBy(orders, [e => e?.node?.orderStatus], ['desc']);
  } else {
    return _orderBy(orders, [e => e?.node?.orderStatus]);
  }
}

export function handleSortByCreateDate(
  orders: (OrdersType | null)[] | null,
  dateSortDirection: boolean,
) {
  if (dateSortDirection) {
    return _orderBy(orders, [e => e?.node?.createDate]);
  } else {
    return _orderBy(orders, [e => e?.node?.createDate], ['desc']);
  }
}

export function handleSortBySubTotalWithDiscounts(
  orders: (OrdersType | null)[] | null,
  subTotalWithDiscountsSortDirection: boolean,
) {
  if (subTotalWithDiscountsSortDirection) {
    return _orderBy(
      orders,
      [
        e => {
          return e?.node?.orderSubTotalAfterDiscounts
            ? Number(e.node.orderSubTotalAfterDiscounts)
            : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(orders, [
      e => {
        return e?.node?.orderSubTotalAfterDiscounts
          ? Number(e.node.orderSubTotalAfterDiscounts)
          : 0;
      },
    ]);
  }
}

export function handleSortByTotal(
  orders: (OrdersType | null)[] | null,
  totalSortDirection: boolean,
) {
  if (totalSortDirection) {
    return _orderBy(
      orders,
      [
        e => {
          return e?.node?.orderTotal ? Number(e?.node?.orderTotal) : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(orders, [
      e => {
        return e?.node?.orderTotal ? Number(e?.node?.orderTotal) : 0;
      },
    ]);
  }
}

export function handleSortByOrderNumber(
  orders: (OrdersType | null)[] | null,
  subTotalSortDirection: boolean,
) {
  if (subTotalSortDirection) {
    return _orderBy(
      orders,
      [
        e => {
          return e?.node?.orderNumber
            ? Number(e?.node?.orderNumber.replace(/-/g, ''))
            : 0;
        },
      ],
      ['desc'],
    );
  } else {
    return _orderBy(orders, [
      e => {
        return e?.node?.orderNumber
          ? Number(e?.node?.orderNumber.replace(/-/g, ''))
          : 0;
      },
    ]);
  }
}

export function handleSearchAllOrders(
  orders: (OrdersType | null)[] | null,
  filterBy: string,
  search: string,
) {
  if (search && search !== '' && orders) {
    const filteredOrders = getFilteredOrders(search, orders);
    return handleFilterByTab(filteredOrders, filterBy);
  }
  return handleFilterByTab(orders, filterBy);
}

function getFilteredOrders(
  search: string,
  orders: (OrdersType | null)[] | null,
) {
  const searchText = search.toLowerCase().replace(/\s/g, '');
  return (
    orders &&
    orders.filter(order => {
      const eventOwnerName =
        order && order.node && order.node.accountFullName
          ? order.node.accountFullName.toLowerCase().replace(/\s/g, '')
          : '';
      const orderNumber =
        order && order.node && order.node.orderNumber
          ? order.node.orderNumber.toString().replace(/-/g, '')
          : '';
      return (
        eventOwnerName.includes(searchText) || orderNumber.includes(searchText)
      );
    })
  );
}

export function handleFilterByTab(
  orders: (OrdersType | null)[] | null,
  filterBy: string,
  search?: string,
) {
  if (orders) {
    let filteredOrders = orders;
    switch (filterBy) {
      case 'open':
        filteredOrders = orders.filter(
          e => evaluateOrderStatus(e?.node?.orderStatus) !== 'cancelled',
        );
        break;
      case 'cancelled':
        filteredOrders = orders.filter(
          e => evaluateOrderStatus(e?.node?.orderStatus) === 'cancelled',
        );
        break;
    }
    return search
      ? handleTabFilterSearch(filteredOrders, search)
      : filteredOrders;
  }
  return orders;
}

export function handleTabFilterSearch(
  orders: (OrdersType | null)[] | null,
  search: string,
) {
  if (search && search !== '' && orders) {
    return getFilteredOrders(search, orders);
  }
  return orders;
}

export function getOrderStatusIcon(status: string | null) {
  if (status) {
    switch (evaluateOrderStatus(status)) {
      case 'delivered':
        return { icon: <OrderSuccessSvg />, status: 'Delivered' };
      case 'complete':
        return { icon: <OrderSuccessSvg />, status: 'Complete' };
      case 'cancelled':
        return { icon: <OrderErrorSvg />, status: 'Cancelled' };
      case 'exception':
        return { icon: <OrderErrorSvg />, status };
      case 'processing':
      default:
        return { icon: <OrderProcessingSvg />, status: 'Processing' };
    }
  }
  return { icon: <OrderErrorSvg />, status: '' };
}

export function isStatusValidForCancel(orderStatus: string | null) {
  switch (orderStatus) {
    case OrderStatus.delivered:
    case OrderStatus.complete:
    case OrderStatus.cancelled:
    case OrderStatus.undetermined: {
      return false;
    }
    default: {
      return true;
    }
  }
}
