import React from 'react';
import { navigate } from 'gatsby';
import {
  Grid,
  makeStyles,
  Text,
  ThemeType,
  useTheme,
  useMediaQuery,
} from '@onehope/design-system-v2';
import CareTeamMember from './CareTeamMember';
import { CareTeamMemberType } from './index';

const useStyles = makeStyles((theme: ThemeType) => ({
  secondaryHeader: {
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '36px',
    letterSpacing: '0.04em',
  },
  rankTitle: {
    fontWeight: 420,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    marginBottom: '4px',
    color: theme.palette.greyDark.main,
    paddingTop: 16,
  },
  border: {
    borderBottom: `1px solid ${theme.palette.veryLightGrey.main}`,
  },
}));

type CareTeamSectionProps = {
  title: string;
  members: Array<CareTeamMemberType>;
  bottomBorder?: boolean;
};

export default function CareTeamSection(props: CareTeamSectionProps) {
  const { title, members, bottomBorder } = props;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  const classes = useStyles();
  const borderClassName = bottomBorder ? classes.border : '';

  if (!members.length) return null;

  return (
    <div className={borderClassName}>
      <Text className={classes.rankTitle}>{title}</Text>
      <Grid
        container
        direction={isTablet ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems={isTablet ? 'center' : 'unset'}
      >
        {members?.map((member: CareTeamMemberType) => (
          <CareTeamMember member={member} />
        ))}
      </Grid>
    </div>
  );
}
