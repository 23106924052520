import React from 'react';
import { Link } from 'gatsby';
import {
  theme,
  styled,
  Text,
  TEXT_DS,
  useMediaQuery,
} from '@onehope/design-system-v2';

export const InvitesPageNames = {
  sms: 'Share via SMS',
  email: 'Share via Email',
};

const StyledBreadCrumb = styled(Text)({
  display: 'inline-block',
  color: `${theme.palette.greyDark.main} !important`,
  marginRight: '8px',
});

const truncateString = (input: string) => {
  if (!input) {
    return '';
  }
  return input.length > 31 ? `${input.substring(0, 31)}...` : input;
};

const BreadCrumbText = ({ text }: { text: string }) => {
  return (
    <StyledBreadCrumb variant="custom" default={TEXT_DS.BODY_SEMIBOLD_12}>
      {text}
    </StyledBreadCrumb>
  );
};

const SelectedPage = styled(Text)({
  display: 'inline-block',
  color: theme.palette.primary.main,
  marginRight: '8px',
});

const DisplayInline = styled('div')(() => ({
  textAlign: 'left',
  maxWidth: '824px',
  paddingTop: '24px',
  paddingLeft: '48px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '16px',
  },
}));

function BreadCrumb({
  page,
  reachOutId,
  previousPage,
}: {
  page: string;
  reachOutId?: string;
  previousPage?: string;
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const previousPageText = isMobile
    ? truncateString(previousPage)
    : previousPage;
  const pageText = isMobile ? truncateString(page) : page;
  return (
    <DisplayInline>
      <Link to={`/reach-outs`}>
        <BreadCrumbText text="Reach outs" />
      </Link>
      {(page === InvitesPageNames.email ||
        page === InvitesPageNames.sms ||
        page === 'Results') && (
        <>
          <BreadCrumbText text=">" />
          <Link to={`/reach-outs?reachoutId=${reachOutId}`}>
            <BreadCrumbText text={previousPageText} />
          </Link>
        </>
      )}
      <BreadCrumbText text=">" />
      <SelectedPage variant="custom" default={TEXT_DS.BODY_SEMIBOLD_12}>
        {pageText}
      </SelectedPage>
    </DisplayInline>
  );
}
export default BreadCrumb;
