import React from 'react';
import { AlgoliaHit, Product } from '@onehope/algolia-components';
import {
  ButtonV2,
  Icons,
  useMediaQuery,
  theme,
  Grid,
} from '@onehope/design-system-v2';
import { excludedSwapProducts } from '../../utils/constants';
import {
  getButtonDisabledStatus,
  getButtonText,
  determineUserRoles,
} from './swapButtonHelpers';
import { WineClub_AutoShipGetQuery_viewer_user } from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';

const { RadioButtonUncheckedIcon, CheckCircleIcon } = Icons;

const getIcon = ({
  isSelected,
  mutationLoading,
}: {
  isSelected: boolean;
  mutationLoading: boolean;
}) => {
  if (!isSelected) {
    return <RadioButtonUncheckedIcon />;
  }
  if (mutationLoading) {
    return <CheckCircleIcon style={{ fill: theme.palette.greyDark.main }} />;
  }
  return <CheckCircleIcon style={{ fill: theme.palette.primary.main }} />;
};

type Props = {
  hit: AlgoliaHit;
  mutationLoading: boolean;
  selectedProductId: string;
  setSelectedProductId: (input: string) => void;
  setSelectedProduct: React.Dispatch<React.SetStateAction<AlgoliaHit | null>>;
  user: WineClub_AutoShipGetQuery_viewer_user;
};

export default function SwapProduct({
  hit,
  mutationLoading,
  selectedProductId,
  setSelectedProduct,
  setSelectedProductId,
  user,
}: Props) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  if (Object.keys(excludedSwapProducts).includes(hit.productId)) {
    return null;
  }

  // calculate array of allowed user roles from oneCommerce permissions
  const allowedUserRoles = determineUserRoles(user);
  const buttonStatus = getButtonDisabledStatus({
    product: hit,
    userPermissions: allowedUserRoles,
  });
  const buttonEnabled =
    !buttonStatus.disabled && !hit.isOutOfStock && !mutationLoading;

  const isSelected = hit.productId === selectedProductId;
  const buttonText = getButtonText({ isDesktop, product: hit });
  const buttonEndIcon = getIcon({
    isSelected,
    mutationLoading,
  });

  // use the addToCartButtonStatus to determine some properties
  const isLocked = !!(
    buttonStatus?.reason && buttonStatus.reason.includes('locked')
  );

  const onClick = () => {
    if (!isSelected) {
      setSelectedProductId(hit.productId);
      setSelectedProduct(hit);
    } else {
      setSelectedProductId('');
      setSelectedProduct(null);
    }
  };

  const button = (
    <ButtonV2
      type="wineClub"
      onClick={onClick}
      fullWidth
      disabled={!buttonEnabled}
      selected={isSelected}
      locked={isLocked}
      endIcon={buttonEndIcon}
    >
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <div>{buttonText}</div>
        </Grid>
      </Grid>
    </ButtonV2>
  );

  return (
    <Product
      isAdded={isSelected}
      key={hit.objectID}
      product={hit}
      ButtonComponent={() => button}
    />
  );
}
