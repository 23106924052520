import gql from 'graphql-tag';

const GET_CUSTOMER_AUTOSHIPS = gql`
  query CustomerAutoshipsContactsV2(
    $maxWineCount: Int
    $take: Int
    $skip: Int
  ) {
    viewer {
      user {
        customerAutoships(
          maxWineCount: $maxWineCount
          take: $take
          skip: $skip
        ) {
          total
          autoships {
            autoShipId
            autoShipName
            contactId
            mobilePhone
            firstName
            lastName
            email
            id
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMER_AUTOSHIPS;
