import gql from 'graphql-tag';

export const OrderFragment = gql`
  fragment Order_order on Order {
    id
    orderId
    createDate
    orderNumber
    orderStatus
    orderTotal
    orderTax
    orderSubTotal
    orderShipping
    shippingAddress {
      firstName
      lastName
      addressLineOne
      addressLineTwo
      city
      state
      zip
      phone
    }
    giftMessage {
      message
      recipientName
      from
    }
    paymentInfo {
      last4
      brand
      expYear
      expMonth
    }
    orderItems {
      id
      quantity
      priceBought
      product {
        id
        productId
        name
        price
        priceSale
        hostKitUpsellPrice
        images {
          productCatalog
          squareFront
          hero
        }
      }
    }
    shippingChosen
  }
`;

const ORDER_WINE_SUMMARY_QUERY = gql`
  query CEDash_OrderWineSummaryPage($orderId: String) {
    viewer {
      id
      user {
        id
        userId
        email
        permissions {
          id
          oneCommerce {
            admin {
              access
            }
          }
        }
      }
      order(orderApiId: $orderId) {
        ...Order_order
      }
    }
  }
  ${OrderFragment}
`;

export default ORDER_WINE_SUMMARY_QUERY;
