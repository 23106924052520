import React from 'react';
import { ReactMuiMask } from '@onehope/design-system-ohw';
import { TextFieldProps } from '@onehope/design-system-v2';

function timeMask(value: string) {
  const chars = value.split('');
  const hours = [/[0-1]/, chars[0] == '2' ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  return hours.concat(':').concat(minutes);
}

export default function TimeMask(props: TextFieldProps) {
  return <ReactMuiMask mask={timeMask} {...props} />;
}
