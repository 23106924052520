import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

// queries
import GET_USER_QUERY from '../../queries/UserGetQuery';
import { Segment_UserQuery } from '../../queries/generatedTypes/Segment_UserQuery';

// helpers
import { isSegmentEnabled, getWindowParams } from '../../utils/helpers';
import { excludePageView } from '../../utils/excludePageView';
import { standardPageView } from '../../utils/standardPageView';

type StandardPageViewProps = {
  enabled: string | boolean | undefined;
  isMegalith?: boolean;
  isCeDashboard?: boolean;
};

export const StandardPageView = (props: StandardPageViewProps) => {
  const { enabled, isMegalith = false, isCeDashboard = false } = props;

  const [getUser] = useLazyQuery<Segment_UserQuery>(GET_USER_QUERY, {
    // always fetch from server to ensure latest user role data, etc.
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // check that window and analytics are defined
      if (typeof window !== 'undefined' && typeof analytics !== 'undefined') {
        const isLoggedIn = data.viewer?.isLoggedIn;
        const user = data.viewer?.user;

        // check that segment is enabled within the app and user is available
        if (isSegmentEnabled(enabled) && user) {
          const skipPageView = excludePageView({
            user: data.viewer?.user,
            isMegalith,
          });

          if (!skipPageView) {
            standardPageView({ user, isLoggedIn, isCeDashboard });
          }
        }
      }
    },
  });

  const { pathname, search } = getWindowParams();

  useEffect(() => {
    getUser();
  }, [pathname, search]);

  return null;
};
