import React from 'react';
import ButtonActionContent from './ButtonActionContent';
import ButtonActionModalProps from '../types/ButtonActionModalProps';
import { ButtonActions } from './constants';

import {
  Button,
  Grid,
  Icons,
  makeStyles,
  Text,
} from '@onehope/design-system-v2';

const useStyles = makeStyles(() => ({
  action: {
    backgroundColor: '#FFFFFF',
    height: 'min-content',
    padding: '40px',
  },
  close: {
    padding: 0,
    margin: 0,
    top: '2px',
    right: '3px',
    height: 'auto',
    width: 'auto',
    position: 'absolute',
  },
  contentWrapper: {
    textAlign: 'center',
    height: 'min-content',
    display: 'flex',
    flexDirection: 'column',
  },
  headline: {
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '32px',
  },
  button: {},
}));

const getHeader = (action: string): string | null => {
  switch (action) {
    case ButtonActions.phoneCall:
      return 'Call Now';
    case ButtonActions.textMessage:
      return 'Send Text';
    default:
      return null;
  }
};

export default function ButtonActionModal({
  button,
  onDialogClose,
}: ButtonActionModalProps) {
  const classes = useStyles();

  const headline = getHeader(button.action);
  const closeButtonText = 'Done';

  return (
    <Grid className={classes.action}>
      <Grid className={classes.close}>
        <Icons.Close onClick={onDialogClose}></Icons.Close>
      </Grid>
      <Grid className={classes.contentWrapper}>
        <Text className={classes.headline} variant={'h3'}>
          {headline}
        </Text>
        <ButtonActionContent button={button} />
        <Button className={classes.button} onClick={onDialogClose}>
          {closeButtonText}
        </Button>
      </Grid>
    </Grid>
  );
}
