import { gql } from '@apollo/client';
import { AutoShipFragment } from './fragments/AutoshipFragment';
import { GetPaymentFragment } from './fragments/PaymentFragment';

const AUTO_SHIP_GET = gql`
  query WineClub_AutoShipGetQuery($autoShipId: String!, $byContactId: String) {
    viewer {
      id
      user {
        id
        userId
        permissions {
          id
          oneCommerce {
            id
            ceo {
              access
            }
            subscriber {
              access
            }
            collective {
              access
            }
            employee {
              access
            }
            wineryEmployee {
              access
            }
          }
        }
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...WineClub_GetPayment
                }
              }
            }
          }
        }
        autoShip(autoShipId: $autoShipId, byContactId: $byContactId) {
          ...WineClub_GetAutoShip
          latestSuccessfulInvoice: latestInvoice(invoiceStatus: "paid") {
            dateCreated
            invoiceStatus
          }
        }
      }
      userInfo(contactId: $byContactId) {
        id
        userId
        permissions {
          id
          oneCommerce {
            id
            ceo {
              access
            }
            subscriber {
              access
            }
            collective {
              access
            }
            employee {
              access
            }
            wineryEmployee {
              access
            }
          }
        }
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...WineClub_GetPayment
                }
              }
            }
          }
        }
      }
    }
  }
  ${AutoShipFragment}
  ${GetPaymentFragment}
`;

export default AUTO_SHIP_GET;
