import {
  segmentCookieV2,
  segmentIdentify,
  defaultSegmentPageView,
  customSegmentPageView,
  SegmentUser,
} from './segment';

export type ManualPageViewOptions = {
  page?: string;
  path?: string;
};
/**
 * Helper function to trigger a manual Identify/Page View.
 * Similar to standardPageView, but with an option for additional
 * properties.
 *
 * To be used for special cases within the
 * Gastby/NextJS apps; should be used sparingly.
 *
 */
export const manualPageView = ({
  user,
  isLoggedIn,
  options = {},
}: {
  user: SegmentUser;
  isLoggedIn: boolean;
  options?: ManualPageViewOptions;
}) => {
  if (typeof window !== 'undefined' && typeof analytics !== 'undefined') {
    // Send user to Segment to set cookie
    segmentCookieV2({ user, isLoggedIn });

    // Identify user
    segmentIdentify({ user });

    // Send page view
    const optionKeys = Object.keys(options);
    if (optionKeys.length) {
      customSegmentPageView(options);
    } else {
      defaultSegmentPageView({});
    }
  }
};
