import React from 'react';
import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Grid, TEXT_DS } from '@onehope/design-system-v2';

/* Components */
import FormContainer from '../../FormContainer';
import Notification from '../../../Notifcations/DefaultNotifcation';
import CharityDetailsForm from '../../../Events/CharityDetailsForm';
import CharityDetailsSummary from '../../../Events/CharityDetailsForm/CharityDetailsSummary';

/* Mutations & Queries */
import PRE_APPROVED_DONATION_ADD from '../../../../mutations/Donations/PreApprovedDonationAdd';
import CUSTOM_DONATION_ADD from '../../../../mutations/Donations/DonationsAddMutation';
import DONATION_V2_ADD from '../../../../mutations/Donations/DonationV2AddMutation';
import EVENT_UPDATE_MUTATION from '../../../../mutations/Event/EventUpdateMutation';
import GET_PRE_APPROVED_NON_PROFITS_QUERY from '../../../../queries/NonProfit';

/* Types */
import { MyFormValues } from './FormTypes';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

/* Helpers & Functions */
import useToggle from '../../../../utils/useToggleHook';
import Loading from '../../../../common/Loading';
import { getDefaultValues } from './helpers';
import { getCharitySchema } from './validationSchema';
import { isSatelliteEvent } from '../../../Events/EventsTable/helpers';
import {
  saveDonation,
  getDisplayPreferences,
} from '../../../Events/EventDetailsForm/submitHelpers';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';

/* Styles */
import {
  BaseContainer,
  TabContentContainer,
  DetailsTitle,
  StraightLine,
} from '../index.styles';
import { DetailsHeader } from '../../../ContactV2/ContactDetailsPage/TabContent/tabContentStyles.styles';
import { SvgPencil } from '../../../../assets/pencil';
import { getEventNonProfit } from '../../../../utils/utils';

const handleUpdateCharity = async ({
  event,
  trackEventsV2,
  eventId,
  values,
  setErrors,
  setTouched,
  eventUpdateMutation,
  setNotificationClosed,
  preApprovedDonationAdd,
  customDonationAdd,
  setNotificationOpen,
  setIsNotEditing,
  donationV2Add,
}: any) => {
  const { charityQuote } = values;
  const displayPreferences = getDisplayPreferences({
    event,
    values,
  });

  const input = {
    form: {
      eventId,
      hostQuote: charityQuote || '',
      displayPreferences,
    },
  };

  await eventUpdateMutation({
    variables: { input: input },
  })
    .then(async () => {
      const updatedEvent = await saveDonation({
        values,
        eventId,
        setErrors,
        preApprovedDonationAdd,
        customDonationAdd,
        donationV2Add,
        setNotificationClosed,
        setNotificationOpen,
      });

      // Old NPO can either be nonProfit or nonProfitV2,
      // while new NPO will always be nonProfitV2
      const oldNonProfit = getEventNonProfit(event?.donationInfo);

      // Send tracking if the charity info changed.
      if (
        updatedEvent?.donationInfo?.nonProfitV2?.nonProfitName !==
        oldNonProfit?.nonProfitName
      ) {
        trackEventsV2({
          eventName: 'Event Cause Updated',
          newEvent: updatedEvent,
          oldEvent: event,
          formValues: values,
        });
      }

      setTouched({});
      setIsNotEditing();
      return setNotificationOpen();
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      return;
    });
};

interface CharityProps {
  event: EventType;
}

const Charity = ({ event }: CharityProps) => {
  const { trackEventsV2 } = useTrackingContextValue();

  const {
    value: editing,
    setTrue: setIsEditing,
    setFalse: setIsNotEditing,
  } = useToggle(false);
  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();
  const [
    preApprovedDonationAdd,
    { loading: preApprovedDonationMutationLoading },
  ] = useMutation(PRE_APPROVED_DONATION_ADD);
  const [
    customDonationAdd,
    { loading: customDonationMutationLoading },
  ] = useMutation(CUSTOM_DONATION_ADD);
  const [donationV2Add, { loading: donationV2AddLoading }] = useMutation(
    DONATION_V2_ADD,
  );
  const [eventUpdateMutation, { loading: mutationLoading }] = useMutation(
    EVENT_UPDATE_MUTATION,
  );
  const {
    data: nonProfitData,
    loading: nonProfitLoading,
    error: nonProfitError,
  } = useQuery(GET_PRE_APPROVED_NON_PROFITS_QUERY);

  const { eventId } = event;
  const nonProfits = get(nonProfitData, 'viewer.user.nonProfits.edges');
  const donation = get(event, 'donationInfo');
  const defaultValues = getDefaultValues(event, donation);
  const satelliteEvent = isSatelliteEvent(event);
  const openEvent = event?.status === 'OPEN';
  const editable = editing ? openEvent : !satelliteEvent;
  const detailsTitle = openEvent
    ? editing
      ? 'Edit Charity Details'
      : 'Charity Details'
    : 'Submission Details';

  if (nonProfitLoading) return <Loading />;

  const SUCCESS_MESSAGE = `This charity has been updated`;
  return (
    <BaseContainer>
      <TabContentContainer>
        <Notification
          variant="success"
          open={notificationOpen}
          message={SUCCESS_MESSAGE}
          handleClose={setNotificationClosed}
        />
        {!satelliteEvent && (
          <div>
            <DetailsHeader>
              <Grid container alignItems="center">
                <Grid>
                  <DetailsTitle
                    variant="custom"
                    default={TEXT_DS.BODY_SEMIBOLD_16}
                  >
                    {detailsTitle}
                  </DetailsTitle>
                </Grid>
                {/*{showNotification && <AlertCircleOutline />}*/}
              </Grid>
              {openEvent && !editing && (
                <SvgPencil onClick={setIsEditing} cursor="pointer" />
              )}
            </DetailsHeader>
            <StraightLine />
            {editing ? (
              <FormContainer
                enableReinitialize
                validationSchema={getCharitySchema()}
                initialValues={defaultValues}
                /* eslint-disable react/jsx-no-bind */
                formToUse={(props: any) => (
                  <React.Fragment>
                    <CharityDetailsForm
                      {...props}
                      editing={true}
                      mutationLoading={
                        preApprovedDonationMutationLoading ||
                        customDonationMutationLoading ||
                        donationV2AddLoading
                      }
                      setIsNotEditing={setIsNotEditing}
                      nonProfits={nonProfits}
                      donation={donation}
                      event={event}
                    />
                  </React.Fragment>
                )}
                onSubmit={async (
                  values: MyFormValues,
                  { setErrors, setTouched }: any,
                ) => {
                  await handleUpdateCharity({
                    values,
                    event,
                    trackEventsV2,
                    eventId,
                    setErrors,
                    setTouched,
                    eventUpdateMutation,
                    preApprovedDonationAdd,
                    customDonationAdd,
                    setNotificationClosed,
                    setNotificationOpen,
                    setIsNotEditing,
                    donationV2Add,
                  });
                }}
              />
            ) : (
              <CharityDetailsSummary
                event={event}
                donation={donation}
                openEvent={openEvent}
              />
            )}
          </div>
        )}
      </TabContentContainer>
    </BaseContainer>
  );
};

export default Charity;
