import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { navigate } from 'gatsby';
import {
  LoaderDark,
  Button as MuiButton,
  theme,
  Grid,
  Icons,
  Text,
  TEXT_DS,
} from '@onehope/design-system-v2';

import SummaryLine from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine';
import EventResendOrderLinkMutation from '../../../../mutations/Event/EventResendOrderLinkMutation';

// Styles
import {
  ChangeHost,
  LinkV2,
} from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine/summaryLineStyles.styles';
import {
  StraightLine,
  TabContentContainer,
  DetailsHeader,
  DetailsTitle,
} from '../index.styles';
import { SvgPencil } from '../../../../assets/pencil';

import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { eventIsAcceptingOrders } from '../../../Events/EventsTable/helpers';
import { getDays } from '../../Notifications';
import WarningCard from './WarningCard';
import useToggle from '../../../../utils/useToggleHook';
import { EventManagementTabs } from '../../TabNavigation/tabConstants';

const { Copy } = Icons;

interface WineSummaryProps {
  event: EventType;
  ceEmail: string | null;
  wineNeeded: string;
  toggleEdit: () => void;
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
}

const CopyIcon = styled(Copy)`
  margin-left: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const CopyText = styled(Text)`
  margin-left: 16px;
  && {
    font-weight: 420;
    color: ${theme.palette.greyDark.main};
  }
`;
const Button = styled(MuiButton)`
  && {
    margin-top: 16px;
  }
`;

const getButtonContent = (
  hostBuying: boolean,
): { type: string; text: string } => {
  return hostBuying
    ? { type: 'primary', text: 'Resend order link to host' }
    : { type: 'primary', text: 'Select wines for tasting' };
};

const WineSummary = ({
  event,
  ceEmail,
  wineNeeded,
  toggleEdit,
  numberOfAvailableCredits,
  numCreditsUsed,
}: WineSummaryProps) => {
  const [resendOrderLink, { loading: mutationLoading }] = useMutation(
    EventResendOrderLinkMutation,
  );
  const [copied, setCopied] = useState(false);

  const hostBuying =
    event.hostKitPurchaser === 'HOST' && ceEmail !== event.hostEmail;
  const purchaser = hostBuying ? `Host (${event.hostEmail})` : 'Myself';
  const dateSent = event?.lastOrderLinkInfo?.dateSent
    ? DateTime.fromISO(event.lastOrderLinkInfo.dateSent).toLocaleString(
        DateTime.DATE_SHORT,
      )
    : '';

  const wineQuantity = event.hostKitQuantity === 1 ? '6' : '12';

  const buttonContent = getButtonContent(hostBuying);

  const isAcceptingOrders = eventIsAcceptingOrders(event);
  const hostKitOrderLink = `${process.env.GATSBY_HOST_KIT_BUILDER_URL}/?hostKitOrderLinkId=${event.hostKitOrderLinkId}`;

  const daysUntilEvent = getDays(event.eventDate);
  const showWineNotification =
    daysUntilEvent < 10 &&
    daysUntilEvent > 0 &&
    ((!event.wineTastingOrderIds?.length && wineNeeded === 'YES') ||
      wineNeeded === 'SKIP');
  const WARNING_MESSAGE = `your event is in ${daysUntilEvent} ${
    daysUntilEvent === 1 ? 'day' : 'days'
  }. If you order your wine today, it may not arrive in time for your event.`;

  const isVirtualEvent = event?.trinityPartyType === 'EPARTY';

  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle(showWineNotification);

  const handleHostChange = () => {
    return navigate(
      `/event?eventId=${event?.eventId}&tab=${EventManagementTabs.DETAILS}`,
    );
  };

  const goToHostKitOrderLink = () => {
    return window.open(hostKitOrderLink, '_blank');
  };

  const handleButtonAction = async () => {
    switch (buttonContent.type) {
      case 'primary': {
        goToHostKitOrderLink();
        break;
      }
      case 'secondary': {
        if (mutationLoading) {
          return;
        }
        await resendOrderLink({
          variables: { input: { eventId: event?.eventId } },
        })
          .then((data: any) => {
            console.log({ data });
          })
          .catch((errors: any) => {
            console.log('errors', errors);
            return;
          });
        break;
      }
    }
  };

  const copyToClipboard = () => {
    // using this method because of struggles
    // with refs on the mui input
    if (!!document) {
      const el = document.createElement('textarea');
      el.value = hostKitOrderLink;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  return (
    <TabContentContainer>
      <DetailsHeader>
        <Grid container alignItems="center">
          <Grid>
            <DetailsTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
              {isVirtualEvent ? 'Tasting Flight details' : 'Wine details'}
            </DetailsTitle>
          </Grid>
          {/*{showDotNotification && <AlertCircleOutline />}*/}
        </Grid>
        {isAcceptingOrders && (
          <SvgPencil onClick={toggleEdit} cursor="pointer" />
        )}
      </DetailsHeader>
      <StraightLine />
      {wineNeeded === 'YES' && isAcceptingOrders && (
        <div>
          <SummaryLine
            width={130}
            title={isVirtualEvent ? 'Tasting Flight' : 'Wine'}
            value={
              isVirtualEvent
                ? 'Yes, Tasting Flight is needed'
                : 'Yes, wine is needed'
            }
          />
          <SummaryLine
            width={130}
            title="Bottles of wine"
            value={`${wineQuantity} bottles`}
          />
          <SummaryLine
            width={130}
            title="Credits used"
            value={`${numCreditsUsed}`}
          />
          <SummaryLine
            width={130}
            title="Who's ordering"
            value={
              hostBuying ? (
                <div>
                  <div>{purchaser}</div>
                  <ChangeHost onClick={handleHostChange}>
                    Change Host
                  </ChangeHost>
                </div>
              ) : (
                purchaser
              )
            }
          />
          <SummaryLine
            width={130}
            title="Order status"
            value="Not yet ordered"
          />
          <SummaryLine
            width={130}
            title="Order link"
            value={
              <Grid container wrap="nowrap">
                <Grid item xs>
                  <LinkV2 onClick={goToHostKitOrderLink}>
                    {hostKitOrderLink}
                  </LinkV2>
                </Grid>
                <Grid item>
                  {copied ? (
                    <CopyText>Copied</CopyText>
                  ) : (
                    <CopyIcon
                      onClick={copyToClipboard}
                      color={theme.palette.forestGreen.main}
                    />
                  )}
                </Grid>
              </Grid>
            }
          />
          {hostBuying && (
            <div>
              <SummaryLine
                width={130}
                title="Order link sent to"
                value={event?.lastOrderLinkInfo?.recipient}
              />
              <SummaryLine width={130} title="Date sent" value={dateSent} />
            </div>
          )}
          <Button
            type={buttonContent.type}
            fullWidth
            disableRipple
            onClick={handleButtonAction}
          >
            {mutationLoading && <LoaderDark style={{ marginRight: 6 }} />}
            {buttonContent.text}
          </Button>
          {showWineNotification && notificationOpen && (
            <WarningCard
              message={WARNING_MESSAGE}
              setClose={setNotificationClosed}
            />
          )}
        </div>
      )}
      {wineNeeded === 'STARTER-KIT' && (
        <SummaryLine
          width={130}
          title="Wine"
          value={'Starter Kit wine applied'}
        />
      )}
      {wineNeeded === 'ALREADY-ORDERED' && (
        <SummaryLine
          width={130}
          title="Wine"
          value={'I already ordered wine for this event'}
        />
      )}
      {(wineNeeded === 'NO' ||
        (wineNeeded === 'YES' && !isAcceptingOrders)) && (
        <SummaryLine
          width={130}
          title={isVirtualEvent ? 'Tasting Flight' : 'Wine'}
          value={
            isVirtualEvent
              ? 'Tasting Flights are not needed for this event'
              : 'Wine is not needed for this event'
          }
        />
      )}
    </TabContentContainer>
  );
};

export default WineSummary;
