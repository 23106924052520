import React from 'react';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { font },
} = styles;

export enum SplitScreenPresets {
  DOING_GOOD_TASTES_GREAT = 'doing good tastes great',
  BE_YOUR_OWN_BOSS = 'be your own boss',
  ORDER_CONFIRMATION = 'order confirmed',
}

type HelloProps = {
  color: string;
};

const Hello = styled.div<HelloProps>`
  font-family: ${font};
  font-size: 44px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.32;
  color: ${(props: HelloProps) => props.color};

  .bottom-left {
    position: absolute;
    left: 40px;
    bottom: 104px;
  }

  .top-center {
    padding-top: 40px;
    text-align: center;
  }
`;

export const presetOverlayText = {
  [SplitScreenPresets.DOING_GOOD_TASTES_GREAT]: (
    <Hello color="#ffffff">
      <div className="bottom-left">
        <div>Doing good</div>
        <div>tastes great.</div>
      </div>
    </Hello>
  ),
  [SplitScreenPresets.BE_YOUR_OWN_BOSS]: (
    <Hello color="#000000">
      <div className="top-center">Be your own boss.</div>
    </Hello>
  ),
} as { [name: string]: React.ReactNode };

export const presetImages = {
  [SplitScreenPresets.DOING_GOOD_TASTES_GREAT]: '201903loginpic_d.jpg',
  [SplitScreenPresets.BE_YOUR_OWN_BOSS]: 'beyourownboss-2x.jpg',
  [SplitScreenPresets.ORDER_CONFIRMATION]: 'order_confirmation.png',
} as { [name: string]: string };
