import React, { useState, memo, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';

/* Components */
import FormContainer from '../../FormContainer';
import Notification from '../../../Notifcations/DefaultNotifcation';
import EventDetailsForm from '../../../Events/EventDetailsForm';
import EventSummary from '../../../Events/EventDetailsForm/EventSummary';
import OrderLinkSentDialog from '../../../Dialog/OrderLinkSentDialog';

/* Mutations */
import EVENT_UPDATE_MUTATION from '../../../../mutations/Event/EventUpdateMutation';

/* Types */
import { MyFormValues } from './FormTypes';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

/* Helpers & Functions */
import useToggle from '../../../../utils/useToggleHook';
import { getDefaultValues } from './helpers';
import { EventDetailsFormSchema } from './validationSchema';
import {
  getEventDetailsToSubmit,
  getDisplayPreferences,
} from '../../../Events/EventDetailsForm/submitHelpers';
import {
  isSatelliteEvent,
  eventIsClosed,
  eventIsCancelled,
} from '../../../Events/EventsTable/helpers';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';

/* Styles */
import {
  TabContentContainer,
  BaseContainer,
  DetailsHeader,
  DetailsTitle,
  StraightLine,
} from '../index.styles';
import { SvgPencil } from '../../../../assets/pencil';
import EditImage from '../../../Events/EventDetailsForm/EditImage';
import { EventTypeEnum } from '../../../EventsV2/eventTypes';
import { segmentTrackEventLocations } from '../../../../utils/segment/segmentConstants';
import { TEXT_DS } from '@onehope/design-system-v2';

//#region Helpers
const handleUpdateEvent = async ({
  event,
  trackEventsV2,
  eventId,
  values,
  setTouched,
  setErrors,
  eventUpdateMutation,
  setNotificationOpen,
  setIsNotEditing,
  setDialogOpen,
}: any) => {
  const eventDetails = getEventDetailsToSubmit({
    values,
    ceAccountId: event?.accountId,
  });
  const displayPreferences = getDisplayPreferences({
    event,
    values,
  });
  const isFundraiser = eventDetails.eventType === EventTypeEnum.Fundraiser;
  const { eventDate, ...eventFundraiserDetails } = eventDetails;

  const details = isFundraiser ? eventFundraiserDetails : eventDetails;
  const input = {
    form: {
      eventId,
      ...details,
      displayPreferences,
    },
  };

  await eventUpdateMutation({
    variables: { input: input },
  })
    .then((data) => {
      const newEvent = get(data, 'data.eventUpdate.event');
      if (newEvent?.eventDate !== event?.eventDate) {
        trackEventsV2({
          eventName: 'Event Date Time Updated',
          newEvent,
          oldEvent: event,
        });
      }

      const closeDateChanged = newEvent?.closeDate !== event?.closeDate;
      if (isFundraiser && closeDateChanged) {
        // if Wine Rep cleared the close date, we should leave newCloseDateTime empty
        // so it's clear the Wine Rep removed it. otherwise, send in new close date

        // In the form values, the new close date currently appears as eventDate
        // (but gets sent to the backend as closeDate).
        const clearedCloseDate = !values?.eventDate;
        trackEventsV2({
          eventName: 'Event Custom Close Date Updated',
          newEvent: clearedCloseDate ? null : newEvent,
          oldEvent: event,
          additionalProperties: {
            updatedLocation: segmentTrackEventLocations.eventManagement,
          },
        });
      }
      setTouched({});
      setIsNotEditing();
      if (
        newEvent?.hostKitPurchaser === 'HOST' &&
        event?.hostAccountId !== newEvent.hostAccountId &&
        (!newEvent?.wineTastingOrderIds ||
          !newEvent?.wineTastingOrderIds.length)
      ) {
        setDialogOpen();
      }
      return setNotificationOpen();
    })
    .catch((errors: any) => {
      const errorMessage = errors?.graphQLErrors?.[0]?.message;
      if (errorMessage.includes('Invalid Close Date')) {
        setErrors({ eventDate: errorMessage });
      } else if (errorMessage.includes('Cannot Change')) {
        setErrors({ eventDate: errorMessage });
      }
      console.error({ errorMessage });
      return;
    });
};

//#region Details Component
interface DetailsProps {
  event: EventType;
}

const Details = ({ event }: DetailsProps) => {
  const { trackEventsV2 } = useTrackingContextValue();

  const newEvent = JSON.parse(localStorage.getItem('eventCreated') || 'false');
  useEffect(() => {
    if (newEvent) {
      const timer = setTimeout(() => {
        localStorage.setItem('eventCreated', 'false');
        return setNewlyCreateEvent(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
    return;
  }, []); // Runs only once because of empty dependency array

  const [newlyCreatedEvent, setNewlyCreateEvent] = useState(newEvent);
  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();
  const {
    value: editing,
    setTrue: setIsEditing,
    setFalse: setIsNotEditing,
  } = useToggle(false);
  const {
    value: dialogOpen,
    setTrue: setDialogOpen,
    setFalse: setDialogClosed,
  } = useToggle();
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  const [eventUpdateMutation, { loading: mutationLoading }] = useMutation(
    EVENT_UPDATE_MUTATION,
  );

  const hostDetails = get(event, 'hostUser');
  const donation = get(event, 'donationInfo');
  const defaultValues = getDefaultValues(event, hostDetails, donation);
  const SUCCESS_MESSAGE = newEvent
    ? `This event has been created`
    : `This event has been updated`;

  const closedEvent = eventIsClosed(event);
  const cancelledEvent = eventIsCancelled(event);
  const satelliteEvent = isSatelliteEvent(event);
  const editable =
    !editing && !satelliteEvent && !closedEvent && !cancelledEvent;

  const getDetailsTitle = () => {
    if (closedEvent || cancelledEvent) return 'Summary';
    if (editing) return 'Edit Event Details';
    return 'Event Details';
  };

  const handleNotificationClose = () => {
    localStorage.setItem('eventCreated', 'false');
    setNewlyCreateEvent(false);
    setNotificationClosed();
  };

  const handleNotificationOpen = () => {
    setNewlyCreateEvent(false);
    setNotificationOpen();
  };

  //#region HTML

  return (
    <BaseContainer>
      <TabContentContainer>
        <Notification
          variant="success"
          open={newlyCreatedEvent || notificationOpen}
          message={SUCCESS_MESSAGE}
          handleClose={handleNotificationClose}
        />
        <OrderLinkSentDialog open={dialogOpen} setClose={setDialogClosed} />
        <DetailsHeader>
          <DetailsTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            {getDetailsTitle()}
          </DetailsTitle>
          {editable && <SvgPencil onClick={setIsEditing} cursor="pointer" />}
        </DetailsHeader>
        <StraightLine />
        {editing ? (
          <FormContainer
            enableReinitialize
            validationSchema={EventDetailsFormSchema}
            initialValues={defaultValues}
            /* eslint-disable react/jsx-no-bind */
            formToUse={(props: any) => (
              <EventDetailsForm
                {...props}
                editing={editing}
                event={event}
                mutationLoading={mutationLoading}
                setIsNotEditing={setIsNotEditing}
              />
            )}
            onSubmit={async (
              values: MyFormValues,
              { setErrors, setTouched }: any,
            ) => {
              await handleUpdateEvent({
                event,
                trackEventsV2,
                values,
                setErrors,
                setTouched,
                eventId: event?.eventId,
                errorField: 'hostFullName',
                eventUpdateMutation,
                setNotificationClosed: handleNotificationClose,
                setNotificationOpen: handleNotificationOpen,
                setIsNotEditing,
                setDialogOpen,
              });
            }}
          />
        ) : (
          <EventSummary
            event={event}
            values={defaultValues}
            satelliteEvent={satelliteEvent}
            editing={editing}
            setIsEditing={setIsEditing}
          />
        )}
        {!editing && (
          <EditImage event={event} setIsImageUpdated={setIsImageUpdated} />
        )}
      </TabContentContainer>
    </BaseContainer>
  );
};

export default memo(Details);
