import React from 'react';
import { styled } from '@onehope/design-system-v2';
import ContactCard from './ContactCard';
import { ContactType } from '../ContactsContext';

interface CardListViewProps {
  contacts: ContactType[];
}

const GridList = styled('div')<{ count: number }>`
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 24px;
  grid-auto-flow: dense;
  max-width: ${({ count }) => (count < 3 ? '756px' : '100%')};
  @media all and (min-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
  }
`;

const CardListView = ({ contacts }: CardListViewProps) => {
  const contactsCount = contacts?.length;
  return (
    <GridList count={contactsCount}>
      {!!contactsCount ? (
        <>
          {contacts.map((contact: ContactType, index: number) => (
            <ContactCard
              contact={contact}
              contactsCount={contactsCount}
              key={index}
            />
          ))}
        </>
      ) : null}
    </GridList>
  );
};

export default CardListView;
