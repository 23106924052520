import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PageRendererProps } from 'gatsby';
import styled from '@emotion/styled';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Layout from '../../layout';
import ContactsV3 from '../../components/ContactsV3';
import USER_QUERY from '../../queries/ContactUserQuery';

const Container = styled.div`
  background-color: white;
  min-height: 100%;
`;

function ContactsPage(props: PageRendererProps) {
  const { data, error } = useQuery(USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const userId = data?.viewer?.user?.userId;

  if (error) return null;
  return (
    <Layout>
      <Container>{userId ? <ContactsV3 userId={userId} /> : null}</Container>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(ContactsPage);
