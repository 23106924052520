import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Icons,
  ChunkyButton,
  LoaderDark,
  Grid,
} from '@onehope/design-system-v2';

import { useCauseOfChoiceContextValue } from '../../../../stateManagement/context';
import {
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../../stateManagement/types';
import { useCauseOfChoiceNetworkLayerValue } from '../../../../stateManagement/networkLayer';
import { getCurrentUser } from '../../../../utils/helper';
import { useCommonStyles } from '../../../../utils/commonStyles';
import CE_EVENTS_COUNT_QUERY from '../../../../queries/CeEventsCountQuery';

const { COCFundraiser } = Icons;

export default function FundraiserButton() {
  const commonStyles = useCommonStyles();
  const [, dispatch] = useCauseOfChoiceContextValue();
  const { user: parentUser } = useCauseOfChoiceNetworkLayerValue();
  const user = getCurrentUser(parentUser);

  const shoppingWithCeoUserId = user?.shoppingWithCeoUser?.userId;
  const isCE = !!user?.permissions?.oneCommerce?.ceo?.access;
  const ceAccountId = isCE ? user?.userId : shoppingWithCeoUserId;

  const { data, loading } = useQuery(CE_EVENTS_COUNT_QUERY, {
    variables: {
      ceAccountId: user?.shoppingWithCeoUser?.userId,
    },
    skip: !ceAccountId,
  });
  const eventCount = data?.viewer?.user?.eventsV2Count;

  const ceText = isCE
    ? `Search my fundraisers`
    : `Search ${user?.shoppingWithCeoUser?.firstName}'s fundraisers`;

  const primaryText = ceAccountId ? ceText : 'Search for a fundraiser';
  const handleClick = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.FUNDRAISER_SEARCH_SCREEN,
    });
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        className={commonStyles.loaderContainer}
      >
        <LoaderDark size={36} />
      </Grid>
    );
  }

  if (eventCount || !ceAccountId) {
    return (
      <div className={commonStyles.container}>
        <ChunkyButton
          avatar={<COCFundraiser />}
          primaryText={primaryText}
          secondaryText="Support any online or in-person ONEHOPE fundraiser"
          onClick={handleClick}
        />
      </div>
    );
  }

  return <></>;
}
