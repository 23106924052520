import React, { useState, FormEvent } from 'react';
import {
  Tab,
  Tabs,
  makeStyles,
  ThemeType,
  theme,
  useMediaQuery,
  Spacer,
  styled,
} from '@onehope/design-system-v2';

import NextOrderView from '../NextOrderView';
import PreferencesView from '../PreferencesView';

import { SendToTrackEventV2 } from '../../utils/segment';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_userInfo,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
  WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import { WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify } from '../../queries/generatedTypes/WineClub_SegmentIdentifyGetQuery';

type TabsWrapperProps = {
  user: WineClub_AutoShipGetQuery_viewer_user;
  segmentUser:
    | WineClub_AutoShipGetQuery_viewer_user
    | WineClub_AutoShipGetQuery_viewer_userInfo;
  segmentPersonas: WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  paymentMethod:
    | WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment
    | null
    | undefined;
  refetchSubscription: () => void;
  conciergeMode: boolean;
  contactId?: string;
  sendToTrackEventV2?: SendToTrackEventV2;
  wineClub923?: boolean;
};

enum SubscriptionTabQueryParams {
  Preferences = 'preferences',
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    margin: '0 auto',
  },
  tabStyle: {
    [theme.breakpoints.up('sm')]: {
      width: 230,
    },
  },
}));

const TabsContainer = styled(Tabs)`
  width: 100%;
  min-height: 40px !important;
  height: 40px !important;
  & .MuiTabs-indicator {
    display: none;
    background-color: transparent;
  }
  & button.MuiButtonBase-root {
    text-transform: unset;
    font-family: ${theme.bodyFontFamily};
    font-size: 14px;
    font-weight: 500;
    color: ${theme.palette.greyDark.main};
    opacity: 1;
    height: 40px;
    min-height: 40px;
    line-height: 18px;
    border: 1px solid ${theme.palette.greyDark.main};
  }
  & button.MuiButtonBase-root:nth-child(1) {
    border-radius: 1px 0px 0px 1px;
  }
  & button.MuiButtonBase-root:nth-child(2) {
    border-radius: 0px 1px 1px 0px;
  }
  & button.Mui-selected {
    color: white;
    font-weight: 500;
    background-color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
  }
`;

function TabsWrapper(props: TabsWrapperProps) {
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get('view');

  const { root, tabStyle } = classes;
  const tabClasses = { root: tabStyle };
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [value, setValue] = useState(
    tab === SubscriptionTabQueryParams.Preferences ? 1 : 0,
  );

  function handleChange(event: FormEvent<EventTarget>, newValue: number) {
    event.preventDefault();
    setValue(newValue);
  }

  return (
    <div style={{ width: '100%', maxWidth: '460px' }}>
      <div className={root}>
        <TabsContainer
          value={value}
          scrollButtons={false}
          variant="fullWidth"
          onChange={handleChange}
          aria-label="Order Preferences Tabs"
        >
          <Tab
            disableRipple
            value={0}
            label={isMobile ? 'Next Shipment' : 'Next Shipment Details'}
            classes={tabClasses}
          />
          <Tab
            disableRipple
            value={1}
            label="Manage Subscription"
            classes={tabClasses}
          />
        </TabsContainer>
      </div>
      <Spacer xs="24px" sm="24px" lg="24px" />
      {value === 0 && (
        <NextOrderView
          segmentUser={props.segmentUser}
          segmentPersonas={props.segmentPersonas}
          user={props.user}
          subscription={props.subscription}
          refetchSubscription={props.refetchSubscription}
          conciergeMode={props.conciergeMode}
          sendToTrackEventV2={props.sendToTrackEventV2}
        />
      )}
      {value === 1 && (
        <PreferencesView
          user={props.user}
          subscription={props.subscription}
          paymentMethod={props.paymentMethod}
          conciergeMode={props.conciergeMode}
          contactId={props.contactId}
          sendToTrackEventV2={props.sendToTrackEventV2}
          wineClub923={props.wineClub923}
        />
      )}
    </div>
  );
}

export default TabsWrapper;
