import React, { useCallback, useEffect, useState } from 'react';
import {
  ActivityEntry,
  Feed,
  getPlaybookSegmentProperties,
  SegmentEvents,
  FeedTypes,
} from '@onehope/action-engine';
import navigationHandler from './navigationHandler';

import { useTrackingContextValue } from '../../../contexts/TrackingContext';

export type PlayBookActivityProps = {
  cardButton: string;
  cardCategory?: string;
  cardName: string;
  streamActionId: string;
  contentfulEntryId: string;
  cardTemplate: string;
  feed: string;
  subjectUserId: string;
};

type CardsProps = {
  openVideoDialog?: (
    playbackId?: string,
    ratio?: string,
    activityId?: string,
  ) => void;
  openButtonActionDialog?: (
    buttonAction: ActivityEntry['buttonAction'],
  ) => void;
  handleSetOpenCardDialog?: (feedType: FeedTypes) => void;
  setHandleCardAction: React.Dispatch<React.SetStateAction<boolean>>;
  handleCardAction: boolean;
  activityId: string;
  setOpenCardDialog: React.Dispatch<React.SetStateAction<boolean>>;

  setRewardBadgeAnimationAmount: React.Dispatch<React.SetStateAction<number>>;
  setTriggerRewardBadgeAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetClose: () => void;

  setFeedType: React.Dispatch<React.SetStateAction<FeedTypes>>;
};

export default function Cards(props: CardsProps) {
  const {
    activityId,
    handleCardAction,
    handleSetClose,
    openButtonActionDialog,
    openVideoDialog,
    setFeedType,
    setHandleCardAction,
    setOpenCardDialog,
    setRewardBadgeAnimationAmount,
    setTriggerRewardBadgeAnimation,
  } = props;

  const [dialogUrl, setDialogUrl] = useState('');
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.append('dialog', 'open');
    setDialogUrl(`${url.origin}${url.pathname}?${params}`);
  }, []); // Runs only once because of empty dependency array

  const handleSetOpenCardDialog = (feedType: FeedTypes) => {
    window.history.pushState({ path: dialogUrl }, '', dialogUrl);
    setOpenCardDialog(true);
    setFeedType(feedType);
    // add onpopstate listener to call close - listener is removed in handleSetClose
    window.onpopstate = handleSetClose;
  };

  const { trackEventV2 } = useTrackingContextValue();
  const sendToTrackEventV2 = useCallback(
    ({
      event,
      activity,
      segmentActivityProps,
    }: {
      event: SegmentEvents;
      activity?: ActivityEntry;
      segmentActivityProps?: PlayBookActivityProps;
    }) => {
      if (!activity) {
        return;
      }
      const properties =
        segmentActivityProps || getPlaybookSegmentProperties(activity);
      // @ts-ignore
      trackEventV2({ event, properties });
    },
    [trackEventV2],
  );

  return (
    <Feed
      activityId={activityId}
      openVideoDialog={openVideoDialog}
      handleCardAction={handleCardAction}
      externalConfig={{ navigationHandler }}
      // @ts-ignore
      sendToTrackEventV2={sendToTrackEventV2}
      setHandleCardAction={setHandleCardAction}
      handleSetOpenCardDialog={handleSetOpenCardDialog}
      openButtonActionDialog={openButtonActionDialog}
      setRewardBadgeAnimationAmount={setRewardBadgeAnimationAmount}
      setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
    />
  );
}
