import produce from 'immer';
import { TypeKeys, StateType, ActionTypes } from './types';

export default function reducer(state: StateType, action: ActionTypes) {
  return produce<StateType>(state, draft => {
    switch (action.type) {
      case TypeKeys.UPDATE_SCREEN:
        const { screen } = action;
        draft.screen = screen;
        break;
      case TypeKeys.UPDATE_USER:
        const { user } = action;
        draft.user = user;
        break;
      case TypeKeys.UPDATE_LOADING:
        const { loading } = action;
        draft.loading = loading;
        break;
      default:
        break;
    }
  });
}
