import { gql } from '@apollo/client';

export const GetPaymentFragment = gql`
  fragment WineClub_GetPayment on StripePayment {
    id
    last4
    expYear
    expMonth
    billingAddressUuid
    paymentId
    brand
    name
    nickname
    billingAddress {
      id
      addressId
      addressLineOne
      addressLineTwo
      city
      country
      firstName
      lastName
      companyName
      nickname
      phoneNumber
      recipientBirthDate
      state
      street
      zip
    }
  }
`;
