import { gql } from '@apollo/client';
import { GetAddressFragment } from '../queries/fragments/AddressFragment';

const mutation = gql`
  mutation Account_AddressBookAddMutation($input: AddressBookAddInput!) {
    addressBookAdd(input: $input) {
      address {
        ...Account_GetAddress
      }
      user {
        id
        shippingAddressDefault {
          ...Account_GetAddress
        }
        shippingAddressIdDefault
        addressBook {
          id
          entries(first: 100) {
            edges {
              node {
                id
                address {
                  ...Account_GetAddress
                }
              }
            }
          }
        }
      }
      userInfo {
        id
        shippingAddressDefault {
          ...Account_GetAddress
        }
        shippingAddressIdDefault
        addressBook {
          id
          entries(first: 100) {
            edges {
              node {
                id
                address {
                  ...Account_GetAddress
                }
              }
            }
          }
        }
      }
    }
  }
  ${GetAddressFragment}
`;

export default mutation;
