import styled from '@emotion/styled';
import Select from '@material-ui/core/Select';
import {
  neutral2,
  errorColor,
  inputFocusColor,
  neutral34,
  textColor,
  font,
} from '../../styles/cssConstants';

type InputSelectProps = {
  error?: boolean;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const InputSelect = styled(Select)<InputSelectProps>`
  &.MuiInput-underline:before {
    transition: none !important;
    border-bottom-style: none !important;
  }
  &.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: none !important;
    border-bottom-style: none !important;
    pointer-events: none;
  }
  &.MuiInputBase-input {
    font-family: ${font};
    padding: 1rem 16px;
    min-width: 144px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    background-color: ${neutral2};
    height: 48px;
  }
  &.MuiSvgIcon-root {
    fill: ${textColor};
  }
  &.MuiInputBase-input:focus {
    background-color: ${({ error }: InputSelectProps) =>
      error ? errorColor : inputFocusColor};
  }
`;

export const Label = styled.label`
  font-family: ${font};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${neutral34};
`;

export const Error = styled.span`
  font-family: ${font};
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  margin-top: 8px;
  color: ${errorColor};
`;
