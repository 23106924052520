import React, { useState } from 'react';
import { handleFilterByTab } from '../../../../../common/Orders/helpers';
import OrdersView from '../../../../../common/Orders';
import { CEDash_GuestOrdersQuery_viewer_user_orders as Orders } from '../../../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import {
  useContactsContext,
  ContactType,
} from '../../../../ContactsV3/ContactsContext';

interface OrdersPageWrapperProps {
  contact: ContactType;
  orders: Orders;
}

function OrdersPageWrapper({ contact, orders }: OrdersPageWrapperProps) {
  const { firstName, lastName } = contact;
  const { handleOrderPermissions } = useContactsContext();

  const ordersEdges = orders.edges;
  const openOrders = handleFilterByTab(ordersEdges, 'open');
  const [loadedOrders, setLoadedOrders] = useState(openOrders);
  const [allOrders] = useState(ordersEdges);

  const contactName = `${firstName} ${lastName}`;
  return (
    <OrdersView
      setOrders={setLoadedOrders}
      orders={loadedOrders}
      allOrders={allOrders}
      contactName={contactName}
      handleOrderForCustomer={() => handleOrderPermissions(contact)}
    />
  );
}

export default OrdersPageWrapper;
