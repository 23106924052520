import React, { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Icons, DialogV2 } from '@onehope/design-system-v2';

import {
  CEDash_ReachOutResults_viewer_v2,
  CEDash_ReachOutResults_viewer_v2_reachOutResults,
} from '../../../queries/generatedTypes/CEDash_ReachOutResults';

import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { segmentEvents } from '../../../utils/segment/constants';

import {
  TableContainer,
  Loader,
  Row,
  ContactName,
} from '../StyledComponents/Tables';

import ContactResultsDisplay from './ContactResultsDisplay';
import ResultCTA from './ResultCTA';

export default function ResultsTable({
  ceAccountId,
  results,
  onLoadMore,
  hasMore,
  reachOutId,
}: {
  ceAccountId: string;
  results: CEDash_ReachOutResults_viewer_v2['reachOutResults'];
  onLoadMore: () => void;
  hasMore: boolean;
  reachOutId: string;
}) {
  const { trackEventV2 } = useTrackingContextValue();

  const [
    openedResult,
    setOpenedResult,
  ] = useState<CEDash_ReachOutResults_viewer_v2_reachOutResults | null>(null);
  const closeResultDialog = () => setOpenedResult(null);

  const onOpenResultDialog = useCallback(
    (result: CEDash_ReachOutResults_viewer_v2_reachOutResults) => {
      trackEventV2({
        event: segmentEvents.reachOutContactResultsViewed,
        properties: {
          reachOutId,
          ceAccountId,
        },
      });

      if (typeof window === 'undefined') {
        setOpenedResult(result);
        return;
      }

      // if URL is on a different domain, open in new tab
      if (
        result.resultsUrl &&
        (new URL(result.resultsUrl).hostname !== window?.location.hostname ||
          new URL(result.resultsUrl).port !== window?.location.port)
      ) {
        window?.open(result.resultsUrl, '_blank');
      } else {
        // otherwise, show dialog
        setOpenedResult(result);
      }
    },
    [reachOutId, ceAccountId, trackEventV2],
  );

  return (
    <>
      <TableContainer>
        <InfiniteScroll
          dataLength={results.length}
          next={onLoadMore}
          hasMore={hasMore}
          loader={
            <Loader>
              <Icons.Loader />
            </Loader>
          }
        >
          {results.map(result => {
            const {
              simpleContact,
              reachOutRequest: { requestId },
            } = result;
            const { firstName, lastName } = simpleContact ?? {};

            return (
              <Row key={requestId}>
                {simpleContact !== null && (
                  <ContactName text={`${firstName} ${lastName}`} />
                )}
                <ResultCTA result={result} showDialog={onOpenResultDialog} />
              </Row>
            );
          })}
        </InfiniteScroll>
      </TableContainer>

      {!!openedResult && (
        <DialogV2
          title="Results"
          open={!!openedResult}
          onClose={closeResultDialog}
          closeButtonType="primary"
          buttonDirection="column"
        >
          <ContactResultsDisplay result={openedResult} />
        </DialogV2>
      )}
    </>
  );
}
