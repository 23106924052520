import * as React from 'react';
import { navigate } from 'gatsby';
import { DateTime } from 'luxon';

import {
  Tooltip,
  Checkbox,
  Spacer,
  Icons,
  styled,
} from '@onehope/design-system-v2';
import { styles as s } from '@onehope/design-system';

import { CEDash_CustomersQuery_viewer_user_customers_edges_node as ContactType } from '../../../queries/generatedTypes/CEDash_CustomersQuery';
import { formatPhone } from '../../Events/EventDetailsForm';
import { useCheckBoxToggleContext } from '../CheckboxToggleContext';
import { useArchiveContactsContext } from '../ArchiveContactsContext';
import IconMenu from '../IconMenu';
import { ContactManagementTabs } from '../../ContactV2/ContactDetailsPage/TabContent/tabConstants';
const { RefreshRightArrow, HeartOutline } = Icons;

const { cssConstants } = s;

const GridItem = styled('div')<{
  contactCount: number;
  selected: boolean;
  isCheckBoxActivated: boolean;
}>`
  position: relative;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  contact-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: ${props =>
    props.selected ? cssConstants.neutral2 : 'white'};
  padding: 24px;
  padding-left: ${props => (props.isCheckBoxActivated ? '14px' : '16px')};
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    padding-left: ${props => (props.isCheckBoxActivated ? '14px' : '24px')};
    max-width: ${({ contactCount }) => (contactCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const HostContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HostName = styled('div')`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const TypeIcon = styled('span')({
  height: '20px',
  width: '20px',
  svg: {
    height: '20px',
    width: '20px',
  },
});

const GridBlock = styled('div')`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const CheckboxFlexbox = styled('div')`
  display: flex;
`;

const GridText = styled('span')`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const GridContainer = styled('div')``;
const Container = styled('div')`
  margin-right: 6px;
  padding-left: 0px;
`;
const Link = styled('a')`
  color: ${cssConstants.primaryColor} !important;
  font-weight: 600;
`;

interface ContactCardProps {
  contact: ContactType | null | undefined;
  contactCount: number;
  handleOrderAdd: (contactId: string) => void;
  rowsPerPage?: number;
}

const handleCardClick = (contact: ContactType | null | undefined) => {
  return () => {
    return navigate(
      `/contact?contactId=${contact?.accountId}&tab=${ContactManagementTabs.DETAILS}`,
    );
  };
};

export default function ContactCard(props: ContactCardProps) {
  const { contact, contactCount, handleOrderAdd } = props;

  const { isCheckBoxActivated } = useCheckBoxToggleContext();
  const { handleContact, selectedContacts } = useArchiveContactsContext();

  if (contact) {
    const accountId = contact.accountId;
    const onCardClick = handleCardClick(contact);
    return (
      <GridItem
        contactCount={contactCount}
        selected={selectedContacts.includes(accountId)}
        isCheckBoxActivated={isCheckBoxActivated}
      >
        <CheckboxFlexbox>
          {isCheckBoxActivated && (
            <Container>
              <Checkbox
                checked={selectedContacts.includes(contact?.accountId)}
                onClick={() => {
                  handleContact(contact?.accountId);
                }}
              />
            </Container>
          )}
          <div>
            <IconMenu
              contactId={contact.accountId}
              email={contact.email}
              isCardView={true}
              handleOrderAdd={handleOrderAdd}
            />
            <GridContainer onClick={onCardClick}>
              <HostContainer>
                <HostName>
                  {contact.organizationName || contact.fullName}
                </HostName>
                {contact.isSubscriber && (
                  <Tooltip title="Wine Club" placement="top">
                    <TypeIcon>
                      <RefreshRightArrow />
                    </TypeIcon>
                  </Tooltip>
                )}
                {contact.isHost && (
                  <Tooltip title="Host" placement="top">
                    <TypeIcon>
                      <HeartOutline />
                    </TypeIcon>
                  </Tooltip>
                )}
              </HostContainer>
              <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
              <GridBlock>
                Email:{' '}
                <GridText>
                  <Link
                  // href={`mailto:${contact.email}`}
                  // target="_blank"
                  // rel="noopener noreferrer"
                  >
                    {contact.email}
                  </Link>
                </GridText>
              </GridBlock>
              {/*<GridBlock>*/}
              {/*  Quantity: <GridText>{contact.contactItemsQty}</GridText>*/}
              {/*</GridBlock>*/}
              <GridBlock>
                Phone Number:{' '}
                <GridText>
                  {contact.mobilePhone
                    ? formatPhone(contact.mobilePhone)
                    : '\u2014'}
                </GridText>
              </GridBlock>
              <GridBlock>
                Lifetime Spend:{' '}
                <GridText>
                  {contact.lifeTimeSpend
                    ? `$${contact.lifeTimeSpend.toFixed(2)}`
                    : '\u2014'}
                </GridText>
              </GridBlock>
              <GridBlock>
                Last Order:{' '}
                {contact.lastOrderDate ? (
                  <GridText>
                    {DateTime.fromISO(contact.lastOrderDate).toLocaleString(
                      DateTime.DATE_SHORT,
                    )}
                  </GridText>
                ) : (
                  '\u2014'
                )}
              </GridBlock>
              {/*<GridBlock>*/}
              {/*  Birthday:{' '}*/}
              {/*  {contact.dateOfBirth && (*/}
              {/*    <GridText>*/}
              {/*      {DateTime.fromFormat(*/}
              {/*        contact.dateOfBirth,*/}
              {/*        'M/d/yyyy',*/}
              {/*      ).toLocaleString(DateTime.DATE_SHORT)}*/}
              {/*    </GridText>*/}
              {/*  )}*/}
              {/*</GridBlock>*/}
            </GridContainer>
          </div>
        </CheckboxFlexbox>
      </GridItem>
    );
  }
  return null;
}
