import React from 'react';
/* SCREENS */
import Home from './Screens/Home';
import NonProfitSearch from './Screens/NonProfitSearch';
import ManualNonProfitAdd from './Screens/ManualNonProfitAdd';
import FundraiserSearch from './Screens/FundraiserSearch';
import NonProfitConfirmation from './Screens/Confirmation/NonProfitConfirmation';
import CeCauseConfirmation from './Screens/Confirmation/CeCauseConfirmation';
import FundraiserConfirmation from './Screens/Confirmation/FundraiserConfirmation';
import NotSureConfirmation from './Screens/Confirmation/NotSureConfirmation';
import CauseOfChoiceSelected from './Screens/CauseOfChoiceSelected';
import EventShoppingSelected from './Screens/EventShoppingSelected';
import NonProfitEdit from './Screens/NonProfitEdit';

/* UTILS */
import { useCauseOfChoiceContextValue } from '../stateManagement/context';
import { CauseOfChoiceScreenDictionary } from '../stateManagement/types';

function renderSwitch(screen: CauseOfChoiceScreenDictionary) {
  switch (screen) {
    case CauseOfChoiceScreenDictionary.HOME_SCREEN:
      return <Home />;
    case CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN:
      return <NonProfitSearch />;
    case CauseOfChoiceScreenDictionary.MANUAL_NON_PROFIT_SCREEN:
      return <ManualNonProfitAdd />;
    case CauseOfChoiceScreenDictionary.FUNDRAISER_SEARCH_SCREEN:
      return <FundraiserSearch />;
    case CauseOfChoiceScreenDictionary.NON_PROFIT_CONFIRMATION_SCREEN:
      return <NonProfitConfirmation />;
    case CauseOfChoiceScreenDictionary.CE_CAUSE_CONFIRMATION_SCREEN:
      return <CeCauseConfirmation />;
    case CauseOfChoiceScreenDictionary.NON_SURE_CONFIRMATION_SCREEN:
      return <NotSureConfirmation />;
    case CauseOfChoiceScreenDictionary.FUNDRAISER_CONFIRMATION_SCREEN:
      return <FundraiserConfirmation />;
    case CauseOfChoiceScreenDictionary.CAUSE_OF_CHOICE_SELECTED_SCREEN:
      return <CauseOfChoiceSelected />;
    case CauseOfChoiceScreenDictionary.EVENT_SHOPPING_SELECTED_SCREEN:
      return <EventShoppingSelected />;
    case CauseOfChoiceScreenDictionary.NON_PROFIT_EDIT_SCREEN:
      return <NonProfitEdit />;
    default:
      return <Home />;
  }
}

export default function ScreenSelector() {
  const [{ screen }] = useCauseOfChoiceContextValue();
  return <>{renderSwitch(screen)}</>;
}
