import { gql } from '@apollo/client';
import { GetPaymentFragment } from '../queries/fragments/PaymentFragment';

const mutation = gql`
  mutation Account_StripePaymentDeleteMutation(
    $input: StripePaymentDeleteInput!
  ) {
    stripePaymentDelete(input: $input) {
      user {
        id
        paymentIdDefault
        paymentDefault {
          ...Account_GetPayment
        }
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...Account_GetPayment
                }
              }
            }
          }
        }
      }
    }
  }
  ${GetPaymentFragment}
`;

export default mutation;
