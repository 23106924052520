import React from 'react';
import styled from '@emotion/styled';

import {
  useTheme,
  useMediaQuery,
  Dialog as DialogRoot,
} from '@onehope/design-system-v2';

import AddOrderForm from '../../ContactsV3/AddOrderForm';

interface AddOrderDialogProps {
  selectedEventId?: string | null;
  isOpen?: boolean;
  selectCustomer?: boolean;
  handleClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddOrderDialogContainer = styled(DialogRoot)`
  &.MuiDialog-root {
    @media only screen and (min-width: 968px) {
      min-height: 520px;
      max-width: 600px;
      margin: 0 auto;
    }
  }
`;

const FormContainer = styled.div`
  margin: auto;
  padding: 0 16px;
`;

export default function AddOrderDialog({
  isOpen,
  selectedEventId,
  selectCustomer,
  handleClose,
}: AddOrderDialogProps) {
  function handleNewCustomerClose() {
    handleClose(false);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <FormContainer>
      <AddOrderDialogContainer
        disableEnforceFocus
        open={isOpen}
        fullWidth
        maxWidth="md"
        fullScreen={fullScreen}
        onClose={handleNewCustomerClose}
      >
        <div>
          <AddOrderForm
            onClose={handleNewCustomerClose}
            selectCustomer={selectCustomer}
            defaultValues={{
              fullName: '',
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              accountId: '',
            }}
            eventId={selectedEventId}
          />
        </div>
      </AddOrderDialogContainer>
    </FormContainer>
  );
}
