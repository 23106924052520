import { useQuery } from '@apollo/client';
import { styled, Text, TEXT_DS } from '@onehope/design-system-v2';
import ReachOutTiles from './ReachOutTiles';
import Loading from '../../../common/Loading';
import REACH_OUTS_V2 from '../../../queries/ReachOutsV2';
import { BodyText } from '../StyledComponents/Text';
import {
  CEDash_GetReachOutsV2,
  CEDash_GetReachOutsV2Variables,
} from '../../../queries/generatedTypes/CEDash_GetReachOutsV2';
import { CEDash_GetReachOutsCategories_viewer_v2_reachOutTemplates_categories } from '../../../queries/generatedTypes/CEDash_GetReachOutsCategories';

const CenteredText = styled(Text)({
  maxWidth: '800px',
  textAlign: 'center',
});

type Props = {
  isActive: boolean;
  isMediumTabletAndUp: boolean;
  selectedCategory: CEDash_GetReachOutsCategories_viewer_v2_reachOutTemplates_categories;
};

export default function Library({
  isActive,
  isMediumTabletAndUp,
  selectedCategory,
}: Props) {
  const { data, loading, error } = useQuery<
    CEDash_GetReachOutsV2,
    CEDash_GetReachOutsV2Variables
  >(REACH_OUTS_V2, {
    variables: {
      category: selectedCategory?.contentfulEntryId,
      isActive,
    },
  });

  const templates = data?.viewer?.v2?.reachOutTemplates?.templates;

  if (loading) {
    return <Loading />;
  }

  if (error || typeof templates === 'undefined') {
    return (
      <BodyText text="Sorry, we can't load reach outs right now. Please try again later." />
    );
  }

  if (!templates?.length) {
    <CenteredText
      variant="custom"
      default={
        isMediumTabletAndUp ? TEXT_DS.BODY_REGULAR_20 : TEXT_DS.BODY_REGULAR_16
      }
    >
      There are currently no {isActive ? 'active' : 'inactive'} reach outs
      {selectedCategory?.name ? ` for ${selectedCategory?.name}` : ''}. Check
      back later.
    </CenteredText>;
  }

  return <ReachOutTiles templates={templates} />;
}
