import { gql } from '@apollo/client';
import { GetPaymentFragment } from './fragments/PaymentFragment';

const PAYMENTS_GET_QUERY = gql`
  query Account_PaymentsGetQuery($contactId: String) {
    viewer {
      id
      user {
        id
        paymentIdDefault
        paymentDefault {
          ...Account_GetPayment
        }
        shippingAddressIdDefault
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...Account_GetPayment
                }
              }
            }
          }
        }
      }
      userInfo(contactId: $contactId) {
        id
        paymentIdDefault
        paymentDefault {
          ...Account_GetPayment
        }
        shippingAddressIdDefault
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...Account_GetPayment
                }
              }
            }
          }
        }
      }
    }
  }
  ${GetPaymentFragment}
`;

export default PAYMENTS_GET_QUERY;
