import React from 'react';

import EventDetailsForm from '../../../Events/EventDetailsForm';
import FormContainer from '../../../Event/FormContainer';
import Notification from '../../../Notifcations/DefaultNotifcation';
import { getInitialValues } from '../../../Event/TabContent/Details/helpers';
import { EventDetailsFormSchema } from '../../../Event/TabContent/Details/validationSchema';
import useToggle from '../../../../utils/useToggleHook';
import { MyFormValues } from '../../../Event/TabContent/Details/FormTypes';
import {
  DetailsWrapper,
  TabContentContainer,
} from '../../../Event/TabContent/index.styles';

interface DetailsProps {
  form: any;
  handleNextStepChange: (
    key: string,
    values: MyFormValues,
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>,
    setErrors?: (errors: Record<string, string>) => void,
  ) => Promise<void> | undefined;
  handlePreviousStepChange: () => void;
}

const Details: React.FC<DetailsProps> = ({
  handlePreviousStepChange,
  handleNextStepChange,
}) => {
  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();
  const {
    value: editing,
    setTrue: setIsEditing,
    setFalse: setIsNotEditing,
  } = useToggle();
  const SUCCESS_MESSAGE = `Your profile has been updated`;
  return (
    <DetailsWrapper>
      <TabContentContainer>
        <Notification
          variant="success"
          open={notificationOpen}
          message={SUCCESS_MESSAGE}
          handleClose={setNotificationClosed}
        />
        <FormContainer
          validationSchema={EventDetailsFormSchema}
          initialValues={getInitialValues()}
          /* eslint-disable react/jsx-no-bind */
          formToUse={(props: any) => (
            <EventDetailsForm
              {...props}
              editing={false}
              setIsNotEditing={setIsNotEditing}
              handlePreviousStepChange={handlePreviousStepChange}
            />
          )}
          onSubmit={async (values: MyFormValues, { setErrors }) => {
            await handleNextStepChange('details', values, null, setErrors);
          }}
        />
      </TabContentContainer>
    </DetailsWrapper>
  );
};
export default Details;
