// theme colors
export const primaryColor = `#373634`;
export const quaternaryColor = `#13c2c2`;
export const quaternaryHoverColor = `#4ed1d1`;

export const successColor = `rgba(19, 194, 194, 1)`;
export const warningColor = `rgba(250,173,20,1)`;
export const disabledColor = `rgba(221, 221, 221, 1)`;
export const secondaryDisabledColor = `rgba(186, 186, 186, 1)`;
export const errorColor = `rgba(255, 77, 79, 1)`;
export const mutedAccentColor = `#dddddd`;
export const neutralBackgroundColor = `#ffffff`;

export const linkColor = `#1890ff`;
export const linkHoverColor = `#51abff`;
export const inputFocusColor = `#faf6f3`;
export const primaryHoverColor = `#535353`;
export const defaultHoverColor = `#e8e8e8`;

export const greenTintColor = `#EFFBFA`;
export const googleColor = `#ff4d4f`;
export const googleHoverColor = `#ff797b`;
export const facebookColor = `#4267b2`;
export const hopeLightPurple = `#784cab`;
export const neutral0 = '#FFFFFF';
export const neutral2 = '#fafafa';
export const neutral5 = '#f2f2f2';
export const neutral6 = '#c2c2c2';
export const neutral10 = '#f6f6f4';
export const neutral13 = '#dddddd';
export const neutral34 = '#a9a9a9';
export const neutral80 = '#333333';
export const neutral100 = '#88898A';

export const bronzeNeutral0 = '#997963';
export const bronzeNeutral2 = '#9F7058';
export const bronzeNeutral3 = '#E9E6DF';
export const bronzeNeutral4 = '#757575';
export const bronzeNeutral5 = '#A17566';
export const bronzeNeutral6 = '#FAF6F3';

export const mudNeutral0 = '#9F7058';

export const black0 = '#000000';
export const black1 = '#1a1a1a';
export const black2 = '#393939';

export const lightBlue = '#F8F9FC';
export const lightBlueTint = '#F0F8FE';
export const blue0 = '#46789E';

// text
export const font = `'Gotham HTF', 'Gotham A', 'Gotham B', Arial`;
export const secondaryFont = `'Roboto', sans-serif`;
export const tertiaryFont = `'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif`;
export const italicFont = `'Playfair Display', 'proxima-nova', serif`;
export const textColor = `#373634`;
export const secondaryTextColor = `rgb(169,169,169,1)`; // #a9a9a9
export const redText = `#f4564e`;
export const deepRedText = `#B04A3D`;
export const bodyFontFamily = `'brandon-grotesque', Arial, sans-serif`;
export const headerFontFamily = `'IvyMode', serif`;
export const accentFontFamily = `'Juniper and Sage', serif`;
export const hestinaFontFamily = `'Hestina', serif`;
export const gattewaySignatureFontFamily = `'Gatteway Signature', serif`;
export const MONTSERRAT = `'Montserrat', serif`;
export const SACRAMENTO = `'Sacramento', serif`;
export const PRATA = `'Prata', serif`;
export const AELX_BRUSH = `'AlexBrush', serif`;
