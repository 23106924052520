import React, { useCallback, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import {
  ThemeType,
  GridV2 as Grid,
  Text,
  useTheme,
  useMediaQuery,
  Icons,
  makeStyles,
  ButtonBase,
  styled,
  Box,
  TEXT_DS,
} from '@onehope/design-system-v2';
import { ceProfileImage } from '../../../utils/utils';
import { mapAbbreviations } from '../MyMentor/MyMentor';
import { CEDash_DashboardQuery_viewer_user } from '../../../queries/generatedTypes/CEDash_DashboardQuery';

type GreetingProps = {
  user: CEDash_DashboardQuery_viewer_user;
};

const useStyles = makeStyles((theme: ThemeType) => ({
  copyBtn: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    textDecorationLine: 'underline',
    color: theme.palette.mud.main,
    backgroundColor: 'Transparent',
    border: '0px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: '0px',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  rank: {
    paddingBottom: 2,
  },
  rankWrapper: {
    height: '144px',
    marginTop: '24px',
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
      height: '120px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '14px',
      height: '108px',
    },
  },
  link: {
    maxWidth: 350,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '380px',
    },
  },
  clickableLink: {
    textDecoration: 'none',
    wordWrap: 'break-word',
    color: theme.palette.forestGreen.main,
    '&:hover': {
      color: theme.palette.forestGreen.main,
      textDecoration: 'underline',
    },
  },
  linkWrapper: {},
  rankTitle: {
    marginBottom: '2px',
    color: theme.palette.greyDark.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  dealerId: {
    marginBottom: '2px',
    color: theme.palette.greyDark.main,
  },
  greetingTitle: {
    paddingBottom: 8,
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      lineHeight: '44px',
      letterSpacing: '0.03em',
    },
  },
  greetingContentWrapper: {
    height: 'auto',
    width: '100%',
    padding: '29px',
    [theme.breakpoints.only('md')]: {
      padding: '16px 24px 24px 24px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px 24px 16px',
    },
  },
  avatar: (props: GreetingProps) => ({
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    backgroundImage: `url(${
      props.user?.profileImages?.thumb
        ? ceProfileImage(props.user?.profileImages?.thumb)
        : ''
    })`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      height: '80px',
      width: '80px',
    },
  }),
}));

const CopyText = styled(Text)(({ theme }) => ({
  color: theme.palette.greyDark.main,
  fontWeight: 420,
}));

function getRank(trinityCurrentRank: string): string | null {
  if (trinityCurrentRank) {
    return mapAbbreviations(trinityCurrentRank);
  }
  return null;
}
const Greeting = ({ user }: GreetingProps) => {
  const [copied, setCopied] = useState(false);

  const getGreeting = (firstName: string | null): string => {
    let date = new Date();
    let now = date.getHours();
    if (now >= 4 && now < 12) {
      return `Good morning, ${firstName || ''}!`;
    } else if (now >= 12 && now < 18) {
      return `Good afternoon, ${firstName || ''}!`;
    } else {
      return `Good evening, ${firstName || ''}!`;
    }
  };

  const { exigoCustomerId, firstName, customUrl, trinityCurrentRank } = user;
  const userLink = `${process.env.GATSBY_HOPECOMMERCE_URL}/myshop/${customUrl}`;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const avatarSize = isDesktop ? '100px' : '80px';

  const classes = useStyles({ user });

  const formatUserLink = useMemo(() => {
    if (userLink.startsWith('https://')) {
      return userLink.substr(8);
    } else if (userLink.startsWith('http://')) {
      return userLink.substr(7);
    }

    return userLink;
  }, [userLink]);

  const copyLink = useCallback(() => {
    copy(userLink);
    setCopied(true);
  }, [userLink]);

  const rankTitle = getRank(trinityCurrentRank || 'Wine Rep');

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item>
          {!!user?.profile?.image ? (
            <Grid className={classes.avatar} />
          ) : (
            <Icons.EmptyProfile width={avatarSize} height={avatarSize} />
          )}
        </Grid>
        <Grid item xs={12} md="auto">
          <Text
            variant="custom"
            default={TEXT_DS.TITLE_LIGHT_24}
            className={classes.greetingTitle}
          >
            {getGreeting(firstName)}
          </Text>
          {rankTitle && (
            <Text
              variant="custom"
              default={TEXT_DS.OVERLINE_SEMIBOLD_14}
              className={classes.rankTitle}
            >
              {rankTitle}
            </Text>
          )}
          <Text
            variant="custom"
            default={TEXT_DS.BODY_REGULAR_16}
            className={classes.dealerId}
          >
            {exigoCustomerId}
          </Text>
          <Text
            variant="custom"
            default={TEXT_DS.BODY_REGULAR_16}
            className={classes.rank}
          >
            Pay Rank: {trinityCurrentRank || 'Wine Rep'}
          </Text>
          <Grid
            container
            className={classes.linkWrapper}
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Text
                variant="custom"
                default={TEXT_DS.BODY_SEMIBOLD_18}
                className={classes.link}
              >
                <a
                  href={userLink}
                  target={'_blank'}
                  className={classes.clickableLink}
                  rel="noreferrer"
                >
                  {formatUserLink}
                </a>
              </Text>
            </Grid>
            <Grid item sx={{ ml: 1 }}>
              {copied ? (
                <CopyText theme={theme}>Copied!</CopyText>
              ) : (
                <ButtonBase onClick={copyLink}>
                  <Icons.Copy color={theme.palette.forestGreen.main} />
                </ButtonBase>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Greeting;
