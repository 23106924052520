import { theme, useMediaQuery } from '@onehope/design-system-v2';
import { CustomerAutoshipsContactsV2_viewer_user_customerAutoships } from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsContactsV2';
import { FourBottleAutoshipCard } from './AutoshipCard';
import { FourBottleAutoshipTable } from './AutoshipTable';
import { GridList } from './styles';

interface Props {
  autoships: CustomerAutoshipsContactsV2_viewer_user_customerAutoships['autoships'];
  /** The function that should be called when a user wants to manage the Autoship subscription */
  handleManageAutoship: (autoshipId: string, contactId: string) => void;
}

export const FourBottleSubscriptionList = ({
  autoships,
  handleManageAutoship,
}: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!autoships) {
    return null;
  }

  if (isMobile) {
    return (
      <GridList count={autoships.length}>
        {autoships.map(autoship => (
          <FourBottleAutoshipCard
            key={autoship.autoShipId}
            autoship={autoship}
            autoshipTotal={autoships.length}
            handleManageAutoship={handleManageAutoship}
          />
        ))}
      </GridList>
    );
  }

  return (
    <FourBottleAutoshipTable
      autoships={autoships}
      handleManageAutoship={handleManageAutoship}
    />
  );
};
