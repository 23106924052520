import styled from '@emotion/styled';
import Button from 'antd/lib/button';
import 'antd/lib/button/style/index.css';

import {
  defaultHoverColor,
  disabledColor,
  font,
  secondaryFont,
  linkColor,
  linkHoverColor,
  primaryColor,
  primaryHoverColor,
  secondaryDisabledColor,
  textColor,
  quaternaryColor,
  quaternaryHoverColor,
  neutralBackgroundColor,
  facebookColor,
} from '../cssConstants';

/****************** BASE STYLES **************************************/
const baseStyle = `
  border-radius: 4px !important;
  margin: 0 0 16px 0;
  height: 40px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-family: ${font} !important;
 
  &:hover {
    background-color: ${primaryHoverColor} !important;
    border-color: ${primaryHoverColor}!important;
    cursor: pointer !important;
  }
  .anticon {
    font-size: 18px !important;
    left: 16px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
`;

const fabButtonBaseStyle = `font-family: ${font} !important;
  height: 56px !important;
  width: 56px !important;
  border-radius: 100px !important;
  .anticon {
      font-size: 20px !important;
  }
`;

const largeStyle = `
  ${baseStyle};
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.38 !important;
  letter-spacing: 1px !important;
  height: 56px !important;
`;

const primaryBaseStyle = `
  ${baseStyle};
  color: white !important;
  background-color: ${primaryColor} !important;
  border-color: ${primaryColor} !important;
  &:focus {
    color: white !important;
    background-color: ${primaryHoverColor} !important;
    border-color: ${primaryHoverColor} !important;
  }
  &[disabled],
  &[disabled]:hover {
    color: white !important;
    border-color: ${disabledColor} !important;
    background-color: ${disabledColor} !important;
  }
`;

const loginButtonStyle = `
  margin: 0 0 16px 0;
  height: 40px !important;
  padding: 11px 8px;
  font-family: ${secondaryFont} !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.22px !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25) !important;

  &:hover {
    background-color: ${primaryHoverColor} !important;
    border-color: ${primaryHoverColor}!important;
    cursor: pointer !important;
  }
`;

/****************** REGULAR BUTTONS **************************************
 * take base style *
 */

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonPrimaryRegular = styled(Button)`
  ${primaryBaseStyle};
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonPrimaryRegularIconRight = styled(Button)`
  ${primaryBaseStyle};
  .anticon {
    right: 16px !important;
    left: unset !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonPrimaryInactive = styled(Button)`
  ${primaryBaseStyle};
  color: white !important;
  background-color: ${disabledColor} !important;
  border-color: ${disabledColor} !important;
  &:focus {
    color: white !important;
    background-color: ${disabledColor} !important;
    border-color: ${disabledColor} !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonSecondaryRegular = styled(Button)`
  ${baseStyle};
  background-color: white !important;
  border-color: ${primaryColor} !important;
  color: ${textColor} !important;
  &:hover,
  &:focus {
    color: ${textColor} !important;
    background-color: ${defaultHoverColor} !important;
    border-color: ${primaryColor} !important;
  }
  &[disabled],
  &[disabled]:hover {
    color: ${secondaryDisabledColor} !important;
    background-color: white !important;
    border-color: ${secondaryDisabledColor} !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonTertiaryRegular = styled(Button)`
  ${baseStyle};
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  &:hover,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: ${primaryHoverColor} !important;
  }
  &[disabled],
  &[disabled]:hover {
    color: ${secondaryDisabledColor} !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonQuaternaryRegular = styled(Button)`
  ${baseStyle};
  color: white !important;
  background-color: ${quaternaryColor} !important;
  border-color: ${quaternaryColor} !important;
  &:hover,
  &:focus {
    color: white !important;
    border-color: ${quaternaryHoverColor} !important;
    background-color: ${quaternaryHoverColor} !important;
  }
` as any;

/****************** LARGE BUTTONS **************************************
 * take large style *
 */

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonPrimaryLarge = styled(Button)`
  ${largeStyle};
  background-color: ${linkColor} !important;
  border-color: ${linkColor} !important;
  &:hover,
  &:focus {
    background-color: ${linkHoverColor} !important;
    border-color: ${linkHoverColor} !important;
  }
  &[disabled],
  &[disabled]:hover {
    color: white !important;
    border-color: ${disabledColor} !important;
    background-color: ${disabledColor} !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonQuaternaryLarge = styled(Button)`
  ${largeStyle};
  background-color: ${quaternaryColor} !important;
  border-color: ${quaternaryColor} !important;
  &:hover,
  &:focus {
    background-color: ${quaternaryHoverColor} !important;
    border-color: ${quaternaryHoverColor} !important;
  }
  &[disabled],
  &[disabled]:hover {
    color: white !important;
    border-color: ${disabledColor} !important;
    background-color: ${disabledColor} !important;
  }
` as any;

/****************** CUSTOM BUTTONS **************************************/

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonEmail = styled(Button)`
  ${loginButtonStyle};
  color: white !important;
  background-color: ${primaryColor} !important;
  border-color: ${primaryColor} !important;
  .anticon {
    padding-right: 4px !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonFacebook = styled(Button)`
  ${loginButtonStyle};
  background-color: ${facebookColor} !important;
  border-color: ${facebookColor} !important;
  &:hover,
  &:focus {
    border-color: ${facebookColor} !important;
    background-color: ${facebookColor} !important;
  }
  .anticon {
    padding-right: 4px !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonGoogle = styled(Button)`
  ${loginButtonStyle};
  color: ${textColor} !important;
  background-color: ${neutralBackgroundColor} !important;
  border-color: ${neutralBackgroundColor} !important;
  &:hover,
  &:focus {
    border-color: ${neutralBackgroundColor} !important;
    background-color: ${neutralBackgroundColor} !important;
  }
` as any;

/****************** FAB BUTTONS **************************************
 * take fab base style *
 */

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonPrimaryFab = styled(Button)`
  ${fabButtonBaseStyle};
  background-color: ${primaryColor} !important;
  border-color: ${primaryColor} !important;
  .anticon {
    fill: black !important;
  }
  &:hover {
    background-color: ${primaryHoverColor} !important;
    border-color: ${primaryHoverColor} !important;
  }
` as any;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const ButtonSecondaryFab = styled(Button)`
  ${fabButtonBaseStyle};
  background-color: white !important;
  border-color: ${primaryColor} !important;
  .anticon {
    fill: white !important;
  }
  &:hover,
  &:focus {
    background-color: ${defaultHoverColor} !important;
    border-color: ${primaryColor} !important;
  }
` as any;
