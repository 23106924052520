import React from 'react';
import { makeStyles, ThemeType, Text, Grid } from '@onehope/design-system-v2';

export const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    margin: '12px auto 0 auto',
    padding: 24,
    backgroundColor: theme.palette.lightOrangeTint.main,
    border: `1px solid ${theme.palette.mud.main}`,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 460,
    },
  },
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '0.01em',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}));

function WarningCard() {
  const classes = useStyles();
  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text className={classes.text}>
        Next season's Wine Club is not ready yet. We will email you as soon as
        it is so you can make customizations.
      </Text>
    </Grid>
  );
}

export default WarningCard;
