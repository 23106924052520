// material-ui
import React from 'react';
import {
  GridV2 as Grid,
  useTheme,
  Text,
  Box,
  styled,
  TEXT_DS,
  Currency,
} from '@onehope/design-system-v2';

import MainCard from '../MainCard';

// types
interface StatisticsCardProps {
  heading: string | number | undefined;
  value: number;
  iconPrimary: any;
}

const Heading = styled(Text)(({ theme }) => ({
  color: theme.palette.greyDark.main,
}));

const StatisticsCard = ({
  heading,
  value = 0,
  iconPrimary,
}: StatisticsCardProps) => {
  const theme = useTheme();

  const IconPrimary = iconPrimary!;
  const headingIcon = iconPrimary ? <IconPrimary /> : null;

  return (
    <MainCard>
      <Grid container alignItems="center">
        <Box
          sx={{
            pr: { xs: 2 },
          }}
        >
          {headingIcon}
        </Box>
        <Grid container item xs>
          <Grid item xs={12}>
            <Heading
              variant="custom"
              default={TEXT_DS.OVERLINE_SEMIBOLD_14}
              theme={theme}
            >
              {heading}
            </Heading>
          </Grid>
          <Grid item xs={12}>
            <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_22}>
              <Currency rawValue={value} />
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default StatisticsCard;
