import React from 'react';

export const SvgPencil = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <path
        id="pencil__a"
        d="M8.8 20.2v3h3l8.846-8.847-3-3L8.8 20.2zm14.166-8.166c.312-.312.312-.816 0-1.128l-1.872-1.872c-.312-.312-.816-.312-1.128 0l-1.463 1.464 3 3 1.463-1.464z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="pencil__b" fill="#fff">
        <use href="#pencil__a" />
      </mask>
      <g fill="#1A1A1A" mask="url(#pencil__b)">
        <path d="M0 0L32 0 32 32 0 32z" />
      </g>
    </g>
  </svg>
);
