import React from 'react';
import styled from '@emotion/styled';
import { Checkbox, Text, TEXT_DS, theme } from '@onehope/design-system-v2';
import { SvgArchive } from '../../../assets/archive';
import { styles as s } from '@onehope/design-system';
import { ContactType, useContactsContext } from '../ContactsContext';
import CardListView from './CardListView';

const { cssConstants } = s;

const ArchiveButton = styled(Text)`
  color: ${theme.palette.forestGreen.main};
  margin-left: 12px;
  :hover {
    cursor: pointer;
  }
`;

const ArchiveWarningDiv = styled.div`
  font-family: ${cssConstants.bodyFontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
`;
const ArchiveWarningContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-family: ${cssConstants.bodyFontFamily};
  background-color: #ddd;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  @media all and (max-width: 600px) {
    height: 60px;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ArchiveContact = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const CheckBoxHeaderContainer = styled.div`
  display: flex;
  height: 50px;
  padding: 2;
  align-items: center;
`;

interface CardListViewProps {
  contacts: ContactType[];
}

const CardListViewContainer = ({ contacts }: CardListViewProps) => {
  const {
    isEditing,
    allSelected,
    selectedContacts,
    toggleAllSelected,
    toggleModalState,
  } = useContactsContext();
  return (
    <>
      {isEditing && (
        <CheckBoxHeaderContainer>
          <Checkbox checked={allSelected} onClick={() => toggleAllSelected()} />
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_14}>
            {selectedContacts.length >= 1
              ? `Selected ${selectedContacts.length}`
              : `Select all ${contacts.length}`}
          </Text>
          {selectedContacts.length >= 1 && (
            <>
              <ArchiveContact>
                <SvgArchive onClick={() => toggleModalState(true)} />
              </ArchiveContact>
              <ArchiveButton
                variant="custom"
                default={TEXT_DS.BODY_SEMIBOLD_14}
                onClick={() => toggleModalState(true)}
              >
                Delete selected
              </ArchiveButton>
            </>
          )}
        </CheckBoxHeaderContainer>
      )}
      {isEditing && allSelected && (
        <ArchiveWarningContainer>
          <ArchiveWarningDiv>
            {contacts?.length === 0
              ? `0 contacts have been selected`
              : `All (${selectedContacts.length}) contacts have been selected`}
          </ArchiveWarningDiv>
        </ArchiveWarningContainer>
      )}
      <CardListView contacts={contacts} />
    </>
  );
};

export default CardListViewContainer;
