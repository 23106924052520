import * as React from 'react';
import styled from '@emotion/styled';
import { CheckBoxPrimaryCircle } from '../styles/ant-design-styles/checkBox';
import { primaryColor, font, textColor } from '../styles/cssConstants';

type HorizontalToggleButtonProps = {
  kind?: string;
  width?: string;
  label: string;
  onClick: () => void;
  svgIcon?: string;
  on: boolean;
};

type ChunkButtonProps = {
  kind?: string;
  width?: string;
  on?: boolean;
};

const ChunkButton = styled.button<ChunkButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ on }: ChunkButtonProps) => (on ? 'white' : '#f2f2f2')};
  width: ${({ width }: ChunkButtonProps) => (width ? width : 'inherit')};
  border: ${({ on }: ChunkButtonProps) =>
    on ? `1px solid ${primaryColor}` : '1px solid white'};
  outline: none;
  border-radius: 4px;
  font-size: 22px;
  padding: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const VerticalButtonText = styled.div<ChunkButtonProps>`
  font-family: ${font};
  color: ${textColor};
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
`;

export default function HorizontalToggleButton({
  onClick,
  label,
  width,
  on,
}: HorizontalToggleButtonProps) {
  // Toggles rely on state outside of the button itself, so "on" should be passed in.
  // const [on, setOn] = useState(false);
  //
  // const toggle = () => {
  //   setOn(!on);
  //   if (onClick) {
  //     onClick();
  //   }
  // };

  return (
    <ChunkButton on={on} type="button" width={width} onClick={onClick}>
      <VerticalButtonText>{label}</VerticalButtonText>
      <CheckBoxPrimaryCircle checked={on} />
    </ChunkButton>
  );
}
