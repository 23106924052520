import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import {
  Dialog as DialogRoot,
  DialogContentText,
  DialogTitle,
} from '@onehope/design-system-v2';

const { cssConstants } = s;

export const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    max-width: 432px;
    margin: 0 auto;
    z-index: 9000 !important;
    padding: 0;
  }
  .MuiDialog-paper {
    margin: 0;
    padding: 40px 56px;
    @media only screen and (max-width: 600px) {
      margin: 16px;
      padding: 40px 16px;
    }
  }
`;

export const DialogTitleWrapper = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 0;
    margin-bottom: 16px;
  }
  .MuiTypography-h6 {
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.5px;
    text-align: center;
  }
`;

export const DialogTextWrapper = styled(DialogContentText)`
  &.MuiDialogContentText-root {
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
  }
  &.MuiDialogContentText-root {
    margin-bottom: 32px;
  }
`;
