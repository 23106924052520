import React from 'react';
import { isMacOs } from 'react-device-detect';
import {
  styled,
  Button,
  DialogV2,
  theme,
  TEXT_DS,
  Text,
  ButtonStyle,
} from '@onehope/design-system-v2';

import { formatPhone } from '../helpers';

import { CEDash_ReachOutRequest_registerReachOutV2_shareConfig } from '../../../mutations/ReachOuts/generatedTypes/CEDash_ReachOutRequest';
import { CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList_simpleContact } from '../../../queries/generatedTypes/CEDash_ReachOutsV2InviteQuery';
import { BlueButtonLink, LinkText } from '../StyledComponents/Text';

const ContentBlock = styled('div')({
  display: 'flex',
  marginBottom: '8px',
});

const Label = styled(Text)({
  color: theme.palette.greyDark.main,
  width: '100px',
  marginRight: '24px',
});

const TextWithMarginTop = styled(Text)({
  marginTop: '16px',
});

const QRCode = styled('img')({
  height: '100px',
  width: '100px',
  marginTop: '24px',
  marginBottom: '8px',
});

const SendOnDesktopContainer = styled('div')({
  marginTop: '12px',
  marginBottom: '8px',
});

const SmsDesktopModal = ({
  open,
  setOpen,
  contact,
  config,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contact: CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList_simpleContact;
  config: CEDash_ReachOutRequest_registerReachOutV2_shareConfig;
}) => {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const onSendToDesktop = () => {
    window.open(config.qrCodeContents, '_blank');
  };

  if (!contact || !config) {
    return null;
  }

  return (
    <DialogV2
      open={open}
      onClose={handleCloseDialog}
      title="Share Reach Out"
      customButtonComponent={
        <Button onClick={handleCloseDialog} fullWidth>
          close
        </Button>
      }
    >
      <>
        <ContentBlock>
          <Label variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
            Contact:
          </Label>
          <Text
            variant="custom"
            default={TEXT_DS.BODY_REGULAR_16}
          >{`${contact.firstName} ${contact.lastName}`}</Text>
        </ContentBlock>
        <ContentBlock>
          <Label variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
            Phone:
          </Label>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {formatPhone(contact.phoneNumber)}
          </Text>
        </ContentBlock>
        <Text variant="custom" default={TEXT_DS.BODY_LIGHT_ITALIC_16}>
          {config.body}
        </Text>

        {config.qrCodeImage !== null && (
          <>
            <TextWithMarginTop
              variant="custom"
              default={TEXT_DS.BODY_SEMIBOLD_16}
            >
              Scan the QR code on your phone to send an SMS:
            </TextWithMarginTop>

            <QRCode src={config.qrCodeImage} alt="qr code" />

            {isMacOs && (
              <SendOnDesktopContainer>
                <BlueButtonLink
                  sx={{ marginTop: '8px', marginBottom: '8px' }}
                  type={ButtonStyle.SMALL_LINK}
                  onClick={onSendToDesktop}
                >
                  <LinkText variant="button">SEND ON DESKTOP</LinkText>
                </BlueButtonLink>
              </SendOnDesktopContainer>
            )}
          </>
        )}
      </>
    </DialogV2>
  );
};

export default SmsDesktopModal;
