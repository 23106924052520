import React, { useState, useContext, createContext } from 'react';
import useToggle from '../../utils/useToggleHook';
import { ArchiveContactModal } from '../Dialog/ArchiveContact/ArchiveContactModal';

type ArchiveContactProviderProps = {
  handleContact: (contactId: string) => void;
  handleContacts: (contactIds: string[], isAllSelected: boolean) => void;
  cancelArchive: () => void;
  handleArchiveContact: (selectedIds: any, allContactsCount: number) => void;
  allSelected: boolean;
  setAllSelectedTrue: () => void;
  setAllSelectedFalse: () => void;
  handleArchiveAllContacts: (
    contactsId: string[],
    allContactsCount: number,
  ) => void;
  handleSingleArchiveContact: (selectedId: string) => void;
  selectedContacts: any;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const ArchiveContactsContext = createContext<
  ArchiveContactProviderProps
>();

export const useArchiveContactsContext = () =>
  useContext<ArchiveContactProviderProps>(ArchiveContactsContext);

export const ArchiveContactsContextProvider = ({
  children,
}: {
  children: any;
}) => {
  const {
    value: isArchiveDialogOpen,
    setTrue: setIsArchiveDialogOpen,
    setFalse: setIsArchiveDialogClose,
  } = useToggle();

  const {
    value: allSelected,
    setTrue: setAllSelectedTrue,
    setFalse: setAllSelectedFalse,
  } = useToggle();

  const [selectedContacts, setSelectedContacts] = useState<
    (string | undefined)[]
  >([]);

  const [allContacts, setAllContacts] = useState<number>(-1);

  const [contactOrContacts, setContactOrContacts] = useState<string>(
    'contact.',
  );

  const [isSingle, setSingle] = useState(false);

  const excludeContact = (contactId: string) => {
    const newContacts = selectedContacts.filter(
      contact => contact !== contactId,
    );
    setSelectedContacts([...newContacts]);
  };

  const addContact = (contactId: string) => {
    setSelectedContacts([...selectedContacts, contactId]);
  };

  const cancelArchive = () => {
    setSelectedContacts([]);
  };

  //for the 'archive' button
  const handleArchiveContact = (selectedIds: any, allContactsCount: number) => {
    if (Array.isArray(selectedIds)) {
      setSelectedContacts(selectedIds);
    } else {
      setSelectedContacts([selectedIds]);
    }
    if (allContactsCount > 1) {
      setContactOrContacts('contacts.');
    } else {
      setContactOrContacts('contact.');
    }
    setIsArchiveDialogOpen();
  };

  const handleSingleArchiveContact = (selectedId: string) => {
    setSelectedContacts([selectedId]);
    setContactOrContacts('');
    setIsArchiveDialogOpen();
  };

  //for the checkbox in the header
  const handleContacts = (contactIds: string[], isAllSelected: boolean) => {
    if (isAllSelected) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts([...contactIds]);
    }
  };

  // for the checkbox in the body
  const handleContact = (contactId: string) => {
    if (selectedContacts.includes(contactId)) {
      excludeContact(contactId);
    } else {
      addContact(contactId);
    }
  };

  const handleArchiveAllContacts = (
    contactIds: string[],
    allContactsCount: number,
  ) => {
    setSelectedContacts([...contactIds]);
    setAllContacts(allContactsCount);
    setContactOrContacts('contacts.');
    setIsArchiveDialogOpen();
  };

  const handleModalClose = () => {
    setSelectedContacts([]);
    setAllContacts(-1);
    setAllSelectedFalse();
    setIsArchiveDialogClose();
  };

  return (
    <ArchiveContactsContext.Provider
      value={{
        handleContact,
        handleContacts,
        selectedContacts,
        setSelectedContacts,
        cancelArchive,
        handleArchiveAllContacts,
        handleArchiveContact,
        handleSingleArchiveContact,
        setAllSelectedTrue,
        setAllSelectedFalse,
        allSelected,
      }}
    >
      <ArchiveContactModal
        setIsArchiveDialogClose={handleModalClose}
        isArchiveDialogOpen={isArchiveDialogOpen}
        contactIds={selectedContacts}
        clearSelectedContacts={cancelArchive}
        allContacts={allContacts}
        contactOrContacts={contactOrContacts}
      />
      {children}
    </ArchiveContactsContext.Provider>
  );
};
