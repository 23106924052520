import React, { useEffect } from 'react';
import { Grid, Text, TEXT_DS, styled } from '@onehope/design-system-v2';

import { useQuery } from '@apollo/client';

import Tabs from '../Tabs';
import SubscriptionError from '../SubscriptionError';

import { isSubscriptionEditable } from '../../utils/wineClub/helpers';
import { SendToTrackEventV2 } from '../../utils/segment';

import AUTO_SHIP_GET from '../../queries/AutoshipGet';
import SEGMENT_IDENTIFY_GET from '../../queries/SegmentIdentifyGet';
import {
  WineClub_AutoShipGetQuery,
  WineClub_AutoShipGetQueryVariables,
  WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges,
  WineClub_AutoShipGetQuery_viewer_userInfo_stripePaymentCollection_entries_edges,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import {
  WineClub_SegmentIdentifyGetQuery,
  WineClub_SegmentIdentifyGetQueryVariables,
} from '../../queries/generatedTypes/WineClub_SegmentIdentifyGetQuery';

const SubscriptionTitle = styled(Text)({
  marginBottom: '24px',
});

function findPaymentMethod(
  payments:
    | (WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges | null)[]
    | (WineClub_AutoShipGetQuery_viewer_userInfo_stripePaymentCollection_entries_edges | null)[],
  paymentMethod: string | null,
) {
  return payments.find(
    (payment) => payment?.node?.stripePayment?.paymentId === paymentMethod,
  );
}

function SubscriptionWrapper({
  autoShipId,
  contactId,
  sendToTrackEventV2,
  refetchOnChange,
  wineClub923,
}: {
  autoShipId: string;
  contactId?: string;
  sendToTrackEventV2?: SendToTrackEventV2;
  /** If provided, `refetch()` will be called for Autoship data when this value changes. */
  refetchOnChange?: any;
  wineClub923?: boolean;
}) {
  const conciergeMode = !!contactId;

  const { data, loading, refetch } = useQuery<
    WineClub_AutoShipGetQuery,
    WineClub_AutoShipGetQueryVariables
  >(AUTO_SHIP_GET, {
    variables: {
      autoShipId: autoShipId.toString(),
      byContactId: contactId ? contactId.toString() : '',
    },
  });

  const user = data?.viewer?.user;
  const contact = data?.viewer?.userInfo;

  const { data: segmentData } = useQuery<
    WineClub_SegmentIdentifyGetQuery,
    WineClub_SegmentIdentifyGetQueryVariables
  >(SEGMENT_IDENTIFY_GET, {
    variables: {
      accountId: conciergeMode ? contact?.userId : user?.userId,
    },
  });

  const refetchSubscription = () => {
    return refetch({
      autoShipId: autoShipId.toString(),
    });
  };

  useEffect(() => {
    if (refetchOnChange) {
      refetchSubscription();
    }
  }, [refetchOnChange]);

  const subscription = data?.viewer?.user?.autoShip;
  if (!subscription) return null;

  const payments =
    (conciergeMode
      ? contact?.stripePaymentCollection?.entries?.edges
      : user?.stripePaymentCollection?.entries?.edges) || [];
  const paymentMethod = findPaymentMethod(
    payments,
    subscription?.paymentMethod,
  );

  if (loading) return null;

  const permissions = isSubscriptionEditable(
    subscription,
    conciergeMode,
    // @ts-ignore
    contact,
  );
  if (!permissions.editable) {
    return <SubscriptionError errorMessage={permissions.errorMessage} />;
  }

  return (
    <Grid container direction="column" alignItems="center">
      <SubscriptionTitle
        variant="custom"
        desktop={TEXT_DS.TITLE_LIGHT_32}
        laptop={TEXT_DS.TITLE_LIGHT_32}
        tablet={TEXT_DS.TITLE_LIGHT_32}
        mobile={TEXT_DS.TITLE_LIGHT_24}
      >
        {subscription?.autoShipName}
      </SubscriptionTitle>
      <Tabs
        // @ts-ignore
        user={user}
        // @ts-ignore
        segmentUser={conciergeMode ? contact : user || {}}
        // @ts-ignore
        segmentPersonas={segmentData?.viewer?.segmentIdentify || {}}
        subscription={subscription}
        paymentMethod={paymentMethod?.node?.stripePayment}
        refetchSubscription={refetchSubscription}
        conciergeMode={conciergeMode}
        contactId={contactId}
        sendToTrackEventV2={sendToTrackEventV2}
        wineClub923={wineClub923}
      />
    </Grid>
  );
}

export default SubscriptionWrapper;
