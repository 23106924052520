import React from 'react';
import { Text, TEXT_DS, DialogV2 } from '@onehope/design-system-v2';

interface FrequencyUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  saveChange: () => void;
  loading: boolean;
}

const FrequencyUpdateDialog = (props: FrequencyUpdateDialogProps) => {
  const { onClose, open, saveChange, loading } = props;

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <DialogV2
      open={open}
      onClose={handleClose}
      saveButtonLabel="Yes"
      closeButtonLabel="Cancel"
      title={'Confirm Frequency Change'}
      onSave={saveChange}
      onSaveLoading={loading}
      buttonDirection="column"
    >
      <Text variant="custom" default={TEXT_DS.BODY_LIGHT_18}>
        Are you sure you would like to update your subscription frequency?
      </Text>
    </DialogV2>
  );
};

export default FrequencyUpdateDialog;
