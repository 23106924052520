export const OrderStatus = {
  orderReceived: 'Order Received',
  onHold: 'On Hold',
  underReview: 'Under Review',
  cancelled: 'Cancelled',
  delivered: 'Delivered',
  complete: 'Complete', // For webservice only orders like memberships
  undetermined: 'Undetermined',
  inProgress: 'In Progress',
};
