import React from 'react';
import { ReactMuiMask } from '@onehope/design-system-v2';

export default function EinMask(props: any) {
  return (
    <ReactMuiMask
      mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      {...props}
    />
  );
}
