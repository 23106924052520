import React, { Dispatch, SetStateAction } from 'react';
import ContactsTable from './ContactsTable';
import { ContactType } from './ContactsContext';
import Header from './Header';

interface ContactsWrapperProps {
  contacts: ContactType[];
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  handleSortContacts: (newSortBy: string) => void;
  count: number;
  userId: string;
  setSearchString: Dispatch<SetStateAction<string>>;
  filter: string;
  handleFilterContacts: (newFilter: string) => void;
}

const ContactsWrapper = ({
  contacts,
  pageNumber,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  handleSortContacts,
  count,
  userId,
  setSearchString,
  filter,
  handleFilterContacts,
}: ContactsWrapperProps) => {
  return (
    <>
      <Header userId={userId} />
      <ContactsTable
        contacts={contacts}
        page={pageNumber}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleSortContacts={handleSortContacts}
        count={count}
        setSearchString={setSearchString}
        filter={filter}
        handleFilterContacts={handleFilterContacts}
      />
    </>
  );
};

export default ContactsWrapper;
