import { useMutation, useQuery } from '@apollo/client';
import {
  ListItemIcon,
  Icons,
  Text,
  useTheme,
  useMediaQuery,
} from '@onehope/design-system-v2';

import ACCOUNT_CAN_USE_TRINITY_BACK_OFFICE from '../../../../../queries/AccountCanUseTrinityBackOffice';
import LoginTrinitySsoMutation from '../../../../../mutations/CE/LoginTrinitySsoMutation';
import { LoginTrinitySsoMutation as LoginTrinitySsoMutationType } from '../../../../../mutations/CE/generatedTypes/LoginTrinitySsoMutation';
import { CEDash_AccountCanUseTrinityBackOfficeQuery } from '../../../../../queries/generatedTypes/CEDash_AccountCanUseTrinityBackOfficeQuery';

import { trackEvent } from '../../../../../utils/segment/tracking';
import { useTrackingContextValue } from '../../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../../utils/segment/constants';
import { CustomListItem } from './NavigationStyled';

/**
 * Self-contained component for the Trinity Back Office menu item.
 * Hides itself by default, appearing based on the result of a GQL query.
 */
export const TrinityBackOfficeMenuItem = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEventV2 } = useTrackingContextValue();

  const backOfficeUrl = process.env.GATSBY_CE_BACK_OFFICE;

  const { data } = useQuery<CEDash_AccountCanUseTrinityBackOfficeQuery>(
    ACCOUNT_CAN_USE_TRINITY_BACK_OFFICE,
  );
  const [loginTrinitySso] = useMutation<LoginTrinitySsoMutationType>(
    LoginTrinitySsoMutation,
  );

  function trackEventStaging(eventName: string) {
    if (
      process.env.GATSBY_BUILD_ENV === 'staging' ||
      process.env.GATSBY_BUILD_ENV === 'development'
    ) {
      trackEvent({
        event: eventName,
        properties: {},
      });
    }
  }

  const handleOpenBackOffice = async () => {
    await loginTrinitySso({
      variables: { input: {} },
    }).then(response => {
      const ua = navigator.userAgent.toLowerCase();
      let isSafari = null;
      if (ua.indexOf('safari') !== -1) {
        isSafari = ua.indexOf('chrome') <= -1;
      }
      const redirectUrl =
        response?.data?.loginTrinitySso?.redirectUrl ||
        `${process.env.GATSBY_CE_BACK_OFFICE}`;
      trackEventStaging('Reports Clicked');

      // Send back office URL (ultimate destination) into Segment instead
      // of redirectUrl, since redirectUrl includes the Security Token
      trackEventV2({
        event: segmentEvents.linkClick,
        properties: { name: 'Reports', url: backOfficeUrl },
      });

      if (isMobile || isSafari) window.open(`${redirectUrl}`, '_self');
      else window.open(`${redirectUrl}`, '_blank');
    });
  };

  if (!data?.viewer?.user?.canAccessTrinityBackOffice) {
    return null;
  }

  return (
    <CustomListItem
      button
      selected={false}
      theme={theme}
      onClick={handleOpenBackOffice}
    >
      <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
        <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
          <Icons.CEDashReports pathProps={{ stroke: '#fff', opacity: '0.8' }} />
        </ListItemIcon>
        <Text className="text">Reports</Text>
      </ListItemIcon>
    </CustomListItem>
  );
};
