import gql from 'graphql-tag';

const ContactsV2BulkArchiveMutation = gql`
  mutation BulkArchiveContactsV2($contacts: [String!]) {
    contactsV2BulkArchive(input: { contacts: $contacts }) {
      response
    }
  }
`;

export default ContactsV2BulkArchiveMutation;
