import { animate, motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { makeStyles, ThemeType } from '@onehope/design-system-v2';

const useStyles = makeStyles((theme: ThemeType) => ({
  textContainer: {
    fontSize: '12px',
    marginLeft: '3px',
  },
}));

// takes a number and adds commas to it ex. formatNumberWithCommas(234234.555) => 234,234.555
const formatNumberWithCommas = (number?: number | null) => {
  if (!number) return '0';
  return number.toLocaleString('en-US');
};

function Counter({ from, to }: { from: number; to: number }) {
  const classes = useStyles();
  const countRef = useRef();

  useEffect(() => {
    const count = countRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        count.textContent = formatNumberWithCommas(Math.floor(value));
      },
    });

    return () => {
      controls.stop();
    };
  }, [from, to]);

  return <motion.div className={classes.textContainer} ref={countRef} />;
}

export default Counter;
