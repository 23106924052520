export enum EventStatusDictionary {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
}

export enum CharityDictionary {
  DEFAULT_CHARITY = '3337a053-c335-4323-b94f-410dee173e30',
}

export enum CharityTypeDictionary {
  OHW_PARTNER = 'OHW-PARTNER',
  CUSTOM = 'CUSTOM',
  SKIP = 'SKIP',
}

export enum CharityNameDictionary {
  ONEHOPE_FOUNDATION = 'onehope foundation',
}

export enum DealerIdDictionary {
  LEAD_GEN_MENTOR_ID = '813586',
}
