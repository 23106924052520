import algoliasearch from 'algoliasearch/lite';

// this file attempts to support being built into both Gatsby and NextJS apps

export const indexName =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX ||
  process.env.GATSBY_ALGOLIA_INDEX ||
  'ENV_VAR_NOT_FOUND';

// fire up Algolia search config
export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ||
    process.env.GATSBY_ALGOLIA_APP_ID ||
    'ENV_VAR_NOT_FOUND',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ||
    process.env.GATSBY_ALGOLIA_API_KEY ||
    'ENV_VAR_NOT_FOUND',
);
