import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/client';
import {
  Text,
  TEXT_DS,
  Spacer,
  Grid,
  ButtonV2,
  Icons,
} from '@onehope/design-system-v2';

import { useCommonStyles } from '../../_shared/styles';
import {
  useFormStyles,
  ContainerGrid,
  ButtonsContainer,
} from '../../_shared/formStyles';

import AUTOSHIP_UPDATE_MUTATION from '../../../mutations/AutoshipUpdateMutation';
import CE_SUBSCRIPTION_UPDATE_MUTATION from '../../../mutations/CeSubscriptionUpdateMutation';

import { Account_GetAutoShip as SubscriptionType } from '../../../queries/fragments/generatedTypes/Account_GetAutoShip';
import { Account_CESubscriptionInformationUpdate_CESubscriptionInformationUpdate_user_subscriptions_CE as CeSubscriptionType } from '../../../mutations/generatedTypes/Account_CESubscriptionInformationUpdate';
import { Account_PaymentsGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment as PaymentStripeType } from '../../../queries/generatedTypes/Account_PaymentsGetQuery';

export interface PaymentType {
  node: {
    id: string;
    stripePayment: PaymentStripeType;
  };
}

interface PaymentSelectViewProps {
  payments: Array<PaymentType>;
  selectedPayment: PaymentStripeType | undefined;
  setView: (input: string) => void;
  currentPaymentId: string | null;
  closeDialog: () => void;
  subscription: SubscriptionType | CeSubscriptionType;
  setLoadingTrue: () => void;
  isEditingCeSubscription?: boolean;
  setLoadingFalse: () => void;
  setSelectedPayment: Dispatch<SetStateAction<PaymentStripeType | null>>;
  setEditPaymentId: (input: string | null) => void;
  sendToTrackEventV2?: any;
}

export function findSelectedPayment(
  currentPaymentId: string | null,
  payments: Array<PaymentType>,
) {
  return payments?.find(
    (payment: PaymentType) =>
      payment?.node?.stripePayment?.paymentId === currentPaymentId,
  );
}

const PaymentsSelectView = (props: PaymentSelectViewProps) => {
  const {
    setView,
    currentPaymentId,
    payments,
    closeDialog,
    subscription,
    setLoadingTrue,
    setLoadingFalse,
    setEditPaymentId,
    selectedPayment,
    setSelectedPayment,
    isEditingCeSubscription,
    sendToTrackEventV2,
  } = props;

  const classes = useFormStyles();
  const commonClasses = useCommonStyles();

  const [editAutoShipMutation, { loading: editingAutoship }] = useMutation(
    AUTOSHIP_UPDATE_MUTATION,
  );
  const [editCeSubscriptionMutation] = useMutation(
    CE_SUBSCRIPTION_UPDATE_MUTATION,
  );

  const handleAddPayment = () => {
    setView('ADD');
  };

  const handleEditAutoship = () => {
    if (!selectedPayment) {
      return;
    }

    setLoadingTrue();
    const { autoShipId, accountId } = subscription as SubscriptionType;
    const { paymentId } = selectedPayment;
    const variables = {
      autoShipId,
      input: {
        autoShip: {
          autoShipId,
          accountId,
          paymentMethod: paymentId,
        },
      },
    };
    editAutoShipMutation({ variables })
      .then(() => {
        // send to segment
        sendToTrackEventV2 &&
          sendToTrackEventV2({
            previousPayment: findSelectedPayment(currentPaymentId, payments)
              ?.node?.stripePayment,
            newPayment: selectedPayment,
          });
        closeDialog();
        setLoadingFalse();
      })
      .catch((error) => {
        console.error({ error });
        // setLoadingFalse();
      });
  };

  const handleUpdateCeSubscription = () => {
    if (!selectedPayment) {
      return;
    }

    setLoadingTrue();
    const { subscriptionId } = subscription as CeSubscriptionType;
    const { brand, expMonth, expYear, last4, name, nickname, paymentId } =
      selectedPayment;
    const variables = {
      input: {
        subscriptionId,
        paymentInfo: {
          brand,
          expMonth,
          expYear,
          last4,
          name,
          nickname,
          paymentId,
        },
      },
    };
    editCeSubscriptionMutation({ variables })
      .then(() => {
        closeDialog();
        setLoadingFalse();
      })
      .catch((error) => {
        console.error({ error });
        // setLoadingFalse();
      });
  };

  return (
    <ContainerGrid container direction="column" alignItems="flex-start">
      <Text
        variant="custom"
        default={TEXT_DS.TITLE_LIGHT_24}
        tablet={TEXT_DS.TITLE_LIGHT_32}
        laptop={TEXT_DS.TITLE_LIGHT_32}
        desktop={TEXT_DS.TITLE_LIGHT_32}
      >
        Payment Options
      </Text>
      <Spacer xs="24px" sm="32px" lg="32px" />
      <Grid container spacing={0}>
        {!payments || payments.length === 0 ? (
          <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
            No saved payment methods
          </Text>
        ) : null}
        {payments?.map((p: PaymentType, index: number) => {
          const payment = p?.node?.stripePayment;
          const { last4, expYear, expMonth, brand, name, paymentId } = payment;
          const { defaultContainer, unSelectedContainer } = commonClasses;
          const isSelectedPayment = selectedPayment?.paymentId === paymentId;
          const addressContainerClass = isSelectedPayment
            ? defaultContainer
            : unSelectedContainer;
          const handleEditPayment = () => {
            setView('EDIT');
            setEditPaymentId(paymentId);
          };

          const handleSetSelectedPayment = () => {
            if (!isSelectedPayment) {
              setSelectedPayment(payment);
            }
          };
          return (
            <Grid
              container
              key={`${p?.node?.stripePayment?.paymentId}${index}`}
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="flex-start"
              onClick={handleSetSelectedPayment}
              className={addressContainerClass}
            >
              <Grid container direction="column" spacing={0}>
                <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                  {`${name}`}
                </Text>
                {brand && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {`${brand} ending in ${last4}`}
                  </Text>
                )}
                {expYear && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {`Expires ${expMonth}/${expYear}`}
                  </Text>
                )}
                <Spacer xs="16px" sm="16px" lg="16px" />
                <Grid container>
                  <ButtonV2
                    type="textLink"
                    className={commonClasses.button}
                    onClick={handleEditPayment}
                  >
                    Edit
                  </ButtonV2>
                </Grid>
              </Grid>
              <div
                className={
                  isSelectedPayment
                    ? commonClasses.defaultTag
                    : commonClasses.unSelectedTag
                }
              >
                {isSelectedPayment ? 'Selected' : 'Select'}
              </div>
            </Grid>
          );
        })}
      </Grid>
      <ButtonV2 fullWidth type="secondary" onClick={handleAddPayment}>
        <Grid container justifyContent="space-between" alignItems="center">
          <div className={commonClasses.buttonSpacer} />
          <div>Add New Payment</div>
          <Icons.Plus />
        </Grid>
      </ButtonV2>
      <Spacer xs="24px" sm="32px" lg="32px" />
      <ButtonsContainer container>
        <ButtonV2
          type="primary"
          disabled={
            editingAutoship ||
            selectedPayment?.paymentId === currentPaymentId ||
            !selectedPayment
          }
          loading={editingAutoship}
          fullWidth
          onClick={
            isEditingCeSubscription
              ? handleUpdateCeSubscription
              : handleEditAutoship
          }
        >
          Save
        </ButtonV2>
        <ButtonV2
          className={classes.secondaryButton}
          type="secondary"
          fullWidth
          onClick={closeDialog}
        >
          Cancel
        </ButtonV2>
      </ButtonsContainer>
    </ContainerGrid>
  );
};

export default PaymentsSelectView;
