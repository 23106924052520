import { gql } from '@apollo/client';

const DynamicDataIntrospectionQuery = gql`
  query {
    __type(name: "ActionEngineData") {
      name
      description
      fields {
        name
        description
        type {
          name
          description
          fields {
            name
            description
            type {
              name
              description
              fields {
                name
                description
                type {
                  name
                  description
                  fields {
                    name
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DynamicDataIntrospectionQuery;
