import * as React from 'react';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import MinusIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { neutral5, neutral13, textColor } from '../styles/cssConstants';


// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const CircleButton = styled(IconButton)`
  && {
    &.MuiIconButton-colorSecondary  {
      color: ${textColor};
      background-color: ${neutral5};
    }
    &.MuiIconButton-colorSecondary:hover {
      color: ${textColor};
      background-color: ${neutral13};
    }
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const MinusButton = styled(MinusIcon) <{ size:  number }>`
 && {
  height: ${({ size }: { size: number }) => `${size}px`};
 }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const AddButton = styled(AddIcon)<{ size:  number }>`
 && {
  height: ${({ size }: { size: number }) => `${size}px`};
 }
`;

type TickerButtonProps = {
  onClick: () => void;
  size: number;
  ariaLabel: string;
  type: string;
};

export default function TickerButton({
  onClick,
  type,
  ariaLabel,
  size,
}: TickerButtonProps) {
  return (
    <CircleButton onClick={onClick} color="secondary" aria-label={ariaLabel}>
      {type === 'minus' ? <MinusButton size={size} /> : <AddButton size={size} />}
    </CircleButton>
  );
}
