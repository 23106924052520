import React from 'react';

import {
  Dialog,
  GridV2 as Grid,
  Icons,
  makeStyles,
  useMediaQuery,
  useTheme,
  Text,
  ThemeType,
  TEXT_DS,
} from '@onehope/design-system-v2';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  config: {
    headerText?: string | null;
    bodyText?: string;
    listItems?: string[];
    secondaryBodyText?: string;
    htmlBody?: string;
    notificationText?: string;
    footerNotificationText?: string;
  };
}

const useStyles = makeStyles((theme: ThemeType) => ({
  main: {
    padding: '24px',
    width: '100%',
    [theme.breakpoints.up('sm')]: { padding: '48px' },
  },
  close: {
    marginTop: -16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {},
  headerText: {
    paddingBottom: 16,
  },
  bodyText: {
    paddingBottom: 16,
  },
  notificationText: {
    paddingBottom: 16,
    color: theme.palette.mud.main,
  },
  listItems: {
    marginTop: 0,
    listStyle: 'none',
    margin: 0,
    padding: '0 0 16px 8px',
  },
  listText: {
    paddingLeft: 8,
  },
}));

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 4,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '460px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
    },
  },
}));

const parseHtml = (html: string) => {
  return html?.replace(/\\"/g, '"') || html;
};

export default function DialogSelector(props: DialogProps) {
  const { open, onClose } = props;
  let { config } = props;
  const classes = useStyles();
  const dialogClasses = useDialogStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (!config) return null;

  const headerText = config?.headerText;
  const bodyText = config?.bodyText;
  const secondaryBodyText = config?.secondaryBodyText;
  const htmlBody = config?.htmlBody;
  const notificationText = config?.notificationText;
  const listItems = config?.listItems;
  const footerNotificationText = config?.footerNotificationText;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={dialogClasses}
      fullScreen={isMobile}
    >
      <Grid container className={classes.main}>
        {headerText && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <Text
                variant="custom"
                default={TEXT_DS.TITLE_LIGHT_32}
                className={classes.headerText}
              >
                {headerText}
              </Text>
            </Grid>
            <Grid item>
              <Icons.Close className={classes.close} onClick={onClose} />
            </Grid>
          </Grid>
        )}
        <Grid container direction="column">
          {notificationText && (
            <Text
              variant="custom"
              default={TEXT_DS.BODY_SEMIBOLD_ITALIC_18}
              className={classes.notificationText}
            >
              {notificationText}
            </Text>
          )}
          {bodyText && (
            <Text
              variant="custom"
              default={TEXT_DS.BODY_LIGHT_18}
              className={classes.bodyText}
            >
              {bodyText}
            </Text>
          )}
          {secondaryBodyText && (
            <Text
              variant="custom"
              default={TEXT_DS.BODY_LIGHT_18}
              className={classes.bodyText}
            >
              {secondaryBodyText}
            </Text>
          )}
          {htmlBody && (
            <Text
              variant="custom"
              default={TEXT_DS.BODY_LIGHT_18}
              className={classes.bodyText}
              dangerouslySetInnerHTML={{ __html: parseHtml(htmlBody) }}
            />
          )}
          {listItems && (
            <ul className={classes.listItems}>
              {listItems?.map((text: string, index: number) => (
                <li key={`${text}${index}`}>
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_18}>
                    &#8226; <span className={classes.listText}>{text}</span>
                  </Text>
                </li>
              ))}
            </ul>
          )}
          {footerNotificationText && (
            <Text variant="custom" default={TEXT_DS.BODY_LIGHT_ITALIC_16}>
              {footerNotificationText}
            </Text>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
