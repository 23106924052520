import { gql } from '@apollo/client';

export default gql`
  query CEDash_StatisticsQuery {
    viewer {
      id
      user {
        id
        kpi {
          currentPeriodPCV
          currentPeriodOV
        }
      }
    }
  }
`;
