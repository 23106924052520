import { gql } from '@apollo/client';
import { CEDash_UserFragment } from '../fragments/UserCOCFragment';
import { CEDash_CauseOfChoiceNonProfit } from '../fragments/NonProfitV2Fragment';

export const CEDash_UserCauseOfChoice = gql`
  fragment CEDash_UserCauseOfChoice on User {
    ...CEDash_UserFragment
    shoppingWithCeoUser {
      ...CEDash_UserFragment
    }
    conciergeUser {
      ...CEDash_UserFragment
      shoppingWithCeoUser {
        ...CEDash_UserFragment
      }
    }
  }
  ${CEDash_UserFragment}
`;

const USER_GET_CAUSE_OF_CHOICE = gql`
  query CEDash_UserCauseOfChoiceGetQuery {
    viewer {
      id
      user {
        ...CEDash_UserCauseOfChoice
        cartV2 {
          eventId
          eventHostFirstName
          event {
            id
            eventId
            title
            hostFirstName
            hostFullName
            trinityPartyId
            trinityPartyType
            images {
              id
              groupName
              fileName
            }
            donationInfo {
              id
              nonProfit {
                id
                nonProfitName
              }
              nonProfitV2 {
                ...CEDash_CauseOfChoiceNonProfit
              }
            }
          }
          guestNonProfit {
            ...CEDash_CauseOfChoiceNonProfit
          }
        }
      }
    }
  }
  ${CEDash_CauseOfChoiceNonProfit}
  ${CEDash_UserCauseOfChoice}
`;

export default USER_GET_CAUSE_OF_CHOICE;
