import React, { useMemo } from 'react';
import {
  GridV2 as Grid,
  styled,
  Box,
  Text,
  useTheme,
  theme,
  Icons,
  useMediaQuery,
  TEXT_DS,
} from '@onehope/design-system-v2';
import { DateTime, Interval } from 'luxon';

// utils
import { CEDash_FastStartQuery_viewer_v3_fastStart23 as FastStartData } from '../../../queries/generatedTypes/CEDash_FastStartQuery';
// @ts-ignore
const getDaysLeft = (start: string, end: string) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const diff = Interval.fromDateTimes(startDate, endDate);
  const daysLeft = Math.floor(diff.length('days'));
  const hoursLeft = Math.floor(diff.length('hours'));
  const minutesLeft = Math.floor(diff.length('minutes')) - hoursLeft * 60;

  // expired case return expired text
  if (!daysLeft && !hoursLeft && !minutesLeft) {
    return null;
  }

  if (daysLeft >= 1) {
    return daysLeft == 1
      ? `${daysLeft} day remaining`
      : `${daysLeft} days remaining`;
  } else {
    const hoursLeftText =
      hoursLeft == 1 ? `${hoursLeft} hour, ` : `${hoursLeft} hours, `;

    const minutesLeftText =
      minutesLeft == 1
        ? `${minutesLeft} minute remaining`
        : `${minutesLeft} minutes remaining`;

    return `${hoursLeft < 1 ? '' : hoursLeftText}${minutesLeftText}`;
  }
};
interface Props {
  fastStartData: FastStartData;
}

const DateText = styled(Text)({
  paddingBottom: 2,
  color: theme.palette.forestGreen.main,
});

const DaysRemaining = styled(Text)(({ theme }) => ({
  color: theme.palette.greyDark.main,
  paddingLeft: 8,
}));

export default function Header({ fastStartData }: Props) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const currentDate = DateTime.local();
  const formattedStartDate = DateTime.fromISO(
    fastStartData?.fastStartBegin,
  ).toLocaleString(DateTime.DATE_FULL);
  const formattedCurrentDate = DateTime.fromISO(
    fastStartData?.fastStartEnd,
  ).toLocaleString(DateTime.DATE_FULL);
  const daysLeft = getDaysLeft(
    currentDate.toISO(),
    fastStartData?.fastStartEnd,
  );

  const endDate = fastStartData?.fastStartEnd
    ? DateTime.fromISO(fastStartData.fastStartEnd)
    : null;

  const inactiveDiff = endDate
    ? Interval.fromDateTimes(endDate, DateTime.now())
    : null;

  const daysExpired =
    inactiveDiff !== null ? inactiveDiff.length('days') : null;

  const fastStartEndDateFormatted = fastStartData?.fastStartEnd
    ? DateTime.fromISO(fastStartData?.fastStartEnd).toFormat('MM/dd/yyyy')
    : null;

  const daysExpiredText = useMemo(() => {
    if (daysExpired === null || daysExpired > 7) {
      return '';
    }

    if (7 - daysExpired <= 1) {
      return 'Your bonus program stats will be removed today.';
    } else {
      return `Your bonus program stats will be removed in ${Math.round(
        7 - daysExpired,
      )} days.`;
    }
  }, [daysExpired]);

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        direction={isTablet ? 'row' : 'column'}
        rowSpacing={2}
        sx={{ pb: { xs: 2, md: 3 } }}
      >
        <Grid item xs>
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
            Fast Start Bonus Program
          </Text>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems={isTablet ? 'flex-end' : 'flex-start'}
          >
            <DateText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
              {formattedStartDate} - {formattedCurrentDate}
            </DateText>
            {daysLeft && (
              <Grid
                container
                alignItems="center"
                justifyContent={isTablet ? 'flex-end' : 'flex-start'}
              >
                <Icons.FastStartClock />
                <DaysRemaining
                  variant="custom"
                  default={TEXT_DS.BODY_SEMIBOLD_ITALIC_16}
                  theme={theme}
                >
                  {daysLeft}
                </DaysRemaining>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {daysExpired !== null && daysExpired > 0 && daysExpired <= 7 && (
        <Box sx={{ pb: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              background: '#EDE0BF',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
              gap: '10px',
            }}
          >
            <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
              Your Fast Start ended
              {fastStartEndDateFormatted !== null
                ? ` on ${fastStartEndDateFormatted}`
                : ''}
              . {daysExpiredText}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}
