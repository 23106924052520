import { gql } from '@apollo/client';
import { UserCauseOfChoice } from '../queries/UserGetQuery';

const mutation = gql`
  mutation CoC_UpdateSelectedCauseMutation($input: UpdateSelectedCauseInput!) {
    causeUpdateSelected(input: $input) {
      user {
        ...UserCauseOfChoice
      }
    }
  }
  ${UserCauseOfChoice}
`;

export default mutation;
