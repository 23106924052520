import React, { useState, useEffect } from 'react';
import { useLazyQuery, QueryHookOptions } from '@apollo/client';
import { Waypoint } from 'react-waypoint';

import { Grid, ChunkyButton, LoaderDark } from '@onehope/design-system-v2';
import { formatNonProfitAddress } from '@onehope/utils';

/* QUERIES */
import NONPROFIT_EDITS_QUERY from '../../../queries/NonProfitEditsQuery';

/* TYPES */
import { CauseOfChoiceNonProfitEditsQuery_viewer_v2_nonProfitEdits_edges_node as NonProfitEditType } from '../../../queries/generatedTypes/CauseOfChoiceNonProfitEditsQuery';

/* UTILS */
import { formatNonProfitEdits } from '../../../utils/helper';
import { useCommonStyles } from '../../../utils/commonStyles';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

const LIMIT = 10;

export default function NonProfitEditSuggestions({
  nonProfitId,
}: {
  nonProfitId: string | null | undefined;
}) {
  const classes = useCommonStyles();
  const { handleCloseModal, handleNonProfitSelected } =
    useCauseOfChoiceNetworkLayerValue();

  const [offset, setOffset] = useState(0);
  const [nonProfitEdits, setNonProfitEdits] = useState<
    Array<NonProfitEditType>
  >([]);
  const [dataLoading, setDataLoading] = useState(true);

  const queryOptions = {
    variables: { nonProfitId, limit: LIMIT, offset },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const newEdits = formatNonProfitEdits(res);
      const mergedEdits = [...nonProfitEdits, ...newEdits];
      setNonProfitEdits(mergedEdits);
      setDataLoading(false);
    },
  } as QueryHookOptions;

  const [loadMoreEdits, { called }] = useLazyQuery(
    NONPROFIT_EDITS_QUERY,
    queryOptions,
  );

  useEffect(() => {
    if (nonProfitId) {
      loadMoreEdits();
    }
  }, [nonProfitId]);

  const onLoadMore = () => {
    setOffset(offset + LIMIT);
    loadMoreEdits();
  };

  const loading = dataLoading || !called || !nonProfitId;

  if (loading) {
    return <LoaderDark />;
  }

  if (!nonProfitEdits || !nonProfitEdits.length) {
    return null;
  }

  return (
    <div>
      <Grid container className={classes.suggestionsWrapper}>
        {nonProfitEdits.map((edit: NonProfitEditType) => {
          // format NPO address
          const address = edit?.nonProfitAddress
            ? edit.nonProfitAddress
            : edit?.nonProfit?.nonProfitAddress;
          const nonProfitAddress = address
            ? formatNonProfitAddress(address)
            : '';

          const handleSelectNonProfitEdit = () => {
            if (handleNonProfitSelected) {
              if (edit?.nonProfit?.nonProfitId && edit.nonProfitEditId) {
                handleNonProfitSelected({
                  nonProfitId: edit.nonProfit.nonProfitId,
                  nonProfitEditId: edit.nonProfitEditId,
                });
              }
              return handleCloseModal();
            }
          };

          return (
            <ChunkyButton
              fullWidth
              hideImage
              primaryText={`${edit?.nonProfitName}`}
              secondaryText={nonProfitAddress}
              tertiaryText={`EIN: ${edit?.nonProfit?.nonProfitTaxId}`}
              onChange={handleSelectNonProfitEdit}
              onChangeText="Select"
              className={classes.suggestion}
            />
          );
        })}
      </Grid>
      <Waypoint topOffset={'20%'} onEnter={onLoadMore} />
    </div>
  );
}
