import React from 'react';
import {
  ButtonBase,
  Grid,
  Icons,
  Text,
  LoaderDark,
} from '@onehope/design-system-v2';

/* COMPONENTS */
import FRSearchWrapper from './FRSearchWrapper';

/* HELPERS */
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import {
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

import { useCommonStyles } from '../../../utils/commonStyles';

export default function FundraiserSearch() {
  const commonStyles = useCommonStyles();
  const [{ loading }, dispatch] = useCauseOfChoiceContextValue();
  const {
    initialScreen,
    handleCloseModal,
  } = useCauseOfChoiceNetworkLayerValue();
  const hideBackButton =
    initialScreen === CauseOfChoiceScreenDictionary.FUNDRAISER_SEARCH_SCREEN;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.HOME_SCREEN,
    });
  };

  return (
    <Grid container>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={commonStyles.loader}
        >
          <LoaderDark size={36} />
        </Grid>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent={hideBackButton ? 'flex-end' : 'space-between'}
          >
            {!hideBackButton && (
              <ButtonBase onClick={handleGoBack}>
                <Grid xs item container alignItems="center" direction="row">
                  <Icons.SmallLeftArrow />
                  <Text className={commonStyles.backButtonText}>Back</Text>
                </Grid>
              </ButtonBase>
            )}
            <ButtonBase onClick={handleCloseModal}>
              <Icons.Close />
            </ButtonBase>
          </Grid>
          <FRSearchWrapper />
        </>
      )}
    </Grid>
  );
}
