import React from 'react';
import {
  ButtonBase,
  GridV2 as Grid,
  makeStyles,
  ThemeType,
  Text,
  TEXT_DS,
} from '@onehope/design-system-v2';

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    paddingBottom: 16,
  },
  edit: {
    color: theme.palette.forestGreen.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface ImpactHeaderProps {
  open: boolean;
  handleClick: (event: React.MouseEvent) => void;
  cause: string | null;
}

export default function ImpactHeader({
  handleClick,
  cause,
}: ImpactHeaderProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
    >
      <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
        Impact
      </Text>
      {cause && (
        <ButtonBase onClick={handleClick}>
          <Text
            variant="custom"
            default={TEXT_DS.BODY_SEMIBOLD_16}
            className={classes.edit}
          >
            Edit
          </Text>
        </ButtonBase>
      )}
    </Grid>
  );
}
