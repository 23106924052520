import React from 'react';
import { useContactsContext } from '../../ContactsContext';
import { Text, TEXT_DS, styled, theme } from '@onehope/design-system-v2';

export const Container = styled('div')`
  display: flex;
`;

export const EditButton = styled(Text)`
  max-width: 218px;
  margin-right: 20px;
  height: 18px;
  color: ${theme.palette.forestGreen.main};
  :hover {
    cursor: pointer;
  }
`;

const CheckboxToggle = () => {
  const { isEditing, toggleIsEditing, clearAllSelected } = useContactsContext();

  return (
    <Container>
      {isEditing ? (
        <EditButton
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
          onClick={clearAllSelected}
        >
          Cancel
        </EditButton>
      ) : (
        <EditButton
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_14}
          onClick={toggleIsEditing}
        >
          Edit
        </EditButton>
      )}
    </Container>
  );
};

export default CheckboxToggle;
