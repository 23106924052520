import * as React from 'react';
import { PageRendererProps } from 'gatsby';
import styled from '@emotion/styled';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Layout from '../layout';
import EventsV2 from '../components/EventsV2';

const Container = styled.div`
  background-color: white;
  height: 100%;
`;

function EventsPage(props: PageRendererProps) {
  return (
    <Layout>
      <Container>
        <EventsV2 />
      </Container>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(EventsPage);
