import { useState, useCallback } from 'react';
import { useWhenMounted } from 'react-use-when-mounted';

export default function useToggle(initial = false) {
  const whenMounted = useWhenMounted();
  const [value, setValue] = useState(initial);
  const setTrue = useCallback(() => whenMounted(() => setValue(true)), [
    setValue,
    whenMounted,
  ]);
  const setFalse = useCallback(() => whenMounted(() => setValue(false)), [
    setValue,
    whenMounted,
  ]);
  const toggle = useCallback(() => whenMounted(() => setValue(p => !p)), [
    setValue,
    whenMounted,
  ]);
  const reset = useCallback(() => whenMounted(() => setValue(initial)), [
    setValue,
    whenMounted,
  ]);

  return {
    value,
    set: setValue,
    toggle,
    setTrue,
    setFalse,
    reset,
  };
}
