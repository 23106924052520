import React, { memo, useCallback, useState } from 'react';
import { styled, useTheme } from '@onehope/design-system-v2';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Lazy, Autoplay, Swiper as SwiperType } from 'swiper';
import 'swiper/swiper-bundle.min.css';

// utils
import {
  CEDash_FastStartQuery_viewer_v3_fastStart23 as FastStartData,
  CEDash_FastStartQuery_viewer_v3_fastStart23_rings as Ring,
} from '../../../queries/generatedTypes/CEDash_FastStartQuery';

import ChartColumn from './ChartColumn';

interface Props {
  fastStartData: FastStartData;
}

const SwiperCarousel = styled(Swiper)(({ theme }) => ({
  marginBottom: 16,
  paddingBottom: 24,
  position: 'unset',
  '--swiper-pagination-color': theme.palette.primary.main,
  '--swiper-pagination-bullet-inactive-color': 'white',
  '--swiper-pagination-bullet-width': '8px',
  '--swiper-pagination-bullet-height': '8px',
  '--swiper-pagination-bullet-horizontal-gap': '4px',
  '--swiper-pagination-bullet-inactive-opacity': '1',
  [theme.breakpoints.up('md')]: {
    marginBottom: 24,
    paddingBottom: 24,
  },

  '.swiper-pagination-bullet': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '.swiper-pagination-bullets.swiper-pagination-horizontal': {
    height: 40,
    bottom: 40,
    ['@media (min-width: 709px)']: {
      bottom: 24,
    },
    [theme.breakpoints.up('md')]: {
      bottom: 40,
    },
    ['@media (min-width: 1557px)']: {
      bottom: 24,
    },
  },
  '.swiper-pagination': {
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}));

function SwiperComponent({ fastStartData }: Props) {
  const theme = useTheme();
  const rings = fastStartData?.rings;
  const [autoplayEnded, setAutoplayEnded] = useState(false);

  const firstIncompleteCapstoneRing = rings.findIndex(
    ring => ring.isCapstoneRing && !ring.completed,
  );

  const slideToStopAutoplay =
    firstIncompleteCapstoneRing === -1 ? 1 : firstIncompleteCapstoneRing + 1;

  const onSlideChange = useCallback(
    (swiper: SwiperType) => {
      if (!autoplayEnded && swiper.activeIndex === slideToStopAutoplay) {
        setAutoplayEnded(true);
        swiper.autoplay.stop();
      }
    },
    [autoplayEnded, slideToStopAutoplay],
  );

  return (
    <SwiperCarousel
      onSlideChange={onSlideChange}
      onInit={onSlideChange}
      theme={theme}
      spaceBetween={0}
      slidesPerView={5}
      slidesPerGroup={5}
      speed={500}
      loop={true}
      lazy={true}
      touchRatio={1.5}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      modules={[Pagination, Lazy, Autoplay]}
      className="mySwiper"
    >
      {rings?.map((ring: Ring, index) => {
        return (
          <SwiperSlide key={`${ring.bonusAmount}-${index}`}>
            <ChartColumn ring={ring} />
          </SwiperSlide>
        );
      })}
    </SwiperCarousel>
  );
}

export default memo(SwiperComponent);
