import React, { useState } from 'react';
import { Grid, Button, TEXT_DS } from '@onehope/design-system-v2';
import { Currency } from '@onehope/design-system';
import { navigate } from 'gatsby';
import Decimal from 'decimal.js';
import get from 'lodash/get';
import {
  DetailsTitle,
  StraightLine,
  TabContentContainer,
  EventOrdersWrapper,
} from '../index.styles';
import SummaryLine from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine';
import { formatDisplayDate } from '../../../Events/EventDetailsForm/EventSummary';
import OrderAddDialog from '../../../Events/AddOrderDialog';
import { EventStatusDictionary } from '../../../../utils/enums';
import {
  eventIsCancelled,
  isSatelliteEvent,
} from '../../../Events/EventsTable/helpers';
import { EventManagementTabs } from '../../TabNavigation/tabConstants';

function EventOrders(props) {
  const { event } = props;
  const { ordersCount } = event;
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const satelliteEvent = isSatelliteEvent(event);
  const closedEvent =
    event?.status === EventStatusDictionary.CLOSED ||
    event?.trinityPartyStatus === EventStatusDictionary.CLOSED;
  const cancelledEvent = eventIsCancelled(event);
  const donation = get(event, 'donationInfo');
  const timeZone = event?.timeZone ? event.timeZone : 'America/Chicago'; // Fall back for trinity events
  const eventDate = formatDisplayDate(event.eventDate, timeZone);
  const closeDate = formatDisplayDate(event.closeDate, timeZone);
  const manualCloseDate = formatDisplayDate(event.manualCloseDate, timeZone);
  const { hostKitDonation, donationAmount, ordersSubTotal } = donation || {};

  // Donation sales subtotal is the amount from orders that contributed directly to the donation.
  const donationFromOrders = new Decimal(donationAmount || 0).minus(
    hostKitDonation || 0,
  );

  // The donation is always 10 percent more than the order donation amount.
  const donationSalesSubTotal = donationFromOrders.times(10).toNumber();

  const handleRouteChange = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    return navigate(
      `/event?eventId=${event?.eventId}&tab=${EventManagementTabs.GUEST_ORDERS}`,
    );
  };

  return (
    <TabContentContainer>
      <OrderAddDialog
        selectCustomer
        isOpen={isOrderDialogOpen}
        selectedEventId={event?.eventId}
        handleClose={setIsOrderDialogOpen}
      />
      <DetailsTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
        Event orders
      </DetailsTitle>
      <StraightLine />
      <EventOrdersWrapper>
        {!satelliteEvent && (
          <div>
            {closeDate && (
              <SummaryLine
                width={140}
                title="Order Period"
                value={`${eventDate} to ${manualCloseDate || closeDate}`}
              />
            )}
          </div>
        )}
        <SummaryLine
          width={140}
          title="Sales total"
          value={<Currency rawValue={ordersSubTotal || 0} />}
        />
        <SummaryLine
          width={140}
          title="Qualified sales total"
          value={<Currency rawValue={donationSalesSubTotal || 0} />}
        />
        {!satelliteEvent && !!hostKitDonation && (
          <SummaryLine
            width={140}
            title="Host Kit donation"
            value={<Currency rawValue={hostKitDonation} />}
          />
        )}
        <SummaryLine
          width={140}
          title="Donation total"
          value={<Currency rawValue={donationAmount || 0} />}
        />
        <SummaryLine
          width={140}
          title="Number of orders"
          value={ordersCount || '0'}
        />
      </EventOrdersWrapper>
      <Grid container spacing={1}>
        {!closedEvent && !cancelledEvent && (
          <Grid item xs={12}>
            <Button fullWidth onClick={() => setIsOrderDialogOpen(true)}>
              + New Order
            </Button>
          </Grid>
        )}
        {ordersCount > 0 && (
          <Grid item xs={12}>
            <Button
              type={closedEvent ? 'primary' : 'secondary'}
              fullWidth
              onClick={handleRouteChange}
            >
              View Orders
            </Button>
          </Grid>
        )}
      </Grid>
    </TabContentContainer>
  );
}

export default EventOrders;
