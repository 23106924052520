import React from 'react';

import {
  ButtonBase,
  Text,
  Grid,
  Icons,
  LoaderDark,
} from '@onehope/design-system-v2';

/* COMPONENTS */
import SearchWrapper from './SearchWrapper';

/* HELPERS */
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
import {
  TypeKeys,
  CauseOfChoiceScreenDictionary,
} from '../../../stateManagement/types';

import { useCommonStyles } from '../../../utils/commonStyles';

export default function NonProfitSearch() {
  const commonStyles = useCommonStyles();
  const [{ loading }, dispatch] = useCauseOfChoiceContextValue();
  const {
    initialScreen,
    handleCloseModal,
  } = useCauseOfChoiceNetworkLayerValue();
  const header = 'Who are you fundraising for?';

  const hideBackButton =
    initialScreen === CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN;
  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.HOME_SCREEN,
    });
  };

  return (
    <Grid container>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={commonStyles.loader}
        >
          <LoaderDark size={36} />
        </Grid>
      ) : (
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent={'space-between'}>
            {!hideBackButton && (
              <ButtonBase onClick={handleGoBack}>
                <Grid xs item container alignItems="center" direction="row">
                  <Icons.SmallLeftArrow />
                  <Text className={commonStyles.backButtonText}>Back</Text>
                </Grid>
              </ButtonBase>
            )}
            {hideBackButton && (
              <Text variant="h1" className={commonStyles.headerText}>
                {header}
              </Text>
            )}
            <ButtonBase onClick={handleCloseModal}>
              <Icons.Close />
            </ButtonBase>
          </Grid>
          <SearchWrapper />
        </Grid>
      )}
    </Grid>
  );
}
