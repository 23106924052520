import React from 'react';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';

import Layout from '../../../layout';
import ReachOutWrapper from '../../../components/ReachOuts/ReachOutWrapper';

const Container = styled.div`
  background-color: white;
  min-height: 100%;
`;

function InvitesPage(props: PageRendererProps) {
  return (
    <Layout>
      <Container>
        <ReachOutWrapper page="invites" />
      </Container>
    </Layout>
  );
}

export default InvitesPage;
