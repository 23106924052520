import { gql } from '@apollo/client';

const NEXT_SHIPMENT_ADDONS = gql`
  query WineClub_AutoshipNextShipmentAddons($autoShipId: String!) {
    viewer {
      user {
        autoShip(autoShipId: $autoShipId) {
          nextShipmentAddons {
            id
            finalPrice
            productId
            productName
            quantity
            images {
              id
              squareFront
              productCatalog
              squareBack
            }
          }
        }
      }
    }
  }
`;

export default NEXT_SHIPMENT_ADDONS;
