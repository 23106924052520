/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import styled from '@emotion/styled';

import {
  Icons,
  TableCell as MuiTableCell,
  Text,
  TableHead,
  TableRow,
  theme,
} from '@onehope/design-system-v2';

const { ImportExport } = Icons;
const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TableCell = styled(MuiTableCell)`
  && {
    background-color: ${theme.palette.barelyThereGrey.main};
    border: 0;
  }
`;

const HeaderLabel = styled(Text)`
  font-weight: 420;
  font-size: 14px;
  line-height: 21px;
`;

const SortIconWrapper = styled(ImportExport)`
  margin-left: 10px;
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

type listType = {
  [key: string]: boolean;
};

interface TableHeaderContainerProps {
  handleSort: (sortBy: string) => void;
  columns: any[];
  noSortByList?: listType;
}

export default function TableHeaderContainer(props: TableHeaderContainerProps) {
  const { handleSort, columns, noSortByList } = props;
  const noSortOn = { ...noSortByList } as listType;
  return (
    <TableHead>
      <TableRow>
        {columns?.map(column => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                height: 60,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
              }}
            >
              <TableCellContainer>
                <HeaderLabel>{column.label}</HeaderLabel>
                {!noSortOn[column.id] && (
                  <SortIconWrapper onClick={() => handleSort(column.id)} />
                )}
              </TableCellContainer>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
