import React from 'react';
import { ThemeProvider, theme } from '@onehope/design-system-v2';
import ActionEngineCard from './ActionEngineCard';
import ActivityEntry from '../types/ActivityEntry';
import SendToTrackEventV2 from '../types/SendToTrackEventV2Type';
import OpenVideoDialogFn from '../types/openVideoDialog';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';

interface Props {
  activity: ActivityEntry;
  openVideoDialog?: OpenVideoDialogFn;
  openButtonActionDialog?: OpenButtonActionDialogFn;
  sendToTrackEventV2?: SendToTrackEventV2;
  onCardButtonClicked?: OnCardButtonClickedFn;
  archiveCardMutation?: (ledgerId: string, isArchived: boolean) => Promise<any>;
  shouldStopVideo?: boolean;
}

const Activity = ({
  activity,
  sendToTrackEventV2,
  openVideoDialog,
  openButtonActionDialog,
  onCardButtonClicked,
  archiveCardMutation,
  shouldStopVideo,
}: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ActionEngineCard
        activity={activity}
        openVideoDialog={openVideoDialog}
        openButtonActionDialog={openButtonActionDialog}
        sendToTrackEventV2={sendToTrackEventV2}
        onCardButtonClicked={onCardButtonClicked}
        archiveCardMutation={archiveCardMutation}
        shouldStopVideo={shouldStopVideo}
      />
    </ThemeProvider>
  );
};

export default Activity;
