import * as React from 'react';
import Icon from 'antd/lib/icon';
const EmailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <defs>
      <path
        id="a"
        d="M28 12H12c-1.1 0-1.99.9-1.99 2L10 26c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V14c0-1.1-.9-2-2-2zm0 4l-8 5-8-5v-2l8 5 8-5v2z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a" />
      </mask>
      <use fill="#757575" fill-rule="nonzero" href="#a" />
      <g fill="#FFF" mask="url(#b)">
        <path d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);

export const EmailIcon = (props: any) => (
  <Icon
    style={{ marginTop: '-11px', marginLeft: '-8px', width: '26px' }}
    component={EmailSvg}
    {...props}
  />
);
