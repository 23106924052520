import * as React from 'react';
import styled from '@emotion/styled';

import { styles as s } from '@onehope/design-system';
import {
  Icons,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TEXT_DS,
  theme,
} from '@onehope/design-system-v2';

import { SvgArchive } from '../../../assets/archive';
import { useContactsContext } from '../ContactsContext';

const { cssConstants } = s;
const { ImportExport } = Icons;

interface TableHeaderProps {
  handleSortContacts: (newSortBy: string) => void;
}

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  justify-content: flex-start;
  width: 100%;
`;

const HeaderLabel = styled.div`
  font-family: ${cssConstants.bodyFontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-right: 14px;
`;

const SortIconWrapper = styled(ImportExport)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const ArchiveWarningSpan = styled.span`
  font-family: ${cssConstants.bodyFontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
`;

const ArchiveButton = styled(Text)`
  color: ${theme.palette.forestGreen.main};
  margin-left: 12px;
  :hover {
    cursor: pointer;
  }
`;

const ArchiveContact = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const ArchiveLabel = styled(Text)`
  padding-right: 12px;
`;

const columns = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true,
  },
  {
    id: 'nextShipmentDate',
    numeric: true,
    disablePadding: false,
    label: 'Next Shipment',
    sortable: true,
  },
  {
    id: 'lastOrder',
    numeric: true,
    disablePadding: false,
    label: 'Last Order',
    sortable: true,
  },
  {
    id: 'causeOfChoice',
    numeric: true,
    disablePadding: false,
    label: 'Cause of Choice',
    sortable: true,
  },
  {
    id: 'amountRaised',
    numeric: true,
    disablePadding: false,
    label: '$ Raised',
    sortable: true,
  },
  {
    id: 'pointBalance',
    numeric: true,
    disablePadding: false,
    label: 'Reward Pts',
    sortable: true,
  },
  {
    id: 'lifetimeSpend',
    numeric: true,
    disablePadding: false,
    label: 'Lifetime Spend',
    sortable: true,
  },
];

const TableHeader = ({ handleSortContacts }: TableHeaderProps) => {
  const {
    selectedContacts,
    allSelected,
    isEditing,
    toggleAllSelected,
    toggleModalState,
  } = useContactsContext();
  return (
    <TableHead>
      {selectedContacts.length === 0 ? (
        <TableRow>
          {isEditing ? (
            <TableCell>
              <TableCellContainer>
                <HeaderLabel>
                  <Checkbox checked={allSelected} onClick={toggleAllSelected} />
                </HeaderLabel>
              </TableCellContainer>
            </TableCell>
          ) : null}
          {columns.map(column => (
            <TableCell
              key={column.id}
              align="left"
              padding={column.disablePadding ? 'none' : 'normal'}
              style={{
                height: 60,
              }}
            >
              <TableCellContainer>
                <HeaderLabel>{column.label}</HeaderLabel>
                {column.sortable && (
                  <SortIconWrapper
                    onClick={() => handleSortContacts(column.id)}
                  />
                )}
              </TableCellContainer>
            </TableCell>
          ))}
          <TableCell />
          <TableCell />
        </TableRow>
      ) : (
        <TableCell
          colSpan={11}
          style={{
            height: 60,
          }}
        >
          <TableCellContainer>
            <ArchiveLabel
              variant="custom"
              default={TEXT_DS.BODY_SEMIBOLD_14}
            >{`Selected (${selectedContacts.length})`}</ArchiveLabel>
            <ArchiveContact>
              <SvgArchive onClick={() => toggleModalState(true)} />
            </ArchiveContact>
            <ArchiveButton
              variant="custom"
              default={TEXT_DS.BODY_SEMIBOLD_14}
              onClick={() => toggleModalState(true)}
            >
              Delete selected
            </ArchiveButton>
          </TableCellContainer>
        </TableCell>
      )}
      {allSelected && (
        <TableRow>
          <TableCell
            colSpan={10}
            align={'center'}
            style={{
              height: 30,
              padding: 7.5,
              backgroundColor: '#ddd',
            }}
          >
            <ArchiveWarningSpan>
              {`All ${selectedContacts.length} contacts on this page are selected`}
            </ArchiveWarningSpan>
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

export default TableHeader;
