import React, { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { styles as s } from '@onehope/design-system-ohw';
import {
  MenuItem,
  SelectCore as Select,
  theme,
} from '@onehope/design-system-v2';

import left from '../../Credits/assets/left.svg';
import right from '../../Credits/assets/right.png';

interface PaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  rowsOnPage: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  baseRoute: string;
}

const PageSelectorContainer = styled.div`
  display: flex;
  font-family: ${theme.bodyFontFamily};
  font-weight: 420;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  letter-spacing: 0.01em;
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 450px;
`;

const SelectRowsPerPage = styled.div`
  margin-right: 10px;
  margin-top: 5px;
`;

const PageSet = styled.div`
  margin-top: 5px;
  margin-left: 40px;
`;

const ArrowContainer = styled.div`
  margin-top: -5px;
`;

const StyledSelect = styled(Select)`
  && {
    font-family: ${theme.bodyFontFamily};
    font-weight: 420;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
  &.MuiInputBase-input:focus {
    background-color: white;
    border-bottom: none !important;
  }
  &.MuiInput-underline:before {
    border-bottom: none !important;
  }
  &.MuiSelect-select.MuiSelect-select {
    font-size: 15px;
  }
`;

const arrowLeft = css`
  margin-left: 20px;
  :hover {
    cursor: pointer;
  }
`;

const arrowRight = css`
  :hover {
    cursor: pointer;
  }
`;

export default function Pagination({
  count,
  page,
  rowsOnPage,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  baseRoute,
}: PaginationProps) {
  const goToPreviousPage = () => {
    const pageNumber = page - 1;
    if (pageNumber > 0) {
      setPageNumber(pageNumber);
      navigate(`/${baseRoute}?page=${pageNumber}`);
    }
  };

  const goToNextPage = () => {
    const pageNumber = page + 1;
    const lastPage = Math.ceil(count / rowsPerPage);
    if (pageNumber <= lastPage) {
      setPageNumber(pageNumber);
      navigate(`/${baseRoute}?page=${pageNumber}`);
    }
  };

  const higherNumberOfSet =
    count <= rowsPerPage
      ? count
      : page * rowsPerPage > count
      ? count
      : page * rowsPerPage;

  const lowerNumberOfSet =
    count <= rowsPerPage ? 1 : higherNumberOfSet - rowsOnPage + 1;

  const handleRowsPerPageOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <PageSelectorContainer>
      {setRowsPerPage && (
        <>
          <SelectRowsPerPage>Rows per page:</SelectRowsPerPage>
          <StyledSelect defaultValue={10} onChange={handleRowsPerPageOnChange}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </StyledSelect>
        </>
      )}
      <PageSet>
        {lowerNumberOfSet} - {higherNumberOfSet} of {count}
      </PageSet>
      <ArrowContainer>
        <img src={left} css={arrowLeft} onClick={goToPreviousPage}></img>
        <img src={right} css={arrowRight} onClick={goToNextPage}></img>
      </ArrowContainer>
    </PageSelectorContainer>
  );
}
