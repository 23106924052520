import { styles as s } from '@onehope/design-system';
import { PhoneNumber } from '@onehope/design-system-ohw';
import {
  styled,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  Icons,
  TableHead,
  ButtonV2,
} from '@onehope/design-system-v2';
import { CustomerAutoshipsContactsV2_viewer_user_customerAutoships } from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsContactsV2';

const { cssConstants } = s;

const TableWrapper = styled('div')`
  font-family: ${cssConstants.bodyFontFamily};
  color: ${cssConstants.textColor};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 24px 16px;
    font-family: ${cssConstants.bodyFontFamily};
    color: ${cssConstants.textColor};
    font-size: 14px;

    &.headerCell {
      height: 60px;
    }
  }
`;

const NameCell = styled(Grid)({
  gap: '8px',
  flexWrap: 'nowrap',
});

interface Props {
  autoships: CustomerAutoshipsContactsV2_viewer_user_customerAutoships['autoships'];
  /** The function that should be called when a user wants to manage the Autoship subscription */
  handleManageAutoship: (autoshipId: string, contactId: string) => void;
}

export const FourBottleAutoshipTable = ({
  autoships,
  handleManageAutoship,
}: Props) => {
  return (
    <TableWrapper>
      <Table stickyHeader aria-label="4-bottle subscribers table">
        <TableHead>
          <TableRow>
            <TableCell className="headerCell" align="left">
              Name
            </TableCell>
            <TableCell className="headerCell" align="left">
              Email
            </TableCell>
            <TableCell className="headerCell" align="left">
              Phone Number
            </TableCell>
            <TableCell className="headerCell" align="left">
              Subscription Name
            </TableCell>
            <TableCell className="headerCell" align="left" />
          </TableRow>
        </TableHead>

        {autoships && autoships.length > 0 && (
          <TableBody>
            {autoships.map(autoship => (
              <TableRow key={autoship.autoShipId}>
                <TableCell align="left">
                  <NameCell>
                    {autoship.firstName} {autoship.lastName}
                  </NameCell>
                </TableCell>
                <TableCell align="left">
                  {autoship.email || 'no email address'}
                </TableCell>
                <TableCell align="left">
                  {autoship.mobilePhone ? (
                    <PhoneNumber rawValue={autoship.mobilePhone} />
                  ) : (
                    'no phone number'
                  )}
                </TableCell>
                <TableCell align="left">{autoship.autoShipName}</TableCell>
                <TableCell align="left">
                  <ButtonV2
                    type="primary"
                    size="small"
                    onClick={() =>
                      handleManageAutoship(
                        autoship.autoShipId,
                        autoship.contactId,
                      )
                    }
                  >
                    Manage
                  </ButtonV2>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableWrapper>
  );
};
