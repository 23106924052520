import React from 'react';
import {
  Text,
  styled,
  theme,
  TEXT_DS,
  ButtonMui5,
} from '@onehope/design-system-v2';

const StyledMainHeaderText = styled(Text)(({ leftAlign, paddingTop }) => ({
  fontSize: '24px',
  fontFamily: `${theme.headerFontFamily} !important`,
  fontWeight: 300,
  textAlign: leftAlign ? 'left' : 'center',
  letterSpacing: '0.03em',
  color: theme.palette.primary.main,
  [theme.breakpoints.up('sm')]: {
    fontSize: '32px',
  },
}));

export const LinkText = styled(Text)({
  color: theme.palette.forestGreen.main,
  letterSpacing: '0.1em',
});

export const BlueButtonLink = styled(ButtonMui5)({
  color: theme.palette.forestGreen.main,
  borderBottom: `2px solid ${theme.palette.forestGreen.main}`,
  '&:hover': {
    color: theme.palette.forestGreen.main,
    borderBottom: `2px solid ${theme.palette.forestGreen.main}`,
  },
});

export const MainHeaderText = ({
  text,
  leftAlign,
  paddingTop,
}: {
  text: string;
  leftAlign?: boolean;
  paddingTop?: string;
}) => {
  return (
    <StyledMainHeaderText paddingTop={paddingTop} leftAlign={leftAlign}>
      {text}
    </StyledMainHeaderText>
  );
};

const StyledBodyText = styled(Text)(({ leftAlign }) => ({
  paddingTop: '8px',
  paddingBottom: '17.5px',
  textAlign: leftAlign ? 'left' : 'center',
  maxWidth: '800px',
  [theme.breakpoints.up('sm')]: {
    paddingBottom: '32px',
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: '33.5px',
  },
}));

export const BodyText = ({ text }: { text: string }) => {
  return (
    <StyledBodyText
      variant="custom"
      default={TEXT_DS.BODY_LIGHT_16}
      tablet={TEXT_DS.BODY_LIGHT_20}
      laptop={TEXT_DS.BODY_LIGHT_20}
      desktop={TEXT_DS.BODY_LIGHT_20}
    >
      {text}
    </StyledBodyText>
  );
};

export const DownloadLabelText = ({ text }: { text: string }) => {
  return (
    <StyledBodyText
      variant="custom"
      default={TEXT_DS.BODY_LIGHT_16}
      tablet={TEXT_DS.BODY_LIGHT_16}
      laptop={TEXT_DS.BODY_LIGHT_16}
      desktop={TEXT_DS.BODY_LIGHT_16}
    >
      {text}
    </StyledBodyText>
  );
};

export const DescriptionText = ({ text }: { text: string }) => {
  return (
    <StyledBodyText
      variant="custom"
      default={TEXT_DS.BODY_LIGHT_16}
      tablet={TEXT_DS.BODY_LIGHT_18}
      laptop={TEXT_DS.BODY_LIGHT_18}
      desktop={TEXT_DS.BODY_LIGHT_18}
      leftAlign
    >
      {text}
    </StyledBodyText>
  );
};

const StyledTinyText = styled(Text)(({ marginTop }) => ({
  textAlign: 'center',
  width: '60px',
  marginTop: marginTop || '-3px',
}));

export const TinyText = ({
  text,
  marginTop,
}: {
  text: string;
  marginTop?: string;
}) => {
  return (
    <StyledTinyText
      variant="custom"
      default={TEXT_DS.TINY_REGULAR_11}
      marginTop={marginTop}
    >
      {text}
    </StyledTinyText>
  );
};
