import React from 'react';
import { ReactMuiMask } from '@onehope/design-system-ohw';
import { TextFieldProps } from '@onehope/design-system-v2';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export default function CurrencyMask(props: TextFieldProps) {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });
  return <ReactMuiMask mask={currencyMask} {...props} />;
}
