import styled from '@emotion/styled';
import { Badge } from '@material-ui/core';

type NotificationProps = {
  size?: number;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const Notification = styled(Badge)<NotificationProps>`
  .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(200%, 50%);
    background-color: #f4564e;
    padding-left: 8px;
    padding-bottom: 8px;
  }
  &.MuiBadge-dot {
    height: 8px;
    min-width: 8px;
  }
`;
