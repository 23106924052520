import { useState } from 'react';

export default function useToggle(initial = false) {
  const [value, setToggle] = useState(initial);
  return {
    value,
    set: setToggle,
    setTrue: () => setToggle(true),
    setFalse: () => setToggle(false),
    reset: () => setToggle(initial),
    toggle: () => setToggle(prev => !prev),
  };
}
