import React from 'react';

import { styles } from '@onehope/design-system';
import {
  MenuItem,
  TEXT_DS,
  Text,
  styled,
  Grid,
} from '@onehope/design-system-v2';

const { styled: s, cssConstants } = styles;

const TextOverFlowContainer = styled('span')(() => ({
  minWidth: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

type SuggestionContainerProps = {
  height: string;
};

const SuggestionContainer = styled('div')(() => ({
  height: `${({ height }: SuggestionContainerProps) => `${height}px`}`,
  width: '432px',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${cssConstants.mutedAccentColor}`,
}));

const Address = styled('div')(() => ({
  width: '432px',
  height: '18px',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.29,
  letterSpacing: 'normal',
  color: `${cssConstants.textColor}`,
}));

const Padding14 = styled('div')(() => ({
  paddingBottom: '14px',
}));

const HeaderAndSubMenuItem = styled(MenuItem)(() => ({
  '&&': {
    height: '67px',
  },
}));

const AddressMenuItem = styled(MenuItem)(() => ({
  '&&': {
    height: '46px',
    borderLeft: `${props =>
      props?.selected
        ? `4px solid ${cssConstants.textColor}`
        : `4px solid ${cssConstants.neutral5}`}`,
  },
}));

export interface GetItemPropsOptions<Item>
  extends React.HTMLProps<HTMLElement> {
  index?: number;
  item: Item;
  isSelected?: boolean;
  disabled?: boolean;
}

interface SuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: GetItemPropsOptions<string>;
  selectedItem: string;
  suggestion?: string;
  objSuggestion?: any;
  id: string;
}

export function renderSuggestion(
  suggestionProps: SuggestionProps,
): JSX.Element {
  const {
    suggestion,
    objSuggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    id,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  if (typeof objSuggestion !== 'undefined') {
    if (id === 'hostFullNameDropDown' || id === 'nameDropDown') {
      const isSelected =
        (selectedItem || '') ===
        (objSuggestion.fullName || objSuggestion.organizationName);
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion.fullName ||
            objSuggestion.organizationName}${index}`}
          selected={isHighlighted}
          component="div"
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                {objSuggestion.organizationName || objSuggestion.fullName}
              </Text>
            </Grid>
            <Grid>
              <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                {objSuggestion.email}
              </Text>
            </Grid>
          </Grid>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'customNonProfitDropDown') {
      return (
        <HeaderAndSubMenuItem
          {...itemProps}
          key={`${objSuggestion.name}${index}`}
          selected={isHighlighted}
        >
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {objSuggestion?.name}
          </Text>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {objSuggestion?.city}, {objSuggestion?.state}
          </Text>
        </HeaderAndSubMenuItem>
      );
    }
    if (id === 'displayAddressDropDown' || id === 'shippingAddressDropDown') {
      const address = `${objSuggestion.addressLineOne}, ${objSuggestion.city}, ${objSuggestion.state}`;
      const isSelected = (selectedItem || '') === address;
      return (
        <AddressMenuItem
          {...itemProps}
          key={`${address}${index}`}
          selected={isHighlighted}
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'46'}>
            <Padding14 />
            <Address>
              <TextOverFlowContainer>{address}</TextOverFlowContainer>
            </Address>
            <Padding14 />
          </SuggestionContainer>
        </AddressMenuItem>
      );
    }
    if (id === 'eventAddressDropDown') {
      const address = `${objSuggestion.addressLineOne}, ${objSuggestion.city}, ${objSuggestion.state}`;
      const isSelected = (selectedItem || '') === address;
      return (
        <AddressMenuItem
          {...itemProps}
          key={`${address}${index}`}
          selected={isHighlighted}
          style={{
            fontWeight: isSelected ? 500 : 400,
          }}
        >
          <SuggestionContainer height={'46'}>
            <Padding14 />
            <Address>
              <TextOverFlowContainer>{address}</TextOverFlowContainer>
            </Address>
            <Padding14 />
          </SuggestionContainer>
        </AddressMenuItem>
      );
    }
  }
  if (typeof suggestion !== 'undefined') {
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={`${suggestion}${index}`}
        selected={isHighlighted}
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        <TextOverFlowContainer>{suggestion}</TextOverFlowContainer>
      </MenuItem>
    );
  }
}
