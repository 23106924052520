import React, { createContext, useReducer, useContext, ReactNode } from 'react';

import reducer from './reducer';
import { StateType, ActionTypes } from './types';

export type AppGlobalReducer = [StateType, React.Dispatch<ActionTypes>];

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const AppGlobalContext = createContext<AppGlobalReducer>();

type AppGlobalProviderProps = {
  children: ReactNode;
};

export default function AppGlobalProvider({
  children,
}: AppGlobalProviderProps) {
  const initialState = {
    drawerOpen: true,
  };

  const sync = useReducer(reducer, initialState);
  return (
    <AppGlobalContext.Provider value={sync}>
      {children}
    </AppGlobalContext.Provider>
  );
}

export const useAppGlobalContextValue = () =>
  useContext<AppGlobalReducer>(AppGlobalContext);
