import isAlpha from 'validator/lib/isAlpha';
import { LaunchDates, ohwImageUploads } from './constants';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../queries/generatedTypes/CEDash_EventDetailsPage';
import {
  eventIsClosed,
  isSatelliteEvent,
} from '../components/Events/EventsTable/helpers';
import { EventTypeEnum } from '../components/EventsV2/eventTypes';
import { OrderStatus } from './orderConstants';

const imageUploadPath = process.env.GATSBY_IMAGE_UPLOAD_PATH;
export const urlProfileV2 = `https://ohw-image-uploads.s3.amazonaws.com/accounts/${imageUploadPath}`;

export function getCurrentUserId(location: any) {
  const { search } = location;
  const hasCorrectParams = search.includes('user=');
  return hasCorrectParams && search !== '' ? search.split('?user=')[1] : false;
}

export const formatPhone = (value: string) => {
  if (!value) return null;
  let i = 0;
  const number = value.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, (_) => number[i++]);
};

export function numberWithCommas(x: number | null | undefined) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
}

export function getMonthName(month: number) {
  switch (month) {
    case 1:
      return 'january';
    case 2:
      return 'february';
    case 3:
      return 'march';
    case 4:
      return 'april';
    case 5:
      return 'may';
    case 6:
      return 'june';
    case 7:
      return 'july';
    case 8:
      return 'august';
    case 9:
      return 'september';
    case 10:
      return 'october';
    case 11:
      return 'november';
    default:
      return 'december';
  }
}

export function validInput(value: string) {
  return !isNaN(+value);
}

export const statesAbbrev = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export enum keyCodes {
  leftArrow = 37,
  rightArrow = 39,
}

export function isStateAbbrev(abbrev: string) {
  return statesAbbrev.indexOf(abbrev) !== -1;
}

export function removeSpaces(text: string): string {
  return text.replace(/\s/g, '');
}

function isNotAlpha(text: string): boolean {
  return !isAlpha(removeSpaces(text));
}

export function checkContainsPOBox(line: string): boolean {
  const pattern = /^box[^a-z]|(p[-. ]?o.?[- ]?|post office )b(.|ox)/i;
  const noExtraSpace = line.replace(/\s+/g, ' ').trim();
  if (noExtraSpace.match(pattern)) return true;
  const noPunctuation = noExtraSpace.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
  if (noPunctuation.match(pattern)) return true;
  return false;
}

export function isValidUSZip(usZip: string): boolean {
  return /^\d{5}(-\d{4})?$/.test(usZip);
}

export function checkContainsNumber(addressLineOne: string) {
  const containsDigits = /[0123456789]/.test(addressLineOne);
  const containsNumbers = /one/.test(addressLineOne.toLowerCase());
  return containsNumbers || containsDigits;
}

export function checkContainsLetter(addressLineOne: string) {
  return /[abcdefghijklmnopqrstuvwxyz]/.test(addressLineOne.toLowerCase());
}

export function checkCountry(country: string) {
  if (!country) {
    return true;
  }
  const lowerCountry = country.toLowerCase();
  switch (true) {
    case lowerCountry === 'usa':
    case lowerCountry === 'us':
    case lowerCountry === 'united states':
    case lowerCountry === '':
    case lowerCountry === ' ':
      return true;
    default:
      return false;
  }
}

export function evaluateOrderStatus(orderStatus: string | null | undefined) {
  const status = orderStatus || '';

  // object key/value lookups are quicker than arrays
  const deliveredStates = {
    [OrderStatus.delivered]: true,
  };

  const completeStates = {
    [OrderStatus.complete]: true,
  };

  const processingStates = {
    [OrderStatus.orderReceived]: true,
    [OrderStatus.onHold]: true,
    [OrderStatus.underReview]: true,
    [OrderStatus.inProgress]: true,
  };

  const cancelledStates = {
    [OrderStatus.cancelled]: true,
  };

  const undeterminedStates = {
    [OrderStatus.undetermined]: true,
  };

  if (deliveredStates[status]) {
    return 'delivered';
  } else if (completeStates[status]) {
    return 'complete';
  } else if (cancelledStates[status]) {
    return 'cancelled';
  } else if (undeterminedStates[status]) {
    return 'exception';
  } else if (processingStates[status]) {
    return 'processing';
  } else {
    return 'processing';
  }
}

export function isDateBeforeHostKitV2Launch(date: string | Date | null) {
  if (!date) return false;
  return new Date(date) < LaunchDates.hostKitV2;
}

export function isDateBeforeRewardsLaunch(date: string | Date | null) {
  if (!date) return false;
  return new Date(date) < LaunchDates.rewards;
}

export function getHostKitsV2(hostKitsV2Flag: boolean, event: EventType) {
  const eventClosed = eventIsClosed(event);
  const manualCloseDateBeforeLaunch = isDateBeforeHostKitV2Launch(
    event.manualCloseDate,
  );

  // Covers deprecation requirements
  if (eventClosed && (manualCloseDateBeforeLaunch || !event.manualCloseDate)) {
    return false;
  }
  return hostKitsV2Flag;
}

export const isVirtual = (trinityPartyType: string | null) => {
  return trinityPartyType === 'EPARTY';
};

export function showHostRewardPoints(event: EventType) {
  const eventClosed = eventIsClosed(event);
  const manualCloseDateBeforeLaunch = isDateBeforeRewardsLaunch(
    event?.manualCloseDate,
  );
  if (eventClosed && manualCloseDateBeforeLaunch) {
    return false;
  }
  return true;
}

export const getImageUrl = (image: string, size: 'thumb' | 'medium') => {
  const imageId = image.match(/(.*)\.[^.]+$/)[1];
  const mimeType = image.match(/[^.]+$/)[0];
  return `${urlProfileV2}/${imageId}_${size}.${mimeType}`;
};

export function ceProfileImage(image?: string) {
  return image ? `${urlProfileV2}/${image}` : '';
}

export const getEventImage = (photo: {
  groupName: string;
  fileName: string;
}) => {
  if (!photo) return null;
  return `${ohwImageUploads}/${photo?.groupName}/${photo?.fileName}`;
};

export const defaultImages = {
  virtual: 'default-event-header-image-virtual_medium.jpg',
  inPerson: 'default-event-header-image-in-person_medium.jpg',
  fundraiser: 'default-event-header-image-online-fundraiser_medium.jpg',
};

export const getDefaultEventImage = ({
  event,
  eventType,
}: {
  event?: EventType;
  eventType?: string;
}): string => {
  const groupName = `events/${process.env.GATSBY_IMAGE_UPLOAD_PATH}`;
  const type = eventType || getEventType(event);
  const fileName = defaultImages[type];
  return `${ohwImageUploads}/${groupName}/${fileName}`;
};

export const getEventType = (event: EventType): string => {
  const eventType = event?.eventType;
  const isOnline = event?.trinityPartyType === 'EPARTY';
  switch (eventType) {
    case EventTypeEnum.WineTasting:
      return isOnline ? 'virtual' : 'inPerson';
    case EventTypeEnum.Fundraiser:
      return 'fundraiser';
    default:
      return 'inPerson';
  }
};

export const redirectToLoginV2 = (redirectUri: string) => {
  console.log(`utils redirect to login for ${redirectUri}`);
  window.location.href = `${process.env.GATSBY_MEGALITH_URL}/login?redirect_uri=${redirectUri}`;
};

export const redirectToMegalith = () => {
  console.log('utils redirect to megalith');
  window.location.href = `${process.env.GATSBY_MEGALITH_URL}`;
};

export const getEventNonProfit = (donationInfo: any) => {
  return donationInfo?.nonProfitV2 || donationInfo?.nonProfit;
};
export const getShowNotification = (event: EventType) => {
  const satelliteEvent = isSatelliteEvent(event);
  const showCharityNotification =
    !satelliteEvent && !getEventNonProfit(event?.donationInfo);
  if (showCharityNotification) return true;
  const isWineTasting = event?.eventType === EventTypeEnum.WineTasting;
  // Host kit quantity can be zero, but if it's more than 0 order numbers must be provided
  if (!isWineTasting) return false;
  return (
    !Number.isInteger(event?.hostKitQuantity) ||
    (event?.hostKitQuantity && !event?.hostKitOrderNumbers) ||
    (event?.hostKitQuantity &&
      event?.trinityPartyType === 'EVENT' &&
      !event?.wineTastingOrderIds) ||
    (event?.trinityPartyType === 'EPARTY' &&
      event?.isPurchasingTastingFlights === null)
  );
};

export const onHandleTrackOrder = (
  trackingLinks: string[] | null,
  orderId: string,
) => {
  return () => {
    if (!trackingLinks?.length) {
      return;
    }
    const hasMultipleTracking = trackingLinks.length > 1;
    const link = hasMultipleTracking
      ? `${process.env.GATSBY_HOPECOMMERCE_URL}/order/${orderId}`
      : trackingLinks[0];
    window.open(link, '_blank');
  };
};
