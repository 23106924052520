import React, { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import get from 'lodash/get';

import { styles as s } from '@onehope/design-system';
import {
  Table,
  TableBody,
  theme,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@onehope/design-system-v2';

import TableHeader from '../../TableComponents/TableListView/TableHeader';
import Columns from '../../Events/EventsTable/Columns';
import { EventStatusDictionary } from '../../../utils/enums';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';

import {
  CEDash_EventsV2Query_viewer_user_eventsV2_edges as EventEdge,
  CEDash_EventsV2Query_viewer_user_eventsV2_edges_node as EventType,
} from '../../../queries/generatedTypes/CEDash_EventsV2Query';
import { getEventNonProfit, getShowNotification } from '../../../utils/utils';
import { EventManagementTabs } from '../../Event/TabNavigation/tabConstants';

const { cssConstants } = s;

const TableWrapper = styled.div`
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${theme.bodyFontFamily};
    color: ${theme.palette.primary.main};
    border-color: ${theme.palette.veryLightGrey.main};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    max-width: 148px;
  }
`;

const eventIsClosed = (e: EventType | null | undefined) =>
  e?.status !== EventStatusDictionary.OPEN;

const eventIsAcceptingOrders = (e: EventType | null | undefined) =>
  e?.trinityPartyStatus === EventStatusDictionary.OPEN;

const noSortByList = {
  orderAdd: true,
  menu: true,
};

interface EventsListViewProps {
  events: (EventEdge | null)[] | null;
  contactId?: string | null | undefined;
  handleSortEvents: (sortBy: string) => void;
  setSelectedEventId: Dispatch<SetStateAction<string>>;
  handleOrderAdd: (eventId: string | null | undefined) => void;
  toggleEventCopyUrlDialog: (
    eventId: string | null | undefined,
    exigoPartyId: string | null | undefined,
  ) => void;
}

export default function EventsListViewTable({
  handleSortEvents,
  setSelectedEventId,
  handleOrderAdd,
  events,
  contactId,
  toggleEventCopyUrlDialog,
}: EventsListViewProps) {
  const handleRowClick = (
    event: EventEdge | null,
    contactId?: string | null | undefined,
  ) => {
    return () => {
      let link = !getEventNonProfit(event?.node?.donationInfo)
        ? `/event?eventId=${event?.node?.eventId}&tab=${EventManagementTabs.CHARITY}`
        : `/event?eventId=${event?.node?.eventId}&tab=${EventManagementTabs.SUMMARY}`;
      if (contactId) {
        link = `${link}&contactId=${contactId}`;
      }
      return navigate(link);
    };
  };

  return (
    <TableWrapper>
      <Table stickyHeader aria-label="events table">
        <TableHeader
          handleSort={handleSortEvents}
          columns={Columns}
          noSortByList={noSortByList}
        />
        <TableBody>
          {events?.map((event: EventEdge | null) => {
            const onRowClick = handleRowClick(event, contactId);
            return (
              <TableRow hover role="checkbox" key={event?.node?.id}>
                {Columns.map(column => {
                  const value = get(event?.node, `${column.id}`);
                  const donationInfo = event?.node?.donationInfo;
                  const eventId = event?.node?.eventId;
                  const eventClosed = eventIsClosed(event?.node);
                  const title =
                    event?.node?.title ||
                    event?.node?.hostNonProfitName ||
                    event?.node?.hostFullName;
                  const hostFullName = event?.node?.hostFullName;
                  const timeZone = event?.node?.timeZone || 'America/Chicago'; // Fall back for trinity events
                  const eventType = event?.node?.eventType;
                  const exigoPartyId = event?.node?.exigoPartyId;
                  const trinityPartyType = event?.node?.trinityPartyType;
                  const isAcceptingOrders = eventIsAcceptingOrders(event?.node);
                  const onCellClick = !column.render ? onRowClick : () => {};
                  const satelliteEvent = isSatelliteEvent(event?.node);
                  const showNotification = getShowNotification(event?.node);
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={onCellClick}
                    >
                      {column.format &&
                        column.format(
                          value,
                          event?.node?.eventId,
                          eventClosed,
                          donationInfo,
                          title,
                          hostFullName,
                          timeZone,
                          satelliteEvent,
                          eventType,
                          exigoPartyId,
                          trinityPartyType,
                          showNotification,
                        )}
                      {column.render &&
                        column.render({
                          eventId,
                          setSelectedEventId,
                          handleOrderAdd,
                          toggleEventCopyUrlDialog,
                          isAcceptingOrders,
                          exigoPartyId: exigoPartyId?.toString() ?? '',
                        })}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
