import React from 'react';
import { Text, makeStyles, ThemeType } from '@onehope/design-system-v2';
import ActivityEntry from '../types/ActivityEntry';

interface Props {
  title: ActivityEntry['title'];
}

const useStyles = makeStyles((theme: ThemeType) => ({
  body: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.01em',
    color: '#373634',
    width: '275px',
    height: '54px',
    marginBottom: '0px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ['@media (max-width: 599px)']: {
      maxWidth: '275px',
      width: '90%',
    },
  },
}));

export default function Title({ title }: Props) {
  if (!title || typeof title === 'undefined') {
    return null;
  }
  const classes = useStyles();

  return <Text className={classes.body}>{title}</Text>;
}
