import React from 'react';
import { ButtonV2 } from '@onehope/design-system-v2';

import useToggle from '../../utils/useToggleHook';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import { SubscriptionStatuses } from '../../utils/helpers';
import { useCommonStyles } from '../_shared/styles';

type ActionType = 'button' | 'link';

interface CancelMembershipProps {
  subscriptionStatus: string | null;
  enrollmentStatus?: string | null;
  isCeSubscription: boolean;
  actionText: string;
  actionType: ActionType;
}

const AccountCancelMembership = ({
  subscriptionStatus,
  isCeSubscription,
  actionText,
  actionType,
}: CancelMembershipProps) => {
  const classes = useCommonStyles();
  const { value: open, setTrue: setOpen, setFalse: setClosed } = useToggle();
  const hasActiveSubscription = !isCeSubscription
    ? !!(subscriptionStatus !== SubscriptionStatuses.Cancelled)
    : true;

  return (
    <>
      <CancelSubscriptionDialog
        open={open}
        onClose={setClosed}
        isCeSubscription={isCeSubscription}
      />
      {actionType === 'link' ? (
        <ButtonV2
          type="textLink"
          className={classes.button}
          disabled={!hasActiveSubscription}
          onClick={setOpen}
        >
          {actionText}
        </ButtonV2>
      ) : (
        <ButtonV2
          fullWidth
          type="secondary"
          disabled={!hasActiveSubscription}
          onClick={setOpen}
        >
          {actionText}
        </ButtonV2>
      )}
    </>
  );
};

export default AccountCancelMembership;
