import gql from 'graphql-tag';

export default gql`
  query CEDash_ReachOutResults(
    $limit: Int
    $offset: Int
    $reachOutId: String!
    $statusFilter: [ReachOutRequestStatus!]
  ) {
    viewer {
      user {
        userId
      }
      v2 {
        reachOutTemplate(id: $reachOutId) {
          id
          reachOutId
          name
          fullTitle
          coverImageUrl
          expirationDate
          description
          isActive
          resultsAvailable
          isEligible
          category {
            name
            contentfulEntryId
            templateCount
          }
          shareConfigurations {
            medium
          }
        }
        reachOutResults(
          limit: $limit
          offset: $offset
          statusFilter: $statusFilter
          reachOutId: $reachOutId
        ) {
          resultType
          resultsUrl
          simpleContact {
            contactId
            firstName
            lastName
            phoneNumber
            emailAddress
          }
          reachOutRequest {
            requestId
            status
            ceId
            reachOutId
            viewCount
            shareMedium
            lifecycleStage
            lastModifiedDate
            response
          }
        }
      }
    }
  }
`;
