import React from 'react';
import { useMutation } from '@apollo/client';
import { Icons, ChunkyButton } from '@onehope/design-system-v2';

/* MUTATIONS */
import UPDATE_SELECTED_CAUSE_MUTATION from '../../../../mutations/UpdateSelectedCauseMutation';

import { useCauseOfChoiceContextValue } from '../../../../stateManagement/context';
import {
  CauseOfChoiceScreenDictionary,
  CauseOfChoiceTypeKeys,
  TypeKeys,
} from '../../../../stateManagement/types';
import { useCommonStyles } from '../../../../utils/commonStyles';
import { useCauseOfChoiceNetworkLayerValue } from '../../../../stateManagement/networkLayer';

const { COCNotSure } = Icons;

export default function NotSureButton() {
  const commonStyles = useCommonStyles();
  const [{ loading }, dispatch] = useCauseOfChoiceContextValue();
  const {
    modalLocation,
    trackUpdateCauseOfChoice,
  } = useCauseOfChoiceNetworkLayerValue();
  const [updateSelectedCauseMutation] = useMutation(
    UPDATE_SELECTED_CAUSE_MUTATION,
  );

  const handleLoadingState = (loadingState: boolean) => {
    dispatch({
      type: TypeKeys.UPDATE_LOADING,
      loading: loadingState,
    });
  };

  const handleClick = () => {
    if (!loading) {
      handleLoadingState(true);
      const variables = {
        input: {
          nonProfitId: null,
        },
      };
      return updateSelectedCauseMutation({ variables }).then(res => {
        handleLoadingState(false);
        dispatch({
          type: TypeKeys.UPDATE_SCREEN,
          screen: CauseOfChoiceScreenDictionary.NON_SURE_CONFIRMATION_SCREEN,
        });
        if (trackUpdateCauseOfChoice) {
          trackUpdateCauseOfChoice({
            selectedCause: res?.data?.causeUpdateSelected?.user?.selectedCause,
            guestCause:
              res?.data?.causeUpdateSelected?.user?.cartV2?.guestNonProfit,
            causeType: CauseOfChoiceTypeKeys.DEFAULT,
            updateLocation: modalLocation,
            shoppingWithCeoUser:
              res?.data?.causeUpdateSelected?.user?.shoppingWithCeoUser,
          });
        }
      });
    } else {
      return;
    }
  };

  return (
    <div className={commonStyles.container}>
      <ChunkyButton
        avatar={<COCNotSure />}
        primaryText="Choose for me"
        secondaryText="Our Foundation will direct your donation to a trusted nonprofit on your behalf."
        onClick={handleClick}
      />
    </div>
  );
}
