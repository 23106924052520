import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Icons } from '@onehope/design-system-v2';

import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplate } from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import REGISTER_REACH_OUT_REQUEST from '../../../mutations/ReachOuts/CreateReachOutRequestV2';

import {
  ReachOutLifecycleStage,
  ShareMedium,
} from '../../../../generatedTypes/globalTypes';
import { SocialShareButton } from './SocialShareButton';
import {
  CEDash_ReachOutRequest,
  CEDash_ReachOutRequestVariables,
  CEDash_ReachOutRequest_registerReachOutV2_shareConfig,
} from '../../../mutations/ReachOuts/generatedTypes/CEDash_ReachOutRequest';
import { SocialShareModal } from './SocialShareModal';
import { IconWrapper } from './sharedComponents';
import { TinyText } from '../StyledComponents/Text';
import { SaveAssetsModal } from './SaveAssetsModal';
import { PrefetchThumbnails } from './PrefetchThumbnails';

type Props = {
  ceAccountId: string;
  reachOut: ReachOutTemplate | null;
};

const SocialSharing = ({ ceAccountId, reachOut }: Props) => {
  const [socialDialogOpen, setSocialDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [
    selectedShareConfig,
    setSelectedShareConfig,
  ] = useState<CEDash_ReachOutRequest_registerReachOutV2_shareConfig | null>(
    null,
  );

  const [registerRequest, { loading }] = useMutation<
    CEDash_ReachOutRequest,
    CEDash_ReachOutRequestVariables
  >(REGISTER_REACH_OUT_REQUEST);

  const closeShareDialog = () => {
    setSocialDialogOpen(false);
  };

  const showShareDialog = async (shareMedium: ShareMedium) => {
    setSocialDialogOpen(true);

    const result = await registerRequest({
      variables: {
        reachOutInput: {
          contactId: null,
          lifecycleStage: ReachOutLifecycleStage.INITIAL,
          reachOutId: reachOut.reachOutId,
          shareMedium,
        },
      },
    });

    setSelectedShareConfig(
      result.data?.registerReachOutV2?.shareConfig ?? null,
    );
  };

  const showDownloadDialog = () => {
    setDownloadDialogOpen(true);
  };

  const closeDownloadDialog = () => {
    setDownloadDialogOpen(false);
  };

  const { shareConfigurations } = reachOut;

  return (
    <>
      {/* Render social media sharing buttons */}
      {shareConfigurations
        .filter(({ medium }) =>
          [ShareMedium.FACEBOOK_FEED_POST, ShareMedium.LINKED_IN_POST].includes(
            medium,
          ),
        )
        .map(({ medium }) => (
          <SocialShareButton handleClick={showShareDialog} medium={medium} />
        ))}

      {/* Render manual sharing buttons */}
      {reachOut.allowSaveAssets && (
        <IconWrapper>
          <Icons.ReachOutsDownloadButton onClick={showDownloadDialog} />
          <TinyText text="Save assets" marginTop="-3px" />
        </IconWrapper>
      )}

      {reachOut.allowCopyLink && (
        <SocialShareButton
          handleClick={showShareDialog}
          medium={ShareMedium.MANUAL_LINK}
        />
      )}

      {/* Render modals for sharing modes */}
      <SocialShareModal
        ceAccountId={ceAccountId}
        dialogOpen={socialDialogOpen}
        handleCloseDialog={closeShareDialog}
        loading={loading}
        shareConfig={selectedShareConfig}
        reachOutTemplate={reachOut}
      />

      <SaveAssetsModal
        ceAccountId={ceAccountId}
        dialogOpen={downloadDialogOpen}
        reachOutTemplate={reachOut}
        handleCloseDialog={closeDownloadDialog}
      />

      <PrefetchThumbnails reachOutTemplate={reachOut} />
    </>
  );
};

export default SocialSharing;
