import React from 'react';
import { styles as s } from '@onehope/design-system';
import {
  Text,
  TEXT_DS,
  ButtonV2,
  theme,
  styled,
} from '@onehope/design-system-v2';

const { cssConstants } = s;

const FilterContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  @media all and (min-width: 960px) {
    padding: 0 24px 0 24px;
    flex-direction: row;
  }
`;

const FilterButton = styled(ButtonV2)`
  width: 100%;
  height: 40px;
  text-transform: none;
  border-color: ${theme.palette.greyDark.main};
  font-size: 14px;
  color: ${theme.palette.greyDark.main};
  ${props => {
    return props.selected
      ? {
          color: '#FFFFFF',
          ':hover': {
            backgroundColor: cssConstants.primaryColor,
          },
        }
      : {
          color: theme.palette.greyDark.main,
          ':hover': {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.greyDark.main,
          },
        };
  }}
  @media all and (min-width: 960px) {
    max-width: 118px;
  }
`;

const FilterText = styled(Text)`
  ${props => {
    return props.selected
      ? {
          color: '#FFFFFF',
        }
      : {
          color: theme.palette.greyDark.main,
        };
  }}
`;

type FilterBarProps = {
  filter: string;
  handleFilterChange: (newFilter: string) => void;
};

const FilterBar = ({ filter, handleFilterChange }: FilterBarProps) => {
  const filterOptions = ['All', 'Wine Club', 'Host'];
  const dispatchHandleFilterChange = (newFilter: string) => {
    handleFilterChange(newFilter);
  };

  return (
    <FilterContainer>
      {filterOptions.map(filterOption => (
        <FilterButton
          type={filterOption === filter ? 'primary' : 'secondary'}
          selected={filterOption === filter}
          onClick={() => {
            dispatchHandleFilterChange(filterOption);
          }}
        >
          <FilterText
            variant="custom"
            default={TEXT_DS.BODY_SEMIBOLD_14}
            selected={filterOption === filter}
          >
            {filterOption}
          </FilterText>
        </FilterButton>
      ))}
    </FilterContainer>
  );
};

export default FilterBar;
