import React from 'react';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import {
  Icons,
  IconButton,
  Menu,
  MenuItem as MuiMenuItem,
} from '@onehope/design-system-v2';

import { useArchiveContactsContext } from './ArchiveContactsContext';
import { ContactManagementTabs } from '../ContactV2/ContactDetailsPage/TabContent/tabConstants';

const { cssConstants } = s;
const { MoreVertIcon: MuiMoreVertIcon } = Icons;

const options = ['View Contact', 'Cancel'];

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '8px' : '0px')};
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  contactId: string | null;
  email: string | null;
  isCardView: boolean;
  handleOrderAdd: (contactId: string) => void;
}
export default function IconMenu(props: IconMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewContact = () => {
    return navigate(
      `/contact?contactId=${props.contactId}&tab=${ContactManagementTabs.DETAILS}`,
    );
  };

  const { handleSingleArchiveContact } = useArchiveContactsContext();
  const handleOrderAdd = (email: string | null | undefined) => {
    if (email) {
      return props.handleOrderAdd(email);
    }
  };

  return (
    <Container isCardView={props.isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="View Contact" onClick={handleViewContact}>
          Edit Details
        </MenuItem>
        <MenuItem
          key="Archive"
          onClick={() => {
            handleSingleArchiveContact(props.contactId);
          }}
        >
          Archive
        </MenuItem>
        <MenuItem
          key="New Contact Order"
          onClick={() => handleOrderAdd(props.email)}
        >
          New Order
        </MenuItem>
      </Menu>
    </Container>
  );
}
