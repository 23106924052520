import React from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { injectStripe, CardElement } from 'react-stripe-elements';

import {
  Container,
  Dialog,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
  Text,
  TEXT_DS,
  Input,
  Button,
  ThemeType,
  Spacer,
} from '@onehope/design-system-v2';

import STRIPE_PAYMENT_ADD_MUTATION from '../../mutations/PaymentAddMutation';

import useToggle from '../../utils/useToggleHook';
import validationSchema from './validationSchema';
import {
  useFormStyles,
  CloseIcon,
  ContainerGrid,
  Checkbox,
  ButtonsContainer,
} from '../_shared/formStyles';

import { Account_PaymentsGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment as PaymentType } from '../../queries/generatedTypes/Account_PaymentsGetQuery';

export type StripeFormDataValues = {
  exp_month?: string | null | number;
  exp_year?: string | null | number;
  name?: string | null;
  address_zip?: string | null;
  last4?: string | number | null;
  brand?: string | null;
};

interface PaymentDialogProps {
  open: boolean;
  stripe?: any;
  onClose: () => void;
  payment?: PaymentType;
  headerText: string;
  isDefault?: boolean;
  isEditing?: boolean;
  hasDefaultPaymentId: boolean;
  setSelectedPayment?: (input: PaymentType) => void;
  contactId?: string;
}

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: { width: '600px' },
  },
}));

const useComponentStyles = makeStyles((theme: ThemeType) => ({
  cardContainer: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: '16px',
    marginBottom: '32px',
  },
  label: {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    fontFamily: theme.bodyFontFamily,
    color: theme.palette.activeGrey.main,
    paddingBottom: 12,
    fontWeight: 500,
  },
  card: {
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
  },
}));

const getStripeAddPaymentFormValues = (
  data: StripeFormDataValues,
  name: string,
) => {
  const { exp_month, exp_year, last4, brand } = data;
  return {
    name,
    brand,
    exp_month: String(exp_month),
    exp_year: String(exp_year),
    dynamic_last4: last4,
  };
};

const PaymentDialog = (props: PaymentDialogProps) => {
  const {
    onClose,
    open,
    payment,
    headerText,
    isDefault,
    isEditing,
    hasDefaultPaymentId,
    stripe,
    setSelectedPayment,
    contactId,
  } = props;

  const classes = useFormStyles();
  const dialogClasses = useDialogStyles();
  const componentStyles = useComponentStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [addPaymentMutation, { loading: addingPayment }] = useMutation(
    STRIPE_PAYMENT_ADD_MUTATION,
  );
  const loading = addingPayment;

  const {
    value: makeDefaultPayment,
    setTrue: setMakeDefaultPayment,
    setFalse: setRemoveDefaultPayment,
  } = useToggle(false);
  const {
    // value: updateSubscriptions,
    // setTrue: setUpdateSubscriptionsPayment,
    setFalse: setRemoveUpdateSubscriptionsPayment,
  } = useToggle(false);

  const {
    value: stripeElementComplete,
    setTrue: setStripeElementComplete,
    setFalse: setStripeElementErrors,
  } = useToggle(false);
  const toggleDefaultPayment = () => {
    if (makeDefaultPayment) {
      setRemoveDefaultPayment();
    } else {
      setMakeDefaultPayment();
    }
  };

  // const toggleUpdateSubscriptions = () => {
  //   if (updateSubscriptions) {
  //     setRemoveUpdateSubscriptionsPayment();
  //   } else {
  //     setUpdateSubscriptionsPayment();
  //   }
  // };

  const handleOnClose = () => {
    if (!loading) {
      setRemoveUpdateSubscriptionsPayment();
      setRemoveDefaultPayment();
      onClose();
    }
  };

  const handleCardElementOnChange = (ev: any) => {
    if (ev.complete) {
      setStripeElementComplete();
    } else {
      setStripeElementErrors();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      classes={dialogClasses}
      fullScreen={isMobile}
    >
      <Container className={classes.main}>
        <Grid container>
          <CloseIcon onClick={handleOnClose} />
          <ContainerGrid container direction="column" alignItems="flex-start">
            <Text
              variant="custom"
              default={TEXT_DS.TITLE_LIGHT_24}
              tablet={TEXT_DS.TITLE_LIGHT_32}
              laptop={TEXT_DS.TITLE_LIGHT_32}
              desktop={TEXT_DS.TITLE_LIGHT_32}
            >
              {headerText}
            </Text>
            <Formik
              initialValues={{
                nickname: payment?.nickname || '',
                name: payment?.name || '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                stripe
                  .createToken({
                    type: 'card',
                    name: values.name || payment?.name,
                  })
                  .then((data: any) => {
                    if (data.token) {
                      const stripePaymentForm = getStripeAddPaymentFormValues(
                        data.token.card,
                        values?.name,
                      );
                      // const stripeTokenId = data.token.id; /* use for adding */
                      // use payment.paymentId for editing
                      const stripeTokenId = data.token.id;
                      const variables = {
                        input: {
                          stripeTokenId,
                          oldStripeTokenId: payment?.paymentId,
                          isAddingNewSubscriptionsPayment: makeDefaultPayment,
                          nickname: values.nickname,
                          data: stripePaymentForm,
                          isEditing,
                          makeDefaultPayment:
                            isDefault ||
                            makeDefaultPayment ||
                            !hasDefaultPaymentId,
                          contactId,
                        },
                      };
                      addPaymentMutation({ variables })
                        .then((res: any) => {
                          if (setSelectedPayment) {
                            setSelectedPayment(
                              res?.data?.stripeV2PaymentAdd?.stripePayment,
                            );
                          }
                          handleOnClose();
                        })
                        .catch((err) => {
                          setFieldError('name', err.message);
                          setSubmitting(false);
                        });
                    }
                  })
                  .catch((err: any) => {
                    setFieldError('name', err.message);
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                submitForm,
                isSubmitting,
                errors,
                touched,
                handleChange,
              }) => (
                <Form className={classes.form}>
                  <div className={classes.formItem}>
                    <Input
                      fullWidth
                      label="Payment nickname"
                      name="nickname"
                      type="nickname"
                      placeholder="My Home"
                      value={values.nickname}
                      onChange={handleChange}
                      error={touched.nickname && Boolean(errors.nickname)}
                      hasError={touched.nickname && Boolean(errors.nickname)}
                      validate
                      helperText={touched.nickname && errors.nickname}
                    />
                  </div>
                  <div className={classes.formItem}>
                    <Input
                      label="Name on card"
                      fullWidth
                      name="name"
                      type="name"
                      placeholder="Jane Austen"
                      onChange={handleChange}
                      value={values.name}
                      error={touched.name && Boolean(errors.name)}
                      hasError={touched.name && Boolean(errors.name)}
                      validate
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div className={componentStyles.cardContainer}>
                    <div className={componentStyles.label}>Credit card #</div>
                    <CardElement
                      // hidePostalCode={true}
                      className={componentStyles.card}
                      onChange={handleCardElementOnChange}
                    />
                  </div>
                  {!isDefault && (
                    <Grid container alignItems="center">
                      <Checkbox
                        checked={makeDefaultPayment}
                        onClick={toggleDefaultPayment}
                      />
                      Make this my default payment method
                      <Spacer xs="11px" sm="11px" lg="11px"></Spacer>
                      <Text
                        variant="custom"
                        default={TEXT_DS.BODY_LIGHT_ITALIC_14}
                      >
                        Setting this card as your default payment will update
                        all of your current subscriptions to use this payment
                        method
                      </Text>
                    </Grid>
                  )}
                  {/*<Grid container alignItems="center">*/}
                  {/*  <Checkbox*/}
                  {/*    checked={updateSubscriptions}*/}
                  {/*    onClick={toggleUpdateSubscriptions}*/}
                  {/*  />{' '}*/}
                  {/*  Update my subscriptions to use this payment*/}
                  {/*</Grid>*/}
                  <Spacer xs="24px" sm="32px" lg="32px" />
                  <ButtonsContainer container>
                    <Button
                      type="primary"
                      disabled={
                        !stripeElementComplete || isSubmitting || loading
                      }
                      loading={
                        stripeElementComplete && (isSubmitting || loading)
                      }
                      fullWidth
                      onClick={submitForm}
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.secondaryButton}
                      type="secondary"
                      disabled={isSubmitting || loading}
                      fullWidth
                      onClick={handleOnClose}
                    >
                      Cancel
                    </Button>
                  </ButtonsContainer>
                </Form>
              )}
            </Formik>
          </ContainerGrid>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default injectStripe(PaymentDialog);
