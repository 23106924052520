import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';

import { styles as s } from '@onehope/design-system-ohw';
import { useTheme, useMediaQuery, Table } from '@onehope/design-system-v2';

import { CreditsPage_user_credits_edges as CreditsEdges } from '../../../queries/generatedTypes/CreditsPage_user';
import TabFilter from '../../TableComponents/TabFilter';
import CardListView from '../../TableComponents/CardListView';
import TablePagination from '../../TableComponents/Pagination';
import TableBody from './TableListView/TableBody';
import { CEDash_CreditsQuery } from '../../../queries/generatedTypes/CEDash_CreditsQuery';

const { cssConstants } = s;
const Container = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.font};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

const NoCreditsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  @media all and (min-width: 768px) {
    padding: 40px 16px;
  }
`;

const NoCreditsText = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

interface CreditsTableProps {
  credits: CEDash_CreditsQuery['viewer']['user']['credits']['edges'];
  page: number;
  numberOfTotalCredits: number;
  filterBy: string;
  setPageNumber: (page: number) => void;
  setFilterBy: (filterBy: any) => void;
  handleSortCredits: (sortBy: string) => void;
}

export default function CreditsTable(props: CreditsTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    page,
    numberOfTotalCredits,
    setPageNumber,
    credits,
    setFilterBy,
    filterBy,
    handleSortCredits,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <Fragment>
      <TabFilter
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        tabValues={['all', 'available', 'redeemed', 'expired']}
      />
      {credits && credits.length > 0 ? (
        <Fragment>
          <Container>
            <Toolbar />
            {!isMobile ? (
              <Table stickyHeader aria-label="credits table">
                <TableBody
                  credits={credits}
                  handleSortCredits={handleSortCredits}
                />
              </Table>
            ) : (
              <CardListView listItems={credits} baseRoute={'credits'} />
            )}
          </Container>
          <TablePagination
            page={page}
            count={numberOfTotalCredits}
            setPageNumber={setPageNumber}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsOnPage={credits.length}
            baseRoute={'credits'}
          />
        </Fragment>
      ) : (
        <NoCreditsContainer>
          <NoCreditsText>No event kit credits</NoCreditsText>
        </NoCreditsContainer>
      )}
    </Fragment>
  );
}
