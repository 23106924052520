import { styles } from '@onehope/design-system-ohw';
import * as datePicker from './datePicker';
import * as inputs from './inputs';
import * as layout from './layout';
import * as menu from './menu';
import * as radio from './radio';
import * as select from './select';
import * as toolTip from './toolTip';
import * as typography from './typography';

const {
  antDesignStyles: { buttons, checkBox, icon },
} = styles;

export {
  buttons,
  checkBox,
  datePicker,
  icon,
  inputs,
  layout,
  menu,
  radio,
  select,
  toolTip,
  typography,
};
