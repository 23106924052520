// import * as React from 'react';
// import { css } from '@emotion/core';
import styled from '@emotion/styled';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  textColor,
  font,
  neutral2,
  inputFocusColor,
  errorColor,
  primaryColor,
  neutral34,
  neutral0,
} from '../../styles/cssConstants';

// const baseInputStyle = css`
//   padding: 0.5rem;
//   width: 100%;
//   display: block;
//   border-radius: 0;
//   border: none;
//   outline: none;
//   font-weight: normal;
//   line-height: 1.57;
//   font-size: 14px;
//   height: 52px;
//   color: ${textColor};
//   background-color: rgba(250, 250, 250, 0.04);
// `;

type InputProps = {
  error?: boolean;
  hasSuccess?: boolean;
  focusError?: boolean;
  startAdornment?: string;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const Input = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${font};
    height: 42.22px;
    padding: ${({ startAdornment }: InputProps) =>
      startAdornment ? '0.15rem 16px 0.15em 32px' : '0.15rem 16px'};
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    background-color: ${({ hasSuccess, focusError }: InputProps) =>
      hasSuccess ? neutral0 : focusError ? '#fff5f5' : neutral2};
    border-bottom: 1px solid ${primaryColor};
  }
  & .MuiInputBase-input::placeholder {
    font-style: italic !important;
  }
  & .MuiInputBase-input:focus {
    background-color: ${({ error }: InputProps) =>
      error ? '#fef8f5' : inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }
  & .MuiFormHelperText-root {
    font-family: ${font};
    color: ${errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-positionEnd {
    position: absolute;
    right: 16px;
  }
  & .MuiInputAdornment-positionStart {
    position: absolute;
    left: 16px;
  }
`;
// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const InputSelect = styled(Select)<InputProps>`
  &.MuiInputBase-input {
    font-family: ${font};
    padding: 1rem 16px;
    min-width: 144px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    border: 1px solid red;
    background-color: ${neutral2};
  }
  &.MuiInputBase-input::placeholder {
    font-style: italic !important;
  }

  &.MuiSvgIcon-root {
    fill: ${textColor};
  }
  &.MuiInputBase-input:focus {
    background-color: ${({ error }: InputProps) =>
      error ? '#fef8f5' : inputFocusColor};
  }
  &.MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const SearchInput = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${font};
    height: 48px;
    padding: 0.15rem 16px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    border-radius: 100px;
    background-color: ${neutral2};
  }
  & .MuiInputBase-input::placeholder {
    font-style: italic !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${primaryColor} !important;
    border-radius: 100px;
  }

  & .MuiInputBase-input:focus {
    background-color: ${inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: InputProps) =>
      error ? errorColor : primaryColor};
  }

  & .MuiFormHelperText-root {
    font-family: ${font};
    color: ${errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;

// export const InputMask =
//   styled(ReactInputMask) <
//   InputProps >
//   `
//   ${baseInputStyle};
//   box-shadow: inset 0 -1px 0 0 ${({ error} ) =>
//     errors ? errorColor : primaryColor};
//   &:hover {
//     box-shadow: inset 0 -1px 0 0 ${({ error} ) =>
//       errors ? errorColor : primaryColor};
//   }
//   &:focus {
//     background-color: ${({ error} ) =>
//       errors ? '#fef8f5' : inputFocusColor};
//     box-shadow: inset 0 -2px 0 0 ${({ error} ) =>
//       errors ? errorColor : primaryColor};
//   }
// `;

export const Label = styled.label`
  font-family: ${font};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${neutral34};
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const StartAdornment = styled(InputAdornment)`
  & .MuiTypography-colorTextSecondary {
    color: ${textColor};
    font-family: ${font};
  }
`;
