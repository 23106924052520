import { gql } from '@apollo/client';

const BULK_PRODUCTS_V2_QUERY = gql`
  query WineClub_BulkProductsV2Query($productIds: [String!]!) {
    viewer {
      id
      productsV2Bulk(productIds: $productIds) {
        edges {
          node {
            id
            productId
            name
            price
            finalPrice
            priceSale
            wineBottleQuantity
            images {
              hero
              productCatalog
              productFront
              productBack
              squareFront
            }
            content {
              id
              summary
              includes
              description
            }
          }
        }
      }
    }
  }
`;

export default BULK_PRODUCTS_V2_QUERY;
