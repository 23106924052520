import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  FormEvent,
} from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { MyFormValues } from './FormTypes';
import { ContactType } from '../../../../ContactsV3/ContactsContext';
import ContactEditMutation from '../../../../../mutations/ContactsV2/ContactsV2UpdateMutation';
import AccountEditMutation from '../../../../../mutations/ContactsV2/ContactEditMutation';
import { ContactDetailsFormSchema } from './validationSchema';
import ContactDetailsForm from './DetailsForm';
import FormContainer from '../../../../Event/FormContainer';
import Loading from '../../../../../common/Loading';
import { useTrackingContextValue } from '../../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../../utils/segment/constants';
import Notification from '../../../../Notifcations/DefaultNotifcation';
import { ContactManagementTabs } from '../tabConstants';

const Container = styled.div`
  position: relative;
`;

interface DetailsProps {
  contact: ContactType;
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  handleChange: (event: FormEvent<EventTarget>, value: string) => void;
}

const Details = ({
  contact,
  editing,
  setEditing,
  handleChange,
}: DetailsProps) => {
  // tracking context
  const { trackEventV2 } = useTrackingContextValue();
  // mutations
  const [contactEdit, { loading: mutationLoading }] = useMutation(
    ContactEditMutation,
  );
  const [accountEdit, { loading: accountMutationLoading }] = useMutation(
    AccountEditMutation,
  );
  // error state container
  const [errorNotification, setErrorNotification] = useState('');

  // detect added existing account for notification
  const isAddedExistingAccount = JSON.parse(
    localStorage.getItem('addedExistingAccount') || 'false',
  );

  useEffect(() => {
    if (isAddedExistingAccount) {
      const timer = setTimeout(() => {
        localStorage.setItem('addedExistingAccount', 'false');
        return setAddedExistingAccount(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
    return;
  }, [isAddedExistingAccount]);

  const [addedExistingAccount, setAddedExistingAccount] = useState(
    isAddedExistingAccount,
  );

  // handle notification close
  const handleAddedExistingAccountClose = () => {
    localStorage.setItem('addedExistingAccount', 'false');
    setAddedExistingAccount(false);
  };

  // toggle editing state
  const toggleEditing = () => {
    setErrorNotification('');
    setEditing(!editing);
  };
  // reformat some of our values to submit them as an account change
  const formatAccountInfo = (values: MyFormValues, contact: ContactType) => {
    const { shippingAddress, emailAddress } = contact;
    const {
      firstName,
      lastName,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zip,
      phoneNumber,
      dateOfBirth,
    } = values;
    return {
      email: emailAddress,
      firstName,
      lastName,
      organizationName: null,
      shippingAddress: addressLineOne
        ? {
            addressId: shippingAddress?.addressId,
            firstName,
            lastName,
            phoneNumber: phoneNumber ? phoneNumber.replace(/[-() ]/g, '') : '',
            addressLineOne,
            addressLineTwo,
            city,
            state,
            zip,
          }
        : null,
      mobilePhone: phoneNumber ? phoneNumber.replace(/[-() ]/g, '') : null,
      dateOfBirth: dateOfBirth ? dateOfBirth : null,
    };
  };

  const handleUpdateContact = async ({ values, contact }: any) => {
    const { contactId, accountId, ownerAccountId } = contact;
    const {
      phoneNumber = '',
      firstName,
      lastName,
      emailAddress,
      dateOfBirth,
    } = values;
    const formattedPhoneNumber = phoneNumber.replace(/[-() ]/g, '');
    try {
      const editResponse = await contactEdit({
        variables: {
          contactId,
          contact: {
            firstName,
            lastName,
            emailAddress,
            dateOfBirth,
            phoneNumber: formattedPhoneNumber,
          },
        },
      });
      const contact = editResponse?.data?.contactsV2Update?.contact;
      // fire off segment event for updated contact
      trackEventV2({
        event: segmentEvents.contactUpdated,
        properties: {
          accountId: ownerAccountId,
          contactId,
        },
      });
      // if contact has an associated account, update the account
      if (accountId) {
        await accountEdit({
          variables: {
            input: {
              form: formatAccountInfo(values, contact),
            },
          },
        });
      }
      if (contact?.accountId && !accountId) {
        // An existing contact has been updated with an existing account
        localStorage.setItem('addedExistingAccount', 'true');
      }
      window.location.href = `/contact?contactId=${contactId}&tab=${ContactManagementTabs.DETAILS}`;
    } catch (errors) {
      const errorMessage = errors?.graphQLErrors?.[0]?.message;
      setErrorNotification(errorMessage);
      return;
    }
  };

  const shippingAddressInitialValues = contact?.shippingAddressIdDefault
    ? {
        addressLineOne: contact?.shippingAddress?.addressLineOne || '',
        addressLineTwo: contact?.shippingAddress?.addressLineTwo || '',
        city: contact?.shippingAddress?.city || '',
        country: contact?.shippingAddress?.country || '',
        state: contact?.shippingAddress?.state || '',
        zip: contact?.shippingAddress?.zip || '',
      }
    : undefined;

  const dobInitialValue = contact?.dateOfBirth ? contact?.dateOfBirth : '';
  // dynamically inject validations depending on if Contact isAccount
  const validationSchema = ContactDetailsFormSchema(!!contact?.accountId);

  if (mutationLoading || accountMutationLoading) return <Loading />;
  return (
    <Container>
      <Notification
        variant="info"
        open={addedExistingAccount}
        message={`This email belongs to an account already. The contact information entered has been updated.`}
        handleClose={handleAddedExistingAccountClose}
        transform={35}
      />
      <FormContainer
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        initialValues={{
          ...contact,
          ...shippingAddressInitialValues,
          dateOfBirth: dobInitialValue,
        }}
        formToUse={(props: any) => (
          <ContactDetailsForm
            {...props}
            editing={editing}
            toggleEditing={toggleEditing}
            contact={contact}
            mutationLoading={mutationLoading}
            errorNotification={errorNotification}
            handleTabChange={handleChange}
          />
        )}
        onSubmit={async (values: MyFormValues, { setErrors }: any) => {
          await handleUpdateContact({
            values,
            contact,
          });
        }}
      />
    </Container>
  );
};

export default Details;
