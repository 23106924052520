import gql from 'graphql-tag';

export const CauseOfChoiceNonProfitAddress = gql`
  fragment CauseOfChoiceNonProfitAddress on NonProfitAddress {
    firstName
    lastName
    city
    state
    zip
    phoneNumber
    addressLineOne
    addressLineOne
  }
`;

export const CauseOfChoiceNonProfit = gql`
  fragment CauseOfChoiceNonProfit on NonProfitV2 {
    id
    nonProfitName
    nonProfitDescription
    nonProfitPayeeName
    nonProfitTaxId
    nonProfitId
    isIRSVerified
    isPreApproved
    nonProfitAddress {
      ...CauseOfChoiceNonProfitAddress
    }
  }
  ${CauseOfChoiceNonProfitAddress}
`;

const NON_PROFIT_V2_QUERY = gql`
  query CEDash_NonProfitV2($nonProfitIds: [String]) {
    viewer {
      id
      v2 {
        id
        nonProfits(nonProfitIds: $nonProfitIds) {
          edges {
            node {
              ...CauseOfChoiceNonProfit
            }
          }
        }
      }
    }
  }
  ${CauseOfChoiceNonProfit}
`;

export default NON_PROFIT_V2_QUERY;
