import * as React from 'react';
import { Tab, Text, Icons } from '@onehope/design-system-v2';
import styled from '@emotion/styled';

const { AlertCircleOutline } = Icons;

import { CEDash_EventDetailsPage_viewer_event } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { getHostKitsV2 } from '../../../utils/utils';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';

import { Container, TabsContainer } from './TabNavigation.styles';
import {
  config,
  satelliteEventConfig,
  oldConfig,
  virtualWineTastingConfig,
} from './config';
import { EventTypeEnum } from '../../EventsV2/eventTypes';

type TabNavigationProps = {
  handleChange: (event: React.FormEvent<EventTarget>, value: string) => void;
  tabValue: string;
  event: CEDash_EventDetailsPage_viewer_event;
};

export const StyledTab = styled(Text)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;

export const Alert = styled(AlertCircleOutline)`
  && {
    margin-left: 8px;
  }
`;

function TabNavigation(props: TabNavigationProps) {
  const { handleChange, tabValue, event } = props;

  const isNotVirtual = event?.trinityPartyType !== 'EPARTY';
  const isVirtualWineTasting =
    event?.trinityPartyType === 'EPARTY' &&
    event?.eventType === EventTypeEnum.WineTasting;

  const showHostKitsV2 = getHostKitsV2(true, event);
  const satelliteEvent = isSatelliteEvent(event);

  const getConfig = () => {
    if (satelliteEvent) {
      return satelliteEventConfig;
    }
    if (showHostKitsV2) {
      // hide the Tasting Kit tab if not a virtual
      // event or if the order is older than Tasting Kit ff
      return isVirtualWineTasting
        ? virtualWineTastingConfig()
        : config(isNotVirtual);
    }
    return oldConfig;
  };

  const configToUse = getConfig();
  return (
    <Container>
      <TabsContainer
        key="TabNavigation"
        value={tabValue}
        scrollButtons={false}
        variant="scrollable"
        onChange={handleChange}
      >
        {configToUse.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            value={tab.value}
            label={<StyledTab>{tab.label}</StyledTab>}
            data-cypress={tab.cypressData}
          />
        ))}
      </TabsContainer>
    </Container>
  );
}

export default TabNavigation;
