import gql from 'graphql-tag';
import { CauseOfChoiceNonProfit } from './NonProfitV2';

const GET_EVENTS_V2_QUERY = gql`
  query CEDash_EventsV2Query(
    $page: Int
    $filterBy: String
    $sortBy: String
    $desc: Boolean
    $rowsPerPage: Int
    $searchBy: String
  ) {
    viewer {
      id
      user {
        id
        numberOfAvailableCredits
        availableStarterKitCredits
        eventsV2Count(filterBy: $filterBy, searchBy: $searchBy)
        eventsV2(
          page: $page
          filterBy: $filterBy
          sortBy: $sortBy
          desc: $desc
          rowsPerPage: $rowsPerPage
          searchBy: $searchBy
        ) {
          edges {
            node {
              id
              status
              trinityPartyStatus
              trinityPartyId
              exigoPartyId
              eventId
              eventDate
              closeDate
              hostFirstName
              hostLastName
              hostFullName
              eventVirtualLink
              hostNonProfitName
              trinityPartyType
              hostKitOrderNumbers
              hostKitQuantity
              timeZone
              hostQuote
              eventType
              eventAddress {
                city
                state
              }
              parentEventId
              donationId
              donationInfo {
                id
                ordersSubTotal
                donationAmount
                hostKitDonation
                donationType
                donationGoal
                status
                lobMemo
                nonProfit {
                  id
                  nonProfitName
                  nonProfitTaxId
                  nonProfitId
                  isPreApproved
                  nonProfitAddress {
                    optionalName
                    addressLineOne
                    addressLineTwo
                    city
                    state
                    zip
                    phoneNumber
                  }
                }
                nonProfitV2 {
                  ...CauseOfChoiceNonProfit
                }
              }
              title
            }
          }
        }
      }
    }
  }
  ${CauseOfChoiceNonProfit}
`;

export default GET_EVENTS_V2_QUERY;
