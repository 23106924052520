import gql from 'graphql-tag';
import { EventFragment } from '../../queries/Event';

export default gql`
  mutation DonationAddMutation($input: DonationAddInput!) {
    donationAdd(input: $input) {
      event {
        ...Event_event
      }
    }
  }
  ${EventFragment}
`;
