import React from 'react';
import {
  styled,
  useTheme,
  useMediaQuery,
  Dialog as DialogRoot,
  DialogActions,
  DialogTitle,
  Text,
  TEXT_DS,
  Button,
} from '@onehope/design-system-v2';

import { useContactsContext } from '../ContactsContext';

const Dialog = styled(DialogRoot)<{ isMobile: boolean }>`
  &.MuiDialog-root {
    padding: ${({ isMobile }) => (isMobile ? '0' : '30px 20px')};
    width: ${({ isMobile }) => (isMobile ? '288px' : '100%')};
    max-width: 760px;
    margin: 0 auto;
    z-index: 9000 !important;
  }
`;

const Content = styled('div')<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '288px' : '600px')};
`;

const DialogTitleWrapper = styled(DialogTitle)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) =>
    isMobile ? '24px 24px 8px 24px' : '48px 48px 8px 48px'};
`;

const DialogContentWrapper = styled(DialogTitle)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) =>
    isMobile ? '0px 24px 20px 24px' : '0px 48px 24px 48px'};
`;

const DialogActionsWrapper = styled(DialogActions)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) =>
    isMobile ? '0px 24px 24px 24px' : '0px 48px 48px 48px'};
`;

const IsCeWarningModal = () => {
  const theme = useTheme();
  const { toggleCEWarningState, ceWarningOpen } = useContactsContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const handleClose = () => {
    toggleCEWarningState(false);
  };

  return (
    <Dialog
      open={ceWarningOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      isMobile={isMobile}
    >
      <Content isMobile={isMobile}>
        <DialogTitleWrapper isMobile={isMobile}>
          <Text
            variant="custom"
            default={isMobile ? TEXT_DS.TITLE_LIGHT_24 : TEXT_DS.TITLE_LIGHT_32}
          >
            Oops!
          </Text>
        </DialogTitleWrapper>
        <DialogContentWrapper isMobile={isMobile}>
          <Text
            variant="custom"
            default={isMobile ? TEXT_DS.BODY_LIGHT_14 : TEXT_DS.BODY_LIGHT_16}
          >
            Looks like this contact is a Wine Rep. You are not able to
            shop or edit their profile for them.
          </Text>
        </DialogContentWrapper>
        <DialogActionsWrapper isMobile={isMobile}>
          <Button type="primary" fullWidth onClick={handleClose}>
            Close
          </Button>
        </DialogActionsWrapper>
      </Content>
    </Dialog>
  );
};

export default IsCeWarningModal;
