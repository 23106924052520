import * as React from 'react';
import { DateTime } from 'luxon';
import { Tooltip, Spacer, Icons, styled } from '@onehope/design-system-v2';
import { styles as s } from '@onehope/design-system';
import { getTrackingLinks } from '@onehope/utils';
import IconMenu from './IconMenu';
import { getOrderStatusIcon } from './helpers';
import { onHandleTrackOrder } from '../../utils/utils';
const { RefreshRightArrow } = Icons;

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges_node as OrderType } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';
const { cssConstants } = s;

const GridItem = styled('div')<{ orderCount: number }>`
  position: relative;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 16px;
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    max-width: ${({ orderCount }) => (orderCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const HostContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HostName = styled('div')`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const TypeIcon = styled('span')({
  height: '20px',
  width: '20px',
  svg: {
    height: '20px',
    width: '20px',
  },
});

const OrderStatusIconContainer = styled('div')`
  display: flex;
  align-items: center;
  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }
`;

const GridBlock = styled('div')`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const GridText = styled('span')`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const GridContainer = styled('div')``;

interface OrderCardProps {
  order: OrderType | null;
  orderCount: number;
}

const handleCardClick = (order: OrderType | null) => {
  const GATSBY_HOPECOMMERCE_URL = process.env.GATSBY_HOPECOMMERCE_URL;
  return () => {
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${order?.orderId}`,
      '_blank',
    );
  };
};

export default function OrderCard(props: OrderCardProps) {
  const { order, orderCount } = props;
  if (order) {
    const onCardClick = handleCardClick(order);
    const { icon, status } = getOrderStatusIcon(order.orderStatus);
    const shipments = order?.shipments;
    const trackingLinks = getTrackingLinks(shipments);
    const handleTrackOrder = onHandleTrackOrder(trackingLinks, order?.orderId);
    return (
      <GridItem orderCount={orderCount}>
        <IconMenu
          orderId={order.orderId}
          isCardView={true}
          trackingLinks={trackingLinks}
          handleTrackOrder={handleTrackOrder}
        />
        <GridContainer onClick={onCardClick}>
          <HostContainer>
            <HostName>{order.accountFullName}</HostName>
            {order.isAutoShipOrder && (
              <Tooltip title="Wine Club" placement="top">
                <TypeIcon>
                  <RefreshRightArrow />
                </TypeIcon>
              </Tooltip>
            )}
          </HostContainer>
          <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
          <GridBlock>
            Ordered:{' '}
            {order.createDate && (
              <GridText>
                {DateTime.fromISO(order.createDate).toLocaleString(
                  DateTime.DATE_FULL,
                )}
              </GridText>
            )}
          </GridBlock>
          <GridBlock>
            Total: <GridText>${order.orderTotal}</GridText>
          </GridBlock>
          {/*<GridBlock>*/}
          {/*  Quantity: <GridText>{order.orderItemsQty}</GridText>*/}
          {/*</GridBlock>*/}
          <GridBlock>
            Order #: <GridText>{order.orderNumber}</GridText>
          </GridBlock>
          <GridBlock>
            <GridText>
              <OrderStatusIconContainer>
                {icon}
                {status}
              </OrderStatusIconContainer>
            </GridText>
          </GridBlock>
        </GridContainer>
      </GridItem>
    );
  }
  return null;
}
