import React from 'react';
import styled from '@emotion/styled';

import { styles as s } from '@onehope/design-system';
import {
  Icons,
  makeStyles,
  ThemeType,
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@onehope/design-system-v2';

const { Close, CheckCircleIcon, ErrorIcon, InfoIcon, WarningIcon } = Icons;

const Notification = styled(Snackbar)<{ transform: number }>`
  && {
    &.MuiSnackbar-anchorOriginBottomCenter {
      transform: ${({ transform }: { transform: number }) =>
        transform ? `translateX(-${transform}%)` : `translateX(-15%)`};
      @media only screen and (max-width: 1200px) {
        transform: translateX(-50%);
      }
      @media only screen and (max-width: 600px) {
        transform: translateX(0%);
      }
    }
  }
`;

const { cssConstants } = s;

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: ThemeType) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: cssConstants.successColor,
  },
  error: {
    backgroundColor: cssConstants.errorColor,
  },
  info: {
    backgroundColor: cssConstants.linkColor,
  },
  warning: {
    backgroundColor: cssConstants.warningColor,
  },
}));

export interface Props {
  message?: string;
  open: boolean;
  handleClose?: () => void;
  variant: keyof typeof variantIcon;
  transform?: number;
}

export default function MySnackbarContentWrapper(props: Props) {
  // @ts-ignore
  const classes = useStyles();
  const { message, handleClose, variant, open, transform, ...other } = props;

  return (
    <Notification
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      transform={transform}
    >
      <SnackbarContent
        className={classes[variant] || classes.success}
        aria-describedby="client-snackbar"
        message={<span id="client-snackbar">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <Close />
          </IconButton>,
        ]}
        {...other}
      />
    </Notification>
  );
}
