import React, { useEffect, ReactElement } from 'react';

// material-ui design-system
import {
  useTheme,
  Box,
  useMediaQuery,
  ToolBar,
} from '@onehope/design-system-v2';

// contexts
import { useAppGlobalContextValue } from '../../contexts/AppGlobalContext/context';
// project import
import Drawer from './Drawer';
import Header from './Header';

// types
import { TypeKeys } from '../../contexts/AppGlobalContext/types';

// ==============================|| MAIN LAYOUT ||============================== //

interface Props {
  children: ReactElement | null;
  rewardBadgeAnimationAmount?: number;
  triggerRewardBadgeAnimation?: boolean;
  setTriggerRewardBadgeAnimation?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export default function MainLayout({
  children,
  rewardBadgeAnimationAmount,
  triggerRewardBadgeAnimation,
  setTriggerRewardBadgeAnimation,
}: Props) {
  const [{ drawerOpen }, dispatch] = useAppGlobalContextValue();
  const theme = useTheme();

  const getLiveChat = () => {
    if (typeof window === 'undefined') {
      return false;
    }
    const { pathname } = window.location;
    return pathname.includes('/chat');
  };

  const isLiveChat = getLiveChat();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  // drawer toggle
  const handleDrawerToggle = () => {
    dispatch({
      type: TypeKeys.TOGGLE_DRAWER,
      drawerOpen: !drawerOpen,
    });
  };

  // set media wise responsive drawer
  useEffect(() => {
    dispatch({
      type: TypeKeys.TOGGLE_DRAWER,
      drawerOpen: !matchDownLG,
    });
  }, [dispatch, matchDownLG]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {!isLiveChat && (
        <Header
          handleDrawerToggle={handleDrawerToggle}
          rewardBadgeAnimationAmount={rewardBadgeAnimationAmount}
          triggerRewardBadgeAnimation={triggerRewardBadgeAnimation}
          setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
        />
      )}
      {!isLiveChat && <Drawer handleDrawerToggle={handleDrawerToggle} />}
      <Box
        component="main"
        sx={{
          width: isLiveChat ? '100%' : 'calc(100% - 260px)',
          flexGrow: 1,
        }}
      >
        {!isLiveChat && <ToolBar />}
        <Box
          sx={{
            position: 'relative',
            minHeight: isLiveChat ? '100vh' : 'calc(100vh - 120px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
