import React, { useCallback } from 'react';
import { ApolloProvider, ApolloConsumer } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { CauseOfChoiceModal } from '@onehope/cause-of-choice-modal';

/* Helpers */
import { client } from '../../apollo/client';
import { ohwImageUploads } from '../../utils/constants';
import { urlProfileV2 } from '../../utils/utils';
import USER_CAUSE_OF_CHOICE_GET from '../../queries/UserCauseOfChoice';
import { useTrackingContextValue } from '../../contexts/TrackingContext';
import { segmentEvents } from '../../utils/segment/constants';

enum CauseOfChoiceAppDictionary {
  OHW_MEGALITH,
  OHW_CE_DASH,
  OHW_CE_ENROLLMENT,
  OHW_CHECKOUT,
}

enum CauseOfChoiceButtonDictionary {
  CE_CAUSE,
  NON_PROFIT,
  FUNDRAISER,
  NOT_SURE,
}

export enum CauseOfChoiceScreenDictionary {
  HOME_SCREEN,
  NON_PROFIT_SEARCH_SCREEN,
  MANUAL_NON_PROFIT_SCREEN,
  FUNDRAISER_SEARCH_SCREEN,
  NON_PROFIT_CONFIRMATION_SCREEN,
  NON_SURE_CONFIRMATION_SCREEN,
  FUNDRAISER_CONFIRMATION_SCREEN,
  CE_CAUSE_CONFIRMATION_SCREEN,
}

export enum CauseOfChoiceViewsDictionary {
  DEFAULT_VIEW,
  CE_PROFILE_VIEW,
}

export enum CauseOfChoiceTypeKeys {
  FUNDRAISER = 'fundraiser',
}

export enum CauseOfChoiceLocationKeys {
  CE_DASHBOARD = 'ceDashboard',
}

const cocModalButtons = [
  CauseOfChoiceButtonDictionary.NON_PROFIT,
  CauseOfChoiceButtonDictionary.FUNDRAISER,
  CauseOfChoiceButtonDictionary.NOT_SURE,
];

const cocModalInitialScreen = CauseOfChoiceScreenDictionary.HOME_SCREEN;

interface CauseOfChoiceModalProps {
  handleCloseModal: () => void;
  handleOpenModal: () => void;
  causeOfChoiceModalOpen: boolean;
}

export default function CauseOfChoiceModalWrapper(
  props: CauseOfChoiceModalProps,
) {
  const { handleCloseModal, causeOfChoiceModalOpen } = props;
  const { data, loading } = useQuery(USER_CAUSE_OF_CHOICE_GET);
  const { trackEventV2 } = useTrackingContextValue();
  const trackUpdateCauseOfChoice = useCallback(
    trackProps => {
      trackEventV2({
        event: segmentEvents.causeOfChoiceUpdated,
        properties: trackProps,
      });
    },
    [trackEventV2],
  );

  if (!data || loading) return null;
  const user = data?.viewer?.user;
  return (
    <ApolloProvider client={client}>
      <ApolloConsumer>
        {() => (
          <CauseOfChoiceModal
            app={CauseOfChoiceAppDictionary.OHW_CE_DASH}
            user={user}
            modalView={CauseOfChoiceViewsDictionary.DEFAULT_VIEW}
            modalLocation={CauseOfChoiceLocationKeys.CE_DASHBOARD}
            buttons={cocModalButtons}
            urlProfileImg={urlProfileV2}
            initialScreen={cocModalInitialScreen}
            open={causeOfChoiceModalOpen}
            handleCloseModal={handleCloseModal}
            urlEventImgUploads={ohwImageUploads}
            assetUrl={process.env.GATSBY_S3_ASSETS}
            trackUpdateCauseOfChoice={trackUpdateCauseOfChoice}
          />
        )}
      </ApolloConsumer>
    </ApolloProvider>
  );
}
