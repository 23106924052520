import React from 'react';
import { useHits, Configure, useSearchBox } from 'react-instantsearch';
import { useMutation } from '@apollo/client';
import {
  AlgoliaHit,
  ProductGrid,
  RefineBar,
} from '@onehope/algolia-components';
import {
  Dialog,
  Button,
  makeStyles,
  AppBar,
  AppBarProps,
  styled,
  Text,
  TEXT_DS,
} from '@onehope/design-system-v2';

// @ts-ignore
import { debounce } from './debounce';

import useToggle from '../../utils/useToggleHook';
import {
  updateObjectInArray,
  updatedProductOrdering,
} from '../../utils/helpers';
import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../utils/segment';

import AUTO_SHIP_CHANGE_WINES_MUTATION from '../../mutations/AutoshipChangeWinesMutation';
import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_userInfo,
  WineClub_AutoShipGetQuery_viewer_user_autoShip_winePackItemsSavings_edges as ProductType,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import { WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify } from '../../queries/generatedTypes/WineClub_SegmentIdentifyGetQuery';
import SwapProduct from './SwapProduct';

const useDialogStyles = makeStyles(() => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
  },
}));

const HeaderContainer = styled('div')`
  padding: 0 16px;
`;

const DialogInnerContainer = styled('div')`
  max-width: 100%;
  margin: 0 auto;
  padding-top: 60px;

  @media all and (min-width: 768px) {
    max-width: 1040px;
  }

  .product-grid {
    margin-top: 24px;

    @media all and (min-width: 768px) {
      margin-top: 40px;
    }
  }
`;

const Container = styled('div')({
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: 0,
  ['@media all and (min-width: 768px)']: {
    maxWidth: '736px',
  },
});

const InlineSpacer = styled('span')({
  width: '32px',
});

const StyledAppBar = styled((props: AppBarProps) => <AppBar {...props} />)(
  () => ({
    backgroundColor: 'white',
    padding: 16,
  }),
);

const StyledButton = styled(Button)({
  borderRadius: '1px !important',
});

const appBarSx = {
  top: 'auto',
  bottom: 0,
};

const SwapButton = styled('div')({
  paddingRight: 8,
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
});

const handleChangeWinesUpdate = ({
  autoShipId,
  winePackItems,
  AutoShipChangeWinesMutation,
  setClosed,
  setUpdateMutationComplete,
  setUpdateMutationLoading,
  user,
  setSelectedProductId,
  setSelectedProduct,
  refetchSubscription,
  selectedProductId,
  selectedProduct,
  currentProductId,
  currentProduct,
  setHasSwapped,
  index,
  conciergeMode,
  sendToTrackEventV2,
}: {
  autoShipId: string;
  winePackItems: Array<Item>;
  AutoShipChangeWinesMutation: any;
  setClosed: () => void;
  setSelectedProductId: (input: string) => void;
  setSelectedProduct: React.Dispatch<React.SetStateAction<AlgoliaHit | null>>;
  setUpdateMutationLoading: () => void;
  setUpdateMutationComplete: () => void;
  user: WineClub_AutoShipGetQuery_viewer_user;
  refetchSubscription: () => void;
  selectedProductId: string;
  selectedProduct: AlgoliaHit | null;
  currentProductId: string;
  currentProduct: ProductType | null;
  setHasSwapped: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
}) => {
  return () => {
    const updatedWinePackItems = updateWinePackItems(
      winePackItems,
      selectedProductId,
      currentProductId,
    );
    setUpdateMutationLoading();
    const variables = {
      autoShipId,
      input: {
        autoShipId,
        winePackItems: updatedWinePackItems,
        eventType: 'EVENT_WINE_SWAPPED',
      },
    };
    return AutoShipChangeWinesMutation({
      variables,
    })
      .then(() => {
        // send to segment
        sendToTrackEventV2 &&
          sendToTrackEventV2({
            event: SubscriptionSegmentEvents.wineClubModified,
            properties: {
              concierge: conciergeMode,
              autoShipId,
              accountId: user?.userId,
              ceAccountId: conciergeMode ? user?.userId : '',
              propertyUpdated: SubscriptionSegmentProperty.wineSwapped,
              values: {
                previousValue: {
                  productId: currentProductId,
                  msrp: currentProduct?.node?.priceOriginal,
                },
                newValue: {
                  productId: selectedProductId,
                  msrp: selectedProduct?.priceUSD,
                },
              },
            },
          });
        setClosed();
        setSelectedProductId('');
        setSelectedProduct(null);
        updatedProductOrdering(
          winePackItems,
          autoShipId,
          selectedProductId,
          currentProductId,
          index,
        );
        refetchSubscription();
        setUpdateMutationComplete();
        setHasSwapped(true);
      })
      .catch((error: any) => {
        console.log('error', error);
        setUpdateMutationComplete();
      });
  };
};

type Item = {
  productId: string;
  quantity: number;
};

function updateWinePackItems(
  winePackItems: Array<Item>,
  selectedProductId: string,
  currentProductId: string,
) {
  return updateObjectInArray(winePackItems, {
    item: { productId: selectedProductId, quantity: 1 },
    currentProductId,
  });
}

export const getWinePackItems = (
  winePackItems: (ProductType | null)[] | null | undefined,
) => {
  return winePackItems
    ? winePackItems?.map((item: any) => {
        return { productId: item.node.productId, quantity: 1 };
      })
    : [];
};

type SwapWinesDialogProps = {
  segmentUser:
    | WineClub_AutoShipGetQuery_viewer_user
    | WineClub_AutoShipGetQuery_viewer_userInfo;
  segmentPersonas: WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify;
  user: WineClub_AutoShipGetQuery_viewer_user;
  currentProductId: string;
  handleNotificationOpen: (input?: string) => void;
  handleNotificationClose: () => void;
  winePackItems: Array<ProductType>;
  autoShipId: string;
  refetchSubscription: () => void;
  setHasSwapped: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
};

export default function SwapWinesDialog(props: SwapWinesDialogProps) {
  const { hits } = useHits<AlgoliaHit>();
  const { query, refine } = useSearchBox();

  const dialogClasses = useDialogStyles();

  const winePackItems = getWinePackItems(props.winePackItems);

  const {
    value: updateMutationLoading,
    setTrue: setUpdateMutationLoading,
    setFalse: setUpdateMutationComplete,
  } = useToggle();

  const { value: open, setTrue: setOpen, setFalse: setClosed } = useToggle();

  const [selectedProductId, setSelectedProductId] = React.useState('');
  const [selectedProduct, setSelectedProduct] =
    React.useState<AlgoliaHit | null>(null);

  const {
    user,
    currentProductId,
    handleNotificationClose,
    refetchSubscription,
    setHasSwapped,
    index,
    conciergeMode,
    sendToTrackEventV2,
  } = props;

  const currentProduct =
    props.winePackItems.find(
      (winePackItem: ProductType) =>
        winePackItem.node?.productId === currentProductId,
    ) || null;

  const [AutoShipChangeWinesMutation] = useMutation(
    AUTO_SHIP_CHANGE_WINES_MUTATION,
  );

  const dispatchHandleChangeWinesUpdate = handleChangeWinesUpdate({
    AutoShipChangeWinesMutation,
    winePackItems,
    autoShipId: props.autoShipId,
    setClosed,
    setSelectedProductId,
    setSelectedProduct,
    setUpdateMutationLoading,
    setUpdateMutationComplete,
    user,
    refetchSubscription,
    selectedProductId,
    selectedProduct,
    currentProductId,
    currentProduct,
    setHasSwapped,
    index,
    conciergeMode,
    sendToTrackEventV2,
  });

  function handleClose() {
    setClosed();
    setSelectedProductId('');
    setSelectedProduct(null);
  }

  function handleClickOpen() {
    handleNotificationClose();
    setOpen();
  }

  const handleQueryChange = debounce((query: string) => {
    refine(query);
  }, 500);

  return (
    <div>
      <SwapButton onClick={handleClickOpen}>
        <Text variant="custom" default={TEXT_DS.BODY_LINK_14}>
          Swap
        </Text>
      </SwapButton>
      <Dialog
        fullScreen
        open={open}
        classes={dialogClasses}
        onClose={handleClose}
      >
        <DialogInnerContainer>
          <HeaderContainer>
            <Text
              variant="custom"
              default={TEXT_DS.TITLE_LIGHT_32}
              mobile={TEXT_DS.TITLE_LIGHT_24}
            >
              Wine Swap
            </Text>
          </HeaderContainer>

          <RefineBar
            handleQueryChange={handleQueryChange}
            refine={refine}
            query={query}
          />

          <Configure
            filters="isBYOEligible:true OR isWineClubExclusive:true"
            hitsPerPage={50}
          />
          <ProductGrid>
            {hits.map((hit: AlgoliaHit) => (
              <SwapProduct
                key={hit.objectID}
                hit={hit}
                user={user}
                mutationLoading={updateMutationLoading}
                selectedProductId={selectedProductId}
                setSelectedProductId={setSelectedProductId}
                setSelectedProduct={setSelectedProduct}
              />
            ))}
          </ProductGrid>

          <StyledAppBar sx={appBarSx}>
            <Container>
              <StyledButton
                type="secondary"
                fullWidth
                disabled={updateMutationLoading}
                onClick={handleClose}
              >
                CANCEL
              </StyledButton>
              <InlineSpacer />
              <StyledButton
                type="primary"
                fullWidth
                disabled={updateMutationLoading || selectedProductId === ''}
                loading={updateMutationLoading}
                onClick={dispatchHandleChangeWinesUpdate}
              >
                Save Swap
              </StyledButton>
            </Container>
          </StyledAppBar>
        </DialogInnerContainer>
      </Dialog>
    </div>
  );
}
