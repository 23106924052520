import { gql } from '@apollo/client';

const GOOGLE_PLACES_ZIP_MUTATION = gql`
  mutation CauseOfChoiceGooglePlacesZipMutation($input: GooglePlacesZipInput!) {
    googlePlacesZip(input: $input) {
      zip
    }
  }
`;

export default GOOGLE_PLACES_ZIP_MUTATION;
