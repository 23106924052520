import { gql } from '@apollo/client';

const CE_EVENTS_COUNT_QUERY = gql`
  query CeEventsCountQuery($ceAccountId: String) {
    viewer {
      id
      user {
        id
        eventsV2Count(ceAccountId: $ceAccountId, acceptingOrdersOnly: true)
      }
    }
  }
`;

export default CE_EVENTS_COUNT_QUERY;
