import * as React from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';

import EventCard from './EventCard';
import {
  CEDash_EventsQuery_viewer_user_events_edges as EventEdge,
  CEDash_EventsQuery_viewer_user_events_edges as EventsType,
} from '../../../../queries/generatedTypes/CEDash_EventsQuery';

const { cssConstants } = s;

const Grid = styled.div``;

const Container = styled.div``;

const GridList = styled.div<{ eventCount: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 24px;
  grid-auto-flow: dense;
  max-width: ${({ eventCount }) => (eventCount < 3 ? '756px' : '100%')};
  @media all and (min-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
  }
`;

interface EventsProps {
  events: (EventsType | null)[] | null;
  page: number;
  rowsPerPage: number;
  contactId?: string | null | undefined;
  setSelectedEventId: (eventId: string) => void;
  handleOrderAdd: (eventId: string) => void;
  toggleEventCopyUrlDialog: (eventId: string | null | undefined) => void;
}
export default function Events(props: EventsProps) {
  const {
    page,
    rowsPerPage,
    setSelectedEventId,
    handleOrderAdd,
    toggleEventCopyUrlDialog,
    contactId,
  } = props;
  const events = props.events?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const eventCount = (events && events.length) || 0;

  return (
    <Container>
      <Grid>
        <GridList eventCount={eventCount}>
          {events?.map((event: EventEdge | null) => (
            <EventCard
              key={`${event?.node?.id}`}
              event={event?.node}
              eventCount={eventCount}
              contactId={contactId}
              setSelectedEventId={setSelectedEventId}
              handleOrderAdd={handleOrderAdd}
              toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
            />
          ))}
        </GridList>
      </Grid>
    </Container>
  );
}
