import React, { Dispatch, SetStateAction, FormEvent } from 'react';
import { ContactType } from '../../../ContactsV3/ContactsContext';
import Details from './Details';
import Orders from './Orders';
import Events from './Events';
import WineClub from './WineClub';
import { ContactManagementTabs } from './tabConstants';

interface TabContentProps {
  tabValue: string;
  contact: ContactType;
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  handleChange: (event: FormEvent<EventTarget>, value: string) => void;
}

export default function TabContent({
  tabValue,
  contact,
  editing,
  setEditing,
  handleChange,
}: TabContentProps) {
  switch (tabValue) {
    case ContactManagementTabs.WINECLUB:
      return <WineClub contact={contact} />;
    case ContactManagementTabs.ORDERS:
      return <Orders contact={contact} />;
    case ContactManagementTabs.EVENTS:
      return <Events contact={contact} />;
    default:
      return (
        <Details
          editing={editing}
          setEditing={setEditing}
          contact={contact}
          handleChange={handleChange}
        />
      );
  }
}
