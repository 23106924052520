import React from 'react';

const SvgMinus = (props: any) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M25 21H15V19H25V21Z" fill="#1A1A1A" />
  </svg>
);

export default SvgMinus;
