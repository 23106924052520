// @ts-ignore
import * as Yup from 'yup';

export const WineFormSchema = Yup.object().shape({
  wineNeeded: Yup.string().required('Required'),
  // hostKitCredits: Yup.string().when('useCredit', {
  //   is: (quantity: any) => {
  //     return quantity === 'NO';
  //   },
  //   then: Yup.string().oneOf(['0', '1']),
  //   otherwise: Yup.string().oneOf(['2', '3', '4']),
  // }),
});
