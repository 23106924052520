import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import { Box, useTheme } from '@onehope/design-system-v2';
import { ActionEngineFeedContextProvider } from '@onehope/action-engine';

import Dashboard from './Dashboard';
import GET_DASHBOARD_QUERY from '../../queries/Dashboard';
import Loading from '../../common/Loading';

import { getPageTitle } from '../../utils/site';
import getClient from '../../apollo-client';
import { CEDash_DashboardQuery } from '../../queries/generatedTypes/CEDash_DashboardQuery';

interface DashBoardProps {
  setRewardBadgeAnimationAmount: React.Dispatch<React.SetStateAction<number>>;
  setTriggerRewardBadgeAnimation: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardPage = ({
  setRewardBadgeAnimationAmount,
  setTriggerRewardBadgeAnimation,
}: DashBoardProps) => {
  const theme = useTheme();
  const [componentDidMount, setComponentDidMount] = useState(false);
  const [errorOccurences, setErrorOccurences] = useState(0);

  const [goToCardId, setGoToCardId] = useState('');

  const { loading, error, data, stopPolling, refetch } = useQuery<
    CEDash_DashboardQuery
  >(GET_DASHBOARD_QUERY, {
    pollInterval: 500,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const { cardId, dialog } = queryString.parse(window.location.search);
    if (cardId && dialog) {
      setGoToCardId(cardId.toString());
    }
  }, []); // Runs only once because of empty dependency array

  useEffect(() => {
    setComponentDidMount(true);
  }, []); // Runs only once because of empty dependency array

  const pageInfo = () => (
    <Helmet>
      <title>{getPageTitle('Home')}</title>
    </Helmet>
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    const errorCount = errorOccurences;

    // error threshold before stopping call to get data, try 3 times
    if (errorCount > 2) {
      stopPolling();
      return <div>Error! ${error.message}</div>;
    } else {
      setErrorOccurences(errorCount + 1);
      refetch();
      return <Loading />;
    }
  }

  const {
    ceImpactGoals,
    ceId,
    mentorInfo,
    customUrl,
    fastStartEndDate,
    ...dashboardInfo
  } = getCeDashboardInfo(data);

  if (componentDidMount || (mentorInfo && customUrl && fastStartEndDate)) {
    stopPolling();
  } else {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.cream.main,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        minHeight: '100%',
        p: { xs: 2, md: 3 },
        mt: { xs: 1, sm: 0 },
      }}
    >
      {pageInfo}
      <ActionEngineFeedContextProvider apolloClient={getClient()}>
        <Dashboard
          dashboardInfo={dashboardInfo}
          goToCardId={goToCardId}
          setRewardBadgeAnimationAmount={setRewardBadgeAnimationAmount}
          setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
        />
      </ActionEngineFeedContextProvider>
    </Box>
  );
};

export default DashboardPage;

function getCeDashboardInfo(data: CEDash_DashboardQuery) {
  if (!data) {
    return {};
  }

  const { user } = data.viewer;
  const { ce, customUrl, fastStartEndDate } = user;

  const ceFromUser = ce || {
    ceImpactData: null,
    ceImpactGoals: null,
    ceId: null,
    mentorInfo: null,
  };

  const {
    ceImpactData,
    ceImpactGoals: ceImpactGoalsData,
    ceId,
    mentorInfo,
  } = ceFromUser;

  const ceImpactGoals = {
    ...ceImpactGoalsData,
    goals: ceImpactGoalsData
      ? formatImpactGoalData(ceImpactGoalsData.goals)
      : formatImpactGoalData([]),
  };

  let fastStartCheck = checkValidDates(
    user.fastStartEndDate,
    user.trinityEnrollDate,
  );

  return {
    user,
    ceImpactData,
    ceImpactGoals,
    ceId,
    fastStartCheck,
    mentorInfo,
    customUrl,
    fastStartEndDate,
  };
}

function checkOldDate(date: Date, now = new Date()) {
  return date.getTime() > now.getTime();
}

function checkValidDates(
  fastStartDate: string,
  trinityDate: string,
  fastStartEndDate = new Date(fastStartDate),
) {
  if (fastStartDate && trinityDate) {
    return checkOldDate(fastStartEndDate);
  }
  return false;
}

function formatImpactGoalData(goalData = []) {
  const goalDataArray = goalData?.length ? [...goalData] : [];

  const now = new Date();
  const month = now.toLocaleString('default', { month: 'long' }).toLowerCase();
  const year = now.toLocaleString('default', { year: 'numeric' });
  const monthlyDataTemplate = { month, impactGoal: '50.00' };

  const yearlyGoalData = goalDataArray.find(
    (data) => data.year === year && Array.isArray(data.months),
  );
  // goal data is missing year, add data for this month
  if (!yearlyGoalData) {
    goalDataArray.push({ year, months: [monthlyDataTemplate] });
    return goalDataArray;
  }

  const monthlyGoalData = yearlyGoalData.months.find(
    (data) => data.month === month,
  );
  if (!monthlyGoalData) {
    yearlyGoalData.months.push(monthlyDataTemplate);
  }
  return goalDataArray;
}
