import React from 'react';
import {
  ButtonBase,
  Button,
  Grid,
  Icons,
  Text,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';
/* UTILS */
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
/* STYLES */
import { useCommonStyles } from '../../../utils/commonStyles';
import { getCurrentUser } from '../../../utils/helper';
import {
  CauseOfChoiceAppDictionary,
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';

const OH_FOUNDATION_CAUSES = [
  'Building 3 schools in the developing world',
  'Planting a forest in Indonesia',
  'Funding over 3M meals for children in need',
  'Helping 83,000 pets find forever homes',
  'And so much more! ',
];

export default function NotSureConfirmation() {
  const {
    handleCloseModal,
    user: parentUser,
    assetUrl,
    onContinue,
    app,
    handleGoToShop,
  } = useCauseOfChoiceNetworkLayerValue();
  const [, dispatch] = useCauseOfChoiceContextValue();
  const user = getCurrentUser(parentUser);
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const guestModeNonProfit = user?.cartV2?.guestNonProfit;
  const selectedCauseOfChoice =
    guestModeNonProfit || user?.selectedCause?.nonProfit;
  const selectedCauseOfChoiceName = selectedCauseOfChoice?.nonProfitName;
  const subHeader = `Fantastic! ${selectedCauseOfChoiceName} will donate 10% of your purchase to a trusted nonprofit partner.`;

  const isMegalith = app === CauseOfChoiceAppDictionary.OHW_MEGALITH;
  const buttonText = isMegalith ? 'Shop' : onContinue ? 'Continue' : 'Close';
  const buttonOnClick = isMegalith
    ? handleGoToShop
    : onContinue
    ? onContinue
    : handleCloseModal;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.HOME_SCREEN,
    });
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={commonStyles.navigationAlt}
        >
          <ButtonBase onClick={handleGoBack}>
            <Grid xs item container alignItems="center" direction="row">
              <Icons.SmallLeftArrow />
              <Text className={commonStyles.backButtonText}>Back</Text>
            </Grid>
          </ButtonBase>
          <ButtonBase onClick={handleCloseModal}>
            <Icons.Close />
          </ButtonBase>
        </Grid>
      </Grid>
      <Text className={commonStyles.subHeaderText}>{subHeader}</Text>
      <Grid>
        <Text className={commonStyles.labelSubHeader}>
          We've proudly donated over $8 million to date to over 30,000 local and
          global causes including:
        </Text>
        <Grid container className={commonStyles.marginTop16} direction="column">
          {OH_FOUNDATION_CAUSES.map((c) => (
            <Text className={commonStyles.regularText}>
              <span className={commonStyles.listBullet}>•</span> {c}
            </Text>
          ))}
        </Grid>
      </Grid>
      {assetUrl && (
        <img
          src={`${assetUrl}/COC_NotSureConfirmationScreenImage${
            isMobile ? 'Mobile' : 'Desktop'
          }.jpg`}
          className={commonStyles.image}
          alt="OH Foundation"
        />
      )}
      <Grid container justifyContent="center">
        <Button
          fullWidth
          type="primary"
          className={commonStyles.saveAndClose}
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
        <Text className={commonStyles.italicText}>
          You can change your cause of choice at any point.
        </Text>
      </Grid>
    </Grid>
  );
}
