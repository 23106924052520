import gql from 'graphql-tag';

const ContactsV2UpdateMutation = gql`
  mutation ContactsV2Update($contactId: String!, $contact: ContactV2Input!) {
    contactsV2Update(input: { contactId: $contactId, contact: $contact }) {
      contact {
        accountId
        firstName
        lastName
        emailAddress
      }
    }
  }
`;
export default ContactsV2UpdateMutation;
