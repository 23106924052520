import React from 'react';
import styled from '@emotion/styled';

import { Loader, DialogActions } from '@onehope/design-system-v2';
import { MuiButton } from '@onehope/design-system-ohw';

import { Dialog, DialogTitleWrapper, DialogTextWrapper } from '../index.styles';

interface ExpiredCreditDialogProps {
  open: boolean;
  setClose: () => void;
  saveChange: () => void;
  mutationLoading: boolean;
}

const DialogActionsWrapper = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0;
  }
`;

export default function ExpiredCreditDialog(props: ExpiredCreditDialogProps) {
  const { open, setClose, saveChange, mutationLoading } = props;

  return (
    <Dialog
      open={open}
      /* eslint-disable react/jsx-no-bind */
      onClose={mutationLoading ? () => {} : setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleWrapper>Tasting Credit has Expired</DialogTitleWrapper>
      <DialogTextWrapper>
        The credit applied towards this event has expired and will not be
        available to apply towards a different event. Are you sure you want to
        remove this credit?
      </DialogTextWrapper>
      <DialogActionsWrapper>
        <MuiButton
          type="SECONDARY"
          disabled={mutationLoading}
          fullWidth
          onClick={setClose}
        >
          Cancel
        </MuiButton>
        <MuiButton
          type="PRIMARY"
          fullWidth
          disabled={mutationLoading}
          onClick={saveChange}
          color="primary"
          autoFocus
        >
          {mutationLoading && <Loader style={{ marginRight: 6 }} />}
          Yes, remove it
        </MuiButton>
      </DialogActionsWrapper>
    </Dialog>
  );
}
