import React, { FormEvent, useState } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { styles as s } from '@onehope/design-system';
import { ContactType } from '../../ContactsV3/ContactsContext';
import Breadcrumbs from '../../../common/Breadcrumbs';
import Header from './Header';
import TabNavigation from './TabNavigation';
import TabContent from './TabContent';

const { cssConstants } = s;

interface ContactDetailsPagepProps {
  contact: ContactType;
  tab: string;
}

const Container = styled.div`
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  background: white;
  padding: 24px 0 0 0;
  @media only screen and (min-width: 768px) {
    padding: 40px 0 0 0;
  }
`;

const ContactDetailsPage = ({ contact, tab }: ContactDetailsPagepProps) => {
  const { contactId, accountId } = contact;
  // check if contact has account and can access orders / events
  const hasAccount = !!accountId;
  // state management
  const [editing, setEditing] = useState(false);

  const handleChange = (e: FormEvent<EventTarget>, value: string) => {
    e.preventDefault();
    return navigate(`/contact?contactId=${contactId}&tab=${value}`);
  };

  return (
    <Container>
      <Breadcrumbs location="contact" />
      <Header editing={editing} contact={contact} handleChange={handleChange} />
      <TabNavigation
        tabValue={tab}
        handleChange={handleChange}
        hasAccount={hasAccount}
      />
      <TabContent
        tabValue={tab}
        contact={contact}
        editing={editing}
        setEditing={setEditing}
        handleChange={handleChange}
      />
    </Container>
  );
};

export default ContactDetailsPage;
