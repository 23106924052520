import { css } from 'emotion';

// Colors
export const colors = {
  blackBrand: '#1A1A1A',
  blueBrand: '#0091EA',
  greenAlert: '#00BFB2',
  yellowAlert: '#FFB600',
  redAlert: '#F4564E', // ? or is should it be ff4d4f
  pureWhite: '#FFFFFF',
  // Neutrals in terms of percentage (0=white, 100=black)
  neutral0: '#FFFFFF',
  neutral2: '#fafafa',
  neutral5: '#f2f2f2',
  neutral13: '#dddddd',
  neutral34: '#a9a9a9',
  netural80: '#333333',
  lightPurpleBrandOld: '#784CAB',
  darkPurpleBrandOld: '#43257C',
};

// Elevations
export const softShadow = '0 8px 20px 0 rgba(0, 0, 0, 0.15)';
export const mediumShadow = '0 3px 10px 0 rgba(0, 0, 0, 0.25)';
export const hardShadow = '0 1px 4px 0 rgba(0, 0, 0, 0.25)';

export const softElevation = css`
  && {
    box-shadow: ${softShadow};
  }
`;

export const mediumElevation = css`
  && {
    box-shadow: ${mediumShadow};
  }
`;

export const hardElevation = css`
  && {
    box-shadow: ${hardShadow};
  }
`;

// Scrims : TODO
