import { makeStyles, ThemeType } from '@onehope/design-system-v2';

type SharedStyles = {
  [key: string]: string | number | object;
};

const swipeButtonBase = (theme: ThemeType) => {
  return {
    borderRadius: '12px',
    padding: '12px',
    backgroundColor: 'white',
    boxShadow:
      '0px 10px 89px rgba(0, 0, 0, 0.07), 0px 4.50776px 11.1442px rgba(0, 0, 0, 0.035)',
    border: 'none',
    display: 'flex',
    paddingRight: '12px',
    fontFamily: theme.bodyFontFamily,
    letterSpacing: '0.1em',
    fontSize: '12px',
    height: '40px',
    fontWeight: 500,
    cursor: 'pointer',
  } as SharedStyles;
};

const cardWrapperBase = () => {
  return {
    borderRadius: '12px',
    maxWidth: '342px',
    height: '466px',
    margin: '16px auto',
    padding: '0px',
    // marginBottom: '36px',
    ['@media (max-width: 599px)']: {
      margin: '38px auto',
    },
  };
};

const stackBase = () => {
  return {
    boxShadow:
      '0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 6px 12px rgba(14, 31, 53, 0.08)',
    borderRadius: '12px',
    width: '325px',
    height: '11px',
    margin: 'auto',
    padding: '0px',
    marginTop: '-40px',
    marginBottom: '20px',
  };
};

const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    paddingBottom: '15px',
    position: 'relative',
    maxWidth: '342px',
    height: '466px',
    margin: 'auto',
  },
  wrapper: {
    ...cardWrapperBase(),
    boxShadow:
      '0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 6px 12px rgba(14, 31, 53, 0.08)',
    ['@media (max-width: 599px)']: {
      margin: '16px auto',
      maxWidth: '342px',
    },
  },
  singleCardWrapper: {
    ...cardWrapperBase(),
    marginBottom: '32px',
    boxShadow:
      '0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 6px 12px rgba(14, 31, 53, 0.08)',
    ['@media (max-width: 599px)']: {
      margin: '38px auto',
      maxWidth: '342px',
      marginBottom: '33px',
    },
  },
  stack: {
    ...stackBase(),
    marginTop: '-59px',
    height: '30px',
    ['@media (max-width: 599px)']: {
      marginTop: '-71px',
      height: '40px',
      margin: '38px auto',
      maxWidth: '328px',
      minWidth: '145px',
    },
  },
  singleStack: {
    ...stackBase(),
    marginBottom: '25px',
    ['@media (max-width: 599px)']: {
      marginTop: '-59px',
      height: '28px',
      margin: '38px auto',
      marginBottom: '26px',
      maxWidth: '328px',
      minWidth: '145px',
    },
  },
  deepStack: {
    boxShadow:
      '0px 1px 2px -2px rgb(14 31 53 / 12%), 0px 4px 2px -2px rgb(14 31 53 / 10%), 0px 6px 2px -2px rgb(14 31 53 / 8%)',
    borderRadius: '0px 0px 10.9155px 10.9155px',
    width: '312px',
    height: '29px',
    margin: 'auto',
    padding: '0px',
    marginTop: '-43px',
    marginBottom: '18px',
    ['@media (max-width: 599px)']: {
      height: '12px',
      maxWidth: '312px',
      minWidth: '234px',
    },
  },
  swipe: {
    position: 'absolute',
    width: 'inherit',
    maxWidth: '343px',
    height: '466px',
  },
  swipeCard: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '12px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '466px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  saveIconContainer: {
    marginTop: '1px',
    marginRight: '8px',
  },
  saveButton: {
    ...swipeButtonBase(theme),
    marginLeft: '12px',
    color: '#335E65',
    paddingBottom: '6px',
  },
  archiveIconContainer: {
    marginTop: '-3px',
    marginRight: '4px',
  },
  archiveButton: {
    ...swipeButtonBase(theme),
    color: '#B04A3D',
    paddingBottom: '2px',
  },
  image: {
    width: '342px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '186px',
    borderRadius: '12px 12px 0px 0px',
    objectFit: 'cover',
    ['@media (max-width: 599px)']: {
      maxWidth: '342px',
      width: '100%',
    },
  },
  player: {
    borderRadius: '12px 12px 0px 0px !important',
    height: '186px !important',
    width: '100%',
    ['@media (max-width: 599px)']: {
      height: '186px !important',
      maxWidth: '342px',
    },
    '& .poster': {
      borderRadius: '12px 12px 0px 0px !important',
    },
  },
  menuItem: {
    fontFamily: theme.bodyFontFamily,
    fontSize: '14px',
    fontWeight: 300,
    backgroundColor: 'white !important',
    '&:hover': {
      fontWeight: 500,
    },
  },
  bodyWrapper: {
    margin: '0 auto',
    height: '104px',
    borderRadius: '0px 0px 12px 12px',
    ['@media (max-width: 599px)']: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  titleWrapper: {
    display: 'block',
    marginBottom: '10px',
  },
  textWrapper: {
    height: '280px',
    width: '342px',
    textAlign: 'left',
    borderRadius: '0px 0px 12px 12px',
    padding: '16px',
    color: theme.palette.primary.main,
    ['@media (max-width: 599px)']: {
      width: 'auto',
      padding: '12px',
    },
  },
  playIcon: {
    height: '48px',
    width: '100%',
    backgroundColor: 'Transparent',
    marginTop: '-115px',
    textAlign: 'center',
    position: 'absolute',
    cursor: 'pointer',
  },
  imageContainer: {
    position: 'relative',
  },
  desktopImage: {
    display: 'block',
    borderRadius: '12px 12px 0px 0px !important',
    height: '186px !important',
    width: '100%',
    ['@media (max-width: 599px)']: {
      height: '186px !important',
      maxWidth: '343px',
    },
  },
  timeAgoContainer: {
    marginTop: '-54px',
    float: 'right',
  },
  timeAgo: {
    color: theme.palette.greyDark.main,
    fontFamily: theme.bodyFontFamily,
    fontSize: '16px',
  },
  cardDialogItemDetailsContainer: {
    ...cardWrapperBase(),
    boxShadow: '0 5px 7px 2px rgb(0 0 0 / 26%)',
    ['@media (max-width: 599px)']: {
      margin: '38px auto',
    },
  },
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: '12px',
  },
  cardImageContainer: {
    width: '342px',
    height: '186px',
    position: 'relative',
  },
  cardRewardBadge: {
    height: '24px',
    width: 'fit-content',
    background: '#EDE0BF',
    position: 'absolute',
    top: '16px',
    right: '16px',
    fontSize: '11px',
    padding: '4px 6px',
    fontFamily: theme.bodyFontFamily,
    borderRadius: '3px',
  },
}));

export default useStyles;
