import { DateTime } from 'luxon';

export const getFormattedDate = (date: string) => {
  return date
    ? DateTime.fromISO(date)
        .setLocale('en-US')
        .setZone('US/Central')
        .toFormat("MM/dd/yyyy h:mma 'CT'")
    : '';
};
