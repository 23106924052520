import React from 'react';

function ShippingError() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <defs>
        <path
          id="hy"
          d="M22.137 13.746l-1.617-2.497a1.568 1.568 0 00-1.307-.71h-2.588a1.55 1.55 0 00-.525.096v-.631c0-.886-.705-1.604-1.575-1.604h-10.5a.53.53 0 00-.525.535.53.53 0 00.525.534h10.5c.29 0 .525.24.525.535v6.95h-.604a2.106 2.106 0 00-2.026-1.578c-.948 0-1.778.646-2.027 1.577h-.068a.53.53 0 01-.525-.534v-1.07a.53.53 0 00-.525-.534.53.53 0 00-.525.535v1.069c0 .885.705 1.603 1.575 1.603h.074a2.106 2.106 0 002.026 1.578c.948 0 1.778-.646 2.026-1.578h2.247a2.106 2.106 0 002.027 1.578c.948 0 1.778-.646 2.026-1.578h.074c.87 0 1.575-.718 1.575-1.603v-1.813a1.624 1.624 0 00-.262-.86zm-9.712 4.811a1.06 1.06 0 01-1.05-1.07c0-.59.47-1.068 1.05-1.068.58 0 1.05.478 1.05 1.069 0 .59-.47 1.069-1.05 1.069zm6.3 0a1.06 1.06 0 01-1.05-1.07c0-.59.47-1.068 1.05-1.068.58 0 1.05.478 1.05 1.069 0 .59-.47 1.069-1.05 1.069zm.488-6.95c.175 0 .338.088.436.236l1.617 2.47a.541.541 0 01.09.288l-.006 1.818a.53.53 0 01-.525.534h-.079a2.106 2.106 0 00-2.026-1.577c-.948 0-1.778.646-2.027 1.577H16.1v-4.81a.53.53 0 01.525-.536h2.588zm-7.765 1.176c.294 0 .533.218.533.487s-.239.487-.533.487H7.183c-.294 0-.533-.218-.533-.487 0-.27.239-.487.533-.487zm-.231-2.192c.288 0 .521.218.521.487 0 .27-.233.487-.521.487H5.476c-.288 0-.522-.218-.522-.487s.234-.487.522-.487z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="28" height="28" fill="#FF4D4F" rx="14" />
        <mask id="gy" fill="#fff">
          <use xlinkHref="#hy" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#h" />
        <g fill="#FFF" mask="url(#gy)">
          <path d="M0 0h28v28H0z" />
        </g>
      </g>
    </svg>
  );
}

export default ShippingError;
