import React from 'react';
import styled from '@emotion/styled';
import { ButtonShape, ButtonSize } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';

import {
  ButtonGoogle,
  ButtonFacebook,
  ButtonPrimaryRegular,
  ButtonSecondaryRegular,
  ButtonTertiaryRegular,
  ButtonPrimaryLarge,
  ButtonPrimaryFab,
  ButtonSecondaryFab,
  ButtonQuaternaryRegular,
  ButtonQuaternaryLarge,
  ButtonPrimaryInactive,
  ButtonPrimaryRegularIconRight,
  ButtonEmail,
} from '../styles/ant-design-styles/buttons';
import { GoogleIcon } from './GoogleIcon';
import { FacebookV2Icon } from './FacebookIconV2';
import { EmailIcon } from './EmailIcon';

export interface ButtonProps {
  type: string;
  icon?: string;
  shape?: ButtonShape;
  size?: ButtonSize;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  prefixCls?: string;
  className?: string;
  ghost?: boolean;
  block?: boolean;
  children?: React.ReactNode;
  href?: string;
  target?: string;
  onClick?: React.MouseEventHandler<any>;
  htmlType?: ButtonHTMLType;
  disabled?: boolean;
  style?: any;
  width?: string;
}

const TertiaryButtonLayout = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 19px;
`;

const Children = styled.div`
  text-align: left;
`;

const Spacer24 = styled.div`
  margin-right: 24px;
`;

const IconContainer = styled.div`
  width: 18px;
  margin-top: -4px;
  margin-left: 3px;
  margin-right: -3px;
`;

export default function Button(props: ButtonProps) {
  const { type, style, width, ...rest } = props;
  const newStyle = { ...style, width };
  switch (type) {
    case 'primary-regular': {
      return <ButtonPrimaryRegular {...rest} style={newStyle} type="primary" />;
    }
    case 'primary-regular-inactive': {
      return (
        <ButtonPrimaryInactive {...rest} style={newStyle} type="primary" />
      );
    }
    case 'secondary-regular': {
      return (
        <ButtonSecondaryRegular {...rest} style={newStyle} type="default" />
      );
    }
    case 'tertiary-regular': {
      return (
        <TertiaryButtonLayout>
          <ButtonTertiaryRegular {...rest} style={newStyle} type="default" />
        </TertiaryButtonLayout>
      );
    }
    case 'quaternary-regular': {
      return (
        <ButtonQuaternaryRegular {...rest} style={newStyle} type="primary" />
      );
    }
    case 'primary-large': {
      return <ButtonPrimaryLarge {...rest} style={newStyle} type="primary" />;
    }
    case 'quaternary-large': {
      return (
        <ButtonQuaternaryLarge {...rest} style={newStyle} type="primary" />
      );
    }
    case 'secondary-fab': {
      return <ButtonSecondaryFab {...rest} style={newStyle} />;
    }
    case 'primary-fab': {
      return <ButtonPrimaryFab {...rest} style={newStyle} />;
    }
    case 'primary-regular-icon-right': {
      return (
        <ButtonPrimaryRegularIconRight
          {...rest}
          style={newStyle}
          type="primary"
        />
      );
    }
    case 'email': {
      return (
        <ButtonEmail {...rest} style={newStyle} type="primary">
          <ContentContainer>
            <EmailIcon />
            <Spacer24 />
            <Children>{props.children}</Children>
          </ContentContainer>
        </ButtonEmail>
      );
    }
    case 'google': {
      return (
        <ButtonGoogle {...rest} style={newStyle} type="primary">
          <ContentContainer>
            <GoogleIcon />
            <Spacer24 />
            <Children>{props.children}</Children>
          </ContentContainer>
        </ButtonGoogle>
      );
    }
    case 'facebook': {
      return (
        <ButtonFacebook {...rest} style={newStyle} type="primary">
          <ContentContainer>
            <IconContainer>
              <FacebookV2Icon />
            </IconContainer>
            <Spacer24 />
            <Children>{props.children}</Children>
          </ContentContainer>
        </ButtonFacebook>
      );
    }
    default:
      return <ButtonPrimaryRegular {...rest} style={newStyle} type="primary" />;
  }
}
