import { gql } from '@apollo/client';

const GET_USER_QUERY = gql`
  query Segment_UserQuery {
    viewer {
      id
      isLoggedIn
      user {
        id
        userId
        email
        firstName
        lastName
        mobilePhone
        pointBalance
        dateOfBirth
        trinityDealerId
        exigoCustomerId
        trinityEnrollDate
        shoppingWithCeoUser {
          id
        }
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
            subscriber {
              access
            }
            legacySubscriber {
              access
            }
          }
        }
        conciergeUser {
          id
          permissions {
            id
            oneCommerce {
              ceo {
                access
              }
              subscriber {
                access
              }
              legacySubscriber {
                access
              }
            }
          }
        }
        orders {
          edges {
            node {
              id
              orderId
              orderTotal
            }
          }
        }
        cartV2 {
          id
          eventId
          event {
            accountId
          }
        }
      }
    }
  }
`;

export default GET_USER_QUERY;
