import React from 'react';

const SvgFYI = (props: any) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h80v80H0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#1A1A1A"
        d="M10.5 32.5v38.309h28.769V38.336a5.849 5.849 0 00-1.692-4.123 5.732 5.732 0 00-4.085-1.713H10.5z"
      />
      <path
        fill="#DDD"
        fillRule="nonzero"
        stroke="#1A1A1A"
        d="M68.816 32.5H45.824a5.732 5.732 0 00-4.085 1.713 5.849 5.849 0 00-1.692 4.123V70.81h28.769V32.5z"
      />
      <path
        stroke="#1A1A1A"
        strokeLinecap="square"
        d="M46.019 41.276h17.509m-17.509 8.827h17.509m-17.509 9.931h17.509"
      />
      <text
        fill="#F4564E"
        fontFamily="GothamHTF-Black, Gotham HTF"
        fontSize="13"
        fontWeight="700"
        letterSpacing="0.39"
        transform="translate(11 33)"
      >
        <tspan x="2" y="24">
          FYI
        </tspan>
      </text>
      <g stroke="#000" strokeLinecap="square" strokeLinejoin="round">
        <path
          fill="#FAAD14"
          d="M28.29 32.026H25V20.513C25 14.155 30.15 9 36.517 9h7.23c6.36 0 11.516 5.153 11.516 11.513v11.513H28.29zm16.447 0h-9.21"
        />
        <g transform="translate(31 18)">
          <circle cx="2.5" cy="1.5" r="1.5" fill="#FF4D4F" />
          <circle cx="15.5" cy="1.5" r="1.5" fill="#FF4D4F" />
          <path
            fill="#FFF"
            d="M0 5h18.286v2c0 1.105-.895 2-1.992 2H1.992C.892 9 0 8.112 0 7V5z"
          />
          <path d="M3.429 5.571v2.914m2.857-2.914v2.914m2.857-2.914v2.914M12 5.571v2.914m2.857-2.914v2.914" />
        </g>
        <path d="M22 32.026h3.289m30.264 0h3.289" />
        <path
          fill="#FF4D4F"
          d="M29.21 32.027c0-1.09.887-1.974 1.977-1.974h3.283c1.092 0 1.978.876 1.978 1.974V34H29.21v-1.973z"
        />
        <path d="M31.842 34v-1.973M33.815 34v-1.973" />
        <path
          fill="#FF4D4F"
          d="M44.342 32.027c0-1.09.887-1.974 1.977-1.974h3.283c1.092 0 1.978.876 1.978 1.974V34h-7.238v-1.973z"
        />
        <path d="M46.974 34v-1.973M48.947 34v-1.973" />
      </g>
    </g>
  </svg>
);

export default SvgFYI;
