import React, { useState, ChangeEvent } from 'react';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import {
  Icons,
  IconButton,
  Menu,
  MenuItem as MuiMenuItem,
} from '@onehope/design-system-v2';

import { ContactType, useContactsContext } from '../ContactsContext';
import { ContactManagementTabs } from '../../ContactV2/ContactDetailsPage/TabContent/tabConstants';

const { cssConstants } = s;
const { MoreVertIcon: MuiMoreVertIcon } = Icons;

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '8px' : '0px')};
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  isCardView?: boolean;
  contact: ContactType;
}

export default function IconMenu(props: IconMenuProps) {
  const {
    toggleSelectedContact,
    toggleModalState,
    handleOrderPermissions,
  } = useContactsContext();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { contact, isCardView = false } = props;
  const { contactId } = contact;
  const open = Boolean(anchorEl);

  // handle element click event
  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    setAnchorEl(currentTarget);
  };

  // close element
  const handleClose = () => {
    setAnchorEl(null);
  };

  // handle clicked 'Edit Details' event
  const handleViewContact = () => {
    return navigate(
      `/contact?contactId=${contactId}&tab=${ContactManagementTabs.DETAILS}`,
    );
  };

  // handle clicked 'Archive' event
  const handleArchiveEvent = (contactId: string) => {
    toggleSelectedContact(contactId);
    toggleModalState(true);
  };

  return (
    <Container isCardView={isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="View Contact" onClick={() => handleViewContact()}>
          Edit Details
        </MenuItem>
        <MenuItem key="Delete" onClick={() => handleArchiveEvent(contactId)}>
          Delete
        </MenuItem>
        <MenuItem
          key="New Contact Order"
          onClick={() => handleOrderPermissions(contact)}
        >
          New Order
        </MenuItem>
      </Menu>
    </Container>
  );
}
