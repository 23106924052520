import * as React from 'react';
import { Tab } from '@onehope/design-system-v2';

import { Container, TabsContainer } from './TabFilter.styles';

type TabFilterProps = {
  filterBy: string;
  handleFilterByTabChange: (value: string) => void;
};

function TabFilter(props: TabFilterProps) {
  const handleTabChange = (e: React.FormEvent<EventTarget>, value: string) => {
    props.handleFilterByTabChange(value);
  };

  return (
    <Container>
      <TabsContainer
        style={{ minHeight: 'unset' }}
        key="TabFilter"
        value={props.filterBy}
        scrollButtons={false}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        <Tab value="all" label="All" />
        <Tab value="open" label="Open" />
        <Tab value="closed" label="Closed" />
      </TabsContainer>
    </Container>
  );
}

export default TabFilter;
