import React from 'react';

// types
import ActivityEntry from '../types/ActivityEntry';
import SendToTrackEventV2 from '../types/SendToTrackEventV2Type';
import OpenVideoDialogFn from '../types/openVideoDialog';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';

// components
import CardContent from './CardContent';

interface Props {
  activity: ActivityEntry;
  openVideoDialog?: OpenVideoDialogFn;
  openButtonActionDialog?: OpenButtonActionDialogFn;
  client?: any;
  isContentfulSideBar?: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
  onCardButtonClicked?: OnCardButtonClickedFn;
  archiveCardMutation?: (ledgerId: string, isArchived: boolean) => Promise<any>;
  shouldStopVideo?: boolean;
}

export default function ActionEngineCard({
  activity,
  openVideoDialog,
  openButtonActionDialog,
  sendToTrackEventV2,
  onCardButtonClicked,
  isContentfulSideBar = false,
  archiveCardMutation,
  shouldStopVideo,
}: Props) {
  return (
    <CardContent
      activity={activity}
      openButtonActionDialog={openButtonActionDialog}
      openVideoDialog={openVideoDialog}
      sendToTrackEventV2={sendToTrackEventV2}
      isContentfulSideBar={isContentfulSideBar}
      onCardButtonClicked={onCardButtonClicked}
      archiveCardMutation={archiveCardMutation}
      shouldStopVideo={shouldStopVideo}
    />
  );
}
