import styled from '@emotion/styled';
import { css } from 'emotion';
import {
  neutral0,
  neutral2,
  neutral34,
  inputFocusColor,
  primaryColor,
  textColor,
  bodyFontFamily,
  errorColor,
} from '../../styles/cssConstants';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { SvgCorner } from './corner';

type WrapProps = {
  validInput: boolean;
};

type TextAreaProps = {
  error?: boolean;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const MuiTextArea = styled(TextareaAutosize)`
  border-bottom: ${({ error }: TextAreaProps) => {
    return error ? `1px solid ${errorColor}` : `1px solid ${primaryColor}`;
  }};
  input:placeholder-shown {
    font-style: italic !important;
  }
  textarea:placeholder-shown {
    font-style: italic !important;
  }
`;

export const Wrap = styled.div<{ validInput: boolean }>`
  position: relative;
  display: inline-block;
  max-width: 460px;
  width: 100%;
  ::after {
    content: '';
    border-top: ${(props: WrapProps) =>
      props.validInput ? `14px solid ${neutral0}` : `14px solid ${neutral2}`};
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    :focus {
      border-top: 14px solid ${inputFocusColor};
      border-left: 14px solid ${inputFocusColor};
      border-right: 14px solid ${inputFocusColor};
    }
    -webkit-transform: rotate(-45deg);
    z-index: 1;
    position: absolute;
    right: -8px;
    bottom: 2px;
    pointer-events: none;
  }
  input:placeholder-shown {
    font-style: italic !important;
  }
  textarea:placeholder-shown {
    font-style: italic !important;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const ResizeIcon = styled(SvgCorner)`
  position: absolute;
  bottom: 4px;
  right: 0;
  pointer-events: none;
  z-index: 2;
`;

export const textAreaBaseStyle = css`
  width: 100%;
  background-color: ${neutral2};
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: ${bodyFontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: 0.01em;
  line-height: 1.43;
  color: #6d7278;
  padding: 16px;
  min-height: 53px;
  :focus {
    background-color: ${inputFocusColor};
    outline: none !important;
  }
  z-index: 1;
  position: relative;
  z-index: 1;
  ]
  input:placeholder-shown {
    font-style: italic !important;
  }
  textarea:placeholder-shown {
    font-style: italic !important;
  }
`;

export const validStyle = css`
  background-color: ${neutral0};
  font-style: normal;
  color: ${textColor};
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Label = styled.label`
  height: 18px;
  font-family: ${bodyFontFamily};
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: ${neutral34};
  margin-right: 8px;
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const HelperContainer = styled('div')`
  && {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: ${({ error }: TextAreaProps) => {
      return error ? '0px' : '40px';
    }};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const WordCount = styled('div')`
  && {
    font-family: ${bodyFontFamily};
    max-width: 460px;
    height: 18px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.64;
    text-align: right;
    color: ${neutral34};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const Error = styled('span')`
  && {
    font-family: ${bodyFontFamily};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: ${errorColor};
    display: block;
  }
`;
