import React from 'react';
import { Text, TEXT_DS, Grid, styled } from '@onehope/design-system-v2';

const SupportContainer = styled(Grid)({
  padding: '32px 0',
});

const SupportLink = styled(Text)({
  cursor: 'pointer',
  textAlign: 'center',
});

export default function NeedSomeHelp() {
  const onClickSupport = () => {
    const hopecommerceUrl =
      process.env.NEXT_PUBLIC_HOPECOMMERCE_URL ||
      process.env.GATSBY_HOPECOMMERCE_URL;
    window.location.href = `${hopecommerceUrl}/faq#contact`;
  };

  return (
    <SupportContainer>
      <SupportLink
        variant="custom"
        default={TEXT_DS.BODY_LINK_16}
        onClick={onClickSupport}
      >
        Contact support
      </SupportLink>
    </SupportContainer>
  );
}
