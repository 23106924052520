import React, { useRef } from 'react';
import {
  ImageUpload,
  makeStyles,
  Grid,
  Text,
  Icons,
  ThemeType,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';
import Modal from './Modal';

const useStyles = makeStyles((theme: ThemeType) => ({
  topBanner: {
    top: 0,
    '& p': { fontWeight: 400, fontSize: '16px', lineHeight: '24px' },
  },
  icon: { cursor: 'pointer' },
  container: { padding: '8px 24px' },
  s3Image: { maxWidth: '100%' },
  done: {
    textDecoration: 'underline',
    color: theme.palette.mud.main,
    cursor: 'pointer',
  },
}));

function ImageUploadDialog(props: any) {
  const { open, onClose, image, setImage, setCanvas } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  const imageRef = useRef(null);

  const handleClose = () => {
    setImage('');
    onClose();
  };

  const handleSave = () => {
    if (imageRef) {
      const canvas = imageRef?.current?.getImage()?.toDataURL();
      setCanvas(canvas);
      if (props.onUpload) {
        props.onUpload(canvas);
      }
    }
    return onClose();
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className={classes.topBanner}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.container}
        >
          {handleClose && (
            <Icons.Close className={classes.icon} onClick={handleClose} />
          )}
          <Text variant="body1" component="p">
            {image ? 'Edit' : 'Upload'} Photo
          </Text>
          <Text
            variant="body1"
            component="p"
            onClick={handleSave}
            className={classes.done}
          >
            Done
          </Text>
        </Grid>
      </div>
      <ImageUpload
        image={image}
        imageRef={imageRef}
        width={isMobile ? 260.5 : 521}
        height={isMobile ? 174 : 348}
        border={isMobile ? 26 : 52}
      />
    </Modal>
  );
}

export default ImageUploadDialog;
