import gql from 'graphql-tag';
import { CEDash_CauseOfChoiceSelectCause } from '../fragments/SelectedCauseFragment';

const GET_CONTACT_V2_QUERY = gql`
  query CEDash_ContactV2Query(
    $contactId: String
    $ownerAccountId: String
    $accountId: String
  ) {
    viewer {
      id
      v2 {
        contactsV2 {
          id
          contact(
            contactId: $contactId
            ownerAccountId: $ownerAccountId
            accountId: $accountId
          ) {
            ownerAccountId
            contactId
            accountId
            createDate
            dateOfBirth
            firstName
            lastName
            emailAddress
            phoneNumber
            organizationName
            lastOrder
            pointBalance
            pointsExpireAt
            shippingAddressIdDefault
            shippingAddress {
              id
              addressId
              addressLineOne
              addressLineTwo
              city
              state
              country
              zip
            }
            lifetimeSpend
            nextShipmentDate
            isHost
            isSubscriber
            isCe
            canShopFor
            shoppingWithCeoAccountId
            totalCauseDonationAmount
            selectedCause {
              ...CEDash_CauseOfChoiceSelectCause
            }
            autoShipPaymentErrors
          }
        }
      }
    }
  }
  ${CEDash_CauseOfChoiceSelectCause}
`;

export default GET_CONTACT_V2_QUERY;
