import * as React from 'react';
import styled from '@emotion/styled';

import {
  AppBar,
  AppBarProps,
  Loader,
  Button as MuiButton,
} from '@onehope/design-system-v2';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  @media all and (min-width: 768px) {
    max-width: 736px;
  }
  gap: 16px;
`;

const StyledAppBar = styled((props: AppBarProps) => <AppBar {...props} />)(
  () => ({
    backgroundColor: 'white',
    padding: 16,
  }),
);

const appBarSx = {
  top: 'auto',
  bottom: 0,
};

interface FooterButtonsProps {
  handleNextStepChange: any;
  handlePrevStepChange: any;
  nextStepButtonText: string;
  prevStepButtonText: string;
  disableNext: boolean;
  loadingEventCreation?: boolean;
}

export default function FooterButtons(props: FooterButtonsProps) {
  return (
    <StyledAppBar sx={appBarSx}>
      <Container>
        <MuiButton
          type="secondary"
          fullWidth
          onClick={props.handlePrevStepChange}
        >
          {props.prevStepButtonText}
        </MuiButton>
        <MuiButton
          fullWidth
          type="primary"
          disabled={props.loadingEventCreation || props.disableNext}
          onClick={props.handleNextStepChange}
        >
          {props.loadingEventCreation ? <Loader /> : props.nextStepButtonText}
        </MuiButton>
      </Container>
    </StyledAppBar>
  );
}
