import React from 'react';
import { DateTime } from 'luxon';
import { Chip, styled, Text, theme, TEXT_DS } from '@onehope/design-system-v2';
import {
  Row,
  CompletedWrapper,
  StatusText,
  GreenCheck,
  ContactName,
} from '../StyledComponents/Tables';

// types
import {
  ReachOutRequestStatus,
  ShareMedium,
} from '../../../../generatedTypes/globalTypes';
import {
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplate as ReachOutV2Type,
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList as ContactShareListType,
} from '../../../queries/generatedTypes/CEDash_ReachOutsV2InviteQuery';

const TinyGrayText = styled(Text)({
  paddingTop: '4px',
  color: `${theme.palette.greyDark.main} !important`,
});

const StatusWrapper = styled('div')({
  paddingTop: '4px',
  textAlign: 'right',
});

type Props = {
  contact: ContactShareListType;
  contactIndex: number;
  reachOut: ReachOutV2Type | null;
  sendInvite: (
    contact: ContactShareListType,
    index: number,
    inviteSent: boolean,
  ) => void;
};

/**
 * Render a row in the invites table
 */
export default function InviteRow({
  contact,
  contactIndex,
  reachOut,
  sendInvite,
}: Props) {
  const { simpleContact, reachOutRequest } = contact;
  const { firstName, lastName, contactId } = simpleContact;
  const inviteSent = !!reachOutRequest?.status;
  const isComplete =
    inviteSent && reachOutRequest?.status === ReachOutRequestStatus.COMPLETE;
  const clickedOn = reachOutRequest?.viewCount > 0;
  const media =
    reachOutRequest?.shareMedium === ShareMedium.EMAIL ? 'email' : 'SMS';
  const date =
    reachOutRequest?.lastModifiedDate &&
    DateTime.fromISO(reachOutRequest?.lastModifiedDate).toFormat('MM/dd/yyyy');

  // if the initial invitation was sent, we want to show the "share again" button,
  // but only if there's a followup template defined in Contentful
  const showShareAgain =
    inviteSent &&
    reachOut?.followupConfigurations.some(
      config => config.medium === reachOutRequest?.shareMedium,
    );

  return (
    <Row key={contactId}>
      <ContactName text={`${firstName} ${lastName}`} />
      {isComplete ? (
        <CompletedWrapper>
          <GreenCheck />
          <StatusText text="Completed" />
        </CompletedWrapper>
      ) : (
        <>
          {inviteSent ? (
            <StatusWrapper>
              {showShareAgain && (
                <Chip
                  label="share again"
                  onClick={() => sendInvite(contact, contactIndex, inviteSent)}
                  secondary
                />
              )}
              <TinyGrayText variant="custom" default={TEXT_DS.TINY_SEMIBOLD_11}>
                {clickedOn ? (
                  <>Clicked on {date}</>
                ) : (
                  <>
                    Sent {media} on {date}
                  </>
                )}
              </TinyGrayText>
            </StatusWrapper>
          ) : (
            <Chip
              label="send"
              onClick={() => sendInvite(contact, contactIndex, inviteSent)}
            />
          )}
        </>
      )}
    </Row>
  );
}
