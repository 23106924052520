import React from 'react';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';

import Layout from '../layout';
import { getCurrentUserId } from '../utils/utils';
import ContactDetailsPage from '../components/ContactV2';
import { ContactManagementTabs } from '../components/ContactV2/ContactDetailsPage/TabContent/tabConstants';

const Container = styled.div`
  background-color: white;
  height: 100%;
`;

function ContactPage(props: PageRendererProps) {
  const { location } = props;
  const { contactId, tab } = queryString.parse(location.search);

  const contactTab = typeof tab === 'string' ? tab : null;
  return (
    <Layout>
      <Container>
        <ContactDetailsPage
          contactId={contactId}
          tab={contactTab || ContactManagementTabs.DETAILS}
        />
      </Container>
    </Layout>
  );
}

export default ContactPage;
