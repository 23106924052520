import * as yup from 'yup';
import { allowedStates } from '../../utils/statesWeCanShipTo';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'First name should be min 2 characters')
    .required('First name required'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Last name should be min 2 characters')
    .required('Last name required'),
  addressLineOne: yup
    .string()
    .min(4, 'Address line one should be min 4 characters')
    .required('Address line one required'),
  city: yup
    .string()
    .trim()
    .min(2, 'City should be min 2 characters')
    .required('City is required'),
  zip: yup
    .string()
    .matches(/^(?!0{3})[0-9]{3,5}$/, 'Enter valid zip code')
    .required('Zip code required'),
  state: yup
    .mixed()
    .oneOf(
      allowedStates,
      'Not a valid shipping state, use 2 capital letters, certain states are disallowed',
    )
    .required('State required'),
});

export default validationSchema;
