import React from 'react';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import { Button as MuiButton, TEXT_DS } from '@onehope/design-system-v2';
import ORDER_WINE_SUMMARY_QUERY from '../../../../queries/Order';
import {
  StraightLine,
  TabContentContainer,
  DetailsHeader,
  DetailsTitle,
} from '../index.styles';
import SummaryLine from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine';
import {
  getOrderStatusIcon,
  isStatusValidForCancel,
} from '../../../../common/Orders/helpers';
import useToggle from '../../../../utils/useToggleHook';
import Loading from '../../../../common/Loading';
import Notification from '../../../Notifcations/DefaultNotifcation';

interface OrderSummaryProps {
  orderId: string;
}

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const Button = styled(MuiButton)`
  && {
    margin-bottom: 8px;
  }
`;

const OrderStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrderStatusIcon = styled.div`
  height: 28px;
  margin-right: 8px;
`;

const handleCancelOrder = async ({
  orderId,
  orderCancel,
  setNotificationOpen,
}: any) => {
  await orderCancel({
    variables: { input: { orderId } },
  })
    .then((data: any) => {})
    .catch((errors: any) => {
      console.log('errors', errors);
      return setNotificationOpen();
    });
};

const OrderSummary = ({ orderId }: OrderSummaryProps) => {
  const { data, loading, error } = useQuery(ORDER_WINE_SUMMARY_QUERY, {
    variables: { orderId },
  });
  // const [orderCancel, { loading: mutationLoading }] = useMutation(
  //   OrderCancelMutation,
  // );

  // const {
  //   value: dialogOpen,
  //   setTrue: setDialogOpen,
  //   setFalse: setDialogClosed,
  // } = useToggle();

  const {
    value: notificationOpen,
    setTrue: setNotificationOpen,
    setFalse: setNotificationClosed,
  } = useToggle();

  if (loading) return <Loading />;

  const order = get(data, 'viewer.order');
  const { billingAddress, shippingAddress } = order;
  const shippingName = `${shippingAddress?.firstName} ${shippingAddress?.lastName}`;
  const billingName =
    billingAddress?.firstName && billingAddress?.lastName
      ? `${billingAddress?.firstName} ${billingAddress?.lastName}`
      : shippingName;

  const orderStatus = getOrderStatusIcon(order.orderStatus);
  const canCancel = isStatusValidForCancel(order.orderStatus);

  const handlePrint = () => {
    return window.print();
  };

  const handleViewOrder = () => {
    return window.open(
      `${process.env.GATSBY_HOPECOMMERCE_URL}/order/${order.orderId}`,
      '_blank',
    );
  };

  const ERROR_MESSAGE = `Unable to cancel. Please refresh your page.`;
  return (
    <div>
      <Notification
        variant="error"
        open={notificationOpen}
        message={ERROR_MESSAGE}
        handleClose={setNotificationClosed}
      />
      <TabContentContainer>
        <DetailsHeader>
          <DetailsTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            Wine Details
          </DetailsTitle>
        </DetailsHeader>
        <StraightLine />
        <SummaryLine width={120} title="Purchased by" value={billingName} />
        <SummaryLine width={120} title="Shipping to" value={shippingName} />
        <SummaryLine
          width={120}
          title="Order date"
          value={DateTime.fromISO(order.createDate).toLocaleString(
            DateTime.DATE_SHORT,
          )}
        />
        <SummaryLine width={120} title="Total" value={`$${order.orderTotal}`} />
        <SummaryLine width={120} title="Order #" value={order.orderNumber} />
        <SummaryLine
          width={120}
          title="Status"
          value={
            <OrderStatusContainer>
              <OrderStatusIcon>{orderStatus.icon}</OrderStatusIcon>
              <div>{orderStatus.status}</div>
            </OrderStatusContainer>
          }
        />
        <ButtonContainer>
          <Button fullWidth disableRipple onClick={handleViewOrder}>
            view order
          </Button>
        </ButtonContainer>
      </TabContentContainer>
    </div>
  );
};

export default OrderSummary;
