import { DateTime } from 'luxon';

export const getDisplayTime = (dateTime?: string | null) => {
  const date = dateTime || new Date().toISOString();
  const day = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
  const time = DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
  return `${day} ${time}`;
};

export const getFormattedTimeAgo = (dateCreated: string) => {
  const now = DateTime.utc();
  const cardCreatedDate = DateTime.fromISO(dateCreated);
  const diffWeeks = now.diff(cardCreatedDate, ['weeks']).toObject();
  if (diffWeeks && diffWeeks.weeks && diffWeeks.weeks >= 1) {
    return `${Math.floor(diffWeeks.weeks)}w`;
  }

  const diffSeconds = now.diff(cardCreatedDate, ['seconds']).toObject();
  const dateTimeAgo = DateTime.local()
    .minus({ seconds: diffSeconds.seconds })
    .toRelative();
  const arr = dateTimeAgo?.split(' ') || [];
  const isOne = arr[0] === 'a';
  const amount = isOne ? '1' : arr[0];
  const interval = arr[1].charAt(0);
  return `${amount}${interval}`;
};
