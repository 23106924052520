import React, { Dispatch, SetStateAction } from 'react';
import AddressDialog from '../AddressDialog';
import { Account_AddressesGetQuery_viewer_user_addressBook_entries_edges_node_address as AddressSummaryType } from '../../../queries/generatedTypes/Account_AddressesGetQuery';

export interface AddressType {
  node: {
    id: string;
    address: AddressSummaryType;
  };
}

interface AddressAddViewProps {
  setView: (input: string) => void;
  setLoadingTrue?: () => void;
  setLoadingFalse?: () => void;
  setSelectedAddress: Dispatch<SetStateAction<AddressSummaryType | null>>;
  contactId?: string;
}

const AddressAddView = (props: AddressAddViewProps) => {
  const { setView, setSelectedAddress } = props;

  const handleClose = () => {
    setView('SELECT');
  };
  return (
    <>
      <AddressDialog
        open={true}
        showDialog={false}
        onClose={handleClose}
        setSelectedAddress={setSelectedAddress}
        isEditing={false}
        key="Add Address"
        headerText="Add Address"
        hasDefaultShippingAddressId={true}
        contactId={props.contactId}
      />
    </>
  );
};

export default AddressAddView;
