import React, { useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';

import {
  Text,
  Card,
  Grid,
  CardContent,
  TEXT_DS,
  styled,
  theme,
  CardMedia,
} from '@onehope/design-system-v2';

import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplateType } from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import { ShareMedium } from '../../../../generatedTypes/globalTypes';
import ReachOutCarouselBodyDisplay from './ReachOutCarouselBodyDisplay';

const ShareMediumTitle: { [P in keyof typeof ShareMedium]: string } = {
  EMAIL: 'EMAIL',
  FACEBOOK_FEED_POST: 'FACEBOOK',
  FACEBOOK_STORY: 'FACEBOOK STORIES',
  INSTAGRAM_STORY: 'INSTAGRAM',
  LINKED_IN_POST: 'LINKEDIN',
  TEXT_MESSAGE: 'SMS MESSAGE',
};

const ReachOutPreviewCardBody = styled(Card)(() => ({
  width: '288px',
  height: '500px',
  background: theme.palette.lightYellowBeige.main,
  boxShadow:
    '0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 6px 12px rgba(14, 31, 53, 0.08)',
  borderRadius: '12px',
  overflowY: 'auto',
  overflowWrap: 'break-word',
}));

const ReachOutPreviewCardTitle = styled(Text)(() => ({
  marginRight: '24px',
}));

const BodyText = styled(Text)(() => ({
  whiteSpace: 'pre-line',
  width: '100%',
}));

const SwipeableViewsContainer = styled('div')({
  overflowX: 'scroll',
});

const StyledImage = styled(CardMedia)({
  width: '100%',
  height: '152px',
});

const StyledImageContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
});

const ReachOutCarousel = ({
  reachOut,
  currentViewIndex,
  setCurrentViewIndex,
}: {
  reachOut: ReachOutTemplateType;
  currentViewIndex: number;
  setCurrentViewIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { previewUrl, shareConfigurations, coverImageUrl } = reachOut;
  const settings = {
    slideContainer: {
      padding: '0 24px',
      height: '545px',
      WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
    },
    slide: {
      width: '312px',
      height: '545px',
      overflowX: 'none',
    },
  };

  const filteredReachOutShareConfig = shareConfigurations
    ?.filter(config => ![ShareMedium.INSTAGRAM_STORY].includes(config.medium))
    .map(config => {
      return {
        ...config,
      };
    });

  const onChangeIndex = useCallback(
    (index: number, indexLatest: number) => {
      setCurrentViewIndex(index);
    },
    [setCurrentViewIndex],
  );

  const goToPreviewUrl = (e?: React.MouseEvent) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    window.open(previewUrl, '_blank');
    return null;
  };

  return (
    <SwipeableViewsContainer>
      <SwipeableViews
        onChangeIndex={onChangeIndex}
        index={currentViewIndex}
        style={settings.slide}
        slideStyle={settings.slideContainer}
      >
        {filteredReachOutShareConfig?.length > 0 &&
          filteredReachOutShareConfig.map(reachOutShareConfig => {
            const title = (reachOutShareConfig?.medium as keyof typeof ShareMedium)
              ? ShareMediumTitle[reachOutShareConfig.medium]
              : '';

            return (
              <Grid
                container
                spacing={4}
                direction="column"
                onClick={goToPreviewUrl}
                style={{ cursor: 'pointer' }}
              >
                <Grid item>
                  <ReachOutPreviewCardTitle
                    variant="custom"
                    default={TEXT_DS.OVERLINE_SEMIBOLD_14}
                    align="center"
                  >
                    {title}
                  </ReachOutPreviewCardTitle>
                </Grid>
                <Grid container>
                  <ReachOutPreviewCardBody>
                    {(reachOutShareConfig?.medium === ShareMedium.EMAIL ||
                      reachOutShareConfig?.medium ===
                        ShareMedium.TEXT_MESSAGE) && (
                      <CardContent>
                        <Grid container spacing={2} direction="column">
                          <Grid item container>
                            {reachOutShareConfig?.subject && (
                              <Text
                                variant="custom"
                                default={TEXT_DS.BODY_LIGHT_ITALIC_18}
                                align="center"
                              >
                                <b>Subject:</b> {reachOutShareConfig.subject}
                              </Text>
                            )}
                          </Grid>
                          {reachOutShareConfig?.body && (
                            <Grid item container>
                              <BodyText
                                variant="custom"
                                default={TEXT_DS.BODY_LIGHT_ITALIC_18}
                                align="center"
                              >
                                <ReachOutCarouselBodyDisplay
                                  body={reachOutShareConfig?.body}
                                />
                              </BodyText>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    )}
                    {(reachOutShareConfig?.medium ===
                      ShareMedium.FACEBOOK_FEED_POST ||
                      reachOutShareConfig?.medium ===
                        ShareMedium.LINKED_IN_POST) && (
                      <StyledImageContainer>
                        <StyledImage image={`${coverImageUrl}`} />
                      </StyledImageContainer>
                    )}
                  </ReachOutPreviewCardBody>
                </Grid>
              </Grid>
            );
          })}
      </SwipeableViews>
    </SwipeableViewsContainer>
  );
};

export default ReachOutCarousel;
