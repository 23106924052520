export enum segmentEvents {
  conciergeModeOrderStarted = 'Concierge Mode Order Started',
  contactImportComplete = 'Contact Import Complete',
  contactCreated = 'Contact Created',
  contactArchived = 'Contact Archived',
  contactUpdated = 'Contact Updated',
  cardRestored = 'Card Restored',
  causeOfChoiceUpdated = 'Cause of Choice Updated',
  reachOutSent = 'Reach Out Sent',
  reachOutContactResultsViewed = 'Reach Out Contact Results Viewed',
  reachOutAssetDownloaded = 'Reach Out Asset Downloaded',
  ceDashboardComponentViewed = 'Component Viewed',
  linkClick = 'Link Click',
}
