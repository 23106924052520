import React from 'react';
import { Text, TEXT_DS } from '@onehope/design-system-v2';

const InputLabel = ({ children }: any) => {
  return (
    <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
      {children}
    </Text>
  );
};

export default InputLabel;
