import { AlgoliaHit } from '@onehope/algolia-components';
import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_userInfo_permissions_oneCommerce,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';

const PRODUCT_PERMISSION_TYPES = {
  DEFAULT: 'user',
  CE: 'ce',
  SUBSCRIBER: 'subscriber',
  COLLECTIVE: 'collective',
  ADMIN: 'admin',
};

export const getButtonText = ({
  isDesktop,
  product,
}: {
  isDesktop: boolean;
  product: AlgoliaHit;
}): string => {
  if (product.isOutOfStock) {
    return 'Out of Stock';
  }

  if (isDesktop) {
    return 'Swap Wine';
  }
  return 'Swap';
};

export const determineUserRoles = (
  user: WineClub_AutoShipGetQuery_viewer_user,
) => {
  // create a stock dictionary of roles mapped to the backend
  const ROLE_STRINGS = {
    ADMIN: 'admin',
    CE: 'ce',
    SUBSCRIBER: 'subscriber',
    COLLECTIVE: 'collective',
  };
  const ROLES_CONVERTER: { [key: string]: string } = {
    admin: ROLE_STRINGS.ADMIN,
    ceo: ROLE_STRINGS.CE,
    subscriber: ROLE_STRINGS.SUBSCRIBER,
    collective: ROLE_STRINGS.COLLECTIVE,
  };

  // init empty array to push roles into
  const roles: string[] = [];

  // pull out the oneCommerce permissions object
  const oneCommerce =
    user?.permissions?.oneCommerce ??
    ({} as WineClub_AutoShipGetQuery_viewer_userInfo_permissions_oneCommerce);
  const oneCommerceKeys = Object.keys(oneCommerce).filter((key) => {
    switch (key) {
      case 'ceo':
        return oneCommerce.ceo?.access;
      case 'subscriber':
        return oneCommerce.subscriber?.access;
      case 'collective':
        return oneCommerce.collective?.access;
      case 'employee':
        return oneCommerce.employee?.access;
      case 'wineryEmployee':
        return oneCommerce.wineryEmployee?.access;
      default:
        return false;
    }
  });
  if (!oneCommerceKeys.length) {
    return roles;
  }

  // calculate the oneCommerce roles
  oneCommerceKeys.forEach((role) => {
    switch (role) {
      case 'ceo':
        if (oneCommerce.ceo?.access) {
          roles.push(ROLES_CONVERTER[role]);
        }
        break;
      case 'subscriber':
        if (oneCommerce.subscriber?.access) {
          roles.push(ROLES_CONVERTER[role]);
        }
        break;
      case 'collective':
        if (oneCommerce.collective?.access) {
          roles.push(ROLES_CONVERTER[role]);
        }
        break;
      case 'employee':
        if (oneCommerce.employee?.access) {
          roles.push(ROLES_CONVERTER['admin']);
        }
        break;
      case 'wineryEmployee':
        if (oneCommerce.wineryEmployee?.access) {
          roles.push(ROLES_CONVERTER['admin']);
        }
        break;
    }
  });
  return roles;
};

export const getButtonDisabledStatus = ({
  product,
  userPermissions,
}: {
  product: AlgoliaHit;
  userPermissions: string[];
}): { disabled: boolean; reason?: string } => {
  // calculate if the user has correct permissions for the product
  const hasPermission = Object.keys(product?.permissions).length
    ? userPermissions.some(
        (permission) => product?.permissions[permission]?.add,
      )
    : true;

  // if the user does not have permission, reject outright
  if (!hasPermission) {
    // find the permission that the user does not have
    const missingPermissions = Object.keys(product?.permissions).length
      ? Object.keys(product?.permissions).filter((productPermission) => {
          const addPermission = product?.permissions[productPermission]?.add;
          if (addPermission) {
            if (productPermission === PRODUCT_PERMISSION_TYPES.DEFAULT) {
              // anyone can add
              return false;
            }
            // The rest of the product permissions match with ROLE STRINGS.
            if (!userPermissions.includes(productPermission)) return true;
          }
          return false;
        })
      : [];
    // If there were multiple permissions missing I guess we just pick one.
    const missingPermission = missingPermissions[0] || '';
    return {
      disabled: true,
      reason: `locked ${missingPermission}`,
    };
  }

  // if no matching cases found, return false
  return {
    disabled: false,
  };
};
