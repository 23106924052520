import React, { useCallback, useContext } from 'react';
import smsLink from 'sms-link';
import {
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
  ThemeType,
} from '@onehope/design-system-v2';

import ActivityEntry from '../types/ActivityEntry';
import { isMobileOnly, isTablet, isMobile, isIOS } from 'react-device-detect';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';
import ExternalConfigContext from '../types/ExternalConfigContext';
import { ButtonText, ButtonActions } from './constants';

interface CardButtonProps {
  activity: ActivityEntry;
  onVideoClickPreview: () => void;
  openButtonActionDialog: OpenButtonActionDialogFn | undefined;
  onCardButtonClicked?: OnCardButtonClickedFn;
  watchNow: () => void;
  isCardDetailsPage?: boolean;
  handleOpenDialog: () => void;
  isDesktop?: boolean;
  isVideoCard?: boolean;
}

type SharedStyles = {
  [key: string]: string | number | object;
};

const buttonBase = () => {
  return {
    position: 'absolute',
    width: '92%',
    borderRadius: '12px',
    cursor: 'pointer',
  } as SharedStyles;
};

const useStyles = makeStyles((theme: ThemeType) => ({
  button: {
    ...buttonBase(),
    bottom: '25px',
    backgroundColor: theme.palette.forestGreen.main,
    '&:hover': {
      backgroundColor: theme.palette.forestGreen.main,
      opacity: 0.9,
    },
    ['@media (max-width: 599px)']: {
      margin: 'auto',
    },
  },
  detailPageButton: {
    ...buttonBase(),
    width: '100%',
    maxWidth: '310px',
    marginTop: '25px',
    backgroundColor: theme.palette.forestGreen.main,
    '&:hover': {
      backgroundColor: theme.palette.forestGreen.main,
      opacity: 0.9,
    },
    ['@media (max-width: 599px)']: {
      maxWidth: '318px',
    },
  },
}));

const convertEmailBreakpoints = (body: string) =>
  body.replace(/<br(\s\/)?>/gi, '\n');

const CardButton = ({
  activity,
  onVideoClickPreview,
  openButtonActionDialog,
  onCardButtonClicked,
  watchNow,
  isCardDetailsPage,
  isDesktop,
  handleOpenDialog,
  isVideoCard,
}: CardButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isWebMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const externalConfig = useContext(ExternalConfigContext);

  /**
   * Open a URL, optionally passing it to the injected `navigationHandler` for context-specific handling.
   */
  const openUrl = useCallback(
    (url: string) => {
      // the navigation handler will return true iff it handled the navigation. otherwise, we'll open the URL ourselves.
      if (
        externalConfig.navigationHandler &&
        externalConfig.navigationHandler(url)
      ) {
        return;
      }
      window.open(url, '_blank');
    },
    [externalConfig.navigationHandler],
  );

  const getUrl = (
    buttonAction: ActivityEntry['buttonAction'] | undefined,
  ): string | null => {
    // action: website
    // fields: name url

    // action: phoneCall
    // fields: name phoneNumber

    // action: email
    // fields: name emailAddress body subject

    // action: textMessage
    // fields: name phoneNumber body
    if (buttonAction) {
      switch (buttonAction.action) {
        case ButtonActions.inAppLink:
          return buttonAction.appUrl ?? null;
        case ButtonActions.website:
          return buttonAction.url;
        case ButtonActions.textMessage:
          return smsLink({
            phone: buttonAction.phoneNumber,
            body: buttonAction.body,
          });
        case ButtonActions.email:
          // left unchecked, this will render "null" in the user's email client
          const emailTo = buttonAction.emailAddress || '';
          return `mailto:${emailTo}?subject=${encodeURI(
            buttonAction.subject,
          )}&body=${encodeURI(convertEmailBreakpoints(buttonAction.body))}`;
        case ButtonActions.phoneCall:
          return `tel:${buttonAction.phoneNumber}`;
        default:
          console.warn(
            `${buttonAction.action} is not a valid action for card button`,
          );
      }
    }

    return null;
  };

  const buttonTriggered = () => {
    const { buttonText, buttonAction } = activity;

    let url: string | null = null;
    if (buttonAction) {
      url = getUrl(buttonAction);
    }

    if (buttonAction && url) {
      openUrl(url);
    } else {
      switch (true) {
        case buttonText === ButtonText.watchNow:
          isMobile || isWebMobile ? watchNow() : onVideoClickPreview();
          break;
        case buttonText === ButtonText.markRead ||
          buttonText === ButtonText.redeemPoints:
          // no op... it's handled in onCardButtonClicked
          break;
        default:
          throw new Error('Invalid button received!');
      }
    }
  };

  const buttonClicked = () => {
    const { buttonAction, buttonText } = activity;
    const isTextOrCallButtonAction =
      buttonAction &&
      (buttonAction.action === ButtonActions.phoneCall ||
        buttonAction.action === ButtonActions.textMessage);
    if (isTextOrCallButtonAction && !isMobileOnly) {
      if (typeof openButtonActionDialog !== 'undefined') {
        openButtonActionDialog(activity.buttonAction);
      }
    } else {
      buttonTriggered();
    }
    // Success triggers, mark read, card clicked segment event
    if (buttonText !== ButtonText.watchNow) {
      onCardButtonClicked && onCardButtonClicked(activity.id);
    }
  };

  const onClick =
    (!isTablet && isIOS) || isDesktop
      ? buttonClicked
      : isVideoCard
      ? handleOpenDialog
      : buttonClicked;

  return (
    <Button
      onClick={onClick}
      onTouchEnd={onClick}
      className={isCardDetailsPage ? classes.detailPageButton : classes.button}
      disabled={
        isCardDetailsPage &&
        (activity.buttonText === ButtonText.markRead ||
          activity.buttonText === ButtonText.redeemPoints)
      }
    >
      {activity.buttonText}
    </Button>
  );
};

export default CardButton;
