import styled from '@emotion/styled';
import Select from 'antd/lib/select';
import 'antd/lib/select/style/index.css';

const Option = Select.Option;
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { font, primaryColor, inputFocusColor },
} = styles;

const baseStyle = `border-radius: 4px;
  height: 54px !important;
  border-radius: 4px !important;
  border: solid 1px ${primaryColor} !important;
  background-color: #ffffff !important;
  &:hover, &.active {
    background-color: ${inputFocusColor} !important;
    border-bottom-width: 3px !important;
  }
  font-family: ${font} !important;`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const SelectRegular = styled(Select)`
  ${baseStyle};
  .ant-select-selection {
    height: 52px !important;
    border: none !important;
    background-color: transparent !important;
  }
  .ant-select-selection__placeholder {
    color: ${primaryColor} !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 150% !important;
    font-family: ${font} !important;
    margin-top: 0 !important;
    margin-left: 7px !important;
  }
  .ant-select-selection-selected-value {
    color: ${primaryColor} !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 150% !important;
    font-family: ${font} !important;
    margin-left: 7px !important;
    margin-top: 15px !important;
  }
  .ant-select-search--inline .ant-select-search__field {
    margin-top: 10px !important;
    margin-left: 7px !important;
  }
` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const OptionRegular = styled(Option)`
  .ant-select-dropdown,
  .ant-select-dropdown-menu {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
` as any;
