import { SegmentEvents } from '@onehope/action-engine';
import {
  SubscriptionSegmentEvents,
  SubscriptionSegmentProps,
} from '@onehope/wine-club-subscription';
import { segmentEvents } from './constants';
import {
  formatTrackUpdateCauseOfChoiceProps,
  formatTrackLinkClickProps,
} from './helpers';

export type SegmentEventPropsV2 = {
  event: SegmentEvents | segmentEvents | SubscriptionSegmentEvents;
  properties:
    | CardEventProps
    | ConciergeModeEventProps
    | ContactImportEventProps
    | GenericContactEventProps
    | ReachOutSentProps
    | ReachOutViewedProps
    | LinkClickProps
    | CEDashboardWidgetEventProps
    | SubscriptionSegmentProps;
};

type SegmentCookiePropsV2 = {
  isLoggedIn: boolean;
  userId: string;
};

type CardEventProps = {
  cardName: string | null;
  contentfulEntryId: string | null;
  streamActionId: string | null;
  cardTemplate: string | null;
  cardCategory?: string | null;
  cardButtton?: boolean | null;
  subjectUserId: string | null;
  rewardPointValue?: number;
};

type ConciergeModeEventProps = {
  eventId: string | null;
  shoppingForId: string;
  isNewCustomer: boolean;
};

type ContactImportEventProps = {
  numContacts: number;
  accountId: string;
  source: string;
};

type GenericContactEventProps = {
  accountId?: string;
  contactId: string;
};

type ReachOutSentProps = {
  name?: string;
  reachOutId: string | string[];
  contactId: string | null;
  deliveryType: string;
  ceAccountId: string | null;
  isReminder: boolean;
  category: string;
};

type ReachOutViewedProps = {
  reachOutId: string;
  ceAccountId?: string;
};

type LinkClickProps = {
  name: string;
  url: string;
};

type CEDashboardWidgetEventProps = {
  name: string;
  ceAccountId: string | null;
};

export const segmentEventV2 = async ({
  event,
  properties,
}: SegmentEventPropsV2) => {
  if (
    process.env.GATSBY_ENABLE_SEGMENT &&
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined'
  ) {
    let trackProps = {};

    switch (event) {
      case segmentEvents.causeOfChoiceUpdated:
        trackProps = formatTrackUpdateCauseOfChoiceProps({
          properties,
        });
        break;
      case segmentEvents.linkClick:
        trackProps = formatTrackLinkClickProps({
          properties,
        });
        break;
      default:
        trackProps = properties;
    }

    return analytics.track(event, trackProps);
  }
};

// V2 - will not pass a userId to Segment if the user is anonymous
export const segmentCookieV2 = ({
  isLoggedIn,
  userId,
}: SegmentCookiePropsV2): void => {
  if (
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined' &&
    analytics._user
  ) {
    if (isLoggedIn) {
      analytics._user.id(userId);
    } else {
      // set to null here in case it has been set to something somwhere else
      analytics._user.id(null);
    }
  }
};
