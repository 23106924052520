import React from 'react';
import {
  Box,
  Button,
  Icons,
  makeStyles,
  TEXT_DS,
  Text,
  ThemeType,
} from '@onehope/design-system-v2';

type MyTrainingProps = {
  inFastStart: boolean;
};

const fastStartDescription =
  'Learn how to share the ONEHOPE opportunity and launch your new Wine Rep business.';
const wineRepDescription =
  'Head to the Training Center to take courses that will challenge Wine Reps at every level and help grow your understanding of ONEHOPE products.';

const fastStartVideoUrl = 'https://resourcecenter.onehopewine.com/';
const ceNoCoursesUrl = 'https://resourcecenter.onehopewine.com/';

const useStyles = makeStyles((theme: ThemeType) => ({
  title: {
    paddingBottom: 24,
  },
  button: {
    backgroundColor: theme.palette.forestGreen.main,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: theme.palette.forestGreen.main,
      opacity: 0.9,
    },
  },
}));

export default function MyTraining(props: MyTrainingProps) {
  const classes = useStyles();

  const getTitle = () => {
    const { inFastStart } = props;
    if (inFastStart) {
      return 'Start Here!';
    } else {
      return 'Get Learning';
    }
  };

  const getDescription = () => {
    const { inFastStart } = props;
    return inFastStart ? fastStartDescription : wineRepDescription;
  };

  const getButtonText = () => {
    return 'Start Learning';
  };

  const getCourseLink = () => {
    const { inFastStart } = props;
    if (inFastStart) {
      return fastStartVideoUrl;
    } else {
      return ceNoCoursesUrl;
    }
  };
  const title = getTitle();
  const description = getDescription();
  const buttonText = getButtonText();
  const courseLink = getCourseLink();
  const onClick = () => {
    window.open(courseLink, '_self');
  };
  return (
    <Box>
      <Box sx={{ pb: 3 }}>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
          Training Courses
        </Text>
      </Box>
      <Box>
        <Box sx={{ pb: 2 }}>
          <Icons.CEDashTrainingCenter />
        </Box>
        <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_24}>
          {title}
        </Text>
        <Box sx={{ pb: 2, pt: 2 }}>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {description}
          </Text>
        </Box>
        <Button className={classes.button} fullWidth onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}
