import { gql } from '@apollo/client';
import { AutoShipFragment } from '../queries/fragments/AutoshipFragment';

const mutation = gql`
  mutation WineClub_AutoShipUpdateMutation(
    $input: AutoShipUpdateMutationInput!
    $autoShipId: String!
  ) {
    autoShipUpdate(input: $input) {
      user {
        id
        autoShips {
          ...WineClub_GetAutoShip
        }
        autoShip(autoShipId: $autoShipId) {
          ...WineClub_GetAutoShip
        }
      }
    }
  }
  ${AutoShipFragment}
`;

export default mutation;
