import { gql } from '@apollo/client';
import { GetPaymentFragment } from '../queries/fragments/PaymentFragment';

const mutation = gql`
  mutation Account_StripeV2PaymentAddMutation(
    $input: StripeV2PaymentAddInput!
  ) {
    stripeV2PaymentAdd(input: $input) {
      stripePayment {
        ...Account_GetPayment
      }
      user {
        id
        paymentIdDefault
        paymentDefault {
          ...Account_GetPayment
        }
        subscriptions {
          CE {
            id
            subscriptionId
            subscriptionStatus
            CECreationDate
            CERenewalDate
            paymentInfo {
              name
              nickname
              brand
              last4
              expMonth
              expYear
              paymentId
            }
          }
        }
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...Account_GetPayment
                }
              }
            }
          }
        }
      }
      userInfo {
        id
        paymentIdDefault
        paymentDefault {
          ...Account_GetPayment
        }
        subscriptions {
          CE {
            id
            subscriptionId
            subscriptionStatus
            CECreationDate
            CERenewalDate
            paymentInfo {
              name
              nickname
              brand
              last4
              expMonth
              expYear
              paymentId
            }
          }
        }
        stripePaymentCollection {
          id
          totalNumberOfStripePayment
          entries(first: 1000) {
            edges {
              node {
                stripePayment {
                  ...Account_GetPayment
                }
              }
            }
          }
        }
      }
    }
  }
  ${GetPaymentFragment}
`;

export default mutation;
