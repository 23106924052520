// @ts-ignore
import * as yup from 'yup';
import { isValidDate, mustBe21OrOlder } from '@onehope/validation';

yup.addMethod(yup.string, 'isValidDate', isValidDate);
yup.addMethod(yup.string, 'mustBe21OrOlder', mustBe21OrOlder);

export const AddContactFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z]/, 'First name is required')
    .required('First name is required'),
  lastName: yup.string().matches(/^[a-zA-Z]/, 'Please enter last name'),
  emailAddress: yup.string().email('The email you entered is not valid'),
  phoneNumber: yup
    .string()
    .matches(
      /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
      'Please enter a valid phone number',
    ),
  dateOfBirth: yup
    .string()
    .isValidDate()
    .mustBe21OrOlder(),
});
