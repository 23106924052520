import { styles as s } from '@onehope/design-system';
import { Spacer, styled } from '@onehope/design-system-v2';
import { IconMenu } from './IconMenu';

import { CustomerAutoshipsContactsV2_viewer_user_customerAutoships_autoships } from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsContactsV2';
import { PhoneNumber } from '@onehope/design-system-ohw';

const { cssConstants } = s;

const GridItem = styled('div')<{
  contactsCount: number;
}>`
  position: relative;
  font-family: ${cssConstants.bodyFontFamily};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  contact-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color:  'white';
  padding: 24px;
  padding-left: 16px;
  max-width: 100%;
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
  @media all and (min-width: 768px) {
    padding: 24px;
    padding-left: 24px};
    max-width: ${({ contactsCount }) => (contactsCount < 3 ? '356px' : '100%')};
  }
`;

const HostContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HostName = styled('div')`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const AutoshipDetails = styled('table')`
  text-align: left;

  tr > * {
    padding-bottom: 8px;
  }

  th {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    padding-right: 8px;
  }

  td {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
`;

interface FourBottleAutoshipCardProps {
  autoship: CustomerAutoshipsContactsV2_viewer_user_customerAutoships_autoships;
  autoshipTotal: number;
  handleManageAutoship: (autoshipId: string, contactId: string) => void;
}

export const FourBottleAutoshipCard = ({
  autoship,
  autoshipTotal,
  handleManageAutoship,
}: FourBottleAutoshipCardProps) => {
  return (
    <GridItem contactsCount={autoshipTotal}>
      <HostContainer>
        <HostName>
          {autoship.firstName} {autoship.lastName}
        </HostName>
      </HostContainer>
      <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
      <AutoshipDetails>
        <tbody>
          <tr>
            <th>Email:</th>
            <td>{autoship.email || 'no email address'}</td>
          </tr>
          <tr>
            <th>Phone Number:</th>
            <td>
              {autoship.mobilePhone ? (
                <PhoneNumber rawValue={autoship.mobilePhone} />
              ) : (
                'no phone number'
              )}
            </td>
          </tr>
          <tr>
            <th>Subscription Name:</th>
            <td>{autoship.autoShipName}</td>
          </tr>
        </tbody>
      </AutoshipDetails>
      <div>
        <IconMenu
          autoship={autoship}
          handleManageAutoship={handleManageAutoship}
        />
      </div>
    </GridItem>
  );
};
