import { makeStyles, ThemeType } from '@onehope/design-system-v2';

export const useCommonStyles = makeStyles((theme: ThemeType) => ({
  navigation: {
    paddingBottom: 32,
  },
  navigationAlt: {
    paddingBottom: 16,
  },
  helperText: {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    fontWeight: 400,
  },
  buttonClose: {
    paddingTop: '48px',
  } as any,
  close: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  form: {
    paddingTop: '24px',
    width: '100%',
  },
  formItem: {
    paddingBottom: '16px',
  },
  loader: {
    padding: '25%',
  },
  closeDialog: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listContainerUnderline: {
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.veryLightGrey.main}`,
  },
  listContainer: {
    padding: '16px 0',
  },
  listText: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    paddingLeft: 16,
  },
  backButtonText: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
    marginLeft: 32,
  },
  labelText: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
    paddingBottom: 16,
  },
  labelTextTop: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
    paddingTop: 24,
  },
  labelSubHeader: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
    paddingTop: 16,
    paddingBottom: 4,
  },
  regularText: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
  },
  underlineText: {
    textDecoration: 'underline',
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
  },
  italicText: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    fontStyle: 'italic',
    padding: '24px 0',
    margin: 0,
  },
  subHeaderText: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.01em',
    padding: 0,
    margin: 0,
    paddingBottom: 8,
  },
  profileImageContainer: {
    paddingBottom: 16,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 16,
    },
  },
  container: {
    paddingBottom: 16,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 24,
    },
  },
  subHeaderContainer: {
    paddingBottom: 16,
  },
  headerText: {
    fontSize: '32px',
    lineHeight: '44px',
    letterSpacing: '0.03em',
    paddingBottom: '16px',
    [theme.breakpoints.up('md')]: {},
  },
  saveAndClose: {
    marginTop: 24,
  },
  iconGridContainer: {
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 24,
    },
  },
  iconGridText: {
    paddingTop: 8,
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
  },
  correctInfoContainer: {
    paddingTop: 24,
  },
  link: {
    color: theme.palette.mud.main,
    fontWeight: 500,
  },
  chunkyButtonImage: {
    height: '48px',
  },
  marginTop16: {
    marginTop: 16,
  },
  iconHungerWrapper: { width: 48, height: 48 },
  listItem: {
    marginLeft: 8,
  },
  listBullet: {
    margin: '0 8px',
  },
  ceStatementContainer: {
    background: theme.palette.lightOrangeTint.main,
    padding: 16,
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'italic',
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0',
    },
  },
  image: {
    maxWidth: '100%',
    margin: '24px 0 0',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0',
    },
  },
  loaderContainer: {
    paddingBottom: 16,
    height: 104,
    [theme.breakpoints.up('sm')]: {
      height: 83,
      paddingBottom: 24,
    },
  },
  buttonActions: {
    width: 327,
    margin: '24px 0 16px 0',
  },
  buttonPadding: {
    marginBottom: 16,
  },
  suggestionsWrapper: {
    margin: '24px 0',
  },
  suggestion: {
    margin: '8px 0',
  },
}));

export const useImageStyles = (url: string) =>
  makeStyles(() => ({
    // to correctly display previously uploaded images (HC)
    // that aren't square
    image: {
      background: `url(${url}) center center / cover no-repeat`,
      width: 48,
      height: 48,
      borderRadius: 100,
    },
  }));
