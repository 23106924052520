import * as React from 'react';

import { Slide, TransitionProps } from '@onehope/design-system-v2';

const SlideTransition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore: Type 'string' is not assignable to type 'ReactElement<any, any>'
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export default SlideTransition;
