import gql from 'graphql-tag';

const mutation = gql`
  mutation CEDash_UserLogoutMutation($input: LogoutInput!) {
    logout(input: $input) {
      viewer {
        id
        isLoggedIn
        user {
          id
          role
          userId
          firstName
          lastName
          permissions {
            id
            id
            oneCommerce {
              admin {
                access
              }
            }
          }
        }
      }
    }
  }
`;

export default mutation;
