import React from 'react';
import styled from '@emotion/styled';
import AntTooltip from 'antd/lib/tooltip';
import 'antd/lib/tooltip/style/index.css';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { primaryColor, font },
} = styles;

interface TooltipProps {
  align?: Object;
  arrowPointAtCenter?: boolean;
  autoAdjustOverflow?: boolean;
  children: any;
  defaultVisible?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  onVisibleChange?: any;
  overlayClassName?: string;
  overlayStyle?: object;
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
  title: string | JSX.Element;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu';
  visible?: boolean;
}

export default function Tooltip(props: TooltipProps) {
  const { children, getPopupContainer, ...rest } = props;

  const TooltipWrapper = styled.div`
    display: inline;
    &:hover {
      cursor: pointer;
    }

    .ant-tooltip-placement-top,
    .ant-tooltip-placement-topLeft,
    .ant-tooltip-placement-topRight {
      .ant-tooltip-arrow {
        border-top-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-bottom,
    .ant-tooltip-placement-bottomLeft,
    .ant-tooltip-placement-bottomRight {
      .ant-tooltip-arrow {
        border-bottom-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-left,
    .ant-tooltip-placement-leftTop,
    .ant-tooltip-placement-leftBottom {
      .ant-tooltip-arrow {
        border-left-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-right,
    .ant-tooltip-placement-rightTop,
    .ant-tooltip-placement-rightBottom {
      .ant-tooltip-arrow {
        border-right-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-top,
    .ant-tooltip-placement-topLeft,
    .ant-tooltip-placement-topRight {
      .ant-tooltip-arrow {
        border-top-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-bottom,
    .ant-tooltip-placement-bottomLeft,
    .ant-tooltip-placement-bottomRight {
      .ant-tooltip-arrow {
        border-bottom-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-left,
    .ant-tooltip-placement-leftTop,
    .ant-tooltip-placement-leftBottom {
      .ant-tooltip-arrow {
        border-left-color: ${primaryColor};
      }
    }

    .ant-tooltip-placement-right,
    .ant-tooltip-placement-rightTop,
    .ant-tooltip-placement-rightBottom {
      .ant-tooltip-arrow {
        border-right-color: ${primaryColor};
      }
    }

    .ant-tooltip-inner {
      min-height: 0;
      padding: 16px;
      background-color: ${primaryColor};
      font-family: ${font};
      font-size: 11px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: center;
      color: white;
    }

    i.anticon {
      position: relative;
      bottom: -2px;
      margin-left: 6px;
    }
  `;

  return (
    <TooltipWrapper>
      {/**
       //@ts-ignore: getPopupContainer ts error*/}
      <AntTooltip getPopupContainer={trigger => trigger.parentNode} {...rest}>
        {children}
      </AntTooltip>
    </TooltipWrapper>
  );
}
