import React, { memo } from 'react';
import {
  styled,
  Icons,
  Text,
  TEXT_DS,
  MenuItem,
  Grid,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  ButtonBase,
  Paper,
} from '@onehope/design-system-v2';

const StyledMenu = styled(MenuList)({
  marginTop: 7,
  width: 220,
  boxShadow: '0px 8px 16px rgba(26, 26, 26, 0.16)',
  borderRadius: '4px',
});

const StyledPopper = styled(Popper)({
  zIndex: 1000,
});

const SortIconWrapper = styled(Icons.ImportExport)``;

const EmptyDiv = styled('div')({
  minWidth: 40,
});

const Check = styled(Icons.CheckMark)({
  width: 40,
});

const StyledMenuItem = styled(MenuItem)({
  width: '100%',
  '&:hover': {
    backgroundColor: 'none',
  },
});

const menuConfig = [
  { id: 'title', text: 'Event Title (A to Z)' },
  { id: 'hostFullName', text: 'Host Name (A to Z)' },
  { id: 'eventType', text: 'Event Type (Tastings first)' },
  { id: 'eventDate', text: 'Event Date (Newest)' },
  { id: 'closeDate', text: 'End Date (Newest)' },
  { id: 'exigoPartyId', text: 'Event ID# (Lowest)' },
  { id: 'donationInfo.ordersSubTotal', text: '$ Sales (Highest)' },
  {
    id: 'donationInfo.nonProfitV2.nonProfitName',
    text: 'Nonprofit Name (A to Z)',
  },
];

const SortMenu = ({
  handleSortEvents,
  sortBy,
}: {
  handleSortEvents: (sortBy: string) => void;
  sortBy: string | null;
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const onClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    value: string,
  ) => {
    handleSortEvents(value);
    onClose(event);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Grid item>
      <ButtonBase
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <SortIconWrapper />
      </ButtonBase>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <StyledMenu
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {menuConfig.map(item => (
                    <StyledMenuItem
                      selected={item.id === sortBy}
                      onClick={e => handleMenuItemClick(e, item.id)}
                      key={item.id}
                    >
                      <Grid container justifyContent="center" key={item.id}>
                        <Grid item>
                          {item.id === sortBy ? <Check /> : <EmptyDiv />}
                        </Grid>
                        <Grid xs>
                          <Text
                            variant="custom"
                            default={TEXT_DS.BODY_REGULAR_14}
                          >
                            {item.text}
                          </Text>
                        </Grid>
                      </Grid>
                    </StyledMenuItem>
                  ))}
                </StyledMenu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </Grid>
  );
};

export default memo(SortMenu);
