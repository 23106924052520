import gql from 'graphql-tag';
import { CEDash_CauseOfChoiceSelectCause } from '../fragments/SelectedCauseFragment';

export default gql`
  query CEDash_DashboardQuery {
    viewer {
      id
      isLoggedIn
      user {
        id
        userId
        firstName
        lastName
        email
        customUrl
        trinityDealerId
        exigoCustomerId
        trinityUplineDealerId
        trinityCurrentRank
        ce {
          id
          ceId
          mentorInfo {
            fullName
            mentorEmail
            phoneNumber
            trinityEnrollDate
            phoneNumber
            trinityCurrentRank
            trinityUplineDealerId
            mentorProfile {
              image
              city
              state
            }
          }
          ceTaskList {
            tasks {
              description
              isCompleted
            }
          }
          ceImpactData {
            years {
              year
              months {
                month
                impactTotal
                causeImpactTotal
              }
            }
            lifetime
            personalCauseLifetime
          }
          ceImpactGoals {
            goals {
              year
              months {
                month
                impactGoal
              }
            }
          }
        }
        profile {
          city
          state
          image
        }
        profileImages {
          id
          thumb
        }
        customUrl
        fastStartEndDate
        trinityEnrollDate
        accountRole {
          id
          accountRoleTitle
        }
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        selectedCause {
          ...CEDash_CauseOfChoiceSelectCause
        }
      }
    }
  }
  ${CEDash_CauseOfChoiceSelectCause}
`;
