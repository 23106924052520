import React, { useState, Fragment } from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import {
  Icons,
  useTheme,
  useMediaQuery,
  IconButton,
  GridV2 as Grid,
  ButtonBase,
} from '@onehope/design-system-v2';
import TabFilter from './TabFilter';
import TablePagination from '../../components/Events/EventsTable/TablePagination';
import TableListView from './TableListView';
import CardListView from './CardListView';
import SearchBar from '../../components/Events/EventsTable/SearchBar';
import OrderAddDialog from '../../components/Events/AddOrderDialog';
import NoOrders from './NoOrders';

import {
  handleSortByFullName,
  handleFilterByTab,
  handleSortByCreateDate,
  handleSortBySubTotalWithDiscounts,
  handleSortByTotal,
  handleSortByStatus,
  handleSortByOrderNumber,
  handleSearchAllOrders,
} from './helpers';
import { EventStatusDictionary } from '../../utils/enums';

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../queries/generatedTypes/CEDash_EventDetailsPage';

import { OrderButton, OrderContainer, OrderText } from './index.styles';

const { cssConstants } = s;
const {
  AddIcon,
  AppsIcon: MuiAppsIcon,
  FormatListBulletedIcon: MuiFormatListBulletedIcon,
} = Icons;

const Container = styled.div`
  position: relative;
  padding: 24px 16px 70px 16px;
  font-family: ${cssConstants.bodyFontFamily};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const GridContainer = styled(Grid)`
  padding: 16px 0 0 0;
  @media all and (min-width: 960px) {
    padding: 0;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const ToggleView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding: 0 0 40px 0;
  }
`;
interface GuestOrdersViewProps {
  orders: (OrdersEdges | null)[] | null;
  allOrders: (OrdersEdges | null)[] | null;
  event?: EventType;
  setOrders: React.Dispatch<
    React.SetStateAction<(OrdersEdges | null)[] | null>
  >;
  noOrdersText?: string;
  contactName?: string | null;
  isOrderDialogOpen?: boolean;
  setIsOrderDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleOrderForCustomer?: () => void;
}

export default function Index(props: GuestOrdersViewProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    isOrderDialogOpen,
    setIsOrderDialogOpen,
    setOrders,
    orders,
    allOrders,
  } = props;
  const [page, setPage] = useState(0);
  const [filterBy, setFilterBy] = useState('open');
  const [search, setSearch] = useState('');
  const [selectedEventId, setSelectedEventId] = useState(
    props?.event?.eventId || '',
  );
  const [nameSortDirection, setNameSortDirection] = useState(false);
  const [orderDateSortDirection, setOrderDateSortDirection] = useState(false);
  const [orderNumberSortDirection, setOrderNumberSortDirection] = useState(
    false,
  );
  const [
    subTotalWithDiscountsSortDirection,
    setSubtotalWithDiscountsSortDirection,
  ] = useState(false);
  const [totalSortDirection, setTotalSortDirection] = useState(false);

  const [isOrdersTableView, setIsOrdersTableView] = useState(
    localStorage.getItem('isOrdersTableView')
      ? localStorage.getItem('isOrdersTableView') === 'true'
      : true,
  );

  const [orderStatusDirection, setOrderStatusSortDirection] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const toggleView = () => {
    localStorage.setItem('isOrdersTableView', `${!isOrdersTableView}`);
    setIsOrdersTableView(!isOrdersTableView);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortOrders = (sortBy: string) => {
    switch (sortBy) {
      case 'accountFullName':
        setOrders(handleSortByFullName(orders, nameSortDirection));
        return setNameSortDirection(!nameSortDirection);
      case 'createDate':
        setOrders(handleSortByCreateDate(orders, orderDateSortDirection));
        return setOrderDateSortDirection(!orderDateSortDirection);
      case 'orderNumber':
        setOrders(handleSortByOrderNumber(orders, orderNumberSortDirection));
        return setOrderNumberSortDirection(!orderNumberSortDirection);
      case 'orderSubTotalAfterDiscounts':
        setOrders(
          handleSortBySubTotalWithDiscounts(
            orders,
            subTotalWithDiscountsSortDirection,
          ),
        );
        return setSubtotalWithDiscountsSortDirection(
          !subTotalWithDiscountsSortDirection,
        );
      case 'orderTotal':
        setOrders(handleSortByTotal(orders, totalSortDirection));
        return setTotalSortDirection(!totalSortDirection);
      case 'orderStatus':
        setOrders(handleSortByStatus(orders, orderStatusDirection));
        return setOrderStatusSortDirection(!orderStatusDirection);
      default:
        return setOrders(handleSortByFullName(orders, nameSortDirection));
    }
  };

  const handleFilterByTabChange = (filterBy: string) => {
    setFilterBy(filterBy);
    setPage(0);
    return setOrders(handleFilterByTab(allOrders, filterBy, search));
  };

  const handleSearchOrders = (search: string) => {
    setSearch(search);
    setPage(0);
    return setOrders(handleSearchAllOrders(allOrders, filterBy, search));
  };

  const ordersCount = (orders && orders.length) || 0;
  const openForOrders =
    props.event?.trinityPartyStatus === EventStatusDictionary.OPEN ||
    props?.contactName;
  return (
    <Fragment>
      <OrderAddDialog
        selectCustomer
        isOpen={isOrderDialogOpen}
        selectedEventId={selectedEventId}
        handleClose={setIsOrderDialogOpen}
      />
      {allOrders && allOrders.length > 0 ? (
        <Fragment>
          <Container>
            <Toolbar>
              <SearchBar
                handleSearch={handleSearchOrders}
                placeHolderText="Search orders"
              />
              <GridContainer container alignItems="center" spacing={1}>
                <Grid item xs>
                  <TabFilter
                    filterBy={filterBy}
                    handleFilterByTabChange={handleFilterByTabChange}
                  />
                </Grid>
              </GridContainer>
              {!isMobile && (
                <ToggleView>
                  <IconButton onClick={toggleView}>
                    {isOrdersTableView ? (
                      <AppsIcon />
                    ) : (
                      <FormatListBulletedIcon />
                    )}
                  </IconButton>
                </ToggleView>
              )}
            </Toolbar>
            {isOrdersTableView && !isMobile ? (
              <TableListView
                page={page}
                orders={orders}
                rowsPerPage={rowsPerPage}
                handleSortOrders={handleSortOrders}
              />
            ) : (
              <CardListView
                page={page}
                orders={orders}
                event={props?.event}
                rowsPerPage={rowsPerPage}
              />
            )}
            <TablePagination
              page={page}
              count={ordersCount}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {openForOrders && (
              <OrderContainer>
                <OrderButton
                  onClick={
                    props?.contactName
                      ? props?.handleOrderForCustomer
                      : setIsOrderDialogOpen
                  }
                >
                  <AddIcon fontSize="small" />
                  <OrderText>ORDER</OrderText>
                </OrderButton>
              </OrderContainer>
            )}
          </Container>
        </Fragment>
      ) : (
        <NoOrders
          noOrdersText={props.noOrdersText}
          openDialog={setIsOrderDialogOpen}
          openForOrders={!!openForOrders}
          contactName={props?.contactName}
          handleOrderForCustomer={props?.handleOrderForCustomer}
        />
      )}
    </Fragment>
  );
}
