import React from 'react';

const SvgCalendar = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6 3.45455H16.4C17.28 3.45455 18 4.10909 18 4.90909V16.5455C18 17.3455 17.28 18 16.4 18H3.6C2.72 18 2 17.3455 2 16.5455V4.90909C2 4.10909 2.72 3.45455 3.6 3.45455H4.4V2H6V3.45455H14V2H15.6V3.45455ZM3.6 16.5455H16.4V7.09091H3.6V16.5455Z"
      fill="#373634"
    />
    <path
      d="M6.8 10C6.8 10.4418 6.44183 10.8 6 10.8C5.55817 10.8 5.2 10.4418 5.2 10C5.2 9.55817 5.55817 9.2 6 9.2C6.44183 9.2 6.8 9.55817 6.8 10Z"
      fill="#373634"
    />
    <path
      d="M10.8 10C10.8 10.4418 10.4418 10.8 10 10.8C9.55817 10.8 9.2 10.4418 9.2 10C9.2 9.55817 9.55817 9.2 10 9.2C10.4418 9.2 10.8 9.55817 10.8 10Z"
      fill="#373634"
    />
    <path
      d="M14 10.8C14.4418 10.8 14.8 10.4418 14.8 10C14.8 9.55817 14.4418 9.2 14 9.2C13.5582 9.2 13.2 9.55817 13.2 10C13.2 10.4418 13.5582 10.8 14 10.8Z"
      fill="#373634"
    />
    <path
      d="M6.8 14C6.8 14.4418 6.44183 14.8 6 14.8C5.55817 14.8 5.2 14.4418 5.2 14C5.2 13.5582 5.55817 13.2 6 13.2C6.44183 13.2 6.8 13.5582 6.8 14Z"
      fill="#373634"
    />
    <path
      d="M10 14.8C10.4418 14.8 10.8 14.4418 10.8 14C10.8 13.5582 10.4418 13.2 10 13.2C9.55817 13.2 9.2 13.5582 9.2 14C9.2 14.4418 9.55817 14.8 10 14.8Z"
      fill="#373634"
    />
    <path
      d="M14.8 14C14.8 14.4418 14.4418 14.8 14 14.8C13.5582 14.8 13.2 14.4418 13.2 14C13.2 13.5582 13.5582 13.2 14 13.2C14.4418 13.2 14.8 13.5582 14.8 14Z"
      fill="#373634"
    />
  </svg>
);

export default SvgCalendar;
