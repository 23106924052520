import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
  WineClub_AutoShipGetQuery_viewer_user_autoShip_winePackItemsSavings_edges,
} from '../../queries/generatedTypes/WineClub_AutoShipGetQuery';

type ProductType =
  WineClub_AutoShipGetQuery_viewer_user_autoShip_winePackItemsSavings_edges & {
    node: NonNullable<
      WineClub_AutoShipGetQuery_viewer_user_autoShip_winePackItemsSavings_edges['node']
    >;
  };

export type BottleQuantity = 0 | 4 | 6 | 12; // TODO: remove 4 after wineClub923

export function splitWinePackItemsIntoSingles(
  winePackItems: {
    node: Pick<ProductType['node'], 'productId' | 'quantity'>;
  }[],
) {
  let items: { node: { quantity: number; productId: string } }[] = [];

  if (!winePackItems?.length) {
    return items;
  }

  winePackItems.forEach((item) => {
    if (item?.node?.quantity && item?.node?.productId) {
      items = items.concat(
        Array(item.node.quantity).fill({
          node: { quantity: 1, productId: item.node.productId },
        }),
      );
    }
  });

  return items;
}

export function getWinePackItemsForBottleQuantityChange(
  winePackItemsInSubscription: {
    node: Pick<ProductType['node'], 'productId' | 'quantity'>;
  }[],
  winePackItemsNotInSubscription: {
    node: Pick<ProductType['node'], 'productId' | 'quantity'>;
  }[],
  newBottleQuantity: BottleQuantity,
) {
  const winePackItemsToSingles = splitWinePackItemsIntoSingles(
    winePackItemsInSubscription,
  );
  const currentBottleQuantity = winePackItemsToSingles.length;

  // don't change item contents if we aren't changing the quantity and don't allow changes to 0 items
  if (
    currentBottleQuantity === newBottleQuantity ||
    currentBottleQuantity < 1 ||
    newBottleQuantity < 1
  ) {
    return winePackItemsInSubscription.concat(winePackItemsNotInSubscription);
  }

  if (currentBottleQuantity < newBottleQuantity) {
    let newWinePackItems = winePackItemsToSingles;
    while (newWinePackItems.length <= newBottleQuantity) {
      newWinePackItems = newWinePackItems.concat(newWinePackItems);
    }

    newWinePackItems = newWinePackItems.slice(0, newBottleQuantity);
    // @ts-ignore
    return newWinePackItems.concat(winePackItemsNotInSubscription);
  }

  if (currentBottleQuantity > newBottleQuantity) {
    let newWinePackItems = winePackItemsToSingles;
    newWinePackItems = newWinePackItems.slice(0, newBottleQuantity);
    // @ts-ignore
    return newWinePackItems.concat(winePackItemsNotInSubscription);
  }

  return winePackItemsInSubscription.concat(winePackItemsNotInSubscription);
}

export function formatWinePackItems(
  winePackItems: {
    node: Pick<ProductType['node'], 'productId' | 'quantity'>;
  }[],
) {
  return winePackItems.map((item) => ({
    productId: item.node.productId,
    quantity: item.node.quantity,
  }));
}

export const getPackSizeByCuratedPackName = (
  curatedPackName: string | null,
) => {
  switch (true) {
    case curatedPackName === 'wineclub4':
      return 4; // TODO: remove after wineClub923
    case curatedPackName === 'wineclub':
      return 6;
    case curatedPackName === 'wineclub12':
      return 12;
    default:
      return 0;
  }
};

export const getCuratedPackNameByPackSize = (size: number | null) => {
  switch (true) {
    case size === 6:
      return 'wineclub';
    case size === 4: // TODO: remove after wineClub923
      return 'wineclub4';
    case size === 12:
      return 'wineclub12';
    default:
      return 'wineclub';
  }
};

export const isSubscriptionEditable = (
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip,
  conciergeMode: boolean,
  contact?: WineClub_AutoShipGetQuery_viewer_user | null,
): { editable: boolean; errorMessage: string } => {
  const { isEditable, ceoAccountFullName } = subscription;
  const isContactCe = contact?.permissions?.oneCommerce?.ceo?.access;
  const subscriptionCe = ceoAccountFullName;

  let editable;
  let errorMessage;

  if (!conciergeMode) {
    // Editing from OHW page
    if (isEditable) {
      editable = true;
      errorMessage = '';
    } else {
      editable = false;
      errorMessage = 'You do not have access to edit this subscription';
    }
  } else {
    // Editing from Wine Rep dash
    if (isEditable) {
      editable = true;
      errorMessage = '';
    } else {
      if (isContactCe) {
        // We cannot update another Wine Rep's subscription
        editable = false;
        errorMessage = 'You cannot manage another CE’s subscription.';
      } else if (subscriptionCe) {
        // We cannot update a subscription owned by another CE
        editable = false;
        errorMessage = `Looks like ${subscriptionCe} is the owner of this subscription. Only the owner is able to manage this subscription`;
      } else {
        // We cannot update a subscription that does not have a CE
        editable = false;
        errorMessage = `Only CEs who have sold the subscription have access to edit this subscription.`;
      }
    }
  }

  return {
    editable,
    errorMessage,
  };
};
