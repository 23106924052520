import {
  segmentCookieV2,
  segmentIdentify,
  defaultSegmentPageView,
  SegmentInput,
} from './segment';

/**
 * Helper function to perform all actions for a Segment Page View.
 */
export const standardPageView = ({
  user,
  isLoggedIn,
  isCeDashboard,
}: SegmentInput) => {
  if (typeof window !== 'undefined' && typeof analytics !== 'undefined') {
    // Send user to Segment to set cookie
    segmentCookieV2({ user, isLoggedIn });

    // Identify user
    segmentIdentify({ user });

    // Send page view
    defaultSegmentPageView({ isCeDashboard });
  }
};
