import { gql } from '@apollo/client';
import { AutoShipFragment } from '../queries/fragments/AutoshipFragment';

const mutation = gql`
  mutation WineClub_AutoShipChangeWinesMutation(
    $input: AutoShipChangeWinesMutationInput!
    $autoShipId: String!
  ) {
    autoShipChangeWines(input: $input) {
      autoShip {
        ...WineClub_GetAutoShip
      }
      user {
        id
        autoShips {
          ...WineClub_GetAutoShip
        }
        autoShip(autoShipId: $autoShipId) {
          ...WineClub_GetAutoShip
        }
      }
    }
  }
  ${AutoShipFragment}
`;

export default mutation;
