import React from 'react';
import { navigate } from 'gatsby';
import { DateTime } from 'luxon';
import Decimal from 'decimal.js';

import { styles as s } from '@onehope/design-system';
import {
  Tooltip,
  Checkbox,
  Icons,
  Spacer,
  styled,
} from '@onehope/design-system-v2';
import IconMenu from '../ContactsTable/IconMenu';
import { ContactType, useContactsContext } from '../ContactsContext';
import { ContactManagementTabs } from '../../ContactV2/ContactDetailsPage/TabContent/tabConstants';
const { CeBrownIcon, RefreshRightArrow, HeartOutline } = Icons;

const { cssConstants } = s;

interface ContactCardProps {
  contact: ContactType;
  contactsCount: number;
}

const GridItem = styled('div')<{
  contactsCount: number;
  selected: boolean;
  isEditing: boolean;
}>`
  position: relative;
  font-family: ${cssConstants.bodyFontFamily};
  color: ${cssConstants.textColor};
  width: 100%;
  height: 100%;
  contact-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: ${props =>
    props.selected ? cssConstants.neutral2 : 'white'};
  padding: 24px;
  padding-left: ${props => (props.isEditing ? '14px' : '16px')};
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    padding-left: ${props => (props.isEditing ? '14px' : '24px')};
    max-width: ${({ contactsCount }) => (contactsCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const HostContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HostName = styled('div')`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const TypeIcon = styled('span')({
  height: '20px',
  width: '20px',
  svg: {
    height: '20px',
    width: '20px',
  },
});

const GridBlock = styled('div')`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding-bottom: 8px;
`;

const CheckboxFlexbox = styled('div')`
  display: flex;
`;

const GridText = styled('span')`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const Container = styled('div')`
  margin-right: 6px;
  padding-left: 0px;
`;

const ContactCard = ({ contact, contactsCount }: ContactCardProps) => {
  const {
    isEditing,
    selectedContacts,
    toggleSelectedContact,
  } = useContactsContext();
  const {
    contactId,
    organizationName,
    firstName,
    lastName,
    isSubscriber,
    isHost,
    isCe,
    emailAddress,
    nextShipmentDate,
    pointBalance,
    lifetimeSpend,
    lastOrder,
    selectedCause,
  } = contact;

  // handle clicked view contact card
  const handleViewContact = () => {
    return navigate(
      `/contact?contactId=${contactId}&tab=${ContactManagementTabs.DETAILS}`,
    );
  };

  return (
    <>
      <GridItem
        contactsCount={contactsCount}
        selected={selectedContacts.includes(contactId)}
        isEditing={isEditing}
      >
        <CheckboxFlexbox>
          {isEditing && (
            <Container>
              <Checkbox
                checked={selectedContacts.includes(contactId)}
                onClick={() => toggleSelectedContact(contactId)}
              />
            </Container>
          )}
          <div onClick={() => handleViewContact()}>
            <HostContainer>
              <HostName>
                {organizationName || `${firstName} ${lastName}`}
              </HostName>
              {isCe && (
                <Tooltip title="Wine Rep" placement="top">
                  <TypeIcon>
                    <CeBrownIcon />
                  </TypeIcon>
                </Tooltip>
              )}
              {isSubscriber && (
                <Tooltip title="Wine Club" placement="top">
                  <TypeIcon>
                    <RefreshRightArrow />
                  </TypeIcon>
                </Tooltip>
              )}
              {isHost && (
                <Tooltip title="Host" placement="top">
                  <TypeIcon>
                    <HeartOutline />
                  </TypeIcon>
                </Tooltip>
              )}
            </HostContainer>
            <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
            <GridBlock>
              Next shipment:{' '}
              <GridText>
                {nextShipmentDate
                  ? DateTime.fromISO(nextShipmentDate, {
                      zone: 'America/Chicago',
                    }).toLocaleString(DateTime.DATE_SHORT)
                  : '---'}
              </GridText>
            </GridBlock>
            <GridBlock>
              Last order:{' '}
              <GridText>
                {lastOrder
                  ? DateTime.fromISO(lastOrder, {
                      zone: 'America/Chicago',
                    }).toLocaleString(DateTime.DATE_SHORT)
                  : '---'}
              </GridText>
            </GridBlock>
            <GridBlock>
              Cause of choice:{' '}
              <GridText>
                {selectedCause?.nonProfit?.nonProfitName ?? '---'}
              </GridText>
            </GridBlock>
            <GridBlock>
              $ raised:{' '}
              <GridText>
                {selectedCause?.donationTotal
                  ? `$${selectedCause.donationTotal.toFixed(2)}`
                  : '$0.00'}
              </GridText>
            </GridBlock>
            <GridBlock>
              Reward points balance:{' '}
              <GridText>
                {pointBalance
                  ? `${pointBalance} ($${new Decimal(pointBalance)
                      .times(0.01)
                      .toFixed(2)})`
                  : '0 ($0.00)'}
              </GridText>
            </GridBlock>
            <GridBlock>
              Lifetime spend:
              <GridText>
                {lifetimeSpend ? ` $${lifetimeSpend.toFixed(2)}` : ' $0.00'}
              </GridText>
            </GridBlock>
          </div>
          <div>
            <IconMenu contact={contact} isCardView />
          </div>
        </CheckboxFlexbox>
      </GridItem>
    </>
  );
};

export default ContactCard;
