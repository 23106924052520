import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';
import CustomerAddMutation from '../../../mutations/Account/ShopWithCustomerAddV2';
import {
  ShopWithCustomerAddV2_shopWithCustomerAddV2_user as UserType,
  ShopWithCustomerAddV2_shopWithCustomerAddV2_newCustomer as NewCustomerType,
} from '../../../mutations/Account/generatedTypes/ShopWithCustomerAddV2';
import ContactCreateMutation from '../../../mutations/ContactsV2/ContactsV2CreateMutation';
import { CreateNewContactV2_contactsV2Create_contact as CreateContactType } from '../../../mutations/ContactsV2/generatedTypes/CreateNewContactV2';
import ContactUpdateMutation from '../../../mutations/ContactsV2/ContactsV2UpdateMutation';
import { MyFormValues } from './MyFormTypes';
import { AddCustomerFormSchema } from './validationSchema';
import AddCustomer from './AddCustomer';
import FormContainer from '../../Event/FormContainer';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { segmentEvents } from '../../../utils/segment/constants';
import { ContactType } from '../../ContactsV3/ContactsContext';

interface AddCustomerFormProps {
  onClose: () => void;
  selectedEventId?: string;
  isCustomerSelected: boolean;
  defaultValues: MyFormValues;
  addCustomerButtonText: string;
  handleSelectedCustomer: (values: MyFormValues) => void;
  contactId?: string;
}

const getFormattedContact = (
  contact: CreateContactType | NewCustomerType,
  user?: UserType,
) => {
  return {
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    fullName: `${contact?.firstName} ${contact?.lastName}`,
    accountId: contact?.accountId,
    email: contact?.email || contact?.emailAddress,
    emailAddress: contact?.email || contact?.emailAddress,
    dateOfBirth: contact?.dateOfBirth,
    phone: contact?.phone || contact?.phoneNumber,
    phoneNumber: contact?.phone || contact?.phoneNumber,
    canShopFor: true,
    ownerAccountId: contact?.ownerAccountId || user?.userId,
    hostCustomerType: 'New Customer',
  };
};

const handleAddCustomer = async ({
  values,
  contactCreate,
  contactUpdate,
  contactId,
  selectedEventId,
  isCustomerSelected,
  shopWithCustomerAdd,
  setErrorNotification,
  setExistingContact,
  handleSelectedCustomer,
  trackEventV2,
}: any) => {
  const { firstName, lastName, email, phone, dateOfBirth } = values;
  try {
    // if customer has been pre-selected, that means we needed more
    // information. Update the contact record accordingly
    if (isCustomerSelected) {
      await contactUpdate({
        variables: {
          contactId,
          contact: {
            emailAddress: email,
            phoneNumber: phone.replace(/[-() ]/g, ''),
            firstName: firstName,
            lastName: lastName,
            dateOfBirth,
          },
        },
      });
      // fire off segment track for contact updated
      trackEventV2({
        event: segmentEvents.contactUpdated,
        properties: {
          contactId,
        },
      });
    } else {
      // else we need to create a new contact record with the info
      const createdContact = await contactCreate({
        variables: {
          contact: {
            emailAddress: email,
            firstName,
            lastName,
            phoneNumber: phone.replace(/[-() ]/g, ''),
            dateOfBirth,
          },
        },
      });
      const contact = createdContact?.data?.contactsV2Create?.contact;
      if (contact?.skipped) {
        const formattedContact = getFormattedContact(contact);
        return setExistingContact(formattedContact);
      }
      // fire off segment track for contact created
      trackEventV2({
        event: segmentEvents.contactCreated,
        properties: {
          accountId: createdContact?.ownerAccountId,
          contactId,
        },
      });
    }

    // fire off the shopWithCustomerAdd mutation (minus permissions)
    // to handle Account checks and creations in the back-end
    const res = await shopWithCustomerAdd({
      variables: {
        customerEmail: email,
        eventId: selectedEventId ? selectedEventId : 'null',
        skipPermissions: true, // skipPermission will skip checks and allow use to retrieve data
      },
    });
    if (!res || !res.data || !res.data.shopWithCustomerAddV2) return;
    const { data } = res;
    const newCustomer = get(data, 'shopWithCustomerAddV2.newCustomer');
    const user = get(data, 'shopWithCustomerAddV2.user');

    // extract the returned account values from mutation
    const customerValues = getFormattedContact(newCustomer, user);
    // pass back the selection to the UI so we can display it
    handleSelectedCustomer(customerValues);
  } catch (errors) {
    const errorMessage = errors?.graphQLErrors?.[0]?.message;
    if (errorMessage.includes('Conflict')) {
      setErrorNotification(
        'A customer with this email or phone number already exists',
      );
    }
  }
};

export default function AddCustomerForm({
  onClose,
  defaultValues,
  isCustomerSelected,
  selectedEventId,
  contactId,
  addCustomerButtonText,
  handleSelectedCustomer,
}: AddCustomerFormProps) {
  const { trackEventV2 } = useTrackingContextValue();
  const [shopWithCustomerAdd, { loading: mutationLoading }] = useMutation(
    CustomerAddMutation,
  );
  const [contactCreate] = useMutation(ContactCreateMutation);
  const [contactUpdate] = useMutation(ContactUpdateMutation);
  const [errorNotification, setErrorNotification] = useState(null);
  const [existingContact, setExistingContact] = useState<ContactType>(null);

  const validDefaultValues = { ...defaultValues } as MyFormValues;
  // Workaround; cannot get initial validation on mount to work. So make sure initial values are valid or empty
  // Phone mask works for newly typed values but does not update the initial value.
  validDefaultValues.phone = defaultValues.phone
    ? defaultValues.phone.replace(/\D/g, '')
    : '';
  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={validDefaultValues}
        validationSchema={AddCustomerFormSchema}
        initialTouched={{
          firstName: !!validDefaultValues.firstName,
          lastName: !!validDefaultValues.lastName,
          emailAddress: !!validDefaultValues.email,
          phoneNumber: !!validDefaultValues.phone,
          dateOfBirth: !!validDefaultValues.dateOfBirth,
        }}
        formToUse={(props: any) => (
          <AddCustomer
            {...props}
            onClose={onClose}
            formTitle={
              isCustomerSelected
                ? 'Complete Missing Information'
                : 'Create a New Contact'
            }
            buttonText={isCustomerSelected ? 'Update' : 'Create'}
            mutationLoading={mutationLoading}
            addCustomerButtonText={addCustomerButtonText}
            errorNotification={errorNotification}
            callback={handleSelectedCustomer}
            existingContact={existingContact}
          />
        )}
        onSubmit={async (values: MyFormValues) => {
          await handleAddCustomer({
            values,
            contactCreate,
            contactUpdate,
            selectedEventId,
            contactId,
            shopWithCustomerAdd,
            isCustomerSelected,
            setErrorNotification,
            setExistingContact,
            handleSelectedCustomer,
            trackEventV2,
          });
        }}
      />
    </div>
  );
}
