import React from 'react';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';

import Layout from '../layout';
import { getCurrentUserId } from '../utils/utils';
import EventDetailsPage from '../components/Event';
import { EventManagementTabs } from '../components/Event/TabNavigation/tabConstants';

const Container = styled.div`
  background-color: white;
  height: 100%;
`;

function EventPage(props: PageRendererProps) {
  const { location } = props;
  const { eventId, tab, contactId, openNewOrder } = queryString.parse(
    location.search,
  );
  return (
    <Layout>
      <Container>
        <EventDetailsPage
          eventId={eventId}
          tab={tab || EventManagementTabs.SUMMARY}
          contactId={contactId}
          openNewOrder={openNewOrder === 'true'}
        />
      </Container>
    </Layout>
  );
}

export default EventPage;
