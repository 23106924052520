import React, { useState, Fragment, Dispatch, SetStateAction } from 'react';
import Header from './Header';
import OrdersView from './OrdersTable';
import { CEDash_GuestOrdersQuery_viewer_user_orders as Orders } from '../../queries/generatedTypes/CEDash_GuestOrdersQuery';

interface OrdersPageWrapperProps {
  orders: Orders;
  pageNumber: number;
  filterBy: string | null;
  setPageNumber: Dispatch<SetStateAction<string | string[]>>;
  handleSortOrders: (sortBy: string) => void;
  setFilterBy: any;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  count: number;
  setSearchBy: any;
}

function OrdersPageWrapper({
  pageNumber,
  filterBy,
  setPageNumber,
  handleSortOrders,
  setFilterBy,
  rowsPerPage,
  setRowsPerPage,
  count,
  setSearchBy,
  orders,
}: OrdersPageWrapperProps) {
  const ordersEdges = orders?.edges;
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);

  return (
    <Fragment>
      <Header setIsOrderDialogOpen={setIsOrderDialogOpen} />
      <OrdersView
        orders={ordersEdges}
        page={Number(pageNumber)}
        filterBy={filterBy ? filterBy : 'all'}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setFilterBy={setFilterBy}
        setRowsPerPage={setRowsPerPage}
        handleSortOrders={handleSortOrders}
        count={count}
        setSearchBy={setSearchBy}
        isOrderDialogOpen={isOrderDialogOpen}
        setIsOrderDialogOpen={setIsOrderDialogOpen}
      />
    </Fragment>
  );
}

export default OrdersPageWrapper;
