import gql from 'graphql-tag';

const USER_QUERY = gql`
  query CEDash_ContactsUserQuery {
    viewer {
      id
      user {
        id
        userId
      }
    }
  }
`;

export default USER_QUERY;
