import React, { FormEvent } from 'react';

import {
  Grid,
  Text,
  TEXT_DS,
  Tooltip,
  styled,
  Icons,
  theme,
  useMediaQuery,
  ButtonV2,
} from '@onehope/design-system-v2';
const {
  AlertCircleOutline,
  CeBrownIcon,
  RefreshRightArrow,
  HeartOutline,
} = Icons;

import { useContactsContext } from '../../ContactsV3/ContactsContext';
import { ContactType } from '../../ContactsV3/ContactsContext';

import { ContactManagementTabs } from './TabContent/tabConstants';

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px',
  flexDirection: 'column',
  '@media all and (min-width: 768px)': {
    padding: '0 40px 0 40px',
    flexDirection: 'row',
  },
});

const TitleWrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
});

const TitleContainer = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: isMobile ? '24px' : '28px',
  gap: '16px',
}));

const WarningContainer = styled(Grid)({
  padding: '0px 0px 24px 0px',
  gap: '10px',
});

const WarningLink = styled(Text)({
  color: theme.palette.forestGreen.main,
  cursor: 'pointer',
});

const ActionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const PlaceOrderButton = styled(ButtonV2)({
  '&&': {
    borderRadius: '1px',
    minWidth: '225px',
  },
});

const TypeIcon = styled('span')({
  height: '24px',
  width: '24px',
  svg: {
    height: '24px',
    width: '24px',
  },
  ':hover': {
    cursor: 'pointer',
  },
});

interface HeaderProps {
  contact?: ContactType;
  editing: boolean;
  handleChange: (event: FormEvent<EventTarget>, value: string) => void;
}

const Header = ({ contact, editing, handleChange }: HeaderProps) => {
  const { handleOrderPermissions } = useContactsContext();
  const paymentError = !!contact?.autoShipPaymentErrors?.length;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <HeaderContainer>
      <TitleWrapper>
        <TitleContainer isMobile={isMobile}>
          <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_24}>
            {contact?.organizationName ||
              `${contact?.firstName} ${contact?.lastName}` ||
              ''}
          </Text>
          {contact?.isCe && (
            <Tooltip title="Wine Rep" placement="top">
              <TypeIcon>
                <CeBrownIcon />
              </TypeIcon>
            </Tooltip>
          )}
          {contact?.isSubscriber && (
            <Tooltip title="Wine Club" placement="top">
              <TypeIcon>
                <RefreshRightArrow />
              </TypeIcon>
            </Tooltip>
          )}
          {contact?.isHost && (
            <Tooltip title="Host" placement="top">
              <TypeIcon>
                <HeartOutline />
              </TypeIcon>
            </Tooltip>
          )}
        </TitleContainer>
        {paymentError && (
          <WarningContainer container alignItems="center" direction="row">
            <AlertCircleOutline />
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              Update Wine Club payment method
            </Text>
            <WarningLink
              variant="custom"
              default={TEXT_DS.BODY_LINK_16}
              onClick={e => handleChange(e, ContactManagementTabs.WINECLUB)}
            >
              {isMobile ? 'Manage' : 'Manage Wine Club'}
            </WarningLink>
          </WarningContainer>
        )}
      </TitleWrapper>
      <ActionsWrapper>
        <PlaceOrderButton
          disabled={editing}
          onClick={() => handleOrderPermissions(contact)}
        >
          NEW ORDER
        </PlaceOrderButton>
      </ActionsWrapper>
    </HeaderContainer>
  );
};

export default Header;
