import React from 'react';
import { Text, makeStyles, ThemeType } from '@onehope/design-system-v2';
import ActivityEntry from '../types/ActivityEntry';

interface Props {
  message: ActivityEntry['message'];
}

const useStyles = makeStyles((theme: ThemeType) => ({
  body: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& p': {
      marginBottom: '0px !important',
    },
  },
}));

export default function Message({ message }: Props) {
  if (!message || typeof message === 'undefined') {
    return null;
  }

  const classes = useStyles();
  return (
    <Text className={classes.body}>
      {message.map(p => (
        <p key={p}>{p}</p>
      ))}
    </Text>
  );
}
