export const formatTrackUpdateCauseOfChoiceProps = ({ properties }: any) => {
  const { selectedCause, causeType, updateLocation, isConcierge } = properties;

  let trackProps = {};
  if (selectedCause) {
    trackProps = {
      causeId: selectedCause.causeId,
      causeName: selectedCause.nonProfit.nonProfitName,
      causeType,
      isConcierge: !!isConcierge,
      nonProfitId: selectedCause.nonProfit.nonProfitId,
      updateLocation,
    };
  }

  return trackProps;
};

export const formatTrackLinkClickProps = ({ properties }) => {
  const { name: linkName, url: linkUrl } = properties;
  return {
    linkName,
    linkUrl,
    linkLocation: 'CE Dashboard',
  };
};
