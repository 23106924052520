import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';

import {
  Grid,
  DialogV2,
  DialogV2Props,
  Text,
  TEXT_DS,
  DatePickerContainer,
  makeStyles,
  ThemeType,
} from '@onehope/design-system-v2';

import ShipmentProcessedWarning from '../ShipmentWarnings/ShipmentProcessedWarning';
import useToggle from '../../../utils/useToggleHook';

import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../../utils/segment';

import AUTO_SHIP_POSTPONE_MUTATION from '../../../mutations/AutoshipPostponeMutation';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';

interface ChangeShipmentDateProps {
  user: WineClub_AutoShipGetQuery_viewer_user;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  open: boolean;
  onClose: () => void;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
}

interface SeasonDict {
  spring: number[];
  summer: number[];
  fall: number[];
  winter: number[];
  [key: string]: number[];
}

const seasonDictionary: SeasonDict = {
  spring: [2, 3, 4],
  summer: [5, 6, 7],
  fall: [8, 9, 10],
  winter: [11, 12, 1],
};

export const getSeasonByMonth = (month: number) => {
  switch (true) {
    case seasonDictionary.spring.includes(month):
      return 'spring';
    case seasonDictionary.summer.includes(month):
      return 'summer';
    case seasonDictionary.fall.includes(month):
      return 'fall';
    case seasonDictionary.winter.includes(month):
      return 'winter';
    default:
      return null;
  }
};

const getCuratedSeasonStartDate = (date: DateTime): DateTime | null => {
  const season = getSeasonByMonth(date.get('month'));

  if (!season) return null;
  const seasonStartMonth = seasonDictionary[season][0];
  const timezoneOffset = season === 'spring' ? 6 : 5;

  const startOfSeason = DateTime.fromObject({
    year: date.year,
    month: seasonStartMonth,
    day: 1,
  });

  return startOfSeason.plus({ hours: timezoneOffset });
};

const useStyles = makeStyles((theme: ThemeType) => ({
  calendarMargin: {
    margin: '-12px',
  },
  sameShipmentBackground: {
    backgroundColor: theme.palette.lightYellowTint.main,
    padding: '16px',
  },
}));

const handleChangeShipmentDate = ({
  autoShipId,
  currentDate,
  postponeDate,
  AutoShipPostponeMutation,
  setClosed,
  setPostponeMutationComplete,
  setPostponeMutationLoading,
  user,
  conciergeMode,
  sendToTrackEventV2,
}: {
  autoShipId: string | null;
  currentDate: string;
  postponeDate: string;
  AutoShipPostponeMutation: any;
  setClosed: () => void;
  setPostponeMutationLoading: () => void;
  setPostponeMutationComplete: () => void;
  user: WineClub_AutoShipGetQuery_viewer_user;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
}) => {
  return () => {
    setPostponeMutationLoading();

    const currentIsoDate = DateTime.fromFormat(currentDate, 'MM/dd/yyyy')
      ?.toISO()
      ?.toString();

    const isoDate = DateTime.fromFormat(postponeDate, 'MM/dd/yyyy')
      ?.toISO()
      ?.toString();

    const variables = {
      autoShipId,
      input: {
        autoShipId,
        postponeDate: isoDate,
      },
    };
    AutoShipPostponeMutation({
      variables,
    })
      .then(() => {
        // send to segment
        sendToTrackEventV2 &&
          sendToTrackEventV2({
            event: SubscriptionSegmentEvents.wineClubModified,
            properties: {
              concierge: conciergeMode,
              autoShipId,
              accountId: user?.userId,
              ceAccountId: conciergeMode ? user?.userId : '',
              propertyUpdated: SubscriptionSegmentProperty.changeShipmentDate,
              values: {
                previousValue: currentIsoDate,
                newValue: isoDate,
              },
            },
          });
        setPostponeMutationComplete();
        setClosed();
      })
      .catch((error: any) => {
        console.log('error', error);
        setPostponeMutationComplete();
      });
  };
};

const ChangeShipmentDateDialog = ({
  user,
  subscription,
  open,
  onClose,
  conciergeMode,
  sendToTrackEventV2,
}: ChangeShipmentDateProps) => {
  const { calendarMargin, sameShipmentBackground } = useStyles();
  const {
    autoShipId,
    nextPaymentDate: nextShipmentDate,
    latestSuccessfulInvoice,
    isCuratedWineClub,
  } = subscription;

  const shipmentProcessing =
    subscription?.latestInvoice?.invoiceStatus === 'pending';

  const currentNextShipmentDate = nextShipmentDate
    ? DateTime.fromISO(nextShipmentDate)?.toFormat('MM/dd/yyyy')?.toString() ||
      ''
    : '';

  const [selectedPostponeDate, setSelectedPostponeDate] = useState(
    currentNextShipmentDate,
  );

  // keeps the calendar in sync when the subscription gets updated elsewhere
  useEffect(() => {
    setSelectedPostponeDate(currentNextShipmentDate);
  }, [nextShipmentDate]);

  const [showShipmentProcessingWarning, setShowShipmentProcessingWarning] =
    useState(false);

  const lastInvoiceSeasonStartDate =
    isCuratedWineClub && latestSuccessfulInvoice?.dateCreated
      ? getCuratedSeasonStartDate(
          DateTime.fromISO(latestSuccessfulInvoice.dateCreated),
        )
      : null;

  const isSameShipment = useMemo(() => {
    if (lastInvoiceSeasonStartDate) {
      const seasonStartDate = getCuratedSeasonStartDate(
        DateTime.fromFormat(selectedPostponeDate, 'MM/dd/yyyy'),
      );
      if (seasonStartDate) {
        return lastInvoiceSeasonStartDate.equals(seasonStartDate);
      }
    }
    return false;
  }, [lastInvoiceSeasonStartDate, selectedPostponeDate]);

  const minDate = DateTime.now().plus({ days: 1 }).toISO(); // can't select before tomorrow
  const maxDate = DateTime.now().plus({ months: 3 }).toISO(); // can't select past 3 months from today

  const {
    value: postponeMutationLoading,
    setTrue: setPostponeMutationLoading,
    setFalse: setPostponeMutationComplete,
  } = useToggle();

  const [AutoShipPostponeMutation] = useMutation(AUTO_SHIP_POSTPONE_MUTATION);

  const dispatchHandleChangeShipmentDate = handleChangeShipmentDate({
    AutoShipPostponeMutation,
    autoShipId,
    currentDate: currentNextShipmentDate,
    postponeDate: selectedPostponeDate,
    setClosed: onClose,
    setPostponeMutationLoading,
    setPostponeMutationComplete,
    user,
    conciergeMode,
    sendToTrackEventV2,
  });

  const onSaveCallback = useCallback(async () => {
    if (showShipmentProcessingWarning) {
      return dispatchHandleChangeShipmentDate();
    }

    return shipmentProcessing
      ? setShowShipmentProcessingWarning(true)
      : dispatchHandleChangeShipmentDate();
  }, [
    shipmentProcessing,
    setShowShipmentProcessingWarning,
    dispatchHandleChangeShipmentDate,
  ]);

  const onExitedCallback = useCallback(() => {
    setShowShipmentProcessingWarning(false);
  }, [setShowShipmentProcessingWarning]);

  const onPostponeDateSelected = (value: DateTime) => {
    setSelectedPostponeDate(value?.toFormat('MM/dd/yyyy').toString());
  };

  const {
    title,
    saveButtonLabel,
    closeButtonLabel,
    buttonDirection,
  }: Partial<DialogV2Props> = useMemo(() => {
    if (showShipmentProcessingWarning) {
      return {
        title: 'Heads Up!',
        saveButtonLabel: 'Yes, Continue',
        closeButtonLabel: 'No, Never Mind',
        buttonDirection: 'column',
      };
    }

    return {
      title: 'Change Shipment Date',
      saveButtonLabel: 'Confirm',
      closeButtonLabel: 'Cancel',
      buttonDirection: 'row',
    };
  }, [showShipmentProcessingWarning]);

  const nextSeasonStartDate = useMemo(() => {
    const seasonStartDate = getCuratedSeasonStartDate(DateTime.now());
    if (!seasonStartDate) {
      return;
    }
    return seasonStartDate
      .plus({
        months: 3,
      })
      .toFormat('MM/dd/yyyy');
  }, []);

  const nextSeasonsDateText = nextSeasonStartDate
    ? `; otherwise, please select a date that is on or after ${nextSeasonStartDate}`
    : '';

  return (
    <DialogV2
      open={open}
      saveButtonLabel={saveButtonLabel}
      onClose={onClose}
      TransitionProps={{ onExited: onExitedCallback }}
      closeButtonLabel={closeButtonLabel}
      title={title}
      onSave={onSaveCallback}
      onSaveLoading={postponeMutationLoading}
      buttonDirection={buttonDirection}
    >
      <>
        {showShipmentProcessingWarning && <ShipmentProcessedWarning />}
        {!showShipmentProcessingWarning && (
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="column" spacing={1}>
              <Grid item>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                  Next order will ship on: {selectedPostponeDate}
                </Text>
              </Grid>
              <Grid item className={calendarMargin}>
                <DatePickerContainer
                  maxDate={maxDate}
                  minDate={minDate}
                  value={selectedPostponeDate}
                  onChange={onPostponeDateSelected}
                  variant="static"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
            {isSameShipment && (
              <Grid className={sameShipmentBackground}>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                  {`Your new shipment date will include this season's Wine Club
                  selection. You have already received this season's shipment.
                  If you are okay with receiving the same selection of wines
                  twice, click "confirm" to proceed${nextSeasonsDateText}`}
                </Text>
              </Grid>
            )}
            <Grid item>
              <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_ITALIC_14}>
                You may select a date up to three months after your next
                scheduled shipment date.
              </Text>
            </Grid>
          </Grid>
        )}
      </>
    </DialogV2>
  );
};

export default ChangeShipmentDateDialog;
