import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Dialog,
  Grid,
  makeStyles,
  ThemeType,
  useTheme,
  useMediaQuery,
  ButtonV2,
} from '@onehope/design-system-v2';
import useToggle from '../../../utils/useToggleHook';
import AddressesSelectView, {
  findSelectedAddress,
} from './AddressesSelectView';
import { CloseIcon } from '../../_shared/formStyles';
import { useCommonStyles } from '../../_shared/styles';
import ADDRESSES_GET_QUERY from '../../../queries/AddressesGetQuery';
import AddressEditView from './AddressEditView';
import AddressAddView from './AddressAddView';
import _sortBy from 'lodash/sortBy';

import { Account_GetAutoShip as SubscriptionType } from '../../../queries/fragments/generatedTypes/Account_GetAutoShip';
import { Account_AddressesGetQuery_viewer_user_addressBook_entries_edges_node_address as AddressSummaryType } from '../../../queries/generatedTypes/Account_AddressesGetQuery';

export interface AddressType {
  node: {
    id: string;
    address: AddressSummaryType;
  };
}

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: { width: '600px' },
  },
}));

function getSortedAddresses(
  addresses: Array<AddressType>,
  addressId: string | null,
) {
  return _sortBy(addresses, (address) => {
    return address?.node?.address?.addressId !== addressId;
  });
}

const AddressesBookWrapper = ({
  currentAddressId,
  subscription,
  contactId = '',
  sendToTrackEventV2,
}: {
  currentAddressId: string | null;
  subscription: SubscriptionType;
  contactId?: string;
  sendToTrackEventV2?: any;
}) => {
  const { data, loading } = useQuery(ADDRESSES_GET_QUERY, {
    variables: { contactId },
    fetchPolicy: 'network-only',
  });

  const addresses = contactId
    ? data?.viewer?.userInfo?.addressBook
    : data?.viewer?.user?.addressBook;

  const [editAddressId, setEditAddressId] = useState(currentAddressId);
  const [selectedAddress, setSelectedAddress] =
    useState<AddressSummaryType | null>(null);
  const classes = useCommonStyles();
  const dialogClasses = useDialogStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [view, setView] = useState('SELECT');
  const { value: open, setTrue: setOpen, setFalse: setClose } = useToggle();
  const {
    value: isLoading,
    setTrue: setLoadingTrue,
    setFalse: setLoadingFalse,
  } = useToggle();
  const handleClose = () => {
    if (!isLoading) {
      setClose();
      setSelectedAddress(null);
      setView('SELECT');
    }
  };

  return (
    <>
      <ButtonV2 type="textLink" className={classes.button} onClick={setOpen}>
        Edit
      </ButtonV2>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={dialogClasses}
        fullScreen={isMobile}
      >
        {!loading && (
          <Grid container>
            <CloseIcon onClick={handleClose} />
            {view === 'SELECT' && (
              <AddressesSelectView
                setLoadingTrue={setLoadingTrue}
                setLoadingFalse={setLoadingFalse}
                setView={setView}
                selectedAddress={
                  selectedAddress ||
                  findSelectedAddress(
                    currentAddressId,
                    addresses?.entries?.edges,
                  )?.node?.address
                }
                setSelectedAddress={setSelectedAddress}
                setEditAddressId={setEditAddressId}
                closeDialog={handleClose}
                subscription={subscription}
                currentAddressId={currentAddressId}
                addresses={getSortedAddresses(
                  addresses?.entries?.edges,
                  currentAddressId,
                )}
                sendToTrackEventV2={sendToTrackEventV2}
              />
            )}
            {view === 'EDIT' && (
              <AddressEditView
                addresses={addresses?.entries?.edges}
                setView={setView}
                setSelectedAddress={setSelectedAddress}
                editAddressId={editAddressId}
                setLoadingTrue={setLoadingTrue}
                setLoadingFalse={setLoadingFalse}
                contactId={contactId}
              />
            )}
            {view === 'ADD' && (
              <AddressAddView
                setView={setView}
                setSelectedAddress={setSelectedAddress}
                setLoadingTrue={setLoadingTrue}
                setLoadingFalse={setLoadingFalse}
                contactId={contactId}
              />
            )}
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default AddressesBookWrapper;
