import * as React from 'react';
import styled from '@emotion/styled';

import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../common/Loading';
import { StraightLine } from '../index.styles';
import OrderCard from '../../../../common/Orders/OrderCard';
import { CEDash_HostRewardsOrdersQuery_viewer_user_orders_edges as OrderEdge } from '../../../../queries/generatedTypes/CEDash_HostRewardsOrdersQuery';
import { GET_HOST_REWARDS_ORDERS_QUERY } from '../../../../queries/HostRewards';

const Grid = styled.div`
  padding: 10px 0;
`;

const GridList = styled.div<{ orderCount: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 24px;
  grid-auto-flow: dense;
  max-width: ${({ orderCount }) => (orderCount < 3 ? '756px' : '100%')};
`;

interface HostRewardsRedeemedOrdersProps {
  hostRewardsOrderIds?: (string | null)[] | null;
}

export default function HostRewardsRedeemedOrders(
  props: HostRewardsRedeemedOrdersProps,
) {
  const { hostRewardsOrderIds } = props;

  const noHostRewardOrderIds =
    !hostRewardsOrderIds || !hostRewardsOrderIds.length;

  const { data, loading, error } = useQuery(GET_HOST_REWARDS_ORDERS_QUERY, {
    variables: { orderIds: hostRewardsOrderIds },
    skip: noHostRewardOrderIds,
  });

  if (noHostRewardOrderIds) {
    return null;
  }

  if (loading) return <Loading />;
  if (error) return <div>`Error! ${error.message}`</div>;
  if (get(data, 'viewer.user')) {
    const { orders } = data.viewer.user;
    const orderCount = orders.edges.length;
    return (
      <>
        <StraightLine />
        <Grid>
          <GridList orderCount={orderCount}>
            {orders.edges.map(({ node: order }: OrderEdge) => (
              <OrderCard
                key={`${order?.id}`}
                order={order}
                orderCount={orderCount}
              />
            ))}
          </GridList>
        </Grid>
      </>
    );
  }
  return null;
}
