import React from 'react';
import styled from '@emotion/styled';

import Decimal from 'decimal.js';
import get from 'lodash/get';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Button as MuiButton,
  Text,
  TEXT_DS,
  theme,
} from '@onehope/design-system-v2';
import { Currency } from '@onehope/design-system';

import SHOP_WITH_CUSTOMER_V2_MUTATION from '../../../../mutations/Account/ShopWithCustomerAddV2';
import SHOP_WITH_CUSTOMER_DELETE_MUTATION from '../../../../mutations/ContactsV2/CustomerShopDeleteMutation';
import { GET_HOST_REWARDS_QUERY } from '../../../../queries/HostRewards';

import Loading from '../../../../common/Loading';
import TrinityHostRewardsRedeemed from './TrinityHostRewardsRedeemed';
import HostRewardsRedeemedOrders from './HostRewardsRedeemedOrders';
import { EventStatusDictionary } from '../../../../utils/enums';

import {
  DetailsContainer,
  DetailsTitle,
  StraightLine,
  TabContentContainer,
} from '../index.styles';
import SummaryLine from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine';

import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { useContactsContext } from '../../../ContactsV3/ContactsContext';
import { segmentEvents } from '../../../../utils/segment/constants';

const Container = styled.div`
  padding: 0 16px;
`;

const TextLine = styled(Text)`
  && {
    color: ${theme.palette.greyDark.main};
    padding-top: 8px;
  }
`;

const Button = styled(MuiButton)`
  && {
    margin-top: 40px;
  }
`;

function roundUpOrDown(decimal: Decimal | number): number {
  return parseFloat(new Decimal(decimal).toFixed(2, Decimal.ROUND_HALF_UP));
}

const formatRewardsPoints = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

interface HostRewardsProps {
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
}

export default function HostRewardsDetails(props: HostRewardsProps) {
  const { event, user } = props;
  const { userId: loggedInUserId } = user;
  const {
    status,
    eventId,
    accountId,
    hostAccountId,
    hostEmail,
    trinityHostRewardsOrderId,
    hostRewardsOrderIds,
    hostRewardsQualifiedSubTotal,
  } = event;

  const subtotal = hostRewardsQualifiedSubTotal || 0;

  const [shopWithCustomerV2Mutation] = useMutation(
    SHOP_WITH_CUSTOMER_V2_MUTATION,
  );
  const [shopWithCustomerDeleteMutation] = useMutation(
    SHOP_WITH_CUSTOMER_DELETE_MUTATION,
  );

  const { data, loading, error } = useQuery(GET_HOST_REWARDS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { eventId },
  });

  const { trackEventV2 } = useTrackingContextValue();
  const {
    loadContact,
    togglePermissionState,
    toggleCEWarningState,
  } = useContactsContext();

  /* Return early if we just need to display Trinity Host Rewards */
  if (trinityHostRewardsOrderId) {
    return (
      <Container>
        <TabContentContainer>
          <TrinityHostRewardsRedeemed
            trinityHostRewardsOrderId={trinityHostRewardsOrderId}
            hostRewardsQualifiedSubTotal={subtotal}
          />
        </TabContentContainer>
      </Container>
    );
  }

  /* If the Host Rewards would be from our system */
  if (loading) return <Loading />;
  if (error) return <div>`Error! ${error.message}`</div>;

  if (get(data, 'viewer.event')) {
    const qualified = subtotal >= 500;
    const loadedBalance = qualified
      ? new Decimal(subtotal).times(0.1).toNumber()
      : 0;
    const hasPointsIssued = !!data?.viewer?.event?.hostRewardPointsIssued;
    const hostRewardPointsIssued = hasPointsIssued
      ? data?.viewer?.event?.hostRewardPointsIssued
      : roundUpOrDown(loadedBalance * 100);

    const isQualifiedOpenEvent =
      qualified && status === EventStatusDictionary.OPEN;
    const hostRewardsExpiredInTrinity =
      qualified && !hasPointsIssued && status !== EventStatusDictionary.OPEN;
    const statusText = qualified ? 'Qualified' : 'Not qualified for rewards';
    const toolTipText = qualified
      ? 'This event has qualified to earn reward points since the subtotal of the orders is at least $500'
      : 'The event must earn $500 in sales to qualify to earn reward points';

    const noop = () => {};

    const redirectToShop = () => {
      return window.open(`${process.env.GATSBY_MEGALITH_URL}`, '_blank');
    };

    const conciergeShop = async (customerId: string) => {
      const variables = {
        customerEmail: hostEmail,
        eventId: event?.eventId,
      };
      try {
        const res = await shopWithCustomerV2Mutation({ variables });
        const shopWithCustomerData = res?.data?.shopWithCustomerAddV2;
        // segment tracking
        const isNewCustomer =
          shopWithCustomerData.newCustomer?.ordersCount === 0;
        trackEventV2({
          event: segmentEvents.conciergeModeOrderStarted,
          properties: {
            eventId: event?.eventId || null,
            shoppingForId: customerId,
            isNewCustomer,
          },
        });
        redirectToShop();
      } catch (errors) {
        // handling concierge contact error checks
        const errorMessage = errors?.graphQLErrors?.[0]?.message;
        if (
          errorMessage.includes(
            'Sorry, this user is a Wine Rep and cannot be added as your customer',
          )
        ) {
          toggleCEWarningState(true);
        } else if (
          errorMessage.includes(
            'This account requires permission to shop with the given Wine Rep',
          ) ||
          errorMessage.includes(
            'Customer is already shopping with a different Wine Rep',
          )
        ) {
          // load contact for permission modal
          loadContact({
            ownerAccountId: accountId || '',
            accountId: hostAccountId || '',
          });
          return togglePermissionState(true);
        }
      }
    };

    const shopForSelf = () => {
      return shopWithCustomerDeleteMutation({ variables: { input: {} } }).then(
        redirectToShop,
      );
    };

    const getShopOptions = () => {
      const shopOptions = { buttonText: '', onClick: noop };
      if (!hasPointsIssued) {
        return shopOptions;
      }
      switch (true) {
        case hostAccountId === accountId:
          // Shop as self, not in concierge mode.
          shopOptions.buttonText = 'SHOP';
          shopOptions.onClick = shopForSelf;
          break;
        default:
          shopOptions.buttonText = 'SHOP FOR HOST';
          shopOptions.onClick = () => conciergeShop(hostAccountId || '');
          break;
      }
      // Dont allow any admins to perform these actions since the mutations arent built for it yet.
      if (loggedInUserId !== accountId) {
        shopOptions.onClick = () =>
          alert(
            'You are not the Wine Rep for this customer so you cannot perform this action',
          );
      }
      return shopOptions;
    };

    const shopOptions = getShopOptions();

    return (
      <Container>
        <TabContentContainer>
          <DetailsContainer>
            <DetailsTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
              Host Rewards
            </DetailsTitle>
            <StraightLine />
            <SummaryLine
              width={140}
              title="Status"
              value={statusText}
              valueToolTipText={toolTipText}
            />
            <SummaryLine
              width={140}
              title="Qualified Sales total"
              value={<Currency rawValue={subtotal} />}
            />
            <SummaryLine
              width={140}
              title="Reward points"
              value={`${
                hostRewardPointsIssued
                  ? formatRewardsPoints(hostRewardPointsIssued)
                  : 0
              } ($${(hostRewardPointsIssued / 100 || 0).toFixed(2)})`}
            />
            <SummaryLine width={140} title="Redeemable by" value={hostEmail} />
            {hasPointsIssued && (
              <Button disableRipple fullWidth onClick={shopOptions.onClick}>
                {shopOptions.buttonText}
              </Button>
            )}

            {isQualifiedOpenEvent && (
              <>
                <TextLine variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                  Once this event has closed, the points earned from the event
                  may be used as a credit towards the Host’s next purchase.
                </TextLine>
              </>
            )}

            {hostRewardsExpiredInTrinity && (
              <TextLine variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                Your host rewards credit expired in the Back Office. For
                questions, contact support.
              </TextLine>
            )}
          </DetailsContainer>
          <HostRewardsRedeemedOrders
            hostRewardsOrderIds={hostRewardsOrderIds}
          ></HostRewardsRedeemedOrders>
        </TabContentContainer>
      </Container>
    );
  }
  return null;
}
