// @mui
import { memo } from 'react';
import {
  useTheme,
  Text,
  Box,
  styled,
  TEXT_DS,
  Icons,
  GridV2 as Grid,
} from '@onehope/design-system-v2';

// components
import Chart, { useChart } from '../Chart';
import { BannerContainer, BannerText, FastStartCheck } from './RingComponents';

// types
import { CEDash_FastStartQuery_viewer_v3_fastStart23_rings as Ring } from '../../../queries/generatedTypes/CEDash_FastStartQuery';

// @ts-ignore
import CE_Lock from './assets/lock.png';
// @ts-ignore
import CE_Unlock from './assets/un-lock.png';

interface Props {
  ring: Ring;
}
const CHART_SIZE = { width: 142, height: 142 };

const SecondaryText = styled(Text)(({ theme, ringInProgress }) => ({
  color: ringInProgress
    ? theme.palette.forestGreen.main
    : theme.palette.greyDark.main,
}));

function ChartColumn({ ring }: Props) {
  const theme = useTheme();

  const chartColors = ['#3C5D62', '#4CB9C3'];

  const isCapstoneRing = ring?.isCapstoneRing;
  const ringComplete = ring?.completed;
  const primaryText = !isCapstoneRing ? 'Sales:' : null;

  const completionAmount = Math.floor(ring?.percentComplete);
  const isRingEmpty = ring?.salesValue === 0;

  const secondaryText = isCapstoneRing
    ? `${completionAmount}% Complete`
    : `$${Math.floor(ring?.salesValue / 100)}/$${ring?.salesGoal / 100}`;
  const bannerText = `$${ring?.bonusAmount / 100} Bonus`;
  const ringInProgress = ring?.salesValue > 0 || ringComplete;
  const sponsorShipComplete = ring?.sponsorshipValue === ring?.sponsorshipGoal;

  const startAngle = completionAmount === 0 ? 0 : -180;
  const endAngle = completionAmount === 0 ? 360 : 180;

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        colorStops: [
          { offset: 0, color: chartColors[0] },
          { offset: 100, color: chartColors[1] },
        ],
      },
    },
    stroke: {
      lineCap: 'round',
      curve: 'smooth',
    },
    plotOptions: {
      radialBar: {
        startAngle,
        endAngle,
        hollow: {
          size: '58%',
          margin: 6,
          image: isCapstoneRing ? (ringComplete ? CE_Unlock : CE_Lock) : null,
          imageWidth: 68,
          imageHeight: 68,
          imageClipped: false,
          background: isCapstoneRing
            ? null
            : sponsorShipComplete
            ? theme.palette.gold.light
            : theme.palette.greenTintColor.dark,
        },
        track: {
          startAngle,
          endAngle,
          margin: 0,
          background: isCapstoneRing
            ? theme.palette.greyLight.main
            : theme.palette.greenTintColor.dark,
          dropShadow: {
            enabled: !isRingEmpty && !isCapstoneRing,
            top: 0,
            left: 0,
            blur: 3,
            color: 'rgba(0, 0, 0, 0.4)',
            opacity: 0.3,
          },
        },
        dataLabels: {
          show: true,
          name: { offsetY: 0, show: !isCapstoneRing },
          value: {
            show: !isCapstoneRing,
            offsetY: 0,
            color: ringComplete
              ? theme.palette.goldStar.main
              : theme.palette.greyDark.main,
          },
          total: {
            show: !isCapstoneRing,
            label: 'Wine Rep',
            color: ringComplete
              ? theme.palette.goldStar.main
              : theme.palette.greyDark.main,
            formatter: function() {
              return `${ring?.sponsorshipValue} / ${ring?.sponsorshipGoal}`;
            },
          },
        },
      },
    },
  });

  return (
    <Grid
      item
      xs
      sx={{
        mr: '-8px',
        ml: '-8px',
        display: 'flex',
        pb: '24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BannerContainer>
        <Icons.FastStartBanner
          fill={
            isCapstoneRing
              ? theme.palette.blossom.main
              : theme.palette.bronzeTint.main
          }
        />
        <BannerText variant="custom" default={TEXT_DS.TINY_SEMIBOLD_12}>
          {bannerText}
          {ringComplete && <FastStartCheck />}
        </BannerText>
      </BannerContainer>
      <Chart
        type="radialBar"
        series={[ring?.percentComplete]}
        options={chartOptions}
        {...CHART_SIZE}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '-8px',
        }}
      >
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_14}>
          {primaryText}
        </Text>
        <SecondaryText
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_16}
          theme={theme}
          ringInProgress={ringInProgress}
        >
          {secondaryText}
        </SecondaryText>
      </Box>
    </Grid>
  );
}

export default memo(ChartColumn);
