import { gql } from '@apollo/client';

const USER_HUBSPOT_CARE_TEAMS = gql`
  query CEDash_UserHubSpotCareTeamsQuery {
    viewer {
      id
      user {
        id
        hubspotCareTeam {
          id
          businessCoordinatorContactOwner {
            id
            fullName
            profile {
              image
            }
          }
          cddContactOwner {
            id
            fullName
            profile {
              image
            }
          }
          supportCoordinatorLeadContactOwner {
            id
            fullName
            profile {
              image
            }
          }
          supportCoordinatorContactOwner {
            id
            fullName
            profile {
              image
            }
          }
          supportCoordinators {
            id
            fullName
            profile {
              image
            }
          }
        }
      }
    }
  }
`;

export default USER_HUBSPOT_CARE_TEAMS;
