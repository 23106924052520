import React from 'react';
import {
  ApolloProvider,
  ApolloConsumer,
  QueryHookOptions,
} from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';

import { CauseOfChoiceModal } from '@onehope/cause-of-choice-modal';

import {
  ButtonBase,
  ChunkyButton,
  Grid,
  Icons,
  LoaderDark,
  makeStyles,
  ThemeType,
  Text,
  TEXT_DS,
} from '@onehope/design-system-v2';
import { formatNonProfitAddress } from '@onehope/utils';

import { ohwImageUploads } from '../../../utils/constants';
import { CauseOfChoiceScreenDictionary } from '../../../common/CauseOfChoiceModalWrapper';
import useToggle from '../../../utils/useToggleHook';
import { client } from '../../../apollo/client';

import NON_PROFIT_V2_QUERY from '../../../queries/NonProfitV2';
import NON_PROFIT_EDIT_QUERY from '../../../queries/NonProfitEdit';

import { CEDash_NonProfitV2_viewer_v2_nonProfits_edges_node as NonProfitV2Type } from '../../../queries/generatedTypes/CEDash_NonProfitV2';
import { CEDash_NonProfitEdit_viewer_v2_nonProfitEdit as NonProfitEditType } from '../../../queries/generatedTypes/CEDash_NonProfitEdit';

const useStyles = makeStyles((theme: ThemeType) => ({
  label: {
    // marginTop: 40,
    marginBottom: 8,
  },
  container: {
    marginBottom: 16,
  },
  button: {
    padding: 16,
    border: `1px solid #C2C6C9`,
    borderRadius: 12,
    color: theme.palette.greyDark.main,
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.greyDark.main,
  },
  icon: {
    margin: '-16px -8px',
  },
  loader: {
    padding: '25%',
  },
}));

interface NonProfitSelectionProps {
  nonProfitId?: string;
  oldNonProfit: any;
  nonProfitEditId?: string;
  handleNonProfitSelected: ({
    nonProfitId,
    nonProfitEditId,
  }: {
    nonProfitId: string | null;
    nonProfitEditId?: string | null;
  }) => void;
}

const combineNonProfitData = (
  nonProfit: NonProfitV2Type,
  nonProfitEdit: NonProfitEditType,
) => {
  const nonProfitId = nonProfit?.nonProfitId;
  const nonProfitName = nonProfitEdit?.nonProfitName
    ? nonProfitEdit.nonProfitName
    : nonProfit?.nonProfitName;
  const nonProfitTaxId = nonProfit?.nonProfitTaxId;
  const nonProfitAddress = nonProfitEdit?.nonProfitAddress
    ? nonProfitEdit.nonProfitAddress
    : nonProfit?.nonProfitAddress;
  return {
    nonProfitId,
    nonProfitName,
    nonProfitTaxId,
    nonProfitAddress,
    isEdited: true,
  };
};

export default function NonProfitSelection({
  nonProfitId,
  oldNonProfit,
  nonProfitEditId,
  handleNonProfitSelected,
}: NonProfitSelectionProps) {
  const classes = useStyles();
  const {
    value: nonProfitSelectionModalOpen,
    setTrue: handleOpenModal,
    setFalse: handleCloseModal,
  } = useToggle();

  const isOldNpo =
    !!oldNonProfit?.nonProfitId && nonProfitId === oldNonProfit?.nonProfitId;
  const queryOptions = {
    variables: { nonProfitIds: [nonProfitId] },
    skip: isOldNpo || !nonProfitId,
  } as QueryHookOptions;

  const { data, loading: nonProfitLoading } = useQuery(
    NON_PROFIT_V2_QUERY,
    queryOptions,
  );

  const nonProfitEditQueryOptions = {
    variables: { nonProfitEditId },
    skip: !nonProfitEditId,
  } as QueryHookOptions;

  const { data: nonProfitEditData, loading: nonProfitEditLoading } = useQuery(
    NON_PROFIT_EDIT_QUERY,
    nonProfitEditQueryOptions,
  );

  const loading = nonProfitLoading || nonProfitEditLoading;

  const nonProfit =
    data?.viewer?.v2?.nonProfits?.edges?.[0]?.node || oldNonProfit;
  const nonProfitEdit = nonProfitEditData?.viewer?.v2?.nonProfitEdit;

  // an old NPO would never be combined with an edit
  // since edits can only be linked to new NPOs
  const nonProfitWithEdit = nonProfitEdit
    ? combineNonProfitData(nonProfit, nonProfitEdit)
    : nonProfit;
  const nonProfitAddress = formatNonProfitAddress(
    nonProfitWithEdit?.nonProfitAddress,
  );

  return (
    <div className={classes.container}>
      {/* Not using <Text/> component since we are using the old fonts */}
      <Text className={classes.label}>
        Which nonprofit are you fundraising for?
      </Text>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.loader}
        >
          <LoaderDark size={36} />
        </Grid>
      ) : nonProfit ? (
        <ChunkyButton
          avatar={<Icons.COCHeartHands />}
          onChange={handleOpenModal}
          onChangeText="Change"
          primaryText={`${nonProfitWithEdit?.nonProfitName}`}
          secondaryText={nonProfitAddress}
          tertiaryText={`EIN: ${nonProfitWithEdit?.nonProfitTaxId}`}
          headerIcon={
            nonProfitWithEdit.isIRSVerified && !nonProfitWithEdit.isEdited ? (
              <Icons.COCVerified />
            ) : (
              <Icons.COCDatabaseEdit />
            )
          }
        />
      ) : (
        <ButtonBase className={classes.button} onClick={handleOpenModal}>
          <Text
            variant="custom"
            default={TEXT_DS.BODY_REGULAR_16}
            className={classes.text}
          >
            Add a nonprofit
          </Text>
          <Icons.RightArrow className={classes.icon} fill="#C2C6C9" />
        </ButtonBase>
      )}
      <ApolloProvider client={client}>
        <ApolloConsumer>
          {() => (
            <CauseOfChoiceModal
              selectedNonProfit={nonProfitWithEdit}
              handleNonProfitSelected={handleNonProfitSelected}
              initialScreen={
                CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN
              }
              open={nonProfitSelectionModalOpen}
              handleCloseModal={handleCloseModal}
              urlEventImgUploads={ohwImageUploads}
              assetUrl={process.env.GATSBY_S3_ASSETS}
            />
          )}
        </ApolloConsumer>
      </ApolloProvider>
    </div>
  );
}
