import { Link } from 'gatsby';
import {
  styled,
  Container,
  Icons,
  theme,
  Button,
  Text,
  TEXT_DS,
  Chip,
} from '@onehope/design-system-v2';

export const PageContainer = styled(Container)({
  paddingTop: '16px',
});

export const TableContainer = styled('div')({
  height: '68vh',
  overflow: 'auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: '760px',
    margin: 'auto',
    marginTop: '16px',
  },
});

export const PageInfo = styled('div')({
  maxWidth: '760px',
  margin: 'auto',
});

export const NoDataContainer = styled('div')({
  textAlign: 'center',
});

export const SearchIcon = styled(Icons.Search)({
  marginRight: '7px',
  height: '40px',
  width: '40px',
});

export const InvitesIcon = styled(Icons.Invites)({
  marginBottom: '24px',
  marginTop: '48px',
});

export const Loader = styled(Icons.Loader)({
  margin: '20px',
  textAlign: 'center',
});

export const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 0 16px 16px',
  borderTop: `1px solid ${theme.palette.greyLight.main}`,
  fontSize: '16px',
  letterSpacing: '0.01em',
  fontFamily: theme.bodyFontFamily,
  color: theme.palette.primary.main,
  alignItems: 'center',
});

export const LinkTo = styled(Link)({
  width: '48%',
  textAlign: 'center',
  color: theme.palette.neutral0.main,
  '&:hover': {
    color: theme.palette.neutral0.main,
  },
  [theme.breakpoints.up('sm')]: {
    width: '49%',
  },
});

export const StyledButton = styled(Button)({
  width: '100%',
  maxWidth: '404px',
});

export const CompletedWrapper = styled('div')({
  display: 'flex',
  marginTop: '10px',
});

export const GreenCheck = styled(Icons.GreenCheck)({
  marginRight: '12px',
});

const StyledStatusText = styled(Text)({
  color: `${theme.palette.forestGreen.main} !important`,
});

export const StatusText = ({ text }: { text: string }) => {
  return (
    <StyledStatusText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
      {text}
    </StyledStatusText>
  );
};

export const ContactName = ({ text }: { text: string }) => {
  return (
    <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
      {text}
    </Text>
  );
};
