import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  useTheme,
  useMediaQuery,
  Button as MuiButton,
  Dialog as DialogRoot,
} from '@onehope/design-system-v2';

import AddContactForm from './AddContactForm';

const CreateContactButton = styled(MuiButton)`
  && {
    //width: 288px;
  }
`;

export default function AddContactDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isContactCreateOpen, setIsContactCreateOpen] = useState(false);

  const handleEventContactToggle = () => {
    setIsContactCreateOpen(!isContactCreateOpen);
  };

  return (
    <div>
      <CreateContactButton fullWidth onClick={handleEventContactToggle}>
        CREATE NEW CONTACT
      </CreateContactButton>
      <DialogRoot
        fullScreen={fullScreen}
        disableEnforceFocus
        open={isContactCreateOpen}
        onClose={handleEventContactToggle}
      >
        <AddContactForm
          onClose={handleEventContactToggle}
          defaultValues={{
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            dateOfBirth: '',
          }}
        />
      </DialogRoot>
    </div>
  );
}
