import { DateTime } from 'luxon';
import { replacer, reviver } from './serializeMap';

interface AutocompleteSeshCache {
  dateCreated: string;
  descriptionMap: Map<string, string>;
  fields: string[];
}

function isAutocompleteSeshCache(obj: any): obj is AutocompleteSeshCache {
  if (
    obj.hasOwnProperty('dateCreated') &&
    obj.hasOwnProperty('descriptionMap') &&
    obj.hasOwnProperty('fields')
  ) {
    return true;
  }
  return false;
}

/**
 * Check sessionStorage for a cached corpus result and return a type-asserted object from it if so
 * Invalidate values once they're >= 6 hours old
 */
export function getSeshCache(): AutocompleteSeshCache | null {
  const seshCacheSerialized = sessionStorage.getItem('ae-autocomplete-cache');
  if (seshCacheSerialized === null) {
    return null;
  }

  try {
    const seshCache = JSON.parse(seshCacheSerialized, reviver);
    if (!isAutocompleteSeshCache(seshCache)) {
      throw new Error('Failed to parse cached value');
    }

    const latestValidCreateDate = DateTime.utc()
      .minus({ hours: 6 })
      .setZone('utc');
    if (DateTime.fromISO(seshCache.dateCreated) < latestValidCreateDate) {
      throw new Error('Cached data has expired');
    }

    return seshCache;
  } catch (e) {
    console.error(e);

    // delete in case it's malformed
    sessionStorage.removeItem('ae-autocomplete-cache');
    return null;
  }
}

export function setSeshCache(
  valueToCache: Omit<AutocompleteSeshCache, 'dateCreated'>,
): void {
  const payload: AutocompleteSeshCache = {
    ...valueToCache,
    dateCreated: DateTime.utc().toISO(),
  };

  const serialized = JSON.stringify(payload, replacer);

  sessionStorage.setItem('ae-autocomplete-cache', serialized);
}
