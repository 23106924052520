import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { presetAssets } from './presets';

type LoaderProps = {
  performAction: () => any;
  onComplete: (results?: any) => any;
  assetUrl: string;
  preset: string;
};

const LoadingContainer = styled.div`
  height: 100%;

  img {
    display: block;
    margin: 0 auto;
    width: inherit;
    height: inherit;
  }
`;

export default function Loader({
  performAction,
  onComplete,
  assetUrl,
  preset,
}: LoaderProps) {
  // Only call the action once, on the initial load.
  // If it is called more than once, you may have dangerous and unwanted effects!
  // e.g. if performAction is placeOrder, you would place the same order twice.
  useEffect(() => {
    const onLoad = async () => {
      const result = await performAction();
      onComplete(result);
    };
    onLoad();
  }, []); // Runs only once because of empty dependency array

  return (
    <LoadingContainer>
      <img alt={preset} src={`${assetUrl}/${presetAssets[preset]}`} />
    </LoadingContainer>
  );
}
