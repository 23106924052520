import React, { useState } from 'react';

import Dashboard from '../components/Dashboard';
import Layout from '../layout';
// import CeChangeModalWrapper from '../../../onehope-megalith/components/_common/CeChangeModalWrapper';
// import { MegalithAppShellGetQuery_viewer_user_shoppingWithCeoUser } from '../../../onehope-megalith/queries/generatedTypes/MegalithAppShellGetQuery';
const DashboardMainPage = () => {
  const [rewardBadgeAnimationAmount, setRewardBadgeAnimationAmount] = useState(
    0,
  );
  // const sessionStorageSavedCE = localStorage.getItem(`loginCE`);
  // const savedCE: MegalithAppShellGetQuery_viewer_user_shoppingWithCeoUser | null = sessionStorageSavedCE
  //   ? JSON.parse(sessionStorageSavedCE)
  //   : null;
  const [
    triggerRewardBadgeAnimation,
    setTriggerRewardBadgeAnimation,
  ] = useState(false);

  return (
    <Layout
      rewardBadgeAnimationAmount={rewardBadgeAnimationAmount}
      triggerRewardBadgeAnimation={triggerRewardBadgeAnimation}
      setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
    >
      <Dashboard
        setRewardBadgeAnimationAmount={setRewardBadgeAnimationAmount}
        setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
      />
      {/* {!!savedCE && <CeChangeModalWrapper />} */}
    </Layout>
  );
};

export default DashboardMainPage;
