import { gql } from '@apollo/client';

export const CauseOfChoiceNonProfit = gql`
  fragment CauseOfChoiceNonProfit on NonProfitV2 {
    id
    nonProfitName
    nonProfitDescription
    nonProfitPayeeName
    nonProfitTaxId
    nonProfitId
    nonProfitEditId
    isIRSVerified
    isPreApproved
    nonProfitAddress {
      firstName
      lastName
      city
      state
      zip
      phoneNumber
      addressLineOne
      addressLineTwo
    }
  }
`;

export const NONPROFIT_AUTOCOMPLETE_QUERY = gql`
  query CauseOfChoiceNonProfitQuery($keyword: String, $includeEdits: Boolean) {
    viewer {
      id
      v2 {
        id
        nonProfits(keyword: $keyword, includeEdits: $includeEdits) {
          edges {
            node {
              ...CauseOfChoiceNonProfit
            }
          }
        }
      }
    }
  }
  ${CauseOfChoiceNonProfit}
`;

export default NONPROFIT_AUTOCOMPLETE_QUERY;
