import { gql } from '@apollo/client';

const CARD_QUERY = gql`
  query ActionEngineCardQuery($ledgerId: String!) {
    viewer {
      v2 {
        actionEngineCard(ledgerId: $ledgerId) {
          id
          cardTemplateId
          title
          message
          dateCreated
          dateDeleted
          dateSucceeded
          dateArchived
          dateSaved
          taskSucceeded
          rewardPointBounty
          rewardPointsEarned
          isDeleted
          category
          buttonText
          rewardPointBounty
          rewardPointRedemptionMode
          buttonAction {
            action
            name
            phoneNumber
            body
            emailAddress
            subject
            url
            firstName
            lastName
          }
          image {
            src
            alt
          }
          muxVideo {
            assetId
            playbackId
            ratio
          }
          createContext {
            changeType
            templateName
            triggerType
            userName
            cardRuleContext {
              audienceDirection
              audienceName
            }
          }
          deleteContext {
            changeType
            templateName
            triggerType
            userName
            cardRuleContext {
              audienceDirection
              audienceName
            }
          }
          dynamicDataParams {
            userId
            subjectUserId
          }
        }
      }
    }
  }
`;

export default CARD_QUERY;
