import { gql } from '@apollo/client';

const CreateReachOutRequest = gql`
  mutation CEDash_ReachOutRequest($reachOutInput: RegisterReachOutV2Input!) {
    registerReachOutV2(input: $reachOutInput) {
      reachOutRequest {
        shareMedium
      }
      reachOut {
        description
        coverImageUrl
        verticalImageUrl
      }
      shareConfig {
        medium
        lifecycleStage
        url
        subject
        body
        qrCodeImage
        qrCodeContents
      }
    }
  }
`;

export default CreateReachOutRequest;
