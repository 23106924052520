import React from 'react';
import { DialogV2 } from '@onehope/design-system-v2';
import { DialogButton } from './sharedComponents';
import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplate } from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import { DownloadComponent } from './DownloadComponent';

type Props = {
  ceAccountId: string;
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  reachOutTemplate: ReachOutTemplate;
};

/**
 * Display a modal with suggested content for sharing a reach out to social media
 */
export function SaveAssetsModal({
  ceAccountId,
  dialogOpen,
  handleCloseDialog,
  reachOutTemplate,
}: Props) {
  const customButtonComponent = (
    <DialogButton onClick={handleCloseDialog}>Close</DialogButton>
  );

  return (
    <DialogV2
      open={dialogOpen}
      onClose={handleCloseDialog}
      title={'Save Assets'}
      customButtonComponent={customButtonComponent}
    >
      <>
        <DownloadComponent
          ceAccountId={ceAccountId}
          mediaType="horizontalImage"
          reachOutTemplate={reachOutTemplate}
        />

        <DownloadComponent
          ceAccountId={ceAccountId}
          mediaType="verticalImage"
          reachOutTemplate={reachOutTemplate}
        />

        <DownloadComponent
          ceAccountId={ceAccountId}
          mediaType="verticalVideo"
          reachOutTemplate={reachOutTemplate}
        />
      </>
    </DialogV2>
  );
}
