import * as React from 'react';
import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';

import { ListItemIcon, Icons, Text, useTheme } from '@onehope/design-system-v2';

import {
  resetSegment,
  trackEvent,
} from '../../../../../utils/segment/tracking';

import UserLogoutMutation from '../../../../../mutations/Authentication/LogoutMutation';

import { CEDash_UserLogoutMutation } from '../../../../../mutations/Authentication/generatedTypes/CEDash_UserLogoutMutation';

import { CustomList, CustomListItem } from './NavigationStyled';
import { ExigoBackOfficeMenuItem } from './ExigoBackOfficeMenuItem';
import { TrinityBackOfficeMenuItem } from './TrinityBackOfficeMenuItem';

import { useTrackingContextValue } from '../../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../../utils/segment/constants';

const {
  CEDashEvents,
  CEDashContacts,
  CEDashCalendarAndEvents,
  CEDashHome,
  CEDashResource,
  CEDashCredits,
  CEDashVine,
  CEDashOrders,
  CEDashLogOut,
  CEDashGrid,
  CEDashReachOut,
  CEDashAccount,
} = Icons;

/**
 * This part is hit in ce dashboard after login.
 * But trackEvent is not sending the event to Segment. Why?
 * Investigation pending... ( old code ported over from Dylan Williams )
 */
function logoutSuccess() {
  resetSegment();
  window.location.href = process.env.GATSBY_MEGALITH_URL;
}

function trackEventStaging(eventName: string) {
  if (
    process.env.GATSBY_BUILD_ENV === 'staging' ||
    process.env.GATSBY_BUILD_ENV === 'development'
  ) {
    trackEvent({
      event: eventName,
      properties: {},
    });
  }
}

const data = [
  { icon: <CEDashGrid />, label: 'Home', link: '/dashboard' },
  { icon: <CEDashReachOut />, label: 'Reach Outs', link: '/reach-outs' },
  { icon: <CEDashEvents />, label: 'Events', link: '/events' },
  { icon: <CEDashOrders />, label: 'Orders', link: '/customer-orders' },
  { icon: <CEDashContacts />, label: 'Contacts', link: '/contacts' },
  {
    icon: <CEDashCalendarAndEvents />,
    label: 'Calendar & Events',
    link: '/calendar-and-events',
  },
  { icon: <CEDashCredits />, label: 'Credits', link: '/credits' },
  TrinityBackOfficeMenuItem,
  ExigoBackOfficeMenuItem,
  {
    icon: <CEDashResource />,
    label: 'Resource Center',
    link: 'resource',
  },
  { icon: <CEDashVine />, label: 'The Vine', link: 'vine' },
  { icon: <CEDashAccount />, label: 'My Account', link: 'account' },

  {
    icon: <CEDashHome />,
    label: 'onehopewine.com',
    link: 'ohw',
  },
  {
    icon: <CEDashLogOut />,
    label: 'Log-out',
    link: 'logout',
  },
];

export default function Navigation() {
  const theme = useTheme();

  const resourceCenterUrl = process.env.GATSBY_RESOURCE_CENTER_URL;
  const vineUrl = process.env.GATSBY_VINE_URL;
  const ohwHomeUrl = process.env.GATSBY_HOPECOMMERCE_URL;
  const ohwAccountUrl = `${ohwHomeUrl}/account`;
  const { pathname } = window.location;

  const { trackEventV2 } = useTrackingContextValue();

  const [logOutMutation, { client }] = useMutation<CEDash_UserLogoutMutation>(
    UserLogoutMutation,
  );

  const handleLogout = () => {
    logOutMutation({ variables: { input: {} } }).then(() => {
      logoutSuccess();
      return client.resetStore();
    });
  };

  const onClickLink = (link: string) => {
    switch (link) {
      case 'resource':
        trackEventStaging('Resource Center Clicked');

        trackEventV2({
          event: segmentEvents.linkClick,
          properties: { name: 'Resource Center', url: resourceCenterUrl },
        });

        return window.open(resourceCenterUrl, '_blank');
      case 'vine':
        trackEventStaging('The Vine Clicked');

        trackEventV2({
          event: segmentEvents.linkClick,
          properties: { name: 'The Vine', url: vineUrl },
        });

        return window.open(vineUrl, '_blank');
      case 'ohw':
        trackEventStaging('onehopewine.com Clicked');
        return window.open(ohwHomeUrl, '_blank');
      case 'account':
        trackEventStaging('My Account Clicked');
        return window.open(ohwAccountUrl, '_blank');
      case 'logout':
        return handleLogout();
      default:
        return navigate(link);
    }
  };
  return (
    <CustomList>
      {data.map(item => {
        if (typeof item === 'function') {
          return item();
        }

        return (
          <CustomListItem
            button
            selected={
              pathname === item.link ||
              (pathname.includes('/event') && item.link == '/events') ||
              (pathname.includes('/contact') && item.link == '/contacts')
            }
            theme={theme}
            key={item.label}
            onClick={() => onClickLink(item.link)}
          >
            <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
              {item.icon}
            </ListItemIcon>
            <Text className="text">{item.label}</Text>
          </CustomListItem>
        );
      })}
    </CustomList>
  );
}
