import { gql } from '@apollo/client';

const SEGMENT_IDENTIFY_GET = gql`
  query WineClub_SegmentIdentifyGetQuery($accountId: String) {
    viewer {
      id
      segmentIdentify(accountId: $accountId, applyTransformations: true) {
        prospects
        allCustomers
        t_qualified_ce
        t_qualified_ce_600
      }
    }
  }
`;

export default SEGMENT_IDENTIFY_GET;
