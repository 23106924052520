import React, { ReactElement } from 'react';
import { ApolloProvider } from '@apollo/client';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { CssBaseline } from '@onehope/design-system-v2';
import MainLayout from './MainLayout';
import AuthGuard from '../utils/AuthGuard';
import AppGlobalProvider from '../contexts/AppGlobalContext/context';
import { client } from '../apollo/client';

// project import - context

// ==============================|| LAYOUTS - STRUCTURE ||============================== //

interface Props {
  children: ReactElement | null;
  rewardBadgeAnimationAmount?: number;
  triggerRewardBadgeAnimation?: boolean;
  setTriggerRewardBadgeAnimation?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const AppLayout = ({
  children,
  rewardBadgeAnimationAmount,
  triggerRewardBadgeAnimation,
  setTriggerRewardBadgeAnimation,
}: Props) => {
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <AuthGuard>
        <AppGlobalProvider>
          <MainLayout
            rewardBadgeAnimationAmount={rewardBadgeAnimationAmount}
            triggerRewardBadgeAnimation={triggerRewardBadgeAnimation}
            setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
          >
            {children}
          </MainLayout>
        </AppGlobalProvider>
      </AuthGuard>
    </ApolloProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(AppLayout);
