import React from 'react';
import { TEXT_DS } from '@onehope/design-system-v2';

import { PaymentDetails } from '@onehope/account';

import { GridContainer, SectionHeader } from '../../_shared/styles';
import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../../utils/segment';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
  WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';

interface PaymentBookType {
  user: WineClub_AutoShipGetQuery_viewer_user;
  payment: WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment;
  subscription?: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  conciergeMode: boolean;
  isEditingCeSubscription?: boolean;
  contactId?: string | undefined;
  sendToTrackEventV2?: SendToTrackEventV2;
}

const PaymentMethod = ({
  user,
  payment,
  subscription,
  conciergeMode,
  isEditingCeSubscription,
  contactId,
  sendToTrackEventV2,
}: PaymentBookType) => {
  const sendToTrackEventV2Payment = ({
    previousPayment,
    newPayment,
  }: {
    previousPayment: WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment;
    newPayment: WineClub_AutoShipGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment;
  }) => {
    sendToTrackEventV2 &&
      sendToTrackEventV2({
        event: SubscriptionSegmentEvents.wineClubModified,
        properties: {
          concierge: conciergeMode,
          autoShipId: subscription?.autoShipId || null,
          accountId: user?.userId,
          ceAccountId: conciergeMode ? user?.userId : '',
          propertyUpdated: SubscriptionSegmentProperty.billingInformationUpdate,
          values: {
            previousValue: previousPayment,
            newValue: newPayment,
          },
        },
      });
  };

  return (
    <GridContainer container direction="column" spacing={0}>
      <SectionHeader variant="custom" default={TEXT_DS.TITLE_LIGHT_24}>
        Payment Method
      </SectionHeader>
      <PaymentDetails
        payment={payment}
        subscription={subscription}
        isEditingCeSubscription={isEditingCeSubscription}
        contactId={contactId}
        sendToTrackEventV2={sendToTrackEventV2Payment}
      />
    </GridContainer>
  );
};

export default PaymentMethod;
