import gql from 'graphql-tag';
import { CEDash_UserFragment } from '../../fragments/UserCOCFragment';

const mutation = gql`
  mutation ShopWithCustomerAddV2(
    $eventId: String
    $customerEmail: String!
    $skipPermissions: Boolean
  ) {
    shopWithCustomerAddV2(
      input: {
        eventId: $eventId
        customerEmail: $customerEmail
        skipPermissions: $skipPermissions
      }
    ) {
      user {
        id
        userId
        conciergeCustomerAccountId
        conciergeUser {
          ...CEDash_UserFragment
        }
      }
      newCustomer {
        id
        accountId
        ordersCount
        email
        firstName
        lastName
        fullName
        dateOfBirth
      }
    }
  }
  ${CEDash_UserFragment}
`;

export default mutation;
