import React from 'react';
import styled from '@emotion/styled';

import Layout from '../../layout';
import ReachOutWrapper from '../../components/ReachOuts/ReachOutWrapper';

const Container = styled.div`
  background-color: white;
  min-height: 100%;
  padding-top: 16px;
`;

function ReachOuts() {
  return (
    <Layout>
      <Container>
        <ReachOutWrapper page="all" />
      </Container>
    </Layout>
  );
}

export default ReachOuts;
