import Decimal from 'decimal.js';
import React from 'react';
import { Currency } from '@onehope/design-system-ohw';
import { Box, Text, TEXT_DS, GridV2 as Grid } from '@onehope/design-system-v2';
import { CEDash_FastStartQuery_viewer_v3_fastStart23 as FastStartData } from '../../../queries/generatedTypes/CEDash_FastStartQuery';
import RetailSalesThermometer from './RetailSalesThermometer';
import RetailAwardItem from './RetailAwardItem';

interface Props {
  fastStartData: FastStartData;
}

export default function RetailAwards({ fastStartData }: Props) {
  return (
    <>
      <Box sx={{ pb: { xs: 2, md: 3 } }}>
        <Text>Earn awards each time you get $500 in sales.</Text>
      </Box>
      <Box sx={{ mb: '1rem' }}>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_14}>
          Sales Total:{' '}
          <Currency
            rawValue={new Decimal(fastStartData?.salesTotal ?? 0)
              .dividedBy(100)
              .toNumber()}
          />
        </Text>
      </Box>
      <Box>
        <RetailSalesThermometer fastStartData={fastStartData} />
      </Box>
      <Box sx={{ overflowX: 'auto', marginTop: '1rem' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            maxWidth: 700,
            minWidth: 630,
            width: '100%',
            m: '0 auto',
          }}
        >
          {fastStartData?.salesRewards?.map((reward, index) => (
            <RetailAwardItem
              key={reward.rewardName}
              index={index}
              reward={reward}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
}
