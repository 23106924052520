import { gql } from '@apollo/client';

const mutation = gql`
  mutation Account_GooglePlacesZipMutation($input: GooglePlacesZipInput!) {
    googlePlacesZip(input: $input) {
      zip
    }
  }
`;

export default mutation;
