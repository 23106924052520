import ActivityEntry from '../../types/ActivityEntry';
import GQLNode from './GQLNode';
export interface ResourceGQLNodes {
  [resourceType: string]: GQLNode;
}

export const getTokenizedTemplateMatches = (activity: ActivityEntry) => {
  if (!activity || !activity.message) activity.message = [];
  const allText = [
    activity.title,
    activity.title,
    activity.button?.phoneNumber,
    activity.button?.emailAddress,
    activity.button?.body,
    activity.button?.subject,
    activity.button?.url,
    activity.button?.name,
    activity.button?.action,
    activity.button?.firstName,
    activity.button?.lastName,
    ...activity.message,
  ].join('');
  const templateMatches = allText.matchAll(/\{{2}\s?(\w+(\.\w+)+)\s?\}{2}/g);

  const tokens: string[][] = [];
  for (const match of templateMatches) {
    tokens.push(match[1].split('.'));
  }
  return tokens;
};

export default function extractDataFields(
  activity: ActivityEntry,
): ResourceGQLNodes {
  const tokens = getTokenizedTemplateMatches(activity);
  const resourceTypes: { [resourceType: string]: boolean } = {};

  // first pass: grab the resource types we'll be using as nodes.
  tokens.forEach((token) => {
    const [resource] = token;
    resourceTypes[resource] = true;
  });

  // Build base gql nodes from the resourceTypes.
  const nodes: ResourceGQLNodes = {};
  Object.keys(resourceTypes).map((name) => {
    nodes[name] = new GQLNode(name);
  });

  // second pass: populate the nodes
  tokens.forEach((token) => {
    // Add all the fields (except the resource name)
    const [resource, ...fields] = token;
    nodes[resource].add(fields);
  });

  return nodes;
}
