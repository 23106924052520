import * as React from 'react';

type CurrencyProps = {
  rawValue: number;
  includeCents?: boolean;
};

// this would be a good place to support i18n currency display in the future
export default function Currency({
  rawValue,
  includeCents = true,
}: CurrencyProps) {
  if (!includeCents) {
    return (
      <>${rawValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}</>
    );
  }

  const formattedValue = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(rawValue);

  return <>{formattedValue}</>;
}
