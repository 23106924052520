import DatePicker from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/calendar/style/index.css';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { errorColor, font, primaryColor, textColor },
} = styles;

const baseStyle = css`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${primaryColor};
  box-shadow: none;
  outline: none;
  font-family: ${font};
  font-weight: 100;
  line-height: 1.57;
  height: 52px;
  color: ${textColor};
  font-size: 14px;
  &:hover {
    border-bottom: 1px solid ${primaryColor};
  }
  &:focus {
    box-shadow: none;
    background-color: #f6fcff;
    border-bottom: 2px solid ${primaryColor};
  }
  .has-error & {
    border-color: ${errorColor};
  }
  .has-error &:focus {
    box-shadow: none;
    background-color: #fff9f9;
  }
`;

// @ts-ignore Type 'CreateStyled<any>' has no call signatures.
export const InputDatePicker = styled(DatePicker)`
  .ant-input {
    ${baseStyle};
    &:focus,
    &:hover {
      background: none;
      border-bottom: 1px solid ${primaryColor} !important;
    }
  }
  .ant-calendar-today-btn {
    color: blue !important;
  }
` as any;
