import React from 'react';
import {
  Dialog,
  makeStyles,
  ThemeType,
  useTheme,
  useMediaQuery,
} from '@onehope/design-system-v2';

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: { minWidth: '600px' },
  },
}));

interface ModalProps {
  open: boolean;
  children: any;
  handleClose: () => void;
}

function Modal(props: ModalProps) {
  const { open, handleClose, children } = props;

  const theme = useTheme();
  const dialogClasses = useDialogStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={dialogClasses}
      fullScreen={isMobile}
    >
      {children}
    </Dialog>
  );
}

export default Modal;
