import { gql } from '@apollo/client';

export const GetAddressFragment = gql`
  fragment Account_GetAddress on Address {
    id
    addressId
    addressLineOne
    addressLineTwo
    city
    companyName
    country
    firstName
    lastName
    specialInstructions
    phoneNumber
    recipientBirthDate
    state
    zip
    nickname
  }
`;
