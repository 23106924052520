// Copied and modified from SimpleImageSlider

import React from 'react';
import {
  // Grid as MuiGrid,
  Carousel as Slider,
  styled,
} from '@onehope/design-system-v2';
import WineBundleOption from './BundleOption';
import { WineClub_BulkProductsV2Query_viewer_productsV2Bulk_edges_node } from '../../queries/generatedTypes/WineClub_BulkProductsV2Query';
import { WineClub_DiscountConfigWCQuery_viewer_v2_discounts } from '../../queries/generatedTypes/WineClub_DiscountConfigWCQuery';

// const GridContainer = styled(MuiGrid)({
//   cursor: 'pointer',
//   height: '100%',
// });

const Carousel = styled(Slider)`
  && {
    .slick-slider {
      position: relative;

      display: block;
      box-sizing: border-box;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      -webkit-touch-callout: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      :hover {
        cursor: pointer;
      }
    }

    .slick-list {
      position: relative;

      display: block;
      overflow: wrap;
      margin: 0 0 8px 0;
      padding: 0;
      /* max-width: 100%; */
      @media all and (min-width: 768px) {
        //max-width: 760px;
        height: 501px;
      }
      *:focus {
        outline: 0;
        outline: none;
      }
    }

    .slick-list:focus {
      outline: none;
    }
    .slick-list.dragging {
      cursor: pointer;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      height: inherit;
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .slick-track:before,
    .slick-track:after {
      display: table;
    }
    .slick-track:after {
      clear: both;
    }
    .slick-loading .slick-track {
      visibility: hidden;
    }

    .slick-slide {
      //display: none;
      float: left;

      height: 100%;
      min-height: 1px;
      width: 304px !important;
      //margin: 0 12px;
    }
    [dir='rtl'] .slick-slide {
      float: right;
    }
    .slick-slide > div {
      height: 100%;
    }
    .slick-slide img {
      display: block;
    }
    .slick-slide.slick-loading img {
      display: none;
    }
    .slick-slide.dragging img {
      pointer-events: none;
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-loading .slick-slide {
      visibility: hidden;
    }
    .slick-vertical .slick-slide {
      display: block;

      height: auto;

      border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
      display: none;
    }

    .slick-prev,
    .slick-next {
      font-size: 0;
      line-height: 0;

      position: absolute;
      top: 50%;

      display: block;

      width: 20px;
      height: 20px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);

      cursor: pointer;

      color: transparent;
      border: none;
      outline: none;
      background: transparent;
    }
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
      color: transparent;
      outline: none;
      background: transparent;
    }
    .slick-prev:hover:before,
    .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1;
    }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      opacity: 0.25;
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 20px;
      line-height: 1;

      opacity: 0.75;
      color: white;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-prev {
      left: -25px;
    }
    [dir='rtl'] .slick-prev {
      right: -25px;
      left: auto;
    }
    .slick-prev:before {
      content: '←';
    }
    [dir='rtl'] .slick-prev:before {
      content: '→';
    }

    .slick-next {
      right: -25px;
    }
    [dir='rtl'] .slick-next {
      right: auto;
      left: -25px;
    }
    .slick-next:before {
      content: '→';
    }
    [dir='rtl'] .slick-next:before {
      content: '←';
    }

    /* Dots */
    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }

    .slick-dots {
      height: 8px;
      bottom: unset;
      width: 100%;
      padding: 0 0 16px 0;
      margin: 0px auto 0px auto;
      list-style: none;
      text-align: center;
      max-width: 100%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
      @media only screen and (min-width: 768px) {
        max-width: 100%;
        padding: 0;
        margin: 24px auto 0 auto;
      }
    }
    .slick-dots li {
      position: relative;

      display: inline-block;

      margin: 0 8px 0 8px;
      padding: 0;

      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;

      display: block;

      padding: 4.5px;
      cursor: pointer;

      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }
    .slick-dots li button:hover,
    .slick-dots li button:focus {
      outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
      opacity: 1;
    }
    .slick-dots li button:before {
      font-size: 6px;
      line-height: 20px;

      position: absolute;
      top: 0;
      left: 0;

      width: 8px;
      height: 8px;

      text-align: center;

      background-color: white;
      border: 1px solid black;
      border-radius: 100%;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li.slick-active button:before,
    .slick-dots li.slick-active,
    .slick-dots li.slick-active button {
      background-color: black;
      border-radius: 100%;
    }
  }
`;

// const Grid = styled(MuiGrid)`
//   /* display: grid;
//   grid-template-columns: repeat(1, 100%);
//   grid-gap: 12px;
//   justify-content: center;
//   align-items: center;
//   padding: 8px; */
//   height: 100% !important;
// `;

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1.08,
  slidesToScroll: 1,
  initialSlide: 0,
  touchThreshold: 1000,
};

// function makeGrid(items: Array<object>, itemsPerRow: number = 1) {
//   const gridItems = [];
//   const numberOfRows = Math.ceil(items.length / itemsPerRow);

//   for (let i = 0; i < numberOfRows; i++) {
//     const offset = i * itemsPerRow;
//     const rowItems = items.slice(offset, offset + itemsPerRow);
//     gridItems.push(
//       <Grid container key={i}>
//         {rowItems}
//       </Grid>,
//     );
//   }

//   return gridItems;
// }

interface WCSliderProps {
  products:
    | (
        | WineClub_BulkProductsV2Query_viewer_productsV2Bulk_edges_node
        | null
        | undefined
      )[];
  discounts:
    | WineClub_DiscountConfigWCQuery_viewer_v2_discounts
    | null
    | undefined;
  setSelectedCuratedWinePackId: (packId: string | null | undefined) => void;
  selectedCuratedWinePackId?: string | null;
}
const WCSlider = ({
  discounts,
  products,
  selectedCuratedWinePackId,
  setSelectedCuratedWinePackId,
}: WCSliderProps) => {
  if (!products) return null;
  return (
    // @ts-ignore
    <Carousel {...settings}>
      {/* @ts-ignore */}
      {products.map((product) => {
        return (
          // <GridContainer item xs key={product?.productId}>
          <WineBundleOption
            product={product}
            discounts={discounts}
            setSelected={setSelectedCuratedWinePackId}
            selected={selectedCuratedWinePackId === product?.productId}
          />
          //  </GridContainer>
        );
      })}
    </Carousel>
  );
};

export default WCSlider;
