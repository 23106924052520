import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { DateTime } from 'luxon';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isTablet, isMobile } from 'react-device-detect';

import SmsDesktopModal from './SmsDesktopModal';
import { TableContainer, Loader } from '../StyledComponents/Tables';

import REGISTER_REACH_OUT_REQUEST from '../../../mutations/ReachOuts/CreateReachOutRequestV2';

import { sendEmail, sendText } from '../helpers';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { segmentEvents } from '../../../utils/segment/constants';

// types
import {
  CEDash_ReachOutRequest,
  CEDash_ReachOutRequestVariables,
  CEDash_ReachOutRequest_registerReachOutV2,
} from '../../../mutations/ReachOuts/generatedTypes/CEDash_ReachOutRequest';
import {
  ReachOutLifecycleStage,
  ReachOutRequestStatus,
  ShareMedium,
} from '../../../../generatedTypes/globalTypes';
import {
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplate as ReachOutV2Type,
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList as ContactShareListType,
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList_reachOutRequest,
} from '../../../queries/generatedTypes/CEDash_ReachOutsV2InviteQuery';
import InviteRow from './InviteRow';

function InvitesTable({
  contacts,
  reachOutId,
  onLoadMore,
  hasMore,
  reachOut,
  method,
  userId,
}: {
  contacts: Array<ContactShareListType>;
  reachOutId?: string;
  onLoadMore: () => void;
  hasMore: boolean;
  reachOut: ReachOutV2Type | null;
  method: string;
  userId: string;
}) {
  const { trackEventV2 } = useTrackingContextValue();
  const [contactRequestStatuses, setContactRequestStatuses] = useState(
    contacts,
  );
  const [contactInfo, setContactInfo] = useState<ContactShareListType | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState<
    CEDash_ReachOutRequest_registerReachOutV2['shareConfig']
  >(null);

  const [registerRequest] = useMutation<
    CEDash_ReachOutRequest,
    CEDash_ReachOutRequestVariables
  >(REGISTER_REACH_OUT_REQUEST, {
    onCompleted: data => {
      const config = data?.registerReachOutV2?.shareConfig;
      const ua = navigator.userAgent.toLowerCase();
      let isSafari = null;
      if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {
          isSafari = false;
        } else {
          isSafari = true;
        }
      }
      const target = isSafari ? '_self' : '_blank';

      if (method === 'email') {
        const { email } = sendEmail({
          body: config.body,
          subject: config.subject,
          email: contactInfo.simpleContact.emailAddress,
        });
        window.open(email, target);
      }

      if (method === 'sms') {
        if (isMobile || isTablet) {
          const { sms } = sendText({
            body: config.body,
            phoneNumber: contactInfo.simpleContact.phoneNumber,
          });
          window.open(sms, target);
        } else {
          setConfig(config);
          setOpen(true);
        }
      }
    },
  });

  useEffect(() => {
    if (contacts.length !== contactRequestStatuses.length) {
      setContactRequestStatuses(contacts);
    }
  }, [contacts, contactRequestStatuses]);

  const sendInvite = async (
    contact: ContactShareListType,
    index: number,
    inviteSent: boolean,
  ) => {
    setContactInfo(contact);
    // create reachOutRequest for contact
    await registerRequest({
      variables: {
        reachOutInput: {
          contactId: contact.simpleContact.contactId,
          lifecycleStage: inviteSent
            ? ReachOutLifecycleStage.FOLLOWUP
            : ReachOutLifecycleStage.INITIAL,
          reachOutId,
          shareMedium:
            method === 'email' ? ShareMedium.EMAIL : ShareMedium.TEXT_MESSAGE,
        },
      },
    });

    trackEventV2({
      event: segmentEvents.reachOutSent,
      properties: {
        name: reachOut?.name,
        reachOutId,
        contactId: contact.simpleContact.contactId,
        deliveryType: method,
        ceAccountId: userId,
        isReminder: false,
        category: reachOut?.category?.name,
      },
    });

    let updateContact = contactRequestStatuses[index];
    if (updateContact) {
      const request: Partial<CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList_reachOutRequest> = {
        reachOutId,
        status: ReachOutRequestStatus.PENDING,
        lastModifiedDate: DateTime.now().toString(),
        shareMedium:
          method === 'email' ? ShareMedium.EMAIL : ShareMedium.TEXT_MESSAGE,
      };
      setContactRequestStatuses(
        contactRequestStatuses.map((contactRequest, i) => {
          if (index === i) {
            return {
              ...contactRequest,
              reachOutRequest: {
                ...contactRequest.reachOutRequest,
                ...request,
              },
            };
          }
          return { ...contactRequest };
        }),
      );
    }
  };

  return (
    <TableContainer>
      <SmsDesktopModal
        open={open}
        setOpen={setOpen}
        contact={contactInfo?.simpleContact}
        config={config}
      />
      <InfiniteScroll
        dataLength={contactRequestStatuses.length}
        next={onLoadMore}
        hasMore={hasMore}
        loader={<Loader />}
        height={'68vh'}
      >
        {contactRequestStatuses.map((contact, i) => (
          <InviteRow
            key={i}
            contactIndex={i}
            contact={contact}
            reachOut={reachOut}
            sendInvite={sendInvite}
          />
        ))}
      </InfiniteScroll>
    </TableContainer>
  );
}
export default InvitesTable;
