// @ts-ignore
import * as yup from 'yup';

import { isValidDate, mustBe21OrOlder } from '@onehope/validation';

yup.addMethod(yup.string, 'isValidDate', isValidDate);
yup.addMethod(yup.string, 'mustBe21OrOlder', mustBe21OrOlder);

export const ContactDetailsFormSchema = (isAccount: boolean) => {
  if (!isAccount) {
    return yup.object().shape({
      firstName: yup
        .string()
        .matches(/^[a-zA-Z]/, 'Please enter first name')
        .required('Required'),
      lastName: yup.string().matches(/^[a-zA-Z]/, 'Please enter last name'),
      emailAddress: yup.string().email('Please enter a valid email'),
      phoneNumber: yup
        .string()
        .matches(
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
          'Please enter a valid phone number',
        ),
      dateOfBirth: yup
        .string()
        .isValidDate()
        .mustBe21OrOlder(),
    });
  }
  return yup.object().shape({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z]/, 'Please enter first name')
      .required('Required'),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z]/, 'Please enter last name')
      .required('Required'),
    emailAddress: yup
      .string()
      .email('Please enter a valid email')
      .required('Required'),
    phoneNumber: yup
      .string()
      .matches(
        /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
        'Please enter a valid phone number',
      ),
    addressLineOne: yup.string(),
    addressLineTwo: yup.string(),
    city: yup.string().when('addressLineOne', {
      is: (addressLineOne: string) => !!addressLineOne,
      then: yup.string().required('City required for shipping address'),
    }),
    state: yup.string().when('addressLineOne', {
      is: (addressLineOne: string) => !!addressLineOne,
      then: yup.string().required('State required for shipping address'),
    }),
    zip: yup.string().when('addressLineOne', {
      is: (addressLineOne: string) => !!addressLineOne,
      then: yup.string().required('Zipcode required for shipping address'),
    }),
    dateOfBirth: yup
      .string()
      .isValidDate()
      .mustBe21OrOlder()
      .required('Required'),
  });
};
