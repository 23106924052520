import gql from 'graphql-tag';
import { EventFragment } from '../../queries/Event';
export const mutation = gql`
  mutation ImageDeleteMutation($input: ImageDeleteInput!) {
    imageDelete(input: $input) {
      event {
        ...Event_event
      }
    }
  }
  ${EventFragment}
`;

export default mutation;
