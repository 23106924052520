import React, { useState, useMemo, ChangeEvent } from 'react';
import { DateTime } from 'luxon';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import _debounce from 'lodash/debounce';

import {
  ThemeType,
  makeStyles,
  TextField,
  Grid,
  Text,
  TEXT_DS,
  styled,
} from '@onehope/design-system-v2';

import BreadCrumb, { InvitesPageNames } from '../BreadCrumb';
import InvitesTable from './InvitesTable';
import {
  PageContainer,
  PageInfo,
  SearchIcon,
  InvitesIcon,
  LinkTo,
  StyledButton,
} from '../StyledComponents/Tables';
import { MainHeaderText, BodyText } from '../StyledComponents/Text';
import GET_REACH_OUTS_INVITES from '../../../queries/ReachOutInvitesV2';
import {
  CEDash_ReachOutsV2InviteQuery,
  CEDash_ReachOutsV2InviteQueryVariables,
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplate,
  CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList,
} from '../../../queries/generatedTypes/CEDash_ReachOutsV2InviteQuery';

const useInputPropStyles = makeStyles((theme: ThemeType) => ({
  root: {
    height: '48px',
    maxWidth: '550px',
    background: `${theme.palette.barelyThereGrey.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '100px',
    fontStyle: 'italic',
    paddingLeft: '16px',
    fontSize: '14px',
    fontFamily: `${theme.bodyFontFamily} !important`,
    textAlign: 'center',
    margin: '24px auto',
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      width: '380px',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
}));

const CenterAlignedText = styled(Text)({
  textAlign: 'center',
});

/**
 * Display all contacts eligible to receive a ReachOutRequest for the given medium along with the status of any existing request.
 */
function Invites({
  reachOutId,
  method,
}: {
  reachOutId: string;
  method?: string;
}) {
  const inputPropStyles = useInputPropStyles();

  const [
    reachOut,
    setReachOut,
  ] = useState<CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplate | null>(
    null,
  );
  const [contacts, setContacts] = useState<
    CEDash_ReachOutsV2InviteQuery_viewer_v2_reachOutTemplates_shareList[]
  >([]);

  const [hasContactsWithContactInfo, setHasContactsWithContactInfo] = useState(
    true,
  );

  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  const [isContactsFiltered, setIsContactsFiltered] = useState(false);

  const limit = 20;
  const requireEmail = method === 'email';
  const requirePhone = method === 'sms';
  const pageName = InvitesPageNames[method];
  const methodInWords = method === 'sms' ? 'phone numbers' : 'email addresses';

  const { data, refetch, loading, error } = useQuery<
    CEDash_ReachOutsV2InviteQuery,
    CEDash_ReachOutsV2InviteQueryVariables
  >(GET_REACH_OUTS_INVITES, {
    variables: {
      limit,
      offset,
      reachOutId,
      requireEmail,
      requirePhone,
    },
    onCompleted: results => {
      const reachOutData = results?.viewer?.v2?.reachOutTemplate;
      setReachOut(reachOutData);

      const hasContactInfo =
        results?.viewer?.v2?.reachOutTemplates?.shareList?.length;
      setHasContactsWithContactInfo(!!hasContactInfo);

      const shareList = results?.viewer?.v2?.reachOutTemplates?.shareList;
      const queryResults = shareList || [];

      if (queryResults.length < limit) {
        setHasMore(false);
      }

      if (queryResults.length > 0) {
        setContacts([...contacts, ...queryResults]);
      }
    },
  });

  const [getContactsBySearchTerm, { data: searchData }] = useLazyQuery<
    CEDash_ReachOutsV2InviteQuery,
    CEDash_ReachOutsV2InviteQueryVariables
  >(GET_REACH_OUTS_INVITES, {
    onCompleted: () => {
      setIsContactsFiltered(true);
      const searchResults =
        searchData?.viewer?.v2?.reachOutTemplates?.shareList;

      if (searchResults) {
        setContacts(searchResults);
      }
    },
  });

  const isReachOutExpired = useMemo(() => {
    if (!reachOut?.expirationDate) {
      return false;
    }

    const expDate = DateTime.fromISO(reachOut.expirationDate);
    const now = DateTime.now();

    return expDate && expDate < now;
  }, [reachOut]);

  const handleSearchContacts = (term: string) => {
    getContactsBySearchTerm({
      variables: { fullName: term, reachOutId, requireEmail, requirePhone },
    });
  };

  const debouncedSearchContacts = _debounce(handleSearchContacts, 100);

  const handleFilterContactsBySearchTerm = (
    ev: ChangeEvent<HTMLInputElement>,
  ) => {
    const term = ev.target.value?.toLowerCase().trim();
    debouncedSearchContacts(term);
  };

  const onLoadMore = () => {
    setOffset(offset + limit);
    refetch({
      limit,
      offset: offset + limit,
      reachOutId,
      requireEmail,
      requirePhone,
    });
  };

  if (error) return null;
  const userId = data?.viewer?.user?.userId;

  return (
    <>
      <BreadCrumb
        page={pageName}
        reachOutId={reachOutId}
        previousPage={reachOut?.name || ''}
      />
      <PageContainer>
        {isReachOutExpired ? (
          <Grid container justifyContent="center">
            <Grid item>
              <BodyText text="Unfortunately, this reach out is no longer active." />
            </Grid>
          </Grid>
        ) : (
          <>
            {contacts.length === 0 &&
            !isContactsFiltered &&
            hasContactsWithContactInfo ? (
              <Grid container justifyContent="center">
                <Grid item>
                  <BodyText
                    text={`You do not have any contacts with ${methodInWords}. You can add contacts with ${methodInWords} under in Contacts in the menu.`}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <PageInfo>
                  <MainHeaderText text={pageName} />
                  {(contacts.length > 0 || isContactsFiltered) && (
                    <TextField
                      placeholder="Search by first or last name"
                      onChange={handleFilterContactsBySearchTerm}
                      InputProps={{
                        classes: inputPropStyles,
                        endAdornment: <SearchIcon />,
                      }}
                      fullWidth={true}
                    />
                  )}
                </PageInfo>

                {contacts.length > 0 || isContactsFiltered ? (
                  <InvitesTable
                    contacts={Array.from(contacts)}
                    reachOutId={reachOutId}
                    hasMore={hasMore}
                    onLoadMore={onLoadMore}
                    reachOut={reachOut}
                    method={method}
                    userId={userId}
                  />
                ) : (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Grid item>
                      <InvitesIcon />
                    </Grid>
                    <Grid item>
                      <CenterAlignedText
                        variant="custom"
                        default={TEXT_DS.BODY_LIGHT_16}
                        tablet={TEXT_DS.BODY_LIGHT_20}
                        laptop={TEXT_DS.BODY_LIGHT_20}
                        desktop={TEXT_DS.BODY_LIGHT_20}
                      >
                        It's feeling lonely here. Import your contacts to start
                        sharing.
                      </CenterAlignedText>
                    </Grid>
                    <Grid item>
                      <LinkTo to={`/contacts?showImport=true`}>
                        <span>
                          <StyledButton type="primary">
                            import contacts
                          </StyledButton>
                        </span>
                      </LinkTo>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </PageContainer>
    </>
  );
}

export default Invites;
