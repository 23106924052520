import styled from '@emotion/styled';
import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Icons,
} from '@onehope/design-system-v2';
import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplate } from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { DownloadLabelText } from '../StyledComponents/Text';
import { segmentEvents } from '../../../utils/segment/constants';

const HImage = styled('img')({
  width: '250px',
  height: 'auto',
});

const VImage = styled('img')({
  width: 'auto',
  height: '250px',
});

const DownloadContainer = styled('div')({
  marginBottom: '24px',
});

const DownloadLabel = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '8px',
  p: {
    padding: 0,
  },
  a: {
    marginLeft: '19px',
  },
  svg: {
    width: '24px',
    height: '24px',
  },
});

const PullRight = styled('div')({
  textAlign: 'right',
});

type Props = {
  ceAccountId: string;
  mediaType: 'horizontalImage' | 'verticalImage' | 'verticalVideo';
  reachOutTemplate: ReachOutTemplate;
};

/**
 * Render a label and thumnbnail of a downloadable asset with a link to download the full-resolution version
 */
export function DownloadComponent({
  ceAccountId,
  mediaType,
  reachOutTemplate,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trackEventV2 } = useTrackingContextValue();

  const handleTrackEvent = () => {
    return trackEventV2({
      event: segmentEvents.reachOutAssetDownloaded,
      properties: {
        name: reachOutTemplate.name,
        reachOutId: reachOutTemplate.reachOutId,
        ceAccountId,
        category: reachOutTemplate.category?.name,
      },
    });
  };

  const handleDownloadClicked = () => {
    if (!window) {
      return;
    }
    handleTrackEvent().then(() => {
      window.location.href = downloadUrl;
    });
  };

  let displayUrl = '';
  let downloadUrl = '';
  let fullResolutionUrl = '';
  switch (mediaType) {
    case 'horizontalImage':
      if (reachOutTemplate.coverImageUrl === null) {
        return null;
      }

      downloadUrl = reachOutTemplate.downloadUrls.featuredImage;
      fullResolutionUrl = reachOutTemplate.coverImageUrl;
      displayUrl = `${reachOutTemplate.coverImageUrl}?w=500`;
      break;
    case 'verticalImage':
      if (reachOutTemplate.verticalImageUrl === null) {
        return null;
      }

      downloadUrl = reachOutTemplate.downloadUrls.verticalImage;
      fullResolutionUrl = reachOutTemplate.verticalImageUrl;
      displayUrl = `${reachOutTemplate.verticalImageUrl}?h=500`;
      break;
    case 'verticalVideo':
      if (reachOutTemplate.muxVideo === null) {
        return null;
      }

      downloadUrl = reachOutTemplate.downloadUrls.muxVideo;
      fullResolutionUrl = `https://stream.mux.com/${reachOutTemplate.muxVideo.playbackId}/high.mp4`;
      displayUrl = [
        'https://image.mux.com',
        reachOutTemplate.muxVideo.playbackId,
        'thumbnail.png?height=500&width=280&fit_mode=smartcrop&time=1',
      ].join('/');
      break;
  }

  if (downloadUrl === null) {
    return null;
  }

  let label = '';
  let ariaLabel = '';
  switch (mediaType) {
    case 'horizontalImage':
      label = 'Horizontal image 1200x630';
      ariaLabel = 'Download horizontal image';
      break;
    case 'verticalImage':
      label = 'Vertical image 1080x1920';
      ariaLabel = 'Download vertical image';
      break;
    case 'verticalVideo':
      label = 'Vertical video 1080x1920';
      ariaLabel = 'Download vertical video';
      break;
  }

  const handleThumbnailClick = () => {
    if (isDesktop) {
      return;
    }
    setDialogOpen(true);
  };

  /**
   * Close the dialog and launch the download in a new tab
   */
  const handleCloseDialog = () => {
    setDialogOpen(false);
    window?.open(fullResolutionUrl, '_blank');
    handleTrackEvent();
  };

  return (
    <>
      <DownloadContainer>
        <DownloadLabel>
          <DownloadLabelText text={label} />
          {isDesktop && (
            <IconButton onClick={handleDownloadClicked}>
              <Icons.DownloadNoBackground role="img" aria-label={ariaLabel} />
            </IconButton>
          )}
        </DownloadLabel>

        {mediaType === 'horizontalImage' ? (
          <HImage src={displayUrl} onClick={handleThumbnailClick} />
        ) : (
          <VImage src={displayUrl} onClick={handleThumbnailClick} />
        )}
      </DownloadContainer>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText>
            A new window with the{' '}
            {mediaType === 'verticalVideo' ? 'video' : 'photo'} will open. Press
            and hold the {mediaType === 'verticalVideo' ? 'video' : 'photo'} for
            the option to save.
          </DialogContentText>

          <PullRight>
            <Button onClick={handleCloseDialog}>OK</Button>
          </PullRight>
        </DialogContent>
      </Dialog>
    </>
  );
}
