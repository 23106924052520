import React from 'react';
import { debounce } from 'throttle-debounce';

import styled from '@emotion/styled';
import { Icons, InputAdornment } from '@onehope/design-system-v2';

import { SearchInput } from './index.styles';

const { SearchIcon } = Icons;

type SearchBarProps = {
  handleSearch: (input: string) => void;
  placeHolderText?: string;
};

const SearchBarContainer = styled.div`
  width: 100%;
  min-width: calc(100%);
  @media all and (min-width: 960px) {
    width: 327px;
    min-width: 327px;
  }
`;

const SearchBar = (props: SearchBarProps) => {
  const debouncedHandleSearchEvents = debounce(300, props.handleSearch);

  const handleOnChange = (event: React.ChangeEvent<{ value: string }>) => {
    debouncedHandleSearchEvents(event.target.value);
  };
  const placeHolder = props.placeHolderText || 'Search events';
  return (
    <SearchBarContainer>
      <SearchInput
        fullWidth
        autoFocus
        variant="outlined"
        placeholder={placeHolder}
        onChange={handleOnChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
