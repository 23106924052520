import React from 'react';

const SvgAdd = (props: any) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 21V25H21V21H25V19H21V15H19V19H15V21H19Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default SvgAdd;
