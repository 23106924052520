import React from 'react';
import {
  ButtonBase,
  Grid,
  Icons,
  Text,
  Button,
  ChunkyButton,
} from '@onehope/design-system-v2';

import {
  CauseOfChoiceAppDictionary,
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

import { useCommonStyles } from '../../../utils/commonStyles';
import { getCurrentUser } from '../../../utils/helper';

export default function CauseOfChoiceSelected() {
  const commonStyles = useCommonStyles();
  const {
    handleCloseModal,
    user: parentUser,
    handleGoToShop,
    app,
  } = useCauseOfChoiceNetworkLayerValue();
  const [, dispatch] = useCauseOfChoiceContextValue();
  const user = getCurrentUser(parentUser);
  const guestModeNonProfit = user?.cartV2?.guestNonProfit;
  const nonProfit = guestModeNonProfit || user?.selectedCause?.nonProfit;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.HOME_SCREEN,
    });
  };

  const header = 'Your Cause of Choice';
  const subHeader =
    'ONEHOPE will donate 10% of your order to a nonprofit of your choice.';
  const isMegalith = app === CauseOfChoiceAppDictionary.OHW_MEGALITH;
  const buttonText = isMegalith ? 'Shop' : 'Close';
  const onClick = isMegalith ? handleGoToShop : handleCloseModal;
  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Text variant="h1" className={commonStyles.headerText}>
          {header}
        </Text>
        <ButtonBase onClick={handleCloseModal}>
          <Icons.Close />
        </ButtonBase>
      </Grid>
      <Grid className={commonStyles.container}>
        <Text className={commonStyles.regularText}>{subHeader}</Text>
      </Grid>
      <ChunkyButton
        avatar={<Icons.COCHeartHands />}
        onChange={handleGoBack}
        onChangeText="Change"
        primaryText={`${nonProfit?.nonProfitName}`}
        secondaryText={`${nonProfit?.nonProfitAddress?.city}, ${nonProfit?.nonProfitAddress?.state}`}
        tertiaryText={`EIN: ${nonProfit?.nonProfitTaxId}`}
        headerIcon={nonProfit?.isIRSVerified ? <Icons.COCVerified /> : null}
      />
      {handleGoToShop && (
        <Grid container justifyContent="center">
          <Button
            fullWidth
            type="primary"
            className={commonStyles.saveAndClose}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
