import gql from 'graphql-tag';

export default gql`
  mutation ContactEditMutation($input: ContactEditInput!) {
    contactEdit(input: $input) {
      contact {
        id
        accountId
        contactType
      }
    }
  }
`;
