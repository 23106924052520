import styled from '@emotion/styled';
import { theme, Text, Button, Grid } from '@onehope/design-system-v2';

export const CopyTextCard = styled('div')({
  padding: '24px',
  backgroundColor: theme.palette.lightYellowBeige.main,
  textAlign: 'center',
  boxShadow:
    '0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 6px 12px rgba(14, 31, 53, 0.08)',
  borderRadius: '12px',
});

export const CardText = styled(Text)({
  fontFamily: theme.bodyFontFamily,
  fontStyle: 'italic',
  fontWeight: 330,
  fontSize: '24px',
  lineHeight: '34px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  color: theme.palette.primary.main,
});

export const CopyTextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.7',
  },
});

export const CopiedTextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
  cursor: 'pointer',
});

export const SnackbarCopiedText = styled(Text)({
  color: theme.palette.white.main,
  paddingLeft: '16px',
});

export const SnackbarGridContainer = styled(Grid)({
  paddingTop: '2px',
});

export const CopyText = styled(Text)({
  letterSpacing: '0.1em',
  lineHeight: '16px',
  color: theme.palette.forestGreen.main,
  marginTop: '4px',
  marginLeft: '10px',
});

export const DialogButton = styled(Button)({
  width: '100%',
  borderRadius: '1px',
});

export const socialSharingButtons = {
  width: '100%',
  marginTop: '8px',
  marignBottom: '30px',
};

export const IconWrapper = styled('div')({
  marginRight: '16px',
  cursor: 'pointer',
  height: '60px',
  svg: {
    width: '60px',
    height: '60px',
  },
});
