import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoaderDark, theme, Text } from '@onehope/design-system-v2';
import BulkExportContactsV2Mutation from '../../../mutations/ContactsV2/ContactsV2BulkExportMutation';
import AddContactDialog from './AddContactDialog';
import ImportContacts from './ImportContacts';
import ExportContacts from './ExportContacts';
import {
  BulkExportContactsV2,
  BulkExportContactsV2Variables,
} from '../../../mutations/ContactsV2/generatedTypes/BulkExportContactsV2';
import GET_CUSTOMER_AUTOSHIPS from '../../../mutations/ContactsV2/GetCustomerAutoships';
import {
  CustomerAutoshipsContactsV2,
  CustomerAutoshipsContactsV2Variables,
} from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsContactsV2';

interface HeaderProps {
  userId: string;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 0 16px;
  flex-direction: column;
  text-align: left;

  @media all and (min-width: 768px) {
    padding: 24px 40px 0 40px;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const HeaderLeft = styled.div`
  a {
    color: ${theme.palette.forestGreen.main};
    text-decoration: underline;
    font-size: 14px;
    font-weight: 420;
    line-height: 21px;
    text-align: left;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  padding-bottom: 24px;
  @media all and (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  margin-left: -10px;
  @media all and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const HeaderTitle = styled(Text)`
  && {
    font-weight: 300;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.03em;
    margin-bottom: 1rem;
  }
`;

const NotificationText = styled.div`
  padding-top: 8px;
  font-size: 14px;
  width: 288px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: center;
`;

const Header = ({ userId }: HeaderProps) => {
  const flags = useFlags();

  const [csvExportStarted, setExportStarted] = useState(false);

  const { data: customerAutoships } = useQuery<
    CustomerAutoshipsContactsV2,
    CustomerAutoshipsContactsV2Variables
  >(GET_CUSTOMER_AUTOSHIPS, {
    variables: {
      maxWineCount: 5,
      take: 1,
      skip: 0,
    },
  });

  const [
    contactsCSVExport,
    { loading: contactsCSVExportLoading },
  ] = useMutation<BulkExportContactsV2, BulkExportContactsV2Variables>(
    BulkExportContactsV2Mutation,
  );

  const handleContactsCSVExport = () => {
    if (!contactsCSVExportLoading) {
      setExportStarted(false);
      return contactsCSVExport({
        variables: {
          ownerAccountId: userId,
        },
      })
        .then((res: any) => {
          console.log(res);
          setExportStarted(true);
        })
        .catch(async (errors: any) => {
          console.error(errors);
        });
    }
    return null;
  };

  return (
    <>
      <HeaderContainer>
        <HeaderLeft>
          <HeaderTitle variant="h3">Contacts</HeaderTitle>
          {flags.wineClub923 &&
            customerAutoships?.viewer?.user?.customerAutoships.total > 0 && (
              <Link to="/contacts/four-bottle-subscribers">
                View 4-bottle subscribers
              </Link>
            )}
        </HeaderLeft>
        <ActionsWrapper>
          <ButtonsWrapper>
            <AddContactDialog />
          </ButtonsWrapper>
          <ContactActionsContainer>
            <ImportContacts userId={userId} />
            <ExportContacts handleContactsCSVExport={handleContactsCSVExport} />
          </ContactActionsContainer>
          {csvExportStarted && (
            <NotificationText>
              Your export is in progress. We will email you when it's complete
            </NotificationText>
          )}
          {contactsCSVExportLoading && (
            <LoadingWrapper>
              <LoaderDark />
            </LoadingWrapper>
          )}
        </ActionsWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
