import * as React from 'react';
import styled from '@emotion/styled';

import { CheckBoxPrimaryCircle } from '../styles/ant-design-styles/checkBox';
import { Icon } from '../styles/ant-design-styles/icon';
import { primaryColor, font, textColor } from '../styles/cssConstants';

type CheckToggleButtonProps = {
  kind?: string;
  width?: string;
  label: string;
  onClick: () => void;
  svgIcon?: string;
  emoji?: string;
  on: boolean;
};

type ChunkButtonProps = {
  kind?: string;
  width?: string;
  on?: boolean;
};

const ChunkButton = styled.button<ChunkButtonProps>`
  background-color: ${({ on }: ChunkButtonProps) => (on ? 'white' : '#f2f2f2')};
  width: ${({ width }: ChunkButtonProps) => (width ? width : 'inherit')};
  border: ${({ on }: ChunkButtonProps) =>
    on ? `1px solid ${primaryColor}` : '1px solid white'};
  outline: none;
  border-radius: 4px;
  font-size: 22px;
  padding: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const VerticalButtonText = styled.div<ChunkButtonProps>`
  margin-top: ${({ kind }: ChunkButtonProps) => (kind === 'check' ? '6px' : 0)};
  font-family: ${font};
  color: ${textColor};
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
`;

export default function ChunkyToggleButton({
  onClick,
  kind,
  label,
  width,
  svgIcon,
  emoji,
  on,
}: CheckToggleButtonProps) {
  // Usually these button behaviors are paired with another,
  // So the state should be managed outside of this button.
  // const [on, setOn] = useState(false);

  // const toggle = () => {
  //   setOn(!on);
  //   if (onClick) {
  //     onClick();
  //   }
  // };

  const getKind = (kind?: string) => {
    switch (kind) {
      case 'check':
        return <CheckBoxPrimaryCircle checked={on} />;
      case 'emoji':
        return on ? <span>👍</span> : <span>👎</span>;
      case 'icon':
        return <Icon component={svgIcon} style={{ fontSize: 40 }} />;
      case 'set-emoji':
        return <span>{emoji}</span>;
      default:
        return on ? <span>👍</span> : <span>👎</span>;
    }
  };
  return (
    <span>
      <ChunkButton
        on={on}
        kind={kind}
        type="button"
        width={width}
        onClick={onClick}
      >
        {kind && getKind(kind)}
        <VerticalButtonText kind={kind}>{label}</VerticalButtonText>
      </ChunkButton>
    </span>
  );
}
