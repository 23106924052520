import { Icons, theme, styled } from '@onehope/design-system-v2';

const { EditIcon: MuiEditIcon } = Icons;

export const FormWrapper = styled('form')`
  max-width: 460px;
  margin: 40px auto 40px auto;
  padding: 0 16px;
  @media all and (min-width: 768px) {
    padding: 0;
  }
`;

export const DetailsHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StraightLine = styled('div')<{ isDark?: boolean }>`
  height: 1px;
  background-color: ${({ isDark }) =>
    isDark ? theme.palette.common.black : theme.palette.veryLightGrey.main};
  margin: ${({ isDark }) =>
    isDark ? '12px 0px 16px 0px' : '24px 0px 32px 0px'};
`;

export const EditIcon = styled(MuiEditIcon)`
  cursor: pointer;
`;
