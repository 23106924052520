import gql from 'graphql-tag';

const REACH_OUTS_V2 = gql`
  query CEDash_GetReachOutsV2($category: String, $isActive: Boolean) {
    viewer {
      id
      v2 {
        id
        reachOutTemplates {
          templates(category: $category, isActive: $isActive) {
            id
            reachOutId
            name
            fullTitle
            coverImageUrl
            expirationDate
            isActive
            helpCenterArticleUrl
            category {
              name
              contentfulEntryId
              templateCount
            }
          }
        }
      }
    }
  }
`;

export default REACH_OUTS_V2;
