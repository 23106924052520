import { gql } from '@apollo/client';

const GET_AUTOCOMPLETE_DATA_QUERY = gql`
  query CauseOfChoiceAutoCompleteDataQuery(
    $ceoId: String
    $count: Int
    $inputValue: String
    $withAllData: Boolean
    $searchCEOS: Boolean
    $searchEvents: Boolean
    $searchProducts: Boolean
  ) {
    viewer {
      id
      user {
        id
        autoCompleteData(
          first: 10
          count: $count
          ceoId: $ceoId
          withAllData: $withAllData
          inputValue: $inputValue
          searchCEOS: $searchCEOS
          searchEvents: $searchEvents
          searchProducts: $searchProducts
        ) {
          edges {
            node {
              id
              ceoId
              image
              isCEO
              isEvent
              isProduct
              eventId
              routePath
              customUrl
              primaryLabel
              secondaryLabel
              quaternaryLabel
              productCategory
            }
          }
        }
      }
    }
  }
`;

export default GET_AUTOCOMPLETE_DATA_QUERY;
