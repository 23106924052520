import React from 'react';
import {
  Grid,
  Text,
  TEXT_DS,
  styled,
  theme,
  Icons,
} from '@onehope/design-system-v2';
const { InfoAlert } = Icons;

const ErrorContainer = styled(Grid)({
  padding: '16px',
  backgroundColor: theme.palette.redTint.main,
  border: `1px solid ${theme.palette.deepRedText.main}`,
  borderRadius: '2px',
  maxWidth: '600px',
  width: '100%',
  margin: 'auto',
  flexWrap: 'nowrap',
  gap: '8px',
  marginTop: '24px',
});

const ErrorAlert = styled(Grid)({
  height: '16px',
});

const ErrorTitle = styled(Text)({
  display: 'inline-block',
});

function SubscriptionError({ errorMessage }: { errorMessage: string }) {
  return (
    <ErrorContainer
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <ErrorAlert item>
        <InfoAlert fill={theme.palette.deepRedText.main} />
      </ErrorAlert>
      <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
        <ErrorTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          Error:
        </ErrorTitle>{' '}
        {errorMessage}
      </Text>
    </ErrorContainer>
  );
}

export default SubscriptionError;
