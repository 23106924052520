import React, { useState } from 'react';
import FormContainer from '../../../Event/FormContainer';
import WineForm from '../../../Events/WineForm';
import { getInitialValues } from '../../../Event/TabContent/Wine/helpers';
import { WineFormSchema } from '../../../Event/TabContent/Wine/validationSchema';
import { FormValues } from '../../../Event/TabContent/Wine/FormTypes';
import { CEDash_EventsV2Query_viewer_user as UserType } from '../../../../queries/generatedTypes/CEDash_EventsV2Query';
import { DetailsWrapper } from '../../../Event/TabContent/index.styles';
interface WineProps {
  form: any;
  user: UserType;
  loadingEventCreation: boolean;
  handleNextStepChange: (
    key: string,
    values: FormValues,
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>,
  ) => Promise<void> | undefined;
  handlePreviousStepChange: () => void;
}

export default function Wine(props: WineProps) {
  const { user, handlePreviousStepChange, loadingEventCreation } = props;
  const [errorNotification, setErrorNotification] = useState('');
  return (
    <DetailsWrapper>
      <FormContainer
        validationSchema={WineFormSchema}
        initialValues={getInitialValues()}
        /* eslint-disable react/jsx-no-bind */
        formToUse={(props: any) => (
          <WineForm
            {...props}
            editing={false}
            loadingEventCreation={loadingEventCreation}
            numberOfAvailableCredits={user?.numberOfAvailableCredits ?? 0}
            numCreditsUsed={0}
            starterKitCredits={user?.availableStarterKitCredits}
            handlePreviousStepChange={handlePreviousStepChange}
            errorNotification={errorNotification}
            setErrorNotification={setErrorNotification}
          />
        )}
        onSubmit={async (values: FormValues) => {
          await props.handleNextStepChange(
            'wine',
            values,
            setErrorNotification,
          );
        }}
      />
    </DetailsWrapper>
  );
}
