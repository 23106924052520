import { gql } from '@apollo/client';

export default gql`
  query CEDash_FastStartQuery {
    viewer {
      id
      v3 {
        fastStart23 {
          salesTotal
          sponsorshipTotal
          bonusTotal
          fastStartBegin
          fastStartEnd
          ringSetSize
          ringsCompletedCount
          rings {
            completed
            isCapstoneRing
            bonusAmount
            sponsorshipGoal
            sponsorshipValue
            salesGoal
            salesValue
            percentComplete
          }
          salesRewards {
            completed
            salesGoal
            salesValue
            rewardName
            rewardImageURI
          }
        }
      }
    }
  }
`;
