type PermissionsType = {
  oneCommerce: {
    admin: {
      access: boolean;
    };
    ceo: {
      access: boolean;
    };
    subscriber: {
      access: boolean;
    };
  };
};

export enum CauseOfChoiceLocationKeys {
  SNACKBAR = 'snackbar',
  NAV_MENU = 'navMenu',
  ACCOUNTS_IMPACT = 'account',
  CHECKOUT = 'checkout',
  CE_PROFILE = 'ceProfile',
  CE_DASHBOARD = 'ceDashboard',
  CE_ENROLLMENT = 'ceEnrollment',
  EVENT_FUNDRAISER = 'eventFundraiser',
  IMPACT_PAGE = 'impactPage',
}

export enum CauseOfChoiceAppDictionary {
  OHW_MEGALITH,
  OHW_CE_DASH,
  OHW_CE_ENROLLMENT,
  OHW_CHECKOUT,
}

export enum CauseOfChoiceTypeKeys {
  CE = 'ce',
  DEFAULT = 'default',
  FUNDRAISER = 'fundraiser',
  NON_PROFIT = 'nonprofit',
}

export enum CauseOfChoiceViewsDictionary {
  DEFAULT_VIEW,
  CE_PROFILE_VIEW,
}

export type AddressType = {
  id: string;
  firstName: string;
  lastName: string;
  addressId: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber?: string;
  nickname?: string;
  specialInstructions?: string;
  companyName?: string;
};

export type NonProfitType = {
  id: string;
  nonProfitName: string;
  nonProfitId: string;
  nonProfitTaxId: string;
  nonProfitDescription?: string;
  nonProfitPayeeName?: string;
  isIRSVerified?: boolean;
  isPreApproved?: string;
  nonProfitAddress?: AddressType;
};

export type ManualNonProfitInputType = {
  customNonProfit: string;
  nonProfitTaxId: string;
  address: string;
  addressLineOne: string;
  city: string;
  state: string;
  addressLineTwo: string;
  zip: string;
};

export type UserProfile = {
  __typename: 'Profile';
  id: string;
  image: string | null;
  city?: string;
  state?: string;
  zip: number;
  summary: string;
};

export type UserType = {
  id?: string;
  firstName?: string;
  lastName?: string;
  pointBalance?: number;
  role?: string;
  userId: string | null;
  email: string | null;
  profile: UserProfile | null;
  profileImages: {
    id?: string;
    thumb?: string;
    medium?: string;
  };
  accountRole: {
    id: string;
    accountRoleTitle: string | null;
  } | null;
  conciergeCustomerAccountId: string | null;
  permissions: PermissionsType;
  selectedCause?: {
    causeId: string;
    statement?: string;
    nonProfit?: NonProfitType;
  };
  cartV2?: {
    id: string;
    totalNumberOfItems: number;
    giftCardEntries?: any;
    eventId?: string;
    eventSource?: string;
    event?: {
      eventId: string;
      title: string;
      hostFirstName: string;
      hostFullName: string;
      hostAccountId: string;
      trinityPartyId: string;
      hostNonProfitName: string;
      images: Array<{
        id: string;
        bucketName: string;
        path: string;
        groupName: string;
        fileName: string;
      }>;
      donationInfo?: {
        nonProfit: { nonProfitId: string | null; nonProfitName: string | null };
        nonProfitV2?: NonProfitType;
      };
    };
    guestNonProfit?: NonProfitType;
  };
  conciergeUser?: UserType;
  shoppingWithCeoUser?: UserType;
};

export type NonProfitWithEditType = Partial<NonProfitType> & {
  isEdited: boolean;
};

export enum TypeKeys {
  UPDATE_SCREEN = 'UPDATE_SCREEN',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_LOADING = 'UPDATE_LOADING',
}

export enum CauseOfChoiceScreenDictionary {
  HOME_SCREEN,
  NON_PROFIT_SEARCH_SCREEN,
  MANUAL_NON_PROFIT_SCREEN,
  FUNDRAISER_SEARCH_SCREEN,
  NON_PROFIT_CONFIRMATION_SCREEN,
  NON_SURE_CONFIRMATION_SCREEN,
  FUNDRAISER_CONFIRMATION_SCREEN,
  CE_CAUSE_CONFIRMATION_SCREEN,
  CAUSE_OF_CHOICE_SELECTED_SCREEN,
  EVENT_SHOPPING_SELECTED_SCREEN,
  NON_PROFIT_EDIT_SCREEN,
}

export enum CauseOfChoiceButtonDictionary {
  CE_CAUSE,
  NON_PROFIT,
  FUNDRAISER,
  NOT_SURE,
}

export type StateType = {
  user: any;
  loading: boolean;
  screen: CauseOfChoiceScreenDictionary;
  handleNonProfitSelected?: ({
    nonProfitId,
    nonProfitEditId,
  }: {
    nonProfitId: string | null;
    nonProfitEditId?: string | null;
  }) => void;
};

export type UpdateScreenAction = {
  type: TypeKeys.UPDATE_SCREEN;
  screen: CauseOfChoiceScreenDictionary;
};

export type UpdateUserAction = {
  type: TypeKeys.UPDATE_USER;
  user: any;
};

export type UpdateLoadingAction = {
  type: TypeKeys.UPDATE_LOADING;
  loading: boolean;
};

export type ActionTypes =
  | UpdateScreenAction
  | UpdateUserAction
  | UpdateLoadingAction;
