/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';
import { ThemeProvider, theme } from '@onehope/design-system-v2';
import { SegmentPageView } from './src/components/SegmentPageView';

import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </ThemeProvider>
  );
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <SegmentPageView />
      {element}
    </>
  );
};
