import { Text, Icons, styled } from '@onehope/design-system-v2';

export const BannerContainer = styled('div')(() => ({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BannerText = styled(Text)(() => ({
  position: 'absolute',
  padding: '0 8px',
}));

export const FastStartCheck = styled(Icons.FastStartCheck)(() => ({
  marginLeft: 4,
}));
