// @ts-ignore
import React from 'react';
import { InputPrimary } from '../styles/ant-design-styles/inputs';
import * as s from '../styles/styles';

interface InputProps {
  kind: string;
  type?: string;
  prefixCls?: string;
  size?: string;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  addonBefore?: string | React.ReactNode;
  addonAfter?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  allowClear?: boolean;
  disabled?: boolean;
  defaultValue?: string | null;
  id?: string;
  value?: string | null;
  label?: string;
  input?: HTMLInputElement;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  name?: string;
  autoComplete?: string;
}

export default function Input(props: InputProps) {
  const { kind, label, ...rest } = props;
  switch (kind) {
    case 'text': {
      return (
        <React.Fragment>
          {label && <s.InputHeader htmlFor={props.name}>{label}</s.InputHeader>}
          <InputPrimary {...rest} />
        </React.Fragment>
      );
    }
    default:
      return (
        <React.Fragment>
          {label && <s.InputHeader htmlFor={props.name}>{label}</s.InputHeader>}
          <InputPrimary {...rest} />
        </React.Fragment>
      );
  }
}
