import { gql } from '@apollo/client';

const mutation = gql`
  mutation WineClub_AutoShipPostponeMutation(
    $input: AutoShipPostponeMutationInput!
    $autoShipId: String!
  ) {
    autoShipPostpone(input: $input) {
      result
      user {
        id
        autoShip(autoShipId: $autoShipId) {
          id
          nextPaymentDate
          stripePlanId
          isCuratedWineClub
          stripeSubscriptionId
        }
      }
    }
  }
`;

export default mutation;
