import { gql } from '@apollo/client';
import { CEDash_CauseOfChoiceNonProfit } from './NonProfitV2Fragment';

export const CEDash_CauseOfChoiceSelectCause = gql`
  fragment CEDash_CauseOfChoiceSelectCause on Cause {
    id
    causeId
    statement
    donationTotal
    nonProfit {
      ...CEDash_CauseOfChoiceNonProfit
    }
  }
  ${CEDash_CauseOfChoiceNonProfit}
`;
