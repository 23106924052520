import * as yup from 'yup';
import { statesAbbrev } from '../../../utils/statesAbbrev';

const validationSchema = yup.object({
  addressLineOne: yup
    .string()
    .min(4, 'Address line one should be min 4 characters'),
  city: yup.string().min(2, 'City should be min 2 characters'),
  zip: yup.string().matches(/^(?!0{3})[0-9]{3,5}$/, 'Enter valid zip code'),
  state: yup
    .mixed()
    .oneOf(
      statesAbbrev,
      'Not a valid state, use 2 capital letters, certain states are disallowed',
    ),
  nonProfitName: yup
    .string()
    .min(4, 'Nonprofit name should be more than 4 characters')
    .max(40, 'Nonprofit name should be less than 40 characters'),
});

const validationSchemaManualAddress = yup.object({
  addressLineOne: yup
    .string()
    .min(4, 'Address line one should be min 4 characters')
    .required('Address line one required'),
  city: yup
    .string()
    .min(2, 'City should be min 2 characters')
    .required('City is required'),
  zip: yup
    .string()
    .matches(/^(?!0{3})[0-9]{3,5}$/, 'Enter valid zip code')
    .required('Zip code required'),
  state: yup
    .mixed()
    .oneOf(
      statesAbbrev,
      'Not a valid state, use 2 capital letters, certain states are disallowed',
    )
    .required('State required'),
  nonProfitName: yup
    .string()
    .min(4, 'Nonprofit name should be more than 4 characters')
    .max(40, 'Nonprofit name should be less than 40 characters')
    .required('Nonprofit name is required'),
});

export { validationSchema, validationSchemaManualAddress };
