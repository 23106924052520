import React from 'react';
import { navigate } from 'gatsby';
import { DateTime } from 'luxon';
import Decimal from 'decimal.js';

import { styles as s } from '@onehope/design-system';
import {
  styled,
  Grid,
  Tooltip,
  Checkbox,
  Table,
  TableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Icons,
} from '@onehope/design-system-v2';
const { CeBrownIcon, RefreshRightArrow, HeartOutline } = Icons;

import { ContactType, useContactsContext } from '../ContactsContext';

import ConciergeButton from './ConciergeButton';
import IconMenu from './IconMenu';
import TableHeader from './TableHeader';
import { ContactManagementTabs } from '../../ContactV2/ContactDetailsPage/TabContent/tabConstants';

const { cssConstants } = s;

interface TableBodyProps {
  contacts: ContactType[];
  handleSortContacts: (newSortBy: string) => void;
}

const TableWrapper = styled('div')`
  font-family: ${cssConstants.bodyFontFamily};
  color: ${cssConstants.textColor};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.bodyFontFamily};
    color: ${cssConstants.textColor};
    font-size: 14px;
  }
`;

const NameCell = styled(Grid)({
  gap: '8px',
  flexWrap: 'nowrap',
});

const TypeIcon = styled('span')({
  height: '20px',
  width: '20px',
  svg: {
    height: '20px',
    width: '20px',
  },
});

const getCustomerType = (contact: ContactType) => {
  return (
    <>
      {contact?.isCe && (
        <Tooltip title="Wine Rep" placement="top">
          <TypeIcon>
            <CeBrownIcon />
          </TypeIcon>
        </Tooltip>
      )}
      {contact?.isSubscriber && (
        <Tooltip title="Wine Club" placement="top">
          <TypeIcon>
            <RefreshRightArrow />
          </TypeIcon>
        </Tooltip>
      )}
      {contact?.isHost && (
        <Tooltip title="Host" placement="top">
          <TypeIcon>
            <HeartOutline />
          </TypeIcon>
        </Tooltip>
      )}
    </>
  );
};

const TableBodyContainer = ({
  handleSortContacts,
  contacts,
}: TableBodyProps) => {
  const {
    isEditing,
    selectedContacts,
    toggleSelectedContact,
  } = useContactsContext();

  const handleClick = (contactId: string) =>
    navigate(
      `/contact?contactId=${contactId}&tab=${ContactManagementTabs.DETAILS}`,
    );

  return (
    <TableWrapper>
      <Table stickyHeader aria-label="contacts table">
        <TableHeader handleSortContacts={handleSortContacts} />
        {contacts && contacts.length > 0 ? (
          <TableBody>
            {contacts.map((contact: ContactType) => {
              const {
                firstName,
                lastName,
                contactId,
                lifetimeSpend,
                pointBalance,
                lastOrder,
                nextShipmentDate,
                selectedCause,
              } = contact;
              const isChecked = selectedContacts.includes(contactId);
              return (
                <TableRow key={contactId} hover role="checkbox">
                  {isEditing ? (
                    <TableCell align="left">
                      <Checkbox
                        checked={isChecked}
                        onClick={() => toggleSelectedContact(contactId)}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    <NameCell container alignItems="center">
                      {firstName} {lastName} {getCustomerType(contact)}
                    </NameCell>
                  </TableCell>
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    {nextShipmentDate
                      ? DateTime.fromISO(nextShipmentDate, {
                          zone: 'America/Chicago',
                        }).toLocaleString(DateTime.DATE_SHORT)
                      : '---'}
                  </TableCell>
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    {lastOrder
                      ? DateTime.fromISO(lastOrder, {
                          zone: 'America/Chicago',
                        }).toLocaleString(DateTime.DATE_SHORT)
                      : '---'}
                  </TableCell>
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    {selectedCause?.nonProfit?.nonProfitName ?? '---'}
                  </TableCell>
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    {selectedCause?.donationTotal
                      ? `$${selectedCause.donationTotal.toFixed(2)}`
                      : '$0.00'}
                  </TableCell>
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    {pointBalance
                      ? `${pointBalance} ($${new Decimal(pointBalance)
                          .times(0.01)
                          .toFixed(2)})`
                      : '0 ($0.00)'}
                  </TableCell>
                  <TableCell
                    onClick={() => handleClick(contactId)}
                    align="left"
                  >
                    {lifetimeSpend ? `$${lifetimeSpend.toFixed(2)}` : '$0.00'}
                  </TableCell>
                  <TableCell align="left">
                    <ConciergeButton contact={contact} />
                  </TableCell>
                  <TableCell align="left">
                    <IconMenu contact={contact} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : null}
      </Table>
    </TableWrapper>
  );
};

export default TableBodyContainer;
