import { gql } from '@apollo/client';

const mutation = gql`
  mutation CoC_AddNonProfitEditMutation($input: AddNonProfitEditInput!) {
    nonProfitEditAdd(input: $input) {
      nonProfitEdit {
        nonProfitEditId
        nonProfitName
        nonProfitAddress {
          firstName
          lastName
          city
          state
          zip
          phoneNumber
          addressLineOne
          addressLineTwo
        }
      }
    }
  }
`;

export default mutation;
