import React from 'react';
import {
  Grid,
  Text,
  TEXT_DS,
  makeStyles,
  styled,
  Icons,
} from '@onehope/design-system-v2';
const { AlertCircleOutline } = Icons;

import PaymentsWalletWrapper from './PaymentsWallet';

import { Account_GetAutoShip as SubscriptionType } from '../../queries/fragments/generatedTypes/Account_GetAutoShip';
import { Account_PaymentsGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment as PaymentType } from '../../queries/generatedTypes/Account_PaymentsGetQuery';

export const useStyles = makeStyles(() => ({
  formText: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: 2,
  },
  spacer: {
    paddingBottom: 16,
  },
}));

const PaymentErrorContainer = styled(Grid)({
  paddingTop: 16,
  gap: 10,
});

interface PaymentBookType {
  payment: PaymentType;
  subscription?: SubscriptionType;
  isEditingCeSubscription?: boolean;
  contactId?: string;
  sendToTrackEventV2?: any;
}

const PaymentDetails = ({
  payment,
  subscription,
  isEditingCeSubscription,
  contactId,
  sendToTrackEventV2,
}: PaymentBookType) => {
  const classes = useStyles();
  const { last4, expYear, expMonth, brand, name, paymentId } = payment;
  const subscriptionPaymentError =
    subscription?.paymentStatus === 'invoice.payment_failed';
  return (
    <>
      {name && (
        <Text variant="body1" component="p" className={classes.formText}>
          {`${name}`}
        </Text>
      )}
      {brand && (
        <Text variant="body1" component="p" className={classes.formText}>
          {`${brand} ending in ${last4}`}
        </Text>
      )}
      {expYear && (
        <Text variant="body1" component="p" className={classes.formText}>
          {`Expires ${expMonth}/${expYear}`}
        </Text>
      )}
      {subscriptionPaymentError && (
        <PaymentErrorContainer container alignItems="center" direction="row">
          <AlertCircleOutline />
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_ITALIC_16}>
            Update payment method
          </Text>
        </PaymentErrorContainer>
      )}
      <div className={classes.spacer} />
      <PaymentsWalletWrapper
        isEditingCeSubscription={isEditingCeSubscription}
        // @ts-ignore this component supports both Autoship and Wine Rep memberships
        subscription={subscription}
        currentPaymentId={paymentId}
        contactId={contactId}
        sendToTrackEventV2={sendToTrackEventV2}
      />
    </>
  );
};

export default PaymentDetails;
