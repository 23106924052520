import React from 'react';
import styled from '@emotion/styled';
import { MuiButton } from '@onehope/design-system-ohw';
import { DialogActions } from '@onehope/design-system-v2';
import { Dialog, DialogTitleWrapper, DialogTextWrapper } from '../index.styles';
import { Close } from '../../../assets/close';
import { SvgInvite } from '../../../assets/invite';

interface OrderLinkSentDialogProps {
  open: boolean;
  setClose: () => void;
}

const CloseSvg = styled(Close)`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;

const DialogActionsWrapper = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0 16px;
    @media only screen and (max-width: 600px) {
      padding: 0;
    }
  }
`;

export default function OrderLinkSentDialog(props: OrderLinkSentDialogProps) {
  const { open, setClose } = props;

  return (
    <Dialog
      open={open}
      onClose={setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseSvg onClick={setClose} />
      <DialogTitleWrapper>
        <SvgInvite />
        <div>Ordering Link Sent!</div>
      </DialogTitleWrapper>
      <DialogTextWrapper>
        FYI, we have emailed your new host the wine ordering link for their
        upcoming event!
      </DialogTextWrapper>
      <DialogActionsWrapper>
        <MuiButton
          type="PRIMARY"
          fullWidth
          onClick={setClose}
          color="primary"
          autoFocus
        >
          Close
        </MuiButton>
      </DialogActionsWrapper>
    </Dialog>
  );
}
