import React, { useMemo } from 'react';
import { styled } from '@onehope/design-system-v2';

import Iframe from '../components/Dashboard/Iframe/Iframe';

import Layout from '../layout';

const TimelyFeedContainer = styled('div')({
  padding: '16px',
});

const CalendarAndEventsPage = () => {
  const timelyFeed = useMemo(
    () => (
      <Iframe
        height="800px"
        src="https://events.timely.fun/r7gc62mt/posterboard"
        title="Events Timely"
        id="timely_script"
        name="timely-script"
      />
    ),
    [],
  );

  return (
    <Layout>
      <TimelyFeedContainer>{timelyFeed}</TimelyFeedContainer>
    </Layout>
  );
};

export default CalendarAndEventsPage;
