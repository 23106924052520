import gql from 'graphql-tag';

export default gql`
  query ReachOutViewCount($reachOutId: String!) {
    viewer {
      id
      v2 {
        id
        reachOutTemplate(id: $reachOutId) {
          id
          viewCounts {
            medium
            viewCount
          }
        }
      }
    }
  }
`;
