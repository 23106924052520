import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import EventsPageWrapper from './EventsWrapper';

import GET_EVENTS_BY_HOST_ACCOUNT_QUERY from '../../../../../queries/EventsByHostAccount';

import Loading from '../../../../../common/Loading';

interface ContactEventsPageProps {
  contact: any;
}

function ContactEventsPage({ contact }: ContactEventsPageProps) {
  const { accountId } = contact;
  const { data, loading, error } = useQuery(GET_EVENTS_BY_HOST_ACCOUNT_QUERY, {
    fetchPolicy: 'network-only',
    skip: !accountId,
    variables: {
      byHostAccountId: accountId,
    },
  });

  if (error) return null;
  if (!loading) {
    const events = data?.viewer?.user?.events || { edges: [] };
    return <EventsPageWrapper contact={contact} events={events} />;
  }
  return <Loading />;
}

export default ContactEventsPage;
