import queryString from 'query-string';
import React from 'react';

import ReachOuts from './index';
import ReachOut from './ReachOut';
import Invites from './Invites';
import Results from './Results';

type Props = {
  page: 'all' | 'invites' | 'results';
};

const ReachOutWrapper = ({ page }: Props) => {
  const { reachoutId, method } = queryString.parse(window.location.search);

  if (reachoutId) {
    switch (page) {
      case 'invites':
        return (
          <Invites
            reachOutId={reachoutId.toString()}
            method={method.toString()}
          />
        );
      case 'results':
        return <Results reachOutId={reachoutId.toString()} />;
      case 'all':
      default:
        return <ReachOut reachOutId={reachoutId.toString()} />;
    }
  }

  return <ReachOuts />;
};
export default ReachOutWrapper;
