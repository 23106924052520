import gql from 'graphql-tag';

export const Customers_user = gql`
  fragment Customers_user on User {
    id
    email
    customers(first: 10000, sortBy: "any") {
      edges {
        node {
          id
          isHost
          isSubscriber
          impactTotal
          dateOfBirth
          fullName
          accountId
          email
          mobilePhone
          lifeTimeSpend
          lastOrderDate
          contactType
          organizationName
        }
      }
    }
  }
`;

const GET_CUSTOMERS_QUERY = gql`
  query CEDash_CustomersQuery {
    viewer {
      id
      user {
        ...Customers_user
      }
    }
  }
  ${Customers_user}
`;

export default GET_CUSTOMERS_QUERY;
