import React, { useContext, createContext, useState } from 'react';
import {
  ApolloClient,
  ApolloQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import FEED_QUERY from '../queries/feedQuery';
import FEED_COUNT_QUERY from '../queries/feedQueryCount';
import ActivityEntry from '../types/ActivityEntry';
import { FeedTypes } from '../utils/constants';

export type DataProps = {
  apolloClient: ApolloClient<any>;
  dealerId?: string;
  userId?: string;
  cardsLoading: boolean;
  cardCount: number;
  savedFeed: ActivityEntry[];
  fetchedFeed: ActivityEntry[];
  setSavedFeed: React.Dispatch<React.SetStateAction<ActivityEntry[]>>;
  setFetchedFeed: React.Dispatch<React.SetStateAction<ActivityEntry[]>>;
  savedLimit: number;
  loadMoreAt: number;
  savedOffset: number;
  setSavedOffset: React.Dispatch<React.SetStateAction<number>>;
  feedCount: number;
  getFeedCount: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  refetchSaved:
    | ((
        variables?:
          | Partial<{
              dealerId: any;
              userId: any;
              feedType: any;
              limit: any;
              offset: any;
            }>
          | undefined,
      ) => Promise<ApolloQueryResult<any>>)
    | undefined;
  fetchSavedFeed: (
    options?:
      | QueryLazyOptions<{
          dealerId: any;
          userId: any;
          feedType: any;
          limit: any;
          offset: any;
        }>
      | undefined,
  ) => void;
};

export type ActionEngineFeedContextProps = {
  children: any;
  apolloClient: ApolloClient<any>;
  dealerId?: string;
  userId?: string;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const ActionEngineFeedContext = createContext<DataProps>();

export const useCheckoutNetworkLayerValue = () =>
  useContext<DataProps>(ActionEngineFeedContext);

export function ActionEngineFeedContextProvider({
  children,
  apolloClient,
  dealerId,
  userId,
}: ActionEngineFeedContextProps) {
  const limit = 20;
  const savedLimit = 20;
  const loadMoreAt = 3;

  const [cardCount, setCardCount] = useState(0);
  const [cardsLoading, setCardsLoading] = useState(true);
  const [fetchedFeed, setFetchedFeed] = useState([] as ActivityEntry[]);
  const [savedFeed, setSavedFeed] = useState([] as ActivityEntry[]);
  const [savedOffset, setSavedOffset] = useState(0);

  useQuery(FEED_QUERY, {
    client: apolloClient,
    variables: {
      dealerId,
      userId,
      limit,
      feedType: FeedTypes.new,
    },
    onCompleted: (data) => {
      const returnData = [...data?.viewer?.v2?.actionEngineFeed];
      const reorderedDeck = returnData.reverse();
      const updatedFeed = [...fetchedFeed, ...reorderedDeck];
      setFetchedFeed(updatedFeed);

      if (returnData.length > 0) {
        setCardsLoading(false);
      }
    },
  });

  // fixes the quick flash of the empty state screen caused by delay of activities loading
  // wait for 2.5 seconds until determining that there really are 0 cards in the deck
  setTimeout(() => {
    if (cardsLoading) {
      setCardsLoading(false);
    }
  }, 2500);

  const [getFeedCount, { data: feedCount }] = useLazyQuery(FEED_COUNT_QUERY, {
    client: apolloClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const count = data?.viewer?.v2?.actionEngineFeedCounts?.new || 0;
      setCardCount(count);
    },
  });

  const [fetchSavedFeed, { refetch: refetchSaved }] = useLazyQuery(FEED_QUERY, {
    client: apolloClient,
    fetchPolicy: 'network-only',
    variables: {
      dealerId,
      userId,
      feedType: FeedTypes.saved,
      limit: savedLimit,
      offset: savedOffset,
    },
    onCompleted: (data) => {
      const returnData = [...data?.viewer?.v2?.actionEngineFeed];
      const reorderedDeck = returnData.reverse();
      const updatedFeed = [...savedFeed, ...reorderedDeck];

      if (savedFeed.length < updatedFeed.length) {
        setSavedFeed(reorderedDeck);
      }

      // combine saved feed with return data when refetchSaved is called
      if (savedFeed.length === loadMoreAt) {
        const paginatedFeed = [...reorderedDeck, ...savedFeed];
        setSavedFeed(paginatedFeed);
      }
    },
  });

  return (
    <ActionEngineFeedContext.Provider
      value={{
        refetchSaved,
        savedFeed,
        fetchedFeed,
        fetchSavedFeed,
        setSavedFeed,
        setFetchedFeed,
        cardCount,
        feedCount,
        getFeedCount,
        cardsLoading,
        savedLimit,
        loadMoreAt,
        savedOffset,
        setSavedOffset,
        dealerId,
        userId,
        apolloClient,
      }}
    >
      {children}
    </ActionEngineFeedContext.Provider>
  );
}
