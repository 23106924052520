import { InstantSearch } from 'react-instantsearch';
import React, { useState, Fragment } from 'react';
import {
  makeStyles,
  ThemeType,
  Grid,
  Image,
  Text,
  TEXT_DS,
  Spacer,
  styled,
  theme,
} from '@onehope/design-system-v2';
import { PwpOffer } from '@onehope/pwp-offer';

import { indexName, searchClient } from '../../../algolia-client';

import SwapWinesDialog from '../../SwapWinesDialog';
import { urlProductImage } from '../../../utils/constants';

import { SendToTrackEventV2 } from '../../../utils/segment';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_userInfo,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
  WineClub_AutoShipGetQuery_viewer_user_autoShip_winePackItemsSavings_edges as ProductType,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import { WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify } from '../../../queries/generatedTypes/WineClub_SegmentIdentifyGetQuery';

export const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    margin: '0 auto',
  },
  productContainer: {
    marginBottom: 24,
  },
  productCount: {
    marginBottom: 24,
  },
  productImage: {
    height: 80,
    width: 80,
    marginRight: 8,
  },
  bronzeTintBar: {
    borderBottom: `1px solid ${theme.palette.bronzeTint.main}`,
    margin: '24px auto',
  },
}));

export const ProductText = styled(Grid)({
  paddingBottom: 10,
});

const ProductStrikeThroughText = styled('div')({
  paddingRight: 8,
});

const SaveTextWrapper = styled('div')({
  backgroundColor: theme.palette.primary.main,
  padding: '2px 4px',
  margin: '0 0 10px 0',
});

const SaveText = styled(Text)({
  color: 'white',
});

function getPercentage(percent: number) {
  return `${Math.round(percent * 100)}%`;
}

const reorderProducts = (products: ProductType[], autoShipId: string) => {
  const winePackOrderingName = `winePackOrdering_${autoShipId}`;
  const storedWinePackOrdering =
    localStorage.getItem(winePackOrderingName) &&
    JSON.parse(localStorage.getItem(winePackOrderingName) || '');
  if (!storedWinePackOrdering || !products) return products;

  // Make sure to remove undefined values so that we fall back to the full list of products
  // In case localStorage hasn't been updated yet when this is called.
  const reorderedProducts = storedWinePackOrdering
    .map((item: string) =>
      products.find((p: ProductType) => item === p.node?.productId),
    )
    .filter((x: ProductType | undefined) => !!x);

  // as a fail-safe, return the unordered products if the lengths don't match;
  if (reorderedProducts?.length !== products?.length) return products;

  return reorderedProducts;
};

type WinePackItemsProps = {
  segmentUser:
    | WineClub_AutoShipGetQuery_viewer_user
    | WineClub_AutoShipGetQuery_viewer_userInfo;
  segmentPersonas: WineClub_SegmentIdentifyGetQuery_viewer_segmentIdentify;
  user: WineClub_AutoShipGetQuery_viewer_user;
  addOnQty: number;
  autoShipId: string;
  refetchSubscription: () => void;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  handleUpdateAutoShipPwp: any;
  handleAddPwP: any;
  handleRemovePwp: any;
  validPwpProduct: any;
  autoShipPwP: any;
  pwpQty: any;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
};

export default function WinePackItems(props: WinePackItemsProps) {
  const classes = useStyles();
  const {
    subscription,
    handleUpdateAutoShipPwp,
    handleAddPwP,
    handleRemovePwp,
    validPwpProduct,
    autoShipPwP,
    pwpQty,
  } = props;

  const [hasSwapped, setHasSwapped] = useState(false);

  const products = subscription.winePackItemsSavings?.edges || [];
  const productCount = products.length + pwpQty;

  // if no swap has been made, use default ordering
  const orderedProducts = hasSwapped
    ? // @ts-ignore
      reorderProducts(products, props.autoShipId)
    : products;

  // PwP is locked when an invoice has been created for the current renewal period.
  // once the renewal period rolls over (~1 hour after the invoice is created), it will be editable again.
  const pwpOfferLocked = !subscription?.canChangePwP;

  return (
    <Fragment>
      {!autoShipPwP && (
        <Grid id="pwp-offer">
          <PwpOffer
            pwpProduct={validPwpProduct}
            handleAddPwpToCart={handleAddPwP}
            handleRemovePwpToCart={handleRemovePwp}
            quantity={pwpQty}
            setAutoShipPwP={handleUpdateAutoShipPwp}
            autoShipPwP={autoShipPwP}
            pwpOfferLocked={pwpOfferLocked}
            placeholderOffer={!validPwpProduct}
          />
          <div className={classes.bronzeTintBar}></div>
        </Grid>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        id="winePackItemsContainer"
      >
        <Text
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_18}
          className={classes.productCount}
        >
          ({productCount + props.addOnQty}) items in order
        </Text>
      </Grid>
      {autoShipPwP && (
        <Grid id="pwp-offer" className={classes.container}>
          <PwpOffer
            pwpProduct={validPwpProduct}
            handleAddPwpToCart={handleAddPwP}
            handleRemovePwpToCart={handleRemovePwp}
            quantity={pwpQty}
            setAutoShipPwP={handleUpdateAutoShipPwp}
            autoShipPwP={autoShipPwP}
            pwpOfferLocked={pwpOfferLocked}
            placeholderOffer={!validPwpProduct}
          />
          <Spacer xs="14px" sm="14px" lg="14px" />
        </Grid>
      )}
      <Grid container spacing={0} className={classes.container}>
        {orderedProducts?.map(({ node: item }: any, index: number) => {
          const {
            productName,
            productId,
            priceDiscount,
            productImageUrl,
            priceOriginal,
            hasQualifyingDiscount,
            priceDiscountPercent,
            quantity,
          } = item;
          return (
            <Grid
              wrap="nowrap"
              key={`${productId}${index}`}
              container
              direction="row"
              className={classes.productContainer}
            >
              <Grid>
                <Image
                  src={`${urlProductImage}${productImageUrl}`}
                  className={classes.productImage}
                  alt={'Product Image'}
                />

                <InstantSearch
                  indexName={indexName}
                  searchClient={searchClient}
                >
                  <SwapWinesDialog
                    segmentUser={props.segmentUser}
                    segmentPersonas={props.segmentPersonas}
                    user={props.user}
                    autoShipId={props.autoShipId}
                    winePackItems={orderedProducts}
                    refetchSubscription={props.refetchSubscription}
                    currentProductId={productId}
                    handleNotificationOpen={() => {}}
                    handleNotificationClose={() => {}}
                    setHasSwapped={setHasSwapped}
                    index={index}
                    conciergeMode={props.conciergeMode}
                    sendToTrackEventV2={props.sendToTrackEventV2}
                  />
                </InstantSearch>
              </Grid>
              <Grid container direction="column">
                <ProductText container item>
                  <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                    {productName}
                  </Text>
                </ProductText>
                <ProductText container item>
                  {hasQualifyingDiscount && (
                    <ProductStrikeThroughText>
                      <Text
                        variant="custom"
                        default={TEXT_DS.BODY_REGULAR_STRIKE_14}
                      >
                        ${priceOriginal?.toFixed(2)}
                      </Text>
                    </ProductStrikeThroughText>
                  )}
                  <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                    $
                    {priceDiscount
                      ? priceDiscount?.toFixed(2)
                      : priceOriginal?.toFixed(2)}
                  </Text>
                </ProductText>
                {hasQualifyingDiscount && (
                  <Grid container item justifyContent="flex-start">
                    <SaveTextWrapper>
                      <SaveText
                        variant="custom"
                        default={TEXT_DS.OVERLINE_SEMIBOLD_11}
                      >
                        Save {getPercentage(priceDiscountPercent)}
                      </SaveText>
                    </SaveTextWrapper>
                  </Grid>
                )}
                <Grid container item>
                  <Text variant="custom" default={TEXT_DS.OVERLINE_REGULAR_11}>
                    QTY: {quantity}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
}
