// @ts-ignore
import * as yup from 'yup';

import { isValidDate, mustBe21OrOlder } from '@onehope/validation';

yup.addMethod(yup.string, 'isValidDate', isValidDate);
yup.addMethod(yup.string, 'mustBe21OrOlder', mustBe21OrOlder);

export const AddCustomerFormSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Required'),
  phone: yup
    .string()
    .matches(
      /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
      'Please enter a valid phone number',
    ),
  dateOfBirth: yup
    .string()
    .isValidDate()
    .mustBe21OrOlder()
    .required('Required'),
});
