import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { theme } from '@onehope/design-system-v2';
import Header from './Header';
import TabNavigation from './TabNavigation/TabNavigation';
import TabContent from './TabContent';
import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../queries/generatedTypes/CEDash_EventDetailsPage';
import BreadCrumbs from '../../common/Breadcrumbs';

//#region Styles

const Container = styled.div`
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
  background: white;
  padding: 24px 0 40px 0;
  @media only screen and (min-width: 768px) {
    padding: 40px 0 80px 0;
  }
`;

//#region Event Details Page Component

interface EventDetailsPageProps {
  event: CEDash_EventDetailsPage_viewer_event;
  tab: string | string[];
  contactId?: string | string[] | null | undefined;
  user: CEDash_EventDetailsPage_viewer_user;
  openNewOrder?: boolean;
}

function EventDetailsPage(props: EventDetailsPageProps) {
  const { tab, contactId, user, openNewOrder } = props;
  const [event, setEvent] = useState(props.event);
  const [didMount, setDidMount] = useState(false);

  const handleChange = (e: React.FormEvent<EventTarget>, value: string) => {
    e.preventDefault();
    if (contactId)
      return navigate(
        `/event?eventId=${event?.eventId}&tab=${value}&contactId=${contactId}`,
      );
    return navigate(`/event?eventId=${event?.eventId}&tab=${value}`);
  };
  const tabValue = typeof tab === 'string' ? tab : null;
  const location = contactId ? 'eventFromContact' : 'event';

  useEffect(() => {
    setDidMount(true);
  }, []); // Runs only once because of empty dependency array

  useEffect(() => {
    if (didMount && !!props.event) {
      setEvent(props.event);
    }
  }, [didMount, props.event]);

  //#region HTML

  return (
    <Container>
      <BreadCrumbs location={location} contactId={contactId} />
      <Header event={event} user={user} />
      <TabNavigation
        event={event}
        tabValue={tabValue}
        handleChange={handleChange}
      />
      <TabContent
        tabValue={tabValue}
        event={event}
        user={user}
        openNewOrder={openNewOrder}
      />
    </Container>
  );
}

export default EventDetailsPage;
