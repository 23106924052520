import React, { useState } from 'react';

import {
  Container,
  Grid,
  List,
  styled,
  Text,
  TEXT_DS,
  theme,
  useMediaQuery,
} from '@onehope/design-system-v2';

import { CEDash_GetReachOutsCategories_viewer_v2_reachOutTemplates_categories as ReachOutCategories } from '../../queries/generatedTypes/CEDash_GetReachOutsCategories';

import Filters from './Filters';
import Library from './Library';

const StyledContainer = styled(Container)({
  overflowY: 'hidden',
  paddingTop: '32px',
  paddingBottom: '16px',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '40px',
  },
});

const CenteredText = styled(Text)({
  maxWidth: '800px',
  textAlign: 'center',
});

const ReactOutTitleText = styled(Text)({
  textAlign: 'center',
  paddingBottom: '8px',
});

export const FiltersContainer = styled(Grid)(() => ({
  marginBottom: '-8px',
  // change to 100% so it stops trying to center the long filters on a small screen size
  ['@media (max-width: 574px)']: {
    width: '100%',
  },
}));

export default function ReachOuts() {
  const [selected, setSelected] = useState<ReachOutCategories | null>(null);
  const [isActive, setIsActive] = useState(true);
  const isMediumTabletAndUp = useMediaQuery('(min-width:744px)');

  return (
    <StyledContainer maxWidth="lg">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={isMediumTabletAndUp ? 4 : 2}
      >
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <ReactOutTitleText
                variant="custom"
                default={
                  isMediumTabletAndUp
                    ? TEXT_DS.TITLE_LIGHT_32
                    : TEXT_DS.TITLE_LIGHT_24
                }
              >
                Reach Outs
              </ReactOutTitleText>
            </Grid>
            <Grid item>
              <CenteredText
                variant="custom"
                default={
                  isMediumTabletAndUp
                    ? TEXT_DS.BODY_LIGHT_20
                    : TEXT_DS.BODY_LIGHT_16
                }
              >
                Need a reason to get in touch with your contacts? Use one of the
                following reach outs to jumpstart your conversation with them.
              </CenteredText>
            </Grid>
          </Grid>
        </Grid>
        <FiltersContainer item>
          <Filters
            selected={selected}
            isActive={isActive}
            setSelected={setSelected}
            setIsActive={setIsActive}
          />
        </FiltersContainer>
        <Grid item>
          <Library
            isActive={isActive}
            isMediumTabletAndUp={isMediumTabletAndUp}
            selectedCategory={selected}
          />
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
