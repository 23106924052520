import React from 'react';
import { Grid, Text } from '@onehope/design-system-v2';
import { useCommonStyles } from '../../_shared/styles';

const ShipmentProcessedWarning = () => {
  const { formText } = useCommonStyles();

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Text variant="body1" className={formText}>
          Your current shipment has already been processed and this change will
          go into effect for future shipments.
        </Text>
      </Grid>
      <Grid item>
        <Text variant="body1" className={formText}>
          Do you want to continue to make this change?
        </Text>
      </Grid>
    </Grid>
  );
};

export default ShipmentProcessedWarning;
