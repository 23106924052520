import { gql } from '@apollo/client';
import { GetAddressFragment } from './fragments/AddressFragment';

const ADDRESSES_GET_QUERY = gql`
  query Account_AddressesGetQuery($contactId: String) {
    viewer {
      id
      user {
        id
        userId
        shippingAddressDefault {
          ...Account_GetAddress
        }
        shippingAddressIdDefault
        addressBook {
          id
          entries(first: 100) {
            edges {
              node {
                id
                address {
                  ...Account_GetAddress
                }
              }
            }
          }
        }
      }
      userInfo(contactId: $contactId) {
        id
        shippingAddressDefault {
          ...Account_GetAddress
        }
        shippingAddressIdDefault
        addressBook {
          id
          entries(first: 100) {
            edges {
              node {
                id
                address {
                  ...Account_GetAddress
                }
              }
            }
          }
        }
      }
    }
  }
  ${GetAddressFragment}
`;

export default ADDRESSES_GET_QUERY;
