import { DateTime } from 'luxon';
import { StringSchema } from 'yup';

/* These are custom yup validators that can be reused in any validation scheme
  Example Usage: 
  At the top of your validation schema file, define custom method on yup object, then call the validation rule in your schema:

      import * as yup from 'yup';
      import { mustBe21OrOlder, isValidDate } from '@onehope/validation';

      yup.addMethod(yup.string, "isValidDate", isValidDate);
      yup.addMethod(yup.string, "mustBe21OrOlder", mustBe21OrOlder);

      const validationSchema = yup.object({
        dateOfBirth: yup
          .isValidDate()
          .mustBe21OrOlder()
      });

      export default validationSchema;
*/

export const checkforValidDate = (date: string | null | undefined) => {
  if (date === undefined || date === '') {
    return true; // support empty date since date might not be required
  }

  return !!date && DateTime.fromFormat(date, 'MM/dd/yyyy').isValid;
};

export function isValidDate(this: StringSchema) {
  return this.test(
    'Valid Date',
    'Please enter a valid date',
    (date: string | null | undefined) => checkforValidDate(date),
  );
}

export const checkDateFor21OrOlder = (date: string | null | undefined) => {
  if (date === undefined || date === '') {
    return true; // support empty date since date might not be required
  }

  return (
    !!date &&
    DateTime.fromFormat(date, 'MM/dd/yyyy').diffNow('years').years < -21
  );
};

export function mustBe21OrOlder(this: StringSchema) {
  return this.test(
    'Must Be 21 Or Older',
    'You must be at least 21 years old',
    (date: string | null | undefined) => checkDateFor21OrOlder(date),
  );
}
