import * as React from 'react';
import styled from '@emotion/styled';
import { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorCircle from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { successColor, errorColor } from '../../styles/cssConstants';
import { Input, Label, StartAdornment } from './input.styles';
import MuiToolTip from '../ToolTip';

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const CheckCircleIcon = styled(CheckCircle)`
  && {
    fill: ${successColor};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const ErrorIcon = styled(ErrorCircle)`
  && {
    fill: ${errorColor};
  }
`;

type InputContainerProps = TextFieldProps & {
  validate?: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
  inputComponent?: any;
  mask?: any;
  toolTipText?: string;
  startAdornment?: string | null;
};

// interface InputContainerProps {
//   validate?: boolean;
//   hasError?: boolean;
//   hasSuccess?: boolean;
//   label?: string;
//   name?: string;
//   [key: string]: any;
// }

export default function InputContainer(props: InputContainerProps) {
  const {
    validate,
    hasError,
    hasSuccess,
    label,
    mask,
    toolTipText,
    ...rest
  } = props;
  const maskInput = mask
    ? { inputComponent: mask, value: props.value, onChange: props.onChange }
    : {};

  const getAdornment = () => {
    if (toolTipText)
      return {
        endAdornment: (
          <InputAdornment position="end">
            <MuiToolTip
              title={toolTipText}
              placement="top"
              enterDelay={100}
              leaveDelay={200}
            >
              {}
            </MuiToolTip>
          </InputAdornment>
        ),
        startAdornment: props?.startAdornment && (
          <StartAdornment position="start">
            {props?.startAdornment}
          </StartAdornment>
        ),
        ...maskInput,
      };
    if (validate)
      return {
        endAdornment: (
          <InputAdornment position="end">
            {hasError ? (
              <ErrorIcon />
            ) : (
              props.value && hasSuccess && <CheckCircleIcon />
            )}
          </InputAdornment>
        ),
        startAdornment: props?.startAdornment && (
          <StartAdornment position="start">
            {props?.startAdornment}
          </StartAdornment>
        ),
        ...maskInput,
      };
    return {
      startAdornment: props?.startAdornment && (
        <StartAdornment position="start">
          {props?.startAdornment}
        </StartAdornment>
      ),
      ...maskInput,
    };
  };

  return (
    <React.Fragment>
      {label && <Label htmlFor={props.name}>{label}</Label>}
      <Input InputProps={getAdornment()} {...rest} />
    </React.Fragment>
  );
}
