import * as React from 'react';
import Decimal from 'decimal.js';
import {
  Grid,
  Spacer,
  Text,
  TEXT_DS,
  styled,
  theme,
} from '@onehope/design-system-v2';
import { WineClub_AutoShipGetQuery_viewer_user_autoShip } from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import { WineClub_DiscountsQuery_viewer_v2_discounts } from '../../../queries/generatedTypes/WineClub_DiscountsQuery';

const LabelText = styled(Text)({
  color: theme.palette.greyDark.main,
});

const SavingsLabelText = styled(Text)({
  color: theme.palette.teal.main,
});

const SavingsAmountText = styled(Text)({
  color: theme.palette.teal.main,
  backgroundColor: theme.palette.greenTintColor.main,
  borderRadius: '4px',
  padding: '0px 4px',
});

export const getDecimalDiscounts = (
  discounts: WineClub_DiscountsQuery_viewer_v2_discounts,
) => {
  return {
    bottleDiscount4: discounts?.bottleDiscount4
      ? new Decimal(discounts?.bottleDiscount4).dividedBy(100)
      : 0,
    bottleDiscount6: discounts?.bottleDiscount6
      ? new Decimal(discounts?.bottleDiscount6).dividedBy(100)
      : 0,
    bottleDiscount12: discounts?.bottleDiscount12
      ? new Decimal(discounts?.bottleDiscount12).dividedBy(100)
      : 0,
  };
};

type WinePackSummaryProps = {
  discounts?: WineClub_DiscountsQuery_viewer_v2_discounts;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
};

export default function WinePackSummary({
  discounts,
  subscription,
}: WinePackSummaryProps) {
  const {
    taxRates,
    shippingCost,
    winePackItemsSavings,
    price,
    nextShipmentIncludesPwP,
    pwpForShipmentDate,
    pendingPwP,
  } = subscription;

  let subTotal = 0;

  const decimalDiscounts = discounts
    ? getDecimalDiscounts(discounts)
    : {
        bottleDiscount4: 0,
        bottleDiscount6: 0,
        bottleDiscount12: 0,
      };

  const winePackItemsSavingsLength = winePackItemsSavings?.edges?.length || 0;
  const discountPercentage =
    winePackItemsSavingsLength >= 12
      ? decimalDiscounts.bottleDiscount12
      : winePackItemsSavingsLength >= 6
      ? decimalDiscounts.bottleDiscount6
      : decimalDiscounts.bottleDiscount4;

  const discountedWineItemsSubTotal =
    winePackItemsSavings?.edges?.reduce((acc, entry: any) => {
      const { priceOriginal, priceSale } = entry?.node;
      const productPrice = priceSale ?? priceOriginal;
      subTotal = new Decimal(subTotal).plus(priceOriginal).toNumber();
      let discountedAmount = new Decimal(productPrice).times(
        discountPercentage,
      );
      const finalPrice = new Decimal(productPrice).minus(discountedAmount);
      return acc.plus(finalPrice);
    }, new Decimal(0)) || new Decimal(0);

  // if next shipment has a pwp AND a valid pwp exists, calculate the pwp in subtotal
  let discountedPwpItemsSubtotal = new Decimal(0);
  if (nextShipmentIncludesPwP && pwpForShipmentDate?.edges?.length) {
    discountedPwpItemsSubtotal = pwpForShipmentDate.edges.reduce(
      (acc, entry: any) => {
        const { price, finalPrice } = entry?.node;
        subTotal = new Decimal(subTotal).plus(price).toNumber();
        return acc.plus(finalPrice);
      },
      new Decimal(0),
    );
  }

  // combine discounted sub total values for wineItems and pwpItems in shipment
  const discountedSubTotal = discountedWineItemsSubTotal.plus(
    discountedPwpItemsSubtotal,
  );

  let taxRate: Decimal;
  if (taxRates?.wine) {
    taxRate = new Decimal(taxRates.wine);
  } else {
    // calculate taxRate by comparing to autoship record
    if (pendingPwP) {
      taxRate = new Decimal(price || 0)
        .dividedBy(discountedWineItemsSubTotal)
        .minus(1);
    } else {
      taxRate = new Decimal(price || 0).dividedBy(discountedSubTotal).minus(1);
    }
  }

  const taxTotal = new Decimal(discountedSubTotal.times(taxRate).toFixed(2, 4));

  const shippingTotal = parseFloat(shippingCost || '0');
  const savings = new Decimal(subTotal).minus(discountedSubTotal);
  const priceTotal = new Decimal(subTotal)
    .plus(taxTotal)
    .plus(shippingTotal)
    .minus(savings)
    .toNumber();

  return (
    <Grid container spacing={0} direction="column">
      <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
        Order Summary
      </Text>
      <Spacer xs="8px" sm="8px" lg="8px" />
      <Grid container spacing={0} direction="column">
        <Grid container xs={12} item spacing={0} justifyContent="space-between">
          <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            Subtotal
          </LabelText>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            ${subTotal.toFixed(2)}
          </Text>
        </Grid>
        <Spacer xs="8px" sm="8px" lg="8px" />
        <Grid container xs={12} item spacing={0} justifyContent="space-between">
          <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            Tax (calculated by state)
          </LabelText>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            ${taxTotal.toFixed(2)}
          </Text>
        </Grid>
        <Spacer xs="8px" sm="8px" lg="8px" />
        <Grid container xs={12} item spacing={0} justifyContent="space-between">
          <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            Shipping: ground
          </LabelText>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            ${shippingTotal.toFixed(2)}
          </Text>
        </Grid>
        <Spacer xs="12px" sm="12px" lg="12px" />
        {savings && (
          <>
            <Grid
              container
              xs={12}
              item
              spacing={0}
              justifyContent="space-between"
            >
              <SavingsLabelText
                variant="custom"
                default={TEXT_DS.BODY_SEMIBOLD_16}
              >
                Savings
              </SavingsLabelText>
              <SavingsAmountText
                variant="custom"
                default={TEXT_DS.BODY_SEMIBOLD_16}
              >
                -${savings.toFixed(2)}
              </SavingsAmountText>
            </Grid>
            <Spacer xs="12px" sm="12px" lg="12px" />
          </>
        )}
        <Grid container xs={12} item spacing={0} justifyContent="space-between">
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
            Total:
          </Text>
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
            ${priceTotal.toFixed(2)}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
}
