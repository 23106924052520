import React, { Dispatch, SetStateAction, memo, useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import {
  Chip,
  styled,
  Icons,
  Text,
  TEXT_DS,
  Menu,
  MenuItem,
  Grid,
} from '@onehope/design-system-v2';
import { REACH_OUTS_GET_CATEGORIES } from '../../../queries/ReachOutGetCategories';
import {
  CEDash_GetReachOutsCategories,
  CEDash_GetReachOutsCategories_viewer_v2_reachOutTemplates_categories as ReachOutCategories,
} from '../../../queries/generatedTypes/CEDash_GetReachOutsCategories';

const FilterContainer = styled('div')({
  display: 'flex',
  overflowX: 'scroll',
  marginBottom: '18px',
  '&::-webkit-scrollbar': {
    background: 'transparent',
    display: 'none',
  },
});

const ChipContainer = styled('div')({
  marginLeft: '4px',
});

const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    marginTop: '4px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  paddingRight: '32px',
});

const IconContainer = styled('div')({
  marginLeft: '4px',
  marginRight: '14px',
  paddingTop: '8px',
  cursor: 'pointer',
});

type Props = {
  selected: ReachOutCategories;
  isActive: boolean;
  setSelected: Dispatch<SetStateAction<ReachOutCategories>>;
  setIsActive: Dispatch<SetStateAction<boolean>>;
};

const Filters = ({ selected, setSelected, setIsActive, isActive }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  /**
   * Read the URL query string and set the selected category if it exists
   */
  const selectCategoryFilterFromQueryString = () => {
    const { category } = queryString.parse(window.location.search);
    if (category) {
      const categoryData = data?.viewer.v2.reachOutTemplates.categories.find(
        cat => cat.name === category,
      );
      if (categoryData) {
        setSelected(categoryData);
      }
    } else {
      setSelected(null);
    }
  };

  const { data } = useQuery<CEDash_GetReachOutsCategories>(
    REACH_OUTS_GET_CATEGORIES,
    {
      onCompleted: selectCategoryFilterFromQueryString,
    },
  );

  /**
   * When the URL query string changes, update the selected category
   */
  useEffect(() => {
    selectCategoryFilterFromQueryString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  /**
   * Update the URL query string when a category is selected without clobbering other params
   * @param category
   */
  const updateCategoryParam = (category: ReachOutCategories['name'] | null) => {
    const { category: _, ...otherParams } = queryString.parse(
      window.location.search,
    );

    let newParams = {};
    if (category === null) {
      newParams = {
        ...otherParams,
      };
    } else {
      newParams = {
        ...otherParams,
        category,
      };
    }

    navigate(`${window.location.pathname}?${queryString.stringify(newParams)}`);
  };

  /**
   * Update component state and URL query string when a category is selected
   * @param category
   */
  const handleSetSelected = (category: ReachOutCategories | null) => {
    if (category === null) {
      setSelected(null);
      updateCategoryParam(null);
      return;
    }
    setSelected(category);
    updateCategoryParam(category.name);
  };

  const setFilterToActive = () => {
    setAnchorEl(null);
    setIsActive(true);
  };

  const setFilterToInactive = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const categories = data?.viewer?.v2?.reachOutTemplates?.categories ?? [];

  return (
    <FilterContainer>
      <IconContainer>
        <Icons.Filter onClick={handleClick} />
      </IconContainer>
      {open && (
        <StyledMenu
          anchorEl={anchorEl}
          PaperProps={{
            style: {
              width: '152px',
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={setFilterToActive}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>{isActive && <Icons.CheckMark small={true} />}</Grid>
              <Grid item>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                  Active offers
                </Text>
              </Grid>
            </Grid>
          </StyledMenuItem>
          <StyledMenuItem onClick={setFilterToInactive}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>{!isActive && <Icons.CheckMark small={true} />}</Grid>
              <Grid item>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                  Inactive offers
                </Text>
              </Grid>
            </Grid>
          </StyledMenuItem>
        </StyledMenu>
      )}
      <Chip
        label={'all'}
        secondary={!!selected?.contentfulEntryId}
        onClick={() => handleSetSelected(null)}
      />
      {categories.length > 0 &&
        categories.map(category => (
          <ChipContainer>
            <Chip
              label={category.name}
              secondary={
                selected?.contentfulEntryId !== category.contentfulEntryId
              }
              onClick={() => handleSetSelected(category)}
            />
          </ChipContainer>
        ))}
    </FilterContainer>
  );
};

export default memo(Filters);
