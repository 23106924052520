import { gql } from '@apollo/client';

export const NONPROFIT_EDITS_QUERY = gql`
  query CauseOfChoiceNonProfitEditsQuery(
    $nonProfitId: String
    $offset: Int
    $limit: Int
  ) {
    viewer {
      id
      v2 {
        id
        nonProfitEdits(
          nonProfitId: $nonProfitId
          offset: $offset
          limit: $limit
        ) {
          edges {
            node {
              nonProfitEditId
              nonProfitName
              nonProfitAddress {
                firstName
                lastName
                city
                state
                zip
                phoneNumber
                addressLineOne
                addressLineTwo
              }
              nonProfit {
                id
                nonProfitId
                nonProfitTaxId
                nonProfitAddress {
                  firstName
                  lastName
                  city
                  state
                  zip
                  phoneNumber
                  addressLineOne
                  addressLineTwo
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NONPROFIT_EDITS_QUERY;
