import React, { useEffect, useState, RefObject } from 'react';
import { Icons } from '@onehope/design-system-v2';
import ReactHlsPlayer from 'react-hls-player';
import { isIOS, isTablet } from 'react-device-detect';

import { CardContentProps } from './CardContent';
import useStyles from './helpers/styles';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';
import VideoFullScreen from './VideoFullScreen';

interface Props extends CardContentProps {
  onVideoClickPreview: () => void;
  playing: boolean;
  onCardButtonClicked?: OnCardButtonClickedFn;
  isDesktop?: boolean;
  openDialog: boolean;
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
}

export default function Video(props: Props) {
  const classes = useStyles();

  const {
    activity,
    onVideoClickPreview,
    playing,
    onCardButtonClicked,
    isDesktop,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
  } = props;

  const [playerRef, setPlayerRef] =
    useState<RefObject<HTMLVideoElement> | null>(null);
  const initialRef = (ref: RefObject<HTMLVideoElement>) => setPlayerRef(ref);

  // if there's a video and an image on this card, use the image as the video's thumbnail.
  // if there's no image, let Mux try making a thumbnail with "smart crop"
  const thumbnail = activity.image
    ? activity.image.src
    : `https://image.mux.com/${activity.muxVideo?.playbackId}/thumbnail.jpg?fit_mode=smartcrop&width=600&height=200`;

  if (!activity.muxVideo) {
    return null;
  }

  const handleOnCardButtonClicked = () => {
    // @ts-ignore: TS is looking for property 'current' which does not exist
    if (playerRef?.webkitExitFullscreen) {
      // @ts-ignore: TS is looking for property 'current' which does not exist
      playerRef?.webkitExitFullscreen();
    }

    if (onCardButtonClicked) {
      onCardButtonClicked(activity.id);
    }
  };

  const handlePlayVideo = () => {
    // @ts-ignore: TS is looking for property 'current' which does not exist
    playerRef?.play();
  };

  useEffect(() => {
    if (!!playerRef) {
      // @ts-ignore: TS is looking for property 'current' which does not exist
      playerRef?.addEventListener('ended', handleOnCardButtonClicked);
    }
  }, [playerRef]);

  useEffect(() => {
    handlePlayVideo();
  }, [playing]);

  if (!isTablet && isIOS) {
    return (
      <ReactHlsPlayer
        src={`https://stream.mux.com/${activity.muxVideo.playbackId}.m3u8`}
        //@ts-ignore: TS is looking for property 'current' which does not exist since initialRef is not technically a refObject
        playerRef={initialRef}
        controls={true}
        className={classes.player}
        preload="auto"
        poster={thumbnail}
        onPause={handleCloseDialog}
      />
    );
  }

  return (
    <div className={classes.imageContainer}>
      {openDialog ? (
        // for androids and iOS tablets
        <VideoFullScreen
          onDialogClose={handleCloseDialog}
          playbackId={activity.muxVideo.playbackId}
          openDialog={openDialog}
        />
      ) : (
        <>
          {/* don't load player on desktop - it plays in a modal located in frontend-apps/ce-dashboard/src/components/Dashboard/Dashboard.tsx */}
          <img
            src={thumbnail}
            className={classes.desktopImage}
            style={{ objectFit: 'cover' }}
          />
          <div
            className={classes.playIcon}
            onClick={isDesktop ? onVideoClickPreview : handleOpenDialog}
            onTouchEnd={isDesktop ? onVideoClickPreview : handleOpenDialog}
          >
            <Icons.CardPlayIcon />
          </div>
        </>
      )}
    </div>
  );
}
