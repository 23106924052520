import React from 'react';
import styled from '@emotion/styled';

import {
  Dialog as DialogRoot,
  Button as MuiButton,
  Grid,
} from '@onehope/design-system-v2';

import { CEDash_EventsV2Query_viewer_user as UserType } from '../../../queries/generatedTypes/CEDash_EventsV2Query';
import EventCreateStepper from './EventCreateStepper';
import SlideTransition from './SlideTransition';
import { EventCreateSteps } from './stepperConstants';

const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    /* z-index: 9000 !important; */
  }
`;

const CreateEventButton = styled(MuiButton)`
  && {
    width: 100%;
  }
`;

type EventDetailsDialogProps = {
  createEventStep: string | string[] | null | undefined;
  handleEventCreateOpen: () => void;
  handleEventCreateClose: () => void;
  isEventCreateOpen: boolean;
  user: UserType;
};

export default function EventCreateDialog(props: EventDetailsDialogProps) {
  // @ts-ignore
  const {
    createEventStep,
    isEventCreateOpen,
    handleEventCreateOpen,
    handleEventCreateClose,
  } = props;

  const eventStep =
    typeof createEventStep === 'string' ? createEventStep : null;

  const currentStep = eventStep ? eventStep : EventCreateSteps.DETAILS;

  return (
    <Grid container>
      <Grid item xs={12}>
        <MuiButton fullWidth onClick={handleEventCreateOpen}>
          Create a new event
        </MuiButton>
      </Grid>
      <Dialog
        fullScreen
        scroll="body"
        disableEnforceFocus
        open={isEventCreateOpen}
        onClose={handleEventCreateClose}
        TransitionComponent={SlideTransition}
      >
        <EventCreateStepper
          user={props.user}
          createEventTab={currentStep}
          handelDialogClose={handleEventCreateClose}
        />
      </Dialog>
    </Grid>
  );
}
