import React from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import SlickCarousel from 'react-slick';

// Custom arrow components
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      aria-label="Next"
      role="button"
    />
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      aria-label="Previous"
      role="button"
    />
  );
};

export type SettingsType = {
  dots: boolean;
  arrows: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  initialSlide: number;
  prevArrow?: any;
  nextArrow?: any;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
const Carousel = styled(SlickCarousel)<{ props: SettingsType }>`
  && {
    display: ${(props: SettingsType) => (props?.arrows ? 'flex' : 'inherit')};
    align-items: center;

    .slick-slider {
      position: relative;

      display: block;
      box-sizing: border-box;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      -webkit-touch-callout: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      :hover {
        cursor: pointer;
      }
    }

    .slick-list {
      position: relative;

      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      *:focus {
        outline: 0;
        outline: none;
      }
    }

    .slick-list:focus {
      outline: none;
    }
    .slick-list.dragging {
      cursor: pointer;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .slick-track:before,
    .slick-track:after {
      display: table;

      content: '';
    }
    .slick-track:after {
      clear: both;
    }
    .slick-loading .slick-track {
      visibility: hidden;
    }

    .slick-slide {
      //display: none;
      float: left;

      height: 100%;
      min-height: 1px;
      //width: 288px;
      //margin: 0 12px;
    }
    [dir='rtl'] .slick-slide {
      float: right;
    }
    .slick-slide img {
      display: block;
    }
    .slick-slide.slick-loading img {
      display: none;
    }
    .slick-slide.dragging img {
      pointer-events: none;
    }
    .slick-initialized .slick-slide {
      display: block;
    }
    .slick-loading .slick-slide {
      visibility: hidden;
    }
    .slick-vertical .slick-slide {
      display: block;

      height: auto;

      border: 1px solid transparent;
    }

    /* Dots */
    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }

    .slick-dots {
      height: fit-content;
      bottom: unset;
      width: 100%;
      padding: 0 0 16px 0;
      margin: 0 auto;
      list-style: none;
      text-align: center;
      max-width: 100%;
      left: 50%;
      @media only screen and (min-width: 768px) {
        max-width: 100%;
        padding: 0;
      }
    }
    .slick-dots li {
      position: relative;

      display: inline-block;

      margin: 32px 8px 0 8px;
      padding: 0;

      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;

      display: block;

      padding: 4px;

      cursor: pointer;

      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }
    .slick-dots li button:hover,
    .slick-dots li button:focus {
      outline: none;
    }
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
      opacity: 1;
    }
    .slick-dots li button:before {
      font-size: 6px;
      line-height: 20px;

      position: absolute;
      top: 0;
      left: 0;

      width: 6px;
      height: 6px;
      content: '';

      text-align: center;

      background-color: white;
      border: 1px solid black;
      border-radius: 100%;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li.slick-active button:before,
    .slick-dots li.slick-active,
    .slick-dots li.slick-active button {
      background-color: black;
      border-radius: 100%;
    }
  }
`;

type GridProps = { rowcount: number; gridWidth?: number };

const Grid = styled.div<GridProps>`
  display: grid !important;
  grid-template-columns: ${(props: GridProps) =>
    props?.gridWidth
      ? `repeat(${props.rowcount}, ${props.gridWidth}px) !important;`
      : 'none'};
  grid-gap: 32px !important;
  justify-content: center !important;
  padding: 0 !important;
`;

function makeGrid(
  items: Array<object>,
  itemsPerRow: number = 1,
  gridWidth?: number,
) {
  const gridItems = [];
  const numberOfRows = Math.ceil(items?.length / itemsPerRow);

  for (let i = 0; i < numberOfRows; i++) {
    const offset = i * itemsPerRow;
    const rowItems = items.slice(offset, offset + itemsPerRow);
    const rowCount = Math.min(rowItems?.length, itemsPerRow);

    gridItems.push(
      <Grid key={i} rowcount={rowCount} gridWidth={gridWidth}>
        {rowItems}
      </Grid>,
    );
  }

  return gridItems;
}

export type CardCarouselProps = {
  children: any;
  slidesToShow: number;
  maxSlides: number;
  settings: SettingsType;
  gridWidth?: number;
};

export default ({
  children,
  slidesToShow,
  maxSlides,
  settings,
  gridWidth,
}: CardCarouselProps) => {
  if (!children) return null;

  // Update settings to include custom arrows
  const updatedSettings = {
    ...settings,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Carousel {...updatedSettings} id="Carousel_DS1">
      {makeGrid(
        children?.slice(0, maxSlides * slidesToShow),
        slidesToShow,
        gridWidth,
      )}
    </Carousel>
  );
};
