import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../../common/Loading';

import GET_ORDERS_BY_ACCOUNT_ID_QUERY from '../../../../../queries/OrdersByAccountId';
import { ContactType } from '../../../../ContactsV3/ContactsContext';
import OrdersPageWrapper from './OrdersWrapper';

interface OrdersPageProps {
  contact: ContactType;
}

function OrdersPage({ contact }: OrdersPageProps) {
  const { accountId = '' } = contact;
  const { data, loading, error } = useQuery(GET_ORDERS_BY_ACCOUNT_ID_QUERY, {
    fetchPolicy: 'network-only',
    skip: !accountId,
    variables: {
      byContactId: accountId,
    },
  });

  const orders = data?.viewer?.user?.orders ?? { edges: [] };
  if (error) return null;
  if (!loading && orders) {
    return <OrdersPageWrapper orders={orders} contact={contact} />;
  }
  return <Loading />;
}

export default OrdersPage;
