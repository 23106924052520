import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { mutedAccentColor },
} = styles;

export default styled.div`
  width: 100%;
  border-bottom: 1px solid ${mutedAccentColor};
  margin: 15px 0;
`;
