const ITEMS_TO_CLEAR = [
  'charityType',
  'preApprovedNonProfit',
  'manualNonProfit',
  'customNonProfit',
  'charityDonationGoal',
  'lobMemo',
  'charityQuote',
  'attentionToName',
  'displayAddress',
  'manualAddress',
  'manualCharityAddress',
  'addressLineOne',
  'addressLineTwo',
  'city',
  'state',
  'zip',
  'eventType',
  'hostType',
  'eventLocation',
  'nonProfitTaxId',
  'nonProfitName',
  'trinityPartyType',
  'hostNonProfitName',
  'hostFullName',
  'hostFirstName',
  'hostLastName',
  'hostAccountId',
  'hostEmail',
  'hostPhone',
  'eventDate',
  'charityDisplayAddress',
  'charityAddressLineOne',
  'charityAddressLineTwo',
  'charityCity',
  'charityState',
  'charityZip',
  'hostType',
  'title',
  'hostCustomerType',
  'eventTime',
  'eventTimeLocale',
  'timeZone',
  'hostKitPurchased',
  'hostKitPurchaser',
  'hostKitCredits',
  'hostKitQuantity',
  'hostKitOrderNumbers',
  'wineNeeded',
  'showSupporters',
  'showDonationGoal',
  'nonProfitId',
  'skipNonProfit',
  'eventVirtualLink',
  'useCredit',
  'nonProfitEditId',
];

export const clearLocalStorage = () => {
  ITEMS_TO_CLEAR.map(item => localStorage.removeItem(item));
};
