import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import CONTACT_V2_QUERY from '../../../../queries/ContactV2';
import { CEDash_ContactV2Query } from '../../../../queries/generatedTypes/CEDash_ContactV2Query';
import ContactV2CreateMutation from '../../../../mutations/ContactsV2/ContactsV2CreateMutation';
import { CreateNewContactV2_contactsV2Create_contact as CreateContactType } from '../../../../mutations/ContactsV2/generatedTypes/CreateNewContactV2';
import { FormTypes } from './FormTypes';
import { AddContactFormSchema } from './validationSchema';
import AddContact from './AddContact';
import FormContainer from '../../../Event/FormContainer';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../utils/segment/constants';
import { ContactType } from '../../ContactsContext';
import { ContactManagementTabs } from '../../../ContactV2/ContactDetailsPage/TabContent/tabConstants';

//#region Helpers

const getFormattedContact = async (
  contact: CreateContactType,
  contactGet: ({
    contactId,
  }: {
    contactId: string;
  }) => { data: CEDash_ContactV2Query },
) => {
  // retrieve isCe and canShopFor using the contact query
  let canShopFor = false;
  let isCe = false;
  if (contact && contact.contactId) {
    try {
      const getResponse = await contactGet({
        contactId: contact.contactId,
      });
      const contactValues = getResponse?.data?.viewer?.v2?.contactsV2?.contact;
      ({ canShopFor = false, isCe = false } = contactValues);
    } catch (error) {
      // continue
    }
  }
  return {
    accountId: contact?.accountId,
    canShopFor,
    contactId: contact?.contactId,
    dateOfBirth: contact?.dateOfBirth,
    email: contact?.emailAddress,
    emailAddress: contact?.emailAddress,
    firstName: contact?.firstName,
    isCe,
    lastName: contact?.lastName,
    ownerAccountId: contact?.ownerAccountId,
    phone: contact?.phoneNumber,
    phoneNumber: contact?.phoneNumber,
  };
};

const handleAddContact = async ({
  callback,
  contactAdd,
  contactGet,
  onClose,
  setErrorNotification,
  setExistingContact,
  trackEventV2,
  values,
}: any) => {
  const {
    dateOfBirth,
    emailAddress,
    firstName,
    lastName,
    phoneNumber,
  } = values;
  try {
    const addResponse = await contactAdd({
      variables: {
        contact: {
          firstName,
          lastName,
          dateOfBirth,
          phoneNumber: phoneNumber.replace(/[-() ]/g, ''),
          emailAddress,
          contactOrigin: 'manual',
        },
      },
    });
    const contact = addResponse?.data?.contactsV2Create?.contact;
    if (contact?.skipped) {
      const formattedContact = await getFormattedContact(contact, contactGet);
      return setExistingContact(formattedContact);
    }
    // fire off segment track for contact created
    trackEventV2({
      event: segmentEvents.contactCreated,
      properties: {
        accountId: contact?.ownerAccountId,
        contactId: contact?.contactId,
      },
    });
    if (contact?.accountId) {
      // An new contact has been created and matches an existing account
      localStorage.setItem('addedExistingAccount', 'true');
    }
    if (callback) {
      onClose();
      const formattedContact = await getFormattedContact(contact, contactGet);
      return callback(formattedContact);
    }
    return navigate(
      `/contact?contactId=${contact?.contactId}&tab=${ContactManagementTabs.DETAILS}`,
    );
  } catch (errors) {
    const errorMessage = errors?.graphQLErrors?.[0]?.message;
    const errorMessageParsed = JSON.parse(errorMessage);
    setErrorNotification(errorMessageParsed);
    return;
  }
};

//#region Add Contact Form

interface AddContactFormProps {
  defaultValues: FormTypes;
  onClose: () => void;
  callback?: () => void;
}

const AddContactForm = ({
  onClose,
  callback,
  defaultValues,
}: AddContactFormProps) => {
  const [contactAdd, { loading: contactAddMutationLoading }] = useMutation(
    ContactV2CreateMutation,
  );
  const { refetch: contactGet } = useQuery<CEDash_ContactV2Query>(
    CONTACT_V2_QUERY,
    {
      variables: { contactId: '' },
      skip: true,
    },
  );
  const { trackEventV2, trackContacts } = useTrackingContextValue();
  const [errorNotification, setErrorNotification] = useState(null);
  const [existingContact, setExistingContact] = useState<ContactType>(null);

  useEffect(() => {
    if (
      process.env.GATSBY_BUILD_ENV === 'staging' ||
      process.env.GATSBY_BUILD_ENV === 'development'
    ) {
      trackContacts({
        eventName: 'Contact Creation Started',
      });
    }
  }, []); // Runs only once because of empty dependency array

  //#region HTML

  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={defaultValues}
        validationSchema={AddContactFormSchema}
        initialTouched={{
          firstName: !!defaultValues.firstName,
          lastName: !!defaultValues.lastName,
          emailAddress: !!defaultValues.emailAddress,
          phoneNumber: !!defaultValues.phoneNumber,
          dateOfBirth: !!defaultValues.dateOfBirth,
        }}
        formToUse={(props: any) => (
          <AddContact
            {...props}
            onClose={onClose}
            mutationLoading={contactAddMutationLoading}
            errorNotification={errorNotification}
            callback={callback}
            existingContact={existingContact}
          />
        )}
        onSubmit={async (values: FormTypes, { setErrors }: any) => {
          await handleAddContact({
            values,
            contactAdd,
            contactGet,
            trackEventV2,
            setErrorNotification,
            setExistingContact,
            callback,
            onClose,
          });
        }}
      />
    </div>
  );
};

export default AddContactForm;
