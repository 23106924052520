import React from 'react';
import { Box, Grid, makeStyles, Text, Icons } from '@onehope/design-system-v2';
import { CareTeamMemberType } from './index';
import { getImageUrl } from '../../../utils/utils';

const useStyles = makeStyles(() => ({
  name: {
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '27px',
    letterSpacing: '0.01em',
    paddingLeft: 8,
    paddingRight: 32,
  },
  avatar: ({ profileImage }: { profileImage?: string }) => ({
    height: '48px',
    width: '48px',
    minHeight: '48px',
    minWidth: '48px',
    borderRadius: '50%',
    backgroundImage: `url(${profileImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
}));

type CareTeamMemberProps = {
  member: CareTeamMemberType;
};

export default function CareTeamMember(props: CareTeamMemberProps) {
  const { member } = props;
  const image = member?.profile?.image;
  const profileImage = image ? getImageUrl(image, 'thumb') : null;
  const classes = useStyles({ profileImage });

  return (
    <Box
      sx={{
        pb: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {!!image ? (
        <Grid className={classes.avatar} />
      ) : (
        <Icons.EmptyProfile width={'48px'} height={'48px'} />
      )}
      <Text variant="h2" className={classes.name}>
        {member?.fullName}
      </Text>
    </Box>
  );
}
