import React from 'react';

const SvgEvent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2H14.9333V1C14.9333 0.447715 14.4558 0 13.8667 0C13.2776 0 12.8 0.447715 12.8 1V2H6.4V1C6.4 0.447715 5.92244 0 5.33333 0C4.74423 0 4.26667 0.447715 4.26667 1V2H3.2C1.43269 2 0 3.34315 0 5V17C0 18.6569 1.43269 20 3.2 20H16C17.7673 20 19.2 18.6569 19.2 17V5C19.2 3.34315 17.7673 2 16 2ZM3.2 4H4.26667V5C4.26667 5.55228 4.74423 6 5.33333 6C5.92244 6 6.4 5.55228 6.4 5V4H12.8V5C12.8 5.55228 13.2776 6 13.8667 6C14.4558 6 14.9333 5.55228 14.9333 5V4H16C16.5891 4 17.0667 4.44772 17.0667 5V9H2.13333V5C2.13333 4.44772 2.6109 4 3.2 4ZM2.13333 17C2.13333 17.5523 2.6109 18 3.2 18H16C16.5891 18 17.0667 17.5523 17.0667 17V11H2.13333V17ZM5.33333 15C5.92244 15 6.4 14.5523 6.4 14C6.4 13.4477 5.92244 13 5.33333 13C4.74423 13 4.26667 13.4477 4.26667 14C4.26667 14.5523 4.74423 15 5.33333 15ZM9.6 13H13.8667C14.4558 13 14.9333 13.4477 14.9333 14C14.9333 14.5523 14.4558 15 13.8667 15H9.6C9.0109 15 8.53333 14.5523 8.53333 14C8.53333 13.4477 9.0109 13 9.6 13Z"
      fill="#373634"
    />
  </svg>
);

export default SvgEvent;
