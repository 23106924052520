import React, { useState, useEffect, ReactElement } from 'react';
import { Container, Icons } from '@onehope/design-system-v2';
import { ApolloClient } from '@apollo/client';

import EmptyCardState from './EmptyCardState';
import SwipeCard from './SwipeCard';
import RewardAnimation from './RewardAnimation';

import { cardArchiveClicked, cardSavedClicked } from '../utils/helpers';
import { SegmentEvents } from './constants/segmentEvents';
import SendToTrackEventV2 from '../types/SendToTrackEventV2Type';
import { getPlaybookSegmentProperties } from '../helpers/segment';
import { FeedTypes } from '../utils/constants';

// styles
import useStyles from './helpers/styles';

const handleSegmentTrackEvent = ({
  activity,
  feedType,
  event,
  sendToTrackEventV2,
}: {
  activity: ReactElement;
  feedType: FeedTypes;
  event: SegmentEvents;
  sendToTrackEventV2: SendToTrackEventV2;
}) => {
  const activityProps = getPlaybookSegmentProperties(
    activity?.props?.activity,
    feedType,
  );

  sendToTrackEventV2({
    segmentActivityProps: activityProps,
    activity: activity?.props?.activity,
    event,
  });
};

type SetStateBoolean = React.Dispatch<React.SetStateAction<boolean>>;
type SetStateNumber = React.Dispatch<React.SetStateAction<number>>;

function Swiper({
  activities,
  savedActivities,
  fetchSavedFeed,
  apolloClient,
  newTabSelected,
  sendToTrackEventV2,
  setShouldStopVideo,
  handleUpdateFeed,
  loadMoreSaved,
  setSavedOffset,
  setNewTabSelected,
  loadMoreAt,
  triggerAnimation,
  setTriggerAnimation,
  rewardPointBounty,
  setRewardBadgeAnimationAmount,
  setTriggerRewardBadgeAnimation,
  startAnimation,
  setStartAnimation,
}: {
  activities: Array<ReactElement>;
  savedActivities: Array<ReactElement>;
  fetchSavedFeed?: () => void;
  apolloClient: ApolloClient<any>;
  newTabSelected: boolean;
  setShouldStopVideo: SetStateBoolean;
  sendToTrackEventV2?: SendToTrackEventV2;
  handleUpdateFeed: () => void;
  loadMoreSaved: () => void;
  setSavedOffset: SetStateNumber;
  setNewTabSelected: SetStateBoolean;
  loadMoreAt: number;
  triggerAnimation: boolean;
  setTriggerAnimation: SetStateBoolean;
  rewardPointBounty?: number;
  setRewardBadgeAnimationAmount?: SetStateNumber;
  setTriggerRewardBadgeAnimation?: SetStateBoolean;
  setStartAnimation: SetStateBoolean;
  startAnimation: boolean;
}) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(activities.length - 1);
  const [savedCurrentIndex, setSavedCurrentIndex] = useState(
    savedActivities.length - 1,
  );
  const [swipeButtonClickedDirection, setSwipeButtonClickedDirection] =
    useState<string | null>(null);
  const [currentActivityId, setCurrentActivityId] = useState<null | string>(
    null,
  );

  // load more saved cards when cards left length === loadMoreAt
  useEffect(() => {
    if (savedCurrentIndex === loadMoreAt - 1) {
      loadMoreSaved();
    }
  }, [savedCurrentIndex]);

  useEffect(() => {
    // send segment card viewed event when switching back and forth between new and saved tabs
    const deck = newTabSelected ? activities : savedActivities;
    const i = newTabSelected ? currentIndex : savedCurrentIndex;
    const activity = deck && deck[i];
    const feedType = newTabSelected ? FeedTypes.new : FeedTypes.saved;
    const activityProps = getPlaybookSegmentProperties(
      activity?.props?.activity,
      feedType,
    );

    if (sendToTrackEventV2) {
      sendToTrackEventV2({
        segmentActivityProps: activityProps,
        activity: activity?.props?.activity,
        event: SegmentEvents.cardViewed,
      });
    }
  }, [newTabSelected]);

  useEffect(() => {
    if (savedActivities.length - 1 !== savedCurrentIndex) {
      setSavedCurrentIndex(savedActivities.length - 1);
    }
    if (activities.length - 1 !== currentIndex) {
      setCurrentIndex(activities.length - 1);
    }
  }, [savedActivities, activities]);

  const archiveCardMutation = cardArchiveClicked(apolloClient);
  const saveCardMutation = cardSavedClicked(apolloClient);
  const handleCardMutation = async (
    activity: ReactElement,
    feedType: FeedTypes,
    direction: string,
  ) => {
    const activityId = activity?.props?.activity?.id;
    const handleMutation =
      direction === 'right' ? saveCardMutation : archiveCardMutation;
    await handleMutation(activityId, true);
    handleUpdateFeed();

    if (sendToTrackEventV2) {
      const event =
        direction === 'right'
          ? SegmentEvents.cardSaved
          : SegmentEvents.cardArchived;
      handleSegmentTrackEvent({
        activity,
        feedType,
        event,
        sendToTrackEventV2,
      });
    }
  };

  const canSwipe = newTabSelected ? currentIndex >= 0 : savedCurrentIndex >= 0;

  const swiped = async (direction: string, index: number) => {
    if (canSwipe) {
      const activity = newTabSelected
        ? activities[index]
        : savedActivities[index];

      // stop the video if its swiped off screen
      const isVideoActivity = !!activity?.props?.activity?.muxVideo?.playbackId;
      if (isVideoActivity) {
        setShouldStopVideo(true);
      }

      const feedType = newTabSelected ? FeedTypes.new : FeedTypes.saved;
      // run mutation on card after its swiped
      await handleCardMutation(activity, feedType, direction);

      // update index after a card has been swiped and track next card viewed
      const updatedIndex = index - 1;
      const handleUpdateIndex = newTabSelected
        ? setCurrentIndex
        : setSavedCurrentIndex;
      handleUpdateIndex(updatedIndex);

      if (sendToTrackEventV2) {
        const activity = newTabSelected
          ? activities && activities[updatedIndex]
          : savedActivities && savedActivities[updatedIndex];
        handleSegmentTrackEvent({
          activity,
          feedType,
          event: SegmentEvents.cardViewed,
          sendToTrackEventV2,
        });
      }

      // reset setState actions
      if (isVideoActivity) {
        setShouldStopVideo(false);
      }
    }
  };

  const handleTriggerAnimation = (direction: string, index: number) => {
    const currentActivityId = newTabSelected
      ? activities[index]?.key
      : savedActivities[index]?.key;

    if (currentActivityId !== null) {
      setCurrentActivityId(currentActivityId.toString());
    }
    setSwipeButtonClickedDirection(direction);
    swiped(direction, index);
  };

  const getSwipeCard = (cardActivity: ReactElement, cardIndex: number) => {
    return (
      <SwipeCard
        cardActivity={cardActivity}
        cardIndex={cardIndex}
        handleSwiped={swiped}
        triggerButtonAnimation={swipeButtonClickedDirection}
        setSwipeButtonClickedDirection={setSwipeButtonClickedDirection}
        currentActivityId={currentActivityId}
      />
    );
  };

  const getStack = (indexOfStack: number) => {
    return (
      <>
        {indexOfStack > 0 && (
          <>
            <div
              className={
                indexOfStack === 1 ? classes.singleStack : classes.stack
              }
            ></div>
            {indexOfStack > 1 && <div className={classes.deepStack}></div>}
          </>
        )}
      </>
    );
  };

  const headerText = !newTabSelected
    ? 'No More Saved Cards!'
    : "You're All Caught Up!";
  const emptyStateText = !newTabSelected
    ? 'Refresh to view your saved cards again.'
    : "You've completed, dismissed or saved all new cards. View your saved cards now or check back later for more tips and best practices!";

  return (
    <div className={classes.container}>
      {triggerAnimation && (
        <RewardAnimation
          setRewardBadgeAnimationAmount={setRewardBadgeAnimationAmount}
          triggerAnimation={triggerAnimation}
          setTriggerAnimation={setTriggerAnimation}
          rewardPointBounty={rewardPointBounty}
          setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
          startAnimation={startAnimation}
          setStartAnimation={setStartAnimation}
        />
      )}
      <>
        {newTabSelected ? (
          <>
            {activities.length === 0 || currentIndex === -1 ? (
              <EmptyCardState
                header={headerText}
                text={emptyStateText}
                setNewTabSelected={setNewTabSelected}
              />
            ) : (
              <>
                <div
                  className={
                    currentIndex === 0
                      ? classes.singleCardWrapper
                      : classes.wrapper
                  }
                >
                  {activities.map((activity: ReactElement, index: number) => (
                    <div key={`${activity.key}${index}`}>
                      {getSwipeCard(activity, index)}
                    </div>
                  ))}
                </div>
                {getStack(currentIndex)}
                <Container className={classes.buttonContainer}>
                  <button
                    className={classes.archiveButton}
                    onClick={() => handleTriggerAnimation('left', currentIndex)}
                  >
                    <div className={classes.archiveIconContainer}>
                      <Icons.Archive />
                    </div>
                    DISMISS
                  </button>
                  <button
                    className={classes.saveButton}
                    onClick={() =>
                      handleTriggerAnimation('right', currentIndex)
                    }
                  >
                    <div className={classes.saveIconContainer}>
                      <Icons.Clock />
                    </div>
                    SAVE FOR LATER
                  </button>
                </Container>
              </>
            )}
          </>
        ) : (
          <>
            {savedActivities.length === 0 || savedCurrentIndex === -1 ? (
              <EmptyCardState
                header={headerText}
                text={emptyStateText}
                fetchSavedFeed={fetchSavedFeed}
                setSavedOffset={setSavedOffset}
              />
            ) : (
              <>
                <div
                  className={
                    savedCurrentIndex === 0
                      ? classes.singleCardWrapper
                      : classes.wrapper
                  }
                >
                  {savedActivities.map(
                    (activity: ReactElement, index: number) => (
                      <div key={`${activity.key}${index}`}>
                        {getSwipeCard(activity, index)}
                      </div>
                    ),
                  )}
                </div>
                {getStack(savedCurrentIndex)}
                <Container className={classes.buttonContainer}>
                  <button
                    className={classes.archiveButton}
                    onClick={() =>
                      handleTriggerAnimation('left', savedCurrentIndex)
                    }
                  >
                    <div className={classes.archiveIconContainer}>
                      <Icons.Archive />
                    </div>
                    DISMISS
                  </button>
                  <button
                    className={classes.saveButton}
                    onClick={() =>
                      handleTriggerAnimation('right', savedCurrentIndex)
                    }
                  >
                    <div className={classes.saveIconContainer}>
                      <Icons.Clock />
                    </div>
                    SAVE FOR LATER
                  </button>
                </Container>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default Swiper;
