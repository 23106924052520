import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { font, primaryColor } from '../../styles/cssConstants';

export const ToolTip = withStyles({
  popper: {
    opacity: '0.9',
  },
  tooltipArrow: {
    fontFamily: font,
    fontSize: '11px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'center',
    backgroundColor: primaryColor,
    maxWidth: '231px',
    wordWrap: 'break-word',
    padding: '12px 18px',
  },
  arrow: {
    fontSize: 15,
    color: primaryColor,
  },
})(Tooltip);
