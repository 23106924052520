import gql from 'graphql-tag';
import { EventFragment } from '../../queries/Event';

export default gql`
  mutation DonationV2AddMutation($input: DonationV2AddInput!) {
    donationV2Add(input: $input) {
      event {
        ...Event_event
      }
    }
  }
  ${EventFragment}
`;
