import styled from '@emotion/styled';
import Typography from 'antd/lib/typography';
import 'antd/lib/typography/style/index.css';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: {
    font,
    textColor,
    errorColor,
    warningColor,
    secondaryTextColor,
    disabledColor,
  },
} = styles;

const baseStyle = `font-family: ${font};
  font-family: ${font} !important;
  color: ${textColor} !important;
  &.ant-typography-danger {
    color: ${errorColor} !important;
  }
  &.ant-typography-secondary {
    color: ${secondaryTextColor} !important;
  }
  &.ant-typography-warning {
    color: ${warningColor} !important;
  }
  &.ant-typography-disabled {
    color: ${disabledColor} !important;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Title = styled(Typography.Title)`
  ${baseStyle};
  font-weight: bold !important;
  letter-spacing: -0.5px !important;
  line-height: 125% !important;
  margin: 12px 0 !important;
  h1&.ant-typography {
    font-size: 60px !important;
  }
  h2&.ant-typography {
    font-size: 44px !important;
    margin-bottom: 0 !important;
  }

  h3&.ant-typography {
    font-size: 32px !important;
    margin-bottom: 0 !important;
  }

  h4&.ant-typography {
    font-size: 18px !important;
    letter-spacing: 1px !important;
  }
` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Paragraph = styled(Typography.Paragraph)`
  ${baseStyle};
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: normal !important;
  margin-bottom: 0 !important;
` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Text = styled(Typography.Text)`
  ${baseStyle};
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: normal !important;
  margin-bottom: 0;
` as any;
