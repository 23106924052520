import ActivityEntry from '../types/ActivityEntry';
import { FeedTypes } from '../utils/constants';

/**
 * Translate an Action Engine card into segment event properties.
 * https://docs.google.com/spreadsheets/d/1zVANxD5ahttbKE9RTqO-P1odbe0UVYYL8wMich5DPVw/edit#gid=454614995
 * @param activity
 * @param feed
 */
export const getPlaybookSegmentProperties = (
  activity?: ActivityEntry,
  feed: FeedTypes = FeedTypes.new,
) => {
  const hasVideo = !!activity?.muxVideo;
  const cardTemplate = hasVideo ? 'video' : 'image';
  return {
    cardButton: activity?.buttonText,
    cardCategory: activity?.category,
    cardName: activity?.title,
    cardTemplate,
    contentfulEntryId: activity?.cardTemplateId,
    feed,
    streamActionId: activity?.id,
    subjectUserId: activity?.dynamicDataParams?.subjectUserId,
    rewardPointValue: activity?.rewardPointBounty ?? 0,
  };
};
