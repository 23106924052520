import { checkAutoAppendDealer } from '@onehope/utils';
import { isHostKitBuilderMainPage } from './helpers';

/**
 * In rare instances, we want to forgo this automated Page View call in
 * favor of a manual call within an app, ex. dealer auto append logic.
 *
 * Files where a manual page view call is triggered in favor of automated one:
 *
 ** MEGALITH:
 * frontend-apps/onehope-megalith/utils/navigation/queryParamHelpers.ts
 * frontend-apps/onehope-megalith/components/ProductDetailsPage/Product/index.tsx
 *
 ** HOST KIT BUILDER:
 * frontend-apps/host-kit-builder/src/common/Stepper/index.tsx
 *
 *
 ** Note: manual page view call is also called from Wine Rep Enrollment, but not included in this
 * list since it's a special edge case. In enrollment stepper, the URL doesn't change with
 * each step (so automated Page View call isn't triggered)
 *
 * File:
 * frontend-apps/ce-enrollment/src/components/MainLayout/enrollmentStepper/enrollmentStepper.tsx
 *
 */
export const excludePageView = ({
  user,
  isMegalith,
}: {
  user: any;
  isMegalith?: boolean;
}) => {
  const autoAppendDealer = checkAutoAppendDealer({ user, isMegalith });
  const isHKBMainPage = isHostKitBuilderMainPage();
  switch (true) {
    case autoAppendDealer:
      return true;
    case isHKBMainPage:
      return true;
    default:
      return false;
  }
};
