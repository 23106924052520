import React from 'react';
import {
  ButtonBase,
  Grid,
  Icons,
  Text,
  Button,
  ChunkyButton,
  Image,
} from '@onehope/design-system-v2';

import {
  CauseOfChoiceAppDictionary,
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

import { useCommonStyles } from '../../../utils/commonStyles';
import { getCurrentUser, getEventImage } from '../../../utils/helper';
import { eventSources } from '../../../utils/constants';

export default function EventShoppingSelectedScreen() {
  const commonStyles = useCommonStyles();
  const {
    handleCloseModal,
    handleGoToShop,
    handleViewFundraiser,
    urlEventImgUploads,
    user: parentUser,
    app,
  } = useCauseOfChoiceNetworkLayerValue();
  const [, dispatch] = useCauseOfChoiceContextValue();
  const user = getCurrentUser(parentUser);
  const event = user?.cartV2?.event;
  const eventTitle = event?.title || 'Fundraiser';
  const eventImage = event?.images?.[0];
  const image =
    eventImage && urlEventImgUploads
      ? getEventImage(eventImage, urlEventImgUploads)
      : null;
  const eventSource = user?.cartV2?.eventSource;

  const nonProfit =
    event?.donationInfo?.nonProfitV2 || event?.donationInfo?.nonProfit;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.HOME_SCREEN,
    });
  };

  const header = 'Your Cause of Choice';
  const subHeader =
    'ONEHOPE will donate 10% of your order to the following fundraiser:';

  const showChangeButton =
    !eventSource || eventSource === eventSources.causeSelection;
  const onChange = showChangeButton ? handleGoBack : undefined;
  const isMegalith = app === CauseOfChoiceAppDictionary.OHW_MEGALITH;
  const buttonText = isMegalith ? 'Shop' : 'Close';
  const buttonOnClick = isMegalith ? handleGoToShop : handleCloseModal;
  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Text variant="h1" className={commonStyles.headerText}>
          {header}
        </Text>
        <ButtonBase onClick={handleCloseModal}>
          <Icons.Close />
        </ButtonBase>
      </Grid>
      <Grid className={commonStyles.container}>
        <Text className={commonStyles.regularText}>{subHeader}</Text>
      </Grid>
      <ChunkyButton
        avatar={
          image ? (
            <Image
              className={commonStyles.chunkyButtonImage}
              altText="Event Image"
              src={`${image}`}
            />
          ) : (
            <Icons.COCHeartHands />
          )
        }
        onChange={onChange}
        onChangeText="Change"
        primaryText={`${eventTitle}`}
        secondaryText={`Host: ${event?.hostFullName}`}
        tertiaryText={`Nonprofit: ${nonProfit?.nonProfitName || '—'}`}
      />
      <Grid container justifyContent="center">
        <Button
          fullWidth
          type="primary"
          className={commonStyles.saveAndClose}
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
      </Grid>
      {handleViewFundraiser && (
        <Grid container justifyContent="center">
          <Button
            fullWidth
            type="tertiary"
            className={commonStyles.marginTop16}
            onClick={handleViewFundraiser}
          >
            View fundraiser page
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
