import * as React from 'react';
import Details from './Details';
import Summary from './Summary';
import GuestOrders from './GuestOrders';
// import HostRewards from './HostRewards';
import Charity from './Charity';
import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { EventManagementTabs } from '../TabNavigation/tabConstants';

interface TabContentProps {
  tabValue: string;
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
  openNewOrder?: boolean;
}

export default function TabContent(props: TabContentProps) {
  const { tabValue, event, user, openNewOrder } = props;

  switch (tabValue) {
    case EventManagementTabs.DETAILS:
      return <Details event={event} />;

    case EventManagementTabs.CHARITY:
      return <Charity event={event} />;

    // case 3:
    //   return <HostRewards event={event} user={user} />;

    case EventManagementTabs.GUEST_ORDERS:
      return <GuestOrders event={event} openNewOrder={openNewOrder} />;

    default:
      return <Summary event={event} user={user} />;
  }
}
