import React from 'react';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';

import Layout from '../../../layout';
import { getCurrentUserId } from '../../../utils/utils';
import ReachOutWrapper from '../../../components/ReachOuts/ReachOutWrapper';

const Container = styled.div`
  background-color: white;
  min-height: 100%;
`;

function ResultsPage(props: PageRendererProps) {
  const { location } = props;
  const currentUser = getCurrentUserId(location);
  return (
    <Layout>
      <Container>
        <ReachOutWrapper page="results" />
      </Container>
    </Layout>
  );
}

export default ResultsPage;
