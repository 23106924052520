import gql from 'graphql-tag';

const ContactsV2CreateMutation = gql`
  mutation CreateNewContactV2($contact: ContactV2Input!) {
    contactsV2Create(input: { contact: $contact }) {
      contact {
        accountId
        contactId
        ownerAccountId
        firstName
        lastName
        emailAddress
        phoneNumber
        dateOfBirth
        skipped
      }
    }
  }
`;

export default ContactsV2CreateMutation;
