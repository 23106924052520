import React from 'react';
import {
  ButtonBase,
  GridV2 as Grid,
  makeStyles,
  Popover,
  Text,
} from '@onehope/design-system-v2';

const useStyles = makeStyles(() => ({
  menu: { padding: 12 },
  item: {
    display: 'block',
    textAlign: 'left',
    '&:hover': {
      fontWeight: 600,
    },
  },
}));

// Applied to the nested paper component in Popover
const usePaperStyles = makeStyles(() => ({
  root: {
    boxShadow: '0px 4px 8px rgba(26, 26, 26, 0.4)',
    borderRadius: 1,
  },
}));

type ImpactMenuItem = { label: string; key: number };

export const impactMenuConfig: Array<ImpactMenuItem> = [
  { label: 'All Time', key: 0 },
  { label: 'This Month', key: 1 },
  { label: 'Last Month', key: 2 },
];

interface ImpactMenuProps {
  open: boolean;
  anchorEl: Element | null;
  handleClose: () => void;
  handleMenuClick: (key: number) => void;
}

export default function ImpactMenu({
  open,
  anchorEl,
  handleClose,
  handleMenuClick,
}: ImpactMenuProps) {
  const classes = useStyles();
  const paperClasses = usePaperStyles();

  return (
    <Popover
      id="account-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      open={open}
      onClose={handleClose}
      paperClasses={paperClasses}
      PaperProps={{
        onMouseLeave: handleClose,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        className={classes.menu}
      >
        {impactMenuConfig.map((item: ImpactMenuItem) => (
          <ButtonBase
            key={item.key}
            className={classes.item}
            onClick={() => handleMenuClick(item.key)}
          >
            <Text>{item.label} </Text>
          </ButtonBase>
        ))}
      </Grid>
    </Popover>
  );
}
