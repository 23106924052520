// @ts-ignore
import * as Yup from 'yup';

export const AddOrderFormSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  accountId: Yup.string().required('Required'),
});
