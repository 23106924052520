import React from 'react';
import styled from '@emotion/styled';
import { Text, TEXT_DS, theme, Icons } from '@onehope/design-system-v2';
const { DownloadNoBackground } = Icons;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 288px;
  gap: 8px;
`;

const ExportContactsLink = styled(Text)`
  margin-right: 10px;
  color: ${theme.palette.forestGreen.main};
  :hover {
    cursor: pointer;
  }
`;

interface ExportContactsProps {
  handleContactsCSVExport: () => void;
}

export default function ExportContacts({
  handleContactsCSVExport,
}: ExportContactsProps) {
  return (
    <Container>
      <DownloadNoBackground />
      <ExportContactsLink
        variant="custom"
        default={TEXT_DS.BODY_SEMIBOLD_14}
        onClick={handleContactsCSVExport}
      >
        Export contacts
      </ExportContactsLink>
    </Container>
  );
}
