import { gql } from '@apollo/client';

const GenerateExigoLoginURIMutation = gql`
  mutation CEDash_GenerateExigoLoginURIMutation(
    $input: GenerateExigoLoginURIInput!
  ) {
    generateExigoLoginURI(input: $input) {
      loginURI
    }
  }
`;

export default GenerateExigoLoginURIMutation;
