import styled from '@emotion/styled';

import { styles as s } from '@onehope/design-system';
import { TextField, theme } from '@onehope/design-system-v2';

type InputProps = {
  error?: boolean;
};

const { cssConstants } = s;

export const SearchInput = styled(TextField)<InputProps>`
  & .MuiInputBase-input {
    font-family: ${theme.bodyFontFamily};
    height: 48px;
    padding: 0.15rem 16px;
    font-style: italic;
    font-weight: 330;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    border-radius: 100px;
    background-color: ${theme.palette.barelyThereGrey.main};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.primary.main} !important;
    border-radius: 100px;
  }

  & .MuiInputBase-input:focus {
    background-color: ${cssConstants.inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }) =>
      error ? theme.palette.error.main : theme.palette.primary.main};
  }

  & .MuiFormHelperText-root {
    font-family: ${theme.bodyFontFamily};
    color: ${cssConstants.errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;
