import gql from 'graphql-tag';

const GET_ORDERS_V2_QUERY = gql`
  query CEDash_OrdersV2Query(
    $page: Int
    $filterBy: String
    $sortBy: String
    $desc: Boolean
    $rowsPerPage: Int
    $searchBy: String
  ) {
    viewer {
      id
      user {
        id
        customerOrdersV2Count(filterBy: $filterBy, searchBy: $searchBy)
        customerOrdersV2(
          page: $page
          filterBy: $filterBy
          sortBy: $sortBy
          desc: $desc
          rowsPerPage: $rowsPerPage
          searchBy: $searchBy
        ) {
          edges {
            node {
              id
              email
              orderId
              isAutoShipOrder
              isBYOInitialOrder
              isHostKitOrder
              orderStatus
              orderNumber
              orderTotal
              orderSubTotalAfterDiscounts
              createDate
              accountFullName
              shippingInfo {
                tracking
                carrier
                expectedDeliveryDate
              }
              trinityTransmissionStatus
              trinityOrderId
              trinityPartyId
              ceoAccountId
              user {
                id
                userId
              }
              shipments {
                shipmentInformation {
                  trackingLink
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_ORDERS_V2_QUERY;
