import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { makeStyles, Icons, Text } from '@onehope/design-system-v2';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '38px auto',
    paddingTop: '80px',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    marginTop: '0px',
    borderRadius: '12px',
    maxWidth: '342px',
    backgroundColor: '#EDE0BF',
    zIndex: 10,
    ['@media (min-width: 600px)']: {
      margin: 'auto',
    },
  },
  pointAmountText: {
    fontSize: '36px',
  },
}));

const variants = {
  hidden: {
    x: 0,
    opacity: 0,
    scale: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    rotate: 360, // rotate and x animate in the same variant
    transition: {
      duration: 1.5, // default transition duration (applies to x and opacity)
      // rotate: {
      //   // unique transition for rotate property only
      //   repeat: 1,
      //   // type: 'tween',
      //   // ease: 'linear',
      // },
    },
  },
};

const RewardAnimation = ({
  rewardPointBounty,
  setTriggerAnimation,
  triggerAnimation,
  setRewardBadgeAnimationAmount,
  setTriggerRewardBadgeAnimation,
  setStartAnimation,
  startAnimation,
}: {
  triggerAnimation: boolean;
  setTriggerAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  rewardPointBounty?: number;
  setRewardBadgeAnimationAmount?: React.Dispatch<React.SetStateAction<number>>;
  setTriggerRewardBadgeAnimation?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setStartAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  startAnimation: boolean;
}) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (triggerAnimation) {
      setIsVisible(true);
    }
  }, [triggerAnimation]);

  const onAnimationComplete = () => {
    // for some reason motion.div calls onAnimationComplete twice, use startAnimation to determine if we should actually run the handler
    if (startAnimation) {
      setStartAnimation(false);
      setTimeout(() => {
        setIsVisible(false);
        if (
          setRewardBadgeAnimationAmount &&
          rewardPointBounty &&
          setTriggerRewardBadgeAnimation
        ) {
          setRewardBadgeAnimationAmount(rewardPointBounty);
          setTriggerRewardBadgeAnimation(true);
        }
      }, 1000);
      setTimeout(() => {
        setTriggerAnimation(false);
      }, 1500);
    }
  };

  return (
    <motion.div
      className={classes.wrapper}
      variants={variants}
      animate={isVisible ? 'visible' : 'hidden'}
      initial="hidden"
      onAnimationComplete={onAnimationComplete}
    >
      <Icons.Stars />
      <Text className={classes.pointAmountText}>
        +{rewardPointBounty} POINTS
      </Text>
    </motion.div>
  );
};

export default RewardAnimation;
