import styled from '@emotion/styled';
import DropDown from 'antd/lib/dropdown';
// import {
//   defaultHoverColor,
//   disabledColor,
//   font,
//   googleColor,
//   googleHoverColor,
//   linkColor,
//   linkHoverColor,
//   primaryColor,
//   primaryHoverColor,
//   secondaryDisabledColor,
//   textColor,
// } from '../cssConstants';

// const baseStyle = `border-radius: 4px;
//   margin-bottom: 16px;
//   height: 40px;
//   font-size: 12px;
//   font-weight: bold;
//   text-transform: uppercase;
//   line-height: 1.67;
//   letter-spacing: 1px;
//   &:hover, &:focus {
//     background-color: ${primaryHoverColor};
//     border-color: ${primaryHoverColor};
//   }
//   .anticon {
//     vertical-align: middle;
//     font-size: 18px;
//     left: 16px;
//     position: absolute;
//   }
//   font-family: ${font};`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const DropDownRegular = styled(DropDown)`` as any;
