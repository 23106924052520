import React, { useState } from 'react';
import {
  styled,
  useTheme,
  useMediaQuery,
  Dialog as DialogRoot,
  DialogActions,
  DialogTitle,
  Text,
  TEXT_DS,
  Button,
} from '@onehope/design-system-v2';

import { useMutation } from '@apollo/react-hooks';
import SHOP_WITH_ACCOUNT_CHANGE_CREATE from '../../../mutations/ShopWithAccountChange/CreateShopWithAccountChangeMutation';
import { useContactsContext } from '../ContactsContext';

const Dialog = styled(DialogRoot)<{ isMobile: boolean }>`
  &.MuiDialog-root {
    padding: ${({ isMobile }) => (isMobile ? '0' : '30px 20px')};
    width: ${({ isMobile }) => (isMobile ? '288px' : '100%')};
    max-width: 760px;
    margin: 0 auto;
    z-index: 9000 !important;
  }
`;

const Content = styled('div')<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '288px' : '600px')};
`;

const DialogTitleWrapper = styled(DialogTitle)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) =>
    isMobile ? '24px 24px 8px 24px' : '48px 48px 8px 48px'};
`;

const DialogContentWrapper = styled(DialogTitle)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) =>
    isMobile ? '0px 24px 20px 24px' : '0px 48px 24px 48px'};
`;

const DialogActionsWrapper = styled(DialogActions)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) =>
    isMobile ? '0px 24px 24px 24px' : '0px 48px 48px 48px'};
`;

const PermissionRequestModal = () => {
  const theme = useTheme();
  const {
    togglePermissionState,
    loadedContact,
    permissionOpen,
  } = useContactsContext();
  const [shopWithCreate] = useMutation(SHOP_WITH_ACCOUNT_CHANGE_CREATE);
  const [success, setSuccess] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // pull contact info for our use here
  const { ownerAccountId, accountId } = loadedContact;

  const handleClose = () => {
    togglePermissionState(false);
  };

  const handleSubmitRequest = async () => {
    try {
      await shopWithCreate({
        variables: {
          customerAccountId: accountId,
          ceAccountId: ownerAccountId,
        },
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      open={permissionOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      isMobile={isMobile}
    >
      <Content isMobile={isMobile}>
        {success ? (
          <>
            <DialogTitleWrapper isMobile={isMobile}>
              <Text
                variant="custom"
                default={
                  isMobile ? TEXT_DS.TITLE_LIGHT_24 : TEXT_DS.TITLE_LIGHT_32
                }
              >
                Request Submitted
              </Text>
            </DialogTitleWrapper>
            <DialogContentWrapper isMobile={isMobile}>
              <Text
                variant="custom"
                default={
                  isMobile ? TEXT_DS.BODY_LIGHT_14 : TEXT_DS.BODY_LIGHT_16
                }
              >
                We will notify you via email once your request has been
                approved.
              </Text>
            </DialogContentWrapper>
            <DialogActionsWrapper isMobile={isMobile}>
              <Button type="primary" fullWidth onClick={handleClose}>
                Close
              </Button>
            </DialogActionsWrapper>
          </>
        ) : (
          <>
            <DialogTitleWrapper isMobile={isMobile}>
              <Text
                variant="custom"
                default={
                  isMobile ? TEXT_DS.TITLE_LIGHT_24 : TEXT_DS.TITLE_LIGHT_32
                }
              >
                Request Permission
              </Text>
            </DialogTitleWrapper>
            <DialogContentWrapper isMobile={isMobile}>
              <Text
                variant="custom"
                default={
                  isMobile ? TEXT_DS.BODY_LIGHT_14 : TEXT_DS.BODY_LIGHT_16
                }
              >
                This customer is already shopping with another Wine Rep, or has
                an existing account not linked to you. In order to shop for this
                customer and edit their account information, you must first
                request permission.
              </Text>
            </DialogContentWrapper>
            <DialogActionsWrapper isMobile={isMobile}>
              <Button type="secondary" fullWidth onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                fullWidth
                onClick={() => handleSubmitRequest()}
              >
                Request Permission
              </Button>
            </DialogActionsWrapper>
          </>
        )}
      </Content>
    </Dialog>
  );
};

export default PermissionRequestModal;
