import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../common/Loading';
import get from 'lodash/get';
import GET_GUEST_ORDERS_QUERY from '../../../../queries/GuestOrdersByEventId';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import OrdersPageWrapper from './OrdersWrapper';

interface GuestOrdersProps {
  event: EventType;
  openNewOrder?: boolean;
}

function GuestOrdersPage(props: GuestOrdersProps) {
  const { event, openNewOrder } = props;

  const { data, loading, error } = useQuery(GET_GUEST_ORDERS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      byEventId: event?.eventId,
    },
  });

  //#region HTML

  if (error) return null;

  if (get(data, 'viewer.user.orders')) {
    const { orders } = data.viewer.user;
    return (
      <OrdersPageWrapper
        orders={orders}
        event={event}
        openNewOrder={openNewOrder}
      />
    );
  }
  return <Loading />;
}

export default GuestOrdersPage;
