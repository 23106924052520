import gql from 'graphql-tag';

const mutation = gql`
  mutation EventResendOrderLinkMutation($input: EventResendOrderLinkInput!) {
    eventResendOrderLinkEmail(input: $input) {
      event {
        id
        lastOrderLinkInfo {
          id
          recipient
          dateSent
        }
      }
    }
  }
`;

export default mutation;
