import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { isNumber } from 'lodash';
import styled from '@emotion/styled';
import {
  CircularProgress,
  Icons,
  TEXT_DS,
  Text,
  theme,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';
const { CloudUpload } = Icons;

import queryString from 'query-string';

import ImportSuccessModal, {
  ImportSuccessModalProps,
} from './ImportSuccessModal';
import CONTACTS_ADD_BULK from '../../../../mutations/ContactsV2/ContactsV2BulkCreateMutation';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';
import { segmentEvents } from '../../../../utils/segment/constants';

//#region Types
interface Email {
  address: string;
  type: string;
}

interface ContactImportType {
  email: Email[];
  first_name: string;
  last_name: string;
  phone: string[];
  selectedEmail: () => string;
  selectedPhone: () => string;
}

interface FormattedContact {
  contactOrigin: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  ownerAccountId: string;
  phoneNumber: string;
}

//#region Styles

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 288px;
  gap: 8px;
`;

const Spinner = styled(CircularProgress)`
  margin-top: 0;
  margin-bottom: 0;
`;

const ImportContactsLink = styled(Text)`
  margin-right: 10px;
  color: ${theme.palette.forestGreen.main};
  :hover {
    cursor: pointer;
  }
`;

//#region Import Contact Component
interface ImportContactsProps {
  userId: string;
}

const ImportContacts = ({ userId }: ImportContactsProps) => {
  const assetUrl = process.env.GATSBY_S3_ASSETS;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [cloudspongeInitialized, setCloudspongeInitialized] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successMetrics, setSuccessMetrics] = useState<
    ImportSuccessModalProps['successMetrics']
  >({
    results: 0,
    skipped: 0,
    errors: 0,
  });

  const toggleSuccessModal = () => {
    setSuccessModalOpen(!successModalOpen);
  };

  const [
    contactsAddBulk,
    { loading: contactsAddBulkMutationLoading },
  ] = useMutation(CONTACTS_ADD_BULK);

  const { trackEventV2 } = useTrackingContextValue();

  function formatContact(contact: ContactImportType): FormattedContact | null {
    if (contact?.email) {
      return {
        ownerAccountId: userId,
        contactOrigin: 'cloudsponge',
        firstName: contact.first_name,
        lastName: contact.last_name,
        emailAddress: contact.selectedEmail(),
        phoneNumber: contact.selectedPhone().replace(/[-() ]/g, ''),
      };
    }
    return null;
  }

  // chunk up the contacts for batch import using set CHUNK_LIMIT
  const chunkContacts = (contacts: ContactImportType[]) => {
    const CHUNK_LIMIT = 200;
    const initialArray: FormattedContact[][] = [];

    const chunkedArray = contacts.reduce((resultArray, contact, index) => {
      const chunkIndex = Math.floor(index / CHUNK_LIMIT);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      // format the contact for our eventual upload
      const formattedContact = formatContact(contact);
      if (formattedContact) {
        resultArray[chunkIndex].push(formattedContact);
      }
      return resultArray;
    }, initialArray);

    return chunkedArray;
  };

  const handleUploadContacts = async (contacts: ContactImportType[]) => {
    const chunkedContacts = chunkContacts(contacts);
    let resultsCount = 0;
    let skippedCount = 0;
    let errorsCount = 0;

    try {
      // fire off contactsAddBulk in batches
      const results = await Promise.all(
        chunkedContacts.map((chunk) => {
          return contactsAddBulk({
            variables: {
              contacts: chunk,
            },
          });
        }),
      );

      // calculate totals from results objects
      results.forEach((result) => {
        const data = result?.data?.contactsV2AddBulk?.data;
        if (isNumber(data?.results?.length)) {
          resultsCount += data.results.length;
        }

        if (isNumber(data?.skipped?.length)) {
          skippedCount += data.skipped.length;
        }

        if (isNumber(data?.errors?.length)) {
          errorsCount += data.errors.length;
        }
      });

      // set success metrics in state for modal to use
      setSuccessMetrics({
        results: resultsCount,
        skipped: skippedCount,
        errors: errorsCount,
      });
      // fire off segment tracker for import complete
      trackEventV2({
        event: segmentEvents.contactImportComplete,
        properties: {
          numContacts: resultsCount,
          accountId: userId,
          source: '',
        },
      });
      // open the success modal with loaded metrics
      toggleSuccessModal();
    } catch (error) {
      console.log(error);
      return;
    }
  };

  // initialize Cloudsponge client
  useEffect(() => {
    return cloudsponge.init({
      css: isMobile
        ? `${assetUrl}/cloudSpongeMobile.css`
        : `${assetUrl}/cloudSponge.css`,
      sources: [
        'gmail',
        'icloud',
        'yahoo',
        'aol',
        'addressbook',
        'windowslive',
        'outlook',
        'office365',
        'csv',
      ],
      include: ['mailing_address', 'name', 'email'],
      filter: function(contact: ContactImportType) {
        return !!contact.first_name.length;
      },
      afterInit: () => {
        setCloudspongeInitialized(true);
      },
      afterClosing: () => {
        if (!window) {
          return;
        }

        // remove showImport query param from URL
        const params = queryString.parse(window.location.search);
        const { showImport, ...rest } = params;

        let urlQuery = '';
        if (Object.keys(rest).length) {
          urlQuery = `?${queryString.stringify(rest)}`;
        }
        const newUrl = [
          window.location.origin,
          window.location.pathname,
          urlQuery,
        ].join('');

        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', newUrl);
      },
      afterImport: function(source: string, success: boolean) {
        // use this for failed import logging
        if (!success) {
          alert(
            'There was a problem importing your contacts from ' + source + '.',
          );
        }
      },
      afterSubmitContacts: (contacts: unknown) =>
        handleUploadContacts(contacts as ContactImportType[]),
    });
  }, []); // Runs only once because of empty dependency array

  // auto-open Cloudsponge import modal if requested by URL param
  useEffect(() => {
    if (!cloudspongeInitialized) {
      return;
    }

    if (!window) {
      return;
    }

    const params = queryString.parse(window.location.search);
    if (Object.keys(params).includes('showImport')) {
      cloudsponge.launch();
    }
  }, [cloudspongeInitialized]);

  const openWidget = () => {
    return cloudsponge.launch();
  };

  //#region HTML

  if (contactsAddBulkMutationLoading)
    return (
      <Container>
        <Spinner />
      </Container>
    );

  return (
    <Container>
      <ImportSuccessModal
        open={successModalOpen}
        toggleImportSuccessDialog={toggleSuccessModal}
        successMetrics={successMetrics}
      />
      <CloudUpload />
      <ImportContactsLink
        variant="custom"
        default={TEXT_DS.BODY_SEMIBOLD_14}
        onClick={openWidget}
      >
        Import contacts
      </ImportContactsLink>
    </Container>
  );
};

export default ImportContacts;
