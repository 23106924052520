import React from 'react';
import {
  makeStyles,
  Text,
  Container,
  ThemeType,
  Icons,
  Button,
} from '@onehope/design-system-v2';

const useStyles = makeStyles((theme: ThemeType) => ({
  dialogText: {
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px',
    maxWidth: '500px',
    margin: 'auto',
  },
  dialogHeader: {
    fontSize: '32px',
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '10px',
  },
  refreshContainer: {
    textAlign: 'center',
    width: '140px',
    cursor: 'pointer',
  },
  refreshText: {
    color: theme.palette.mud.main,
    fontSize: '18px',
  },
  refreshIcon: {
    marginRight: '10px',
    float: 'left',
  },
  button: {
    position: 'absolute',
    borderRadius: '12px',
    width: '320px',
    backgroundColor: theme.palette.forestGreen.main,
    '&:hover': {
      backgroundColor: theme.palette.forestGreen.main,
      opacity: 0.9,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type EmptyCardStateProps = {
  header: string;
  text: string;
  fetchSavedFeed?: () => void;
  setSavedOffset?: React.Dispatch<React.SetStateAction<number>>;
  setNewTabSelected?: React.Dispatch<React.SetStateAction<boolean>>;
};

function EmptyCardState(props: EmptyCardStateProps) {
  const classes = useStyles();
  const { header, text, fetchSavedFeed, setSavedOffset, setNewTabSelected } =
    props;

  const handleFetchSavedFeed = () => {
    if (fetchSavedFeed && setSavedOffset) {
      setSavedOffset(0);
      fetchSavedFeed();
    }
  };

  const hanleOpenSavedTab = () => {
    if (setNewTabSelected) {
      setNewTabSelected(false);
    }
  };

  return (
    <Container>
      <Text className={classes.dialogHeader}>{header}</Text>
      <Text className={classes.dialogText}>{text}</Text>
      {fetchSavedFeed ? (
        <Container
          onClick={handleFetchSavedFeed}
          className={classes.refreshContainer}
        >
          <Icons.RefreshLeftArrow className={classes.refreshIcon} />
          <Text className={classes.refreshText}>Refresh</Text>
        </Container>
      ) : (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={hanleOpenSavedTab}>
            VIEW SAVED CARDS
          </Button>
        </div>
      )}
    </Container>
  );
}

export default EmptyCardState;
