import React from 'react';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';

import Layout from '../layout';
import LiveChat from '../components/LiveChat';

const Container = styled.div`
  background-color: white;
  min-height: 100%;
`;

function LiveChatPage(props: PageRendererProps) {
  return (
    <Layout>
      <Container>
        <LiveChat />
      </Container>
    </Layout>
  );
}

export default LiveChatPage;
