import { Tabs } from '@onehope/design-system-v2';
import { styles as s } from '@onehope/design-system';
import styled from '@emotion/styled';

const { cssConstants } = s;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  padding: 0 16px 0 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: 768px) {
    padding: 0 40px 0 40px;
  }
`;

export const TabsContainer = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  & .MuiTabs-indicator {
    background-color: ${cssConstants.textColor};
  }
  & button {
    text-transform: unset;
    font-family: ${cssConstants.bodyFontFamily};
    font-size: 14px;
    font-weight: 420;
    color: ${cssConstants.neutral34};
    padding: 10px;
    opacity: 1;
    min-width: 80px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
  & button.Mui-selected {
    color: ${cssConstants.textColor};
    font-weight: 420;
  }
`;
