import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import {
  Grid,
  makeStyles,
  Text,
  TEXT_DS,
  ThemeType,
  Button,
  DialogV2,
  DialogV2Props,
  theme,
} from '@onehope/design-system-v2';

import ShipmentProcessedWarning from '../ShipmentWarnings/ShipmentProcessedWarning';
import InputLabel from '../InputLabel';

import {
  BottleQuantity,
  formatWinePackItems,
  getCuratedPackNameByPackSize,
  getPackSizeByCuratedPackName,
  getWinePackItemsForBottleQuantityChange,
} from '../../../utils/wineClub/helpers';
import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../../utils/segment';

import AUTO_SHIP_CHANGE_WINES_MUTATION from '../../../mutations/AutoshipChangeWinesMutation';
import AUTOSHIP_UPDATE_MUTATION from '../../../mutations/AutoshipUpdateMutation';
import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';
import {
  WineClub_AutoShipUpdateMutation,
  WineClub_AutoShipUpdateMutationVariables,
} from '../../../mutations/generatedTypes/WineClub_AutoShipUpdateMutation';
import {
  WineClub_AutoShipChangeWinesMutation,
  WineClub_AutoShipChangeWinesMutationVariables,
} from '../../../mutations/generatedTypes/WineClub_AutoShipChangeWinesMutation';

const buttonStyles = {
  height: '60px',
  color: theme.palette.primary.main,
  transition: 'unset',
  '&:disabled': {
    border: 'unset',
  },
  '&:hover': {
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px rgba(26, 26, 26, 0.16)',
    border: `1px solid`,
    borderRadius: '52px',
  },
};

const getButtonStyles = (
  isSelected: boolean,
  theme: ThemeType,
  wineClub923?: boolean,
) => {
  return isSelected
    ? {
        ...buttonStyles,
        width: wineClub923 ? '180px' : '100px',
        ['@media (max-width: 412px)']: {
          width: wineClub923 ? '150px' : '100px',
        },
        backgroundColor: theme.palette.neutral0.main,
        boxShadow: '0px 8px 16px rgba(26, 26, 26, 0.16)',
        border: `1px solid`,
        borderRadius: '52px',
        '&& span': {
          fontSize: '16px',
          fontWeight: 700,
        },
      }
    : {
        ...buttonStyles,
        width: wineClub923 ? '180px' : '100px',
        ['@media (max-width: 412px)']: {
          width: wineClub923 ? '150px' : '100px',
        },
        border: 'unset',
        backgroundColor: 'unset',
        boxShadow: 'unset',
        borderRadius: 'unset',
        '&& span': {
          fontSize: '16px',
          fontWeight: 400,
        },
      };
};

const useStyles = makeStyles<
  ThemeType,
  { selectedQuantity: BottleQuantity; wineClub923?: boolean }
>((theme: ThemeType) => ({
  button4: ({ selectedQuantity, wineClub923 }: any) =>
    getButtonStyles(selectedQuantity === 4, theme, wineClub923), // TODO: remove after wineClub923
  button6: ({ selectedQuantity, wineClub923 }: any) =>
    getButtonStyles(selectedQuantity === 6, theme, wineClub923),
  button12: ({ selectedQuantity, wineClub923 }: any) =>
    getButtonStyles(selectedQuantity === 12, theme, wineClub923),
  container: {
    paddingBottom: theme.spacing(3),
  },
  buttonContainer: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.lightYellowBeige.main,
    borderRadius: theme.spacing(8),
  },
  titleClass: {
    paddingBottom: theme.spacing(1),
  },
}));

interface BottleQuantitySelectorProps {
  user: WineClub_AutoShipGetQuery_viewer_user;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
  wineClub923?: boolean;
}

const BottleQuantitySelector = (props: BottleQuantitySelectorProps) => {
  const { user, subscription, conciergeMode, sendToTrackEventV2, wineClub923 } =
    props;
  const { autoShipId, winePackItemsSavings, curatedPackName } = subscription;

  const shipmentProcessing =
    subscription?.latestInvoice?.invoiceStatus === 'pending';

  const winePackItemsInSubscription = winePackItemsSavings?.edges?.filter(
    (item) => item?.node?.discountEligible,
  );

  const winePackItemsNotInSubscription = winePackItemsSavings?.edges?.filter(
    (item) => !item?.node?.discountEligible,
  );

  const getCurrentQuantity = (): BottleQuantity => {
    const winePackItemsInSubscriptionLength =
      winePackItemsInSubscription?.length || 0;
    if ([4, 6, 12].includes(winePackItemsInSubscriptionLength)) {
      // TODO: remove 4 after wineClub923
      return winePackItemsInSubscriptionLength as BottleQuantity;
    }

    if (curatedPackName && curatedPackName !== 'none') {
      return getPackSizeByCuratedPackName(curatedPackName);
    }

    return 0;
  };

  const [selectedQuantity, setSelectedQuantity] = useState<BottleQuantity>(
    getCurrentQuantity(),
  );

  type ModalShowControls = {
    show: boolean;
    nextQuantityPending: 4 | 6 | 12; // TODO: remove 4 after wineClub923
  };

  const [showShipmentProcessingWarning, setShowShipmentProcessingWarning] =
    useState<ModalShowControls>({
      show: false,
      nextQuantityPending: 6,
    });

  const [showConfirmModal, setShowConfirmModal] = useState<ModalShowControls>({
    show: false,
    nextQuantityPending: 6,
  });

  const { container, button4, button6, button12, buttonContainer, titleClass } =
    useStyles({
      selectedQuantity,
      wineClub923,
    });

  const [updateMutationLoading, setUpdateMutationLoading] = useState(false);
  const [EditAutoShipMutation] = useMutation<
    WineClub_AutoShipUpdateMutation,
    WineClub_AutoShipUpdateMutationVariables
  >(AUTOSHIP_UPDATE_MUTATION);
  const [AutoShipChangeWinesMutation] = useMutation<
    WineClub_AutoShipChangeWinesMutation,
    WineClub_AutoShipChangeWinesMutationVariables
  >(AUTO_SHIP_CHANGE_WINES_MUTATION);

  const onWarningClose = () => {
    setShowConfirmModal({
      show: false,
      nextQuantityPending: 6,
    });
    setShowShipmentProcessingWarning({
      show: false,
      nextQuantityPending: 6,
    });
  };

  const handleChangeBottleQuantity = useCallback(
    (bottleQuantity: 4 | 6 | 12) => {
      // TODO: remove 4 after wineClub923
      const currentBottleQuantity = getCurrentQuantity();

      if (currentBottleQuantity === bottleQuantity) {
        return;
      }

      if (!showShipmentProcessingWarning.show && !showConfirmModal.show) {
        if (shipmentProcessing) {
          return setShowShipmentProcessingWarning({
            show: true,
            nextQuantityPending: bottleQuantity,
          });
        }

        return setShowConfirmModal({
          show: true,
          nextQuantityPending: bottleQuantity,
        });
      }

      if (showShipmentProcessingWarning.show && !showConfirmModal.show) {
        setShowShipmentProcessingWarning({
          show: false,
          nextQuantityPending: bottleQuantity,
        });

        return setShowConfirmModal({
          show: true,
          nextQuantityPending: bottleQuantity,
        });
      }

      // Optimistically set new selected quantity for UI feedback
      setSelectedQuantity(bottleQuantity);

      const newWinePackItems = getWinePackItemsForBottleQuantityChange(
        // @ts-ignore
        winePackItemsInSubscription,
        winePackItemsNotInSubscription,
        bottleQuantity,
      );

      const formattedNewWinePackItems = formatWinePackItems(newWinePackItems);

      setUpdateMutationLoading(true);

      // if this is a curated wine pack: update the curatedPackName instead
      // which represents the curated quantity when the picks are ready
      if (curatedPackName && curatedPackName !== 'none') {
        const editAutoShipVariables = {
          autoShipId,
          input: {
            autoShip: {
              autoShipId,
              curatedPackName: getCuratedPackNameByPackSize(bottleQuantity),
            },
          },
        };

        return EditAutoShipMutation({
          variables: editAutoShipVariables,
        })
          .then(() => {
            // send to segment
            sendToTrackEventV2 &&
              sendToTrackEventV2({
                event: SubscriptionSegmentEvents.wineClubModified,
                properties: {
                  concierge: conciergeMode,
                  autoShipId,
                  accountId: user?.userId,
                  ceAccountId: conciergeMode ? user?.userId : '',
                  propertyUpdated: SubscriptionSegmentProperty.bottleCount,
                  values: {
                    previousValue: subscription.winePackCount,
                    newValue: bottleQuantity,
                  },
                },
              });
            onWarningClose();
            setUpdateMutationLoading(false);
          })
          .catch((error) => {
            console.log('error', error);

            // rollback optimistic UI selection
            setSelectedQuantity(
              ([0, 4, 6, 12].includes(currentBottleQuantity) // TODO: remove 4 after wineClub923
                ? currentBottleQuantity
                : 0) as BottleQuantity,
            );
            setUpdateMutationLoading(false);
          });
      } else {
        const changeWinesVariables = {
          autoShipId,
          input: {
            autoShipId,
            winePackItems: formattedNewWinePackItems,
            eventType: 'EVENT_WINE_QUANTITY_CHANGED',
          },
        };

        return AutoShipChangeWinesMutation({
          variables: changeWinesVariables,
        })
          .then(() => {
            // send to segment
            sendToTrackEventV2 &&
              sendToTrackEventV2({
                event: SubscriptionSegmentEvents.wineClubModified,
                properties: {
                  concierge: conciergeMode,
                  autoShipId,
                  accountId: user?.userId,
                  ceAccountId: conciergeMode ? user?.userId : '',
                  propertyUpdated: SubscriptionSegmentProperty.bottleCount,
                  values: {
                    previousValue: subscription.winePackCount,
                    newValue: formattedNewWinePackItems.length,
                  },
                },
              });
            onWarningClose();
          })
          .catch((error) => {
            console.log('error', error);

            // rollback optimistic UI selection
            setSelectedQuantity(
              ([0, 4, 6, 12].includes(currentBottleQuantity) // TODO: remove 4 after wineClub923
                ? currentBottleQuantity
                : 0) as BottleQuantity,
            );
          })
          .finally(() => {
            setUpdateMutationLoading(false);
          });
      }
    },
    [
      winePackItemsInSubscription,
      showShipmentProcessingWarning.show,
      showConfirmModal.show,
      winePackItemsNotInSubscription,
      autoShipId,
      AutoShipChangeWinesMutation,
      shipmentProcessing,
      updateMutationLoading,
    ],
  );

  const selectFour = () => handleChangeBottleQuantity(4); // TODO: remove after wineClub923
  const selectSix = () => handleChangeBottleQuantity(6);
  const selectTwelve = () => handleChangeBottleQuantity(12);
  const selectNextQuantityPending = () =>
    handleChangeBottleQuantity(
      showShipmentProcessingWarning.show
        ? showShipmentProcessingWarning.nextQuantityPending
        : showConfirmModal.nextQuantityPending,
    );

  const { title, saveButtonLabel, closeButtonLabel }: Partial<DialogV2Props> =
    useMemo(() => {
      if (showShipmentProcessingWarning.show) {
        return {
          title: 'Heads Up!',
          saveButtonLabel: 'Yes, Continue',
          closeButtonLabel: 'No, Never Mind',
        };
      }

      return {
        title: 'Confirm Change Quantity',
        saveButtonLabel: 'Confirm Change',
        closeButtonLabel: 'Keep as is',
      };
    }, [showShipmentProcessingWarning]);

  return (
    <>
      <Grid container className={container}>
        <Grid item className={titleClass}>
          <InputLabel>Quantity</InputLabel>
        </Grid>
        <Grid container item className={buttonContainer}>
          {!wineClub923 && (
            <Grid item>
              <Button
                disabled={updateMutationLoading}
                variant="outlined"
                onClick={selectFour}
                className={button4}
              >
                4
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              disabled={updateMutationLoading}
              onClick={selectSix}
              className={button6}
            >
              6
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={updateMutationLoading}
              onClick={selectTwelve}
              className={button12}
            >
              12
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {(showShipmentProcessingWarning.show || showConfirmModal.show) && (
        <DialogV2
          open={showShipmentProcessingWarning.show || showConfirmModal.show}
          saveButtonLabel={saveButtonLabel}
          onClose={onWarningClose}
          closeButtonLabel={closeButtonLabel}
          title={title}
          onSave={selectNextQuantityPending}
          onSaveLoading={updateMutationLoading}
          buttonDirection="column"
        >
          {showShipmentProcessingWarning.show ? (
            <ShipmentProcessedWarning />
          ) : (
            <Text variant="custom" default={TEXT_DS.BODY_LIGHT_18}>
              If you change your quantity, we&#39;ll automatically add/remove
              wines from your next shipment to reflect the new amount. Please
              use the swap feature to make sure we have the correct wines for
              your next shipment.
            </Text>
          )}
        </DialogV2>
      )}
    </>
  );
};

export default BottleQuantitySelector;
