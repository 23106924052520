import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Icons,
  Text,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';
import { useLazyQuery, ApolloClient } from '@apollo/client';

import FEED_QUERY from '../queries/feedQuery';
import CARD_QUERY from '../queries/cardQuery';

import CardDialogItems from './CardDialogItems';
import EmptyCardState from './EmptyCardState';
import CardContent from './CardContent';
import CardsLoading from '../CardsLoading';

import ActivityEntry from '../types/ActivityEntry';
import { FeedTypes } from '../utils/constants';
import { cardArchiveClicked } from '../utils/helpers';
import { ExternalConfigContextType } from '../types/ExternalConfigContext';
import ExternalConfigContext from '../types/ExternalConfigContext';
import SendToTrackEventV2 from '../types/SendToTrackEventV2Type';
import { SegmentEvents } from './constants/segmentEvents';
import { getPlaybookSegmentProperties } from '../helpers/segment';
import { getDisplayTime } from './helpers';
import { useStyles } from './helpers/cardDialogStyles';

type CardDialogProps = {
  openCardDialog: boolean;
  handleSetClose?: () => void;
  apolloClient: ApolloClient<any>;
  feedType: FeedTypes;
  sendToTrackEventV2?: SendToTrackEventV2;
  goToCardId?: string;
  externalConfig: ExternalConfigContextType;
};

function CardDialog(props: CardDialogProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    handleSetClose,
    openCardDialog,
    apolloClient,
    feedType,
    sendToTrackEventV2,
    goToCardId,
    externalConfig,
  } = props;

  const [feed, setFeed] = useState([] as ActivityEntry[]);
  const [selectedActivity, setSelectedActivity] = useState(
    null as ActivityEntry | null,
  );
  const [displayDate, setDisplayDate] = useState('');
  const [showSuccessToaster, setShowSuccessToaster] = useState(false);
  const [pointsEarned, setPointsEarned] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 30;

  useEffect(() => {
    if (openCardDialog && isMobile) {
      window.scrollTo(0, 70);
    }
  }, [openCardDialog, isMobile]);

  const [fetchFeed, { loading, refetch }] = useLazyQuery(FEED_QUERY, {
    client: apolloClient,
    variables: {
      feedType,
      offset,
      limit,
    },
    onCompleted: (data) => {
      const fetchedData = [...data?.viewer?.v2?.actionEngineFeed];
      if (
        fetchedData.length < 1 ||
        (feed.length < 1 && fetchedData.length < 30)
      ) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      const updatedFeed = [...feed, ...fetchedData];
      setFeed(updatedFeed);
    },
  });

  const [fetchCard, {}] = useLazyQuery(CARD_QUERY, {
    client: apolloClient,
    variables: {
      ledgerId: goToCardId,
    },
    onCompleted: (data) => {
      const card = data?.viewer?.v2?.actionEngineCard;
      if (card) {
        setSelectedActivity(card);
        setPointsEarned(card?.rewardPointBounty || 0);
      }
    },
  });

  useEffect(() => {
    fetchFeed();
  }, []); // Runs only once because of empty dependency array

  useEffect(() => {
    if (goToCardId) {
      fetchCard();
    }
  }, [goToCardId]);

  const onLoadMore = () => {
    refetch &&
      refetch({
        limit,
        offset: offset + limit,
      });

    setOffset(offset + limit);
  };

  const handleSetActivity = (activity: ActivityEntry) => {
    const { rewardPointBounty, rewardPointRedemptionMode } = activity;
    const showPointsEarned =
      rewardPointRedemptionMode === 'uponCompletion' && rewardPointBounty > 0;
    if (showPointsEarned) {
      setPointsEarned(rewardPointBounty);
    }
    setSelectedActivity(activity);
    setShowSuccessToaster(false);

    if (sendToTrackEventV2) {
      const activityProps = getPlaybookSegmentProperties(activity, feedType);

      sendToTrackEventV2({
        segmentActivityProps: activityProps,
        activity: activity,
        event: SegmentEvents.cardViewed,
      });
    }
  };

  const handleClearSetActivity = () => {
    setSelectedActivity(null);
  };

  const handleCloseToaster = () => {
    setShowSuccessToaster(false);
  };

  useEffect(() => {
    const displayDateTime =
      feedType === FeedTypes.completed
        ? getDisplayTime(selectedActivity?.dateSucceeded)
        : getDisplayTime(selectedActivity?.dateArchived);
    setDisplayDate(displayDateTime);
  }, [selectedActivity]);

  const archiveCardMutation = cardArchiveClicked(apolloClient);
  const restoreClicked = async () => {
    if (selectedActivity) {
      const result = await archiveCardMutation(selectedActivity.id, false);
      const index = feed.indexOf(selectedActivity);
      const updatedFeed = feed;
      if (index > -1) {
        updatedFeed.splice(index, 1);
        setFeed(updatedFeed);
      }
      if (result?.data?.actionEngineArchiveCard?.status === 'done') {
        handleClearSetActivity();
        setShowSuccessToaster(true);
        if (isMobile) {
          window.scrollTo(0, 70);
        } else {
          window.scroll(0, 0);
        }
        setTimeout(() => {
          setShowSuccessToaster(false);
        }, 2000);
      }
      if (sendToTrackEventV2) {
        const activityProps = getPlaybookSegmentProperties(
          selectedActivity,
          FeedTypes.archived,
        );
        sendToTrackEventV2({
          activity: selectedActivity,
          segmentActivityProps: activityProps,
          event: SegmentEvents.cardRestored,
        });
      }
    }
  };

  return (
    <ExternalConfigContext.Provider value={externalConfig}>
      <div className={classes.container}>
        {showSuccessToaster && (
          <div className={classes.successToastContainer}>
            <div className={classes.successToast}>
              <Icons.BlueSuccess className={classes.blueSuccessIcon} />
              <Icons.BlueClose
                className={classes.blueCloseIcon}
                onClick={handleCloseToaster}
                onTouchEnd={handleCloseToaster}
              />
              Success: Card restored
            </div>
          </div>
        )}
        {!!selectedActivity && (
          <div className={classes.dialogTitleDetails}>
            <div
              onClick={handleClearSetActivity}
              className={classes.backContainerSelected}
            >
              <Icons.BackArrow className={classes.backArrow} />
              {feedType === FeedTypes.completed ? (
                <Text> Completed Cards </Text>
              ) : (
                <Text>Dismissed Cards</Text>
              )}
            </div>
          </div>
        )}

        {!selectedActivity && (
          <>
            <div className={classes.dialogTitleList}>
              <div className={classes.centerText}>
                {feedType === FeedTypes.completed ? (
                  <Text> Completed Cards </Text>
                ) : (
                  <Text>Dismissed Cards</Text>
                )}
              </div>
            </div>
            <div className={classes.backContainer} onClick={handleSetClose}>
              <Icons.BackArrow className={classes.backArrow} />
              <Text>Back</Text>
            </div>
          </>
        )}
        {feed?.length ? (
          <>
            {!!selectedActivity ? (
              <>
                <CardContent
                  activity={selectedActivity}
                  isCardDialogItems={true}
                  isCardDetailsPage={true}
                />
                {feedType === FeedTypes.archived ? (
                  <>
                    <Text className={classes.dismissedText}>
                      Dismissed {displayDate}
                    </Text>
                    <div>
                      <div
                        className={classes.restoreButton}
                        onClick={restoreClicked}
                      >
                        <div className={classes.restoreIconContainer}>
                          <Icons.Restore />
                        </div>
                        <Text className={classes.restoreText}>RESTORE</Text>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Text className={classes.dismissedText}>
                      Completed {displayDate}
                    </Text>
                    {!!pointsEarned && (
                      <div className={classes.pointsCompletedContainer}>
                        <Icons.StarMedium />
                        <Text
                          className={classes.pointsCompletedText}
                        >{`${pointsEarned} points earned`}</Text>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <InfiniteScroll
                dataLength={feed.length}
                next={onLoadMore}
                hasMore={hasMore}
                loader={
                  <div className={classes.loader}>
                    <Icons.Loader />
                  </div>
                }
              >
                <CardDialogItems
                  activities={feed}
                  handleSetActivity={handleSetActivity}
                />
              </InfiniteScroll>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <CardsLoading />
            ) : (
              <EmptyCardState
                header="No Cards Available"
                text={`Any cards you have ${
                  feedType === FeedTypes.completed ? 'completed' : 'dismissed'
                } will be made available here.`}
              />
            )}
          </>
        )}
      </div>
    </ExternalConfigContext.Provider>
  );
}

export default CardDialog;
