import React, { useState } from 'react';
import { GridV2 as Grid } from '@onehope/design-system-v2';

import ImpactStats from './ImpactStats';
import { CEDash_DashboardQuery_viewer_user_ce_ceImpactData as CeImpactData } from '../../../queries/generatedTypes/CEDash_DashboardQuery';

import ImpactChoice from './ImpactChoice';
import ImpactHeader from './ImpactHeader';
import CauseOfChoiceModalWrapper from '../../../common/CauseOfChoiceModalWrapper';
import useToggle from '../../../utils/useToggleHook';

import { CEDash_DashboardQuery_viewer_user } from '../../../queries/generatedTypes/CEDash_DashboardQuery';

interface CauseImpactProps {
  user: CEDash_DashboardQuery_viewer_user;
  ceImpactData: CeImpactData;
}

export default function CauseImpact({ ceImpactData, user }: CauseImpactProps) {
  const {
    value: causeOfChoiceModalOpen,
    setTrue: handleOpenModal,
    setFalse: handleCloseModal,
  } = useToggle();

  const [menuKey, setMenuKey] = useState(0);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (key: number) => {
    handleClose();
    setMenuKey(key);
  };

  const cause = user?.selectedCause?.nonProfit?.nonProfitName;

  return (
    <Grid>
      <CauseOfChoiceModalWrapper
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        causeOfChoiceModalOpen={causeOfChoiceModalOpen}
      />
      <ImpactHeader cause={cause} open={open} handleClick={handleOpenModal} />
      <ImpactChoice
        cause={cause}
        menuKey={menuKey}
        handleMenuClick={handleMenuClick}
        handleOpenModal={handleOpenModal}
      />
      <ImpactStats menuKey={menuKey} ceImpactData={ceImpactData} />
    </Grid>
  );
}
