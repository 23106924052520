import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';
import { Link, navigate } from 'gatsby';
import {
  Text,
  TEXT_DS,
  ChunkyButton,
  Icons,
  CircularProgress,
  Grid,
  styled,
  theme,
  DialogV2,
  useMediaQuery,
  ButtonStyle,
} from '@onehope/design-system-v2';

import REACH_OUT_V2 from '../../../queries/ReachOutV2';
import {
  MainHeaderText,
  DescriptionText,
  TinyText,
  BodyText,
  LinkText,
  BlueButtonLink,
} from '../StyledComponents/Text';
import {
  ReachOutContainer,
  StyledImage,
  IconsContainer,
  HelpIcon,
} from '../StyledComponents/ReachOut';
import BreadCrumb from '../BreadCrumb';
import SocialSharing from '../SocialSharing';
import {
  getMediumLabel,
  getMediumUrlMethod,
  getMediumIcon,
} from '../ReachOut/ShareMedium';
import {
  CEDash_ReachOutV2,
  CEDash_ReachOutV2Variables,
} from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import { ShareMedium } from '../../../../generatedTypes/globalTypes';

import ReachOutPreviews from './ReachOutPreviews';

const Expiration = styled(props => <div {...props} />)(
  ({ isExpired }: { isExpired: boolean }) => ({
    borderRadius: '100px',
    backgroundColor: isExpired
      ? theme.palette.greyDark.main
      : theme.palette.goldStar.main,
    padding: '4px 10px',
    display: 'flex',
    '& svg': {
      '& path': {
        fill: 'white',
      },
      marginRight: '5px',
      marginTop: '4px',
      width: '13px',
      height: '13px',
    },
  }),
);

const StyledText = styled(Text)({
  color: 'white !important',
});

const ReachOut = ({ reachOutId }: { reachOutId: string }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [previewReachoutOpen, setPreviewReachoutOpen] = useState(false);

  const onPreviewReachout = () => {
    setPreviewReachoutOpen(true);
  };

  const onClosePreviewReachout = () => {
    setPreviewReachoutOpen(false);
  };

  const { data, loading, error } = useQuery<
    CEDash_ReachOutV2,
    CEDash_ReachOutV2Variables
  >(REACH_OUT_V2, {
    variables: {
      id: reachOutId,
    },
  });

  const { isReachOutExpired, expDateTimeText } = useMemo(() => {
    if (
      !data?.viewer?.v2?.reachOutTemplate ||
      !data.viewer.v2.reachOutTemplate.expirationDate
    ) {
      return { isReachOutExpired: false, expDateTimeText: null };
    }

    const expDate = DateTime.fromISO(
      data.viewer.v2.reachOutTemplate.expirationDate,
    );
    const now = DateTime.now();

    const expDateTimeText = expDate
      ? expDate
          .setLocale('en-US')
          .setZone('US/Central')
          .toFormat("MM/dd @ h a 'CT'")
      : '';

    const reachoutExpired = expDate && expDate < now;
    if (reachoutExpired) {
      return {
        isReachOutExpired: true,
        expDateTimeText: expDateTimeText,
      };
    }

    return { isReachOutExpired: false, expDateTimeText: expDateTimeText };
  }, [data?.viewer?.v2?.reachOutTemplate]);

  const handleViewResults = (reachOutId: string) => {
    navigate(`/reach-outs/results?reachoutId=${reachOutId}`);
  };

  if (loading) {
    return (
      <ReachOutContainer>
        <CircularProgress />
      </ReachOutContainer>
    );
  }

  if (error || (data && !data.viewer?.v2?.reachOutTemplate)) {
    console.error(error);
    return (
      <>
        <BreadCrumb reachOutId={reachOutId} page={'Reach out missing'} />
        <ReachOutContainer>
          <BodyText text="Sorry, we couldn't find that reach out. Please select another from the library." />

          <ChunkyButton
            primaryText="All reach outs"
            fullWidth
            hideImage
            onClick={() => navigate('/reach-outs')}
          />

          {process.env.NODE_ENV === 'development' && (
            <>
              <br />
              <br />
              <p>
                Dev hint: Did you copy a URL or <code>ReachOutRequest</code>{' '}
                from another environment? The <code>reachoutId</code> needs to
                exist in Contentful in order to work here.
              </p>
            </>
          )}
        </ReachOutContainer>
      </>
    );
  }

  const reachOut = data.viewer.v2.reachOutTemplate;

  return (
    <>
      <BreadCrumb reachOutId={reachOutId} page={reachOut?.name} />
      <ReachOutContainer>
        <Grid container spacing={2}>
          <Grid item style={{ width: '100%' }}>
            <StyledImage src={`${reachOut?.coverImageUrl}`} width="744" />
          </Grid>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={null}>
              <MainHeaderText
                leftAlign
                paddingTop="20px"
                text={reachOut?.name}
              />
            </Grid>
            {reachOut?.helpCenterArticleUrl && (
              <Grid item>
                <HelpIcon
                  onClick={() =>
                    window.open(reachOut?.helpCenterArticleUrl, '_blank')
                  }
                />
              </Grid>
            )}
          </Grid>
          {expDateTimeText && (
            <Grid item>
              <div
                style={{
                  marginTop: '-8px',
                }}
              >
                <Expiration isExpired={isReachOutExpired}>
                  <Icons.ClockSolid />
                  <StyledText
                    variant="custom"
                    default={TEXT_DS.BODY_SEMIBOLD_14}
                  >
                    {`End${
                      isReachOutExpired ? 'ed' : 's'
                    } on ${expDateTimeText}`}
                  </StyledText>
                </Expiration>
              </div>
            </Grid>
          )}

          {isReachOutExpired ? (
            <Grid container item>
              <DescriptionText
                text={
                  'Unfortunately, this reach out is no longer active. View your results below.'
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item>
                <Text
                  style={{
                    marginBottom: '8px',
                  }}
                  variant="custom"
                  tablet={TEXT_DS.BODY_LIGHT_18}
                  laptop={TEXT_DS.BODY_LIGHT_18}
                  desktop={TEXT_DS.BODY_LIGHT_18}
                  mobile={TEXT_DS.BODY_LIGHT_16}
                >
                  {reachOut?.description}
                </Text>
              </Grid>
              {reachOut.shareConfigurations?.length > 0 && (
                <>
                  <Grid container direction="column" item>
                    <Grid item>
                      <BlueButtonLink
                        sx={{ marginBottom: '32px', paddingBottom: '6px' }}
                        type={ButtonStyle.SMALL_LINK}
                        onClick={onPreviewReachout}
                      >
                        <LinkText variant="button">PREVIEW REACH OUT</LinkText>
                      </BlueButtonLink>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
                      Share Reach Out
                    </Text>
                    <IconsContainer>
                      {/* Render SMS and email buttons; sort order is determined by the backend */}
                      {reachOut.shareConfigurations
                        .filter(({ medium }) =>
                          [
                            ShareMedium.EMAIL,
                            ShareMedium.TEXT_MESSAGE,
                          ].includes(medium),
                        )
                        .map(({ medium }) => (
                          <Link
                            to={`/reach-outs/invites?reachoutId=${reachOutId}&method=${getMediumUrlMethod(
                              medium,
                            )}`}
                          >
                            {getMediumIcon(medium)}
                            <TinyText text={`${getMediumLabel(medium)}`} />
                          </Link>
                        ))}
                      <SocialSharing
                        ceAccountId={data.viewer.user.userId}
                        reachOut={reachOut}
                      />
                    </IconsContainer>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        <ChunkyButton
          primaryText="View results"
          fullWidth
          hideImage
          rightIcon={<Icons.ArrowRightBlackThick />}
          onClick={() => handleViewResults(reachOut.reachOutId)}
        />
      </ReachOutContainer>
      {previewReachoutOpen && (
        <DialogV2
          open={previewReachoutOpen}
          title="Preview Reach Out"
          onClose={onClosePreviewReachout}
          hideBottomCloseButton={true}
          fullScreen={!isDesktop}
          maxWidth={'lg'}
        >
          <ReachOutPreviews reachOut={reachOut} />
        </DialogV2>
      )}
    </>
  );
};

export default ReachOut;
