import { Icons } from '@onehope/design-system-v2';

import { ShareMedium } from '../../../../generatedTypes/globalTypes';
import { TinyText } from '../StyledComponents/Text';
import { IconWrapper } from './sharedComponents';

type Props = {
  handleClick: (medium: ShareMedium) => void;
  medium: ShareMedium;
};

export function SocialShareButton({ handleClick, medium }: Props) {
  const clickHandler = () => handleClick(medium);

  switch (medium) {
    default:
      return null;

    case ShareMedium.FACEBOOK_FEED_POST:
      return (
        <IconWrapper>
          <Icons.FacebookLogo onClick={clickHandler} />
          <TinyText text="Facebook" marginTop="-3px" />
        </IconWrapper>
      );

    case ShareMedium.LINKED_IN_POST:
      return (
        <IconWrapper>
          <Icons.LinkedInLogo onClick={clickHandler} />
          <TinyText text="LinkedIn" marginTop="-3px" />
        </IconWrapper>
      );

    case ShareMedium.MANUAL_LINK:
      return (
        <IconWrapper>
          <Icons.ReachOutsCopyButton onClick={clickHandler} />
          <TinyText text="Copy link" marginTop="-3px" />
        </IconWrapper>
      );
  }
}
