import {
  Container,
  Grid,
  Spacer,
  Dialog,
  Icons,
  styled,
  Text,
  TEXT_DS,
  ButtonV2,
  useMediaQuery,
  theme,
} from '@onehope/design-system-v2';

const { Close } = Icons;

const DialogContainer = styled(Container)(
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: isMobile ? '24px' : '48px',
    width: isMobile ? '100%' : '600px',
  }),
);

const CloseIcon = styled(Close)({
  cursor: 'pointer',
});

interface SubscriptionWarningDialogProps {
  openDialog: boolean;
  closeDialog: () => void;
  dialogContent: string;
}

const SubscriptionWarningDialog = (props: SubscriptionWarningDialogProps) => {
  const { openDialog, closeDialog, dialogContent } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog open={openDialog} onClose={closeDialog} fullScreen={isMobile}>
      <DialogContainer disableGutters isMobile={isMobile}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_32}>
            Oops!
          </Text>
          <CloseIcon onClick={closeDialog} />
        </Grid>
        <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
        <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
          {dialogContent}
        </Text>
        <Spacer xl="24px" lg="24px" sm="24px" xs="24px" />
        <ButtonV2 fullWidth onClick={closeDialog}>
          Close
        </ButtonV2>
      </DialogContainer>
    </Dialog>
  );
};

export default SubscriptionWarningDialog;
