import React, { useState, useContext, createContext, ReactNode } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { checkforValidDate, checkDateFor21OrOlder } from '@onehope/validation';
import ShopWithCustomerAddV2 from '../../mutations/Account/ShopWithCustomerAddV2';
import { useTrackingContextValue } from '../../contexts/TrackingContext';
import { segmentEvents } from '../../utils/segment/constants';

// modal imports
import ArchiveContactsModal from './ArchiveContactsModal';
import PermissionRequestModal from './ContactModals/PermissionRequest';
import AccountInfoModal from './ContactModals/AccountInfo';
import IsCeWarningModal from './ContactModals/IsCeWarning';
import {
  CauseOfChoiceLocationKeys,
  CauseOfChoiceTypeKeys,
} from '../../common/CauseOfChoiceModalWrapper';

export interface ContactType {
  ownerAccountId: string;
  contactId: string;
  accountId: string;
  createDate: string;
  canShopFor: boolean;
  emailAddress: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  pointBalance: number;
  lifetimeSpend: number;
  organizationName: string;
  isSubscriber: boolean;
  isHost: boolean;
  isCe: boolean;
  lastOrder: string;
  dateOfBirth: string;
  nextShipmentDate: string;
  shoppingWithCeoAccountId: string;
  shippingAddressIdDefault: string;
  shippingAddress: {
    addressId: string;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    country: string;
    state: string;
    zip: string;
  };
  pointsExpireAt: string;
  selectedCause: any;
  totalCauseDonationAmount: number;
  autoShipPaymentErrors?: string[];
}

interface ContactsContextProviderProps {
  selectedContacts: string[];
  allSelected: boolean;
  isEditing: boolean;
  isTableView: boolean;
  toggleIsEditing: () => void;
  toggleSelectedContact: (contact: string) => void;
  toggleAllSelected: () => void;
  toggleModalState: (state: boolean) => void;
  togglePermissionState: (state: boolean) => void;
  toggleAccountInfoState: (state: boolean) => void;
  toggleCEWarningState: (state: boolean) => void;
  toggleTableView: () => void;
  clearAllSelected: () => void;
  loadedContact: ContactType;
  loadContact: (contact: Partial<ContactType>) => void;
  permissionOpen: boolean;
  accountInfoOpen: boolean;
  ceWarningOpen: boolean;
  handleOrderAdd: (
    email: string,
    eventId?: string,
    redirectLink?: string,
  ) => Promise<void>;
  handleOrderPermissions: (
    contact: ContactType,
    eventId?: string,
    redirectLink?: string,
  ) => void;
  hasPermissionToEdit: (contact: ContactType) => boolean | null;
}

export const ContactsContext = createContext<ContactsContextProviderProps>({
  selectedContacts: [],
  isEditing: false,
  isTableView: true,
  allSelected: false,
  toggleIsEditing: () => null,
  toggleSelectedContact: () => null,
  toggleAllSelected: () => null,
  toggleModalState: () => null,
  togglePermissionState: () => null,
  toggleAccountInfoState: () => null,
  toggleCEWarningState: () => null,
  toggleTableView: () => null,
  clearAllSelected: () => null,
  loadedContact: {},
  loadContact: () => null,
  handleOrderAdd: () => null,
  permissionOpen: false,
  accountInfoOpen: false,
  ceWarningOpen: false,
  handleOrderPermissions: () => null,
  hasPermissionToEdit: () => null,
});

export const useContactsContext = () =>
  useContext<ContactsContextProviderProps>(ContactsContext);

export const ContactsContextProvider = ({
  children,
  contacts,
}: {
  children: ReactNode;
  contacts: ContactType[];
}) => {
  // state variables and functions
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [permissionOpen, setPermissionOpen] = useState<boolean>(false);
  const [accountInfoOpen, setAccountInfoOpen] = useState<boolean>(false);
  const [ceWarningOpen, setCEWarningOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isTableView, setIsTableView] = useState<boolean>(true);
  const [loadedContact, setLoadedContact] = useState<ContactType>({});

  // mutations
  const [shopWithCustomerAddV2] = useMutation(ShopWithCustomerAddV2);

  // tracking events
  const { trackEventV2 } = useTrackingContextValue();

  //
  // CONTACTS SELECTION
  //

  // toggle all contacts either selected or unselected
  const toggleAllSelected = () => {
    const allContactIds = contacts.map(
      (contact: ContactType) => contact?.contactId,
    );
    if (allSelected) {
      setSelectedContacts([]);
      setAllSelected(false);
    } else {
      setSelectedContacts(allContactIds);
      setAllSelected(true);
    }
  };

  // given a passed contactId, add or remove it from selectedContacts
  const toggleSelectedContact = (contactId: string) => {
    let newlySelectedContacts: string[];
    if (selectedContacts.includes(contactId)) {
      newlySelectedContacts = selectedContacts;
      newlySelectedContacts.splice(selectedContacts.indexOf(contactId), 1);
      setAllSelected(false);
    } else {
      newlySelectedContacts = [...selectedContacts, contactId];
      if (newlySelectedContacts.length === contacts.length)
        setAllSelected(true);
    }
    setSelectedContacts([...newlySelectedContacts]);
  };

  // clear all selected contacts, close modal, stop editing
  const clearAllSelected = () => {
    setSelectedContacts([]);
    setAllSelected(false);
    setModalOpen(false);
    setIsEditing(false);
  };

  // toggle isEditing state to be its inverse
  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  //
  // CONTACTS LOADING FOR MANIPULATION
  //
  const loadContact = (contact: ContactType) => {
    setLoadedContact(contact);
  };

  //
  // MODAL STATE HANDLERS
  //

  // toggle modal open or closed
  const toggleModalState = (state: boolean) => {
    setModalOpen(state);
  };

  // handle canceling archive contact dialog
  const handleArchiveDialogClose = () => {
    clearAllSelected();
    toggleModalState(false);
  };

  // toggle request permission to shop modal
  const togglePermissionState = (state: boolean) => {
    setPermissionOpen(state);
  };

  // toggle account info / creation modal
  const toggleAccountInfoState = (state: boolean) => {
    setAccountInfoOpen(state);
  };

  // toggle ce warning modal open state
  const toggleCEWarningState = (state: boolean) => {
    setCEWarningOpen(state);
  };

  //
  // CONCIERGE MODE / ACCOUNT HANDLERS
  //

  // function to obtain concierge mode data,
  // fire segment tracker, and move window location
  const handleOrderAdd = async (
    email: string,
    eventId?: string,
    redirectLink?: string,
  ) => {
    try {
      const res = await shopWithCustomerAddV2({
        variables: { customerEmail: email, eventId },
      });
      const conciergeService = res?.data?.shopWithCustomerAddV2;
      if (!conciergeService) return;

      // segment tracking
      const isNewCustomer = conciergeService.newCustomer?.ordersCount === 0;
      const customerId = conciergeService.user?.conciergeCustomerAccountId;
      trackEventV2({
        event: segmentEvents.conciergeModeOrderStarted,
        properties: {
          eventId: eventId || null,
          shoppingForId: customerId,
          isNewCustomer,
        },
      });

      if (eventId) {
        // customer cause of choice updated
        trackEventV2({
          event: segmentEvents.causeOfChoiceUpdated,
          properties: {
            selectedCause:
              res?.data?.shopWithCustomerAddV2?.user?.conciergeUser
                ?.selectedCause,
            causeType: CauseOfChoiceTypeKeys.FUNDRAISER,
            updateLocation: CauseOfChoiceLocationKeys.CE_DASHBOARD,
            isConcierge: true,
          },
        });
      }
      window.location.href =
        redirectLink || `${process.env.GATSBY_MEGALITH_URL}/shop`;
    } catch (error) {
      const { message } = error;
      if (message) {
        if (
          error.message.includes(
            'Sorry, this user is a Wine Rep and cannot be added as your customer',
          )
        ) {
          toggleCEWarningState(true);
        }
        if (
          error.message.includes(
            'This account requires permission to shop with the given Wine Rep',
          ) ||
          error.message.includes(
            'Customer is already shopping with a different Wine Rep',
          )
        ) {
          togglePermissionState(true);
        }
      }
      console.log(error);
    }
  };

  // function to determine if user has permissions to
  // edit a user's information. If they have an attached
  // account and are not shopping with the given CE,
  // we need to deny the request and have them request
  // additional permissions first.
  const hasPermissionToEdit = (contact: ContactType) => {
    const { accountId, canShopFor } = contact;
    if (accountId && !canShopFor) {
      return false;
    }
    return true;
  };

  // function to handle if user is permitted to shop for contact
  // and modal, account creation, concierge mode handling depending
  const handleOrderPermissions = (
    contact: ContactType,
    eventId?: string,
    redirectLink?: string,
  ) => {
    const {
      accountId,
      dateOfBirth,
      lastName,
      emailAddress,
      canShopFor,
      isCe,
    } = contact;
    if (!accountId) {
      // if missing required info, load and show AccountInfoModal
      if (
        !dateOfBirth ||
        !checkforValidDate(dateOfBirth) ||
        !checkDateFor21OrOlder(dateOfBirth) ||
        !lastName ||
        !emailAddress
      ) {
        loadContact(contact);
        toggleAccountInfoState(true);
      } else {
        // otherwise, let's go into concierge mode
        handleOrderAdd(emailAddress, eventId, redirectLink);
      }
    } else if (isCe) {
      toggleCEWarningState(true);
    } else if (canShopFor) {
      handleOrderAdd(emailAddress, eventId, redirectLink);
    } else {
      loadContact(contact);
      togglePermissionState(true);
    }
  };

  //
  // Table View
  //

  // toggle table view state
  const toggleTableView = () => {
    setIsTableView(!isTableView);
  };

  return (
    <ContactsContext.Provider
      value={{
        selectedContacts,
        allSelected,
        isEditing,
        isTableView,
        toggleIsEditing,
        toggleSelectedContact,
        toggleAllSelected,
        toggleModalState,
        togglePermissionState,
        toggleAccountInfoState,
        toggleCEWarningState,
        toggleTableView,
        handleOrderAdd,
        clearAllSelected,
        loadedContact,
        loadContact,
        permissionOpen,
        accountInfoOpen,
        ceWarningOpen,
        handleOrderPermissions,
        hasPermissionToEdit,
      }}
    >
      <ArchiveContactsModal
        contacts={selectedContacts}
        isArchiveDialogOpen={modalOpen}
        setIsArchiveDialogClose={handleArchiveDialogClose}
      />
      <PermissionRequestModal />
      <AccountInfoModal />
      <IsCeWarningModal />
      {children}
    </ContactsContext.Provider>
  );
};
