import * as React from 'react';
import { Table, theme, styled } from '@onehope/design-system-v2';

import { CEDash_EventsQuery_viewer_user_events_edges as EventsType } from '../../../../queries/generatedTypes/CEDash_EventsQuery';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const TableWrapper = styled('div')`
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

interface EventsListViewProps {
  events: (EventsType | null)[] | null;
  page: number;
  contactId?: string | null | undefined;
  rowsPerPage: number;
  handleSortEvents: (sortBy: string) => void;
  setSelectedEventId: (eventId: string | null | undefined) => void;
  handleOrderAdd: (eventId: string | null | undefined) => void;
  toggleEventCopyUrlDialog: (eventId: string | null | undefined) => void;
}

export default function EventsListViewTable(props: EventsListViewProps) {
  const {
    handleSortEvents,
    setSelectedEventId,
    handleOrderAdd,
    events,
    page,
    rowsPerPage,
    contactId,
    toggleEventCopyUrlDialog,
  } = props;
  return (
    <TableWrapper>
      <Table stickyHeader aria-label="events table">
        <TableHeader handleSortEvents={handleSortEvents} />
        <TableBody
          page={page}
          events={events}
          contactId={contactId}
          rowsPerPage={rowsPerPage}
          handleOrderAdd={handleOrderAdd}
          setSelectedEventId={setSelectedEventId}
          toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
        />
      </Table>
    </TableWrapper>
  );
}
