import React from 'react';
import styled from '@emotion/styled';

import {
  Loader,
  useTheme,
  withStyles,
  useMediaQuery,
  Dialog as DialogRoot,
  DialogActions,
  DialogTitle,
} from '@onehope/design-system-v2';

import { styles as s } from '@onehope/design-system';
import { MuiButton } from '@onehope/design-system-ohw';

const { cssConstants } = s;

interface AlertDialogProps {
  open: boolean;
  dialogText?: string;
  dialogContent?: string;
  setClose: (input: boolean) => void;
  saveChange: (input: string) => void;
  mutationLoading: boolean;
}

const Dialog = styled(DialogRoot)`
  &.MuiDialog-root {
    padding: 30px 20px;
    max-width: 560px;
    margin: 0 auto;
    z-index: 9000 !important;
    @media only screen and (max-width: 600px) {
      padding: 0;
      width: 288px;
    }
  }
`;

const Content = styled.div`
  width: 400px;
  @media only screen and (max-width: 600px) {
    width: 288px;
  }
`;

const DialogTitleWrapper = styled(DialogTitle)`
  .MuiTypography-h6 {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 0px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
    }
  }
`;

const DialogContentWrapper = styled(DialogTitle)`
  .MuiTypography-h6 {
    text-align: center;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 15px;
    padding-top: 0px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

export default function AlertDialog({
  open,
  setClose,
  saveChange,
  dialogText,
  dialogContent,
  mutationLoading,
}: AlertDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const GlobalCss = withStyles({
    '@global': {
      '.MuiDialog-paper': {
        margin: '0px',
      },
      '.MuiDialogActions-root': {
        padding: '20px 32px 40px 32px;',
      },
      '.MuiButton-label': {
        fontSize: isMobile ? '12px' : '10px',
      },
    },
  })(() => null);

  const handleClose = () => {
    setClose(false);
  };

  return (
    <React.Fragment>
      <GlobalCss />
      <Dialog
        open={open}
        onClose={!mutationLoading ? handleClose : () => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Content>
          <DialogTitleWrapper>
            {dialogText || `Are you sure you want to archive this contact?`}
          </DialogTitleWrapper>
          <DialogContentWrapper>
            {dialogContent ||
              `This action will unlink you as the contact's Wine Rep.`}
          </DialogContentWrapper>
          <DialogActions>
            <MuiButton
              type="SECONDARY"
              disabled={mutationLoading}
              fullWidth
              onClick={handleClose}
            >
              CANCEL
            </MuiButton>
            <MuiButton
              type="PRIMARY"
              fullWidth
              disabled={mutationLoading}
              onClick={saveChange}
              color="primary"
              autoFocus
            >
              {mutationLoading && <Loader style={{ marginRight: 6 }} />}
              YES, DELETE
            </MuiButton>
          </DialogActions>
        </Content>
      </Dialog>
    </React.Fragment>
  );
}
