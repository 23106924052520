import gql from 'graphql-tag';
import { HostRewardsFragment } from './HostRewards';
import { CauseOfChoiceNonProfit } from './NonProfitV2';

export const EventFragment = gql`
  fragment Event_event on Event {
    id
    accountId
    createDate
    eventType
    status
    eventId
    eventDate
    ceoFirstName
    ceoEmail
    ceoFullName
    eventVirtualLink
    hostLastName
    hostType
    hostFirstName
    hostFullName
    hostEmail
    hostPhone
    hostAccountId
    hostNonProfitName
    ordersCount
    trinityPartyId
    trinityDealerId
    exigoPartyId
    trinityPartyType
    trinityPartyStatus
    trinityHostRewardsOrderId
    hostRewardsOrderIds
    hostRewardsQualifiedSubTotal
    hostKitOrderNumbers
    hostKitQuantity
    hostKitCredits
    starterKitCredits
    hostKitPurchaser
    isPurchasingTastingFlights
    wineTastingOrderIds
    hostKitOrderLinkId
    version
    lastOrderLinkInfo {
      id
      recipient
      dateSent
    }
    timeZone
    hostQuote
    hostUser {
      id
      role
      userId
      firstName
      lastName
      fullName
      organizationName
      mobilePhone
      email
    }
    eventAddress {
      addressLineOne
      addressLineTwo
      city
      state
      zip
      phone
    }
    closeDate
    manualCloseDate
    donationId
    canUpdateEventDates
    donationInfo {
      id
      ordersSubTotal
      donationAmount
      donationGoal
      hostKitDonation
      orderDonationAmount
      status
      lobMemo
      nonProfit {
        id
        nonProfitName
        nonProfitTaxId
        nonProfitId
        nonProfitDescription
        nonProfitPayeeName
        isPreApproved
        nonProfitAddress {
          optionalName
          addressLineOne
          addressLineTwo
          city
          state
          zip
          phoneNumber
        }
      }
      nonProfitV2 {
        ...CauseOfChoiceNonProfit
      }
      nonProfitEdit {
        id
        nonProfitEditId
      }
    }
    parentEventId
    groupFundraiserType
    groupFundraiserSlug
    title
    displayPreferences {
      id
      donationGoal
      supporters
      eventLocation
    }
    images {
      id
      imageId
      groupName
      fileName
    }
  }
  ${CauseOfChoiceNonProfit}
`;

const EVENT_DETAILS_QUERY = gql`
  query CEDash_EventDetailsPage($eventId: String) {
    viewer {
      id
      user {
        id
        userId
        email
        trinityDealerId
        permissions {
          id
          oneCommerce {
            admin {
              access
            }
          }
        }
        numberOfAvailableCredits
        availableStarterKitCredits
      }
      event(eventId: $eventId) {
        ...Event_event
        ...HostRewards_event
      }
    }
  }
  ${EventFragment}
  ${HostRewardsFragment}
`;

export default EVENT_DETAILS_QUERY;
