import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Container,
  Grid,
  Spacer,
  Text,
  TEXT_DS,
  ButtonV2,
  theme,
  styled,
} from '@onehope/design-system-v2';

import SubscriptionSummary from './SubscriptionSummary';
import SubscriptionDialog from './SubscriptionDialog';
import SubscriptionWarningDialog from './SubscriptionWarningDialog';

import { CEDash_AutoshipsGetQuery_viewer_user_autoShips as SubscriptionType } from '../../../../../queries/generatedTypes/CEDash_AutoshipsGetQuery';
import { CEDash_ContactV2Query_viewer_v2_contactsV2_contact as ContactType } from '../../../../../queries/generatedTypes/CEDash_ContactV2Query';

import { useContactsContext } from '../../../../ContactsV3/ContactsContext';

import useToggle from '../../../../../utils/useToggleHook';

const Button = styled(ButtonV2)({
  maxWidth: '288px',
  width: '100%',
  '&&': {
    background: theme.palette.forestGreen.main,
  },
});

interface SubscriptionWrapperProps {
  autoShipId: string;
  setAutoShipId: Dispatch<SetStateAction<string>>;
  contact: ContactType;
  subscriptions: SubscriptionType[];
}

function SubscriptionsWrapper({
  autoShipId,
  setAutoShipId,
  contact,
  subscriptions,
}: SubscriptionWrapperProps) {
  const { contactId } = contact;
  const { handleOrderPermissions } = useContactsContext();

  const [warningDialogContent, setWarningDialogContent] = useState('');
  const {
    value: warningDialogOpen,
    setTrue: setWarningDialogOpen,
    setFalse: setWarningDialogClose,
  } = useToggle();

  const openSubscriptionDialog = (
    autoShipId: string,
    isEditable: boolean,
    isContactCe: boolean,
    subscriptionCe: string | null,
  ) => {
    if (isEditable) {
      setWarningDialogContent('');
      setAutoShipId(autoShipId);
    } else {
      let content = '';
      if (isContactCe) {
        // We cannot update another Wine Rep's subscription
        content = 'You cannot manage another CE’s subscription.';
      } else if (subscriptionCe) {
        // We cannot update a subscription owned by another CE
        content = `Looks like ${subscriptionCe} is the owner of this subscription. Only the owner is able to manage this subscription`;
      } else {
        // We cannot update a subscription that does not have a CE
        content = `Only CEs who have sold the subscription have access to edit this subscription.`;
      }
      setWarningDialogContent(content);
      setWarningDialogOpen();
    }
  };

  const closeSubscriptionDialog = () => {
    setAutoShipId('');
  };

  const shopForContact = () => {
    const wineClubBuilderRedirectLink = `${process.env.GATSBY_MEGALITH_URL}/wine-club-builder`;
    handleOrderPermissions(contact, '', wineClubBuilderRedirectLink);
  };

  if (subscriptions.length === 0) {
    return (
      <Container>
        <Spacer xl="32px" lg="32px" sm="32px" xs="24px" />
        <Grid container direction="column" alignItems="center">
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
            Contact is not a Wine Club member
          </Text>
          <Spacer xl="16px" lg="16px" sm="16px" xs="16px" />
          <Button
            type="primary"
            fullWidth
            disableRipple
            onClick={shopForContact}
          >
            Create Wine Club Subscription
          </Button>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Spacer xl="32px" lg="32px" sm="32px" xs="24px" />
        <Grid container direction="column" alignItems="center">
          {subscriptions.map(
            (subscription: SubscriptionType, index: number) => {
              return (
                <>
                  <SubscriptionSummary
                    key={`${index}${subscription?.autoShipName}`}
                    subscription={subscription}
                    contact={contact}
                    openSubscriptionDialog={openSubscriptionDialog}
                  />
                  <Spacer xl="24px" lg="24px" sm="24px" xs="16px" />
                </>
              );
            },
          )}
        </Grid>
      </Container>
      <SubscriptionDialog
        autoShipId={autoShipId}
        contactId={contactId}
        openDialog={!!autoShipId}
        closeDialog={closeSubscriptionDialog}
      ></SubscriptionDialog>
      <SubscriptionWarningDialog
        openDialog={warningDialogOpen}
        closeDialog={setWarningDialogClose}
        dialogContent={warningDialogContent}
      />
    </>
  );
}

export default SubscriptionsWrapper;
