import React from 'react';
import styled from '@emotion/styled';
import { PageRendererProps } from 'gatsby';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Layout from '../layout';
import { ContactsContextProvider } from '../components/ContactsV3/ContactsContext';

import OrdersV2 from '../components/OrdersV2';

const Container = styled.div`
  background-color: white;
  min-height: 100%;
`;

function CustomerOrdersPage(props: PageRendererProps) {
  return (
    <Layout>
      <ContactsContextProvider contacts={[]}>
        <Container>
          <OrdersV2 />
        </Container>
      </ContactsContextProvider>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(CustomerOrdersPage);
