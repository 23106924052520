import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ContactsWrapper from './ContactsWrapper';
import { ContactsContextProvider } from './ContactsContext';
import GET_CONTACTS_V2_QUERY from '../../queries/ContactsV2';
import {
  CEDash_ContactsV2Query,
  CEDash_ContactsV2QueryVariables,
} from '../../queries/generatedTypes/CEDash_ContactsV2Query';

interface ContactsV3Props {
  userId: string;
}

const Contacts = ({ userId }: ContactsV3Props) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>('');
  const [sortingInfo, setSortingInfo] = useState({
    orderBy: 'firstName',
    orderByDesc: false,
  });
  const [filter, setFilter] = useState('All');

  // handle sort by request from component tree
  const handleSortContacts = (newSortBy: string) => {
    setSortingInfo({
      orderBy: newSortBy,
      orderByDesc: !sortingInfo.orderByDesc,
    });
  };

  const handleFilterContacts = (newFilter: string) => {
    setFilter(newFilter);
  };

  // pull Contacts data using GQL query
  const { data, error } = useQuery<
    CEDash_ContactsV2Query,
    CEDash_ContactsV2QueryVariables
  >(GET_CONTACTS_V2_QUERY, {
    variables: {
      limit: Number(rowsPerPage),
      offset: (pageNumber - 1) * rowsPerPage,
      owner: userId,
      orderBy: sortingInfo.orderBy,
      sortBy: sortingInfo.orderByDesc ? 'DESC' : 'ASC',
      filterBy: filter,
      ...(searchString && { fullName: searchString }),
    },
    fetchPolicy: 'no-cache',
  });

  if (error) return null;
  if (data?.viewer?.v2?.contactsV2?.contacts?.data) {
    const { data: contactsData, count } = data.viewer.v2.contactsV2.contacts;

    return (
      <ContactsContextProvider contacts={contactsData}>
        <ContactsWrapper
          contacts={contactsData}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleSortContacts={handleSortContacts}
          count={count}
          userId={userId}
          setSearchString={setSearchString}
          filter={filter}
          handleFilterContacts={handleFilterContacts}
        />
      </ContactsContextProvider>
    );
  }
  return null;
};

export default Contacts;
