import { gql } from '@apollo/client';

export const CEDash_CauseOfChoiceNonProfit = gql`
  fragment CEDash_CauseOfChoiceNonProfit on NonProfitV2 {
    id
    nonProfitName
    nonProfitDescription
    nonProfitPayeeName
    nonProfitTaxId
    nonProfitId
    isIRSVerified
    isPreApproved
    nonProfitAddress {
      firstName
      lastName
      city
      state
      zip
      phoneNumber
      addressLineOne
      addressLineOne
    }
  }
`;
