export interface FieldType {
  name: string;
  description?: string;
  type?: {
    name?: string;
    description?: string;
    fields?: FieldType[];
  };
}

export function isFieldType(obj: any): obj is FieldType {
  if (obj.hasOwnProperty('name')) {
    return true;
  }
  return false;
}

export function isFieldArray(obj: any): obj is FieldType[] {
  if (!Array.isArray(obj)) {
    return false;
  }

  if (obj.length === 0) {
    return true;
  }

  return obj.every((value) => isFieldType(value));
}
