import React, { useState } from 'react';
import Decimal from 'decimal.js';
import {
  ButtonV2,
  makeStyles,
  ThemeType,
  Grid,
  Image,
  Text,
  TEXT_DS,
  styled,
  theme,
  useMediaQuery,
} from '@onehope/design-system-v2';
import PwpAddDefaultDialog from './PwpDefaultDialog';
import { PwpOptions } from '../utils/constants';

const assetUrl = `https://dhrovp8aa4hj8.cloudfront.net/assets/shop/square-product/`;

const useStyles = makeStyles((theme: ThemeType) => ({
  mainSubsMgr: {
    maxWidth: '100%',
  },
  productImage: {
    width: '80px',
    height: '80px',
    marginRight: '8px',
  },
  lockedState: {
    width: '100%',
    height: '64px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.lightYellowBeige.main,
  },
  lockedText: {
    fontSize: '14px',
    margin: '0 14px',
    width: '100%',
  },
  salePrice: {
    color: '#B04A3D',
  },
  paddingRight16: {
    paddingRight: '16px',
  },
}));

const Title = styled(Text)({
  marginBottom: 8,
});

const ProductStrikeThroughText = styled('div')({
  paddingRight: 8,
});

const SaveTextWrapper = styled('div')({
  color: '#FFFFFF',
  backgroundColor: '#3C5D62',
  padding: '2px 4px',
  margin: '0 0 10px 0',
});

const SaveText = styled(Text)({
  color: 'white',
});

const RemoveText = styled(Text)({
  width: '80px',
  margin: '0 auto',
  textAlign: 'center',
  cursor: 'pointer',
  paddingRight: '8px',
});

const AddToOrderWCB = styled(ButtonV2)({
  width: '288px',
  [theme.breakpoints.down('xs')]: {
    margin: '24px auto 0 auto',
    width: '100%',
  },
});

interface PwpOfferV2Props {
  pwpProduct:
    | {
        id: string;
        quantity: number | null;
        price: number | null;
        finalPrice: number | null;
        priceSale?: number | null;
        productId: string | null;
        productName: string | null;
        images: {
          id: string;
          hero: string | null;
          squareFront: string | null;
          productCatalog: string | null;
        } | null;
      }
    | null
    | undefined;
  handleAddPwpToCart: (productId: string | null | undefined) => void;
  handleRemovePwpToCart: () => void;
  quantity: number;
  setAutoShipPwP: (autoShipPwp: PwpOptions) => void;
  autoShipPwP: PwpOptions | null;
  pwpOfferLocked?: boolean;
  placeholderOffer?: boolean;
}

export default function PwpOfferV2({
  pwpProduct,
  handleAddPwpToCart,
  handleRemovePwpToCart,
  quantity,
  setAutoShipPwP,
  autoShipPwP,
  pwpOfferLocked,
  placeholderOffer,
}: PwpOfferV2Props) {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [pwpDialogOpen, setPwpDialogOpen] = useState(false);

  const fullPrice = pwpProduct?.price || 0;
  const salePrice =
    pwpProduct?.priceSale || new Decimal(fullPrice).dividedBy(2).toNumber();

  const hasMaxProducts = quantity >= 1;

  const handleAddPwp = () => {
    handleAddPwpToCart(pwpProduct?.productId);
  };
  const handleRemovePwP = () => {
    handleRemovePwpToCart();
  };
  const handleClosePwPDefaultDialog = () => {
    setPwpDialogOpen(false);
  };
  const handleAddPwpAndOpenDialog = () => {
    handleAddPwp();
    setPwpDialogOpen(true);
  };
  const handleSelectPwpOption = (selection: PwpOptions) => {
    setAutoShipPwP(selection);
  };

  const toCurrency = (value: any): string => {
    let num = 0;
    if (value !== '') {
      num = parseFloat(value);
    }
    return num.toFixed(2).toString();
  };

  const getPwpAddedMessage = () => {
    switch (autoShipPwP) {
      case PwpOptions.default:
        return 'You have opted-in to add the monthly 50% off promotion to every shipment in your Wine Club subscription.';
      case PwpOptions.oneTime:
        return 'You will receive this 50% off promotion with your next shipment only.';
      default:
        return '';
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={isMobile ? 0 : 2}
      className={isMobile && classes.paddingRight16}
    >
      <PwpAddDefaultDialog
        open={pwpDialogOpen}
        onClose={handleClosePwPDefaultDialog}
        handleSelectPwpOption={handleSelectPwpOption}
      />
      {(pwpProduct || placeholderOffer) && (
        <Grid container item xs={12} spacing={isMobile ? 0 : 4}>
          {!autoShipPwP && (
            <>
              <Title variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
                Don't miss out on 50% off!
              </Title>
              <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                {
                  "With the purchase of 6+ bottles, you've unlocked this exclusive offer. Enjoy these select wines at 50% off!"
                }
              </Text>
            </>
          )}
          <Grid container item xs={12} md={8}>
            <Grid wrap="nowrap" container direction="row">
              <Grid container direction="column">
                {placeholderOffer ? (
                  <Grid container item xs={12}>
                    <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                      Next month's 50% off promo coming soon!
                    </Text>
                  </Grid>
                ) : (
                  <Grid container item xs={12}>
                    <Grid item xs={2}>
                      <Image
                        src={`${assetUrl}${
                          pwpProduct?.images?.hero ??
                          pwpProduct?.images?.squareFront ??
                          'OH-Generic_PwP_1440x1440.jpg'
                        }`}
                        alt="Promo 3-pack image"
                        altText="Promo 3-pack image"
                        className={classes.productImage}
                      />
                      {hasMaxProducts && !pwpOfferLocked && (
                        <RemoveText
                          variant="custom"
                          default={TEXT_DS.BODY_LINK_14}
                          onClick={handleRemovePwP}
                        >
                          Remove
                        </RemoveText>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                        {pwpProduct?.productName}
                      </Text>
                    </Grid>
                    <Grid container item xs={2}>
                      <Grid item xs={12}>
                        <ProductStrikeThroughText>
                          <Text
                            variant="custom"
                            default={TEXT_DS.BODY_REGULAR_STRIKE_14}
                          >
                            ${toCurrency(fullPrice)}
                          </Text>
                        </ProductStrikeThroughText>
                      </Grid>
                      <Grid item xs={12}>
                        <Text
                          variant="custom"
                          default={TEXT_DS.BODY_REGULAR_14}
                        >
                          <span className={classes.salePrice}>
                            ${toCurrency(salePrice)}
                          </span>
                        </Text>
                      </Grid>
                      <Grid item xs={12}>
                        <SaveTextWrapper>
                          <SaveText
                            variant="custom"
                            default={TEXT_DS.OVERLINE_SEMIBOLD_11}
                          >
                            SAVE 50%
                          </SaveText>
                        </SaveTextWrapper>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4}>
            {pwpOfferLocked && (
              <div className={classes.lockedState}>
                <Text variant={'h5'} className={classes.lockedText}>
                  This order has already been processed and cannot be changed
                </Text>
              </div>
            )}
            {hasMaxProducts && !pwpOfferLocked ? (
              <Text variant="custom" default={TEXT_DS.BODY_LIGHT_ITALIC_16}>
                {getPwpAddedMessage()}
              </Text>
            ) : (
              <AddToOrderWCB
                type="addToCart"
                onClick={handleAddPwpAndOpenDialog}
                disabled={hasMaxProducts}
              >
                ADD TO ORDER
              </AddToOrderWCB>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
