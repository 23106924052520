import React from 'react';
import { ApolloProvider, ApolloConsumer } from '@apollo/client';

import { StandardPageView } from '@onehope/segment';

import { client } from '../../apollo/client';

export const SegmentPageView = () => {
  return (
    <ApolloProvider client={client}>
      <ApolloConsumer>
        {() => (
          <StandardPageView
            enabled={process.env.GATSBY_ENABLE_SEGMENT}
            isCeDashboard
          />
        )}
      </ApolloConsumer>
    </ApolloProvider>
  );
};
