import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';

import CustomerAddMutation from '../../../../mutations/ContactsV2/CustomerAddMutation';
import ContactEditMutation from '../../../../mutations/ContactsV2/ContactsV2UpdateMutation';
import { FormTypes } from './FormTypes';
import { AccountInfoFormSchema } from './validationSchema';
import AccountInfo from './AccountInfo';
import FormContainer from '../../../Event/FormContainer';
import { useContactsContext } from '../../ContactsContext';
import { useTrackingContextValue } from '../../../../contexts/TrackingContext';

//#region Account Info Form

interface AccountInfoFormProps {
  onClose: () => void;
}

const AccountInfoForm = ({ onClose }: AccountInfoFormProps) => {
  const [
    accountAddMutation,
    { loading: accountAddMutationLoading },
  ] = useMutation(CustomerAddMutation);
  const [contactEdit] = useMutation(ContactEditMutation);
  const { loadedContact, handleOrderAdd } = useContactsContext();
  const { trackContacts } = useTrackingContextValue();
  const [errorNotification, setErrorNotification] = useState('');

  const [selectedEventId, setEventId] = useState('');

  useEffect(() => {
    const { eventId } = queryString.parse(window?.location?.search);
    if (eventId) {
      setEventId(eventId);
    }
  }, []); // Runs only once because of empty dependency array

  // pull out loaded contact info for default values
  const {
    contactId,
    firstName,
    lastName = '',
    emailAddress = '',
    dateOfBirth = '',
  } = loadedContact;

  const handleAccountCreate = async ({ values }: { values: FormTypes }) => {
    try {
      // add the account from contact information
      const { data } = await accountAddMutation({
        variables: {
          input: {
            customerEmail: values.emailAddress,
            firstName,
            lastName: values.lastName,
            mobilePhone: '',
            eventId: 'null',
            isAddingNewCustomer: true,
          },
        },
      });
      // extract the newly created account ID
      const newCustomer = data?.shopWithCustomerAdd?.newCustomer;
      const accountId = newCustomer?.accountId;
      // update existing contact record with new accountId
      await contactEdit({
        variables: {
          contactId,
          contact: {
            ...values,
            accountId,
          },
        },
      });
      // segment track the creation of a new account
      trackContacts({
        eventName: 'CE Created Account from Contact',
        contact: newCustomer,
      });
      // let concierge handler take care of the rest
      handleOrderAdd(values.emailAddress, selectedEventId);
    } catch (errors) {
      const errorMessage = errors?.graphQLErrors?.[0]?.message;
      setErrorNotification(errorMessage);
      return;
    }
  };

  //#region HTML

  return (
    <div>
      <FormContainer
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          lastName,
          emailAddress,
          dateOfBirth,
        }}
        validationSchema={AccountInfoFormSchema}
        formToUse={(props: any) => (
          <AccountInfo
            {...props}
            onClose={onClose}
            mutationLoading={accountAddMutationLoading}
            errorNotification={errorNotification}
            setErrorNotification={setErrorNotification}
          />
        )}
        onSubmit={async (values: FormTypes, { setErrors }: any) => {
          await handleAccountCreate({ values });
        }}
      />
    </div>
  );
};

export default AccountInfoForm;
