import {
  styled,
  makeStyles,
  theme,
  GridV2,
  Text,
} from '@onehope/design-system-v2';

export const GridContainer = styled(GridV2)({
  backgroundColor: theme.palette.lightYellowBeige.main,
  padding: 24,
  marginBottom: 24,
});

export const SectionHeader = styled(Text)({
  paddingBottom: 16,
});

export const useCommonStyles = makeStyles(() => ({
  formText: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: 16,
    whiteSpace: 'pre-line',
  },
}));
