import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import {
  Grid,
  Input,
  ButtonV2,
  ButtonBase,
  Icons,
  Text,
  TEXT_DS,
  styled,
  AutoCompleteV2 as AutoComplete,
} from '@onehope/design-system-v2';

/* MUTATIONS */
import ADDRESS_AUTOCOMPLETE from '../../../mutations/AddressAutoCompleteMutation';
import ADDRESS_ZIP_AUTOCOMPLETE from '../../../mutations/AddressGooglePlacesZipMutation';
import ADD_NONPROFIT_EDIT_MUTATION from '../../../mutations/AddNonProfitEditMutation';

/* UTILS */
import useToggle from '../../../utils/useToggleHook';
import { statesAbbrev } from '../../../utils/statesAbbrev';
import {
  validationSchema,
  validationSchemaManualAddress,
} from './validationSchema';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
import {
  AddressType,
  TypeKeys,
  CauseOfChoiceScreenDictionary,
} from '../../../stateManagement/types';
import { useCommonStyles } from '../../../utils/commonStyles';

const BackText = styled(Text)(() => ({
  marginLeft: 32,
}));

export default function NonProfitEdit() {
  const [, dispatch] = useCauseOfChoiceContextValue();
  const { handleCloseModal, handleNonProfitSelected, selectedNonProfit } =
    useCauseOfChoiceNetworkLayerValue();

  const classes = useCommonStyles();
  const [addressPredictions, setAddressPredictions] = useState([]);

  const [addressAutoComplete] = useMutation(ADDRESS_AUTOCOMPLETE);
  const [addressZipMutation] = useMutation(ADDRESS_ZIP_AUTOCOMPLETE);
  const [addNonProfitEditMutation, { loading }] = useMutation(
    ADD_NONPROFIT_EDIT_MUTATION,
  );

  const {
    value: menuOpen,
    setTrue: setMenuOpen,
    setFalse: setMenuClosed,
  } = useToggle(false);

  const {
    value: showManualAddress,
    setTrue: setActivateManualAddress,
    setFalse: setDeactivateManualAddress,
  } = useToggle(false);

  const handleDeactivateManualAddress = (
    setFieldValue: (inputId: string, item: string | null) => void,
  ) => {
    setDeactivateManualAddress();
    setFieldValue('addressLineOne', null);
    setFieldValue('address', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
    setFieldValue('zip', '');
    setAddressPredictions([]);
  };

  const handleGoBack = () => {
    return dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN,
    });
  };

  const handleInputAddressChange = async (search: string) => {
    const variables = {
      input: {
        query: search,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setMenuOpen();
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions?.filter((address: AddressType) => {
          return (
            statesAbbrev.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: AddressType) => {
        return !!address;
      });
      setAddressPredictions(truthyList);
    });
  };

  const handleLoadingState = (loadingState: boolean) => {
    dispatch({
      type: TypeKeys.UPDATE_LOADING,
      loading: loadingState,
    });
  };

  const handleAddressSelected = () => {
    setMenuClosed();
  };

  const handleAddNonProfitEdit = (values: {
    nonProfitName: string;
    address: string;
    addressLineOne: string;
    city: string;
    state: string;
    addressLineTwo: string;
    zip: string;
  }) => {
    handleLoadingState(true);
    const variables = {
      input: {
        nonProfitId: selectedNonProfit?.nonProfitId,
        nonProfitName: values.nonProfitName || null,
        addressLineOne: values.addressLineOne || null,
        addressLineTwo: values.addressLineTwo || null,
        city: values.city || null,
        state: values.state || null,
        zip: values.zip || null,
      },
    };
    addNonProfitEditMutation({ variables }).then((res) => {
      const nonProfitEdit = res?.data?.nonProfitEditAdd?.nonProfitEdit;
      handleLoadingState(false);
      if (handleNonProfitSelected) {
        if (selectedNonProfit?.nonProfitId && nonProfitEdit?.nonProfitEditId) {
          handleNonProfitSelected({
            nonProfitId: selectedNonProfit?.nonProfitId,
            nonProfitEditId: nonProfitEdit.nonProfitEditId,
          });
        }
      }
      handleCloseModal();
    });
  };

  return (
    <Grid container direction="column">
      <Grid container alignItems="center" justifyContent="space-between">
        <ButtonBase onClick={handleGoBack}>
          <Grid xs item container alignItems="center" direction="row">
            <Icons.SmallLeftArrow />
            <BackText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
              Back
            </BackText>
          </Grid>
        </ButtonBase>
        <ButtonBase onClick={handleCloseModal}>
          <Icons.Close />
        </ButtonBase>
      </Grid>
      <Text variant="custom" default={TEXT_DS.BODY_LIGHT_18}>
        Edit the name/address of your selected NPO
      </Text>
      <Formik
        initialValues={{
          nonProfitName: '',
          address: '',
          addressLineOne: '',
          city: '',
          state: '',
          addressLineTwo: '',
          zip: '',
        }}
        validationSchema={
          showManualAddress ? validationSchemaManualAddress : validationSchema
        }
        onSubmit={(values) => handleAddNonProfitEdit(values)}
      >
        {({
          values,
          submitForm,
          isSubmitting,
          errors,
          touched,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <Form className={classes.form}>
              <div className={classes.formItem}>
                <Input
                  fullWidth
                  label="Name of nonprofit"
                  name="nonProfitName"
                  type="nonProfitName"
                  placeholder="American Red Cross"
                  value={values.nonProfitName}
                  onChange={handleChange}
                  error={touched.nonProfitName && Boolean(errors.nonProfitName)}
                  hasError={
                    touched.nonProfitName && Boolean(errors.nonProfitName)
                  }
                  validate
                  helperText={touched.nonProfitName && errors.nonProfitName}
                />
              </div>
              {!showManualAddress && (
                <div className={classes.formItem}>
                  <AutoComplete
                    label="Address"
                    id="displayAddressDropDown"
                    inputId="address"
                    isWithinDialog={true}
                    placeholder="Enter address"
                    value={values.address}
                    touched={touched.address}
                    isSubmitting={isSubmitting}
                    isMenuOpen={menuOpen}
                    setFieldValue={setFieldValue}
                    toggleAddManualAddress={setActivateManualAddress}
                    handleAddressSelected={handleAddressSelected}
                    handleChange={handleChange}
                    getAddressZipMutation={addressZipMutation}
                    handleInputChange={handleInputAddressChange}
                    handleOuterClick={setMenuClosed}
                    objectPredictions={addressPredictions}
                    showLocationIcon
                    error={
                      touched.addressLineOne && Boolean(errors.addressLineOne)
                    }
                  />
                  {Boolean(errors.addressLineOne) && (
                    <div className={classes.helperText}>
                      Address line one required
                    </div>
                  )}
                </div>
              )}
              {showManualAddress && (
                <div>
                  <div className={classes.formItem}>
                    <Input
                      fullWidth
                      label="Address 1"
                      name="addressLineOne"
                      type="addressLineOne"
                      placeholder="Enter address"
                      value={values.addressLineOne}
                      onChange={handleChange}
                      error={
                        touched.addressLineOne && Boolean(errors.addressLineOne)
                      }
                      hasError={
                        touched.addressLineOne && Boolean(errors.addressLineOne)
                      }
                      validate
                      helperText={
                        touched.addressLineOne && errors.addressLineOne
                      }
                    />
                  </div>
                  <Grid container justifyContent="flex-end">
                    <ButtonBase
                      onClick={() =>
                        handleDeactivateManualAddress(setFieldValue)
                      }
                    >
                      <Text className={classes.underlineText}>
                        Back to address search
                      </Text>
                    </ButtonBase>
                  </Grid>
                </div>
              )}
              <div className={classes.formItem}>
                <Input
                  fullWidth
                  label="Address 2"
                  placeholder="Apt, Bldg, Suite"
                  id="addressLineTwo"
                  name="addressLineTwo"
                  value={values.addressLineTwo}
                  onChange={handleChange}
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  helperText={touched.addressLineTwo && errors.addressLineTwo}
                  hasError={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  validate
                />
              </div>
              {showManualAddress && (
                <div>
                  <div className={classes.formItem}>
                    <Input
                      fullWidth
                      label="City"
                      name="city"
                      type="city"
                      placeholder="Enter city"
                      value={values.city}
                      onChange={handleChange}
                      error={touched.city && Boolean(errors.city)}
                      hasError={touched.city && Boolean(errors.city)}
                      validate
                      helperText={touched.city && errors.city}
                    />
                  </div>
                  <Grid container spacing={2} className={classes.formItem}>
                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        label="State"
                        name="state"
                        type="state"
                        placeholder="CA"
                        value={values.state}
                        onChange={handleChange}
                        error={touched.state && Boolean(errors.state)}
                        hasError={touched.state && Boolean(errors.state)}
                        validate
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        label="Zip code"
                        placeholder="90210"
                        id="zip"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        error={touched.zip && Boolean(errors.zip)}
                        helperText={touched.zip && errors.zip}
                        hasError={touched.zip && Boolean(errors.zip)}
                        validate
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={classes.buttonClose}>
                <ButtonV2
                  type="primary"
                  disabled={isSubmitting || loading}
                  fullWidth
                  loading={isSubmitting || loading}
                  onClick={submitForm}
                >
                  Save
                </ButtonV2>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}
