// Following is taken from https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
import React, { createContext, useContext } from 'react';

import { CEDash_UserAuthorizedCeQuery_viewer } from '../queries/generatedTypes/CEDash_UserAuthorizedCeQuery';

import {
  SegmentTrackEventProps,
  trackEventsV2,
  SegmentTrackContactProps,
  trackContacts,
} from '../utils/segment/tracking';
import {
  segmentCookieV2,
  segmentEventV2,
  SegmentEventPropsV2,
} from '../utils/segment/SegmentTrackingV2';

export type TrackingProps = {
  trackEventsV2: (props: SegmentTrackEventProps) => void;
  trackContacts: (props: SegmentTrackContactProps) => void;
  trackEventV2: (props: SegmentEventPropsV2) => Promise<any>;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const TrackingContext = createContext<TrackingProps>();

export const useTrackingContextValue = () =>
  useContext<TrackingProps>(TrackingContext);

// Just sending to Segment for now,
// but we can add other services in here too!
export default function TrackingProvider({
  viewer,
  children,
}: {
  viewer: CEDash_UserAuthorizedCeQuery_viewer;
  children: React.ReactNode;
}) {
  const { user, isLoggedIn } = viewer;

  const trackUserEventV2 = ({
    eventName,
    newEvent,
    oldEvent,
    formValues,
    additionalProperties,
  }: SegmentTrackEventProps) => {
    trackEventsV2({
      eventName,
      user,
      newEvent,
      oldEvent,
      formValues,
      additionalProperties,
    });
  };
  const trackUserContact = ({
    eventName,
    contact,
    totalArchived,
    contactImportStats,
  }: SegmentTrackContactProps) => {
    trackContacts({
      eventName,
      ceAccountId: user?.userId,
      contact,
      totalArchived,
      contactImportStats,
    });
  };

  const trackEventV2 = ({ event, properties }: SegmentEventPropsV2) => {
    if (user?.userId) {
      segmentCookieV2({ userId: user.userId, isLoggedIn: !!isLoggedIn });
    }
    return segmentEventV2({
      event,
      properties,
    });
  };

  return (
    <TrackingContext.Provider
      value={{
        trackEventsV2: trackUserEventV2,
        trackContacts: trackUserContact,
        trackEventV2: trackEventV2,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}
