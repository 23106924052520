import React from 'react';
import { DateTime } from 'luxon';
import { Grid, styled, Text, TEXT_DS, Spacer } from '@onehope/design-system-v2';

import ChangeShipmentDateDialog from './ChangeShipmentDateDialog';
import InputLabel from '../InputLabel';

import { SendToTrackEventV2 } from '../../../utils/segment';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';

const PreferenceEditLabel = styled(Text)({
  cursor: 'pointer',
});

type ChangeShipmentDateProps = {
  user: WineClub_AutoShipGetQuery_viewer_user;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
};

export default function ChangeShipmentDate(props: ChangeShipmentDateProps) {
  const { subscription } = props;

  const [isChangeShipmentModalOpen, setIsChangeShipmentModalOpen] =
    React.useState(false);

  const closeChangeShipmentModalOpen = () => {
    setIsChangeShipmentModalOpen(false);
  };

  const openChangeShipmentModalOpen = () => {
    setIsChangeShipmentModalOpen(true);
  };

  const shippingDateValue = subscription.nextPaymentDate
    ? DateTime.fromISO(subscription.nextPaymentDate).toLocaleString(
        DateTime.DATE_FULL,
      )
    : '';

  return (
    <>
      <ChangeShipmentDateDialog
        user={props.user}
        subscription={subscription}
        open={isChangeShipmentModalOpen}
        onClose={closeChangeShipmentModalOpen}
        conciergeMode={props.conciergeMode}
        sendToTrackEventV2={props.sendToTrackEventV2}
      />
      <Grid item>
        <InputLabel>Next shipment date:</InputLabel>
      </Grid>
      <Spacer xs="4px" sm="4px" lg="4px" />
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {shippingDateValue}
          </Text>
        </Grid>
        <Grid item>
          <PreferenceEditLabel
            variant="custom"
            default={TEXT_DS.BODY_LINK_16}
            onClick={openChangeShipmentModalOpen}
          >
            Change
          </PreferenceEditLabel>
        </Grid>
      </Grid>
      <Spacer xs="24px" sm="24px" lg="24px" />
    </>
  );
}
