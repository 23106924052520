import React from 'react';
import { styled, Text, TEXT_DS, theme } from '@onehope/design-system-v2';
import { DefinitionType, AnswerType } from '../helpers';

const Answer = styled(Text)({
  width: 'fit-content',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '12px',
  background: theme.palette.barelyThereGrey.main,
  padding: '6px 16px',
  marginTop: '12px',
});

const ReachOut = ({
  definition,
  answer,
}: {
  definition: DefinitionType;
  answer: AnswerType;
}) => {
  return (
    <>
      {definition && (
        <Text variant="custom" default={TEXT_DS.BODY_REGULAR_18}>
          {definition.title}
        </Text>
      )}
      {answer.type === 'choices' &&
        answer.choices?.labels.length > 0 &&
        answer.choices?.labels.map(type => (
          <Answer variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {type}
          </Answer>
        ))}

      {answer.type === 'text' && (
        <Answer variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {answer.text}
        </Answer>
      )}
      {answer.type === 'choice' && (
        <Answer variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {answer.choice.label}
        </Answer>
      )}
    </>
  );
};

export default ReachOut;
