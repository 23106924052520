import React from 'react';

import {
  Text,
  TEXT_DS,
  makeStyles,
  ThemeType,
} from '@onehope/design-system-v2';

import { AddressesBookWrapper } from '@onehope/account';

import { GridContainer, SectionHeader } from '../../_shared/styles';
import { formatPhone } from '../../../utils/helpers';
import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../../utils/segment';

import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
  WineClub_AutoShipGetQuery_viewer_user_autoShip_shippingAddress,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';

export const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    paddingBottom: 16,
  },
  formTextGrey: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: 2,
    color: theme.palette.greyDark.main,
  },
  formTextMud: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: 2,
    fontWeight: 500,
    color: theme.palette.mud.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  formText: {
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: 2,
  },
  spacer: {
    paddingBottom: 14,
  },
}));

interface AddressBookType {
  user: WineClub_AutoShipGetQuery_viewer_user;
  address: WineClub_AutoShipGetQuery_viewer_user_autoShip_shippingAddress;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  conciergeMode: boolean;
  contactId?: string;
  sendToTrackEventV2?: SendToTrackEventV2;
}

const ShippingAddress = ({
  user,
  address,
  subscription,
  conciergeMode,
  contactId,
  sendToTrackEventV2,
}: AddressBookType) => {
  const classes = useStyles();

  const {
    firstName,
    lastName,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    phoneNumber,
    addressId,
    companyName,
  } = address;

  const phone = formatPhone(phoneNumber);

  const sendToTrackEventV2Address = ({
    previousAddress,
    newAddress,
  }: {
    previousAddress: WineClub_AutoShipGetQuery_viewer_user_autoShip_shippingAddress;
    newAddress: WineClub_AutoShipGetQuery_viewer_user_autoShip_shippingAddress;
  }) => {
    sendToTrackEventV2 &&
      sendToTrackEventV2({
        event: SubscriptionSegmentEvents.wineClubModified,
        properties: {
          concierge: conciergeMode,
          autoShipId: subscription.autoShipId,
          accountId: user?.userId,
          ceAccountId: conciergeMode ? user?.userId : '',
          propertyUpdated: SubscriptionSegmentProperty.shippingAddressUpdate,
          values: {
            previousValue: previousAddress,
            newValue: newAddress,
          },
        },
      });
  };

  return (
    <>
      <GridContainer container direction="column" spacing={0}>
        <SectionHeader variant="custom" default={TEXT_DS.TITLE_LIGHT_24}>
          Shipping Address
        </SectionHeader>
        {companyName && (
          <Text variant="body1" component="p" className={classes.formText}>
            {companyName}
          </Text>
        )}
        <Text variant="body1" component="p" className={classes.formText}>
          {`${firstName} ${lastName}`}
        </Text>
        {addressLineOne && (
          <Text variant="body1" component="p" className={classes.formText}>
            {`${addressLineOne}`}
          </Text>
        )}
        {addressLineTwo && (
          <Text variant="body1" component="p" className={classes.formText}>
            {`${addressLineTwo}`}
          </Text>
        )}
        {city && (
          <Text variant="body1" component="p" className={classes.formText}>
            {`${city}, ${state} ${zip}`}
          </Text>
        )}
        {phone && (
          <Text variant="body1" component="p" className={classes.formText}>
            {`${phone}`}
          </Text>
        )}
        <div className={classes.spacer} />
        <AddressesBookWrapper
          currentAddressId={addressId}
          subscription={subscription}
          contactId={contactId}
          sendToTrackEventV2={sendToTrackEventV2Address}
        />
      </GridContainer>
    </>
  );
};

export default ShippingAddress;
