import React from 'react';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/react-hooks';

import { styles as s } from '@onehope/design-system';
import {
  Icons,
  IconButton,
  Menu,
  MenuItem as MuiMenuItem,
} from '@onehope/design-system-v2';
import {
  ContactType,
  useContactsContext,
} from '../../components/ContactsV3/ContactsContext';
import GET_CONTACT_V2_QUERY from '../../queries/ContactV2';

const { cssConstants } = s;
const { MoreVertIcon: MuiMoreVertIcon } = Icons;

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '8px' : '0px')};
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  orderId: string | null;
  isCardView: boolean;
  ceId?: string | null;
  customerId?: string | null;
  trackingLinks?: (string | null)[];
  handleTrackOrder?: () => void;
}

interface OrderForCustomerProps {
  ceId: string;
  customerId: string;
  handleOrderPermissions: (contact: ContactType) => void;
  getContact: (params: { ownerAccountId: string; accountId: string }) => any;
}

const handleOrderForCustomer = async ({
  ceId,
  customerId,
  getContact,
  handleOrderPermissions,
}: OrderForCustomerProps) => {
  try {
    const { data } = await getContact({
      ownerAccountId: ceId,
      accountId: customerId,
    });
    const contact = data?.viewer?.v2?.contactsV2?.contact;
    return handleOrderPermissions(contact);
  } catch (error) {
    console.log(error);
  }
};

export default function IconMenu({
  orderId,
  isCardView,
  ceId,
  customerId,
  trackingLinks,
  handleTrackOrder,
}: IconMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const GATSBY_HOPECOMMERCE_URL = process.env.GATSBY_HOPECOMMERCE_URL;

  const { handleOrderPermissions } = useContactsContext();

  const { refetch: getContact } = useQuery(GET_CONTACT_V2_QUERY, {
    variables: { ownerAccountId: '', accountId: '' },
    skip: true,
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewOrder = () => {
    return window.open(`${GATSBY_HOPECOMMERCE_URL}/order/${orderId}`, '_blank');
  };

  return (
    <Container isCardView={isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="View Order" onClick={handleViewOrder}>
          View order
        </MenuItem>
        <MenuItem
          disabled={trackingLinks?.length === 0}
          key="Track Order"
          onClick={handleTrackOrder ? () => handleTrackOrder() : () => {}}
        >
          Track order
        </MenuItem>
        <MenuItem
          key="New Order"
          onClick={() =>
            handleOrderForCustomer({
              ceId,
              customerId,
              handleOrderPermissions,
              getContact,
            })
          }
        >
          New Order
        </MenuItem>
      </Menu>
    </Container>
  );
}
