import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import {
  Button as MuiButton,
  Grid,
  useMediaQuery,
  theme,
  Text,
  Icons,
} from '@onehope/design-system-v2';
import EVENT_UPDATE_MUTATION from '../../../mutations/Event/EventUpdateMutation';
import EventCopyUrlDialog from '../../Dialog/EventCopyUrlDialog';
import Notifications from '../Notifications';
import CancelEventDialog from '../../Dialog/AlertDialog';
import {
  CEDash_EventDetailsPage_viewer_event,
  CEDash_EventDetailsPage_viewer_user,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { isSatelliteEvent } from '../../Events/EventsTable/helpers';
import { EventStatusDictionary } from '../../../utils/enums';
import { getEventNonProfit } from '../../../utils/utils';
import { EventTypeEnum } from '../../EventsV2/eventTypes';

const { Monitor, Globe, InPerson } = Icons;

const Button = styled(MuiButton)`
  width: 100%;
  && {
    width: 100%;
    //width: 100%;
    @media all and (min-width: 768px) {
      width: 180px;
    }
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  flex-direction: column;
  @media all and (min-width: 768px) {
    padding: 0 40px 0 40px;
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  width: 100%;
  @media all and (min-width: 768px) {
    padding: 0;
    max-width: 288px;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const ButtonLeftContainer = styled.div`
  //padding-right: 8px;
  width: 138px;
  @media all and (min-width: 768px) {
    padding: 0;
    margin-bottom: 8px;
  }
  @media all and (min-width: 1280px) {
    width: 180px;
  }
`;
const ButtonRightContainer = styled.div`
  width: 138px;
  @media all and (min-width: 1280px) {
    width: 100%;
  }
`;

const HostName = styled.div`
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
`;

const EventDescription = styled(Text)`
  padding-left: 8px;
`;

const HeaderText = styled(Text)`
  && {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.04em;
    padding-bottom: 8px;
    padding-right: 16px;
  }
`;

const ButtonGrid = styled(Grid)`
  padding-top: 16px;
`;

const DIALOG_TEXT = 'Are you sure you would like to close this event?';

const handleCloseEvent = ({
  event,
  eventUpdateMutation,
  handleSetCancelDialog,
}: {
  event: CEDash_EventDetailsPage_viewer_event;
  eventUpdateMutation: any;
  handleSetCancelDialog: () => void;
}) => () => {
  const { eventId, hostKitQuantity, starterKitCredits } = event;
  // Set the wine selection to "no wine needed" to bypass event closure
  const isNotVirtual = event?.trinityPartyType !== 'EPARTY';
  let noWineNeededSelection = {};
  if (!hostKitQuantity && !starterKitCredits && isNotVirtual) {
    noWineNeededSelection = {
      hostKitQuantity: 0,
      hostKitCredits: 0,
      hostKitPurchaser: null,
      starterKitCredits: 0,
    };
  }
  const input = {
    form: {
      eventId,
      status: EventStatusDictionary.CLOSED,
      trinityPartyStatus: EventStatusDictionary.CLOSED,
      ...noWineNeededSelection,
    },
  };
  return eventUpdateMutation({
    variables: { input: input },
  })
    .then(() => {
      handleSetCancelDialog();
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      return;
    });
};

export function getEventDescription(
  event: CEDash_EventDetailsPage_viewer_event,
) {
  const { eventType, trinityPartyType } = event;
  switch (eventType) {
    case EventTypeEnum.WineTasting: {
      return trinityPartyType === 'EPARTY' ? (
        <Grid container alignItems="center">
          <Monitor /> <EventDescription>Virtual Wine Tasting</EventDescription>
        </Grid>
      ) : (
        <Grid container alignItems="center">
          <InPerson />{' '}
          <EventDescription> In-person Wine Tasting</EventDescription>
        </Grid>
      );
    }
    case EventTypeEnum.Fundraiser: {
      return (
        <Grid container alignItems="center">
          <Globe /> <EventDescription>Online Fundraiser Page</EventDescription>
        </Grid>
      );
    }
    default:
      return null;
  }
}

interface HeaderProps {
  event: CEDash_EventDetailsPage_viewer_event;
  user: CEDash_EventDetailsPage_viewer_user;
}

export default function Header(props: HeaderProps) {
  const { event, user } = props;

  const closedEvent = event?.status === EventStatusDictionary.CLOSED;
  const isWineTasting = event?.eventType === EventTypeEnum.WineTasting;
  const hasOrders = !!event?.ordersCount;
  const satelliteEvent = isSatelliteEvent(event);
  const [isCopyUrlDialogOpen, setIsCopyUrlDialogOpen] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogClose] = React.useState(false);
  const [eventUpdateMutation, { loading }] = useMutation(EVENT_UPDATE_MUTATION);

  const hasCharitySelected = getEventNonProfit(event?.donationInfo);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // show close event button if event is not closed, has orders, has charity selected, has provided hostkit info
  const showCloseEventButton =
    hasOrders && !closedEvent && hasCharitySelected && !satelliteEvent;

  const handleSetCancelDialog = () => {
    setIsCancelDialogClose(!isCancelDialogOpen);
  };

  const dispatchCloseEvent = handleCloseEvent({
    event,
    eventUpdateMutation,
    handleSetCancelDialog,
  });

  function handleViewEvent() {
    return setIsCopyUrlDialogOpen(true);
  }
  return (
    <HeaderContainer>
      <CancelEventDialog
        dialogText={DIALOG_TEXT}
        open={isCancelDialogOpen}
        setClose={handleSetCancelDialog}
        saveChange={dispatchCloseEvent}
        mutationLoading={loading}
      />
      <EventCopyUrlDialog
        isOpen={isCopyUrlDialogOpen}
        selectedTrinityPartyId={event?.exigoPartyId?.toString() || ''}
        selectedEventId={event?.eventId}
        setIsCopyUrlDialogOpen={setIsCopyUrlDialogOpen}
        isSatellite={satelliteEvent}
        groupFundraiserSlug={event?.groupFundraiserSlug || ''}
        trinityDealerId={user.trinityDealerId || ''}
      />
      <Grid container direction={isMobile ? 'column' : 'row'} wrap="nowrap">
        <Grid item xs>
          <HeaderText variant="h3">
            {event.title ||
              event.hostNonProfitName ||
              event.hostFullName ||
              event.ceoFullName}
          </HeaderText>
          <Notifications event={event} />
        </Grid>
        <ButtonGrid container={!!isMobile} item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Button fullWidth={!!isMobile} onClick={handleViewEvent}>
                Event Page
              </Button>
            </Grid>
            {showCloseEventButton && (
              <Grid item>
                <Button type="secondary" onClick={handleSetCancelDialog}>
                  Close Event
                </Button>
              </Grid>
            )}
          </Grid>
        </ButtonGrid>
      </Grid>
    </HeaderContainer>
  );
}
