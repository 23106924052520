import React, { createContext, useReducer, useContext, ReactNode } from 'react';

import reducer from './reducer';
import { CauseOfChoiceScreenDictionary, StateType, ActionTypes } from './types';

export type CauseOfChoiceReducer = [StateType, React.Dispatch<ActionTypes>];

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const CauseOfChoiceContext = createContext<CauseOfChoiceReducer>();

type CauseOfChoiceProviderProps = {
  children: ReactNode;
  initialScreen: CauseOfChoiceScreenDictionary;
};

export default function CauseOfChoiceProvider({
  children,
  initialScreen,
}: CauseOfChoiceProviderProps) {
  const initialState = {
    user: null,
    screen: initialScreen || CauseOfChoiceScreenDictionary.HOME_SCREEN,
    loading: false,
  };

  const sync = useReducer(reducer, initialState);
  return (
    <CauseOfChoiceContext.Provider value={sync}>
      {children}
    </CauseOfChoiceContext.Provider>
  );
}

export const useCauseOfChoiceContextValue = () =>
  useContext<CauseOfChoiceReducer>(CauseOfChoiceContext);
