import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';

import GET_USER_AUTHORIZED_CE_QUERY_QUERY from '../queries/UserAuthorizedCe';
import LoginV2Redirect from './loginV2Redirect';
import { redirectToMegalith } from './utils';
import TrackingProvider from '../contexts/TrackingContext';
import { CEDash_UserAuthorizedCeQuery } from '../queries/generatedTypes/CEDash_UserAuthorizedCeQuery';

// ==============================|| AUTH GUARD ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};
const AuthGuard = ({ children }: GuardProps) => {
  const { data, loading, error } = useQuery<CEDash_UserAuthorizedCeQuery>(
    GET_USER_AUTHORIZED_CE_QUERY_QUERY,
  );
  // if (loading) return <LoaderDark />;
  if (loading) return null;
  if (error) return <div>`Error! ${error.message}`</div>;

  const isLoggedInAsCeo =
    data?.viewer?.user?.permissions?.oneCommerce?.ceo?.access;
  const isLoggedIn = data?.viewer?.isLoggedIn;
  if (!isLoggedIn) {
    return <LoginV2Redirect />;
  }
  if (isLoggedIn && !isLoggedInAsCeo) {
    redirectToMegalith();
    return null;
  }
  return <TrackingProvider viewer={data?.viewer}>{children}</TrackingProvider>;
};

export default AuthGuard;
