import React, { useState } from 'react';
import {
  Grid,
  Icons,
  ButtonBase,
  Text,
  LoaderDark,
} from '@onehope/design-system-v2';

/* COMPONENTS */
import ManualNonProfitWrapper from './ManualNonProfitWrapper';
import NonProfitEditSuggestions from './NonProfitEditSuggestions';

/* UTILS */
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
import {
  TypeKeys,
  CauseOfChoiceScreenDictionary,
  NonProfitType,
  ManualNonProfitInputType,
} from '../../../stateManagement/types';

import { useCommonStyles } from '../../../utils/commonStyles';

export const manualNonProfitAddPages = {
  NON_PROFIT_ADD: 'NON_PROFIT_ADD',
  SUGGESTIONS: 'SUGGESTIONS',
};

export default function CustomNonProfitSearch() {
  const [{ loading }, dispatch] = useCauseOfChoiceContextValue();
  const { handleCloseModal } = useCauseOfChoiceNetworkLayerValue();
  const commonStyles = useCommonStyles();
  const [page, setPage] = useState(manualNonProfitAddPages.NON_PROFIT_ADD);

  const [selectedNonProfitId, setSelectedNonProfitId] = useState<
    string | null | undefined
  >(null);
  const [existingNonProfit, setExistingNonProfit] =
    useState<NonProfitType | null>(null);
  const [initialNonProfitValues, setInitialNonProfitValues] =
    useState<ManualNonProfitInputType>({
      customNonProfit: '',
      nonProfitTaxId: '',
      address: '',
      addressLineOne: '',
      city: '',
      state: '',
      addressLineTwo: '',
      zip: '',
    });

  const showSuggestions = page == manualNonProfitAddPages.SUGGESTIONS;

  const handleGoToNonProfitAdd = () => {
    setPage(manualNonProfitAddPages.NON_PROFIT_ADD);
  };

  const handleGoToSuggestionsPage = () => {
    setPage(manualNonProfitAddPages.SUGGESTIONS);
  };

  const handleGoBack = () => {
    if (showSuggestions) {
      return handleGoToNonProfitAdd();
    }
    return dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN,
    });
  };

  return (
    <Grid container>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={commonStyles.loader}
        >
          <LoaderDark size={36} />
        </Grid>
      ) : (
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="space-between">
            <ButtonBase onClick={handleGoBack}>
              <Grid xs item container alignItems="center" direction="row">
                <Icons.SmallLeftArrow />
                <Text className={commonStyles.backButtonText}>Back</Text>
              </Grid>
            </ButtonBase>
            <ButtonBase onClick={handleCloseModal}>
              <Icons.Close />
            </ButtonBase>
          </Grid>
          {showSuggestions ? (
            <NonProfitEditSuggestions nonProfitId={selectedNonProfitId} />
          ) : (
            <ManualNonProfitWrapper
              initialNonProfitValues={initialNonProfitValues}
              existingNonProfit={existingNonProfit}
              handlePageUpdate={handleGoToSuggestionsPage}
              setExistingNonProfit={setExistingNonProfit}
              setSelectedNonProfitId={setSelectedNonProfitId}
              setInitialNonProfitValues={setInitialNonProfitValues}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
