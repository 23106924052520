import React from 'react';
import CreditsTable from './CreditsTable';
import { CEDash_CreditsQuery } from '../../queries/generatedTypes/CEDash_CreditsQuery';

interface CreditsProps {
  credits: CEDash_CreditsQuery['viewer']['user']['credits'];
  pageNumber: number;
  numberOfTotalCredits: number;
  filterBy: string;
  setPageNumber: (page: number) => void;
  setFilterBy: (filterBy: any) => void;
  handleSortCredits: (sortBy: string) => void;
}

function TablePageWrapper(props: CreditsProps) {
  const {
    credits,
    pageNumber,
    setPageNumber,
    numberOfTotalCredits,
    setFilterBy,
    filterBy,
    handleSortCredits,
  } = props;
  const creditEdges = credits?.edges;

  return (
    <CreditsTable
      credits={creditEdges}
      page={pageNumber}
      setPageNumber={setPageNumber}
      setFilterBy={setFilterBy}
      filterBy={filterBy}
      handleSortCredits={handleSortCredits}
      numberOfTotalCredits={numberOfTotalCredits}
    />
  );
}

export default TablePageWrapper;
