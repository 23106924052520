const TIPS = [
  {
    text: 'Recommended image size is 720 X 442 pixels.',
    key: 'recommended',
  },
  {
    text: 'Minimum image size is 320 X 198 pixels.',
    key: 'minimum',
  },
  {
    text: 'Max file size is 16 MB',
    key: 'maxFileSize',
  },
  {
    text: 'File types: JPG, JPEG, or PNG',
    key: 'fileTypes',
  },
];

export default TIPS;
