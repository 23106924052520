import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../../common/Loading';
import SubscriptionsWrapper from './SubscriptionsWrapper';

import CONTACT_AUTO_SHIPS_GET from '../../../../../queries/ContactAutoshipsGet';

interface ContactWineClubPageProps {
  contact: any;
}

function ContactWineClubPage({ contact }: ContactWineClubPageProps) {
  const { contactId } = contact;

  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionId = urlParams.get('subscriptionId') || '';

  const [autoShipId, setAutoShipId] = useState(subscriptionId);

  const { data, loading, error } = useQuery(CONTACT_AUTO_SHIPS_GET, {
    fetchPolicy: 'network-only',
    skip: !contactId,
    variables: {
      byContactId: contactId,
    },
  });

  if (error) return null;
  if (!loading) {
    const subscriptions = data?.viewer?.user?.autoShips || [];
    return (
      <SubscriptionsWrapper
        autoShipId={autoShipId}
        setAutoShipId={setAutoShipId}
        contact={contact}
        subscriptions={subscriptions}
      />
    );
  }

  return <Loading />;
}

export default ContactWineClubPage;
