import React from 'react';
import { DateTime } from 'luxon';
import {
  Grid,
  Icons,
  makeStyles,
  Text,
  TEXT_DS,
  ThemeType,
  useTheme,
} from '@onehope/design-system-v2';
import { fCurrency } from '../../../../utils/formatNumber';

import { CEDash_DashboardQuery_viewer_user_ce_ceImpactData as CeImpactData } from '../../../../queries/generatedTypes/CEDash_DashboardQuery';

import MainCard from '../../MainCard';

const { CEDashLineGraph } = Icons;

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    color: theme.palette.greyDark.main,
    ['@media (min-width: 1280px)']: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    ['@media (min-width: 1360px)']: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
}));

interface Month {
  month: string;
  impactTotal: string;
}
interface Year {
  year: string;
  months: Array<Month>;
}

const calculateMonthlyData = (ceImpactData: CeImpactData, date: DateTime) => {
  if (!ceImpactData) return null;
  const { years } = ceImpactData;
  const currentYear = date.year.toString();
  const month = date.monthLong.toLowerCase();
  const currentYearData = years.find(
    (yearData: Year) => yearData.year === currentYear,
  );
  return (
    currentYearData &&
    currentYearData.months.find(
      (monthData: Month) => monthData.month.toLowerCase() === month,
    )
  );
};

const getLastMonthData = (ceImpactData: CeImpactData) => {
  if (!ceImpactData) return null;
  const date = DateTime.local();
  const lastMonth = date.minus({ months: 1 });
  return calculateMonthlyData(ceImpactData, lastMonth);
};

const getThisMonthData = (ceImpactData: CeImpactData) => {
  if (!ceImpactData) return null;
  const date = DateTime.local();
  return calculateMonthlyData(ceImpactData, date);
};

interface ImpactStatsProps {
  menuKey: number;
  ceImpactData: CeImpactData;
}

export default function ImpactStats({
  menuKey,
  ceImpactData,
}: ImpactStatsProps) {
  const classes = useStyles();

  const theme = useTheme();

  const getSelectedCauseImpact = () => {
    switch (menuKey) {
      case 1:
        return getThisMonthData(ceImpactData)?.causeImpactTotal;
      case 2:
        return getLastMonthData(ceImpactData)?.causeImpactTotal;
      default:
        return ceImpactData?.personalCauseLifetime;
    }
  };

  const getAllCauseImpact = () => {
    switch (menuKey) {
      case 1:
        return getThisMonthData(ceImpactData)?.impactTotal;
      case 2:
        return getLastMonthData(ceImpactData)?.impactTotal;
      default:
        return ceImpactData?.lifetime;
    }
  };

  const selectedCauseImpact = getSelectedCauseImpact();
  const allCauseImpact = getAllCauseImpact();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={12} lg={6}>
        <MainCard
          sx={{
            backgroundColor: theme.palette.cream.main,
            boxShadow: 'none',
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Text
                variant="custom"
                default={TEXT_DS.OVERLINE_SEMIBOLD_14}
                className={classes.header}
              >
                Cause of Choice
              </Text>
              <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_22}>
                {fCurrency(selectedCauseImpact)}
              </Text>
            </Grid>
            <Grid item>
              <CEDashLineGraph />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={6}>
        <MainCard
          sx={{
            backgroundColor: theme.palette.cream.main,
            boxShadow: 'none',
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Text
                variant="custom"
                default={TEXT_DS.OVERLINE_SEMIBOLD_14}
                className={classes.header}
              >
                All Causes
              </Text>
              <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_22}>
                {fCurrency(allCauseImpact)}
              </Text>
            </Grid>
            <Grid item>
              <CEDashLineGraph />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
}
