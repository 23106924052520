import React, { Ref } from 'react';
import { Box } from '@onehope/design-system-v2';

export interface MainCardProps {
  children: React.ReactNode | string;
  sx?: any;
}

const MainCard = React.forwardRef(
  ({ children, sx = {} }: MainCardProps, ref: Ref<HTMLDivElement>) => {
    return (
      <Box
        ref={ref}
        sx={{
          backgroundColor: 'white',
          borderRadius: '12px',
          boxShadow:
            '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
          p: { xs: 2, md: 3 },
          ...sx,
        }}
      >
        {children}
      </Box>
    );
  },
);

export default MainCard;
