import gql from 'graphql-tag';

export const REACH_OUTS_GET_CATEGORIES = gql`
  query CEDash_GetReachOutsCategories {
    viewer {
      id
      v2 {
        id
        reachOutTemplates {
          categories {
            id
            name
            templateCount
            contentfulEntryId
          }
        }
      }
    }
  }
`;
