import React, { Dispatch, SetStateAction } from 'react';
import StripeWrapper from '../StripeWrapper';
import PaymentDialog from '../PaymentDialog';

import { Account_PaymentsGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment as PaymentStripeType } from '../../../queries/generatedTypes/Account_PaymentsGetQuery';

interface PaymentAddViewProps {
  setView: (input: string) => void;
  setLoadingTrue?: () => void;
  setLoadingFalse?: () => void;
  defaultPaymentId: string;
  setSelectedPayment: Dispatch<SetStateAction<PaymentStripeType | null>>;
  contactId?: string;
}

const PaymentAddView = (props: PaymentAddViewProps) => {
  const { setView, setSelectedPayment, defaultPaymentId } = props;

  const handleClose = () => {
    setView('SELECT');
  };
  return (
    <StripeWrapper>
      <PaymentDialog
        open={true}
        onClose={handleClose}
        setSelectedPayment={setSelectedPayment}
        isEditing={false}
        key="Add Payment"
        headerText="Add Payment"
        hasDefaultPaymentId={!!defaultPaymentId}
        contactId={props.contactId}
      />
    </StripeWrapper>
  );
};

export default PaymentAddView;
