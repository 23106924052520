import React from 'react';
import {
  useTheme,
  theme,
  styled,
  Text,
  TEXT_DS,
  Icons,
  GridV2 as Grid,
} from '@onehope/design-system-v2';

// components
import Chart, { useChart } from '../Chart';
import { BannerContainer, BannerText, FastStartCheck } from './RingComponents';

// types
import { CEDash_FastStartQuery_viewer_v3_fastStart23_salesRewards as SalesReward } from '../../../queries/generatedTypes/CEDash_FastStartQuery';
import Decimal from 'decimal.js';

const ChartWrapper = styled('div')`
  && {
    /* have to override some auto-height setting that cuts off the bottom rounded borders */
    .apexcharts-canvas svg {
      min-height: 120px;
    }
  }
`;

const ChartLeftLabel = styled(Text)`
  display: inline-block;
  position: relative;
  width: 40px;
  top: -7px;
  left: 15px;
  text-align: center;
  color: ${theme.palette.greyDark.main};
`;

const ChartRightLabel = styled(Text)`
  display: inline;
  position: relative;
  width: 40px;
  top: -7px;
  left: 45px;
  text-align: center;
  color: ${theme.palette.greyDark.main};
`;

interface Props {
  index: number;
  reward: SalesReward;
}

const CHART_SIZE = { width: 142, height: 142 };

export default function RetailAwardItem({ index, reward }: Props) {
  const theme = useTheme();

  const chartColors = ['#3C5D62', '#4CB9C3'];

  const completionAmount = Math.floor(
    new Decimal(reward.salesValue)
      .dividedBy(reward.salesGoal)
      .times(100)
      .toNumber(),
  );

  const startAngle = -130;
  const endAngle = 130;

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'horizontal',
        colorStops: [
          { offset: 0, color: chartColors[0] },
          { offset: 100, color: chartColors[1] },
        ],
      },
    },
    stroke: {
      lineCap: 'round',
      curve: 'smooth',
    },
    plotOptions: {
      radialBar: {
        startAngle,
        endAngle,
        hollow: {
          size: '68%',
          margin: 20,
          image: reward.rewardImageURI,
          imageWidth: 80,
          imageHeight: 80,
          imageClipped: false,
          background: 'transparent',
        },
        track: {
          startAngle,
          endAngle,
          margin: 0,
          background: theme.palette.greenTintColor.dark,
          dropShadow: {
            enabled: completionAmount > 0,
            top: 0,
            left: 0,
            blur: 3,
            color: 'rgba(0, 0, 0, 0.4)',
            opacity: 0.3,
          },
        },
        dataLabels: {
          show: false,
        },
      },
    },
  });

  return (
    <Grid
      item
      xs
      sx={{
        mr: '-8px',
        ml: '-8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BannerContainer>
        <Icons.FastStartWideBanner fill={theme.palette.bronzeTint.main} />
        <BannerText variant="custom" default={TEXT_DS.TINY_SEMIBOLD_12}>
          {reward.rewardName}
          {reward.completed && <FastStartCheck />}
        </BannerText>
      </BannerContainer>
      <ChartWrapper>
        <Chart
          type="radialBar"
          series={[completionAmount]}
          options={chartOptions}
          {...CHART_SIZE}
        />

        <ChartLeftLabel variant="custom" default={TEXT_DS.TINY_SEMIBOLD_12}>
          $
          {new Decimal(500)
            .times(index - 1)
            .plus(501)
            .times(index === 0 ? 0 : 1) // hack to make the first label show $0
            .toNumber()
            .toLocaleString('en-US', { maximumFractionDigits: 0 })}
        </ChartLeftLabel>
        <ChartRightLabel variant="custom" default={TEXT_DS.TINY_SEMIBOLD_12}>
          $
          {new Decimal(500)
            .times(index)
            .plus(500)
            .toNumber()
            .toLocaleString('en-US', { maximumFractionDigits: 0 })}
        </ChartRightLabel>
      </ChartWrapper>
    </Grid>
  );
}
