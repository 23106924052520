import * as React from 'react';
import { useMutation } from '@apollo/client';
import {
  Icons,
  SelectCore,
  MenuItem,
  SelectInput as MuiSelectInput,
  Text,
  TEXT_DS,
  styled,
} from '@onehope/design-system-v2';
const { ArrowDropDownCircleIcon } = Icons;

import useToggle from '../../../utils/useToggleHook';
import FrequencyUpdateDialog from './FrequencyUpdateDialog';
import InputLabel from '../InputLabel';

import {
  SendToTrackEventV2,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProperty,
} from '../../../utils/segment';

import AUTOSHIP_UPDATE_MUTATION from '../../../mutations/AutoshipUpdateMutation';
import {
  WineClub_AutoShipGetQuery_viewer_user,
  WineClub_AutoShipGetQuery_viewer_user_autoShip,
} from '../../../queries/generatedTypes/WineClub_AutoShipGetQuery';

const Container = styled('div')`
  margin-bottom: 24px;
  min-width: 288px;
`;

const Select = styled(SelectCore)`
  &.MuiInputBase-root {
    margin-top: 8px;
  }
`;

const SelectInput = styled(MuiSelectInput)`
  & .MuiSelect-select {
    border-radius: 4px;
  }
`;

const Option = styled(MenuItem)`
  && {
  }
`;

const WarningText = styled('div')`
  max-width: 460px;
  padding-top: 8px;
`;

const ArrowDropDown = styled(ArrowDropDownCircleIcon)`
  && {
    fill: #373634;
    margin-right: 16px;
  }
`;

interface FrequencyDropDownProps {
  user: WineClub_AutoShipGetQuery_viewer_user;
  subscription: WineClub_AutoShipGetQuery_viewer_user_autoShip;
  conciergeMode: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
}

export default function FrequencyDropDown(props: FrequencyDropDownProps) {
  const { user, subscription, conciergeMode, sendToTrackEventV2 } = props;

  const [editAutoShipMutation, { loading }] = useMutation(
    AUTOSHIP_UPDATE_MUTATION,
  );

  const [updatedSubscription, setUpdatedSubscription] =
    React.useState(subscription);

  const handleEditAutoship = () => {
    if (!loading) {
      const { autoShipId, accountId, billingIntervalCount } =
        updatedSubscription;
      const variables = {
        autoShipId,
        input: {
          autoShip: {
            autoShipId,
            accountId,
            billingIntervalCount,
          },
        },
      };
      editAutoShipMutation({ variables })
        .then((res) => {
          // send to segment
          sendToTrackEventV2 &&
            sendToTrackEventV2({
              event: SubscriptionSegmentEvents.wineClubModified,
              properties: {
                concierge: conciergeMode,
                autoShipId,
                accountId: user?.userId,
                ceAccountId: conciergeMode ? user?.userId : '',
                propertyUpdated:
                  SubscriptionSegmentProperty.billingIntervalCount,
                values: {
                  previousValue: subscription.billingIntervalCount,
                  newValue: billingIntervalCount,
                },
              },
            });
          const sub = res?.data?.autoShipUpdate?.user?.autoShip;
          setUpdatedSubscription(sub);

          setAlertClose();
        })
        .catch((error) => {
          handleClose();
          console.error({ error });
        });
    }
  };

  const {
    value: isAlertOpen,
    setTrue: setAlertOpen,
    setFalse: setAlertClose,
  } = useToggle();

  function handleChange(event: React.ChangeEvent<{ value: any }>) {
    if (subscription.billingIntervalCount !== event.target.value.toString()) {
      const sub = {
        ...subscription,
        billingIntervalCount: event.target.value.toString(),
      };
      setUpdatedSubscription(sub);
      setAlertOpen();
    }
  }

  function handleClose() {
    if (!loading && isAlertOpen) {
      setUpdatedSubscription(subscription);
      setAlertClose();
    }
  }

  const isLegacySub = subscription?.billingIntervalCount === '4';
  return (
    <Container>
      <FrequencyUpdateDialog
        open={isAlertOpen}
        onClose={handleClose}
        saveChange={handleEditAutoship}
        loading={loading}
      />
      <InputLabel>Frequency</InputLabel>
      <Select
        value={updatedSubscription.billingIntervalCount}
        onChange={handleChange}
        IconComponent={(props: any) => <ArrowDropDown {...props} />}
        input={
          <SelectInput fullWidth name="frequency" id="customized-select" />
        }
      >
        <Option value={1}>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            Every month
          </Text>
        </Option>
        <Option value={2}>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            Every 2 months
          </Text>
        </Option>
        <Option value={3}>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            Every 3 months
          </Text>
        </Option>
        {isLegacySub && (
          <Option disabled value={4}>
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              Every 4 months (unavailable)
            </Text>
          </Option>
        )}
      </Select>
      {isLegacySub && (
        <WarningText>
          <Text>
            Unfortunately, we no longer support the option for shipments every 4
            months. Please choose between shipments every 1 - 3 months.
          </Text>
        </WarningText>
      )}
    </Container>
  );
}
