import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Grid,
  Text,
  TEXT_DS,
  Spacer,
  Icons,
  ButtonV2,
} from '@onehope/design-system-v2';

import { Account_GetAutoShip as SubscriptionType } from '../../../queries/fragments/generatedTypes/Account_GetAutoShip';
import { Account_AddressesGetQuery_viewer_user_addressBook_entries_edges_node_address as AddressSummaryType } from '../../../queries/generatedTypes/Account_AddressesGetQuery';

import { formatPhone } from '../../../utils/helpers';
import AUTOSHIP_UPDATE_MUTATION from '../../../mutations/AutoshipUpdateMutation';

import { useCommonStyles } from '../../_shared/styles';
import {
  useFormStyles,
  ContainerGrid,
  ButtonsContainer,
} from '../../_shared/formStyles';

export interface AddressType {
  node: {
    id: string;
    address: AddressSummaryType;
  };
}

interface AddressSelectViewProps {
  addresses: Array<AddressType>;
  selectedAddress: AddressSummaryType | undefined;
  setView: (input: string) => void;
  currentAddressId: string | null;
  closeDialog: () => void;
  subscription: SubscriptionType;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  setSelectedAddress: Dispatch<SetStateAction<AddressSummaryType | null>>;
  setEditAddressId: (input: string | null) => void;
  sendToTrackEventV2?: any;
}

export function findSelectedAddress(
  currentAddressId: string | null,
  addresses: Array<AddressType>,
) {
  return addresses?.find(
    (address: AddressType) =>
      address?.node?.address?.addressId === currentAddressId,
  );
}

const AddressesSelectView = (props: AddressSelectViewProps) => {
  const {
    setView,
    currentAddressId,
    addresses,
    closeDialog,
    subscription,
    setLoadingTrue,
    setLoadingFalse,
    setEditAddressId,
    selectedAddress,
    setSelectedAddress,
    sendToTrackEventV2,
  } = props;

  if (!selectedAddress) {
    return null;
  }

  const classes = useFormStyles();
  const commonClasses = useCommonStyles();

  const [editAutoShipMutation, { loading: editingAutoship }] = useMutation(
    AUTOSHIP_UPDATE_MUTATION,
  );

  const handleAddAddress = () => {
    setView('ADD');
  };

  const handleEditAutoship = () => {
    setLoadingTrue();
    const { autoShipId, accountId } = subscription;
    const { __typename, id, ...shippingAddress } = selectedAddress;
    const variables = {
      autoShipId,
      input: {
        autoShip: {
          autoShipId,
          accountId,
          shippingAddress,
        },
      },
    };
    editAutoShipMutation({ variables })
      .then(() => {
        // send to segment
        sendToTrackEventV2 &&
          sendToTrackEventV2({
            previousAddress: subscription.shippingAddress,
            newAddress: selectedAddress,
          });
        closeDialog();
        setLoadingFalse();
      })
      .catch((error) => {
        console.error({ error });
        // setLoadingFalse();
      });
  };

  return (
    <ContainerGrid container direction="column" alignItems="flex-start">
      <Text
        variant="custom"
        default={TEXT_DS.TITLE_LIGHT_24}
        tablet={TEXT_DS.TITLE_LIGHT_32}
        laptop={TEXT_DS.TITLE_LIGHT_32}
        desktop={TEXT_DS.TITLE_LIGHT_32}
      >
        Ship To
      </Text>
      <Spacer xs="24px" sm="32px" lg="32px" />
      <Grid container spacing={0}>
        {addresses?.map((a: AddressType) => {
          const address = a?.node?.address;
          const {
            firstName,
            lastName,
            addressLineOne,
            addressLineTwo,
            city,
            state,
            zip,
            phoneNumber,
            addressId,
            companyName,
            nickname,
          } = address;
          const phone = formatPhone(phoneNumber);
          const { defaultContainer, unSelectedContainer } = commonClasses;
          const isSelectedAddress = !!(
            selectedAddress?.addressId === addressId
          );
          const addressContainerClass = isSelectedAddress
            ? defaultContainer
            : unSelectedContainer;
          const handleEditAddress = () => {
            setView('EDIT');
            setEditAddressId(addressId);
          };

          const handleSetSelectedAddress = () => {
            if (!isSelectedAddress) {
              setSelectedAddress(address);
            }
          };
          return (
            <Grid
              container
              key={a?.node?.id}
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="flex-start"
              onClick={handleSetSelectedAddress}
              className={addressContainerClass}
            >
              <Grid key={a?.node?.id} container direction="column" spacing={0}>
                {nickname && (
                  <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
                    {nickname}
                  </Text>
                )}
                {companyName && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {companyName}
                  </Text>
                )}
                <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                  {`${firstName} ${lastName}`}
                </Text>
                {addressLineOne && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {`${addressLineOne}`}
                  </Text>
                )}
                {addressLineTwo && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {`${addressLineTwo}`}
                  </Text>
                )}
                {city && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {`${city}, ${state} ${zip}`}
                  </Text>
                )}
                {phone && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                    {`${phone}`}
                  </Text>
                )}
                <Spacer xs="16px" sm="16px" lg="16px" />
                <Grid container>
                  <ButtonV2
                    type="textLink"
                    className={commonClasses.button}
                    onClick={handleEditAddress}
                  >
                    Edit
                  </ButtonV2>
                </Grid>
              </Grid>
              <div
                className={
                  isSelectedAddress
                    ? commonClasses.defaultTag
                    : commonClasses.unSelectedTag
                }
              >
                {isSelectedAddress ? 'Selected' : 'Select'}
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Button fullWidth type="secondary" onClick={handleAddAddress}>
        <Grid container justifyContent="space-between" alignItems="center">
          <div className={commonClasses.buttonSpacer} />
          <div>Add New Address</div>
          <Icons.Plus />
        </Grid>
      </Button>
      <Spacer xs="24px" sm="32px" lg="32px" />
      <ButtonsContainer container>
        <Button
          type="primary"
          disabled={
            editingAutoship || selectedAddress?.addressId === currentAddressId
          }
          loading={editingAutoship}
          fullWidth
          onClick={handleEditAutoship}
        >
          Save
        </Button>
        <Button
          className={classes.secondaryButton}
          type="secondary"
          fullWidth
          onClick={closeDialog}
        >
          Cancel
        </Button>
      </ButtonsContainer>
    </ContainerGrid>
  );
};

export default AddressesSelectView;
