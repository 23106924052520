import gql from 'graphql-tag';

const ContactsV2BulkExportMutation = gql`
  mutation BulkExportContactsV2($ownerAccountId: String!) {
    contactsV2BulkExport(input: { ownerAccountId: $ownerAccountId }) {
      data {
        firstName
        lastName
      }
    }
  }
`;

export default ContactsV2BulkExportMutation;
