/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Which stage of the ReachOut lifecycle this is, e.g. initial reachout, followup, etc.
 */
export enum ReachOutLifecycleStage {
  FOLLOWUP = "FOLLOWUP",
  INITIAL = "INITIAL",
}

/**
 * A medium through which a ReachOut may be shared
 */
export enum ReachOutRequestStatus {
  CLICKED = "CLICKED",
  COMPLETE = "COMPLETE",
  PENDING = "PENDING",
  REMINDER = "REMINDER",
}

/**
 * The type of result this Reach Out produced; such as an order, a survey response, etc.
 */
export enum ReachOutResultType {
  NONE = "NONE",
  ORDER = "ORDER",
  SURVEY_RESPONSE = "SURVEY_RESPONSE",
}

/**
 * A medium through which a ReachOut may be shared
 */
export enum ShareMedium {
  EMAIL = "EMAIL",
  FACEBOOK_FEED_POST = "FACEBOOK_FEED_POST",
  FACEBOOK_STORY = "FACEBOOK_STORY",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  LINKED_IN_POST = "LINKED_IN_POST",
  MANUAL_LINK = "MANUAL_LINK",
  TEXT_MESSAGE = "TEXT_MESSAGE",
}

export interface AddressAutoCompleteInput {
  query?: string | null;
  clientMutationId?: string | null;
}

export interface AddressForm {
  id?: string | null;
  name?: string | null;
  cardNumber?: string | null;
  expirationDate?: string | null;
  cvv?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  addressId?: string | null;
  phone?: string | null;
  country?: string | null;
  recipientBirthDate?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  specialInstructions?: string | null;
  companyName?: string | null;
  nickname?: string | null;
  guestAcceptsMarketing?: string | null;
  guestShoppingWithCEO?: string | null;
  disabled?: boolean | null;
}

/**
 * A Shipping Address
 */
export interface AddressInput {
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  recipientBirthDate?: string | null;
  addressId?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  specialInstructions?: string | null;
  nickname?: string | null;
  companyName?: string | null;
  guestAcceptsMarketing?: string | null;
  guestShoppingWithCEO?: string | null;
  placeId?: string | null;
  disabled?: boolean | null;
}

export interface CancelOptions {
  skipShipCompliantAndWineShipping?: boolean | null;
  doNotRefundShipping?: boolean | null;
  refundPreferredCustomerMembership?: boolean | null;
  cancelPreferredCustomerMembership?: boolean | null;
}

export interface ContactEditFormFieldsType {
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
  contactType?: string | null;
  email?: string | null;
  mobilePhone?: string | null;
  companies?: (string | null)[] | null;
  dateOfBirth?: string | null;
  shippingAddress?: AddressForm | null;
}

export interface ContactEditInput {
  form?: ContactEditFormFieldsType | null;
  clientMutationId?: string | null;
}

/**
 * Cloudsponge Contact
 */
export interface ContactV2Input {
  accountId?: string | null;
  ownerAccountId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  dateOfBirth?: string | null;
  contactImage?: string | null;
  contactOrigin?: string | null;
  socialProfiles?: SocialProfiles | null;
}

export interface DisplayPreferencesInput {
  id?: string | null;
  donationGoal?: boolean | null;
  supporters?: boolean | null;
  eventLocation?: boolean | null;
}

export interface DonationAddInput {
  eventId?: string | null;
  nonProfitUuid?: string | null;
  donationType?: string | null;
  form?: DonationFormFieldsType | null;
  lobMemo?: string | null;
  clientMutationId?: string | null;
}

export interface DonationFormFieldsType {
  optionalName?: string | null;
  nonProfitName?: string | null;
  nonProfitPayeeName?: string | null;
  nonProfitTaxId?: string | null;
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phoneNumber?: string | null;
  donationGoal?: number | null;
}

export interface DonationV2AddInput {
  eventId?: string | null;
  nonProfitId?: string | null;
  lobMemo?: string | null;
  donationGoal?: number | null;
  nonProfitEditId?: string | null;
  clientMutationId?: string | null;
}

export interface EventCreateFormFieldsType {
  eventType?: string | null;
  hostType?: string | null;
  eventDate?: string | null;
  hostEmail?: string | null;
  hostFirstName?: string | null;
  hostFullName?: string | null;
  hostLastName?: string | null;
  hostAccountId?: string | null;
  hostNonProfitName?: string | null;
  hostQuote?: string | null;
  hostPhone?: string | null;
  timeZone?: string | null;
  trinityPartyType?: string | null;
  eventAddress?: AddressInput | null;
  eventVirtualLink?: string | null;
  hostKitCredits?: number | null;
  starterKitCredits?: number | null;
  hostKitPurchaser?: string | null;
  hostKitQuantity?: number | null;
  title?: string | null;
  isPurchasingTastingFlights?: boolean | null;
  displayPreferences?: DisplayPreferencesInput | null;
  version?: string | null;
  closeDate?: string | null;
}

export interface EventCreateInput {
  form?: EventCreateFormFieldsType | null;
  clientMutationId?: string | null;
}

export interface EventEditHostFormFieldsType {
  hostKitOrderNumbers?: string | null;
  hostKitQuantity?: string | null;
}

export interface EventEditInput {
  eventId?: string | null;
  donationId?: string | null;
  hostKitForm?: EventEditHostFormFieldsType | null;
  selectedUserId?: string | null;
  clientMutationId?: string | null;
}

export interface EventResendOrderLinkInput {
  eventId?: string | null;
  clientMutationId?: string | null;
}

export interface EventUpdateFormFieldsType {
  eventId?: string | null;
  isArchived?: boolean | null;
  hostType?: string | null;
  eventDate?: string | null;
  hostEmail?: string | null;
  eventType?: string | null;
  hostFirstName?: string | null;
  hostFullName?: string | null;
  hostLastName?: string | null;
  hostNonProfitName?: string | null;
  hostQuote?: string | null;
  hostAccountId?: string | null;
  hostPhone?: string | null;
  status?: string | null;
  timeZone?: string | null;
  trinityPartyType?: string | null;
  trinityPartyStatus?: string | null;
  eventAddress?: AddressInput | null;
  hostKitCredits?: number | null;
  starterKitCredits?: number | null;
  hostKitPurchaser?: string | null;
  hostKitQuantity?: number | null;
  hostKitOrderNumbers?: string | null;
  title?: string | null;
  closeDate?: string | null;
  eventVirtualLink?: string | null;
  isPurchasingTastingFlights?: boolean | null;
  displayPreferences?: DisplayPreferencesInput | null;
}

export interface EventUpdateInput {
  form?: EventUpdateFormFieldsType | null;
  clientMutationId?: string | null;
}

export interface GenerateExigoLoginURIInput {
  clientMutationId?: string | null;
}

export interface GooglePlacesZipInput {
  placeId?: string | null;
  clientMutationId?: string | null;
}

export interface ImageAddInput {
  imageId?: string | null;
  suggestedImageName?: string | null;
  authorId?: string | null;
  path?: string | null;
  groupName?: string | null;
  bucketName?: string | null;
  imageType?: string | null;
  mimeType?: string | null;
  thumbnails?: ThumbnailInput | null;
  clientMutationId?: string | null;
}

export interface ImageDeleteInput {
  imageId?: string | null;
  clientMutationId?: string | null;
}

export interface ImagePresignedUrlGetInput {
  bucketName?: string | null;
  keys?: (string | null)[] | null;
  region?: string | null;
  clientMutationId?: string | null;
}

export interface LogoutInput {
  id?: string | null;
  clientMutationId?: string | null;
}

export interface OrderCancelInput {
  orderId: string;
  cancelOptions?: CancelOptions | null;
  clientMutationId?: string | null;
}

export interface PreApprovedDonationAddInput {
  eventId?: string | null;
  npoId?: string | null;
  donationType?: string | null;
  lobMemo?: string | null;
  donationGoal?: number | null;
  clientMutationId?: string | null;
}

export interface RegisterReachOutV2Input {
  contactId?: string | null;
  lifecycleStage: ReachOutLifecycleStage;
  reachOutId: string;
  shareMedium: ShareMedium;
  clientMutationId?: string | null;
}

export interface ShopWithCustomerAddInput {
  eventId: string;
  ceoEmail?: string | null;
  isAddingNewCustomer?: boolean | null;
  customerEmail: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  clientMutationId?: string | null;
}

export interface ShopWithCustomerDeleteInput {
  clientMutationId?: string | null;
}

/**
 * Social profiles information for use with ContactsV2
 */
export interface SocialProfiles {
  twitter?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
}

export interface ThumbnailInput {
  id?: string | null;
  medium?: string | null;
  thumb?: string | null;
}

export interface TrinitySsoUrlGetInput {
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
