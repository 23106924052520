import React, { Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  makeStyles,
  useMediaQuery,
  useTheme,
  ThemeType,
} from '@onehope/design-system-v2';

import { Account_AddressesGetQuery_viewer_user_shippingAddressDefault as AddressType } from '../../queries/generatedTypes/Account_AddressesGetQuery';

import AddressForm from './AddressForm';

interface AddressDialogProps {
  open: boolean;
  onClose: () => void;
  showDialog?: boolean;
  address?: AddressType;
  headerText: string;
  isDefault?: boolean;
  isEditing?: boolean;
  hasDefaultShippingAddressId?: boolean;
  setSelectedAddress?: Dispatch<SetStateAction<AddressType | null>>;
  contactId?: string;
}

const useDialogStyles = makeStyles((theme: ThemeType) => ({
  paper: {
    borderRadius: 0,
    minWidth: '320px',
    [theme.breakpoints.up('sm')]: { width: '600px' },
  },
}));

const AddressDialog = (props: AddressDialogProps) => {
  const {
    onClose,
    open,
    showDialog,
    headerText,
    address,
    isEditing,
    isDefault,
    hasDefaultShippingAddressId,
  } = props;
  const dialogClasses = useDialogStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleOnClose = () => {
    onClose();
  };
  if (!open) return null;
  return (
    <>
      {showDialog ? (
        <Dialog
          open={open}
          onClose={handleOnClose}
          classes={dialogClasses}
          fullScreen={isMobile}
        >
          <AddressForm
            open={open}
            hasDefaultShippingAddressId={hasDefaultShippingAddressId}
            address={address}
            onClose={onClose}
            isDefault={isDefault}
            isEditing={isEditing}
            headerText={headerText}
            setSelectedAddress={props.setSelectedAddress}
            contactId={props.contactId}
          />
        </Dialog>
      ) : (
        <AddressForm
          open={open}
          onClose={onClose}
          address={address}
          headerText={headerText}
          isDefault={isDefault}
          isEditing={isEditing}
          hasDefaultShippingAddressId={hasDefaultShippingAddressId}
          setSelectedAddress={props.setSelectedAddress}
          contactId={props.contactId}
        />
      )}
    </>
  );
};

export default AddressDialog;
