import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import {
  styled,
  Grid,
  Icons,
  Text,
  TEXT_DS,
  useMediaQuery,
  theme,
  ButtonV2,
  Input as MuiInput,
} from '@onehope/design-system-v2';
const { Close } = Icons;

import { checkforValidDate, checkDateFor21OrOlder } from '@onehope/validation';
import { FormikProps } from 'formik';
import { FormTypes } from './FormTypes';
import DateMask from '../../../../utils/DateMask';

interface AccountInfoProps {
  values: FormTypes;
  onClose: () => void;
  existingCustomer: any;
  setExistingCustomer: () => void;
  existingCustomerSelected: boolean;
  errorNotification: string | null;
  setErrorNotification: (error: string) => void;
  mutationLoading: boolean;
}

const Form = styled('div')(() => ({
  margin: '0 auto',
  width: '100%',
  backgroundColor: `${theme.palette.white.main}`,
  padding: '32px 16px',
  '@media all and (min-width: 968px)': {
    padding: '48px',
    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.15)',
    maxWidth: '616px',
  },
}));

const Subtitle = styled('div')(() => ({
  paddingBottom: '24px',
  paddingTop: '16px',
}));

const InputContainer = styled('div')(() => ({
  margin: '0 auto',
  width: '100%',
}));

const Input = styled(MuiInput)(() => ({
  paddingBottom: '24px',
}));

const CloseDialog = styled('div')(() => ({
  cursor: 'pointer',
}));

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ErrorNotification = styled('div')(() => ({
  width: '100%',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.29,
  letterSpacing: 'normal',
  borderRadius: '4px',
  border: 'solid 1px #faad14',
  backgroundColor: ' #fffbf0',
  padding: '14px 22px',
  marginTop: '-16px',
  marginBottom: '24px',
}));

const AccountInfo = ({
  errors,
  errorNotification,
  handleChange,
  handleSubmit,
  initialValues,
  isSubmitting,
  mutationLoading,
  onClose,
  setTouched,
  setFieldTouched,
  touched,
  values,
}: FormikProps<FormTypes> & AccountInfoProps) => {
  const { dateOfBirth, lastName, emailAddress } = values;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (errors) {
      setTouched({
        dateOfBirth: !!initialValues.dateOfBirth,
        lastName: !!initialValues.lastName,
        emailAddress: !!initialValues.emailAddress,
      });
    }
  }, [errors, initialValues, setTouched]);

  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const disableSubmit = mutationLoading || !isEmpty(errors) || isEmpty(touched);

  return (
    <form onSubmit={handleSubmit}>
      <Form>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Text
              variant="custom"
              default={
                isMobile ? TEXT_DS.TITLE_LIGHT_24 : TEXT_DS.TITLE_LIGHT_32
              }
            >
              Complete Missing Information
            </Text>
          </Grid>
          <Grid item>
            <CloseDialog onClick={onClose}>
              <Close />
            </CloseDialog>
          </Grid>
        </Grid>
        <Subtitle>
          <Text
            variant="custom"
            default={isMobile ? TEXT_DS.BODY_LIGHT_14 : TEXT_DS.BODY_LIGHT_16}
          >
            In order to start shopping for this contact, please add the
            following information to continue.
          </Text>
        </Subtitle>
        <>
          <InputContainer>
            {!initialValues.lastName && (
              <Input
                label={'Last name'}
                placeholder="Austen"
                id="lastName"
                name="lastName"
                value={lastName}
                fullWidth
                validate
                hasError={
                  !isSubmitting && touched.lastName && Boolean(errors.lastName)
                }
                hasSuccess={
                  !isSubmitting && touched.lastName && !Boolean(errors.lastName)
                }
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={
                  errors.lastName && touched.lastName && errors.lastName
                }
                onChange={(e: React.SyntheticEvent) => change('lastName', e)}
              />
            )}
            {!initialValues.emailAddress && (
              <Input
                label="Email address"
                placeholder="jane@gmail.com"
                id="emailAddress"
                name="emailAddress"
                value={emailAddress}
                fullWidth
                validate
                hasError={
                  !isSubmitting &&
                  touched.emailAddress &&
                  Boolean(errors.emailAddress)
                }
                hasSuccess={
                  !isSubmitting &&
                  touched.emailAddress &&
                  !Boolean(errors.emailAddress)
                }
                error={touched.emailAddress && Boolean(errors.emailAddress)}
                helperText={
                  errors.emailAddress &&
                  touched.emailAddress &&
                  errors.emailAddress
                }
                onChange={(e: React.SyntheticEvent) =>
                  change('emailAddress', e)
                }
              />
            )}
            {(!initialValues.dateOfBirth ||
              !checkforValidDate(initialValues.dateOfBirth) ||
              !checkDateFor21OrOlder(initialValues.dateOfBirth)) && (
              <Input
                label="Birthday"
                placeholder="MM / DD / YYYY"
                defaultChecked={false}
                id="dateOfBirth"
                name="dateOfBirth"
                fullWidth
                onChange={(e: React.SyntheticEvent) => change('dateOfBirth', e)}
                value={dateOfBirth}
                helperText={
                  errors.dateOfBirth &&
                  touched.dateOfBirth &&
                  errors.dateOfBirth
                }
                hasSuccess={
                  !isSubmitting &&
                  touched.dateOfBirth &&
                  !Boolean(errors.dateOfBirth)
                }
                hasError={
                  !isSubmitting &&
                  touched.dateOfBirth &&
                  Boolean(errors.dateOfBirth)
                }
                mask={DateMask}
              />
            )}
          </InputContainer>
          {errorNotification && (
            <ErrorNotification>{errorNotification}</ErrorNotification>
          )}
          <ButtonContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ButtonV2
                  variant="outlined"
                  type={'secondary'}
                  disableRipple
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </ButtonV2>
              </Grid>
              <Grid item xs={12} md={6}>
                <ButtonV2
                  disableRipple
                  fullWidth
                  disabled={disableSubmit}
                  onClick={handleSubmit}
                >
                  Update
                </ButtonV2>
              </Grid>
            </Grid>
          </ButtonContainer>
        </>
      </Form>
    </form>
  );
};

export default AccountInfo;
