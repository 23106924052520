import { css } from '@emotion/core';

export const widgetContainer = css`
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

// widgets that take up two container spaces
export const widgetContainerDouble = css`
  grid-column-start: 1;
  grid-column-end: 3;
  height: 778px;
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  @media only screen and (max-width: 1280px) {
    margin-top: 0;
    // revert to one space, not enough room for double cards on mobile
    grid-column-end: 2;
  }
`;
