// @ts-ignore
import React from 'react';
import { Button } from '@onehope/design-system-ohw';
import { DropDownRegular } from '../styles/ant-design-styles/dropDown';
// import * as s from '../styles/styles';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';

interface DropDownProps {
  kind: string;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  overlay: React.ReactNode | (() => React.ReactNode);
  onVisibleChange?: (visible: boolean) => void;
  visible?: boolean;
  disabled?: boolean;
  align?: object;
  getPopupContainer?: (triggerNode: Element) => HTMLElement;
  prefixCls?: string;
  className?: string;
  transitionName?: string;
  placement?: string;
  overlayClassName?: string;
  overlayStyle?: React.CSSProperties;
  forceRender?: boolean;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
}

// function handleButtonClick(e) {
//   message.info('Click on left button.');
//   console.log('click left button', e);
// }

function handleMenuClick(e: any) {
  // message.info('Click on menu item.');
  console.log('click', e);
}

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">
      <Icon type="user" />
      1st menu item
    </Menu.Item>
    <Menu.Item key="2">
      <Icon type="user" />
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3">
      <Icon type="user" />
      3rd item
    </Menu.Item>
  </Menu>
);

export default function DropDown(props: DropDownProps) {
  const { kind, ...rest } = props;
  switch (kind) {
    case 'regular': {
      return (
        <DropDownRegular {...rest} overlay={menu}>
          <Button block type="secondary-regular">
            Button <Icon type="down" />
          </Button>
        </DropDownRegular>
      );
    }
    default:
      return (
        <DropDownRegular {...rest} overlay={menu}>
          <Button block type="tertiary-regular">
            Button <Icon type="down" />
          </Button>
        </DropDownRegular>
      );
  }
}
