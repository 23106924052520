import styled from '@emotion/styled';
import { Text } from '@onehope/design-system-v2';
type CustomerCardProps = {
  selected?: boolean;
};

export const CustomerCard = styled.div<CustomerCardProps>`
  max-height: 150px;
  border-radius: 4px;
  background-color: ${({ selected }: CustomerCardProps) =>
    selected ? '#ffffff' : '#f2f2f2'};
  border: ${({ selected }: CustomerCardProps) =>
    selected ? 'solid 1px #1a1a1a' : 'solid 1px #f2f2f2'};
  margin-bottom: 40px;
`;

export const CardTitle = styled(Text)`
  && {
    padding-bottom: 8px;
  }
`;

export const CardInfo = styled.div`
  position: relative;
  padding: 16px;
  width: 100%;
`;

export const Icon = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
`;

export const CardLine = styled(Text)`
  /* font-family: ProximaNova; */
  font-weight: 400;
  word-break: break-word;
`;
