import React, { useState } from 'react';
import Decimal from 'decimal.js';
import {
  ButtonV2,
  makeStyles,
  ThemeType,
  Grid,
  Image,
  Text,
  TEXT_DS,
  styled,
  theme,
} from '@onehope/design-system-v2';
import PwpAddDefaultDialog from './PwpDefaultDialog';
import { PwpOptions } from '../utils/constants';

const onecommerceImageHost =
  process.env.NEXT_PUBLIC_ONECOMMERCE_IMAGE_HOST ||
  process.env.GATSBY_ONECOMMERCE_IMAGE_HOST;
const assetUrl = `${onecommerceImageHost}/assets/shop/square-product/`;

const useStyles = makeStyles((theme: ThemeType) => ({
  mainSubsMgr: {
    maxWidth: '100%',
  },
  mainWCB: {
    ['@media (max-width: 599px)']: {
      maxWidth: window.innerWidth,
    },
  },
  productImage: {
    width: '80px',
    height: '80px',
    marginRight: '8px',
  },
  lockedState: {
    width: '100%',
    height: '64px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.lightYellowBeige.main,
  },
  lockedText: {
    fontSize: '14px',
    margin: '0 14px',
    width: '100%',
  },
  productContainer: {
    marginTop: '8px',
    marginBottom: '16px',
  },
  subContainer: {
    width: '100%',
  },
}));

const Title = styled(Text)({
  marginBottom: 8,
});

const ProductText = styled(Grid)({
  paddingBottom: 10,
});

const ProductStrikeThroughText = styled('div')({
  paddingRight: 8,
});

const SaveTextWrapper = styled('div')({
  backgroundColor: theme.palette.primary.main,
  padding: '2px 4px',
  margin: '0 0 10px 0',
});

const SaveText = styled(Text)({
  color: 'white',
});

const RemoveText = styled(Text)({
  width: '80px',
  margin: '0 auto',
  textAlign: 'center',
  cursor: 'pointer',
  paddingRight: '8px',
});

const AddToOrderWCB = styled(ButtonV2)({
  width: '288px',
  [theme.breakpoints.down('xs')]: {
    margin: '24px auto 0 auto',
    width: '100%',
  },
});

interface PwPOfferProps {
  pwpProduct:
    | {
        id: string;
        quantity: number | null;
        price: number | null;
        finalPrice: number | null;
        priceSale?: number | null;
        productId: string | null;
        name: string | null;
        images: {
          id: string;
          squareFront: string | null;
          productCatalog: string | null;
        } | null;
      }
    | null
    | undefined;
  handleAddPwpToCart: (productId: string | null | undefined) => void;
  handleRemovePwpToCart: () => void;
  quantity: number;
  setAutoShipPwP: (autoShipPwp: PwpOptions) => void;
  autoShipPwP: PwpOptions | null;
  pwpOfferLocked?: boolean;
  placeholderOffer?: boolean;
  wineClubBuilder?: boolean;
}

export default function PwpOffer(pwpOfferProps: PwPOfferProps) {
  const classes = useStyles();

  const [pwpDialogOpen, setPwpDialogOpen] = useState(false);

  const {
    pwpProduct,
    handleAddPwpToCart,
    handleRemovePwpToCart,
    quantity,
    setAutoShipPwP,
    autoShipPwP,
    pwpOfferLocked,
    placeholderOffer,
    wineClubBuilder,
  } = pwpOfferProps;

  const fullPrice = pwpProduct?.price || 0;
  const salePrice =
    pwpProduct?.priceSale || new Decimal(fullPrice).dividedBy(2).toNumber();

  const hasMaxProducts = quantity >= 1;

  const handleAddPwp = () => {
    handleAddPwpToCart(pwpProduct?.productId);
  };
  const handleRemovePwP = () => {
    handleRemovePwpToCart();
  };
  const handleClosePwPDefaultDialog = () => {
    setPwpDialogOpen(false);
  };
  const handleAddPwpAndOpenDialog = () => {
    handleAddPwp();
    setPwpDialogOpen(true);
  };
  const handleSelectPwpOption = (selection: PwpOptions) => {
    setAutoShipPwP(selection);
  };

  const toCurrency = (value: any): string => {
    let num = 0;
    if (value !== '') {
      num = parseFloat(value);
    }
    return num.toFixed(2).toString();
  };

  const getPwPOfferMessage = () => {
    switch (true) {
      case wineClubBuilder:
        return "With the purchase of 6+ bottles, you've unlocked this exclusive offer. Enjoy these select wines at 50% off!";
      case placeholderOffer:
        return "Congratulations, you've unlocked a selection of wines at 50% off. Would you like to add to your next Wine Club shipment?";
      default:
        return "Congratulations, you've unlocked this exclusive offer. Enjoy these select wines at 50% off!";
    }
  };
  const getPwpAddedMessage = () => {
    switch (autoShipPwP) {
      case PwpOptions.default:
        return 'You have opted-in to add the monthly 50% off promotion to every shipment in your Wine Club subscription.';
      case PwpOptions.oneTime:
        return 'You will receive this 50% off promotion with your next shipment only.';
      default:
        return '';
    }
  };

  return (
    <Grid>
      <PwpAddDefaultDialog
        open={pwpDialogOpen}
        onClose={handleClosePwPDefaultDialog}
        handleSelectPwpOption={handleSelectPwpOption}
      />
      {(pwpProduct || placeholderOffer) && (
        <div
          className={wineClubBuilder ? classes.mainWCB : classes.mainSubsMgr}
        >
          {!autoShipPwP && (
            <>
              <Title variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
                Don't miss out on 50% off!
              </Title>
              <Text variant="custom" default={TEXT_DS.BODY_LIGHT_16}>
                {getPwPOfferMessage()}
              </Text>
            </>
          )}
          <div>
            <Grid
              wrap="nowrap"
              container
              direction="row"
              className={classes.productContainer}
            >
              <Grid>
                <Image
                  src={`${assetUrl}${
                    pwpProduct?.images?.squareFront ??
                    'OH-Generic_PwP_1440x1440.jpg'
                  }`}
                  alt="Promo 3-pack image"
                  altText="Promo 3-pack image"
                  className={classes.productImage}
                />
                {hasMaxProducts && !pwpOfferLocked && (
                  <RemoveText
                    variant="custom"
                    default={TEXT_DS.BODY_LINK_14}
                    onClick={handleRemovePwP}
                  >
                    Remove
                  </RemoveText>
                )}
              </Grid>
              <Grid container direction="column">
                {placeholderOffer ? (
                  <ProductText container item>
                    <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                      Next month's 50% off promo coming soon!
                    </Text>
                  </ProductText>
                ) : (
                  <>
                    <ProductText container item>
                      <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                        {pwpProduct?.name}
                      </Text>
                    </ProductText>
                    <ProductText container item>
                      <ProductStrikeThroughText>
                        <Text
                          variant="custom"
                          default={TEXT_DS.BODY_REGULAR_STRIKE_14}
                        >
                          ${toCurrency(fullPrice)}
                        </Text>
                      </ProductStrikeThroughText>
                      <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
                        ${toCurrency(salePrice)}
                      </Text>
                    </ProductText>
                  </>
                )}
                <Grid container item justifyContent="flex-start">
                  <SaveTextWrapper>
                    <SaveText
                      variant="custom"
                      default={TEXT_DS.OVERLINE_SEMIBOLD_11}
                    >
                      SAVE 50%
                    </SaveText>
                  </SaveTextWrapper>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item className={classes.subContainer}>
              {pwpOfferLocked ? (
                <div className={classes.lockedState}>
                  <Text variant={'h5'} className={classes.lockedText}>
                    This order has already been processed and cannot be changed
                  </Text>
                </div>
              ) : hasMaxProducts ? (
                wineClubBuilder && (
                  <Text variant="custom" default={TEXT_DS.BODY_LIGHT_ITALIC_16}>
                    {getPwpAddedMessage()}
                  </Text>
                )
              ) : wineClubBuilder ? (
                <AddToOrderWCB
                  type="addToCart"
                  onClick={handleAddPwpAndOpenDialog}
                  disabled={hasMaxProducts}
                >
                  ADD TO ORDER
                </AddToOrderWCB>
              ) : (
                <ButtonV2
                  fullWidth
                  type="addToCart"
                  onClick={handleAddPwpAndOpenDialog}
                  disabled={hasMaxProducts}
                >
                  ADD TO ORDER
                </ButtonV2>
              )}
            </Grid>
          </div>
        </div>
      )}
    </Grid>
  );
}
