import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'gatsby';
import {
  Grid,
  styled,
  Text,
  TEXT_DS,
  theme,
  Icons,
  Card,
  CardMedia,
  useMediaQuery,
} from '@onehope/design-system-v2';
import { CEDash_GetReachOutsV2_viewer_v2_reachOutTemplates_templates } from '../../../queries/generatedTypes/CEDash_GetReachOutsV2';

// Custom breakpoint - the last moment before the cards are arranged in a single column
const minMultiColumnBP = 764; // differs from the mock (744)

const GrayText = styled(Text)({
  color: theme.palette.greyDark.main,
  marginTop: '2px',
});

const Expiration = styled(props => <div {...props} />)(
  ({ isExpired }: { isExpired: boolean }) => ({
    top: '0px',
    borderRadius: '100px',
    position: 'absolute',
    backgroundColor: isExpired
      ? theme.palette.greyMedium.main
      : theme.palette.neutral0.main,
    padding: '3px 6px',
    marginTop: '10px',
    right: '10px',
    display: 'flex',
    '& svg': {
      marginRight: '5px',
      marginTop: '2px',
    },
  }),
);

const CardGridItem = styled(Grid)({
  minWidth: '343px',

  // grows back in size to fill screen as a column
  [theme.breakpoints.down(minMultiColumnBP)]: {
    width: '521px',
  },
  [theme.breakpoints.down(575)]: {
    width: '343px',
  },
});

const StyledImage = styled(CardMedia)({
  minHeight: '171px',
  minWidth: '327px',

  // gets large images again
  [theme.breakpoints.down(minMultiColumnBP)]: {
    height: '272px',
  },
  [theme.breakpoints.down(575)]: {
    height: '180px',
  },
  backgroundSize: '100% 100%',
});

const StyledCard = styled(Card)({
  borderRadius: '12px',
  boxShadow: '0 1px 4px rgba(26, 26, 26, 0.25)',
  position: 'relative',
});

const CardContentContainer = styled('div')({
  padding: '10px 16px 10px 16px',
});

const NoUnderlinedLink = styled(Link)({
  textDecoration: 'unset',
});

const getExpirationInfo = (expirationDate: string) => {
  if (!expirationDate) {
    return { daysLeftStringLabel: null, isExpired: null };
  }
  const expDate = DateTime.fromISO(expirationDate);
  const now = DateTime.now();

  const reachoutExpired = expDate && expDate < now;
  if (reachoutExpired) {
    return { daysLeftStringLabel: null, isExpired: true };
  }

  const diff = expDate.diff(now, ['days']);
  const daysLeft = Math.ceil(diff?.values?.days) || 0;
  return {
    daysLeftStringLabel:
      daysLeft !== 1 ? `${daysLeft} days left` : `${daysLeft} day left`,
    isExpired: false,
  };
};

type Props = {
  templates: CEDash_GetReachOutsV2_viewer_v2_reachOutTemplates_templates[];
};

export default function Tiles({ templates }: Props) {
  const isMultiColumn = useMediaQuery(`(min-width:${minMultiColumnBP}px)`);

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      spacing={isMultiColumn ? 4 : 2}
    >
      {templates.length > 0 &&
        templates.map(template => {
          const { daysLeftStringLabel, isExpired } = getExpirationInfo(
            template.expirationDate,
          );

          return (
            <CardGridItem item>
              <NoUnderlinedLink
                to={`/reach-outs?reachoutId=${template.reachOutId}`}
              >
                <StyledCard elevation={0}>
                  <StyledImage
                    image={`${template.coverImageUrl}?w=1110&h=583`}
                  />
                  {isExpired && (
                    <Expiration isExpired={true}>
                      <Icons.ClockSolid />
                      <Text variant="custom" default={TEXT_DS.TINY_SEMIBOLD_11}>
                        Inactive
                      </Text>
                    </Expiration>
                  )}
                  {!isExpired && daysLeftStringLabel && (
                    <Expiration>
                      <Icons.ClockSolid />
                      <Text variant="custom" default={TEXT_DS.TINY_SEMIBOLD_11}>
                        {daysLeftStringLabel}
                      </Text>
                    </Expiration>
                  )}
                  <CardContentContainer>
                    <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
                      {template.name}
                    </Text>
                    {template.category?.name && (
                      <GrayText
                        variant="custom"
                        default={TEXT_DS.OVERLINE_SEMIBOLD_11}
                      >
                        {template.category.name}
                      </GrayText>
                    )}
                  </CardContentContainer>
                </StyledCard>
              </NoUnderlinedLink>
            </CardGridItem>
          );
        })}
    </Grid>
  );
}
