import gql from 'graphql-tag';

const ContactsV2BulkCreateMutation = gql`
  mutation BulkCreateContactsV2($contacts: [ContactV2Input]!) {
    contactsV2AddBulk(input: { contacts: $contacts }) {
      data {
        results {
          firstName
          lastName
          emailAddress
        }
        skipped {
          firstName
          lastName
          emailAddress
          skipped
        }
        errors {
          error {
            message
            name
          }
        }
      }
    }
  }
`;
export default ContactsV2BulkCreateMutation;
