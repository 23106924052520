import React, { FormEvent } from 'react';

import { Tab } from '@onehope/design-system-v2';
import { Container, TabsContainer } from './TabNavigation.styles';
import { ContactManagementTabs } from '../TabContent/tabConstants';

interface TabNavigationProps {
  handleChange: (event: FormEvent<EventTarget>, value: string) => void;
  tabValue: string;
  hasAccount: boolean;
}

export const config = [
  {
    label: 'Details',
    value: ContactManagementTabs.DETAILS,
  },
  {
    label: 'Wine Club',
    value: ContactManagementTabs.WINECLUB,
  },
  {
    label: 'Orders',
    value: ContactManagementTabs.ORDERS,
  },
  {
    label: 'Events',
    value: ContactManagementTabs.EVENTS,
  },
];

const TabNavigation = ({ handleChange, tabValue }: TabNavigationProps) => {
  return (
    <Container>
      <TabsContainer
        key="TabNavigation"
        value={tabValue}
        scrollButtons={false}
        variant="scrollable"
        onChange={handleChange}
      >
        {config.map(({ value, label }) => (
          <Tab disableRipple key={value} value={value} label={label} />
        ))}
      </TabsContainer>
    </Container>
  );
};

export default TabNavigation;
