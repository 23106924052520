import React from 'react';
import { Container, TabsContainer, TabContainer } from './TabFilter.styles';

type TabFilterProps = {
  filterBy: string;
  setFilterBy: (value: string) => void;
  tabValues: Array<string>;
  tabLabels?: Array<string>;
};

function TabFilter(props: TabFilterProps) {
  const { filterBy, setFilterBy, tabValues, tabLabels } = props;
  const handleTabChange = (e: React.FormEvent<EventTarget>, value: string) => {
    setFilterBy(value);
  };
  return (
    <Container>
      <TabsContainer
        style={{ minHeight: 'unset' }}
        key="TabFilter"
        value={filterBy}
        scrollButtons={false}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        {tabValues &&
          tabValues.length &&
          tabValues.map((val, index) => {
            return (
              <TabContainer
                disableRipple
                key={val}
                value={val}
                label={(tabLabels && tabLabels[index]) || val}
              />
            );
          })}
      </TabsContainer>
    </Container>
  );
}

export default TabFilter;
