import React from 'react';
import { DialogV2, Spacer, Text, TEXT_DS } from '@onehope/design-system-v2';
import { PwpOptions } from '../utils/constants';

interface PwpAddDefaultDialogProps {
  open: boolean;
  onClose: () => void;
  handleSelectPwpOption: (selection: PwpOptions) => void;
}

/** Option to select recurring addition of PwP to Autoship  */
export default function PwpAddDefaultDialog(props: PwpAddDefaultDialogProps) {
  const { onClose, handleSelectPwpOption, open } = props;

  const handleSelectDefaultAndClose = () => {
    handleSelectPwpOption(PwpOptions.default);
    onClose();
  };
  const handleSelectOneTimeAndClose = () => {
    handleSelectPwpOption(PwpOptions.oneTime);
    onClose();
  };

  return (
    <DialogV2
      open={open}
      saveButtonLabel="Yes, add to every shipment"
      onSave={handleSelectDefaultAndClose}
      closeButtonLabel="No thanks, just this shipment"
      onClose={handleSelectOneTimeAndClose}
      title="Confirmed! This offer has been added to your cart."
      buttonDirection="column"
    >
      <>
        <Text variant="custom" default={TEXT_DS.BODY_LIGHT_18}>
          Would you like to opt-in to add this 50% off promotion, that changes
          each month, to your future Wine Club shipments?
        </Text>
        <Spacer xl="16px" lg="16px" sm="16px" xs="16px"></Spacer>
        <Text variant="custom" default={TEXT_DS.BODY_LIGHT_ITALIC_14}>
          You can remove these wines at any time before your order ships.
        </Text>
      </>
    </DialogV2>
  );
}
