import React from 'react';
import { GridV2 as Grid, Box, Text } from '@onehope/design-system-v2';

// utils
import {
  CEDash_FastStartQuery_viewer_v3_fastStart23 as FastStartData,
  CEDash_FastStartQuery_viewer_v3_fastStart23_rings as Ring,
} from '../../../queries/generatedTypes/CEDash_FastStartQuery';

import ChartColumn from './ChartColumn';
import Swiper from './Swiper';

interface Props {
  fastStartData: FastStartData;
}

export default function Content({ fastStartData }: Props) {
  const rings = fastStartData?.rings;
  const showSwiper =
    typeof rings !== 'undefined' && rings.length > fastStartData.ringSetSize;

  return (
    <>
      <Box sx={{ pb: { xs: 2, md: 3 } }}>
        <Text>
          Sell $500 and sponsor 1 Wine Rep to close a ring for a $150 bonus.
          Close 4 rings for another $400.
        </Text>
      </Box>
      <Box sx={{ overflowX: 'auto' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            maxWidth: 700,
            minWidth: 630,
            width: '100%',
            m: '0 auto',
          }}
        >
          {showSwiper ? (
            <Swiper fastStartData={fastStartData} />
          ) : (
            <>
              {rings?.map((ring: Ring, index) => {
                return (
                  <ChartColumn
                    key={`${ring.bonusAmount}-${index}`}
                    ring={ring}
                  />
                );
              })}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}
