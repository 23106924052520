import * as React from 'react';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import {
  Icons,
  IconButton,
  Menu,
  Text,
  TEXT_DS,
  MenuItem as MuiMenuItem,
} from '@onehope/design-system-v2';
import { EventManagementTabs } from '../../../Event/TabNavigation/tabConstants';

const { cssConstants } = s;
const { MoreVertIcon: MuiMoreVertIcon } = Icons;

const options = ['Share Link', 'Manage Event', 'New Order', 'Donate'];

const Container = styled.div<{ isCardView: boolean }>`
  position: ${({ isCardView }) => (isCardView ? 'absolute' : 'relative')};
  right: 0;
  top: ${({ isCardView }) => (isCardView ? '14px' : '0px')};
  z-index: 100;
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  eventId: string | null | undefined;
  isCardView: boolean;
  setSelectedEventId: (eventId: string) => void;
  handleOrderAdd: (eventId: string) => void;
  toggleEventCopyUrlDialog?: (
    eventId: string | null | undefined,
    exigoPartyId: string | null | undefined,
  ) => void;
  isAcceptingOrders?: boolean | null | undefined;
  exigoPartyId?: string | null | undefined;
}

export default function IconMenu(props: IconMenuProps) {
  const { eventId, isCardView, setSelectedEventId } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewEvent = () => {
    return navigate(`/event?eventId=${eventId}`);
  };

  const handleDonate = () => {
    return navigate(
      `/event?eventId=${eventId}&tab=${EventManagementTabs.CHARITY}`,
    );
  };

  const handleCancel = () => {
    if (eventId) {
      setSelectedEventId(eventId);
      handleClose();
    }
  };
  const handleOrderAdd = () => {
    if (eventId) {
      props.handleOrderAdd(eventId);
      handleClose();
    }
  };

  const handleCopyEventLink = () => {
    if (eventId && props.toggleEventCopyUrlDialog) {
      props.toggleEventCopyUrlDialog(eventId, props.exigoPartyId);
      handleClose();
    }
  };

  return (
    <Container isCardView={isCardView}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem key="Manage Event" onClick={handleViewEvent}>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
            Manage event
          </Text>
        </MenuItem>
        {/*{props.isAcceptingOrders && (*/}
        {/*  <MenuItem key="Order" onClick={handleOrderAdd}>*/}
        {/*    <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>*/}
        {/*      Place order*/}
        {/*    </Text>*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
        <MenuItem key="Copy" onClick={handleCopyEventLink}>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_14}>
            Copy event link
          </Text>
        </MenuItem>
        {/*<MenuItem key="Cancel" onClick={handleCancel}>*/}
        {/*  Cancel event*/}
        {/*</MenuItem>*/}
        {/*<MenuItem key="Donate" onClick={handleDonate}>*/}
        {/*  Donate*/}
        {/*</MenuItem>*/}
      </Menu>
    </Container>
  );
}
