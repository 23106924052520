import CheckBox from 'antd/lib/checkbox';
import 'antd/lib/checkbox/style/index.css';
import styled from '@emotion/styled';
import { primaryColor } from '../cssConstants';

const baseStyle = `
  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after  {
    border-width: 2px !important;
    border-color: ${primaryColor} !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
     border-color: ${primaryColor} !important;
     background-color: ${primaryColor} !important;
  }
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const CheckBoxPrimary = styled(CheckBox)`
  ${baseStyle};
  padding: 0 6px;
` as any;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const CheckBoxPrimaryCircle = styled(CheckBox)`
  ${baseStyle}
  .ant-checkbox-inner, 
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-width: 2px !important;
    border-radius: 100px !important;
  }
  .ant-checkbox-inner {
    border-radius: 100px !important;
    background-color: transparent !important;
  }
` as any;
