import React, { useState } from 'react';
import {
  ButtonV2,
  Currency,
  GridV2 as Grid,
  Icons,
  Image as MuiImage,
  SelectionButtonContainer,
  styled,
  TEXT_DS,
  Text,
  theme,
} from '@onehope/design-system-v2';

// State Management
import { getPriceDiscount } from './helper';
import DialogSelector from './DialogSelector';
import { urlProductImage } from '../../utils/constants';
import { WineClub_BulkProductsV2Query_viewer_productsV2Bulk_edges_node } from '../../queries/generatedTypes/WineClub_BulkProductsV2Query';
import { WineClub_DiscountConfigWCQuery_viewer_v2_discounts } from '../../queries/generatedTypes/WineClub_DiscountConfigWCQuery';

//#region Styles

const IconContainer = styled('span')({
  right: '8px',
  top: '8px',
  position: 'absolute',
});

const Title = styled(Text)({
  margin: '8px 0 4px',
});

const BodyText = styled(Text)({
  marginTop: '4px',
  textAlign: 'center',
  textWrap: 'balance',

  ul: {
    paddingInlineStart: '20px',
    lineHeight: '1.3',
    li: {
      marginBottom: '5px',
      textAlign: 'left',
    },
  },

  [theme.breakpoints.down('xs')]: {
    textAlign: 'left',
    lineHeight: '1.4',
    ul: {
      paddingInlineStart: '5px',
      lineHeight: '1.2',
    },
  },
});

const ViewMoreButton = styled(ButtonV2)({
  marginTop: '10px !important',
  marginBottom: '6px !important',
});

const SelectionButton = styled(SelectionButtonContainer)({
  height: '100% !important',
  maxWidth: '343px !important',
  width: '288px !important',
  [theme.breakpoints.up('md')]: {
    height: 'unset', //'447.5px',
    width: '331px !important',
    // minWidth: '331px !important',
    // width: 'unset !important',
  },
});

const MudText = styled(Text)({
  color: `${theme.palette.mud.main} !important`,
  marginLeft: '4px',
});

const Image = styled(MuiImage)({
  margin: 'auto',
  height: '235px',
  width: '168px',
});

//#region Component

interface WineBundleOptionProps {
  product:
    | WineClub_BulkProductsV2Query_viewer_productsV2Bulk_edges_node
    | undefined
    | null;
  discounts:
    | WineClub_DiscountConfigWCQuery_viewer_v2_discounts
    | null
    | undefined;
  setSelected: (selected: string | null | undefined) => void;
  selected: boolean;
}

const WineBundleOption = ({
  product,
  discounts,
  setSelected,
  selected,
}: WineBundleOptionProps) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const dialogConfig = {
    headerText: product?.name,
    htmlBody: `${product?.content?.summary} ${product?.content?.includes}`,
  };

  const onClickViewMore = (e: React.MouseEvent) => {
    setDetailsOpen(true);
    e.stopPropagation();
  };

  const onCardClick = () => {
    if (selected) {
      setSelected(null);
    } else {
      setSelected(product?.productId);
    }
  };

  const handleClose = () => {
    setDetailsOpen(false);
  };

  const finalPrice = getPriceDiscount(
    product?.priceSale,
    product?.price,
    product?.wineBottleQuantity,
    discounts,
  );
  const originalPrice = product?.price;

  //#region HTML

  return (
    <>
      <DialogSelector
        open={detailsOpen}
        onClose={handleClose}
        config={dialogConfig}
      />
      <SelectionButton selected={selected} onClick={onCardClick}>
        {selected && (
          <IconContainer>
            <Icons.RadioCheckOn fill={theme.palette.mud.main} />
          </IconContainer>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            // alt=`${product?.name} Wine Club`
            src={`${urlProductImage}${product?.images?.hero}`}
          />
          <Title variant="custom" default={TEXT_DS.BODY_SEMIBOLD_20}>
            {product?.name}
          </Title>
          <Grid container direction="row" justifyContent="center">
            {originalPrice && finalPrice != originalPrice && (
              <Text variant="custom" default={TEXT_DS.BODY_REGULAR_STRIKE_16}>
                <Currency rawValue={originalPrice} />
              </Text>
            )}
            {finalPrice && (
              <MudText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
                <Currency rawValue={finalPrice} />
              </MudText>
            )}
          </Grid>
          <BodyText
            variant="custom"
            default={TEXT_DS.BODY_REGULAR_16}
            dangerouslySetInnerHTML={{ __html: product?.content?.summary }}
          ></BodyText>

          <ViewMoreButton
            type="link"
            color="mud"
            size="small"
            onClick={onClickViewMore}
          >
            View Wines
          </ViewMoreButton>
        </Grid>
      </SelectionButton>
    </>
  );
};

export default WineBundleOption;
