import { Tabs, theme } from '@onehope/design-system-v2';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  padding: 0 16px 0 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  @media only screen and (min-width: 768px) {
    padding: 0 40px 0 40px;
  }
`;

export const TabLabel = styled.div`
  margin-left: -8px;
`;

export const TabsContainer = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  & .MuiTabs-indicator {
    background-color: ${theme.palette.primary.main};
  }
  & button {
    text-transform: unset;
    font-family: ${theme.bodyFontFamily};
    font-weight: 420;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.01em;
    color: ${theme.palette.greyDark.main};
    padding: 16px;
    opacity: 1;
    min-width: 94px;
  }
  & button.Mui-selected {
    color: ${theme.palette.primary.main};
    font-weight: 420;
  }
  & button span span.MuiBadge-badge {
    transform: translate(200%, -50%);
  }
`;
