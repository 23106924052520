import * as React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { styles as s } from '@onehope/design-system';
import { Icons, Grid, Text, theme, TEXT_DS } from '@onehope/design-system-v2';
const { AlertCircleOutline, Monitor, Globe, InPerson } = Icons;
import IconMenu from '../IconMenu';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { EventTypeEnum } from '../../../EventsV2/eventTypes';
import { EventStatusDictionary } from '../../../../utils/enums';

import { isSatelliteEvent } from '../helpers';
import {
  getEventNonProfit,
  getShowNotification,
} from '../../../../utils/utils';
import { EventManagementTabs } from '../../../Event/TabNavigation/tabConstants';

//#region Styles

const { cssConstants } = s;

const GridItem = styled.div<{ eventCount: number }>`
  position: relative;
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px rgba(26, 26, 26, 0.25);
  border-radius: 2px;
  background-color: white;
  padding: 16px;
  max-width: 100%;
  @media all and (min-width: 768px) {
    padding: 24px;
    max-width: ${({ eventCount }) => (eventCount < 3 ? '356px' : '100%')};
  }
  :hover {
    cursor: pointer;
    background-color: ${cssConstants.neutral2};
  }
`;

const NonProfitNotSelected = styled(Text)`
  color: ${theme.palette.greyDark.main};
`;

const HostName = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  padding-bottom: 8px;
  padding-right: 16px;
`;

const GridBlock = styled(Text)`
  font-weight: 500;
  padding-bottom: 8px;
  width: 90px;
`;

const GridText = styled(Text)``;

const GridContainer = styled(Grid)`
  width: 90px;
`;

const EventDescription = styled(Text)`
  padding-left: 8px;
  color: ${theme.palette.greyDark.main};
`;

const DividerWrapper = styled.div`
  padding: 8px 0;
`;
const Divider = styled.div`
  border-bottom: 1px solid ${theme.palette.greyLight.main};
`;

//#region Helpers

const eventIsAcceptingOrders = (e: EventType | null | undefined) =>
  e?.trinityPartyStatus === EventStatusDictionary.OPEN;

const eventClosed = (e: EventType | null | undefined) =>
  e?.status !== EventStatusDictionary.OPEN;

const handleRowClick = (
  event: EventType | null | undefined,
  contactId?: string,
) => {
  return () => {
    let link = !getEventNonProfit(event?.donationInfo)
      ? `/event?eventId=${event?.eventId}&tab=${EventManagementTabs.CHARITY}`
      : `/event?eventId=${event?.eventId}&tab=${EventManagementTabs.SUMMARY}`;
    if (contactId) {
      link = `${link}&contactId=${contactId}`;
    }
    return navigate(link);
  };
};

function getEventDescription(eventType: string, trinityPartyType: string) {
  switch (eventType) {
    case EventTypeEnum.WineTasting: {
      return trinityPartyType === 'EPARTY' ? (
        <Grid container alignItems="center">
          <Monitor color={theme.palette.greyDark.main} />{' '}
          <EventDescription variant="custom" default={TEXT_DS.BODY_SEMIBOLD_14}>
            Virtual Wine Tasting
          </EventDescription>
        </Grid>
      ) : (
        <Grid container alignItems="center">
          <InPerson color={theme.palette.greyDark.main} />{' '}
          <EventDescription variant="custom" default={TEXT_DS.BODY_SEMIBOLD_14}>
            {' '}
            In-person Wine Tasting
          </EventDescription>
        </Grid>
      );
    }
    case EventTypeEnum.Fundraiser: {
      return (
        <Grid container alignItems="center">
          <Globe color={theme.palette.greyDark.main} />{' '}
          <EventDescription variant="custom" default={TEXT_DS.BODY_SEMIBOLD_14}>
            Online Fundraiser Page
          </EventDescription>
        </Grid>
      );
    }
    default:
      return null;
  }
}

//#region Event Card Component
interface EventCardProps {
  event: EventType | null | undefined;
  eventCount: number;
  contactId?: string;
  setSelectedEventId: (eventId: string) => void;
  handleOrderAdd: (eventId: string) => void;
  toggleEventCopyUrlDialog: (eventId: string | null | undefined) => void;
}

export default function EventCard(props: EventCardProps) {
  const {
    event,
    eventCount,
    contactId,
    setSelectedEventId,
    handleOrderAdd,
    toggleEventCopyUrlDialog,
  } = props;

  if (event) {
    const onRowClick = handleRowClick(event, contactId);
    const isAcceptingOrders = eventIsAcceptingOrders(event);
    const timeZone = event?.timeZone ? event.timeZone : 'America/Chicago';
    const satelliteEvent = isSatelliteEvent(event);
    const isFundraiser = event.eventType === 'FUNDRAISER';
    const eventDate = isFundraiser
      ? 'ACTIVE'
      : DateTime.fromISO(event.eventDate, {
          zone: timeZone,
        }).toLocaleString(DateTime.DATE_SHORT);
    const showCharityNotification =
      !satelliteEvent && !getEventNonProfit(event?.donationInfo);
    const showNotification = getShowNotification(event);

    //#region HTML

    if (!event) {
      return <> </>;
    }

    return (
      <GridItem eventCount={eventCount}>
        <IconMenu
          isCardView
          eventId={event?.eventId}
          exigoPartyId={event?.exigoPartyId?.toString()}
          handleOrderAdd={handleOrderAdd}
          setSelectedEventId={setSelectedEventId}
          isAcceptingOrders={isAcceptingOrders}
          toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
        />
        <Grid onClick={onRowClick}>
          <HostName>
            {event.title || event.hostNonProfitName || event.hostFullName}
          </HostName>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid>
              {getEventDescription(event.eventType, event.trinityPartyType)}
            </Grid>
            <Grid>
              {(showNotification || showCharityNotification) && (
                <AlertCircleOutline />
              )}
            </Grid>
          </Grid>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
          <Grid container>
            <GridBlock>Host Name:</GridBlock>
            <GridText>{event?.hostFullName}</GridText>
          </Grid>
          <Grid container>
            <GridBlock>Event ID#:</GridBlock>
            <GridText>{event?.exigoPartyId}</GridText>
          </Grid>
          {!isFundraiser && (
            <Grid container>
              <GridBlock>Event Date:</GridBlock>
              <GridText>{eventDate}</GridText>
            </Grid>
          )}
          <Grid container>
            <GridBlock>End Date:</GridBlock>
            <GridText>
              {event.closeDate && (
                <GridText>
                  {DateTime.fromISO(event.closeDate, {
                    zone: timeZone,
                  }).toLocaleString(DateTime.DATE_SHORT)}
                </GridText>
              )}
            </GridText>
          </Grid>
          <Grid container>
            <GridBlock>$ Sales:</GridBlock>
            <GridText>${event?.donationInfo?.ordersSubTotal || 0}</GridText>
          </Grid>
          <Grid container>
            <GridBlock>Nonprofit:</GridBlock>
            <GridText>
              {event?.donationInfo?.nonProfitV2?.nonProfitName || (
                <NonProfitNotSelected>Not Selected</NonProfitNotSelected>
              )}
            </GridText>
          </Grid>
        </Grid>
      </GridItem>
    );
  }
  return null;
}
