import gql from 'graphql-tag';

const mutation = gql`
  mutation CardButtonClickedMutation($input: CardButtonClickedMutationInput!) {
    cardButtonClicked(input: $input) {
      updatedCard {
        cardTemplateId
      }
      sequenceCardsPublished {
        id
        cardTemplateId
        title
        message
        dateCreated
        dateDeleted
        dateSucceeded
        dateArchived
        dateSaved
        taskSucceeded
        rewardPointBounty
        rewardPointsEarned
        isDeleted
        category
        buttonText
        rewardPointBounty
        rewardPointRedemptionMode
        buttonAction {
          action
          appUrl
          name
          phoneNumber
          body
          emailAddress
          subject
          url
          firstName
          lastName
        }
        image {
          src
          alt
        }
        muxVideo {
          assetId
          playbackId
          ratio
        }
        createContext {
          changeType
          templateName
          triggerType
          userName
          cardRuleContext {
            audienceDirection
            audienceName
          }
        }
        deleteContext {
          changeType
          templateName
          triggerType
          userName
          cardRuleContext {
            audienceDirection
            audienceName
          }
        }
        dynamicDataParams {
          userId
          subjectUserId
        }
      }
    }
  }
`;

export default mutation;
