import React from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  makeStyles,
  Text,
  ThemeType,
  LoaderDark,
} from '@onehope/design-system-v2';

import CareTeamSection from './CareTeamSection';
import USER_HUBSPOT_CARE_TEAMS from '../../../queries/UserHubSpotCareTeam';
import {
  CEDash_UserHubSpotCareTeamsQuery,
  CEDash_UserHubSpotCareTeamsQuery_viewer_user_hubspotCareTeam_businessCoordinatorContactOwner as BusinessCoordinatorType,
  CEDash_UserHubSpotCareTeamsQuery_viewer_user_hubspotCareTeam_cddContactOwner as CDDType,
  CEDash_UserHubSpotCareTeamsQuery_viewer_user_hubspotCareTeam_supportCoordinatorLeadContactOwner as SupportCoordinatorLeadType,
  CEDash_UserHubSpotCareTeamsQuery_viewer_user_hubspotCareTeam_supportCoordinatorContactOwner as SupportCoordinatorType,
} from '../../../queries/generatedTypes/CEDash_UserHubSpotCareTeamsQuery';

const description =
  'Your All in One Care Team can assist you with onboarding, tactical, tutorial, and tech support.';
const chatPath = '/chat';
const supportRequestURL =
  'https://go.onehopewine.com/onehope-wine-support-request';

const useStyles = makeStyles((theme: ThemeType) => ({
  title: {
    fontSize: '18px',
    fontWeight: 420,
    lineHeight: '27px',
    letterSpacing: '0.01em',
  },
  button: {
    backgroundColor: theme.palette.forestGreen.main,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: theme.palette.forestGreen.main,
      opacity: 0.9,
    },
  },
  secondaryButton: {
    marginTop: 8,
    backgroundColor: theme.palette.neutral0.main,
    color: theme.palette.forestGreen.main,
    borderRadius: 12,
    border: `1px solid ${theme.palette.forestGreen.main}`,
    '&:hover': {
      backgroundColor: theme.palette.neutral0.main,
      opacity: 0.9,
    },
  },
}));

export type CareTeamMemberType =
  | BusinessCoordinatorType
  | CDDType
  | SupportCoordinatorLeadType
  | SupportCoordinatorType;

const filteredMembers = (members: Array<CareTeamMemberType>) => {
  return members.filter((o: CareTeamMemberType) => !!o?.fullName);
};

export default function MyCareTeam() {
  const classes = useStyles();

  const { data, loading } = useQuery<CEDash_UserHubSpotCareTeamsQuery>(
    USER_HUBSPOT_CARE_TEAMS,
  );

  const hubspotCareTeam = data?.viewer?.user?.hubspotCareTeam;

  const businessCoordinator = hubspotCareTeam?.businessCoordinatorContactOwner;
  const communityDevelopmentDirector = hubspotCareTeam?.cddContactOwner;
  const supportCoordinatorLead =
    hubspotCareTeam?.supportCoordinatorLeadContactOwner;
  const supportCoordinator = hubspotCareTeam?.supportCoordinatorContactOwner;
  const supportCoordinators = hubspotCareTeam?.supportCoordinators;

  const onClick = (url: string) => {
    window.open(url, '_self');
  };

  const onStartChatClick = () => navigate(chatPath);
  const onSubmitTicketClick = () => onClick(supportRequestURL);

  const businessCoordinators = filteredMembers([businessCoordinator]);
  const communityDevelopmentDirectors = filteredMembers([
    communityDevelopmentDirector,
  ]);

  let displayedSupportCoordinators;
  if (!supportCoordinators || !supportCoordinators.length) {
    displayedSupportCoordinators = filteredMembers([
      supportCoordinatorLead,
      supportCoordinator,
    ]);
  } else {
    displayedSupportCoordinators = filteredMembers(supportCoordinators);
  }

  return (
    <Box>
      <Text className={classes.title}>My Care Team</Text>
      <Box>
        <Box sx={{ pt: 2 }}>
          <Text>{description}</Text>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pb: 10,
              pt: 8,
            }}
          >
            <LoaderDark />
          </Box>
        ) : (
          <>
            <CareTeamSection
              title="Business Coordinator"
              members={businessCoordinators}
              bottomBorder
            />
            <CareTeamSection
              title="Community Development Director"
              members={communityDevelopmentDirectors}
              bottomBorder
            />
            <CareTeamSection
              title="Support Coordinators"
              members={displayedSupportCoordinators}
            />
          </>
        )}
        <Button className={classes.button} fullWidth onClick={onStartChatClick}>
          Start a chat
        </Button>
        <Button
          className={classes.secondaryButton}
          fullWidth
          onClick={onSubmitTicketClick}
        >
          Submit a support ticket
        </Button>
      </Box>
    </Box>
  );
}
