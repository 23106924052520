import React from 'react';
import styled from '@emotion/styled';

import { MuiButton } from '@onehope/design-system-ohw';
import { styles } from '@onehope/design-system';
import {
  useTheme,
  useMediaQuery,
  Dialog as DialogRoot,
  DialogContent,
} from '@onehope/design-system-v2';

const {
  cssConstants: { textColor },
} = styles;

const ImportSuccessDialog = styled(DialogRoot)`
  &.MuiDialog-root {
  }
`;

const ShowContactsButton = styled(MuiButton)`
  && {
    margin: 1rem auto;
  }
`;

const Title = styled.div`
  height: 42px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${textColor};
  margin-bottom: 24px;
`;

const DialogText = styled.p`
  text-align: center;
`;

export interface ImportSuccessModalProps {
  open: boolean;
  toggleImportSuccessDialog: () => void;
  successMetrics: {
    results: number;
    skipped: number;
    errors: number;
  };
}

const ImportSuccessModal = ({
  open,
  toggleImportSuccessDialog,
  successMetrics,
}: ImportSuccessModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { results, skipped, errors } = successMetrics;

  const handleLoadContacts = () => {
    return (window.location.href = '/contacts');
  };

  return (
    <ImportSuccessDialog
      fullScreen={fullScreen}
      disableEnforceFocus
      open={open}
      onClose={toggleImportSuccessDialog}
      maxWidth="xs"
    >
      <DialogContent>
        <Title>Hooray!</Title>
        <DialogText>{results} contacts were imported successfully</DialogText>
        {!!skipped ? (
          <DialogText>{skipped} duplicates were ignored</DialogText>
        ) : null}
        {!!errors ? (
          <DialogText>{errors} contacts failed to import</DialogText>
        ) : null}
        <ShowContactsButton
          type="PRIMARY"
          fullWidth
          onClick={handleLoadContacts}
        >
          Show Contacts
        </ShowContactsButton>
      </DialogContent>
    </ImportSuccessDialog>
  );
};

export default ImportSuccessModal;
