import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { FormikProps, FastField } from 'formik';
import get from 'lodash/get';
import { EventHostingTypeEnum } from '../../EventsV2/eventTypes';

import {
  Box,
  Button as MuiButton,
  Grid,
  Loader,
  LoaderDark,
  TEXT_DS,
  Text,
} from '@onehope/design-system-v2';
import { styles } from '@onehope/design-system';

import { RadioButton, RadioButtonGroup } from '../../../common/RadioButton';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';

import { GET_EXPIRED_EVENT_CREDITS_QUERY } from '../../../queries/Credits';

import {
  DetailsTitle,
  StraightLine,
  TabContentContainer,
  ToggleLabel,
} from '../../Event/TabContent/index.styles';
import { FormValues } from '../../Event/TabContent/Wine/FormTypes';
import { BalanceContainer, BalanceHeader } from './index.styles';
import {
  getCreditOptions,
  hasAvailableCredits,
} from '../../Event/TabContent/Wine/helpers';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import useToggle from '../../../utils/useToggleHook';
import ExpiredCreditDialog from '../../Dialog/ExpiredCreditDialog';
import { isVirtual } from '../../../utils/utils';
import { optionsMap } from '../../Event/TabContent/Wine/helpers';

//#region Styles

const { styled: s } = styles;

const Padding40 = styled.div`
  padding-bottom: 16px;
`;

const Container = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0 16px;
`;

const SaveButtons = styled.div`
  //margin-top: 16px;
`;

const Spacer = styled.div`
  margin-bottom: 8px;
`;

const ErrorNotification = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #ff4d4f;
  background-color: #fff5f5;
  padding: 14px 22px;
  margin-bottom: 40px;
`;

//#region WineForm component

interface WineFormProps {
  handlePreviousStepChange: any;
  editing: boolean;
  toggleEdit: () => void;
  numberOfAvailableCredits: number;
  numCreditsUsed: number;
  event: EventType;
  starterKitCredits: number;
  errorNotification: string;
  loadingEventCreation: boolean;
  setErrorNotification: (error: string | null) => void;
  starterKitCreditsUsed?: number;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

export default function WineForm(
  props: FormProps & FormikProps<FormValues> & WineFormProps,
) {
  const {
    editing,
    errorNotification,
    errors,
    handlePreviousStepChange,
    loadingEventCreation,
    mutationLoading,
    numberOfAvailableCredits,
    numCreditsUsed,
    starterKitCredits,
    starterKitCreditsUsed,
    touched,
    values,
    handleReset,
    handleSubmit,
    setErrorNotification,
    setErrors,
    setFieldTouched,
    setFieldValue,
  } = props;
  const {
    hostKitCredits,
    hostKitPurchaser,
    hostKitQuantity,
    useCredit,
    wineNeeded,
  } = values;
  const { refetch: checkExpiredEventCredits } = useQuery(
    GET_EXPIRED_EVENT_CREDITS_QUERY,
    {
      variables: { eventId: props.event?.eventId, numberOfCredits: 0 },
    },
  );

  // check if event is virtual
  const trinityPartyType =
    localStorage.getItem('trinityPartyType') || props?.event?.trinityPartyType;
  const isVirtualEvent = isVirtual(trinityPartyType);

  const event = editing
    ? props.event
    : ({ trinityPartyType, createDate: new Date().toString() } as EventType);

  const {
    value: dialogOpen,
    setTrue: setDialogOpen,
    setFalse: setDialogClosed,
  } = useToggle();

  const checkDisable = () => {
    if (useCredit === 'NO_WINE' && !orderingWine) return false;
    if (hostKitCredits === '' && orderingWine) return true;
    if (orderingWine && !isVirtualEvent) {
      return (
        (!hostKitPurchaser && !selfHost) ||
        (!selfHost && !hostKitCreditsSelected)
      );
    }
    if (editing) return wineNeeded === 'SKIP';
    return wineNeeded === '';
  };

  const onSubmit = async () => {
    if (!isVirtualEvent) {
      const hostKitCreditsInt = parseInt(
        optionsMap[hostKitCredits].hostKitCredits,
        10,
      );
      // if un-applying credits, check if expired
      if (numCreditsUsed > hostKitCreditsInt) {
        let expired = false;
        const numberOfCredits = numCreditsUsed - hostKitCreditsInt;
        await checkExpiredEventCredits({
          eventId: props.event?.eventId,
          numberOfCredits,
        }).then((response) => {
          expired = get(response, 'data.viewer.user.checkExpiredEventCredits');
        });
        if (expired) {
          return setDialogOpen();
        }
      }
    }
    return handleSubmit();
  };

  const availableCredits = hasAvailableCredits({
    numberOfAvailableCredits,
    numCreditsUsed,
  });
  const orderingWine =
    (numberOfAvailableCredits === 0 && wineNeeded !== 'NO') ||
    (useCredit !== 'NO_WINE' &&
      useCredit !== '' &&
      useCredit !== 'STARTER-KIT');

  const creditOptions = getCreditOptions({
    numberOfAvailableCredits,
    numCreditsUsed,
    useCredit,
    starterKitCredits,
  });

  const hostKitQuantitySelected = orderingWine && !!hostKitQuantity;
  const hostKitCreditsSelected =
    hostKitPurchaser && orderingWine && (!!hostKitCredits || !creditOptions);
  const hostType = localStorage.getItem('hostType') || '';
  const selfHost =
    hostType === EventHostingTypeEnum.Self ||
    (!!props.event && props.event?.accountId === props.event?.hostAccountId);
  const disableNext = checkDisable();
  const noCreditsAvailableForEventCreation =
    numberOfAvailableCredits === 0 && creditOptions.length > 0;

  useEffect(() => {
    if (editing) {
      if (numCreditsUsed > 0) {
        setFieldValue('useCredit', 'YES');
      } else {
        setFieldValue('useCredit', 'NO');
      }
    }
  }, []); // Runs only once because of empty dependency array

  useEffect(() => {
    if (editing) {
      if (numCreditsUsed === 0 && hostKitQuantity === '6') {
        setFieldValue('hostKitCredits', '0');
      } else if (numCreditsUsed === 0 && hostKitQuantity === '') {
        setFieldValue('hostKitCredits', 'NO_WINE');
        setFieldValue('wineNeeded', 'NO');
      } else if (numCreditsUsed === 1 && hostKitQuantity === '6') {
        setFieldValue('hostKitCredits', '2');
      } else if (numCreditsUsed === 0 && hostKitQuantity === '12') {
        setFieldValue('hostKitCredits', '1');
      } else if (numCreditsUsed === 1 && hostKitQuantity === '12') {
        setFieldValue('hostKitCredits', '3');
      } else if (numCreditsUsed === 2 && hostKitQuantity === '12') {
        setFieldValue('hostKitCredits', '4');
      }
    }
  }, []); // Runs only once because of empty dependency array

  useEffect(() => {
    localStorage.setItem('wineNeeded', wineNeeded ? wineNeeded : '');
    setFieldTouched('wineNeeded', true, false);
    setErrorNotification(null);
    setErrors({});
  }, [wineNeeded, setFieldTouched, setErrorNotification, setErrors]);

  useEffect(() => {
    localStorage.setItem(
      'hostKitQuantity',
      hostKitQuantity ? hostKitQuantity.toString() : '',
    );
    setFieldTouched('hostKitQuantity', true, false);
  }, [hostKitQuantity, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'hostKitCredits',
      hostKitCredits
        ? hostKitCredits.toString()
        : localStorage.getItem('hostKitCredits') || '',
    );
    // setFieldTouched('hostKitCredits', true, false);
    if (hostKitCredits === 'NO_WINE') {
      setFieldValue('wineNeeded', 'NO');
      setFieldValue('hostKitPurchaser', null);
      setFieldValue('useCredit', 'NO_WINE');
    } else {
      setFieldValue('wineNeeded', 'YES');
    }
  }, [hostKitCredits, setFieldTouched, setFieldValue]);

  useEffect(() => {
    localStorage.setItem('useCredit', useCredit ? useCredit.toString() : '');
    setFieldTouched('useCredit', true, false);
    if (useCredit === 'NO_WINE') {
      setFieldValue('wineNeeded', 'NO');
      setFieldValue('hostKitCredits', 'NO_WINE');
    } else if (useCredit === 'STARTER-KIT') {
      setFieldValue('wineNeeded', 'NO');
      setFieldValue('hostKitCredits', 'STARTER-KIT');
    } else {
      setFieldValue('wineNeeded', 'YES');
      if (!editing && availableCredits) {
        setFieldValue('hostKitCredits', '');
      }
    }
  }, [availableCredits, editing, useCredit]);

  useEffect(() => {
    localStorage.setItem(
      'hostKitPurchaser',
      hostKitPurchaser ? hostKitPurchaser : '',
    );
    setFieldTouched('hostKitPurchaser', true, false);
  }, [hostKitPurchaser, setFieldTouched]);

  const onCancel = () => {
    handleReset();
    if (editing && props.toggleEdit) {
      props.toggleEdit();
    }
  };

  //#region HTML

  return (
    <form onSubmit={onSubmit}>
      <TabContentContainer>
        <ExpiredCreditDialog
          open={dialogOpen}
          setClose={setDialogClosed}
          saveChange={handleSubmit}
          mutationLoading={mutationLoading}
        />
        <>
          {editing ? (
            <Grid container alignItems="center">
              <Grid>
                <DetailsTitle
                  variant="custom"
                  default={TEXT_DS.BODY_SEMIBOLD_16}
                >
                  {'Edit Wine Details'}
                </DetailsTitle>
              </Grid>
              <Grid item xs={12}>
                <StraightLine />
              </Grid>
            </Grid>
          ) : (
            <Text variant="h3">Wine Details</Text>
          )}
          <BalanceContainer editing={editing}>
            <BalanceHeader variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
              Balance:
            </BalanceHeader>
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              {numberOfAvailableCredits + numCreditsUsed} free event kit credits
              available
            </Text>
          </BalanceContainer>
          {loadingEventCreation ? (
            <Grid container alignItems="center" justifyContent="center">
              <Box sx={{ mt: 10 }}>
                <LoaderDark size={50} />
              </Box>
            </Grid>
          ) : (
            <>
              {availableCredits && (
                <div>
                  <ToggleLabel>
                    Do you want to use an available credit?
                  </ToggleLabel>
                  <RadioButtonGroup
                    id="hostKitCreditsGroup"
                    label=""
                    value={numberOfAvailableCredits === 0 ? 'NO' : useCredit}
                    error={errors.useCredit}
                    touched={touched.useCredit}
                  >
                    <RadioButtonGroup
                      id="useCreditGroup"
                      label=""
                      value={numberOfAvailableCredits === 0 ? 'NO' : useCredit}
                      error={errors.useCredit}
                      touched={touched.useCredit}
                    >
                      <FastField
                        component={RadioButton}
                        name="useCredit"
                        id="YES"
                        label="Yes"
                      />
                      <FastField
                        component={RadioButton}
                        name="useCredit"
                        id="NO"
                        label="No"
                      />
                      <FastField
                        component={RadioButton}
                        name="useCredit"
                        id="NO_WINE"
                        label="I don't need wine"
                      />
                    </RadioButtonGroup>
                  </RadioButtonGroup>
                  <Padding40 />
                </div>
              )}
              {(noCreditsAvailableForEventCreation ||
                (useCredit && orderingWine)) && (
                <div>
                  <ToggleLabel>
                    How many bottles of wine do you need?
                  </ToggleLabel>
                  <RadioButtonGroup
                    id="hostKitCreditsGroup"
                    label=""
                    value={hostKitCredits}
                    error={errors.hostKitCredits}
                    touched={touched.hostKitCredits}
                  >
                    {creditOptions.map((option) => (
                      <FastField
                        component={RadioButton}
                        name="hostKitCredits"
                        key={`${option.key}_${option.label}`}
                        id={option.id}
                        label={option.label}
                      />
                    ))}
                  </RadioButtonGroup>
                  <Padding40 />
                </div>
              )}
              {orderingWine && !selfHost && hostKitCredits !== '' && (
                <div>
                  <ToggleLabel>Who will select the wine?</ToggleLabel>
                  <RadioButtonGroup
                    id="hostKitPurchaserGroup"
                    label=""
                    value={hostKitPurchaser}
                    error={errors.hostKitPurchaser}
                    touched={touched.hostKitPurchaser}
                  >
                    <FastField
                      component={RadioButton}
                      name="hostKitPurchaser"
                      id="CE"
                      label="I will"
                    />
                    <FastField
                      component={RadioButton}
                      name="hostKitPurchaser"
                      id="HOST"
                      label="The Host will"
                    />
                  </RadioButtonGroup>
                </div>
              )}
              {errorNotification && (
                <ErrorNotification>{errorNotification}</ErrorNotification>
              )}
            </>
          )}
          {editing ? (
            <SaveButtons>
              <Grid container>
                <Grid item xs={12}>
                  <MuiButton
                    type="primary"
                    fullWidth
                    disableRipple
                    disabled={
                      mutationLoading ||
                      disableNext ||
                      isEmpty(touched) ||
                      !isEmpty(errors)
                    }
                    onClick={mutationLoading ? () => {} : onSubmit}
                  >
                    {mutationLoading ? <Loader /> : 'SAVE CHANGES'}
                  </MuiButton>{' '}
                </Grid>
              </Grid>
              <Spacer />
              <Grid item>
                <MuiButton
                  type="secondary"
                  disableRipple
                  fullWidth
                  onClick={onCancel}
                >
                  CANCEL
                </MuiButton>
              </Grid>
            </SaveButtons>
          ) : (
            <FooterButtons
              prevStepButtonText="BACK"
              nextStepButtonText="BOOK EVENT"
              handleNextStepChange={onSubmit}
              loadingEventCreation={loadingEventCreation}
              handlePrevStepChange={handlePreviousStepChange}
              disableNext={disableNext}
            />
          )}
        </>
      </TabContentContainer>
    </form>
  );
}
