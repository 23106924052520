import React from 'react';
import { navigate } from 'gatsby';
import {
  Grid,
  Icons,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';

import { CEDash_EventDetailsPage_viewer_event } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { GridContainer, Notification, Link, ClosedEvent } from './index.styles';
import {
  getHostKitsV2,
  getEventNonProfit,
  getShowNotification,
} from '../../../utils/utils';
import { EventStatusDictionary } from '../../../utils/enums';
import { getWineNeeded } from '../TabContent/Wine/helpers';
import styled from '@emotion/styled';
import { EventManagementTabs } from '../TabNavigation/tabConstants';

const { AlertCircleOutline } = Icons;

export const Alert = styled(AlertCircleOutline)`
  && {
    margin-right: 8px;
  }
`;

interface HeaderProps {
  event: CEDash_EventDetailsPage_viewer_event;
}

export function getDays(date: string | null) {
  if (!date) return 0;
  let oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  const eventDateAsDate = new Date(date);
  return Math.round((eventDateAsDate.getTime() - today.getTime()) / oneDay);
}

export default function Notifications(props: HeaderProps) {
  const { event } = props;
  const handleRouteChange = (
    e: React.FormEvent<EventTarget>,
    value: string,
  ) => {
    e.preventDefault();
    return navigate(
      `/event?eventId=${event?.eventId}&tab=${value}&scroll=${value ===
        EventManagementTabs.SUMMARY}`,
    );
  };

  const getEventDescription = (charitySelected: boolean) => {
    if (!charitySelected) {
      if (daysUntilEvent > 0) {
        if (daysUntilEvent === 1) {
          return `${daysUntilEvent} day until event: `;
        } else if (daysUntilEvent <= 15) {
          return `${daysUntilEvent} days left until event: `;
        }
      } else if (daysUntilClose > 0 && daysUntilClose <= 15)
        return daysUntilClose == 1
          ? `Event will close for orders in ${daysUntilClose} day:`
          : `Event will close for orders in ${daysUntilClose} days: `;
      else return 'Nonprofit details needed to close event: ';
    } else if (daysUntilClose <= 5 && daysUntilClose > 0) {
      return daysUntilClose == 1
        ? `Event will close for orders in ${daysUntilClose} day:`
        : `Event will close for orders in ${daysUntilClose} days: `;
    } else {
      if (daysUntilEvent > 0 && daysUntilEvent <= 15) {
        if (daysUntilEvent === 1) {
          return `${daysUntilEvent} day until event: `;
        }
        return `${daysUntilEvent} days left until event: `;
      }
      return null;
    }
  };

  const getWineDescription = () => {
    if (daysUntilEvent <= 15) {
      if (daysUntilEvent === 1) {
        return `${daysUntilEvent} day until event: `;
      }
      return `${daysUntilEvent} days left until event: `;
    }
  };

  const getCharityDetailsLink = () => {
    if (daysUntilEvent > 0 || daysUntilClose > 0) {
      if (isMobile) return 'Select a nonprofit';
      return 'Enter nonprofit details';
    }
    return 'Enter now';
  };

  const { eventDate, trinityPartyStatus, donationInfo, closeDate } = event;
  const daysUntilEvent = getDays(eventDate);
  const daysUntilClose = getDays(closeDate);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const notAcceptingOrders =
    trinityPartyStatus === EventStatusDictionary.CLOSED;
  const openEvent = event?.status === EventStatusDictionary.OPEN;
  const showEventStatusChangeText = notAcceptingOrders || !openEvent;
  let closedEventText =
    notAcceptingOrders && !openEvent
      ? 'This event has closed'
      : 'No longer accepting orders';
  const nonProfit = getEventNonProfit(donationInfo);
  const charitySelected = !!nonProfit;

  // Temporary for launch
  const isMigrating = !event?.status;
  if (isMigrating) {
    closedEventText = 'Please have patience while this event is migrated';
  }

  const hostKitsV2 = true;
  const showHostKitsV2 = getHostKitsV2(hostKitsV2, event);

  const wineNeeded = getWineNeeded(
    event.hostKitQuantity,
    event.starterKitCredits,
    event.hostKitPurchaser,
  );

  const pendingWineInformation =
    (!event.wineTastingOrderIds?.length && wineNeeded === 'YES') ||
    wineNeeded === 'SKIP';
  const showWineNotification =
    showHostKitsV2 &&
    pendingWineInformation &&
    daysUntilEvent <= 15 &&
    daysUntilEvent > 0;
  const showDaysUntilEventNotification =
    daysUntilEvent <= 5 && daysUntilEvent > 0;
  const showDaysUntilCloseNotification =
    daysUntilClose <= 5 && daysUntilClose > 0;
  const showNotifications =
    openEvent &&
    (getShowNotification(event) ||
      showDaysUntilEventNotification ||
      showDaysUntilCloseNotification);
  // (
  //   showDaysUntilEventNotification ||
  //     showDaysUntilCloseNotification ||
  //     showWineNotification,
  // ) &&
  // openEvent;

  const eventDescription = getEventDescription(charitySelected);
  // const wineDescription = getWineDescription();

  const charityDetailsLink = getCharityDetailsLink();
  // calculate wine link text based on
  // trinityPartyStatus for virtual / non
  const wineLink = 'Order wine';

  if (
    !showNotifications &&
    !showEventStatusChangeText &&
    !showDaysUntilEventNotification &&
    !showDaysUntilCloseNotification
  )
    return null;
  return (
    <>
      <GridContainer container>
        {showNotifications && (
          <Grid container>
            <Notification>
              {eventDescription && (
                <Grid container direction={isMobile ? 'column' : 'row'}>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Alert />
                      {eventDescription}
                    </Grid>
                  </Grid>
                  {/* eslint-disable react/jsx-no-bind */}
                  {(!charitySelected || showWineNotification) && (
                    <Grid item>
                      <Grid container direction={isMobile ? 'column' : 'row'}>
                        {showWineNotification && (
                          <>
                            <Link
                              onClick={(e) =>
                                handleRouteChange(
                                  e,
                                  EventManagementTabs.SUMMARY,
                                )
                              }
                            >
                              {wineLink}
                            </Link>
                            {!charitySelected && !isMobile && <>•</>}
                          </>
                        )}
                        {!charitySelected && (
                          <Link
                            onClick={(e) =>
                              handleRouteChange(e, EventManagementTabs.CHARITY)
                            }
                          >
                            {charityDetailsLink}
                          </Link>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Notification>
          </Grid>
        )}
        <Grid item>
          {showEventStatusChangeText && (
            <ClosedEvent>{closedEventText}</ClosedEvent>
          )}
        </Grid>
      </GridContainer>
    </>
  );
}
