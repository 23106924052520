import * as React from 'react';
import LuxonUtils from '@date-io/luxon';
import {
  MuiPickersUtilsProvider,
  DateTimePickerProps,
} from '@material-ui/pickers';
import { Label, MuiDateTimePicker, DateIcon } from './input.styles';

type DateTimePickerContainerProps = DateTimePickerProps & {
  label?: string;
};

export default function DatePickerContainer(
  props: DateTimePickerContainerProps,
) {
  const { label, ...rest } = props;
  return (
    <React.Fragment>
      {label && (
        <div>
          <Label htmlFor={props.name}>{label}</Label>
        </div>
      )}
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <MuiDateTimePicker keyboardIcon={<DateIcon />} {...rest} />
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}
