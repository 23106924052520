import _orderBy from 'lodash/orderBy';

import {
  CreditsPage_user_credits_edges_node as CreditType,
  CreditsPage_user_credits_edges as CreditsType,
} from '../../../queries/generatedTypes/CreditsPage_user';

export const TabListValues = {
  available: 'available',
  redeemed: 'redeemed',
  expired: 'expired',
};

export function creditIsAvailable(c: CreditType) {
  return c?.status === TabListValues.available;
}

export function creditIsRedeemed(c: CreditType) {
  return c?.status === TabListValues.redeemed;
}

export function creditIsExpired(c: CreditType) {
  return c?.status === TabListValues.expired;
}

export function handleFilterByTab(
  credits: (CreditsType | null)[] | null,
  filterBy: string,
) {
  if (credits) {
    let filteredCredits = credits;
    switch (filterBy) {
      case TabListValues.available:
        filteredCredits = credits.filter(c => !creditIsAvailable(c.node));
        filteredCredits = _orderBy(
          filteredCredits,
          [c => c?.node?.dateCreated],
          ['desc'],
        );
        break;
      case TabListValues.redeemed:
        filteredCredits = credits.filter(c => creditIsRedeemed(c.node));
        filteredCredits = _orderBy(
          filteredCredits,
          [c => c?.node?.dateCreated],
          ['desc'],
        );
        break;
      case TabListValues.expired:
        filteredCredits = credits.filter(c => creditIsExpired(c.node));
        filteredCredits = _orderBy(
          filteredCredits,
          [c => c?.node?.dateCreated],
          ['desc'],
        );
        break;
      default:
        filteredCredits = _orderBy(
          credits,
          [e => e?.node?.dateCreated],
          ['desc'],
        );
    }
    return filteredCredits;
  }
  return credits;
}

export const isCreditExpired = credit => {
  const now = new Date();
  const expirationDate = new Date(credit?.expirationDate);
  return credit?.node?.status === 'available' && now > expirationDate;
};
