import React, { useState, ChangeEvent } from 'react';
import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system';
import {
  Icons,
  IconButton,
  Menu,
  MenuItem as MuiMenuItem,
} from '@onehope/design-system-v2';
import { CustomerAutoshipsContactsV2_viewer_user_customerAutoships_autoships } from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsContactsV2';

const { cssConstants } = s;
const { MoreVertIcon: MuiMoreVertIcon } = Icons;

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
`;

const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 8px 16px;
    font-family: ${cssConstants.font};
    color: ${cssConstants.textColor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
`;

const MoreVertIcon = styled(MuiMoreVertIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

interface IconMenuProps {
  autoship: CustomerAutoshipsContactsV2_viewer_user_customerAutoships_autoships;
  handleManageAutoship: (autoshipId: string, contactId: string) => void;
}

export const IconMenu = ({ autoship, handleManageAutoship }: IconMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ horizontal: -75, vertical: 'top' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key="Manage Autoship"
          onClick={() =>
            handleManageAutoship(autoship.autoShipId, autoship.contactId)
          }
        >
          Manage
        </MenuItem>
      </Menu>
    </Container>
  );
};
