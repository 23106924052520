import React, { useContext, createContext } from 'react';
import useToggle from '../../utils/useToggleHook';

type CheckBoxToggleContextProviderProps = {
  isCheckBoxActivated: boolean;
  setCheckBoxActivated: () => void;
  setCheckBoxDeActivated: () => void;
};

// @ts-ignore: An argument for 'defaultValue' was not provided.
export const CheckBoxToggleContext = createContext<
  CheckBoxToggleContextProviderProps
>();

export const useCheckBoxToggleContext = () =>
  useContext<CheckBoxToggleContextProviderProps>(CheckBoxToggleContext);

export const CheckBoxToggleContextProvider = ({
  children,
}: {
  children: any;
}) => {
  const {
    value: isCheckBoxActivated,
    setTrue: setCheckBoxActivated,
    setFalse: setCheckBoxDeActivated,
  } = useToggle();

  return (
    <CheckBoxToggleContext.Provider
      value={{
        isCheckBoxActivated,
        setCheckBoxActivated,
        setCheckBoxDeActivated,
      }}
    >
      {children}
    </CheckBoxToggleContext.Provider>
  );
};
