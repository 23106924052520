import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  Dropzone,
  GridV2 as Grid,
  Icons,
  LoaderDark,
  makeStyles,
  ThemeType,
  ButtonBase,
  Text,
  TEXT_DS,
} from '@onehope/design-system-v2';
import { getEventImage, getDefaultEventImage } from '../../../utils/utils';
import { useEventImageStyles } from '../commonStyles';
import IMAGE_DELETE_MUTATION from '../../../mutations/Image/ImageDeleteMutation';

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '16px 0',
  },
  container: {
    position: 'relative',
  },
  button: {
    background: 'rgba(26, 26, 26, 0.8)',
    borderRadius: 4,
    padding: '2px 10px',
    marginTop: '100%',
  },
  text: {
    color: 'white',
  },
  dropzone: {
    position: 'absolute',
    cursor: 'pointer',
    right: 16,
    bottom: 56,
  },
  loader: { padding: '25% 50%' },
  // loader: {
  //   padding: '24px',
  //   width: 40,
  //   height: 36,
  //   ['@media (min-width: 768px)']: {
  //     width: 80,
  //     height: 72,
  //   },
  // },
  noImage: {
    width: '100%,',
    border: '1px dashed #C2C6C9',
    verticalAlign: 'middle',
    marginBottom: 8,
    borderRadius: 4,
    height: 280,
  },
  edit: {
    position: 'absolute',
    margin: '-10px 0px 0 164px',
    cursor: 'pointer',
  },
}));

export default function UpdateImage(props: any) {
  const { onFileDrop, event, loading, setIsImageUpdated } = props;
  const defaultImageUrl = getDefaultEventImage({ event });
  const eventImage = event?.images?.[0];
  const imageUrl = getEventImage(eventImage) || defaultImageUrl;
  const classes = useStyles();
  const imageClasses = useEventImageStyles(imageUrl)();

  const [imageDeleteMutation, { loading: imageDeleteLoading }] = useMutation(
    IMAGE_DELETE_MUTATION,
  );

  const deleteImage = () => {
    return imageDeleteMutation({
      variables: {
        input: {
          imageId: eventImage.imageId,
        },
      },
    })
      .then((data: any) => {
        console.log({ data });
        if (setIsImageUpdated) {
          setIsImageUpdated(true);
        }
      })
      .catch((error: any) => {
        console.log({ error });
      });
  };

  return (
    <div>
      <Grid container className={classes.container}>
        <Dropzone
          className={classes.dropzone}
          accept="image/jpg, image/jpeg, image/png"
          onDrop={onFileDrop}
          maxFiles={1}
          multiple={false}
          maxSize={16000000} // in bytes (16 MB)
        >
          {!loading && imageUrl && (
            <ButtonBase className={classes.button}>
              <Text
                variant="custom"
                className={classes.text}
                default={TEXT_DS.BODY_SEMIBOLD_14}
              >
                Change Cover
              </Text>
            </ButtonBase>
          )}
        </Dropzone>
        {loading || imageDeleteLoading ? (
          <div className={classes.loader}>
            <LoaderDark />
          </div>
        ) : imageUrl ? (
          <div className={imageClasses.image} />
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.noImage}
          >
            <Grid item>
              <Icons.CeDashEmptyPhoto />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
