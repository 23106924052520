export const EventCreateSteps = {
  DETAILS: 'details',
  IMAGE: 'imageUpload',
  WINE: 'wine',
};

export const EventCreateStepOrder = [
  EventCreateSteps.DETAILS,
  EventCreateSteps.IMAGE,
  EventCreateSteps.WINE,
];
