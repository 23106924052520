import React from 'react';
import { DateTime } from 'luxon';

import { Text, TEXT_DS, Grid } from '@onehope/design-system-v2';

import { isJsonString } from '../helpers';
import QAndA from './QAndA';

import { CEDash_ReachOutResults_viewer_v2_reachOutResults } from '../../../queries/generatedTypes/CEDash_ReachOutResults';

export interface ContactResultsDisplayProps {
  result: CEDash_ReachOutResults_viewer_v2_reachOutResults;
}

function ContactResultsDisplay({ result }: ContactResultsDisplayProps) {
  const {
    simpleContact: { firstName, lastName },
    reachOutRequest: { response, lastModifiedDate },
  } = result;
  const contactName = [firstName, lastName].join(' ');

  const parsedResponse =
    (response && isJsonString(response) && JSON.parse(response)) || {};

  const dateQuizCompleted = DateTime.fromISO(lastModifiedDate).toFormat(
    'dd MMMM yy',
  );

  const timeQuizCompleted = DateTime.fromISO(lastModifiedDate).toLocaleString(
    DateTime.TIME_SIMPLE,
  );

  const answers = parsedResponse.answers || [];
  const definitions = parsedResponse.definition?.fields || [];

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item container direction="column">
        <Grid item>
          <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
            {contactName}
          </Text>
        </Grid>
        <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {dateQuizCompleted}
        </Text>
        <Grid item>
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {timeQuizCompleted}
          </Text>
        </Grid>
      </Grid>
      {answers.map((answer, index) => {
        const definition = definitions[index];
        return (
          <Grid key={`QandA-${index}`} item>
            <QAndA definition={definition} answer={answer} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default ContactResultsDisplay;
