import { getPageName } from './helpers';
import { ManualPageViewOptions } from './manualPageView';
import { Segment_UserQuery_viewer_user } from '../queries/generatedTypes/Segment_UserQuery';

export type SegmentUser = Segment_UserQuery_viewer_user;

export type SegmentInput = {
  user: SegmentUser;
  isLoggedIn?: boolean | null;
  isCeDashboard?: boolean;
};

export type SegmentUserInput = {
  user: SegmentUser;
};

export type SegmentDefaultInput = {
  isCeDashboard?: boolean;
};

export const segmentGaSessionID = '_ga_99QLETVG9M';

/**
 * Simple function to reformat phone numbers consistently for Segment
 */
const formatPhone = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
};

/**
 * Helper function to set the Segment User ID.
 *
 * Note: this is the V2 version of this method. This method
 * will NOT pass a userId to Segment if the user is anonymous
 */
export const segmentCookieV2 = ({ user, isLoggedIn }: SegmentInput): void => {
  const { userId } = user;
  if (analytics.user) {
    if (isLoggedIn) {
      analytics.user().id(userId);
    } else {
      // set to null here in case it has been set to something somewhere else
      analytics.user().id(null);
    }
  }
};

/**
 * Helper function to trigger Segment Identify for logged-in users.
 */
export const segmentIdentify = ({ user }: SegmentUserInput): void => {
  const {
    userId,
    email,
    firstName,
    lastName,
    mobilePhone,
    trinityDealerId,
    trinityEnrollDate,
    exigoCustomerId,
    pointBalance,
    shoppingWithCeoUser,
    permissions,
    orders,
  } = user;
  // get the orders so we can manipulate them
  const ordersArray = orders?.edges || [];
  // count the number of individual orders to create a totalOrders count
  const totalOrders = ordersArray.length || 0;
  // sum the value of all orders to create a totalOrderValue total
  const totalOrderValue = ordersArray.reduce(
    // @ts-ignore
    (acc: number, { node }) => acc + parseFloat(node?.orderTotal || '0'),
    0,
  );

  let sessionID = '';
  let clientID: any = '';

  const getCookie = (name: string) => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
  };
  const sessionIDCookie = getCookie(segmentGaSessionID);
  if (sessionIDCookie) {
    sessionID = sessionIDCookie.split('.')[2];
  }
  clientID = getCookie('_ga');

  if (userId && userId.indexOf('.') === -1) {
    analytics.identify(userId?.toString(), {
      email,
      firstName,
      lastName,
      mobilePhone: formatPhone(mobilePhone || ''),
      trinityDealerId,
      trinityEnrollDate,
      exigoCustomerId,
      pointBalance,
      totalOrders: totalOrders || 0,
      totalOrderValue: totalOrderValue || 0,
      // Is a wine club subscriber; Includes 20/20 collective members
      is_wine_club_member:
        permissions?.oneCommerce?.subscriber?.access || false,
      // 4-bottle grandfathered WC
      is_legacy_wine_club_member:
        permissions?.oneCommerce?.legacySubscriber?.access || false,
      // Is Logged in as a CE
      is_ce: permissions?.oneCommerce?.ceo?.access || false,
      // Is shopping with a CE
      is_attached_to_ce: !!shoppingWithCeoUser?.id,
      // Has placed an order before
      is_customer: !!orders?.edges?.[0]?.node?.orderId,
      ga_client_id: clientID,
      ga_session_id: sessionID,
    });
  } else {
    // If they aren't logged in, we can still identify if they are shopping with someone.
    analytics.identify({
      is_attached_to_ce: !!shoppingWithCeoUser?.id,
      ga_client_id: clientID,
      ga_session_id: sessionID,
    });
  }
};

/**
 * Helper function to trigger default Segment Page View (with no additional properties)
 * other than the HTML name.
 *
 * Leave HTML name empty for funnels and certain apps (CE Dashboard)
 *
 */
export const defaultSegmentPageView = ({
  isCeDashboard,
}: SegmentDefaultInput): void => {
  const page = getPageName({ isCeDashboard });
  analytics.page(page);
};

/**
 * Helper function to trigger custom Segment Page View custom HTML name.
 */
export const customSegmentPageView = (options: ManualPageViewOptions): void => {
  const { page, ...restOfOptions } = options;
  analytics.page(page, restOfOptions);
};
