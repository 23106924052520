import { CharityTypeDictionary } from '../../../../utils/enums';
import { getEventNonProfit } from '../../../../utils/utils';

interface Address {
  addressLineOne: string;
  city: string;
  state?: string;
  zip: string;
}

export const getCharityType = (donation: any) => {
  if (donation?.nonProfit?.isPreApproved) {
    return CharityTypeDictionary.OHW_PARTNER;
  } else if (donation?.nonProfit) {
    return CharityTypeDictionary.CUSTOM;
  }
  return CharityTypeDictionary.SKIP;
};

const getCharityAddress = (donation: any) => {
  return {
    addressLineOne: donation?.nonProfit?.nonProfitAddress?.addressLineOne || '',
    city: donation?.nonProfit?.nonProfitAddress?.city || '',
    state: donation?.nonProfit?.nonProfitAddress?.state || '',
    zip: donation?.nonProfit?.nonProfitAddress?.zip || '',
  };
};

export const formatAddress = ({
  addressLineOne,
  city,
  state,
  zip,
}: Address) => {
  if (state) {
    return `${addressLineOne}, ${city}, ${state}, ${zip}`
      .replace(/,+/g, ',')
      .replace(/(,\s*$)|(^,*)/, '');
  }
  return `${addressLineOne}, ${city}, ${zip}`
    .replace(/,+/g, ',')
    .replace(/(,\s*$)|(^,*)/, '')
    .replace(/ , /g, '');
};

export const getDefaultValues = (event: any, donation: any) => {
  const charityType = getCharityType(donation);
  const nonProfitAddressParsed = getCharityAddress(donation);
  const partnerCharity = charityType === CharityTypeDictionary.OHW_PARTNER;
  // NOTE: rest of NPO fields below should remain referencing nonProfit (and not nonProfitV2)
  const nonProfit = getEventNonProfit(donation);
  return {
    charityType,
    ...nonProfitAddressParsed,
    preApprovedNonProfit: partnerCharity ? donation.nonProfit.nonProfitId : '',
    customNonProfit: !partnerCharity ? donation?.nonProfit?.nonProfitName : '',
    nonProfitId: nonProfit?.nonProfitId ? nonProfit.nonProfitId : '',
    nonProfitEditId: donation?.nonProfitEdit?.nonProfitEditId
      ? donation.nonProfitEdit.nonProfitEditId
      : '',
    charityDonationGoal: donation?.donationGoal ? donation.donationGoal : '',
    charityQuote: event?.hostQuote ? event.hostQuote : '',
    attentionToName:
      !partnerCharity && donation?.nonProfit?.nonProfitAddress?.optionalName
        ? donation.nonProfit?.nonProfitAddress?.optionalName
        : '',
    displayAddress:
      !partnerCharity && donation && nonProfitAddressParsed
        ? formatAddress(nonProfitAddressParsed)
        : '',
    addressLineTwo: donation?.nonProfit?.nonProfitAddress?.addressLineTwo
      ? donation?.nonProfit?.nonProfitAddress?.addressLineTwo || ''
      : '',
    nonProfitTaxId:
      !partnerCharity && donation?.nonProfit?.nonProfitTaxId
        ? donation.nonProfit.nonProfitTaxId
        : '',
    lobMemo: donation?.lobMemo ? donation.lobMemo : '',
    showDonationGoal:
      event?.displayPreferences?.donationGoal?.toString() ?? 'false',
  };
};

export const getInitialValues = () => {
  return {
    charityType: localStorage.getItem('charityType') || '',
    preApprovedNonProfit: localStorage.getItem('preApprovedNonProfit') || '',
    customNonProfit: localStorage.getItem('customNonProfit') || '',
    charityDonationGoal: localStorage.getItem('charityDonationGoal') || '',
    charityQuote: localStorage.getItem('charityQuote') || '',
    displayAddress: localStorage.getItem('charityDisplayAddress') || '',
    attentionToName: localStorage.getItem('attentionToName') || '',
    addressLineOne: localStorage.getItem('charityAddressLineOne') || '',
    addressLineTwo: localStorage.getItem('charityAddressLineTwo') || '',
    city: localStorage.getItem('charityCity') || '',
    state: localStorage.getItem('charityState') || '',
    zip: localStorage.getItem('charityZip') || '',
    nonProfitTaxId: localStorage.getItem('nonProfitTaxId') || '',
    lobMemo: localStorage.getItem('lobMemo') || '',
    showDonationGoal: localStorage.getItem('showDonationGoal') || 'false',
    nonProfitId: localStorage.getItem('nonProfitId') || '',
  };
};
