import React, { useEffect, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@apollo/client';

import { GridV2 as Grid, Box, useTheme } from '@onehope/design-system-v2';

import MainCard from '../MainCard';

// utils
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { segmentEvents } from '../../../utils/segment/constants';

import DASHBOARD_FASTSTART_QUERY from '../../../queries/DashboardFastStart';
import { CEDash_FastStartQuery } from '../../../queries/generatedTypes/CEDash_FastStartQuery';
import RetailAwards from './RetailAwards';

interface Props {
  ceAccountId: string;
}

export default function FastStartBonus({ ceAccountId }: Props) {
  const flags = useFlags();

  const { data } = useQuery<CEDash_FastStartQuery>(DASHBOARD_FASTSTART_QUERY);

  const theme = useTheme();

  const fastStartWidgetRef = useRef();
  const { trackEventV2 } = useTrackingContextValue();

  const fastStartData = data?.viewer?.v3?.fastStart23;

  // Tracks when the fast start widget is initially visible
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        observer.unobserve(fastStartWidgetRef.current);
        trackEventV2({
          event: segmentEvents.ceDashboardComponentViewed,
          properties: {
            name: 'Fast Start',
            ceAccountId: ceAccountId,
          },
        });
      }
    });

    observer.observe(fastStartWidgetRef.current);

    return () => {
      observer.disconnect();
    };
  }, [ceAccountId]);

  return (
    <Grid item xs={12} ref={fastStartWidgetRef}>
      {flags?.fastStartNewAwards && (
        <>
          <MainCard
            sx={{
              borderRadius: '12px 12px 0 0',
              position: 'relative',
            }}
          >
            <Header fastStartData={fastStartData} />
            <RetailAwards fastStartData={fastStartData} />
          </MainCard>
          <Box
            sx={{
              border: `1px solid ${theme.palette.cream.main}`,
              width: '100%',
            }}
          />
        </>
      )}
      <MainCard
        sx={{
          borderRadius: flags?.fastStartNewAwards ? '0 0 12px 12px' : '12px',
          position: 'relative',
        }}
      >
        {!flags?.fastStartNewAwards && <Header fastStartData={fastStartData} />}
        <Content fastStartData={fastStartData} />

        <Box
          sx={{
            border: `1px solid ${theme.palette.greyLight.main}`,
            width: '100%',
            marginBottom: '24px',
          }}
        />

        <Footer fastStartData={fastStartData} />
      </MainCard>
    </Grid>
  );
}
