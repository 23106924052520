import { gql } from '@apollo/client';

const GET_USER_AUTHORIZED_CE_QUERY = gql`
  query CEDash_UserAuthorizedCeQuery {
    viewer {
      id
      isLoggedIn
      user {
        id
        userId
        pointBalance
        firstName
        lastName
        email
        trinityDealerId
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
      }
    }
  }
`;

export default GET_USER_AUTHORIZED_CE_QUERY;
