import { gql } from '@apollo/client';

const mutation = gql`
  mutation Account_CESubscriptionInformationUpdate(
    $input: CESubscriptionInformationUpdateInput!
  ) {
    CESubscriptionInformationUpdate(input: $input) {
      user {
        id
        subscriptions {
          id
          CE {
            id
            subscriptionId
            subscriptionStatus
            CECreationDate
            CERenewalDate
            paymentInfo {
              name
              nickname
              brand
              last4
              expMonth
              expYear
              paymentId
            }
          }
        }
      }
    }
  }
`;

export default mutation;
