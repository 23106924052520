import React from 'react';

export const SvgArchive = (props: any) => (
  <svg width="40" height="40" viewBox="0 -2 20 40" {...props}>
    <defs>
      <path
        d="M26.83 11c1.75 0 3.17 1.343 3.17 3a2.932 2.932 0 0 1-1.057 2.22V26c0 1.657-1.419 3-3.17 3H15.209c-1.75 0-3.17-1.343-3.17-3v-9.78c-.967-.831-1.295-2.139-.828-3.293.467-1.155 1.635-1.92 2.941-1.927zm0 6H14.151v9c0 .552.473 1 1.057 1h10.566c.583 0 1.056-.448 1.056-1v-9zm-4.04 3c.48 0 .87.39.87.87v.26c0 .48-.39.87-.87.87h-4.6a.87.87 0 0 1-.87-.87v-.26c0-.48.39-.87.87-.87h4.6zm4.04-7H14.151c-.583 0-1.056.448-1.056 1s.473 1 1.056 1h12.68c.583 0 1.056-.448 1.056-1s-.473-1-1.057-1z"
        id="archive_svg__a"
      />
    </defs>
    <g transform="translate(0 0)" fill="none" fillRule="evenodd">
      <mask id="archive_svg__b" fill="#fff">
        <use xlinkHref="#archive_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#archive_svg__a" />
      <g mask="url(#archive_svg__b)" fill="#1A1A1A">
        <path d="M0 0h40v40H0z" />
      </g>
    </g>
  </svg>
);
