/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { DateTime } from 'luxon';

import {
  Icons,
  Tooltip,
  ButtonV2 as MuiButton,
  styled,
} from '@onehope/design-system-v2';
const { RefreshRightArrow, HeartOutline } = Icons;

import { getOrderStatusIcon } from './helpers';
import IconMenu from './IconMenu';

const TypeIcon = styled('div')({
  height: '20px',
  width: '20px',
  svg: {
    height: '20px',
    width: '20px',
  },
});

const OrderStatusIconContainer = styled('div')({
  height: '28px',
  width: '28px',
  svg: {
    height: '28px',
    width: '28px',
  },
});

function getOrderType(orderType: string) {
  switch (orderType) {
    case 'wineClub':
      return (
        <Tooltip title="Wine Club" placement="top">
          <TypeIcon>
            <RefreshRightArrow />
          </TypeIcon>
        </Tooltip>
      );
    case 'hostKit':
      return (
        <Tooltip title="Host Kit" placement="top">
          <TypeIcon>
            <HeartOutline />
          </TypeIcon>
        </Tooltip>
      );
    default:
      return <div />;
  }
}

interface RenderProps {
  orderId?: string | null | undefined;
  ceId?: string | null | undefined;
  customerId?: string | null | undefined;
  handleTrackOrder?: () => void;
  trackingLinks?: (string | null)[];
}

interface Column {
  id:
    | 'menu'
    | 'accountFullName'
    | 'createDate'
    | 'orderSubTotalAfterDiscounts'
    | 'orderStatus'
    | 'orderNumber'
    | 'orderType'
    | 'trackOrder';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: string, orderId?: string | null | undefined) => void;
  render?: (renderProps: RenderProps) => any;
  orderType?: (props: { orderType: string }) => any;
}

const columns: Column[] = [
  {
    id: 'accountFullName',
    label: 'Name',
    align: 'left',
    format: (value, eventId?: string | null | undefined) =>
      value && eventId ? value.toLocaleString() : '',
  },
  {
    id: 'createDate',
    label: 'Date',
    minWidth: 100,
    align: 'left',
    format: value => DateTime.fromISO(value).toFormat('LL/dd/yy'),
  },
  {
    id: 'orderNumber',
    label: 'Order #',
    align: 'left',
    format: (value, orderId?: string | null | undefined) =>
      value && orderId ? value.toLocaleString() : '',
  },
  {
    id: 'orderSubTotalAfterDiscounts',
    label: 'Total',
    minWidth: 70,
    align: 'left',
    format: value =>
      value
        ? `$${Number(value)
            .toFixed(2)
            .toLocaleString()}`
        : '$0',
  },
  {
    id: 'orderStatus',
    label: 'Status',
    minWidth: 100,
    align: 'left',
    format: value => {
      const { icon, status } = getOrderStatusIcon(value);
      return value ? (
        <Tooltip title={status} placement="top">
          <OrderStatusIconContainer>{icon}</OrderStatusIconContainer>
        </Tooltip>
      ) : (
        ''
      );
    },
  },
  {
    id: 'orderType',
    label: 'Order Type',
    align: 'center',
    orderType: ({ orderType }) => getOrderType(orderType),
  },
  {
    id: 'trackOrder',
    minWidth: 0,
    align: 'left',
    render: ({ handleTrackOrder, trackingLinks }: RenderProps) => (
      <MuiButton
        disabled={trackingLinks?.length === 0}
        type="primary"
        size="small"
        onClick={handleTrackOrder ? () => handleTrackOrder() : () => {}}
      >
        Track
      </MuiButton>
    ),
  },
  {
    id: 'menu',
    minWidth: 0,
    align: 'right',
    render: ({
      orderId,
      ceId,
      customerId,
      trackingLinks,
      handleTrackOrder,
    }: {
      orderId: string;
      ceId?: string;
      customerId?: string;
      trackingLinks?: (string | null)[];
      handleTrackOrder?: () => void;
    }) => (
      <IconMenu
        orderId={orderId}
        isCardView={false}
        ceId={ceId}
        customerId={customerId}
        trackingLinks={trackingLinks}
        handleTrackOrder={handleTrackOrder}
      />
    ),
  },
];

export default columns;
