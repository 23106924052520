import React from 'react';
import styled from '@emotion/styled';
import { presetOverlayText, presetImages } from './presets';

type FullHeightImageProps = {
  assetUrl: string;
  preset: string;
  height?: string;
};

const imageBackground = (props: FullHeightImageProps) =>
  `${props.assetUrl}/${presetImages[props.preset]}`;

// @ts-ignore
const ImageBG = styled.div<FullHeightImageProps>`
  background: ${(props: FullHeightImageProps) => `url(${imageBackground({
    assetUrl: props.assetUrl,
    preset: props.preset,
  })}) center center / cover
    no-repeat`};
  width: 50vw;
  position: relative;
  height: ${(props: FullHeightImageProps) =>
    props.height
      ? props.height
      : '100vh'}; /* If there were a header, you may pass in height='calc(100vh - 64px)' */
`;

export default function FullHeightImage({
  assetUrl,
  preset,
  height,
}: FullHeightImageProps) {
  return (
    <ImageBG assetUrl={assetUrl} preset={preset} height={height}>
      {presetOverlayText[preset]}
    </ImageBG>
  );
}
