import { Chip } from '@onehope/design-system-v2';

import { CEDash_ReachOutResults_viewer_v2_reachOutResults } from '../../../queries/generatedTypes/CEDash_ReachOutResults';
import {
  ReachOutRequestStatus,
  ReachOutResultType,
} from './../../../../generatedTypes/globalTypes';

import { CompletedWrapper, StatusText } from '../StyledComponents/Tables';

type Props = {
  result: CEDash_ReachOutResults_viewer_v2_reachOutResults;
  showDialog: (
    result: CEDash_ReachOutResults_viewer_v2_reachOutResults,
  ) => void;
};

/**
 * For a ReachOut result, display a status icon or CTA based on the status and type of the ReachOutRequest.
 */
export default function ResultCTA({ result, showDialog }: Props) {
  const {
    reachOutRequest: { status },
    resultsUrl,
    resultType,
  } = result;

  let ctaLabel = 'View';

  switch (resultType) {
    case ReachOutResultType.ORDER:
      ctaLabel = 'View Order';
      break;
    case ReachOutResultType.SURVEY_RESPONSE:
      ctaLabel = 'View Results';
      break;
    default:
      break;
  }

  // for "clicked" status OR for "completed" status with "clicked" success condition, show "clicked" badge
  if (
    status === ReachOutRequestStatus.CLICKED ||
    (status === ReachOutRequestStatus.COMPLETE && resultsUrl === null)
  ) {
    return (
      <CompletedWrapper>
        <StatusText text="Link Clicked" />
      </CompletedWrapper>
    );
  }

  if (status === ReachOutRequestStatus.COMPLETE) {
    return <Chip label={ctaLabel} onClick={() => showDialog(result)} />;
  }

  return null;
}
