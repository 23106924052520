import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import get from 'lodash/get';

// Components
import UploadImage, { ImageValuesType } from './UploadImage';
import Details from './Details';
import Wine from './Wine';

// Mutations
import EventCreateMutation from '../../../mutations/Event/EventCreateMutation';
import PRE_APPROVED_DONATION_ADD from '../../../mutations/Donations/PreApprovedDonationAdd';
import CUSTOM_DONATION_ADD from '../../../mutations/Donations/DonationsAddMutation';
import DONATION_V2_ADD from '../../../mutations/Donations/DonationV2AddMutation';
import IMAGE_ADD_MUTATION from '../../../mutations/Image/ImageAddMutation';
import GET_IMAGE_PRESIGNED_URL_MUTATION from '../../../mutations/Image/ImagePresignedUrlGetMutation';

// Helpers
import {
  getEventDetailsToSubmit,
  uploadImage,
  getDisplayPreferences,
} from '../../Events/EventDetailsForm/submitHelpers';
import { saveDonation } from '../../Events/EventDetailsForm/submitHelpers';
import { getWineDetailsToSubmit } from '../../Event/TabContent/Wine/helpers';

import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { clearLocalStorage } from './ClearLocalStorage';

import { getInitialValues as getInitialDetailsValues } from '../../Event/TabContent/Details/helpers';
import { getInitialValues as getInitialCharityValues } from '../../Event/TabContent/Charity/helpers';
import { EventVersionTypeEnum, EventTypeEnum } from '../../EventsV2/eventTypes';

// Types
import { MyFormValues as CharityFormValues } from '../../Event/TabContent/Charity/FormTypes';
import { FormValues as WineFormValues } from '../../Event/TabContent/Wine/FormTypes';
import { CEDash_EventsV2Query_viewer_user as UserType } from '../../../queries/generatedTypes/CEDash_EventsV2Query';
import { segmentTrackEventLocations } from '../../../utils/segment/segmentConstants';
import { EventCreateSteps, EventCreateStepOrder } from './stepperConstants';
import { EventManagementTabs } from '../../Event/TabNavigation/tabConstants';

interface EventCreateStepperProps {
  createEventTab: string;
  handelDialogClose: () => void;
  user: UserType;
}

export default function EventCreateStepper(props: EventCreateStepperProps) {
  const [form, setForm] = React.useState({});
  const { createEventTab, handelDialogClose } = props;
  const [detailsFormValues, setDetailsFormValues] = useState(
    getInitialDetailsValues(),
  );
  const [charityFormValues, setCharityFormValues] = useState(
    getInitialCharityValues(),
  );
  const [imageValues, setImageValues] = useState({
    imageCanvas: '',
    mimeType: '',
  } as ImageValuesType);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const [
    eventCreate,
    { loading: mutationEventCreateMutationLoading },
  ] = useMutation(EventCreateMutation);
  const [
    preApprovedDonationAdd,
    { loading: preApprovedDonationMutationLoading },
  ] = useMutation(PRE_APPROVED_DONATION_ADD);
  const [donationV2Add, { loading: donationV2AddLoading }] = useMutation(
    DONATION_V2_ADD,
  );
  const [
    customDonationAdd,
    { loading: customDonationMutationLoading },
  ] = useMutation(CUSTOM_DONATION_ADD);
  const [imageAddMutation, { loading: imageAddMutationloading }] = useMutation(
    IMAGE_ADD_MUTATION,
  );
  const [getPresignedUrl, { loading: getPresignedUrloading }] = useMutation(
    GET_IMAGE_PRESIGNED_URL_MUTATION,
  );

  const { trackEventsV2 } = useTrackingContextValue();

  const loadingEventCreation =
    mutationEventCreateMutationLoading ||
    preApprovedDonationMutationLoading ||
    customDonationMutationLoading ||
    donationV2AddLoading ||
    imageAddMutationloading ||
    getPresignedUrloading ||
    imageUploadLoading;

  async function handleBookEvent({
    charityValues,
    wineValues,
    setErrorNotification,
    setErrors,
    imageValues: imgValues,
  }: {
    charityValues?: CharityFormValues;
    wineValues?: WineFormValues;
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>;
    setErrors?: (errors: Record<string, string>) => void;
    imageValues: ImageValuesType;
  }) {
    if (!loadingEventCreation) {
      const eventDetails = getEventDetailsToSubmit({
        values: detailsFormValues,
      });
      const wineDetails = wineValues
        ? getWineDetailsToSubmit(
            wineValues,
            eventDetails?.trinityPartyType,
            new Date().toString(),
          )
        : {};

      const charityVals = charityValues || charityFormValues;
      const displayPreferences = getDisplayPreferences({
        event: null,
        values: {
          ...detailsFormValues,
          ...charityVals,
        },
      });
      const input = {
        form: {
          ...eventDetails,
          ...wineDetails,
          displayPreferences,
          hostQuote: charityVals?.charityQuote || '',
        },
      };

      let eventId = '';
      await eventCreate({
        variables: { input },
      })
        .then(async (data) => {
          const {
            data: { eventCreate },
          } = data;

          if (!eventCreate?.eventId) {
            console.error('Event creation failed', { data });
          }

          eventId = eventCreate?.eventId;
          const newEvent = get(data, 'data.eventCreate.event');

          // Save Donation separately from event
          const updatedEventData = await saveDonation({
            eventId,
            newEvent,
            values: charityVals,
            customDonationAdd,
            preApprovedDonationAdd,
            donationV2Add,
          });

          if (imgValues?.imageCanvas) {
            await uploadImage({
              imageAddMutation,
              getPresignedUrl,
              values: imgValues,
              groupName: `events/${process.env.GATSBY_IMAGE_UPLOAD_PATH}`,
              authorId: eventId,
              imageType: 'event',
              setLoading: setImageUploadLoading,
            });
          }
          // Send tracking when it's all complete.
          const eventData = updatedEventData ?? newEvent;
          const isFundraiser =
            eventDetails.eventType === EventTypeEnum.Fundraiser;

          trackEventsV2({
            eventName: 'Event Create Completed',
            newEvent: eventData,
            formValues: {
              ...charityVals,
              ...(wineValues ? wineValues : {}),
              ...wineDetails,
            },
          });
          // only call on event creation
          trackEventsV2({
            eventName: 'Event Type Updated',
            newEvent: eventData,
          });

          // Only fire this track call if a close date is manually set.
          // If one is manually set, it currently appears as eventDate
          // in the form values (but gets sent to the backend as closeDate).
          //
          // We want to check if this value is preset in the form values instead
          // of eventData, since eventData returns the auto-set 90-day date from
          // the backend.
          if (isFundraiser && detailsFormValues?.eventDate) {
            trackEventsV2({
              eventName: 'Event Custom Close Date Updated',
              newEvent: eventData,
              additionalProperties: {
                updatedLocation: segmentTrackEventLocations.eventCreation,
              },
            });
          }
        })
        .then(() => {
          const isManualAddress =
            localStorage.getItem('manualAddress') === 'true';
          const isManualCharityAddress =
            localStorage.getItem('manualCharityAddress') === 'true';
          const isManualNonProfit =
            localStorage.getItem('manualNonProfit') === 'true';
          clearLocalStorage();
          localStorage.setItem('eventCreated', 'true');
          if (isManualAddress)
            localStorage.setItem(`manualAddress${eventId}`, 'true');
          if (isManualCharityAddress)
            localStorage.setItem(`manualCharityAddress${eventId}`, 'true');
          if (isManualNonProfit)
            localStorage.setItem(`manualNonProfit${eventId}`, 'true');
          return navigate(
            `/event?eventId=${eventId}&tab=${EventManagementTabs.SUMMARY}`,
          );
        })
        .catch((errors: any) => {
          const errorMessage = errors?.graphQLErrors?.[0]?.message;
          if (errorMessage.includes('Not Found') && setErrorNotification) {
            setErrorNotification(
              'These selections are no longer valid - please update your choices and try again.',
            );
          } else if (setErrors && errorMessage.includes('Invalid Close Date')) {
            setErrors({ eventDate: errorMessage });
            return navigate(
              `/events?createEventStep=${EventCreateSteps.DETAILS}`,
            );
          }
          return navigate(
            `/events?createEventStep=${EventCreateSteps.DETAILS}`,
          );
        });
    }
  }

  async function handleNextStepChange(
    key: string,
    values: any,
    setErrorNotification?: React.Dispatch<React.SetStateAction<string>>,
    setErrors?: (errors: Record<string, string>) => void,
  ) {
    const currentStepIndex = EventCreateStepOrder.indexOf(createEventTab);
    switch (key) {
      case EventCreateSteps.DETAILS: {
        setDetailsFormValues(values);
        setCharityFormValues(values);
        return navigate(
          `/events?createEventStep=${
            EventCreateStepOrder[currentStepIndex + 1]
          }`,
        );
      }
      case EventCreateSteps.IMAGE: {
        setImageValues(values);
        if (detailsFormValues.eventType === EventTypeEnum.WineTasting) {
          return navigate(
            `/events?createEventStep=${
              EventCreateStepOrder[currentStepIndex + 1]
            }`,
          );
        } else {
          return await handleBookEvent({
            setErrorNotification,
            imageValues: values,
          });
        }
      }
      case EventCreateSteps.WINE: {
        return await handleBookEvent({
          wineValues: values,
          setErrorNotification,
          setErrors,
          imageValues,
        });
      }
      default:
        return;
    }
  }

  function handlePreviousStepChange() {
    if (createEventTab !== EventCreateStepOrder[0]) {
      const currentStepIndex = EventCreateStepOrder.indexOf(createEventTab);
      return navigate(
        `/events?createEventStep=${EventCreateStepOrder[currentStepIndex - 1]}`,
      );
    } else {
      clearLocalStorage();
      return handelDialogClose();
    }
  }
  switch (createEventTab) {
    case EventCreateSteps.IMAGE:
      return (
        <UploadImage
          form={form}
          isWineTasting={
            detailsFormValues.eventType === EventTypeEnum.WineTasting
          }
          isOnline={detailsFormValues.trinityPartyType === 'EPARTY'}
          loadingEventCreation={loadingEventCreation}
          handleNextStepChange={handleNextStepChange}
          handlePreviousStepChange={handlePreviousStepChange}
        />
      );

    case EventCreateSteps.WINE:
      return (
        <Wine
          user={props.user}
          form={form}
          loadingEventCreation={loadingEventCreation}
          handleNextStepChange={handleNextStepChange}
          handlePreviousStepChange={handlePreviousStepChange}
        />
      );

    default:
      return (
        <Details
          form={form}
          handleNextStepChange={handleNextStepChange}
          handlePreviousStepChange={handlePreviousStepChange}
        />
      );
  }
}
