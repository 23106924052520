/* SEGMENT DEPENDENCIES */
//import Cookie from 'js-cookie';
import { NOT_SET, segmentGaSessionID } from './segmentConstants';

import { CEDash_EventDetailsPage_viewer_event } from '../../queries/generatedTypes/CEDash_EventDetailsPage';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../queries/generatedTypes/CEDash_CustomerDetailsPage';

import { MyFormValues as CharityFormTypes } from '../../components/Event/TabContent/Charity/FormTypes';
import { MyFormValues as DetailsFormTypes } from '../../components/Event/TabContent/Details/FormTypes';
import { FormValues as WineFormTypes } from '../../components/Event/TabContent/Wine/FormTypes';
// import { CEDash_DashboardQuery_viewer_user } from '../../queries/generatedTypes/CEDash_DashboardQuery';
// import { CEDash_UserAuthorizedCeQuery_viewer_user as CEDash_DashboardQuery_viewer_user } from '../../queries/generatedTypes/CEDash_UserAuthorizedCeQuery';

/* SEGMENT HELPER FUNCTIONS */
const formatConciergeShoppingStarted = (cart, user) => {
  const event =
    cart && cart.event
      ? cart.event
      : {
          eventId: NOT_SET,
          trinityPartyType: NOT_SET,
          trinityPartyId: NOT_SET,
        };

  const orderType = event?.eventId ? 'Party' : 'Storefront';
  const partyType =
    event && event.trinityPartyType === 'EPARTY'
      ? 'Virtual'
      : event && event.trinityPartyType === 'INHOME - SHIPTOGUEST'
      ? 'Home'
      : NOT_SET;

  const partyId = event?.eventId;
  const linkedCeoId = user?.userId;

  return {
    orderChannel: 'VIA', // must be VIA
    shoppingMode: 'Concierge',
    linkedCeoId,
    orderType,
    partyId,
    partyType,
  };
};

export const resetSegment = () => {
  if (
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined' &&
    !!analytics._user
  ) {
    analytics._user.id(null);
    analytics.reset();
  }
};

export const trackEvent = (eventParams: any) => {
  if (window) {
    // userId must be passesd in along with name of event
    const { user, event, properties, reset } = eventParams;
    let sessionID = '';
    let clientID = '';

    const getCookie = (name: string) => {
      var re = new RegExp(name + '=([^;]+)');
      var value = re.exec(document.cookie);
      return value != null ? unescape(value[1]) : null;
    };
    const sessionIDCookie = getCookie(segmentGaSessionID);
    if (sessionIDCookie) {
      sessionID = sessionIDCookie.split('.')[2];
    }
    if (typeof (window as any).ga !== 'undefined') {
      clientID = (window as any).ga.getAll()[0].get('clientId');
    }
    const integrations = {
      Klaviyo: {
        confirmOptin: false,
        listId: 'do not add',
      },
      Intercom: { hideDefaultLauncher: true },
      'Actions Google Analytic 4': {
        clientID: clientID,
      },
    };

    let trackProperties;
    if (process.env.GATSBY_ENABLE_SEGMENT) {
      switch (event) {
        case 'Concierge Shopping Started':
          trackProperties = formatConciergeShoppingStarted(
            properties.cart,
            user,
          );
          break;
        case 'Contact Creation Started':
        case 'Training Center Clicked':
        case 'Resources Clicked':
        case 'Reports Clicked':
        case 'onehopewine.com Clicked':
        case 'The Vine Clicked':
        case 'User Login':
        case 'User Logout':
        default:
          trackProperties = properties;
          break;
      }
      trackProperties.application = 'One Commerce';
      trackProperties['ga_client_id'] = clientID;
      trackProperties['ga_session_id'] = sessionID;

      /**
       * I'm not 100% sure what the original intent of this code was, but by overriding Segment's anonymous ID, it was
       * causing issues with identity resolution on their end. Mike Weber says we don't need to do any special tagging
       * for concierge mode anymore, so I'm commenting this out so that the Segment library is in full control of
       * anonymous IDs.

      if (user && user.email) {
        trackProperties.userId = user.email;
      } else {
        const anonymousId = Cookie.get('OHSegmentId');
        trackProperties.anonymousId = anonymousId;
      }
      */

      if (reset) {
        return analytics.track(event, trackProperties, { integrations }, () =>
          resetSegment(),
        );
      }
      return analytics.track(event, trackProperties, { integrations });
    }
  }
};

type SegmentTrackEventAdditionalProps = {
  updatedLocation?: string;
};

export type SegmentTrackEventProps = {
  eventName?: string;
  user?: any;
  newEvent?: CEDash_EventDetailsPage_viewer_event;
  oldEvent?: CEDash_EventDetailsPage_viewer_event;
  formValues?: CharityFormTypes & DetailsFormTypes & WineFormTypes;
  additionalProperties?: SegmentTrackEventAdditionalProps;
};

export const trackEventsV2 = ({
  eventName,
  user,
  newEvent, // Would maybe be nice to merge this into formValues?
  oldEvent,
  formValues,
  additionalProperties,
}: SegmentTrackEventProps) => {
  if (process.env.GATSBY_ENABLE_SEGMENT === 'true') {
    if (typeof window !== 'undefined' && typeof analytics !== 'undefined') {
      const { userId, trinityDealerId } = user;
      const integrations = {
        Klaviyo: {
          confirmOptin: false,
          listId: 'do not add',
        },
        Intercom: { hideDefaultLauncher: true },
      };
      let trackProperties;

      switch (eventName) {
        case 'Event Create Started':
          trackProperties = {
            accountId: userId,
            trinityDealerId: trinityDealerId,
          };
          break;
        case 'Event Create Completed':
          trackProperties = {
            accountId: userId,
            trinityDealerId,
            eventId: newEvent?.eventId,
            trinityPartyId: newEvent?.trinityPartyId,
            eventLocation:
              newEvent?.trinityPartyType === 'EPARTY' ? 'Online' : 'In person',
            eventHostType: formValues?.hostType,
            eventAddress: newEvent?.eventAddress,
            eventDateTime: newEvent?.eventDate,
            eventTimeZone: newEvent?.timeZone,
            hostAccountId: newEvent?.hostAccountId,
            hostCustomerType: formValues?.hostCustomerType, // I dont think this is ever getting set?
            eventCauseType: formValues?.charityType
              ? formValues?.charityType
              : 'SKIP',
            eventNonProfitId:
              newEvent?.donationInfo?.nonProfitV2?.nonProfitId || null,
            eventNonProfitName:
              newEvent?.donationInfo?.nonProfitV2?.nonProfitName || null,
            eventDonationGoal: newEvent?.donationInfo?.donationGoal || null,
            eventCauseQuote: newEvent?.hostQuote || null,
            eventWineRequired: formValues?.wineNeeded || 'SKIP', // YES NO STARTER-KIT SKIP ALREADY-ORDERED
            eventWineBottles: formValues?.hostKitQuantity || null, // 6 12 null
            eventWineSelector: formValues?.hostKitPurchaser || null, // HOST CE
            eventWineCredits: formValues?.hostKitCredits || null, // 1 2 null
            eventType: formValues?.eventType, // either WINE_TASTING or FUNDRAISER
          };
          break;
        case 'Event Cancelled':
          trackProperties = {
            eventId: newEvent?.eventId,
            accountId: userId,
            trinityDealerId,
            trinityPartyId: newEvent?.trinityPartyId,
          };
          break;
        case 'Event Date Time Updated':
          trackProperties = {
            eventId: newEvent?.eventId,
            oldEventDateTime: oldEvent?.eventDate,
            newEventDateTime: newEvent?.eventDate,
          };
          break;
        case 'Event Custom Close Date Updated':
          trackProperties = {
            eventId: newEvent?.eventId,
            oldCloseDateTime: oldEvent?.closeDate || null,
            newCloseDateTime: newEvent?.closeDate || null,
            UpdatedLocation: additionalProperties.updatedLocation,
          };
          break;
        case 'Event Cause Updated':
          trackProperties = {
            eventId: newEvent?.eventId,
            eventCauseType: formValues?.charityType,
            eventNonProfitId: newEvent?.donationInfo?.nonProfitV2?.nonProfitId,
            eventNonProfitName:
              newEvent?.donationInfo?.nonProfitV2?.nonProfitName,
            eventDonationGoal: newEvent?.donationInfo?.donationGoal,
            eventCauseQuote: newEvent?.hostQuote,
          };
          break;
        case 'Event Host Updated':
          trackProperties = {
            eventId: newEvent?.eventId,
            hostAccountId: newEvent?.hostAccountId,
            hostCreate: formValues?.hostCustomerType,
          };
          break;
        case 'Event Wine Updated':
          trackProperties = {
            eventWineRequired: formValues?.wineNeeded || 'SKIP', // YES NO STARTER-KIT SKIP ALREADY-ORDERED
            eventWineBottles: formValues?.hostKitQuantity || null, // 6 12 null
            eventWineSelector: formValues?.hostKitPurchaser || null, // HOST CE
            eventWineCredits: formValues?.hostKitCredits || null, // 1 2 null
          };
          break;
        case 'Event Type Updated':
          trackProperties = {
            eventId: newEvent?.eventId,
            eventType: newEvent?.eventType,
          };
          break;
        default:
          console.log(`cannot track event ${eventName}`);
          return;
      }
      return analytics.track(eventName, trackProperties, { integrations });
    }
  }
};

type ContactImportStats = {
  totalImported: number;
  successfulImports: number;
  failedImports: number;
};

export type SegmentTrackContactProps = {
  eventName?: string;
  ceAccountId?: string | null;
  contact?: ContactType;
  totalArchived?: number;
  contactImportStats?: ContactImportStats;
};

export const trackContacts = ({
  eventName,
  ceAccountId,
  contact,
  totalArchived,
  contactImportStats,
}: SegmentTrackContactProps) => {
  if (process.env.GATSBY_ENABLE_SEGMENT === 'true') {
    if (typeof window !== 'undefined' && typeof analytics !== 'undefined') {
      const integrations = {
        Klaviyo: {
          confirmOptin: false,
          listId: 'do not add',
        },
        Intercom: { hideDefaultLauncher: true },
      };
      let trackProperties;
      switch (eventName) {
        case 'CE Created Contact':
          trackProperties = {
            accountId: ceAccountId,
            type:
              contact?.contactType === 'PERSON' ? 'individual' : 'organization',
            ce_interest: contact?.contactPreferences?.interestedIn?.joinAsCE,
            host_interest:
              contact?.contactPreferences?.interestedIn?.hostWineTasting,
            wineclub_interest:
              contact?.contactPreferences?.interestedIn?.joinWineClub,
            corpgifting_interest:
              contact?.contactPreferences?.interestedIn?.corporateGifting,
          };
          break;
        case 'CE Started Contact Import':
          trackProperties = {
            accountId: ceAccountId,
          };
          break;
        case 'CE Completed Contact Import':
          trackProperties = {
            accountId: ceAccountId,
            total_imported: contactImportStats?.totalImported,
            success: contactImportStats?.successfulImports,
            failed: contactImportStats?.failedImports,
          };
          break;
        case 'CE Archived Contact':
          trackProperties = {
            accountId: ceAccountId,
            total_archived: totalArchived,
          };
          break;
        default:
          console.log(`cannot track event ${eventName}`);
          return;
      }
      return analytics.track(eventName, trackProperties, { integrations });
    }
  }
};

// Note: This cookie must be shared across all apps.
export const segmentCookie = ({ email }: { email?: string }) => {
  if (
    typeof window !== 'undefined' &&
    typeof analytics !== 'undefined' &&
    !!analytics._user
  ) {
    if (email) {
      analytics._user.id(email);
    } else {
      analytics._user.id(null);
    }
  }
};
