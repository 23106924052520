import React from 'react';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';

import { MyFormValues } from '../../Event/TabContent/Details/FormTypes';
import { DetailsContainer } from '../../Event/TabContent/index.styles';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';

import SummaryLine from '../../ContactV2/ContactDetailsPage/TabContent/SummaryLine';
// import EventCancelButton from './EventCancelButton';

import { formatZone } from '../../Event/TabContent/Details/helpers';
import { EventStatusDictionary } from '../../../utils/enums';
import { Icons, Grid, Text, theme, TEXT_DS } from '@onehope/design-system-v2';
import { LinkV2 } from '../../ContactV2/ContactDetailsPage/TabContent/SummaryLine/summaryLineStyles.styles';
import { EventTypeEnum } from '../../EventsV2/eventTypes';

const { Copy } = Icons;

interface EventSummaryProps {
  event: EventType;
  values: MyFormValues;
  editing: boolean;
  satelliteEvent: boolean;
  setIsEditing: () => void;
}

export const formatDisplayDate = (
  eventDate: string | null,
  timeZone: string | undefined,
) => {
  if (!eventDate || !timeZone) return '';
  const date = DateTime.fromISO(eventDate, { zone: timeZone });

  if (!date) return '';
  if (!date.c) return date;
  const {
    c: { month, day, year },
  } = date;
  return `${month}/${day.toString().padStart(2, 0)}/${year}`.padStart(10, 0);
};

const CopyIcon = styled(Copy)`
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export default function EventSummary(props: EventSummaryProps) {
  const { event, values, satelliteEvent } = props;
  const {
    hostFullName,
    eventTime,
    timeZone,
    displayAddress,
    eventTimeLocale,
    eventVirtualLink,
  } = values;

  const closedEvent = event?.status === EventStatusDictionary.CLOSED;
  const image = event?.images?.[0];

  const eventDate = formatDisplayDate(event.eventDate, timeZone);
  const closeDate = formatDisplayDate(event.closeDate, timeZone);
  const manualCloseDate = formatDisplayDate(event.manualCloseDate, timeZone);
  const showSupporters = event?.displayPreferences?.supporters;
  const showLocation = event?.displayPreferences?.eventLocation;
  const isWineTasting = event?.eventType === EventTypeEnum.WineTasting;
  const isVirtualEvent = isWineTasting && event?.trinityPartyType === 'EPARTY';

  const formattedCloseDate = DateTime.fromISO(event.closeDate);
  const formattedManualCloseDate = DateTime.fromISO(event.manualCloseDate);
  const isManualCloseDateBeforeClose =
    formattedManualCloseDate.startOf('day') < formattedCloseDate.startOf('day');
  const goToLink = () => {
    window.open(eventVirtualLink, '_blank');
  };

  const copyToClipboard = () => {
    // using this method because of struggles
    // with refs on the mui input
    if (!!document) {
      const el = document.createElement('textarea');
      el.value = eventVirtualLink;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  };
  return (
    <DetailsContainer>
      {satelliteEvent ? (
        <div>
          <SummaryLine width={130} title="Event location" value="Online" />
          <SummaryLine
            width={130}
            title="Host name"
            value={event.hostFullName}
          />
        </div>
      ) : (
        <div>
          {hostFullName && (
            <SummaryLine width={130} title="Host name" value={hostFullName} />
          )}
          {isVirtualEvent && (
            <SummaryLine
              width={130}
              title="Virtual event link"
              value={
                <Grid container wrap="nowrap">
                  <Grid item xs>
                    {eventVirtualLink ? (
                      <LinkV2 onClick={goToLink}>{eventVirtualLink}</LinkV2>
                    ) : (
                      <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                        N/A
                      </Text>
                    )}
                  </Grid>
                  {eventVirtualLink && (
                    <CopyIcon
                      onClick={copyToClipboard}
                      color={theme.palette.forestGreen.main}
                    />
                  )}
                </Grid>
              }
            />
          )}
          {displayAddress && (
            <SummaryLine
              width={130}
              title="Event address"
              value={displayAddress}
            />
          )}
          {isWineTasting && (
            <SummaryLine
              width={130}
              title="Event location"
              value={showLocation ? 'Show' : 'Hide'}
            />
          )}
          {eventDate && isWineTasting && (
            <SummaryLine width={130} title="Event date" value={eventDate} />
          )}
          {closedEvent && isWineTasting && !satelliteEvent && (
            <div>
              {closeDate && (
                <SummaryLine
                  width={130}
                  title="Stopped accepting orders on"
                  value={
                    isManualCloseDateBeforeClose ? manualCloseDate : closeDate
                  }
                />
              )}
              {manualCloseDate && (
                <SummaryLine
                  width={130}
                  title="Event ended on"
                  value={manualCloseDate}
                />
              )}
            </div>
          )}
          {!isWineTasting && closeDate && (
            <div>
              <SummaryLine width={130} title="End date" value={closeDate} />
              {manualCloseDate && (
                <SummaryLine
                  width={130}
                  title="Event ended on"
                  value={manualCloseDate}
                />
              )}
            </div>
          )}
          {eventDate && isWineTasting && (
            <SummaryLine
              width={130}
              title="Time"
              value={`${eventTime}
                ${eventTimeLocale} ${formatZone(timeZone)}`}
            />
          )}
          <SummaryLine
            width={130}
            title="Event supporters"
            value={showSupporters ? 'Show' : 'Hide'}
          />
        </div>
      )}
    </DetailsContainer>
  );
}
