import {
  styled,
  Container,
  Image,
  theme,
  Icons,
} from '@onehope/design-system-v2';

export const ReachOutContainer = styled(Container)({
  padding: '16px',
  maxWidth: '824px',
  div: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '24px 40px 100px',
  },
});

export const StyledImage = styled(Image)({
  width: '100%',
  height: '100%',
  borderRadius: '4px',
});

export const IconsContainer = styled('div')({
  display: 'flex',
  margin: '8px 0 32px',
  overflowX: 'auto',
});

export const EmailIcon = styled(Icons.Email)({
  marginRight: '16px',
  cursor: 'pointer',
  height: '60px',
});

export const SMSIcon = styled(Icons.SMS)({
  marginRight: '16px',
  cursor: 'pointer',
  height: '60px',
});

export const HelpIcon = styled(Icons.CircleQuestion)({
  cursor: 'pointer',
});
