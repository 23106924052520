import { redirectToLoginV2 } from './utils';

const LoginV2Redirect = () => {
  const redirectUrl = encodeURI(window.location.href);
  redirectToLoginV2(redirectUrl);

  return null;
};

export default LoginV2Redirect;
