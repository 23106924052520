import React from 'react';
import {
  Dropzone,
  GridV2 as Grid,
  Icons,
  makeStyles,
  ThemeType,
  Text,
  TEXT_DS,
} from '@onehope/design-system-v2';
import {
  useDefaultSelectedImageStyles,
  useEventImageStyles,
} from '../commonStyles';

const useStyles = makeStyles((theme: ThemeType) => ({
  dragAndDrop: {
    padding: '16px',
    width: '100%',
    height: '289px',
    border: `1px dashed ${theme.palette.primary.main}`,
    marginBottom: '40px',
  },
  textUpload: {
    paddingLeft: '4px',
    fontWeight: 600,
    color: theme.palette.linkColor.main,
    cursor: 'pointer',
  },
  text: { height: '100%' },
  icon: { paddingBottom: '8px', cursor: 'pointer' },
  skip: {
    fontWeight: 700,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    paddingTop: '24px',
    cursor: 'pointer',
  },
  selectedImageContainer: {
    padding: 8,
    border: `1.5px solid ${theme.palette.forestGreen.main}`,
    borderRadius: 4,
  },
  defaultImageUploadContainer: {
    border: `1px dashed ${theme.palette.greyMedium.main}`,
    borderRadius: 4,
    height: 136,
    marginLeft: 24,
    width: 'calc(100% - 24px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  uploadText: {
    color: `${theme.palette.forestGreen.main}`,
  },
}));

export default function DragAndDrop(props: any) {
  const { onFileDrop, defaultImageUrl } = props;
  const imageClasses = useEventImageStyles(defaultImageUrl)();
  const defaultImageClasses = useDefaultSelectedImageStyles(defaultImageUrl)();

  const classes = useStyles();

  return (
    <div>
      <div className={imageClasses.image} />
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        <Grid item xs={6} className={classes.selectedImageContainer}>
          <div className={defaultImageClasses.image} />
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Dropzone
              className={classes.defaultImageUploadContainer}
              accept="image/jpg, image/jpeg, image/png"
              onDrop={onFileDrop}
              maxFiles={1}
              multiple={false}
              maxSize={16000000} // in bytes (16 MB)
            >
              <Icons.CeDashEmptyPhoto />
              <Text
                variant="custom"
                default={TEXT_DS.BODY_SEMIBOLD_16}
                className={classes.uploadText}
              >
                Upload
              </Text>
            </Dropzone>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
