import * as React from 'react';
import { css } from '@emotion/core';
import { ButtonProps } from 'antd/lib/button';

import { ButtonPrimaryLarge } from '../styles/ant-design-styles/buttons';

export default function FullWidthPrimaryButton(props: ButtonProps) {
  const fullWidthStyles = css`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    z-index: 1;
  `;

  const { children, ...rest } = props;
  return (
    <ButtonPrimaryLarge css={fullWidthStyles} type="primary" {...rest}>
      {children}
    </ButtonPrimaryLarge>
  );
}
