import React from 'react';
import {
  ButtonBase,
  Button,
  Grid,
  Icons,
  Text,
  useMediaQuery,
  useTheme,
  ChunkyButton,
} from '@onehope/design-system-v2';
/* COMPONENTS */
import ProfileImage from '../../ProfileImage';
/* UTILS */
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
import { getCurrentUser } from '../../../utils/helper';
/* STYLES */
import { useCommonStyles } from '../../../utils/commonStyles';
import {
  CauseOfChoiceAppDictionary,
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';

export default function CeCauseConfirmation() {
  const {
    handleCloseModal,
    user: parentUser,
    onContinue,
    handleGoToShop,
    app,
  } = useCauseOfChoiceNetworkLayerValue();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [, dispatch] = useCauseOfChoiceContextValue();
  const commonStyles = useCommonStyles();
  const user = getCurrentUser(parentUser);
  const guestModeNonProfit = user?.cartV2?.guestNonProfit;
  const ce = user?.shoppingWithCeoUser;
  const selectedCauseOfChoice =
    guestModeNonProfit || ce?.selectedCause?.nonProfit;
  const ceStatement = ce?.selectedCause?.statement;
  const subHeader = `Thank you for choosing to support ${ce?.firstName}’s cause of choice.`;
  const isMegalith = app === CauseOfChoiceAppDictionary.OHW_MEGALITH;
  const buttonText = isMegalith ? 'Shop' : onContinue ? 'Continue' : 'Close';
  const buttonOnClick = isMegalith
    ? handleGoToShop
    : onContinue
    ? onContinue
    : handleCloseModal;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.HOME_SCREEN,
    });
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={commonStyles.navigationAlt}
        >
          <ButtonBase onClick={handleGoBack}>
            <Grid xs item container alignItems="center" direction="row">
              <Icons.SmallLeftArrow />
              <Text className={commonStyles.backButtonText}>Back</Text>
            </Grid>
          </ButtonBase>
          <ButtonBase onClick={handleCloseModal}>
            <Icons.Close />
          </ButtonBase>
        </Grid>
      </Grid>
      <Text className={commonStyles.subHeaderText}>{subHeader}</Text>
      <Grid className={commonStyles.marginTop16}>
        <ChunkyButton
          avatar={<Icons.COCHeartHands />}
          onChange={handleGoBack}
          onChangeText="Change"
          primaryText={`${selectedCauseOfChoice?.nonProfitName}`}
          secondaryText={`${selectedCauseOfChoice?.nonProfitAddress?.city}, ${selectedCauseOfChoice?.nonProfitAddress?.state}`}
          tertiaryText={`EIN: ${selectedCauseOfChoice?.nonProfitTaxId}`}
          headerIcon={
            selectedCauseOfChoice?.isIRSVerified ? <Icons.COCVerified /> : null
          }
        />
      </Grid>
      {ceStatement && (
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'center' : 'flex-start'}
          wrap="nowrap"
          className={commonStyles.ceStatementContainer}
        >
          <Grid item className={commonStyles.profileImageContainer}>
            <ProfileImage />
          </Grid>
          <Grid container item xs>
            <Text
              dangerouslySetInnerHTML={{
                __html: ceStatement,
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center">
        <Button
          fullWidth
          type="primary"
          className={commonStyles.saveAndClose}
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
        <Text className={commonStyles.italicText}>
          You can change your cause of choice at any point.
        </Text>
      </Grid>
    </Grid>
  );
}
