import React, { useState } from 'react';
import { Grid, useTheme, useMediaQuery } from '@onehope/design-system-v2';

import Message from './Message';
import Title from './Title';
import ActivityEntry from '../types/ActivityEntry';
import SendToTrackEventV2 from '../types/SendToTrackEventV2Type';
import OpenVideoDialogFn from '../types/openVideoDialog';
import OpenButtonActionDialogFn from '../types/OpenButtonActionDialog';
import OnCardButtonClickedFn from '../types/OnCardButtonClicked';
import { SegmentEvents } from './constants/segmentEvents';
import CardButton from './CardButton';
import Video from './Video';
import useStyles from './helpers/styles';
import { getFormattedTimeAgo } from './helpers';

/**
 * Renders the actual content of an Action Engine card given the ledger entry and the result of a GraphQL query for
 * any included dynamic data.
 */

export interface CardContentProps {
  activity: ActivityEntry;
  openVideoDialog?: OpenVideoDialogFn;
  openButtonActionDialog?: OpenButtonActionDialogFn;
  isContentfulSideBar?: boolean;
  sendToTrackEventV2?: SendToTrackEventV2;
  onCardButtonClicked?: OnCardButtonClickedFn;
  archiveCardMutation?: (ledgerId: string, isArchived: boolean) => Promise<any>;
  isCardDialogItems?: boolean;
  isCardDetailsPage?: boolean;
  shouldStopVideo?: boolean;
}

export default function CardContent(props: CardContentProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const [playing, setPlaying] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    activity,
    openVideoDialog,
    openButtonActionDialog,
    sendToTrackEventV2,
    onCardButtonClicked,
    isCardDialogItems,
    isCardDetailsPage,
    shouldStopVideo,
  } = props;

  const cardCreatedTimeAgo = getFormattedTimeAgo(activity.dateCreated);
  const { rewardPointBounty, id } = activity;
  const displayPointBadge = rewardPointBounty > 0 && !isCardDetailsPage;

  const wrapperClasses: Array<string> = [];
  if (activity.isDeleted) {
    wrapperClasses.push('is-deleted');
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (onCardButtonClicked) {
      onCardButtonClicked(id);
    }
  };

  const onVideoClickPreview = () => {
    if (isDesktop) {
      openVideoDialog &&
        openVideoDialog(
          activity.muxVideo ? activity.muxVideo.playbackId : 'undefined',
          activity.muxVideo ? activity.muxVideo.ratio : 'undefined',
          activity.id,
        );
    } else {
      setPlaying(true);
    }
    sendToTrackEventV2 &&
      sendToTrackEventV2({ event: SegmentEvents.cardClicked, activity });
  };

  const watchNow = () => {
    setPlaying(!playing);
  };

  /**
   * append query param to instruct Contentful to resize images. they're often huge and we are displaying them at a 342px width.
   * resize to 700px width to support hi-res displays with a bit of margin for design tweaks; it's still a drastic reduction.
   */
  const getImageWithResizeParam = (url: string, widthInPx: number) => {
    // append param, preserving any existing query params. use URLSearchParams to avoid encoding issues.
    const appendQueryParam = (url: string, key: string, value: string) => {
      // contentful URLs are scheme-agnostic (e.g. //images.ctfassets.net/...) which is not supported by the URL constructor
      const urlWithScheme = url.startsWith('https') ? url : `https:${url}`;

      const urlObj = new URL(urlWithScheme);
      const params = new URLSearchParams(urlObj.search);
      params.set(key, value);
      urlObj.search = params.toString();
      return urlObj.toString();
    };

    const resizedUrl = appendQueryParam(url, 'w', widthInPx.toString());
    return resizedUrl;
  };

  return (
    <Grid>
      <Grid className={wrapperClasses.join(' ')}>
        <div
          className={
            isCardDialogItems
              ? classes.cardDialogItemDetailsContainer
              : classes.cardContainer
          }
        >
          {/* don't render the image if there's also a video - it will be rendered in <Video /> as the thumbnail */}
          {activity.image && !activity.muxVideo?.playbackId && (
            <div className={classes.cardImageContainer}>
              <img
                className={classes.image}
                alt={activity.image.alt}
                src={getImageWithResizeParam(activity.image.src, 700)}
              />
              {displayPointBadge && (
                <div className={classes.cardRewardBadge}>
                  +{rewardPointBounty} POINTS
                </div>
              )}
            </div>
          )}

          {activity.muxVideo?.playbackId && (
            <div className={classes.cardImageContainer}>
              <Video
                {...props}
                onVideoClickPreview={onVideoClickPreview}
                playing={playing}
                onCardButtonClicked={onCardButtonClicked}
                shouldStopVideo={shouldStopVideo}
                isDesktop={isDesktop}
                openDialog={openDialog}
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
              />
              {displayPointBadge && (
                <div className={classes.cardRewardBadge}>
                  +{rewardPointBounty} POINTS
                </div>
              )}
            </div>
          )}

          <Grid className={classes.textWrapper}>
            <Grid className={classes.titleWrapper}>
              <Title title={activity.title} />
              <Grid className={classes.timeAgoContainer}>
                <div className={classes.timeAgo}>{cardCreatedTimeAgo}</div>
              </Grid>
            </Grid>
            <Grid className={classes.bodyWrapper}>
              <Message message={activity.message} />
            </Grid>
            {activity.buttonText && (
              <CardButton
                activity={activity}
                onVideoClickPreview={onVideoClickPreview}
                openButtonActionDialog={openButtonActionDialog}
                onCardButtonClicked={onCardButtonClicked}
                watchNow={watchNow}
                isCardDetailsPage={isCardDetailsPage}
                handleOpenDialog={handleOpenDialog}
                isDesktop={isDesktop}
                isVideoCard={!!activity.muxVideo?.playbackId}
              />
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
