import gql from 'graphql-tag';

export default gql`
  mutation CEDash_ShopWithCustomerDeleteMutation(
    $input: ShopWithCustomerDeleteInput!
  ) {
    shopWithCustomerDelete(input: $input) {
      cart {
        id
        taxTotal
        eventId
        totalNumberOfItems
        subTotalPriceOfItems
        promoCodeSavings
        viaQualifiedCartTotal
        subTotalPriceOfItemsWithPromoCodes
        entries(first: 1000) {
          edges {
            __typename
            cursor
            node {
              id
              quantity
              productId
            }
          }
        }
      }
      user {
        id
        firstName
        lastName
        cart {
          id
          taxTotal
          totalNumberOfItems
          subTotalPriceOfItems
          promoCodeSavings
          viaQualifiedCartTotal
          subTotalPriceOfItemsWithPromoCodes
          entries(first: 1000) {
            edges {
              __typename
              cursor
              node {
                id
                quantity
                productId
              }
            }
          }
        }
      }
    }
  }
`;
