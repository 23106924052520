import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Icons, Text, theme } from '@onehope/design-system-v2';
import { ShareMedium } from '../../../../generatedTypes/globalTypes';
import {
  ReachOutViewCount,
  ReachOutViewCountVariables,
} from '../../../queries/generatedTypes/ReachOutViewCount';
import REACH_OUT_VIEW_COUNTS from '../../../queries/ReachOutViewCounts';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  margin: '16px auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: '760px',
  },
});

const ViewCount = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const IconWrapper = styled('div')({
  height: '30px',
  svg: {
    width: '30px',
    height: '30px',
  },
});

const CountValueText = styled(Text)({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 420,
  padding: '5px 0 2px',
});

const CountLabelText = styled(Text)({
  fontSize: '11px',
  lineHeight: '16px',
  color: theme.palette.greyDark.main,
});

type Props = {
  reachOutId: string;
};

/**
 * Render how many views this Reach Out has had, broken down by medium.
 */
export default function ViewCounts({ reachOutId }: Props) {
  const [counts, setCounts] = useState<Map<ShareMedium, number | null>>(
    new Map([
      [ShareMedium.INSTAGRAM_STORY, null],
      [ShareMedium.FACEBOOK_FEED_POST, null],
      [ShareMedium.LINKED_IN_POST, null],
      [ShareMedium.MANUAL_LINK, null],
    ]),
  );

  useQuery<ReachOutViewCount, ReachOutViewCountVariables>(
    REACH_OUT_VIEW_COUNTS,
    {
      pollInterval: 10000,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true, // required for onComplete to run with pollInterval
      variables: {
        reachOutId,
      },
      onCompleted: data => {
        if (!data?.viewer?.v2?.reachOutTemplate?.viewCounts) {
          console.warn('No viewCount data received!');
          return;
        }

        const newCounts = new Map(counts);

        data.viewer.v2.reachOutTemplate.viewCounts.forEach(viewCount => {
          if (newCounts.has(viewCount.medium)) {
            newCounts.set(viewCount.medium, viewCount.viewCount);
          }
        });

        setCounts(newCounts);
      },
    },
  );

  return (
    <Wrapper>
      {Array.from(counts.entries()).map(([medium, count]) => {
        return (
          <ViewCount>
            <IconWrapper>
              {medium === ShareMedium.INSTAGRAM_STORY && (
                <Icons.InstagramLogo />
              )}
              {medium === ShareMedium.FACEBOOK_FEED_POST && (
                <Icons.FacebookLogo />
              )}
              {medium === ShareMedium.LINKED_IN_POST && <Icons.LinkedInLogo />}
              {medium === ShareMedium.MANUAL_LINK && (
                <Icons.ReachOutsCopyButton />
              )}
            </IconWrapper>

            {count === null && (
              <CountValueText>
                <>&mdash;</>
              </CountValueText>
            )}
            {count !== null && <CountValueText>{count}</CountValueText>}

            {count !== 1 && <CountLabelText>Clicks</CountLabelText>}
            {count === 1 && <CountLabelText>Click</CountLabelText>}
          </ViewCount>
        );
      })}
    </Wrapper>
  );
}
