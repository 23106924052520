import * as React from 'react';
import * as moment from 'moment';
import { InputDatePicker } from '../styles/ant-design-styles/datePicker';
import { TimePickerProps } from 'antd/lib/time-picker';
import { DatePickerMode } from 'antd/lib/date-picker/interface';

interface DatePickerProps {
  className?: string;
  style?: object;
  showTime?: TimePickerProps | boolean;
  showToday?: boolean;
  open?: boolean;
  disabledTime?: (
    current: moment.Moment | undefined,
  ) => {
    disabledHours?: () => number[];
    disabledMinutes?: () => number[];
    disabledSeconds?: () => number[];
  };
  onOpenChange?: (status: boolean) => void;
  onPanelChange?: (
    value: moment.Moment | undefined,
    mode: DatePickerMode,
  ) => void;
  onOk?: (selectedTime: moment.Moment) => void;
  placeholder?: string;
  mode?: DatePickerMode;
  disabled?: boolean;
  allowClear?: boolean;
  autoFocus?: boolean;
  size?: string;
  format?: string;
}

class DatePicker extends React.Component<DatePickerProps, {}> {
  render() {
    const { ...rest } = this.props;
    return <InputDatePicker {...rest} />;
  }
}

export default DatePicker;
