import React from 'react';

type IframeProps = {
  src: string;
  id: string;
  title: string;
  name: string;
  width?: string;
  height?: string;
};

function Iframe(props: IframeProps) {
  const { src, id, name, width, height } = props;
  const styleWidth = width || '100%';
  const styleHeight = height || '100%';

  return (
    <>
      <iframe
        src={src}
        id={id}
        title={id}
        className={name}
        width={styleWidth}
        height={styleHeight}
        frameBorder="0"
        style={{
          padding: 0,
          margin: 0,
        }}
      ></iframe>
    </>
  );
}

export default Iframe;
