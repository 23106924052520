export enum TypeKeys {
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
}

export type StateType = {
  drawerOpen: boolean;
};

export type UpdateToggleDrawerAction = {
  type: TypeKeys.TOGGLE_DRAWER;
  drawerOpen: boolean;
};

export type ActionTypes = UpdateToggleDrawerAction;
