import React from 'react';
import { Icons, ChunkyButton } from '@onehope/design-system-v2';

import { useCauseOfChoiceContextValue } from '../../../../stateManagement/context';
// import { useCauseOfChoiceNetworkLayerValue } from '../../../../stateManagement/networkLayer';

import {
  CauseOfChoiceScreenDictionary,
  // CauseOfChoiceViewsDictionary,
  TypeKeys,
} from '../../../../stateManagement/types';
import { useCommonStyles } from '../../../../utils/commonStyles';

const { COCHeartHands } = Icons;

export default function NonProfitButton() {
  const commonStyles = useCommonStyles();

  const [, dispatch] = useCauseOfChoiceContextValue();
  // const { modalView } = useCauseOfChoiceNetworkLayerValue();

  const primaryText = 'Search for a nonprofit';
  //   modalView === CauseOfChoiceViewsDictionary.CE_PROFILE_VIEW
  //     ? 'Your own cause of choice'
  //     : `A nonprofit`;

  const handleClick = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_SEARCH_SCREEN,
    });
  };
  return (
    <div className={commonStyles.container}>
      <ChunkyButton
        avatar={<COCHeartHands />}
        primaryText={primaryText}
        secondaryText="Support any 501(c)3 including school PTAs, food banks, animal shelters, etc."
        onClick={handleClick}
      />
    </div>
  );
}
