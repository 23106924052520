import React from 'react';
import { ThemeProvider, theme } from '@onehope/design-system-v2';

import {
  BaseContainer,
  TabContentContainer,
} from '../../../Event/TabContent/index.styles';
import UploadImageForm from '../../../Events/UploadImageForm';

export type ImageValuesType = {
  imageCanvas: string;
  mimeType: string;
};

interface UploadImageProps {
  form: any;
  loadingEventCreation: boolean;
  isWineTasting: boolean;
  handleNextStepChange: (
    key: string,
    imageValues: ImageValuesType,
  ) => Promise<void> | undefined;
  handlePreviousStepChange: () => void;
  isOnline: boolean;
}

export default function UploadImage(props: UploadImageProps) {
  const {
    handlePreviousStepChange,
    loadingEventCreation,
    isWineTasting,
    isOnline,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <BaseContainer>
        <TabContentContainer>
          <UploadImageForm
            {...props}
            editing={false}
            isOnline={isOnline}
            isWineTasting={isWineTasting}
            loadingEventCreation={loadingEventCreation}
            handlePreviousStepChange={handlePreviousStepChange}
            /* eslint-disable react/jsx-no-bind */
            handleNextStepChange={async (imageValues: ImageValuesType) => {
              await props.handleNextStepChange('imageUpload', imageValues);
            }}
          />
        </TabContentContainer>
      </BaseContainer>
    </ThemeProvider>
  );
}
