import React, { useEffect, memo } from 'react';
import {
  Container,
  Dialog,
  makeStyles,
  ThemeType,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';

import ScreenSelector from './ScreenSelecter';

import { useCauseOfChoiceNetworkLayerValue } from '../stateManagement/networkLayer';
import { useCauseOfChoiceContextValue } from '../stateManagement/context';
import {
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../stateManagement/types';

const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    padding: 24,
    backgroundColor: theme.palette.neutral0.main,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      padding: 48,
    },
  },
  loader: {
    padding: '25%',
  },
}));

const useDialogStyles = (dialogHeight: number) =>
  makeStyles((theme: ThemeType) => ({
    paper: {
      borderRadius: 4,
      minWidth: 320,
      maxHeight: '100%',
      [theme.breakpoints.up('sm')]: {
        height: dialogHeight,
        width: 780,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 780,
      },
    },
  }));

function getDialogHeight(screen: CauseOfChoiceScreenDictionary) {
  switch (screen) {
    case CauseOfChoiceScreenDictionary.NON_SURE_CONFIRMATION_SCREEN:
      return 762;
    default:
      return 688;
  }
}

function Modal() {
  const { initialScreen, open } = useCauseOfChoiceNetworkLayerValue();
  const [{ screen }, dispatch] = useCauseOfChoiceContextValue();
  const dialogHeight = getDialogHeight(screen);
  const theme = useTheme();
  const classes = useStyles();
  const dialogClasses = useDialogStyles(dialogHeight)();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    if (open) {
      dispatch({
        type: TypeKeys.UPDATE_SCREEN,
        screen: initialScreen,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} classes={dialogClasses} fullScreen={isMobile}>
      <Container className={classes.container}>
        <ScreenSelector />
      </Container>
    </Dialog>
  );
}

export default memo(Modal);
