import RadioToggle from 'antd/lib/radio';
import 'antd/lib/radio/style/index.css';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { primaryColor },
} = styles;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures.
export const Radio = styled(RadioToggle)`
  .ant-radio-inner,
  .ant-radio-inner:hover,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: ${primaryColor} !important;
  }
  .ant-radio-inner::after {
    background-color: ${primaryColor} !important;
  }
` as any;
