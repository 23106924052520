import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';

import {
  ButtonBase,
  makeStyles,
  FormControlLabel,
  Switch,
  Grid,
  Text,
} from '@onehope/design-system-v2';

/* Components */
import SaveButtons from '../../Dialog/EventCreateDialog/SaveButtons';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';

/* Types */
import { MyFormValues } from '../../Event/TabContent/Charity/FormTypes';
import {
  CEDash_EventDetailsPage_viewer_event as EventType,
  CEDash_EventDetailsPage_viewer_event_donationInfo as DonationInfoType,
} from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { CEDash_NonProfitsQuery_viewer_user_nonProfits as NonProfitsType } from '../../../queries/generatedTypes/CEDash_NonProfitsQuery';

/* Helpers & Functions */
import useToggle from '../../../utils/useToggleHook';

import { EventStatusDictionary } from '../../../utils/enums';

/* Styles */
import { styles } from '@onehope/design-system';
import { MuiInputAutosize } from '@onehope/design-system-ohw';
import { Title, LongInput, DonationContainer, Details } from './index.styles';
import CurrencyMask from './CurrencyMask';

import NonProfitSelection from '../NonProfitV2Selection';

const { styled: s } = styles;

interface CharityDetailsFormProps {
  event: EventType;
  donation: DonationInfoType;
  nonProfits: NonProfitsType;
  editing: boolean;
  setIsNotEditing: () => void;
  handlePreviousStepChange: () => void;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

const useStyles = makeStyles(() => ({
  skip: {
    fontWeight: 400,
    paddingTop: 24,
    fontSize: '12px',
    lineHeight: '16px',
    width: '100%',
  },
  formControl: {
    marginRight: 0,
  },
  label: {
    margin: '24px 0 8px 0',
  },
  genericLabel: {
    margin: '24px 0 8px 0',
  },
}));

export default function CharityDetailsForm(
  props: FormProps & FormikProps<MyFormValues> & CharityDetailsFormProps,
) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleReset,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    handlePreviousStepChange,
    editing,
    mutationLoading,
    donation,
    event,
    setIsNotEditing,
  } = props;
  const {
    charityType,
    preApprovedNonProfit,
    customNonProfit,
    charityDonationGoal,
    charityQuote,
    attentionToName,
    displayAddress,
    addressLineOne,
    addressLineTwo,
    state,
    city,
    zip,
    nonProfitTaxId,
    lobMemo,
    showDonationGoal,
    nonProfitId: nonProfitV2Id,
    nonProfitEditId,
  } = values;

  // Not using for loop to avoid warning
  useEffect(() => {
    localStorage.setItem('charityType', charityType ? charityType : '');
    setFieldTouched('charityType', true, false);
  }, [charityType, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'preApprovedNonProfit',
      preApprovedNonProfit ? preApprovedNonProfit : '',
    );
  }, [preApprovedNonProfit]);

  useEffect(() => {
    localStorage.setItem(
      'customNonProfit',
      customNonProfit ? customNonProfit : '',
    );
  }, [customNonProfit]);

  useEffect(() => {
    localStorage.setItem(
      'charityDonationGoal',
      charityDonationGoal ? charityDonationGoal : '',
    );
  }, [charityDonationGoal]);

  useEffect(() => {
    localStorage.setItem(
      'showDonationGoal',
      showDonationGoal ? showDonationGoal : '',
    );
  }, [showDonationGoal]);

  useEffect(() => {
    localStorage.setItem('charityQuote', charityQuote ? charityQuote : '');
  }, [charityQuote]);

  useEffect(() => {
    localStorage.setItem(
      'attentionToName',
      attentionToName ? attentionToName : '',
    );
  }, [attentionToName]);

  useEffect(() => {
    localStorage.setItem(
      'charityDisplayAddress',
      displayAddress ? displayAddress : '',
    );
  }, [displayAddress]);

  useEffect(() => {
    localStorage.setItem(
      'charityAddressLineOne',
      addressLineOne ? addressLineOne : '',
    );
  }, [addressLineOne]);

  useEffect(() => {
    localStorage.setItem(
      'charityAddressLineTwo',
      addressLineTwo ? addressLineTwo : '',
    );
  }, [addressLineTwo]);

  useEffect(() => {
    localStorage.setItem('charityCity', city ? city : '');
  }, [city]);

  useEffect(() => {
    localStorage.setItem('charityState', state ? state : '');
  }, [state]);

  useEffect(() => {
    localStorage.setItem('charityZip', zip ? zip : '');
  }, [zip]);

  useEffect(() => {
    localStorage.setItem(
      'nonProfitTaxId',
      nonProfitTaxId ? nonProfitTaxId : '',
    );
  }, [nonProfitTaxId]);

  useEffect(() => {
    localStorage.setItem('nonProfitId', nonProfitV2Id ? nonProfitV2Id : '');
    setFieldTouched('nonProfitId', true, false);
  }, [nonProfitV2Id, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'nonProfitEditId',
      nonProfitEditId ? nonProfitEditId : '',
    );
    setFieldTouched('nonProfitEditId', true, false);
  }, [nonProfitEditId, setFieldTouched]);

  const {
    value: showStartAdornment,
    setTrue: setShowStartAdornmentTrue,
    setFalse: setShowStartAdornmentFalse,
  } = useToggle(!!charityDonationGoal);
  const classes = useStyles();

  /* FUNCTIONS */
  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleNonProfitV2Selected = ({
    nonProfitId,
    nonProfitEditId,
  }: {
    nonProfitId: string;
    nonProfitEditId?: string;
  }) => {
    setFieldValue('nonProfitId', nonProfitId);
    setFieldValue('nonProfitEditId', nonProfitEditId);
  };

  const noop = () => {};

  const textChangeValid = (name: string, event: React.SyntheticEvent) => {
    return name === 'charityQuote'
      ? event.target.value.length <= 600
      : event.target.value.length <= 40;
  };

  const onCancel = () => {
    handleReset();
    return setIsNotEditing();
  };

  const toggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const name = 'showDonationGoal';
    setFieldValue(name, e.target.checked?.toString());
    setFieldTouched(name, true, false);
    setFieldValue('charityDonationGoal', '');
  };

  const ordersSubTotal = donation?.ordersSubTotal ?? 0;
  const qualified = ordersSubTotal > 500;
  const notAcceptingOrders =
    event?.trinityPartyStatus === EventStatusDictionary.CLOSED;
  const editable = editing
    ? event?.status === EventStatusDictionary.OPEN
    : true;
  const hostQuotePlaceholder =
    'Enter a message from your host that will appear on their event’s landing page. Ask them “Why you are raising funds for this cause?” A strong why yields stronger support.';
  const lobMemoPlaceholder = 'Write a quick note for your donation check';
  const showDonationGoalInput = showDonationGoal === 'true';

  const disableSave =
    (charityType === 'OHW-PARTNER' && !preApprovedNonProfit) ||
    (!qualified && isEmpty(touched));

  return (
    <form onSubmit={handleSubmit}>
      {!editing && (
        <s.LoginHeader css>
          Enter the charity details{' '}
          <span role="img" aria-labelledby="red-heart">
            ❤️
          </span>
        </s.LoginHeader>
      )}
      {editable && (
        <>
          <NonProfitSelection
            nonProfitId={nonProfitV2Id}
            oldNonProfit={event?.donationInfo?.nonProfit}
            nonProfitEditId={nonProfitEditId}
            handleNonProfitSelected={handleNonProfitV2Selected}
          />
          <Details>
            {!notAcceptingOrders && (
              <div>
                <DonationContainer>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Title>Set a donation goal</Title>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        disabled={false}
                        className={classes.formControl}
                        control={
                          <Switch
                            checked={showDonationGoal === 'true'}
                            onChange={toggleCheckbox}
                            value="showDonationGoal"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  {showDonationGoalInput && (
                    <div>
                      <Text className={classes.label}>
                        How much would you like to raise?
                      </Text>
                      <LongInput
                        placeholder={showStartAdornment ? '' : '$500'}
                        id="charityDonationGoal"
                        name="charityDonationGoal"
                        value={charityDonationGoal}
                        fullWidth
                        validate
                        mask={CurrencyMask}
                        onFocus={setShowStartAdornmentTrue}
                        onBlur={
                          charityDonationGoal
                            ? noop
                            : setShowStartAdornmentFalse
                        }
                        startAdornment={showStartAdornment ? '$' : null}
                        hasError={
                          !isSubmitting &&
                          touched.charityDonationGoal &&
                          Boolean(errors.charityDonationGoal)
                        }
                        hasSuccess={
                          editing
                            ? !isSubmitting &&
                              ((isEmpty(touched) && isEmpty(errors)) ||
                                !Boolean(errors.charityDonationGoal))
                            : !isSubmitting &&
                              !Boolean(errors.charityDonationGoal)
                        }
                        error={
                          touched.charityDonationGoal &&
                          Boolean(errors.charityDonationGoal)
                        }
                        helperText={
                          errors.charityDonationGoal &&
                          touched.charityDonationGoal &&
                          errors.charityDonationGoal
                        }
                        onChange={(e: React.SyntheticEvent) =>
                          change('charityDonationGoal', e)
                        }
                      />
                    </div>
                  )}
                </DonationContainer>
                <Grid>
                  <Text className={classes.label}>
                    Message from your Host (optional)
                  </Text>
                  <MuiInputAutosize
                    rows={6}
                    rowsMax={16}
                    maxCount="600"
                    placeholder={hostQuotePlaceholder}
                    value={charityQuote}
                    name="charityQuote"
                    id="charityQuote"
                    validInput={charityQuote.length > 0}
                    onChange={(e: React.SyntheticEvent) => {
                      if (textChangeValid('charityQuote', e)) {
                        change('charityQuote', e);
                      }
                    }}
                  />
                </Grid>
              </div>
            )}
            <Grid>
              <Text className={classes.label}>Check memo (optional)</Text>
              <MuiInputAutosize
                rows={1}
                rowsMax={16}
                maxCount="40"
                placeholder={lobMemoPlaceholder}
                value={lobMemo}
                name="lobMemo"
                id="lobMemo"
                validInput={lobMemo.length > 0}
                onChange={(e: React.SyntheticEvent) => {
                  if (textChangeValid('lobMemo', e)) {
                    change('lobMemo', e);
                  }
                }}
              />
            </Grid>
          </Details>
          {!editing && (
            <ButtonBase className={classes.skip} onClick={handleSubmit}>
              SKIP FOR NOW
            </ButtonBase>
          )}
          {editing ? (
            <SaveButtons
              cancelButtonText="CANCEL"
              saveButtonText="SAVE"
              handleCancel={onCancel}
              mutationLoading={mutationLoading}
              handleSave={mutationLoading ? () => {} : handleSubmit}
              disableSave={disableSave}
            />
          ) : (
            <FooterButtons
              prevStepButtonText="BACK"
              nextStepButtonText={'NEXT'}
              handleNextStepChange={handleSubmit}
              handlePrevStepChange={handlePreviousStepChange}
              disableNext={false}
            />
          )}
        </>
      )}
    </form>
  );
}
