import styled from '@emotion/styled';
import { DateTimePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  font,
  neutral2,
  inputFocusColor,
  errorColor,
  primaryColor,
  neutral34,
  neutral0,
} from '../../styles/cssConstants';

type DatePickerProps = {
  error?: boolean;
  hasSuccess?: boolean;
};

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const MuiDateTimePicker = styled(DateTimePicker)<DatePickerProps>`
  & .MuiInputBase-input {
    font-family: ${font};
    height: 42.22px;
    padding: 0.15rem 16px;
    font-weight: normal;
    line-height: 1.57;
    font-size: 14px;
    background-color: ${({ hasSuccess }: DatePickerProps) =>
      hasSuccess ? neutral0 : neutral2};
    border-bottom: 1px solid ${primaryColor};
  }
  & .MuiInputBase-input:focus {
    background-color: ${({ error }: DatePickerProps) =>
      error ? '#fef8f5' : inputFocusColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ error }: DatePickerProps) =>
      error ? errorColor : primaryColor};
  }
  & .MuiFormHelperText-root {
    font-family: ${font};
    color: ${errorColor};
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    margin-top: 8px;
  }
  & .MuiInputAdornment-root {
    position: absolute;
    right: 16px;
  }
  & .MuiInputAdornment-root {
    right: 0px;
  }
  & .MuiPickersDay-daySelected {
    color: ${primaryColor};
  }
`;

// @ts-ignore: Type 'CreateStyled<any>' has no call signatures
export const DateIcon = styled(DateRangeIcon)`
  && {
    color: black;
  }
`;

export const Label = styled.label`
  font-family: ${font};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${neutral34};
`;
