import Input from 'antd/lib/input';
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system-ohw';
const {
  cssConstants: { errorColor, font, primaryColor, textColor, inputFocusColor },
} = styles;

const baseStyle = css`
  border-radius: 0 !important;
  outline: none !important;
  font-family: ${font} !important;
  font-weight: 300 !important;
  line-height: 1.57 !important;
  color: ${textColor} !important;
  font-size: 14px !important;
  height: 52px !important;
  background-color: rgba(250, 250, 250, 0.04) !important;
  box-shadow: inset 0 -1px 0 0 ${primaryColor} !important;
  border: none !important;
  &:hover {
    box-shadow: inset 0 -1px 0 0 ${primaryColor} !important;
  }
  &:focus {
    background-color: ${inputFocusColor} !important;
    box-shadow: inset 0 -2px 0 0 ${primaryColor} !important;
  }
  &.has-error {
    box-shadow: inset 0 -2px 0 0 ${errorColor} !important;

    &:focus {
      background-color: #fef8f5 !important;
      box-shadow: inset 0 -2px 0 0 ${errorColor} !important;
    }
  }
`;

// @ts-ignore CreatedStyle has no call signature
export const InputPrimary = styled(Input)`
  ${baseStyle};
` as any;
