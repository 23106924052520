import React from 'react';
import { useQuery } from '@apollo/client';
// material-ui
import { GridV2 as Grid, Icons, GridSpacing } from '@onehope/design-system-v2';
import StatCard from './StatCard';

import DASHBOARD_STATISTICS_QUERY from '../../../queries/DashboardStatistics';
import { CEDash_StatisticsQuery } from '../../../queries/generatedTypes/CEDash_StatisticsQuery';

const { CEDashCircleGraph } = Icons;

interface StatisticsContainerProps {
  gridSpacing: GridSpacing;
}
const StatisticsContainer = ({ gridSpacing }: StatisticsContainerProps) => {
  const { data } = useQuery<CEDash_StatisticsQuery>(DASHBOARD_STATISTICS_QUERY);

  const kpi = data?.viewer?.user?.kpi;
  const hasPcvConverted = !!kpi && kpi.currentPeriodPCV !== null;
  const hasOvConverted = !!kpi && kpi.currentPeriodOV !== null;
  const pcvConverted = parseFloat(kpi?.currentPeriodPCV || '0');
  const ovConverted = parseFloat(kpi?.currentPeriodOV || '0');

  return (
    <Grid
      id="StatisticsContainer"
      data-kpi={JSON.stringify(kpi)}
      container
      columnSpacing={gridSpacing}
      rowSpacing={gridSpacing}
    >
      {hasPcvConverted && (
        <Grid item xs={12} lg={6}>
          <StatCard
            heading="PCV"
            value={pcvConverted}
            iconPrimary={CEDashCircleGraph}
          />
        </Grid>
      )}
      {hasOvConverted && (
        <Grid item xs={12} lg={6}>
          <StatCard
            heading="OV"
            value={ovConverted}
            iconPrimary={CEDashCircleGraph}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default StatisticsContainer;
