import { useMutation, useQuery } from '@apollo/client';
import {
  ListItemIcon,
  Icons,
  Text,
  theme,
  useTheme,
  styled,
} from '@onehope/design-system-v2';
import { useState } from 'react';
import {
  CEDash_GenerateExigoLoginURIMutation,
  CEDash_GenerateExigoLoginURIMutationVariables,
} from '../../../../../mutations/Exigo/generatedTypes/CEDash_GenerateExigoLoginURIMutation';
import GenerateExigoLoginURIMutation from '../../../../../mutations/Exigo/GenerateExigoLoginURIMutation';
import ACCOUNT_CAN_USE_EXIGO_BACK_OFFICE from '../../../../../queries/AccountCanUseExigoBackOffice';
import { CEDash_AccountCanUseExigoBackOfficeQuery } from '../../../../../queries/generatedTypes/CEDash_AccountCanUseExigoBackOfficeQuery';
import { CustomListItem } from './NavigationStyled';

const NewLabel = styled('span')(() => ({
  fontWeight: 700,
  fontSize: theme.spacing(1.7),
  textTransform: 'uppercase',
  paddingRight: theme.spacing(0.5),
  color: theme.palette.yellow.main,
}));

/**
 * Self-contained component for the Exigo Back Office menu item.
 * Hides itself by default, appearing based on the result of a GQL query.
 * Upon click calls and awaits a mutation to get the "silent login" URL, then navigates there.
 */
export const ExigoBackOfficeMenuItem = (): JSX.Element => {
  const theme = useTheme();
  const [authError, setAuthError] = useState<boolean>(false);

  const { data } = useQuery<CEDash_AccountCanUseExigoBackOfficeQuery>(
    ACCOUNT_CAN_USE_EXIGO_BACK_OFFICE,
  );
  const [generateLoginURI, { loading: mutationLoading }] = useMutation<
    CEDash_GenerateExigoLoginURIMutation,
    CEDash_GenerateExigoLoginURIMutationVariables
  >(GenerateExigoLoginURIMutation);

  const handleClick = async () => {
    try {
      const result = await generateLoginURI({
        variables: {
          input: {},
        },
      });

      if (result.data?.generateExigoLoginURI?.loginURI) {
        window.open(result.data.generateExigoLoginURI.loginURI, '_blank');
      }
    } catch (e) {
      setAuthError(true);
    }
  };

  if (!data?.viewer?.user?.canAccessExigoBackOffice) {
    return null;
  }

  if (authError) {
    return (
      <CustomListItem
        button
        selected={false}
        theme={theme}
        onClick={handleClick}
        disabled={true}
      >
        <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
          <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
            <Icons.LockOpen />
          </ListItemIcon>
          <Text className="text">Auth error!</Text>
        </ListItemIcon>
      </CustomListItem>
    );
  }

  if (mutationLoading) {
    return (
      <CustomListItem
        button
        selected={false}
        theme={theme}
        onClick={handleClick}
        disabled={true}
      >
        <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
          <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
            <Icons.LockOpen />
          </ListItemIcon>
          <Text className="text">Authorizing...</Text>
        </ListItemIcon>
      </CustomListItem>
    );
  }

  return (
    <CustomListItem button selected={false} theme={theme} onClick={handleClick}>
      <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
        <ListItemIcon sx={{ color: 'inherit', mr: -2 }}>
          <Icons.LockOpen pathProps={{ stroke: '#fff', opacity: '0.8' }} />
        </ListItemIcon>
        <Text className="text">
          <NewLabel>New</NewLabel> Reports
        </Text>
      </ListItemIcon>
    </CustomListItem>
  );
};
