import React, { Fragment, Dispatch, SetStateAction, useState } from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import {
  Icons,
  useTheme,
  useMediaQuery,
  GridV2 as Grid,
  ButtonBase,
} from '@onehope/design-system-v2';

import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import NoOrders from '../../../common/Orders/NoOrders';

import TabFilter from '../../TableComponents/TabFilter';
import SearchBar from '../../Events/EventsTable/SearchBar';
import CardListView from '../../TableComponents/CardListView';
import TablePagination from '../../TableComponents/Pagination';
import OrderAddDialog from '../../Events/AddOrderDialog';

import TableListView from './TableBody';

const { cssConstants } = s;
const {
  AppsIcon: MuiAppsIcon,
  FormatListBulletedIcon: MuiFormatListBulletedIcon,
} = Icons;

const Container = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.font};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const GridContainer = styled(Grid)`
  padding: 16px 0 0 0;
  @media all and (min-width: 960px) {
    padding: 0;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const ToggleView = styled.div`
  width: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

const NoFilteredOrders = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

interface OrdersTableProps {
  orders: (OrdersEdges | null)[] | null;
  page: number;
  filterBy: string;
  setPageNumber: Dispatch<SetStateAction<string | string[]>>;
  setFilterBy: Dispatch<SetStateAction<string | null | undefined>>;
  handleSortOrders: (sortBy: string) => void;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  count: number;
  setSearchBy: Dispatch<SetStateAction<string | null | undefined>>;
  isOrderDialogOpen: boolean;
  setIsOrderDialogOpen: Dispatch<SetStateAction<boolean>>;
  event?: EventType;
}

export default function OrdersTable({
  page,
  setPageNumber,
  orders,
  setFilterBy,
  filterBy,
  handleSortOrders,
  setRowsPerPage,
  rowsPerPage,
  count,
  setSearchBy,
  isOrderDialogOpen,
  setIsOrderDialogOpen,
  event,
}: OrdersTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSearchingBy, setIsSearchingBy] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const selectedEventId = event?.eventId;

  const [isOrdersTableView, setIsOrdersTableView] = useState(
    localStorage.getItem('isOrdersTableView')
      ? localStorage.getItem('isOrdersTableView') === 'true'
      : true,
  );
  const toggleView = () => {
    localStorage.setItem('isOrdersTableView', `${!isOrdersTableView}`);
    setIsOrdersTableView(!isOrdersTableView);
  };

  const handleSearchOrders = (search: string) => {
    setSearchBy(search);
    setIsSearchingBy(true);
  };

  const handleFilterOrders = (filter: string) => {
    setIsFiltering(true);
    setFilterBy(filter);
  };

  return (
    <Fragment>
      <OrderAddDialog
        selectCustomer
        isOpen={isOrderDialogOpen}
        selectedEventId={selectedEventId}
        handleClose={setIsOrderDialogOpen}
      />
      {(orders && orders.length > 0) || isSearchingBy || isFiltering ? (
        <Fragment>
          <Container>
            <Toolbar>
              <SearchBar
                handleSearch={handleSearchOrders}
                placeHolderText="Search orders"
              />
              <GridContainer container alignItems="center" spacing={1}>
                <Grid item xs>
                  <TabFilter
                    filterBy={filterBy}
                    setFilterBy={handleFilterOrders}
                    tabValues={['all', 'open', 'cancelled']}
                  />
                </Grid>
                {!isMobile && (
                  <Grid item>
                    <ToggleView>
                      <ButtonBase onClick={toggleView}>
                        {isOrdersTableView ? (
                          <AppsIcon />
                        ) : (
                          <FormatListBulletedIcon />
                        )}
                      </ButtonBase>
                    </ToggleView>
                  </Grid>
                )}
              </GridContainer>
            </Toolbar>
            {isOrdersTableView && !isMobile ? (
              <TableListView
                orders={orders}
                handleSortOrders={handleSortOrders}
              />
            ) : (
              <CardListView listItems={orders} baseRoute={'orders'} />
            )}
            {isFiltering && (!orders || (orders && !orders.length)) && (
              <NoFilteredOrders>
                There are no {filterBy} orders
              </NoFilteredOrders>
            )}
            <TablePagination
              page={page}
              count={count}
              rowsOnPage={(orders && orders.length) || 0}
              setPageNumber={setPageNumber}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              baseRoute={'customer-orders'}
            />
          </Container>
        </Fragment>
      ) : (
        <NoOrders
          openDialog={setIsOrderDialogOpen}
          noOrdersText={'There are no orders yet'}
          openForOrders={true}
          contactName={null}
        />
      )}
    </Fragment>
  );
}
