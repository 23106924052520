import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import {
  LoaderDark,
  useTheme,
  theme,
  useMediaQuery,
  Text,
  Button,
} from '@onehope/design-system-v2';

const { cssConstants } = s;

export const TableContainer = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.bodyFontFamily};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px 0 16px;
  flex-direction: column;
  text-align: center;

  ${theme.breakpoints.up('md')} {
    padding: 24px 40px 0 40px;
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
  }
`;

export const HeaderLeft = styled.div`
  a {
    color: ${theme.palette.forestGreen.main};
    text-decoration: underline;
    font-size: 14px;
    font-weight: 420;
    line-height: 21px;
    text-align: left;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  margin-left: -10px;
  @media all and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const HeaderTitle = styled(Text)`
  && {
    font-weight: 300;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.03em;
    margin-bottom: 1rem;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  padding-top: 16px;

  ${theme.breakpoints.up('md')} {
    justify-content: center;
  }
`;

export const GridList = styled('div')<{ count: number }>`
  display: grid;
  margin-bottom: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 24px;
  grid-auto-flow: dense;
  max-width: ${({ count }) => (count < 3 ? '756px' : '100%')};
  @media all and (min-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
  }
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 288px;
  gap: 8px;
  cursor: pointer;
`;

export const DownloadCompleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 288px;
  gap: 8px;

  > p {
    margin-right: 10px;
  }
`;

export const ExportAutoshipsLink = styled(Text)`
  margin-right: 10px;
  color: ${theme.palette.forestGreen.main};
  :hover {
    cursor: pointer;
  }
`;
