export enum SubscriptionStatuses {
  Active = 'active',
  Cancelled = 'cancelled',
  NoPaymentRequired = 'no payment required',
  PaymentNotSent = 'payment not sent',
  PaymentReceived = 'payment received',
  PaymentPending = 'payment pending',
  StripeError = 'stripe error',
}

export const formatPhone = (value: string | null) => {
  if (!value) return null;
  let i = 0;
  const number = value.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, (_) => number[i++]);
};
