import React from 'react';
import { DateTime } from 'luxon';
import Decimal from 'decimal.js';
import {
  Grid,
  Text,
  TEXT_DS,
  Spacer,
  theme,
  styled,
  Icons,
} from '@onehope/design-system-v2';
import { Currency } from '@onehope/design-system';
const { AlertCircleOutline } = Icons;

import { CEDash_AutoshipsGetQuery_viewer_user_autoShips as SubscriptionType } from '../../../../../queries/generatedTypes/CEDash_AutoshipsGetQuery';
import { CEDash_ContactV2Query_viewer_v2_contactsV2_contact as ContactType } from '../../../../../queries/generatedTypes/CEDash_ContactV2Query';

const SubscriptionCard = styled(Grid)({
  border: `1px solid ${theme.palette.greyMedium.main}`,
  borderRadius: '4px',
  padding: '16px',
  maxWidth: '460px',
  position: 'relative',
});

const PaymentError = styled(Grid)({
  gap: 10,
});

const LabelText = styled(Text)({
  flex: 1,
  color: theme.palette.greyDark.main,
});

const ValueText = styled(Text)({
  flex: 2,
});

const ManageLink = styled(Text)({
  color: theme.palette.forestGreen.main,
  position: 'absolute',
  bottom: '16px',
  right: '16px',
  cursor: 'pointer',
});

interface WineClubWrapperProps {
  subscription: SubscriptionType;
  contact: ContactType;
  openSubscriptionDialog: (
    autoShipId: string,
    isEditable: boolean,
    isContactCe: boolean,
    subscriptionHasCe: string | null,
  ) => void;
}

function SubscriptionSummary(props: WineClubWrapperProps) {
  const { subscription, contact, openSubscriptionDialog } = props;
  const {
    autoShipId,
    isEditable,
    ceoAccountFullName,
    billingIntervalCount,
    billingIntervalUnit,
    winePackCount,
    price,
    nextPaymentDate,
    shippingAddress: { addressLineOne },
    paymentStatus,
  } = subscription;
  const { isCe } = contact;

  const paymentError = paymentStatus === 'invoice.payment_failed';

  const frequency =
    parseInt(billingIntervalCount) > 1
      ? `Every ${billingIntervalCount} ${billingIntervalUnit}s`
      : `Every ${billingIntervalUnit}`;

  const quantity = `${winePackCount || 0} bottles`;

  const finalPrice = new Decimal(price || 0).toNumber();

  const nextShipment = nextPaymentDate
    ? DateTime.fromISO(nextPaymentDate, {
        zone: 'America/Chicago',
      }).toLocaleString(DateTime.DATE_MED)
    : '-';

  const address = addressLineOne || '-';

  const onManageClick = () => {
    openSubscriptionDialog(autoShipId, isEditable, isCe, ceoAccountFullName);
  };

  return (
    <SubscriptionCard container direction="column">
      {paymentError && (
        <Grid item>
          <PaymentError container alignItems="center" direction="row">
            <AlertCircleOutline />
            <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_ITALIC_14}>
              Update payment method
            </Text>
          </PaymentError>
          <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
        </Grid>
      )}
      <Grid item>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
          {subscription?.autoShipName}
        </Text>
      </Grid>
      <Spacer xl="8px" lg="8px" sm="8px" xs="8px" />
      <Grid container>
        <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          Frequency:
        </LabelText>
        <ValueText variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {frequency}
        </ValueText>
      </Grid>
      <Spacer xl="4px" lg="4px" sm="4px" xs="4px" />
      <Grid container>
        <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          Quantity:
        </LabelText>
        <ValueText variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {quantity}
        </ValueText>
      </Grid>
      <Spacer xl="4px" lg="4px" sm="4px" xs="4px" />
      <Grid container>
        <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          Price:
        </LabelText>
        <ValueText variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          <Currency rawValue={finalPrice}></Currency>
        </ValueText>
      </Grid>
      <Spacer xl="4px" lg="4px" sm="4px" xs="4px" />
      <Grid container>
        <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          Next shipment:
        </LabelText>
        <ValueText variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {nextShipment}
        </ValueText>
      </Grid>
      <Spacer xl="4px" lg="4px" sm="4px" xs="4px" />
      <Grid container>
        <LabelText variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          Shipping address:
        </LabelText>
        <ValueText variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
          {address}
        </ValueText>
      </Grid>
      <ManageLink
        variant="custom"
        default={TEXT_DS.BODY_LINK_14}
        onClick={onManageClick}
      >
        Manage
      </ManageLink>
    </SubscriptionCard>
  );
}

export default SubscriptionSummary;
