import gql from 'graphql-tag';
import { EventFragment } from '../../queries/Event';

const mutation = gql`
  mutation EventUpdateMutation($input: EventUpdateInput!) {
    eventUpdate(input: $input) {
      event {
        ...Event_event
      }
      user {
        id
        numberOfAvailableCredits
        availableStarterKitCredits
      }
    }
  }
  ${EventFragment}
`;

export default mutation;
