import React from 'react';
import { TooltipProps } from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ToolTip } from './index.styles';

export default function MuiToolTip(props: TooltipProps) {
  return (
    <ToolTip arrow {...props}>
      <InfoOutlinedIcon />
    </ToolTip>
  );
}
