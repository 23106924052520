import React from 'react';
import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplate } from '../../../queries/generatedTypes/CEDash_ReachOutV2';

type Props = {
  reachOutTemplate: ReachOutTemplate;
};

/**
 * Render `<link rel="preload" ... />` tags for each thumbnail image used in the Reach Out asset download modal.
 */
export function PrefetchThumbnails({ reachOutTemplate }: Props) {
  return (
    <>
      {reachOutTemplate.coverImageUrl && (
        <link
          rel="preload"
          as="image"
          href={`${reachOutTemplate.coverImageUrl}?w=500`}
        />
      )}
      {reachOutTemplate.verticalImageUrl && (
        <link
          rel="preload"
          as="image"
          href={`${reachOutTemplate.verticalImageUrl}?h=500`}
        />
      )}
      {reachOutTemplate.muxVideo?.playbackId && (
        <link
          rel="preload"
          as="image"
          href={[
            'https://image.mux.com',
            reachOutTemplate.muxVideo.playbackId,
            'thumbnail.png?height=500&width=280&fit_mode=smartcrop&time=1',
          ].join('/')}
        />
      )}
    </>
  );
}
