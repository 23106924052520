/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';

import styled from '@emotion/styled';
import FullHeightImage from './FullHeightImage';

interface ImageProps {
  preset: string;
  assetsUrl: string;
  height?: string;
}
interface SplitFullScreenProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  leftImage?: ImageProps;
  rightImage?: ImageProps;
  rightStyle?: SerializedStyles;
}

const PageLayout = styled.div`
  display: flex;
  min-height: 100vh;
`;

const LeftSide = styled.div`
  flex: 1;
`;

const RightSide = styled.div`
  display: none;
  @media all and (min-width: 1024px) {
    display: block;
    flex: 1;
  }
`;

const PaddedContent = styled.div`
  margin: 0 auto;
  padding: 44px 5% 80px 5%;
  @media all and (min-width: 412px) {
    padding: 44px 10% 80px 10%;
  }
  @media all and (min-width: 768px) {
    padding: 44px 20% 80px 20%;
  }
`;

const getSideContent = (
  children: React.ReactNode,
  image: ImageProps | undefined
) => {
  // Children take precedence over full height images
  if (children) {
    return <PaddedContent>{children}</PaddedContent>;
  }
  if (image) {
    const { assetsUrl, preset, height } = image;
    if (preset && assetsUrl) {
      return (
        <FullHeightImage assetUrl={assetsUrl} preset={preset} height={height} />
      );
    }
  }
  return null;
};

export default function SplitFullScreen({
  leftChildren,
  rightChildren,
  leftImage,
  rightImage,
  rightStyle,
}: SplitFullScreenProps) {
  return (
    <PageLayout>
      <LeftSide>{getSideContent(leftChildren, leftImage)}</LeftSide>
      <RightSide css={rightStyle}>
        {getSideContent(rightChildren, rightImage)}
      </RightSide>
    </PageLayout>
  );
}
