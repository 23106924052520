import { gql } from '@apollo/client';

export const AutoShipFragment = gql`
  fragment WineClub_GetAutoShip on AutoShip {
    id
    winePackId
    stripePlanId
    autoShipId
    accountId
    ceoAccountFullName
    isEditable
    autoShipName
    curatedPackName
    hasBeenCustomized
    nextPaymentDate
    stripePlanId
    stripeSubscriptionId
    paymentStatus
    price
    paymentMethod
    taxRate
    taxRates {
      wine
    }
    subscriptionStatus
    shippingCost
    billingIntervalUnit
    billingIntervalCount
    isCuratedWineClub
    pendingCuratedPack
    timesRenewed
    nextShipmentIncludesPwP
    isDefaultPwP
    pendingPwP
    canChangePwP
    winePackCount
    shippingAddress {
      id
      addressId
      firstName
      lastName
      addressLineOne
      addressLineTwo
      city
      state
      zip
      phoneNumber
      companyName
    }
    winePackItems {
      edges {
        node {
          id
          isWinePack
          quantity
          isSubscription
          finalPrice
          price
          productId
          name
          images {
            id
            squareFront
            productCatalog
          }
        }
      }
    }
    winePackItemsSavings(first: 1000) {
      edges {
        node {
          quantity
          id
          sku
          productId
          quantity
          productName
          productURLslug
          productImageUrl
          wineBottleQuantity
          isWinePack
          priceOriginal
          priceSale
          priceDiscount
          discountEligible
          priceDiscountPercent
          hasQualifyingDiscount
        }
      }
    }
    pwpItems {
      edges {
        node {
          id
          quantity
          price
          finalPrice
          productId
          name
          images {
            id
            squareFront
            productCatalog
          }
        }
      }
    }
    pwpForShipmentDate {
      edges {
        node {
          id
          quantity
          price
          finalPrice
          priceSale
          productId
          name
          images {
            id
            squareFront
            productCatalog
          }
        }
      }
    }
    latestInvoice {
      dateCreated
      invoiceStatus
    }
  }
`;
