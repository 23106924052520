import React from 'react';
import { Interval, Duration } from 'luxon';
import {
  Box,
  GridV2 as Grid,
  Text,
  makeStyles,
  ThemeType,
  Button,
  Icons,
  TEXT_DS,
} from '@onehope/design-system-v2';
import { myMentorComingSoonLogo } from './MyMentorStyles';

import myMentorLogo from './onehope_mentor_logo.svg';

import { DealerIdDictionary } from '../../../utils/enums';
import { formatPhone, getImageUrl } from '../../../utils/utils';
import { CEDash_DashboardQuery_viewer_user } from '../../../queries/generatedTypes/CEDash_DashboardQuery';

export const simulateCall = (phoneNumber: string) =>
  window.open(`tel:${phoneNumber}`, '_self');

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    paddingBottom: 16,
  },
  rankTitle: {
    marginBottom: '4px',
    color: theme.palette.greyDark.main,
  },
  text: {
    marginBottom: 4,
  },
  phone: {
    marginBottom: 4,
    letterSpacing: '0.01em',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  email: {
    letterSpacing: '0.01em',
    color: theme.palette.forestGreen.main,
    '&:hover': {
      color: theme.palette.forestGreen.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  nameContainer: {
    paddingBottom: 8,
  },
  button: {
    backgroundColor: theme.palette.forestGreen.main,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: theme.palette.forestGreen.main,
      opacity: 0.9,
    },
  },

  avatar: ({ profileImage }: { profileImage?: string }) => ({
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    backgroundImage: `url(${profileImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
}));

type MyMentorProps = {
  user: CEDash_DashboardQuery_viewer_user;
};

const HOME_OFFICE_MENTOR_ID = '813448';
const HOME_OFFICE_NAME = 'Home Office';
const HOME_OFFICE_RANK = 'Wine Rep';
const HOME_OFFICE_EMAIL = 'support@onehopewine.com';
const HOME_OFFICE_NUMBER = '(949) 329-5576';
const HOME_OFFICE_LOCATION = 'El Segundo, CA';
const HOME_OFFICE_DATE = 'June 1, 2007';

export default function MyMentor(props: MyMentorProps) {
  const info = props.user?.ce?.mentorInfo;
  const mentorProfile = info?.mentorProfile;
  const trinityUplineDealerId = info?.trinityUplineDealerId;
  const image = mentorProfile?.image;
  const city = mentorProfile?.city;
  const state = mentorProfile?.state;
  const profileImage = image ? getImageUrl(image, 'thumb') : null;
  const classes = useStyles({ profileImage });
  const getName = (
    mentor: { fullName: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.fullName) return mentor.fullName;
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_NAME;
    else return null;
  };

  const getRank = (
    mentor: { trinityCurrentRank: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor?.trinityCurrentRank)
      return mapAbbreviations(mentor.trinityCurrentRank);
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_RANK;
    else return null;
  };

  const getEmail = (
    mentor: { mentorEmail: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.mentorEmail) return mentor.mentorEmail;
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_EMAIL;
    else return null;
  };

  const getPhone = (
    mentor: { phoneNumber: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.phoneNumber) return formatPhone(mentor.phoneNumber);
    else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID)
      return HOME_OFFICE_NUMBER;
    else return null;
  };

  const getTimeAtOneHope = (
    mentor: { trinityEnrollDate: string },
    trinityUplineDealerId: string,
  ) => {
    if (mentor && mentor.trinityEnrollDate) {
      let trinityEnrollDate = new Date(mentor.trinityEnrollDate);
      let beginDay = new Date();
      let time = calculateInterval(trinityEnrollDate, beginDay);
      let { months, years } = time;
      return displayTime(Math.floor(months), Math.floor(years));
    } else if (trinityUplineDealerId === HOME_OFFICE_MENTOR_ID) {
      let trinityEnrollDate = new Date(HOME_OFFICE_DATE);
      let beginDay = new Date();
      let time = calculateInterval(trinityEnrollDate, beginDay);
      let { months, years } = time;
      return displayTime(Math.floor(months), Math.floor(years));
    }
    return null;
  };

  if (trinityUplineDealerId === DealerIdDictionary.LEAD_GEN_MENTOR_ID) {
    return <MyMentorComingSoon />;
  }

  const mentorFullName = getName(info, trinityUplineDealerId);
  const rank = getRank(info, trinityUplineDealerId);
  const email = getEmail(info, trinityUplineDealerId);
  const phone = getPhone(info, trinityUplineDealerId);
  const location =
    city && state
      ? `${city}, ${state}`
      : trinityUplineDealerId === HOME_OFFICE_MENTOR_ID
      ? HOME_OFFICE_LOCATION
      : null;
  const joinDate = getTimeAtOneHope(info, trinityUplineDealerId);

  const callHandler = () => {
    simulateCall(phone);
  };

  return (
    <Box>
      <Box sx={{ pb: 2 }}>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
          My Mentor
        </Text>
      </Box>
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={classes.nameContainer}
      >
        <Grid item>
          {!!profileImage ? (
            <Grid className={classes.avatar} />
          ) : (
            <Icons.EmptyProfile width={'48px'} height={'48px'} />
          )}
        </Grid>
        <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_18}>
          {mentorFullName}
        </Text>
      </Grid>
      {rank && (
        <Text
          className={classes.rankTitle}
          variant="custom"
          default={TEXT_DS.OVERLINE_SEMIBOLD_14}
        >
          {rank}
        </Text>
      )}
      {email && (
        <Text
          variant="custom"
          default={TEXT_DS.BODY_SEMIBOLD_16}
          className={classes.text}
        >
          <a
            className={classes.email}
            href={`mailto:${email}`}
            rel="noopener noreferrer"
          >
            {email}
          </a>
        </Text>
      )}
      {location && (
        <Text
          variant="custom"
          default={TEXT_DS.BODY_REGULAR_16}
          className={classes.text}
        >
          {location}
        </Text>
      )}
      {phone && (
        <Text
          variant="custom"
          default={TEXT_DS.BODY_REGULAR_16}
          className={classes.phone}
          onClick={callHandler}
        >
          {phone}
        </Text>
      )}
      {joinDate && (
        <Text
          variant="custom"
          default={TEXT_DS.BODY_REGULAR_16}
          className={classes.text}
        >
          {joinDate}
        </Text>
      )}
    </Box>
  );
}

const MyMentorComingSoon = () => {
  const classes = useStyles({ profileImage: null });
  const onClick = () => {
    window.open(
      `https://helpcenter.onehopewine.com/article/760-when-is-support-available`,
      '_self',
    );
  };
  return (
    <Box>
      <Box sx={{ pb: 2 }}>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_18}>
          My Mentor
        </Text>
      </Box>
      <Box>
        <Box sx={{ pb: 2 }}>
          <img src={myMentorLogo} alt="" css={myMentorComingSoonLogo} />
        </Box>
        <Text variant="h3">Welcome!</Text>
        <Box sx={{ pb: 2, pt: 2 }}>
          <Text>
            We’re working on connecting you with your mentor, but in the
            meantime, please do not hesitate to contact Home Office with any
            questions or needs.
          </Text>
        </Box>
        <Button className={classes.button} fullWidth onClick={onClick}>
          Message Home Office
        </Button>
      </Box>
    </Box>
  );
};

function calculateInterval(startDate: Date, endDate: Date): Duration {
  return Interval.fromDateTimes(startDate, endDate).toDuration([
    'years',
    'months',
  ]);
}

function maybePluralize(count: number, noun: string): string {
  let suffix;
  let time;
  suffix = count !== 1 ? 's' : '';
  time = count === 0 ? '' : count + ' ' + noun + suffix;
  return time;
}

function displayTime(months: number, years: number): string {
  if (months === 0) {
    return maybePluralize(years, ' year');
  } else if (years === 0) {
    return maybePluralize(months, 'month');
  } else {
    return `${maybePluralize(years, 'year')}, ${maybePluralize(
      months,
      'month',
    )} at ONEHOPE`;
  }
}

export function mapAbbreviations(rank: string): string {
  switch (rank) {
    case 'LCE':
      return 'Lead Wine Rep';
    case 'SCE':
      return 'Senior Wine Rep';
    case 'ECE':
      return 'Executive Wine Rep';
    case 'D':
      return 'Director';
    case 'LD':
      return 'Lead Director';
    case 'SD':
      return 'Senior Director';
    case 'ED':
      return 'Executive Director';
    case 'SED':
      return 'Senior Executive Director';
    case 'NED':
      return 'National Executive Director';
    case '':
      return '';
    case 'CE':
    default:
      return 'Wine Rep';
  }
}
