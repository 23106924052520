import gql from 'graphql-tag';

export const CUSTOMERPAGE_USER = gql`
  fragment CustomerPage_User on User {
    id
    email
    role
    firstName
    lastName
    userId
    permissions {
      id
      oneCommerce {
        ceo {
          access
        }
      }
    }
    cart {
      id
      taxRate
      taxTotal
      eventId
      totalNumberOfItems
      subTotalPriceOfItems
      promoCodeSavings
      viaQualifiedCartTotal
      subTotalPriceOfItemsWithPromoCodes
      event {
        eventId
        trinityPartyType
        trinityPartyId
        trinityHostId
      }
      entries(first: 1000) {
        edges {
          __typename
          cursor
          node {
            id
            quantity
            product {
              id
              productId
              images {
                productCatalog
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CUSTOMERS_QUERY = gql`
  query CEDash_CustomersPageQuery {
    viewer {
      id
      user {
        ...CustomerPage_User
      }
    }
  }
  ${CUSTOMERPAGE_USER}
`;

export default GET_CUSTOMERS_QUERY;
