import { makeStyles, ThemeType } from '@onehope/design-system-v2';

type SharedStyles = {
  [key: string]: string | number | object;
};

const moreDropdownBase = (theme: ThemeType) => {
  return {
    width: '162px',
    fontFamily: theme.bodyFontFamily,
    boxShadow: `0px 5px 8px ${theme.palette.greyMedium.main}`,
    backgroundColor: theme.palette.neutral0.main,
    position: 'absolute',
    right: 0,
    zIndex: 9,
    padding: '5px 0 5px 16px',
  } as SharedStyles;
};

const tabBase = (theme: ThemeType) => {
  return {
    fontWeight: 420,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '4px 16px',
    letterSpacing: '0.01em',
    textAlign: 'center',
    cursor: 'pointer',
    fontFamily: theme.bodyFontFamily,
    borderRadius: '12px',
  } as SharedStyles;
};

export const useStyles = makeStyles((theme: ThemeType) => ({
  inbox: {
    fontFamily: theme.bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.01em',
    color: theme.palette.primary.main,
    display: 'flex',
  },
  alert: {
    backgroundColor: '#B04A3D',
    height: '16px',
    color: 'white',
    marginLeft: '5px',
    borderRadius: '100px',
    fontSize: '8px',
    fontWeight: 450,
    padding: '1px 4px',
    fontFamily: theme.bodyFontFamily,
    lineHeight: '14px',
    textAlign: 'center',
  },
  moreDropdownMobile: {
    ...moreDropdownBase(theme),
    marginRight: '15px',
  },
  moreDropdown: {
    ...moreDropdownBase(theme),
  },
  moreDropdownItem: {
    fontSize: '14px',
    height: '40px',
    lineHeight: 3,
    '&:hover': {
      fontWeight: 500,
    },
  },
  cardsHeader: {
    justifyContent: 'space-between',
    marginTop: '12px',
    maxWidth: '342px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      marginTop: '28px',
      justifyContent: 'center',
    },
    // ['@media (max-width: 599px)']: {
    //   maxWidth: '342px',
    //   marginTop: '2px',
    //   marginBottom: '-37px',
    //   justifyContent: 'space-between',
    // },
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabActiveMobile: {
    ...tabBase(theme),
    backgroundColor: theme.palette.green.main,
    color: 'white',
  },
  tabInactiveMobile: {
    ...tabBase(theme),
    backgroundColor: theme.palette.cream.main,
    color: theme?.palette?.primary?.main,
  },
  tabActive: {
    ...tabBase(theme),
    backgroundColor: theme.palette.green.main,
    color: 'white',
  },
  tabInactive: {
    ...tabBase(theme),
    backgroundColor: 'white',
    color: theme?.palette?.primary?.main,
  },
  deskMore: {
    float: 'right',
    position: 'relative',
    marginTop: '-34px',
    marginRight: 0,
    cursor: 'pointer',
    zIndex: 10,
  },
}));
