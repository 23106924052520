import React from 'react';

const SvgLogo = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="140"
    height="14"
    fill="none"
    viewBox="0 0 140 14"
    {...props}
  >
    <path
      fill="#393939"
      d="M6.91652 1.38226C5.9892 1.35314 5.07454 1.60345 4.29127 2.10071C3.57303 2.58018 3.00545 3.25346 2.65438 4.04246C2.26759 4.9157 2.07418 5.86224 2.08739 6.81721C2.07771 7.76619 2.2731 8.70606 2.6602 9.57255C3.00994 10.357 3.57565 11.0258 4.29127 11.5007C5.0758 11.9909 5.988 12.2375 6.91263 12.2094C7.8374 12.2387 8.74994 11.9919 9.53399 11.5007C10.2506 11.0253 10.8181 10.3568 11.1709 9.57255C11.5567 8.70448 11.7501 7.7632 11.7379 6.81333C11.7511 5.85836 11.5577 4.91181 11.1709 4.03857C10.8125 3.21833 10.2143 2.52568 9.45505 2.0516C8.69576 1.57753 7.81084 1.3442 6.91652 1.38226ZM6.91652 13.4211C5.51263 13.4211 4.28739 13.1221 3.2738 12.5357C2.28396 11.9749 1.48001 11.1365 0.961174 10.124C0.441654 9.10177 0.175282 7.96969 0.184475 6.82304C0.172894 5.66744 0.439277 4.52599 0.961174 3.49488C1.48092 2.48271 2.28373 1.64347 3.27185 1.07935C4.28545 0.485174 5.51263 0.184204 6.91458 0.184204C8.31652 0.184204 9.5437 0.485174 10.5592 1.07935C11.5473 1.64356 12.3501 2.48277 12.8699 3.49488C13.3876 4.52713 13.6506 5.66833 13.6369 6.82304C13.6518 7.97032 13.3851 9.10375 12.8602 10.124C12.3292 11.1341 11.5208 11.9714 10.5301 12.5376C9.42938 13.1537 8.18339 13.4628 6.92234 13.4327"
    />
    <path
      fill="#393939"
      d="M21.3961 13.1337V12.3881L21.6388 12.2793C21.7759 12.208 21.8901 12.0995 21.9684 11.9662C22.0467 11.833 22.0859 11.6804 22.0816 11.5259V2.01331C21.9959 1.87515 21.8978 1.74516 21.7883 1.62496C21.6772 1.51639 21.533 1.44805 21.3786 1.43079L21 1.40166V0.452148H22.8097L22.932 0.593896C23.1294 0.838475 23.3073 1.09817 23.4641 1.37059C23.5223 1.46574 23.5728 1.55118 23.6155 1.61526C24.5981 3.09681 26.4816 4.58419 29.2136 6.03273C30.7404 6.83958 32.1092 7.91503 33.2544 9.20749V2.06768C33.2588 1.91296 33.2194 1.76015 33.1407 1.62686C33.062 1.49356 32.9473 1.38521 32.8097 1.31428L32.5689 1.20555V0.459916H35.7476V1.20555L35.5049 1.31428C35.3675 1.3853 35.253 1.49373 35.1747 1.62704C35.0964 1.76036 35.0573 1.91313 35.0621 2.06768V13.1356H33.7204L33.6447 12.8153C33.6447 12.7881 32.8466 9.72011 28.3243 7.2638C26.4718 6.29293 24.9845 5.28322 23.8893 4.26768V11.5279C23.8848 11.6826 23.924 11.8355 24.0023 11.9691C24.0805 12.1027 24.1948 12.2115 24.332 12.2832L24.5748 12.392V13.1376L21.3961 13.1337Z"
    />
    <path
      fill="#393939"
      d="M54.6291 13.1337H44.1922V12.39L44.433 12.2793C44.5696 12.208 44.6833 12.0997 44.7612 11.9668C44.8392 11.834 44.8782 11.6818 44.8738 11.5279V2.06961C44.8783 1.91485 44.8391 1.76196 44.7609 1.62839C44.6826 1.49482 44.5683 1.38597 44.4311 1.31427L44.1884 1.20553V0.4599H54.6253L54.833 2.68514H54.0563L53.9456 2.44048C53.8136 2.13951 53.4893 1.81524 53.1981 1.81524H46.6738V4.93757H49.7631C50.0524 4.93757 50.3767 4.6133 50.5107 4.31427L50.6194 4.06961H51.3554V7.16087H50.6175L50.5049 6.91621C50.3728 6.61718 50.0486 6.29291 49.7573 6.29291H46.6738V11.7783H53.2C53.4893 11.7783 53.8156 11.4541 53.9476 11.1531L54.0583 10.9084H54.835L54.6291 13.1337Z"
    />
    <path
      fill="#393939"
      d="M71.6893 13.1337H68.5223V12.39L68.7631 12.2793C68.9005 12.2083 69.0149 12.0999 69.0933 11.9666C69.1716 11.8332 69.2106 11.6805 69.2058 11.5259V2.06961C69.2102 1.91416 69.1704 1.76065 69.091 1.62693C69.0116 1.49322 68.8959 1.38479 68.7573 1.31427L68.5165 1.20553V0.4599H71.6893V1.20553L71.4485 1.31427C71.3108 1.38552 71.196 1.49422 71.1173 1.62785C71.0387 1.76148 70.9994 1.91461 71.0039 2.06961V5.38223C72.604 5.33158 74.2028 5.5194 75.7476 5.93951C77.532 6.44437 79.0893 6.70262 80.3864 6.70456V2.06961C80.3914 1.91477 80.3524 1.76169 80.2741 1.62803C80.1957 1.49438 80.0812 1.38561 79.9437 1.31427L79.701 1.20553V0.4599H82.8699V1.20553L82.6291 1.31427C82.4916 1.38561 82.3771 1.49438 82.2987 1.62803C82.2204 1.76169 82.1815 1.91477 82.1864 2.06961V11.5279C82.1815 11.6827 82.2204 11.8358 82.2987 11.9694C82.3771 12.1031 82.4916 12.2119 82.6291 12.2832L82.8699 12.3919V13.1356H79.6932V12.3919L79.9359 12.2832C80.0734 12.2119 80.188 12.1031 80.2663 11.9694C80.3447 11.8358 80.3836 11.6827 80.3786 11.5279V8.04825C78.9825 8.07349 77.3184 7.79388 75.4175 7.25407C73.9799 6.86387 72.4929 6.68658 71.0039 6.72786V11.5279C70.9994 11.6829 71.0387 11.836 71.1173 11.9696C71.196 12.1033 71.3108 12.212 71.4485 12.2832L71.6893 12.3919V13.1337Z"
    />
    <path
      fill="#393939"
      d="M96.6311 1.38224C95.7058 1.35429 94.7933 1.60456 94.0117 2.10068C93.2935 2.58015 92.7259 3.25344 92.3748 4.04243C91.988 4.91567 91.7946 5.86221 91.8078 6.81719C91.7957 7.76704 91.9892 8.70828 92.3748 9.57641C92.7256 10.3614 93.2927 11.0302 94.0097 11.5046C94.8052 11.9691 95.7099 12.2139 96.6311 12.2139C97.5523 12.2139 98.457 11.9691 99.2525 11.5046C99.9696 11.0297 100.537 10.3611 100.889 9.57641C101.275 8.70834 101.469 7.76706 101.456 6.81719C101.47 5.86221 101.276 4.91567 100.889 4.04243C100.531 3.22187 99.9324 2.529 99.1727 2.0549C98.413 1.5808 97.5277 1.34765 96.633 1.38612L96.6311 1.38224ZM96.633 13.425C95.3618 13.4574 94.1048 13.1518 92.9903 12.5395C92.0008 11.9792 91.1969 11.1416 90.6777 10.1298C90.1625 9.10639 89.8995 7.97458 89.9107 6.82884C89.897 5.67413 90.16 4.53293 90.6777 3.50068C91.1975 2.48851 92.0003 1.64927 92.9884 1.08515C94.0039 0.490973 95.2292 0.190002 96.633 0.190002C98.0369 0.190002 99.2602 0.490973 100.276 1.08515C101.264 1.64936 102.067 2.48857 102.586 3.50068C103.105 4.53262 103.368 5.67403 103.353 6.82884C103.368 7.97612 103.102 9.10955 102.577 10.1298C102.046 11.1403 101.238 11.9778 100.247 12.5434C99.146 13.1598 97.9 13.4689 96.6389 13.4385"
    />
    <path
      fill="#393939"
      d="M113.439 7.61916H116.447C116.711 7.62024 116.975 7.60076 117.237 7.56091C117.619 7.50835 117.991 7.39357 118.336 7.2211C118.804 6.99458 119.197 6.63796 119.468 6.19392C119.71 5.78097 119.844 5.31332 119.856 4.83469V4.65799C119.848 4.17514 119.714 3.70281 119.468 3.28712C119.197 2.84258 118.803 2.48587 118.334 2.25993C117.989 2.08862 117.618 1.97389 117.237 1.92013C117.14 1.90265 117.052 1.88712 116.965 1.87741L116.87 1.8677H116.771L116.612 1.85605H113.437L113.439 7.61916ZM110.955 13.1337V12.3881L111.196 12.2794C111.334 12.2081 111.449 12.0994 111.527 11.9658C111.606 11.8321 111.645 11.679 111.641 11.524V2.0677C111.645 1.91298 111.606 1.76017 111.527 1.62688C111.448 1.49358 111.334 1.38523 111.196 1.3143L110.955 1.20557V0.459935H117.423C118.18 0.456898 118.924 0.652599 119.581 1.02745C120.238 1.4023 120.785 1.94315 121.167 2.59586C121.549 3.24277 121.75 3.98047 121.749 4.73178C121.75 5.48309 121.549 6.22079 121.167 6.8677C120.788 7.51478 120.244 8.05054 119.592 8.4211C118.932 8.79943 118.184 8.99639 117.423 8.99197H113.439V11.5279C113.434 11.6827 113.473 11.8358 113.551 11.9695C113.629 12.1031 113.744 12.2119 113.882 12.2832L114.124 12.392V13.1376L110.955 13.1337Z"
    />
    <path
      fill="#393939"
      d="M139.792 13.1337H129.353V12.39L129.596 12.2793C129.734 12.2084 129.848 12.1 129.927 11.9667C130.006 11.8334 130.045 11.6806 130.041 11.5259V2.06961C130.045 1.91461 130.006 1.76148 129.927 1.62785C129.849 1.49422 129.734 1.38552 129.596 1.31427L129.353 1.20553V0.4599H139.792L140 2.68514H139.223L139.113 2.44048C138.979 2.13951 138.66 1.81912 138.363 1.81912H131.839V4.93757H134.926C135.217 4.93757 135.542 4.6133 135.674 4.31427L135.784 4.06961H136.524V7.16087H135.786L135.678 6.91621C135.546 6.61718 135.221 6.29291 134.93 6.29291H131.837V11.7783H138.363C138.654 11.7783 138.979 11.4541 139.111 11.1531L139.221 10.9084H139.998L139.792 13.1337Z"
    />
  </svg>
);

export default SvgLogo;
