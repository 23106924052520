import React from 'react';
import {
  ButtonBase,
  Grid,
  Icons,
  Text,
  Button,
  ChunkyButton,
  Image,
} from '@onehope/design-system-v2';

import {
  CauseOfChoiceAppDictionary,
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

import { useCommonStyles } from '../../../utils/commonStyles';
import { getCurrentUser, getEventImage } from '../../../utils/helper';

export default function FundraiserConfirmation() {
  const commonStyles = useCommonStyles();
  const {
    handleCloseModal,
    user: parentUser,
    onContinue,
    app,
    handleGoToShop,
    urlEventImgUploads,
  } = useCauseOfChoiceNetworkLayerValue();
  const [, dispatch] = useCauseOfChoiceContextValue();
  const user = getCurrentUser(parentUser);

  const event = user?.cartV2?.event;
  const eventTitle = event?.title || 'Fundraiser';
  const eventImage = event?.images?.[0];
  const image =
    eventImage && urlEventImgUploads
      ? getEventImage(eventImage, urlEventImgUploads)
      : null;

  const nonProfit =
    event?.donationInfo?.nonProfitV2 || event?.donationInfo?.nonProfit;

  const isMegalith = app === CauseOfChoiceAppDictionary.OHW_MEGALITH;
  const buttonText = isMegalith ? 'Shop' : onContinue ? 'Continue' : 'Close';
  const buttonOnClick = isMegalith
    ? handleGoToShop
    : onContinue
    ? onContinue
    : handleCloseModal;

  const handleGoBack = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.FUNDRAISER_SEARCH_SCREEN,
    });
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={commonStyles.navigation}
      >
        <ButtonBase onClick={handleGoBack}>
          <Grid xs item container alignItems="center" direction="row">
            <Icons.SmallLeftArrow />
            <Text className={commonStyles.backButtonText}>Back</Text>
          </Grid>
        </ButtonBase>
        <ButtonBase onClick={handleCloseModal}>
          <Icons.Close />
        </ButtonBase>
      </Grid>
      <Grid container>
        <Text className={commonStyles.labelText}>Your cause of choice is:</Text>
      </Grid>
      <ChunkyButton
        avatar={
          image ? (
            <Image
              className={commonStyles.chunkyButtonImage}
              altText="Event Image"
              src={`${image}`}
            />
          ) : (
            <Icons.COCHeart />
          )
        }
        onChange={handleGoBack}
        onChangeText="Change"
        primaryText={`${eventTitle}`}
        secondaryText={`Host: ${event?.hostFullName}`}
        tertiaryText={`Nonprofit: ${nonProfit?.nonProfitName}`}
      />
      <Grid container justifyContent="center">
        <Button
          fullWidth
          type="primary"
          className={commonStyles.saveAndClose}
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
        <Text className={commonStyles.italicText}>
          You can change your cause of choice at any point.
        </Text>
      </Grid>
    </Grid>
  );
}
