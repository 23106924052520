export default class GQLNode {
  private name: string;
  private leaves: string[];
  private childNodes: { [nodeName: string]: GQLNode };

  constructor(name: string) {
    this.name = name;
    // Could always include 'id' in the leaves array by default,
    // but looks like some don't have it filled out which was throwing an error,
    // e,g, access type on permissions.
    // For now we could do it on top level resource and see if that's sufficient
    // otherwise we should update any of the graphql types missing id
    this.leaves = ['id'];
    this.childNodes = {};
  }

  // Add the remaining list of fields in a resource;
  // if this node is a user node, then you might add(['accountRole','accountRoleTitle'])
  add(values: string[]) {
    if (!values.length) return this;

    const name = values[0];
    if (values.length > 1) {
      const nodeValue = values.slice(1);
      let childNode = this.childNodes[name];
      if (!childNode) {
        childNode = new GQLNode(name);
        this.childNodes[name] = childNode;
      }
      childNode.add(nodeValue);
    } else {
      this.leaves.push(name);
    }
    return this;
  }

  getGraphQLSubquery(): string {
    const childQueries = Object.values(this.childNodes)
      .map((node) => node.getGraphQLSubquery())
      .join('\n');
    return `${this.name} { ${this.leaves.join(' ')} ${childQueries} }`;
  }

  // Note: I tried `get fieldNames` which worked except there was a TS error:
  // Accessors are only available when targeting ECMAScript 5 and higher. ts(1056)
  getFieldNames() {
    const fieldNames = this.leaves.concat(Object.keys(this.childNodes));
    return fieldNames;
  }

  getChildNodes() {
    return this.childNodes;
  }
}
