import React from 'react';
import { isEmpty } from 'lodash';
import { navigate } from 'gatsby';
import {
  styled,
  Grid,
  Icons,
  Text,
  TEXT_DS,
  Input,
  Button,
  theme,
  makeStyles,
} from '@onehope/design-system-v2';
import { PhoneMask } from '../../../Contacts/AddContactDialog/PhoneMask';
import DateMask from '../../../../utils/DateMask';
import { FormikProps } from 'formik';
import { FormTypes } from './FormTypes';
import CustomerCard from '../../../CustomerCard';
const { Close } = Icons;
import { ContactType } from '../../ContactsContext';
import { ContactManagementTabs } from '../../../ContactV2/ContactDetailsPage/TabContent/tabConstants';

interface AddContactProps {
  values: FormTypes;
  onClose: () => void;
  existingContact: ContactType;
  errorNotification: string | null;
  mutationLoading: boolean;
  callback: (value: ContactType) => void | null;
}

const Form = styled('div')`
  margin: 0 auto;
  width: 100%;
  background-color: ${theme.palette.white.main};
  padding: 32px 16px;
  @media all and (min-width: 968px) {
    padding: 48px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    width: 600px;
  }
`;

const InputContainer = styled('div')`
  margin: 0 auto;
  width: 100%;
  @media all and (min-width: 968px) {
    max-width: 100%;
    width: 600px;
  }
`;

const CloseDialog = styled('div')`
  cursor: pointer;
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ErrorNotification = styled('div')`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px ${theme.palette.yellow.main};
  background-color: #fdf6f5;
  padding: 14px 22px;
  margin-top: -16px;
  margin-bottom: 24px;
`;

const useStyles = makeStyles(() => ({
  formTitle: {
    paddingBottom: '24px',
  },
  formItem: {
    paddingBottom: '24px',
  },
  informationalText: {
    paddingBottom: '24px',
  },
}));

const AddContact = ({
  errors,
  errorNotification,
  handleChange,
  handleSubmit,
  isSubmitting,
  mutationLoading,
  onClose,
  setFieldTouched,
  touched,
  values,
  existingContact,
  callback,
}: FormikProps<FormTypes> & AddContactProps) => {
  const {
    dateOfBirth,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
  } = values;

  const classes = useStyles();

  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const customerCardClick = () => {
    if (typeof window === 'undefined') return;
    const { pathname } = window.location;
    switch (true) {
      case pathname.includes('/contacts'):
        return navigate(
          `/contact?contactId=${existingContact.contactId}&tab=${ContactManagementTabs.DETAILS}`,
        );
      case pathname.includes('/event'):
      case pathname.includes('/customer-orders'):
        onClose();
        return callback(existingContact);
      default:
        return;
    }
  };

  const disableSubmit = mutationLoading || !isEmpty(errors) || isEmpty(touched);

  return (
    <form onSubmit={handleSubmit}>
      <Form>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Text
              variant="custom"
              default={TEXT_DS.TITLE_LIGHT_32}
              className={classes.formTitle}
            >
              Create a New Contact
            </Text>
          </Grid>
          <Grid item>
            <CloseDialog onClick={onClose}>
              <Close />
            </CloseDialog>
          </Grid>
        </Grid>
        <InputContainer>
          <div className={classes.formItem}>
            <Input
              label={'First name'}
              placeholder="Jane"
              id="firstName"
              name="firstName"
              value={firstName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.firstName && Boolean(errors.firstName)
              }
              hasSuccess={
                !isSubmitting && touched.firstName && !Boolean(errors.firstName)
              }
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              onChange={(e: React.SyntheticEvent) => change('firstName', e)}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label={'Last name'}
              placeholder="Austen"
              id="lastName"
              name="lastName"
              value={lastName}
              fullWidth
              validate
              hasError={
                !isSubmitting && touched.lastName && Boolean(errors.lastName)
              }
              hasSuccess={
                !isSubmitting && touched.lastName && !Boolean(errors.lastName)
              }
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              onChange={(e: React.SyntheticEvent) => change('lastName', e)}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label="Email address"
              placeholder="jane@gmail.com"
              id="emailAddress"
              name="emailAddress"
              value={emailAddress}
              fullWidth
              validate
              hasError={
                !isSubmitting &&
                touched.emailAddress &&
                Boolean(errors.emailAddress)
              }
              hasSuccess={
                !isSubmitting &&
                touched.emailAddress &&
                !Boolean(errors.emailAddress)
              }
              error={touched.emailAddress && Boolean(errors.emailAddress)}
              helperText={
                errors.emailAddress &&
                touched.emailAddress &&
                errors.emailAddress
              }
              onChange={(e: React.SyntheticEvent) => change('emailAddress', e)}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label="Birthday"
              placeholder="MM / DD / YYYY"
              defaultChecked={false}
              id="dateOfBirth"
              name="dateOfBirth"
              fullWidth
              onChange={(e: React.SyntheticEvent) => {
                change('dateOfBirth', e);
              }}
              value={dateOfBirth}
              helperText={
                errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
              }
              hasSuccess={
                !isSubmitting &&
                touched.dateOfBirth &&
                !Boolean(errors.dateOfBirth)
              }
              hasError={
                !isSubmitting &&
                touched.dateOfBirth &&
                Boolean(errors.dateOfBirth)
              }
              mask={DateMask}
            />
          </div>
          <div className={classes.formItem}>
            <Input
              label="Phone Number"
              placeholder="(555) 123-4567"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              fullWidth
              validate
              hasError={
                !isSubmitting &&
                touched.phoneNumber &&
                Boolean(errors.phoneNumber)
              }
              hasSuccess={
                !isSubmitting &&
                touched.phoneNumber &&
                !Boolean(errors.phoneNumber)
              }
              mask={PhoneMask}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              helperText={
                errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
              }
              onChange={(e: React.SyntheticEvent) => change('phoneNumber', e)}
            />
          </div>
          {errorNotification && (
            <ErrorNotification>{errorNotification}</ErrorNotification>
          )}
          {existingContact && (
            <div style={{ paddingBottom: '24px' }}>
              <Text
                variant="custom"
                default={TEXT_DS.BODY_REGULAR_16}
                className={classes.informationalText}
              >
                A contact with this email and/or phone number already exisits.
                Did you mean to select this contact?
              </Text>
              <CustomerCard
                title={'Contact details'}
                name={`${existingContact.firstName} ${existingContact.lastName}`}
                email={existingContact.emailAddress}
                dateOfBirth={existingContact.dateOfBirth}
                phone={existingContact.phoneNumber}
                cardClick={customerCardClick}
              />
            </div>
          )}
          <ButtonContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  type="secondary"
                  disableRipple
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  type="primary"
                  disableRipple
                  fullWidth
                  disabled={disableSubmit}
                  onClick={handleSubmit}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </ButtonContainer>
        </InputContainer>
      </Form>
    </form>
  );
};

export default AddContact;
