import gql from 'graphql-tag';

const GET_CUSTOMER_AUTOSHIPS_CSV = gql`
  query CustomerAutoshipsCSVContactsV2($maxWineCount: Int) {
    viewer {
      user {
        customerAutoshipsCSV(maxWineCount: $maxWineCount)
      }
    }
  }
`;

export default GET_CUSTOMER_AUTOSHIPS_CSV;
