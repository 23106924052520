import React from 'react';
import AddOrder from './AddOrder';
import { MyFormValues } from './MyFormTypes';
import { AddOrderFormSchema } from './validationSchema';
import { ContactsContextProvider } from '../../ContactsV3/ContactsContext';
import FormContainer from '../../Event/FormContainer';

interface AddOrderFormProps {
  defaultValues: MyFormValues;
  selectCustomer?: boolean;
  onClose: () => void;
  eventId?: string | null;
}

export default function AddOrderForm({
  onClose,
  selectCustomer,
  defaultValues,
  eventId,
}: AddOrderFormProps) {
  return (
    <ContactsContextProvider contacts={[]}>
      <FormContainer
        initialValues={defaultValues}
        validationSchema={AddOrderFormSchema}
        formToUse={(props: any) => (
          <AddOrder
            {...props}
            onClose={onClose}
            selectCustomer={selectCustomer}
            eventId={eventId}
          />
        )}
        onSubmit={(values: MyFormValues, { setErrors }: any) => {
          return;
        }}
      />
    </ContactsContextProvider>
  );
}
