import React from 'react';
import { useMutation } from '@apollo/client';
import { ChunkyButton } from '@onehope/design-system-v2';
/* COMPONENTS */
import ProfileImage from '../../../ProfileImage';

/* MUTATIONS */
import UPDATE_SELECTED_CAUSE_MUTATION from '../../../../mutations/UpdateSelectedCauseMutation';

/* UTILS */
import { useCauseOfChoiceContextValue } from '../../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../../stateManagement/networkLayer';
import {
  CauseOfChoiceScreenDictionary,
  CauseOfChoiceTypeKeys,
  CauseOfChoiceViewsDictionary,
  TypeKeys,
} from '../../../../stateManagement/types';
import { getCurrentUser } from '../../../../utils/helper';
import { useCommonStyles } from '../../../../utils/commonStyles';

export default function CeCauseButton() {
  const [{ loading }, dispatch] = useCauseOfChoiceContextValue();
  const { user: parentUser, modalView } = useCauseOfChoiceNetworkLayerValue();
  const commonStyles = useCommonStyles();
  const [updateSelectedCauseMutation] = useMutation(
    UPDATE_SELECTED_CAUSE_MUTATION,
  );
  const {
    modalLocation,
    trackUpdateCauseOfChoice,
  } = useCauseOfChoiceNetworkLayerValue();

  const user = getCurrentUser(parentUser);
  const ceCauseId =
    user?.shoppingWithCeoUser?.selectedCause?.nonProfit?.nonProfitId;

  const primaryText =
    modalView === CauseOfChoiceViewsDictionary.CE_PROFILE_VIEW
      ? 'My Cause of Choice'
      : `${user?.shoppingWithCeoUser?.firstName} ${user?.shoppingWithCeoUser?.lastName}'s Cause`;

  const handleLoadingState = (loadingState: boolean) => {
    dispatch({
      type: TypeKeys.UPDATE_LOADING,
      loading: loadingState,
    });
  };

  const handleSelectCeCause = () => {
    if (!loading) {
      handleLoadingState(true);
      const nonProfitId = ceCauseId;
      const variables = {
        input: {
          nonProfitId,
        },
      };
      return updateSelectedCauseMutation({ variables }).then(res => {
        handleLoadingState(false);
        dispatch({
          type: TypeKeys.UPDATE_SCREEN,
          screen: CauseOfChoiceScreenDictionary.CE_CAUSE_CONFIRMATION_SCREEN,
        });
        if (trackUpdateCauseOfChoice) {
          trackUpdateCauseOfChoice({
            selectedCause: res?.data?.causeUpdateSelected?.user?.selectedCause,
            guestCause:
              res?.data?.causeUpdateSelected?.user?.cartV2?.guestNonProfit,
            causeType: CauseOfChoiceTypeKeys.CE,
            updateLocation: modalLocation,
            shoppingWithCeoUser:
              res?.data?.causeUpdateSelected?.user?.shoppingWithCeoUser,
          });
        }
      });
    } else {
      return;
    }
  };
  if (ceCauseId) {
    return (
      <div className={commonStyles.container}>
        <ChunkyButton
          avatar={<ProfileImage />}
          primaryText={primaryText}
          secondaryText={
            user?.shoppingWithCeoUser?.selectedCause?.nonProfit?.nonProfitName
          }
          onClick={handleSelectCeCause}
        />
      </div>
    );
  } else {
    return null;
  }
}
