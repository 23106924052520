// @ts-ignore
import * as yup from 'yup';

import { isValidDate, mustBe21OrOlder } from '@onehope/validation';

yup.addMethod(yup.string, 'isValidDate', isValidDate);
yup.addMethod(yup.string, 'mustBe21OrOlder', mustBe21OrOlder);

export const AccountInfoFormSchema = yup.object().shape({
  lastName: yup
    .string()
    .matches(/^[a-zA-Z]/, 'Please enter last name')
    .required(),
  emailAddress: yup
    .string()
    .email('Please enter a valid email')
    .required(),
  dateOfBirth: yup
    .string()
    .isValidDate()
    .mustBe21OrOlder()
    .required(),
});
