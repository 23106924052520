import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import fetch from 'isomorphic-fetch';

export default function getClient() {
  const httpLink = createHttpLink({
    credentials: 'include',
    fetch,
    uri: `${process.env.GATSBY_GRAPHQL_SERVER_URL}/graphql`,
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
  });
}
