import React from 'react';
import { Link } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';

import { styled, Grid, Text, TEXT_DS, Paper } from '@onehope/design-system-v2';

import { segmentEvents } from '../../../utils/segment/constants';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';

import GET_REACH_OUT_REQUEST from '../../../queries/ReachOutRequest';
import {
  CEDash_ReachOutRequestQuery,
  CEDash_ReachOutRequestQueryVariables,
} from '../../../queries/generatedTypes/CEDash_ReachOutRequestQuery';

import ContactResultsDisplay from './ContactResultsDisplay';

const PaperContainer = styled(Paper)({
  height: '100vh',
});

const ContactResultsContainer = styled('div')({
  padding: '48px',
});

type Props = {
  reachOutRequestId: string;
};

/**
 * Render results for a single `ReachOutRequest`. (Confusingly named.)
 * Most views of this component will come from Playbook cards deep-linking to ReachOut results.
 */
export default function ContactResults({ reachOutRequestId }: Props) {
  const { trackEventV2 } = useTrackingContextValue();

  const { data } = useQuery<
    CEDash_ReachOutRequestQuery,
    CEDash_ReachOutRequestQueryVariables
  >(GET_REACH_OUT_REQUEST, {
    variables: { reachOutRequestId },
    onCompleted: () => {
      // track "results viewed" event if possible
      const reachOutId =
        data?.viewer?.v2?.reachOutResult?.reachOutRequest?.reachOutId;
      const ceAccountId = data?.viewer?.user?.userId;

      if (reachOutId && ceAccountId) {
        trackEventV2({
          event: segmentEvents.reachOutContactResultsViewed,
          properties: {
            reachOutId,
            ceAccountId,
          },
        });
      }
    },
  });

  return (
    <PaperContainer elevation={0}>
      <ContactResultsContainer>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_32}>
              Results
            </Text>
          </Grid>
          <Grid item>
            {data?.viewer?.v2?.reachOutResult && (
              <ContactResultsDisplay result={data.viewer.v2.reachOutResult} />
            )}
          </Grid>
        </Grid>
      </ContactResultsContainer>
    </PaperContainer>
  );
}
