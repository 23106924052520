import React, { useMemo, Fragment } from 'react';
import {
  Grid,
  Icons,
  LoaderDark,
  Text,
  ButtonBase,
} from '@onehope/design-system-v2';

/* COMPONENTS */
import CeCauseButton from './Buttons/CeCauseButton';
import NonProfitButton from './Buttons/NonProfitButton';
import NoSureButton from './Buttons/NotSureButton';
import FundraiserButton from './Buttons/FundraiserButton';

/* UTILS */
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';
import {
  CauseOfChoiceButtonDictionary,
  CauseOfChoiceViewsDictionary,
} from '../../../stateManagement/types';

import { useCommonStyles } from '../../../utils/commonStyles';

function renderButton(button: CauseOfChoiceButtonDictionary) {
  switch (button) {
    case CauseOfChoiceButtonDictionary.CE_CAUSE:
      return <CeCauseButton />;
    case CauseOfChoiceButtonDictionary.NON_PROFIT:
      return <NonProfitButton />;
    case CauseOfChoiceButtonDictionary.FUNDRAISER:
      return <FundraiserButton />;
    case CauseOfChoiceButtonDictionary.NOT_SURE:
      return <NoSureButton />;
  }
}

function getCopy(modalView: CauseOfChoiceViewsDictionary) {
  switch (modalView) {
    case CauseOfChoiceViewsDictionary.CE_PROFILE_VIEW:
      return {
        header: 'Fundraise With Me',
        subHeader: `10% of your purchases will be donated to your cause of choice. Who would you like to start fundraising for?`,
      };
    default:
      return {
        header: 'Select a Cause',
        subHeader: `ONEHOPE will donate 10% of your order to a nonprofit of your choice. Choose a cause:`,
      };
  }
}

export default function Home() {
  const commonStyles = useCommonStyles();
  const [{ loading }] = useCauseOfChoiceContextValue();
  const {
    handleCloseModal,
    buttons,
    modalView,
  } = useCauseOfChoiceNetworkLayerValue();

  const { header, subHeader } = getCopy(modalView);

  const memoizedButtons = useMemo(() => {
    return buttons?.map((button: CauseOfChoiceButtonDictionary) => (
      <Fragment key={button}>{renderButton(button)}</Fragment>
    ));
  }, [buttons]);

  return (
    <Grid container>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={commonStyles.loader}
        >
          <LoaderDark size={36} />
        </Grid>
      ) : (
        <>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Text variant="h1" className={commonStyles.headerText}>
              {header}
            </Text>
            <ButtonBase onClick={handleCloseModal}>
              <Icons.Close />
            </ButtonBase>
          </Grid>
          <Grid className={commonStyles.container}>
            <Text className={commonStyles.regularText}>{subHeader}</Text>
          </Grid>
          <Grid container direction="column">
            {memoizedButtons}
          </Grid>
        </>
      )}
    </Grid>
  );
}
