import { DateTime } from 'luxon';
import { formatAddress, getCharityType } from '../Charity/helpers';
import { CharityTypeDictionary } from '../../../../utils/enums';
import { getEventNonProfit } from '../../../../utils/utils';
import {
  EventHostingTypeEnum,
  EventTypeEnum,
} from '../../../EventsV2/eventTypes';

const getHostType = (event: any) => {
  const { accountId, hostAccountId, hostType } = event;
  if (hostType) return hostType;
  if (accountId !== hostAccountId) {
    return EventHostingTypeEnum.Contact;
  }
  return EventHostingTypeEnum.Self;
};

const getValue = (value: string) => {
  return value && value !== 'null' ? value : '';
};

const getHostInformation = (event: any, hostDetails: any) => {
  // hostDetails will always be null for imported trinity events
  if (hostDetails?.userId) {
    return {
      hostFullName:
        (getValue(hostDetails?.organizationName) ||
          getValue(hostDetails?.fullName)) ??
        '',
      hostFirstName: getValue(hostDetails?.firstName) ?? '',
      hostLastName: getValue(hostDetails?.lastName) ?? '',
      hostAccountId: hostDetails?.userId ?? '',
      hostEmail: hostDetails?.email ?? '',
      hostPhone: hostDetails?.mobilePhone ?? '',
    };
  }
  return {
    hostFullName:
      (getValue(hostDetails?.fullName) ||
        getValue(hostDetails?.organizationName)) ??
      '',
    hostFirstName: getValue(hostDetails?.firstName) ?? '',
    hostLastName: getValue(hostDetails?.lastName) ?? '',
    hostAccountId: event?.hostAccountId ?? '',
    hostEmail: event?.hostEmail ?? '',
    hostPhone: event?.hostPhone ?? '',
  };
};

const getTrinityPartyType = (trinityPartyType: string) => {
  if (trinityPartyType === 'EPARTY') return 'EPARTY';
  return 'EVENT'; // if partyType === "EVENT" or "INHOME - SHIPTOGUEST"
};

const getAddress = (event: any) => {
  return {
    addressLineOne: event?.eventAddress?.addressLineOne || '',
    city: event?.eventAddress?.city || '',
    state: event?.eventAddress?.state || '',
    zip: event?.eventAddress?.zip || '',
  };
};
export const getDefaultValues = (
  event: any,
  hostDetails: any,
  donation: any,
) => {
  const charityType = getCharityType(donation);
  // const nonProfitAddressParsed = getCharityAddress(donation);
  const partnerCharity = charityType === CharityTypeDictionary.OHW_PARTNER;
  // NOTE: rest of NPO fields below should remain referencing nonProfit (and not nonProfitV2)
  const nonProfit = getEventNonProfit(donation);
  const addressParsed = getAddress(event);

  const canUpdateEventDates = event?.canUpdateEventDates;
  const timeZone = event?.timeZone ? event.timeZone : 'America/Chicago'; // Fall back for trinity events
  const eventTimeFormatted = event?.eventDate
    ? DateTime.fromISO(event.eventDate, { zone: timeZone })
        .toLocaleString(DateTime.TIME_SIMPLE)
        .replace(/\s/g, '')
        .padStart(7, 0)
    : '';
  const eventDate = event?.eventDate
    ? DateTime.fromISO(event.eventDate, { zone: timeZone }).toFormat(
        'MM/dd/yyyy',
      )
    : '';
  const eventCloseDate = event?.closeDate
    ? DateTime.fromISO(event.closeDate, { zone: timeZone }).toFormat(
        'MM/dd/yyyy',
      )
    : '';
  const hostInfo = getHostInformation(event, hostDetails);
  return {
    ...addressParsed,
    ...hostInfo,
    charityType,
    canUpdateEventDates,
    preApprovedNonProfit: partnerCharity ? donation.nonProfit.nonProfitId : '',
    customNonProfit: !partnerCharity ? donation?.nonProfit?.nonProfitName : '',
    nonProfitId: nonProfit?.nonProfitId ? nonProfit.nonProfitId : '',
    charityDonationGoal: donation?.donationGoal ? donation.donationGoal : '',
    charityQuote: event?.hostQuote ? event.hostQuote : '',
    attentionToName:
      !partnerCharity && donation?.nonProfit?.nonProfitAddress?.optionalName
        ? donation.nonProfit?.nonProfitAddress?.optionalName
        : '',
    nonProfitTaxId:
      !partnerCharity && donation?.nonProfit?.nonProfitTaxId
        ? donation.nonProfit.nonProfitTaxId
        : '',
    lobMemo: donation?.lobMemo ? donation.lobMemo : '',
    showDonationGoal:
      event?.displayPreferences?.donationGoal?.toString() ?? 'false',
    hostNonProfitName: event?.hostNonProfitName ? event.hostNonProfitName : '',
    hostType: getHostType(event),
    hostCustomerType: '',
    displayAddress: addressParsed ? formatAddress(addressParsed) : '',
    addressLineTwo: event?.eventAddress?.addressLineTwo
      ? event?.eventAddress?.addressLineTwo
      : '',
    eventDate:
      event?.eventType === EventTypeEnum.WineTasting
        ? eventDate
        : eventCloseDate,
    isDateUpdated: false,
    eventTime: eventTimeFormatted ? eventTimeFormatted.substring(0, 5) : '',
    eventTimeLocale: eventTimeFormatted
      ? eventTimeFormatted.substring(5, 7)
      : '',
    eventVirtualLink: event?.eventVirtualLink || '',
    trinityPartyType: getTrinityPartyType(event.trinityPartyType),
    timeZone,
    title: event?.title ?? '',
    eventType: event?.eventType ?? '',
    showSupporters:
      event?.displayPreferences?.supporters?.toString() ?? 'false',
    eventLocation:
      event?.displayPreferences?.eventLocation?.toString() ?? 'false',
  };
};

export function formatZone(zone: string | undefined) {
  switch (zone) {
    case 'America/Anchorage': {
      return 'AKST';
    }
    case 'America/New_York': {
      return 'EST';
    }
    case 'America/Chicago': {
      return 'CST';
    }
    case 'America/Denver': {
      return 'MST';
    }
    case 'America/Los_Angeles': {
      return 'PST';
    }
    default: {
      return 'CST';
    }
  }
}

export function formatShortZoneToLong(zone: string) {
  switch (zone) {
    case 'AKST':
      return 'America/Anchorage';
    case 'AKDT':
      return 'America/Anchorage';
    case 'EST':
      return 'America/New_York';
    case 'EDT':
      return 'America/New_York';
    case 'CST':
      return 'America/Chicago';
    case 'CDT':
      return 'America/Chicago';
    case 'MST':
      return 'America/Denver';
    case 'MDT':
      return 'America/Denver';
    case 'PST':
      return 'America/Los_Angeles';
    case 'PDT':
      return 'America/Los_Angeles';
    default:
      return 'America/Chicago';
  }
}

export const getInitialValues = () => {
  const timeZone = formatShortZoneToLong(DateTime.local().offsetNameShort);
  return {
    hostFullName: localStorage.getItem('hostFullName') || '',
    hostFirstName: localStorage.getItem('hostFirstName') || '',
    hostLastName: localStorage.getItem('hostLastName') || '',
    hostAccountId: localStorage.getItem('hostAccountId') || '',
    hostEmail: localStorage.getItem('hostEmail') || '',
    hostPhone: localStorage.getItem('hostPhone') || '',
    eventType: localStorage.getItem('eventType') || '',
    hostNonProfitName: localStorage.getItem('hostNonProfitName') || '',
    hostType: localStorage.getItem('hostType') || '',
    hostCustomerType: localStorage.getItem('hostCustomerType') || '',
    displayAddress: localStorage.getItem('displayAddress') || '',
    eventVirtualLink: localStorage.getItem('eventVirtualLink') || '',
    addressLineOne: localStorage.getItem('addressLineOne') || '',
    addressLineTwo: localStorage.getItem('addressLineTwo') || '',
    city: localStorage.getItem('city') || '',
    state: localStorage.getItem('state') || '',
    zip: localStorage.getItem('zip') || '',
    eventDate: localStorage.getItem('eventDate') || '',
    eventTime: localStorage.getItem('eventTime') || '',
    eventTimeLocale: localStorage.getItem('eventTimeFormat') || 'PM',
    trinityPartyType: localStorage.getItem('trinityPartyType') || '',
    timeZone,
    title: localStorage.getItem('title') || '',
    skipNonProfit: localStorage.getItem('skipNonProfit') || 'false',
    showSupporters: localStorage.getItem('showSupporters') || 'true',
    eventLocation: localStorage.getItem('eventLocation') || 'false',
    nonProfitId: localStorage.getItem('nonProfitId') || '',
    nonProfitEditId: localStorage.getItem('nonProfitEditId') || '',
    charityType: localStorage.getItem('charityType') || '',
    charityDonationGoal: localStorage.getItem('charityDonationGoal') || '',
    charityQuote: localStorage.getItem('charityQuote') || '',
    lobMemo: localStorage.getItem('lobMemo') || '',
    showDonationGoal: localStorage.getItem('showDonationGoal') || 'false',
    preApprovedNonProfit: localStorage.getItem('preApprovedNonProfit') || '',
    attentionToName: localStorage.getItem('attentionToName') || '',
    canUpdateEventDates: true,
  };
};
