import React, { useState } from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { useTheme, useMediaQuery, theme } from '@onehope/design-system-v2';

import down from './assets/down.svg';

const HowItWorksContainer = styled.div`
  padding-left: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-top: 50px;
  width: 75%;
  margin-bottom: 30px;
  background-color: #fafafa;
  /* max-width: 1500px; */
`;

const mobileContainer = css`
  width: 100% !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
`;

const ComponentTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.5px;
`;

const MobileComponentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.5px;
  margin-left: -10px;
`;

const BulletPointWrapper = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: 20px;
  text-wrap: balance;
  line-height: 1.5;
`;

const CircleNumber = styled.div`
  height: 26px;
  min-width: 26px;
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 15px;
  margin: -2px 15px 5px 0;
  text-align: center;
`;

const AccordianHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const downArrowStyle = css`
  margin-top: -13px;
  height: 48px;
`;

export default function HowItWorks() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [openAccordian, setOpenAccordian] = useState(false);

  const toggleAccordian = () => {
    setOpenAccordian(!openAccordian);
  };

  const howItWorksContentTasting = (
    <>
      <BulletPointWrapper>
        <CircleNumber>1</CircleNumber>
        <div>
          Three ways to earn a Tasting Kit Credit
          <ul>
            <li>
              Earn 1 Tasting Kit Credit when you hit $500 retail sales each
              month.
            </li>
            <li>
              Earn 1 Tasting Kit Credit when you hit $1000 retail sales each
              month.
            </li>
            <li>
              Earn 1 Tasting Kit Credit when you help your Level 1 Wine Rep hit
              Rock Star. (No limit!)
            </li>
          </ul>
        </div>
      </BulletPointWrapper>
      <BulletPointWrapper>
        <CircleNumber>2</CircleNumber>
        <div>Apply 1 or 2 Tasting Kit Credits when creating a new event.</div>
      </BulletPointWrapper>
      <BulletPointWrapper>
        <CircleNumber>3</CircleNumber>
        <div>
          Once your event has been created, either you or the Host can select
          the wine for the tasting by using the event's unique ordering link.
        </div>
      </BulletPointWrapper>
      <BulletPointWrapper>
        <CircleNumber>4</CircleNumber>
        <div>
          Don't have any Tasting Kit Credits in the bank? No worries, wine can
          still be purchased starting at $49 for 6 bottles.
        </div>
      </BulletPointWrapper>
    </>
  );

  return (
    <HowItWorksContainer
      id="CeDash_HowItWorks"
      css={isMobile && mobileContainer}
    >
      {isMobile ? (
        <>
          <AccordianHeaderContainer>
            <MobileComponentTitle>HOW IT WORKS</MobileComponentTitle>
            <img
              src={down}
              css={downArrowStyle}
              onClick={toggleAccordian}
            ></img>
          </AccordianHeaderContainer>
          {openAccordian && howItWorksContentTasting}
        </>
      ) : (
        <>
          <ComponentTitle>HOW IT WORKS</ComponentTitle>
          {howItWorksContentTasting}
        </>
      )}
    </HowItWorksContainer>
  );
}
