import { createContext } from 'react';

export type ExternalConfigContextType = {
  /**
   * A custom hook to handle navigation to an arbitrary URL. Should return `true` if navigation was handled, and `false` if the default behavior should be invoked.
   * Example use case: navigating with the the Gatsby or NextJS router rather than performing a full browser navigation.
   */
  navigationHandler?: (url: string) => boolean;
};

export default createContext<ExternalConfigContextType>({});
