import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import { ErrorResponse, onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import fetch from 'isomorphic-fetch';

import introspectionQueryResultData from '../../../../tools/generate-graphql-typescript/filteredSchema.generated.json';
const GATSBY_GRAPHQL_SERVER_URL = process.env.GATSBY_GRAPHQL_SERVER_URL;

const graphQlUrl: string = `${GATSBY_GRAPHQL_SERVER_URL}/graphql`;

// @ts-ignore: types don't match up
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

/**
 * Create an Apollo client with the appropriate configuration
 */
export const client = new ApolloClient({
  queryDeduplication: true,
  link: ApolloLink.from([
    onError((error: ErrorResponse) => {
      logApolloError(error, 'ce-dahsboard');
    }),
    new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error,
      },
    }),
    new HttpLink({
      uri: graphQlUrl,
      fetch,
      credentials: 'include',
      // credentials: 'same-origin'
    }),
  ]),
  cache: new InMemoryCache({ fragmentMatcher }),
});

/**
 * When there is an Apollo GraphQL error, log it to the console.
 */
function logApolloError(error: ErrorResponse, clientName: string) {
  const errorPrefix = `[${clientName} GraphQL error]:`;
  const { graphQLErrors, networkError, operation, response } = error;
  if (graphQLErrors) {
    try {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(errorPrefix, {
          graphQlUrl,
          message,
          locations,
          operation,
          path,
          response,
        }),
      );
    } catch (error) {
      console.error(`${errorPrefix} Error logging graphQLErrors:`, error);
    }
  }
  try {
    if (networkError) {
      console.error(`${errorPrefix} Network error: ${networkError}`);
    }
  } catch (error) {
    console.error(`${errorPrefix} Error logging networkError:`, error);
  }
}
