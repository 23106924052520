import { useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Container,
  Spacer,
  Dialog,
  Icons,
  styled,
  useMediaQuery,
  theme,
} from '@onehope/design-system-v2';
import {
  SubscriptionWrapper,
  SubscriptionSegmentEvents,
  SubscriptionSegmentProps,
} from '@onehope/wine-club-subscription';
const { Close } = Icons;
import { useTrackingContextValue } from '../../../../../contexts/TrackingContext';

const CloseIcon = styled(Close)(({ isMobile }: { isMobile: boolean }) => ({
  position: 'absolute',
  top: isMobile ? '4px' : '40px',
  right: isMobile ? '4px' : '40px',
  cursor: 'pointer',
}));

interface SubscriptionDialogProps {
  autoShipId: string;
  contactId: string;
  openDialog: boolean;
  closeDialog: () => void;
}

const SubscriptionDialog = (props: SubscriptionDialogProps) => {
  const { autoShipId, contactId, openDialog, closeDialog } = props;
  const { wineClub923 } = useFlags();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { trackEventV2 } = useTrackingContextValue();
  const sendToTrackEventV2 = useCallback(
    ({
      event,
      properties,
    }: {
      event: SubscriptionSegmentEvents;
      properties: SubscriptionSegmentProps;
    }) => {
      trackEventV2({ event, properties });
    },
    [trackEventV2],
  );

  // guard against race condition where autoShipId: null propagates to the modal before it's closed
  if (!autoShipId) {
    return null;
  }

  return (
    <Dialog fullScreen open={openDialog} onClose={closeDialog}>
      <CloseIcon onClick={closeDialog} isMobile={isMobile} />
      <Container>
        <Spacer xl="64px" lg="64px" sm="64px" xs="16px"></Spacer>
        <SubscriptionWrapper
          autoShipId={autoShipId}
          contactId={contactId}
          sendToTrackEventV2={sendToTrackEventV2}
          wineClub923={wineClub923}
        />
      </Container>
    </Dialog>
  );
};

export default SubscriptionDialog;
