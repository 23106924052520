import React, { useCallback, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  SubscriptionSegmentEvents,
  SubscriptionSegmentProps,
  SubscriptionWrapper,
} from '@onehope/wine-club-subscription';
import {
  Icons,
  Text,
  TEXT_DS,
  Dialog,
  Container,
  Spacer,
  styled,
  useMediaQuery,
  theme,
} from '@onehope/design-system-v2';

import { GlobalCss } from '../ContactsTable';
import TablePagination from '../../TableComponents/Pagination';
import { FourBottleSubscriptionList } from './FourBottleSubscriptionList';

import GET_CUSTOMER_AUTOSHIPS from '../../../mutations/ContactsV2/GetCustomerAutoships';
import {
  CustomerAutoshipsContactsV2,
  CustomerAutoshipsContactsV2Variables,
} from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsContactsV2';
import {
  HeaderContainer,
  HeaderLeft,
  HeaderTitle,
  ActionsWrapper,
  TableContainer,
  ExportAutoshipsLink,
  ExportButtonContainer,
  DownloadCompleteContainer,
} from './styles';
import GET_CUSTOMER_AUTOSHIPS_CSV from '../../../mutations/ContactsV2/GetCustomerAutoshipsCSV';
import {
  CustomerAutoshipsCSVContactsV2,
  CustomerAutoshipsCSVContactsV2Variables,
} from '../../../mutations/ContactsV2/generatedTypes/CustomerAutoshipsCSVContactsV2';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';

const { DownloadNoBackground, CheckMark, Close } = Icons;

const CloseIcon = styled(Close)(({ isMobile }: { isMobile: boolean }) => ({
  position: 'absolute',
  top: isMobile ? '4px' : '40px',
  right: isMobile ? '4px' : '40px',
  cursor: 'pointer',
}));

export const FourBottleSubscribers = () => {
  const { wineClub923 } = useFlags();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [managingAutoshipId, setManagingAutoshipId] = useState<string>('');
  const [managingContactId, setManagingContactId] = useState<string>('');
  const [downloadComplete, setDownloadComplete] = useState<boolean>(false);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { data } = useQuery<
    CustomerAutoshipsContactsV2,
    CustomerAutoshipsContactsV2Variables
  >(GET_CUSTOMER_AUTOSHIPS, {
    variables: {
      maxWineCount: 5,
      take: rowsPerPage,
      skip: rowsPerPage * (pageNumber - 1),
    },
  });

  // NOTE: due to buggy Apollo behavior, this can only run once.
  // as a workaround, we hide the download link with a cheerful success message after it runs.
  // https://github.com/apollographql/apollo-client/issues/9338
  const [getCSVExport] = useLazyQuery<
    CustomerAutoshipsCSVContactsV2,
    CustomerAutoshipsCSVContactsV2Variables
  >(GET_CUSTOMER_AUTOSHIPS_CSV, {
    variables: {
      maxWineCount: 5,
    },
    onCompleted: data => {
      // generate data: URI and download
      const csvInBase64 = data.viewer.user.customerAutoshipsCSV;
      const link = document.createElement('a');
      link.href = `data:text/csv;base64,${csvInBase64}`;
      link.download = '4-bottle-subscribers.csv';
      link.click();

      setDownloadComplete(true);
    },
  });

  const { trackEventV2 } = useTrackingContextValue();
  const sendToTrackEventV2 = useCallback(
    ({
      event,
      properties,
    }: {
      event: SubscriptionSegmentEvents;
      properties: SubscriptionSegmentProps;
    }) => {
      trackEventV2({ event, properties });
    },
    [trackEventV2],
  );

  const handleRowsPerPageChange = (page: number) => {
    // reset the page number when rows per page change happens
    setPageNumber(1);
    // fire off the state change
    setRowsPerPage(page);
  };

  /**
   * Pop the Autoship concierge management modal so the Wine Rep can modify the subscription
   */
  const handleManageAutoship = (autoshipId: string, contactId: string) => {
    setManagingAutoshipId(autoshipId);
    setManagingContactId(contactId);
  };

  const handleExportCSV = () => getCSVExport();

  const closeManageDialog = () => {
    setManagingAutoshipId('');
    setManagingContactId('');
  };

  const customerAutoships = data?.viewer?.user?.customerAutoships;
  const autoships = customerAutoships?.autoships;
  const total = customerAutoships?.total;

  return (
    <>
      <GlobalCss />
      <HeaderContainer>
        <HeaderLeft>
          <HeaderTitle variant="h3">4-Bottle Subscribers</HeaderTitle>
        </HeaderLeft>
        <ActionsWrapper>
          {downloadComplete && (
            <DownloadCompleteContainer>
              <CheckMark />
              <Text component="p" variant="body">
                Download complete
              </Text>
            </DownloadCompleteContainer>
          )}
          {!downloadComplete && (
            <>
              <ExportButtonContainer onClick={handleExportCSV}>
                <DownloadNoBackground />
                <ExportAutoshipsLink
                  variant="custom"
                  default={TEXT_DS.BODY_SEMIBOLD_14}
                >
                  Export contacts
                </ExportAutoshipsLink>
              </ExportButtonContainer>
            </>
          )}
        </ActionsWrapper>
      </HeaderContainer>
      <TableContainer>
        <FourBottleSubscriptionList
          autoships={autoships}
          handleManageAutoship={handleManageAutoship}
        />
        <TablePagination
          page={pageNumber}
          count={total}
          rowsOnPage={(autoships && autoships.length) || 0}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={handleRowsPerPageChange}
          baseRoute={'contacts/four-bottle-subscribers'}
        />
      </TableContainer>
      <Dialog
        fullScreen
        open={!!managingAutoshipId}
        onClose={closeManageDialog}
      >
        <CloseIcon onClick={closeManageDialog} isMobile={isMobile} />
        <Container>
          <Spacer xl="64px" lg="64px" sm="64px" xs="16px"></Spacer>
          <SubscriptionWrapper
            autoShipId={managingAutoshipId}
            contactId={managingContactId}
            sendToTrackEventV2={sendToTrackEventV2}
            wineClub923={wineClub923}
          />
        </Container>
      </Dialog>
    </>
  );
};
