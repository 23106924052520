import React from 'react';
import { Text, TEXT_DS } from '@onehope/design-system-v2';
import { Line, Value } from '../../../../Event/TabContent/index.styles';
import { Address } from '../../../../Events/EventDetailsForm';
import {
  WideKey,
  Title,
  AddressContainer,
  AddressValue,
  ExpirationDateValue,
} from './summaryLineStyles.styles';
import { useImageStyles } from '../../../../Events/commonStyles';
import styled from '@emotion/styled';

export const BoldText = styled(Text)`
  && {
    font-weight: 500;
  }
`;

interface SummaryLineProps {
  title: string;
  value?: string | JSX.Element | null;
  address?: Address;
  imgSrc?: string;
  pointExpirationDate?: string;
  pointBalance?: number;
  width?: number;
}

export default function SummaryLine(props: SummaryLineProps) {
  const {
    title,
    value,
    address,
    imgSrc,
    pointExpirationDate,
    pointBalance,
    width,
  } = props;

  const imageClasses = useImageStyles(imgSrc)();

  return (
    <Line>
      <WideKey width={width}>
        <Title variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          {title}:
        </Title>
      </WideKey>
      {address && (
        <AddressContainer>
          <AddressValue>
            <Text>{address?.addressLineOne}</Text>
          </AddressValue>
          <AddressValue>
            <Text>{address?.addressLineTwo}</Text>
          </AddressValue>
          <AddressValue>
            <Text>
              {address?.city}, {address?.state} {address?.zip}{' '}
            </Text>
          </AddressValue>
        </AddressContainer>
      )}
      {imgSrc && <div className={imageClasses.image} />}
      {!!pointExpirationDate && !!pointBalance ? (
        <AddressContainer>
          <AddressValue>
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              {value}
            </Text>
          </AddressValue>
          <ExpirationDateValue>
            <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              Expire: {pointExpirationDate}
            </Text>
          </ExpirationDateValue>
        </AddressContainer>
      ) : (
        <>
          {value && !pointExpirationDate && (
            <Value>
              <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
                {value}
              </Text>
            </Value>
          )}
        </>
      )}
    </Line>
  );
}
