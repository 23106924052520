import * as React from 'react';
import { Notification } from './input.styles';
import { BadgeProps } from '@material-ui/core/Badge';

type BadgeContainerProps = BadgeProps & {
  label?: string;
};

export default function BadgeContainer(props: BadgeContainerProps) {
  const { label, ...rest } = props;
  return (
    <React.Fragment>
      <Notification {...rest} />
    </React.Fragment>
  );
}
