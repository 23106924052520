import { gql } from '@apollo/client';

const FEED_COUNT_QUERY = gql`
  query ActionEngineFeedCountQuery {
    viewer {
      id
      v2 {
        id
        actionEngineFeedCounts {
          new
        }
      }
    }
  }
`;

export default FEED_COUNT_QUERY;
