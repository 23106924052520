import gql from 'graphql-tag';
import {
  CauseOfChoiceNonProfit,
  CauseOfChoiceNonProfitAddress,
} from './NonProfitV2';

const NON_PROFIT_EDIT_QUERY = gql`
  query CEDash_NonProfitEdit($nonProfitEditId: String) {
    viewer {
      id
      v2 {
        id
        nonProfitEdit(nonProfitEditId: $nonProfitEditId) {
          id
          nonProfitEditId
          nonProfitName
          nonProfitAddress {
            ...CauseOfChoiceNonProfitAddress
          }
          nonProfit {
            ...CauseOfChoiceNonProfit
          }
        }
      }
    }
  }
  ${CauseOfChoiceNonProfit}
  ${CauseOfChoiceNonProfitAddress}
`;

export default NON_PROFIT_EDIT_QUERY;
