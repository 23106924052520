import React from 'react';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../common/Loading';
import ContactDetailsPage from './ContactDetailsPage';
import CONTACT_V2_QUERY from '../../queries/ContactV2';
import { ContactsContextProvider } from '../ContactsV3/ContactsContext';

interface EventPageProps {
  contactId: string | string[] | null | undefined;
  tab: string;
}

function goBack() {
  navigate('/contacts');
  return null;
}

function ContactPage({ contactId, tab }: EventPageProps) {
  const { data, loading, error } = useQuery(CONTACT_V2_QUERY, {
    variables: { contactId },
  });
  const contact = data?.viewer?.v2?.contactsV2?.contact;
  if (loading) return <Loading />;
  if (error) return goBack();
  if (contact) {
    return (
      <ContactsContextProvider contacts={[]}>
        <ContactDetailsPage contact={contact} tab={tab} />
      </ContactsContextProvider>
    );
  }
  return null;
}

export default ContactPage;
