import gql from 'graphql-tag';

const mutation = gql`
  mutation CardArchiveMutation($input: ActionEngineArchiveCardInput!) {
    actionEngineArchiveCard(input: $input) {
      status
    }
  }
`;

export default mutation;
