import gql from 'graphql-tag';
import { Customers_user } from '../../queries/Customers';

const mutation = gql`
  mutation ArchiveMultiContactsMutation($contactIds: [String]) {
    archiveMultiContacts(input: { contactIds: $contactIds }) {
      user {
        ...Customers_user
        id
      }
    }
  }
  ${Customers_user}
`;

export default mutation;
