import React, { Dispatch, SetStateAction } from 'react';
import { findSelectedPayment } from './PaymentsSelectView';
import PaymentDialog from '../PaymentDialog';
import StripeWrapper from '../StripeWrapper';
import { Account_PaymentsGetQuery_viewer_user_stripePaymentCollection_entries_edges_node_stripePayment as PaymentStripeType } from '../../../queries/generatedTypes/Account_PaymentsGetQuery';

export interface PaymentType {
  node: {
    id: string;
    stripePayment: PaymentStripeType;
  };
}

interface PaymentEditViewProps {
  payments: Array<PaymentType>;
  editPaymentId: string | null;
  setView: (input: string) => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  defaultPaymentId: string;
  setSelectedPayment: Dispatch<SetStateAction<PaymentStripeType | null>>;
  contactId?: string;
}

const PaymentEditView = (props: PaymentEditViewProps) => {
  const {
    setView,
    payments,
    setSelectedPayment,
    editPaymentId,
    defaultPaymentId,
  } = props;
  const payment = findSelectedPayment(editPaymentId, payments)?.node
    ?.stripePayment;

  const handleClose = () => {
    setView('SELECT');
  };
  return (
    <StripeWrapper>
      <PaymentDialog
        open={true}
        payment={payment}
        isDefault={defaultPaymentId === payment?.paymentId}
        onClose={handleClose}
        setSelectedPayment={setSelectedPayment}
        isEditing
        hasDefaultPaymentId={!!defaultPaymentId}
        key="Edit Payment"
        headerText="Edit Payment"
        contactId={props.contactId}
      />
    </StripeWrapper>
  );
};

export default PaymentEditView;
