// @ts-ignore
import * as Yup from 'yup';
import {
  EventHostingTypeEnum,
  EventTypeEnum,
} from '../../../EventsV2/eventTypes';

const dayInMilliseconds = 24 * 60 * 60 * 1000;
const ninetyDaysInMilliseconds = dayInMilliseconds * 90;
export const EventDetailsFormSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  eventType: Yup.string().required('Required'),
  trinityPartyType: Yup.string().when('eventType', {
    is: EventTypeEnum.WineTasting,
    then: Yup.string().required('Required'),
  }),
  hostType: Yup.string().required('Required'),
  eventDate: Yup.date()
    .when('isDateUpdated', {
      is: true,
      then: Yup.date()
        .typeError('Invalid date, make sure format is MM/DD/YYYY')
        .min(new Date(), 'Invalid time, date must be in the future')
        .when('eventType', {
          is: EventTypeEnum.Fundraiser,
          then: Yup.date().max(
            new Date(Date.now() + ninetyDaysInMilliseconds),
            'The event end date must be within 90 days of the event creation date',
          ),
        }),
    })
    .when('eventType', {
      is: EventTypeEnum.WineTasting,
      then: Yup.date().required('Required'),
    }),
  eventTime: Yup.string()
    .matches(
      /^\b((1[0-2]|0?[1-9]):([0-5][0-9]))$/,
      'Invalid time, make sure format is 06:00',
    )
    .when('eventType', {
      is: EventTypeEnum.WineTasting,
      then: Yup.string().required('Required'),
    }),
  eventTimeLocale: Yup.string().when('eventType', {
    is: EventTypeEnum.WineTasting,
    then: Yup.string().required('Required'),
  }),
  timeZone: Yup.string().when('eventType', {
    is: EventTypeEnum.WineTasting,
    then: Yup.string().required('Required'),
  }),
  hostFullName: Yup.string()
    .when('hostType', {
      is: EventHostingTypeEnum.Contact,
      then: Yup.string().required('Required'),
    })
    .when('hostType', {
      is: EventHostingTypeEnum.NonProfit,
      then: Yup.string().required('Required'),
    }),
  displayAddress: Yup.string(),
  addressLineOne: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  state: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  city: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  zip: Yup.string().when('trinityPartyType', {
    is: 'EVENT',
    then: Yup.string().required('Required'),
  }),
  charityDonationGoal: Yup.string().when('showDonationGoal', {
    is: 'true',
    then: Yup.string().required('Required'),
  }),
});
