import { gql } from '@apollo/client';
import { CauseOfChoiceNonProfit } from '../queries/NonProfitsQuery';

export const ADDRESS_AUTOCOMPLETE = gql`
  mutation CauseOfChoiceNonProfitV2Mutation($input: NonProfitV2AddInput!) {
    nonProfitV2Add(input: $input) {
      nonProfitV2 {
        ...CauseOfChoiceNonProfit
      }
      nonProfitV2Existing {
        ...CauseOfChoiceNonProfit
      }
    }
  }
  ${CauseOfChoiceNonProfit}
`;

export default ADDRESS_AUTOCOMPLETE;
