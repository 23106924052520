import React, { useState } from 'react';
import { Icons, Grid, theme, Text, TEXT_DS } from '@onehope/design-system-v2';
import styled from '@emotion/styled';

import {
  DetailsTitle,
  StraightLine,
  TabContentContainer,
} from '../index.styles';
import SummaryLine from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine';
import { isSatelliteEvent } from '../../../Events/EventsTable/helpers';
import { getEventDescription } from '../../Header';
import { formatDisplayDate } from '../../../Events/EventDetailsForm/EventSummary';
import { LinkV2 } from '../../../ContactV2/ContactDetailsPage/TabContent/SummaryLine/summaryLineStyles.styles';
import { EventStatusDictionary } from '../../../../utils/enums';
import { CEDash_EventDetailsPage_viewer_event } from '../../../../queries/generatedTypes/CEDash_EventDetailsPage';

const { Copy } = Icons;

const CopyIcon = styled(Copy)`
  margin-left: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const CopyText = styled(Text)`
  margin-left: 16px;
  && {
    font-weight: 420;
    color: ${theme.palette.greyDark.main};
  }
`;

type Props = {
  event: CEDash_EventDetailsPage_viewer_event;
};

function Info(props: Props) {
  const { event } = props;
  const [copied, setCopied] = useState(false);
  const satelliteEvent = isSatelliteEvent(event);
  const closedEvent = event?.status === EventStatusDictionary.CLOSED;
  const timeZone = event?.timeZone ? event.timeZone : 'America/Chicago'; // Fall back for trinity events
  const closeDate = formatDisplayDate(event.closeDate, timeZone);
  const manualCloseDate = formatDisplayDate(event.manualCloseDate, timeZone);
  const donationSubtotal = event?.donationInfo?.donationAmount || 0;
  const hostRewardsQualifiedSubTotal = event?.hostRewardsQualifiedSubTotal || 0;
  const showCancelButton =
    donationSubtotal <= 0 && hostRewardsQualifiedSubTotal <= 0;

  const urlId = event?.exigoPartyId?.toString() ?? event?.eventId;
  const url = satelliteEvent
    ? `${process.env.GATSBY_HOPECOMMERCE_URL}/${event?.groupFundraiserSlug}?dealer=${event?.trinityDealerId}`
    : `${process.env.GATSBY_MEGALITH_URL}/event/${urlId}`;

  const goToEvent = () => {
    window.open(url, '_blank');
  };

  const copyToClipboard = () => {
    // using this method because of struggles
    // with refs on the mui input
    if (!!document) {
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  return (
    <TabContentContainer>
      <DetailsTitle variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
        Info
      </DetailsTitle>
      <StraightLine />
      <SummaryLine
        title="Url"
        width={140}
        value={
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <LinkV2 onClick={goToEvent}>{url}</LinkV2>
            </Grid>
            <Grid item>
              {copied ? (
                <CopyText>Copied</CopyText>
              ) : (
                <CopyIcon
                  onClick={copyToClipboard}
                  color={theme.palette.forestGreen.main}
                />
              )}
            </Grid>
          </Grid>
        }
      />
      <SummaryLine width={140} title="Event ID#" value={urlId} />
      <SummaryLine
        width={140}
        title="Event type"
        value={getEventDescription(event)}
      />
      {/*{closeDate && !satelliteEvent && (*/}
      {/*  <div>*/}
      {/*    {!manualCloseDate && closeDate && (*/}
      {/*      <SummaryLine width={140} title="Close Date" value={closeDate} />*/}
      {/*    )}*/}
      {/*    {manualCloseDate && (*/}
      {/*      <SummaryLine*/}
      {/*        width={140}*/}
      {/*        title="Event closed on"*/}
      {/*        value={manualCloseDate}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*)}*/}
    </TabContentContainer>
  );
}

export default Info;
