import React from 'react';
import { navigate } from 'gatsby';

export default class Page404 extends React.Component {
  componentDidMount() {
    navigate('/events');
  }
  render() {
    return <div />;
  }
}
