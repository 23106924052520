import React, { Dispatch, SetStateAction, useState } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import {
  AutoCompleteV2 as AutoComplete,
  ButtonBase,
  ButtonV2,
  ChunkyButton,
  Grid,
  Icons,
  Input,
  Text,
} from '@onehope/design-system-v2';
import { formatNonProfitAddress } from '@onehope/utils';

/* MUTATIONS */
import ADDRESS_AUTOCOMPLETE from '../../../mutations/AddressAutoCompleteMutation';
import ADDRESS_ZIP_AUTOCOMPLETE from '../../../mutations/AddressGooglePlacesZipMutation';
import NON_PROFIT_ADD_MUTATION from '../../../mutations/NonProfitV2AddMutation';
import UPDATE_SELECTED_CAUSE_MUTATION from '../../../mutations/UpdateSelectedCauseMutation';
import ADD_NONPROFIT_EDIT_MUTATION from '../../../mutations/AddNonProfitEditMutation';

/* UTILS */
import EinMask from '../../../utils/EinMask';
import useToggle from '../../../utils/useToggleHook';
import { statesAbbrev } from '../../../utils/statesAbbrev';
import validationSchema from './validationSchema';
import { useCommonStyles } from '../../../utils/commonStyles';
import { isEventCeDashPage, isOHFoundationNpo } from '../../../utils/helper';
import {
  AddressType,
  CauseOfChoiceTypeKeys,
  NonProfitType,
  ManualNonProfitInputType,
} from '../../../stateManagement/types';
import {
  CauseOfChoiceScreenDictionary,
  TypeKeys,
} from '../../../stateManagement/types';
import { useCauseOfChoiceContextValue } from '../../../stateManagement/context';
import { useCauseOfChoiceNetworkLayerValue } from '../../../stateManagement/networkLayer';

function scrollToBottom() {
  if (window && document) {
    const suggestionsElement = document.getElementById('suggestions-anchor');
    if (suggestionsElement && suggestionsElement.scrollIntoView) {
      suggestionsElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

export default function ManualNonProfitWrapper({
  existingNonProfit,
  setExistingNonProfit,
  handlePageUpdate,
  setSelectedNonProfitId,
  initialNonProfitValues,
  setInitialNonProfitValues,
}: {
  initialNonProfitValues: ManualNonProfitInputType;
  existingNonProfit: NonProfitType | null;
  handlePageUpdate: () => void;
  setExistingNonProfit: Dispatch<SetStateAction<NonProfitType | null>>;
  setSelectedNonProfitId: Dispatch<SetStateAction<string | null | undefined>>;
  setInitialNonProfitValues: Dispatch<SetStateAction<ManualNonProfitInputType>>;
}) {
  const classes = useCommonStyles();
  const [addressPredictions, setAddressPredictions] = useState([]);

  const [, dispatch] = useCauseOfChoiceContextValue();
  const {
    modalLocation,
    trackUpdateCauseOfChoice,
  } = useCauseOfChoiceNetworkLayerValue();
  const {
    handleCloseModal,
    handleNonProfitSelected,
  } = useCauseOfChoiceNetworkLayerValue();
  const [addressAutoComplete] = useMutation(ADDRESS_AUTOCOMPLETE);
  const [addressZipMutation] = useMutation(ADDRESS_ZIP_AUTOCOMPLETE);
  const [
    addCustomNonProfit,
    { loading: addCustomNonProfitMutationLoading },
  ] = useMutation(NON_PROFIT_ADD_MUTATION);
  const [
    updateSelectedCauseMutation,
    { loading: selectedCauseMutationLoading },
  ] = useMutation(UPDATE_SELECTED_CAUSE_MUTATION);
  const [
    addNonProfitEditMutation,
    { loading: addNonProfitEditMutationLoading },
  ] = useMutation(ADD_NONPROFIT_EDIT_MUTATION);

  const isOnEventPage = isEventCeDashPage();
  const isOHFoundation = isOHFoundationNpo(existingNonProfit?.nonProfitTaxId);
  const showEditNonProfitFunctionality = isOnEventPage && !isOHFoundation;

  const loading =
    addCustomNonProfitMutationLoading ||
    selectedCauseMutationLoading ||
    addNonProfitEditMutationLoading;

  const {
    value: menuOpen,
    setTrue: setMenuOpen,
    setFalse: setMenuClosed,
  } = useToggle(false);

  const {
    value: showManualAddress,
    setTrue: setActivateManualAddress,
    setFalse: setDeactivateManualAddress,
  } = useToggle(false);

  const handleDeactivateManualAddress = (
    setFieldValue: (inputId: string, item: string | null) => void,
  ) => {
    setDeactivateManualAddress();
    setFieldValue('addressLineOne', null);
    setFieldValue('address', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
    setFieldValue('zip', '');
    setAddressPredictions([]);
  };

  const handleCustomNonProfitSelected = () => {
    // custom behavior for event NPO selection
    if (handleNonProfitSelected) {
      if (existingNonProfit?.nonProfitId) {
        handleNonProfitSelected({ nonProfitId: existingNonProfit.nonProfitId });
      }
      return handleCloseModal();
    }
    handleLoadingState(true);
    const variables = {
      input: {
        nonProfitId: existingNonProfit?.nonProfitId,
      },
    };
    return updateSelectedCauseMutation({ variables }).then((res) => {
      const user = res?.data?.causeUpdateSelected?.user;
      handleLoadingState(false);
      if (user) {
        setExistingNonProfit(null);
        setSelectedNonProfitId(null);
        handleGotoConfirmationPage();
      }
      if (trackUpdateCauseOfChoice) {
        trackUpdateCauseOfChoice({
          selectedCause: res?.data?.causeUpdateSelected?.user?.selectedCause,
          guestCause:
            res?.data?.causeUpdateSelected?.user?.cartV2?.guestNonProfit,
          causeType: CauseOfChoiceTypeKeys.NON_PROFIT,
          updateLocation: modalLocation,
          shoppingWithCeoUser:
            res?.data?.causeUpdateSelected?.user?.shoppingWithCeoUser,
        });
      }
    });
  };

  const handleAddNonProfitEdit = (values: {
    customNonProfit: string;
    nonProfitTaxId: string;
    address: string;
    addressLineOne: string;
    city: string;
    state: string;
    addressLineTwo: string;
    zip: string;
  }) => {
    handleLoadingState(true);
    const variables = {
      input: {
        nonProfitId: existingNonProfit?.nonProfitId,
        nonProfitName: values.customNonProfit || null,
        addressLineOne: values.addressLineOne || null,
        addressLineTwo: values.addressLineTwo || null,
        city: values.city || null,
        state: values.state || null,
        zip: values.zip || null,
      },
    };
    addNonProfitEditMutation({ variables }).then((res) => {
      const nonProfitEdit = res?.data?.nonProfitEditAdd?.nonProfitEdit;
      handleLoadingState(false);
      if (handleNonProfitSelected) {
        if (existingNonProfit?.nonProfitId && nonProfitEdit?.nonProfitEditId) {
          handleNonProfitSelected({
            nonProfitId: existingNonProfit.nonProfitId,
            nonProfitEditId: nonProfitEdit.nonProfitEditId,
          });
        }
      }
      handleCloseModal();
    });
  };

  const handleLoadingState = (loadingState: boolean) => {
    dispatch({
      type: TypeKeys.UPDATE_LOADING,
      loading: loadingState,
    });
  };

  const handleInputAddressChange = async (search: string) => {
    const variables = {
      input: {
        query: search,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setMenuOpen();
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions?.filter((address: AddressType) => {
          return (
            statesAbbrev.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: AddressType) => {
        return !!address;
      });
      setAddressPredictions(truthyList);
    });
  };

  const handleAddressSelected = () => {
    setMenuClosed();
  };

  const handleGotoConfirmationPage = () => {
    dispatch({
      type: TypeKeys.UPDATE_SCREEN,
      screen: CauseOfChoiceScreenDictionary.NON_PROFIT_CONFIRMATION_SCREEN,
    });
  };

  return (
    <Grid container>
      <Grid container direction="column">
        <Formik
          initialValues={initialNonProfitValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            // set updated values
            setInitialNonProfitValues(values);

            if (existingNonProfit) {
              return handleAddNonProfitEdit(values);
            }

            const variables = {
              input: {
                nonProfitTaxId: values.nonProfitTaxId,
                nonProfitName: values.customNonProfit,
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo || null,
                city: values.city,
                state: values.state,
                zip: values.zip,
              },
            };
            return addCustomNonProfit({ variables })
              .then((res: any) => {
                const nonProfitId =
                  res?.data?.nonProfitV2Add?.nonProfitV2?.nonProfitId;
                const existingNonProfit =
                  res?.data?.nonProfitV2Add?.nonProfitV2Existing;
                if (existingNonProfit) {
                  setSubmitting(false);
                  setFieldError(
                    'nonProfitTaxId',
                    'A nonprofit with this Tax ID (EIN #) already exists',
                  );
                  setExistingNonProfit(existingNonProfit);
                  setSelectedNonProfitId(existingNonProfit.nonProfitId);
                  scrollToBottom();
                } else if (nonProfitId) {
                  // custom behavior for event NPO selection
                  if (handleNonProfitSelected) {
                    handleNonProfitSelected({ nonProfitId });
                    return handleCloseModal();
                  }
                  const variables = {
                    input: {
                      nonProfitId,
                    },
                  };
                  updateSelectedCauseMutation({ variables }).then((res) => {
                    setSubmitting(false);
                    handleGotoConfirmationPage();
                    if (trackUpdateCauseOfChoice) {
                      trackUpdateCauseOfChoice({
                        selectedCause:
                          res?.data?.causeUpdateSelected?.user?.selectedCause,
                        guestCause:
                          res?.data?.causeUpdateSelected?.user?.cartV2
                            ?.guestNonProfit,
                        causeType: CauseOfChoiceTypeKeys.NON_PROFIT,
                        updateLocation: modalLocation,
                        shoppingWithCeoUser:
                          res?.data?.causeUpdateSelected?.user
                            ?.shoppingWithCeoUser,
                      });
                    }
                  });
                } else {
                  setSubmitting(false);
                  setFieldError('customNonProfit', 'error');
                }
              })
              .catch((error: any) => {
                setSubmitting(false);
                setFieldError(
                  'nonProfitTaxId',
                  error?.graphQLErrors?.[0]?.message || 'Error',
                );
              });
          }}
        >
          {({
            values,
            submitForm,
            isSubmitting,
            errors,
            touched,
            handleChange,
            setFieldValue,
          }) => (
            <Form className={classes.form}>
              <div className={classes.formItem}>
                <Input
                  fullWidth
                  label="Name of Nonprofit"
                  name="customNonProfit"
                  type="customNonProfit"
                  placeholder="American Red Cross"
                  value={values.customNonProfit}
                  onChange={handleChange}
                  error={
                    touched.customNonProfit && Boolean(errors.customNonProfit)
                  }
                  hasError={
                    touched.customNonProfit && Boolean(errors.customNonProfit)
                  }
                  validate
                  helperText={touched.customNonProfit && errors.customNonProfit}
                />
              </div>
              {!showManualAddress && (
                <div className={classes.formItem}>
                  <AutoComplete
                    label="Address 1"
                    id="displayAddressDropDown"
                    inputId="address"
                    isWithinDialog={true}
                    placeholder="Enter address"
                    value={values.address}
                    touched={touched.address}
                    isSubmitting={isSubmitting}
                    isMenuOpen={menuOpen}
                    setFieldValue={setFieldValue}
                    toggleAddManualAddress={setActivateManualAddress}
                    handleAddressSelected={handleAddressSelected}
                    handleChange={handleChange}
                    getAddressZipMutation={addressZipMutation}
                    handleInputChange={handleInputAddressChange}
                    handleOuterClick={setMenuClosed}
                    objectPredictions={addressPredictions}
                    showLocationIcon
                    error={
                      touched.addressLineOne && Boolean(errors.addressLineOne)
                    }
                  />
                  {Boolean(errors.addressLineOne) && (
                    <div className={classes.helperText}>
                      Address line one required
                    </div>
                  )}
                </div>
              )}
              {showManualAddress && (
                <div>
                  <div className={classes.formItem}>
                    <Input
                      fullWidth
                      label="Address 1"
                      name="addressLineOne"
                      type="addressLineOne"
                      placeholder="Enter address"
                      value={values.addressLineOne}
                      onChange={handleChange}
                      error={
                        touched.addressLineOne && Boolean(errors.addressLineOne)
                      }
                      hasError={
                        touched.addressLineOne && Boolean(errors.addressLineOne)
                      }
                      validate
                      helperText={
                        touched.addressLineOne && errors.addressLineOne
                      }
                    />
                  </div>
                  <Grid container justifyContent="flex-end">
                    <ButtonBase
                      onClick={() =>
                        handleDeactivateManualAddress(setFieldValue)
                      }
                    >
                      <Text className={classes.underlineText}>
                        Back to address search
                      </Text>
                    </ButtonBase>
                  </Grid>
                </div>
              )}
              <div className={classes.formItem}>
                <Input
                  fullWidth
                  label="Address 2"
                  placeholder="Apt, Ste, Bldg number"
                  id="addressLineTwo"
                  name="addressLineTwo"
                  value={values.addressLineTwo}
                  onChange={handleChange}
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  helperText={touched.addressLineTwo && errors.addressLineTwo}
                  hasError={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  validate
                />
              </div>
              {showManualAddress && (
                <div>
                  <div className={classes.formItem}>
                    <Input
                      fullWidth
                      label="City"
                      name="city"
                      type="city"
                      placeholder="Enter city"
                      value={values.city}
                      onChange={handleChange}
                      error={touched.city && Boolean(errors.city)}
                      hasError={touched.city && Boolean(errors.city)}
                      validate
                      helperText={touched.city && errors.city}
                    />
                  </div>
                  <Grid container spacing={2} className={classes.formItem}>
                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        label="State"
                        name="state"
                        type="state"
                        placeholder="CA"
                        value={values.state}
                        onChange={handleChange}
                        error={touched.state && Boolean(errors.state)}
                        hasError={touched.state && Boolean(errors.state)}
                        validate
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        fullWidth
                        label="Zip code"
                        placeholder="90210"
                        id="zip"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        error={touched.zip && Boolean(errors.zip)}
                        helperText={touched.zip && errors.zip}
                        hasError={touched.zip && Boolean(errors.zip)}
                        validate
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={classes.formItem}>
                <Input
                  fullWidth
                  label="Tax ID (EIN #)"
                  placeholder="e.g. 12-3456789"
                  id="nonProfitTaxId"
                  name="nonProfitTaxId"
                  value={values.nonProfitTaxId}
                  onChange={handleChange}
                  error={
                    touched.nonProfitTaxId && Boolean(errors.nonProfitTaxId)
                  }
                  helperText={touched.nonProfitTaxId && errors.nonProfitTaxId}
                  hasError={
                    touched.nonProfitTaxId && Boolean(errors.nonProfitTaxId)
                  }
                  validate
                  mask={EinMask}
                />
              </div>
              {existingNonProfit && (
                <Grid>
                  <Text className={classes.container}>
                    It looks like a nonprofit with this Tax ID (EIN #) already
                    exists. Did you mean to choose this one?
                  </Text>
                  <ChunkyButton
                    avatar={<Icons.COCHeartHands />}
                    onChange={
                      showEditNonProfitFunctionality
                        ? undefined
                        : handleCustomNonProfitSelected
                    }
                    onChangeText={
                      showEditNonProfitFunctionality ? undefined : 'Select'
                    }
                    primaryText={`${existingNonProfit?.nonProfitName}`}
                    secondaryText={formatNonProfitAddress(
                      existingNonProfit?.nonProfitAddress,
                    )}
                    tertiaryText={`EIN: ${existingNonProfit?.nonProfitTaxId}`}
                    headerIcon={
                      existingNonProfit?.isIRSVerified ? (
                        <Icons.COCVerified />
                      ) : null
                    }
                  />
                  {showEditNonProfitFunctionality ? (
                    <div>
                      <div className={classes.buttonActions}>
                        <div className={classes.buttonPadding}>
                          <ButtonV2
                            type="primary"
                            disabled={isSubmitting || loading}
                            fullWidth
                            loading={isSubmitting || loading}
                            onClick={handleCustomNonProfitSelected}
                          >
                            Yes, use suggested nonprofit
                          </ButtonV2>
                        </div>
                        <ButtonV2
                          type="secondary"
                          disabled={isSubmitting || loading}
                          fullWidth
                          loading={isSubmitting || loading}
                          onClick={submitForm}
                        >
                          No, use the information I provided
                        </ButtonV2>
                      </div>
                      <ButtonV2
                        type="tertiary"
                        fullWidth
                        onClick={handlePageUpdate}
                        id="suggestions-anchor"
                      >
                        View more suggestions
                      </ButtonV2>
                    </div>
                  ) : (
                    <Text className={classes.correctInfoContainer}>
                      Is this information incorrect?{' '}
                      <a
                        className={classes.link}
                        href="mailto:support@onehopewine.com"
                      >
                        Contact Support
                      </a>
                    </Text>
                  )}
                </Grid>
              )}
              {!existingNonProfit && (
                <div className={classes.buttonClose}>
                  <ButtonV2
                    type="primary"
                    disabled={isSubmitting || loading}
                    fullWidth
                    loading={isSubmitting || loading}
                    onClick={submitForm}
                  >
                    Save
                  </ButtonV2>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
