import React from 'react';

const SvgArrow = (props: any) => {
  const { fill, ...rest } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.20005 11.4H14.502L11.148 8.046L12 7.2L16.8 12L12 16.8L11.154 15.954L14.502 12.6H7.20005V11.4Z"
        fill={fill || '#393939'}
      />
    </svg>
  );
};

export default SvgArrow;
