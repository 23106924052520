import { gql } from '@apollo/client';

const USER_LIVE_CHAT = gql`
  query CEDash_UserLiveChatQuery {
    viewer {
      id
      user {
        id
        email
        hubspotIdentificationToken
      }
    }
  }
`;

export default USER_LIVE_CHAT;
