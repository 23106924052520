// source: ChatGPT
// prompt: "please produce an API-compatible standalone version of the debounce function from lodash"

// lodash was proving difficult to import into this library, then again into Gatsby and NextJS due to ES module vs CommonJS issues.

export function debounce(func, wait, options) {
  let timeout;
  let lastArgs;
  let lastThis;
  let result;
  let previous = 0;
  if (options === undefined) options = {};

  const leading = options.leading;
  const maxWait = options.maxWait;
  const trailing = options.trailing === undefined ? true : options.trailing;

  const later = function () {
    previous = leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(lastThis, lastArgs);
    if (!timeout) lastThis = lastArgs = null;
  };

  const debounced = function (...args) {
    const now = Date.now();
    if (!previous && leading === false) previous = now;
    const remaining = wait - (now - previous);
    lastThis = this;
    lastArgs = args;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(lastThis, args);
      if (!timeout) lastThis = lastArgs = null;
    } else if (!timeout && trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  debounced.cancel = function () {
    if (timeout) {
      clearTimeout(timeout);
      previous = 0;
      timeout = null;
    }
  };

  debounced.flush = function () {
    if (timeout) {
      clearTimeout(timeout);
      later();
    }
  };

  if (maxWait !== undefined) {
    maxWait = Math.max(maxWait - (Date.now() - previous), 0);
  }

  if (options.leading === false) {
    return debounced;
  }

  return debounced;
}
