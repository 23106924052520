import * as React from 'react';

import { Tab } from '@onehope/design-system-v2';
import { Container, TabsContainer } from './TabNavigation.styles';

import { config } from './config';

type EventsTabNavigationProps = {
  handleChange: (event: React.FormEvent<EventTarget>, value: number) => void;
  tabValue: number;
};

function EventsTabNavigation(props: EventsTabNavigationProps) {
  const { handleChange, tabValue } = props;
  return (
    <Container>
      <TabsContainer
        key="EventsTabNavigation"
        value={tabValue}
        scrollButtons={false}
        variant="scrollable"
        onChange={handleChange}
      >
        {config.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            disabled={tab.disabled}
            data-cypress={tab.cypressData}
          />
        ))}
      </TabsContainer>
    </Container>
  );
}

export default EventsTabNavigation;
