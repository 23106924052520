import { EventManagementTabs } from './tabConstants';

export const config = (hideTastingKit: boolean) => {
  const configObject = [
    {
      label: 'Summary',
      value: EventManagementTabs.SUMMARY,
      cypressData: 'event-summary-tab-button',
    },
    {
      label: 'Details',
      value: EventManagementTabs.DETAILS,
      cypressData: 'event-details-tab-button',
    },
    {
      label: 'Nonprofit',
      value: EventManagementTabs.CHARITY,
      cypressData: 'event-charity-tab-button',
    },
    {
      label: 'Event Orders',
      value: EventManagementTabs.GUEST_ORDERS,
      cypressData: 'event-guest-orders-tab-button',
    },
  ];

  return configObject;
};

export const virtualWineTastingConfig = () => {
  return [
    {
      label: 'Summary',
      value: EventManagementTabs.SUMMARY,
      cypressData: 'event-summary-tab-button',
    },
    {
      label: 'Details',
      value: EventManagementTabs.DETAILS,
      cypressData: 'event-details-tab-button',
    },
    {
      label: 'Nonprofit',
      value: EventManagementTabs.CHARITY,
      cypressData: 'event-charity-tab-button',
    },
    {
      label: 'Event Orders',
      value: EventManagementTabs.GUEST_ORDERS,
      cypressData: 'event-guest-orders-tab-button',
    },
  ];
};

export const satelliteEventConfig = [
  {
    label: 'Details',
    value: 0,
    cypressData: 'event-details-tab-button',
  },
  {
    label: 'Nonprofit',
    value: 2,
    cypressData: 'event-charity-tab-button',
  },
  {
    label: 'Host Rewards',
    value: 3,
    cypressData: 'event-host-rewards-tab-button',
  },
  {
    label: 'Event Orders',
    value: 4,
    cypressData: 'event-guest-orders-tab-button',
  },
];

export const oldConfig = [
  {
    label: 'Details',
    value: 0,
    cypressData: 'event-details-tab-button',
  },
  {
    label: 'Host Kit',
    value: 1,
    cypressData: 'event-host-kit-tab-button',
  },
  {
    label: 'Charity',
    value: 2,
    cypressData: 'event-charity-tab-button',
  },
  {
    label: 'Host Rewards',
    value: 3,
    cypressData: 'event-host-rewards-tab-button',
  },
  {
    label: 'Guest Orders',
    value: 4,
    cypressData: 'event-guest-orders-tab-button',
  },
];
