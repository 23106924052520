import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Grid, Icons, styled } from '@onehope/design-system-v2';

import BreadCrumb from '../BreadCrumb';
import ResultsTable from './ResultsTable';
import { PageContainer } from '../StyledComponents/Tables';
import { MainHeaderText, BodyText } from '../StyledComponents/Text';
import Loading from '../../../common/Loading';
import REACH_OUT_RESULTS from '../../../queries/ReachOutResultsV2';

import { ReachOutRequestStatus } from './../../../../generatedTypes/globalTypes';
import {
  CEDash_ReachOutResults,
  CEDash_ReachOutResultsVariables,
  CEDash_ReachOutResults_viewer_v2_reachOutResults,
  CEDash_ReachOutResults_viewer_v2_reachOutTemplate,
} from '../../../queries/generatedTypes/CEDash_ReachOutResults';
import ViewCounts from './ViewCounts';

export const InviteResultsIcon = styled(Icons.InviteResults)({
  marginBottom: '36px',
});

/**
 * Display the results of all ReachOut Requests for a given ReachOut.
 */
export default function Results({ reachOutId }: { reachOutId: string }) {
  const [completedResults, setCompletedResults] = useState<
    Set<CEDash_ReachOutResults_viewer_v2_reachOutResults>
  >(new Set());
  const [ceAccountId, setCeAccountId] = useState('');
  const [
    reachOut,
    setReachOut,
  ] = useState<CEDash_ReachOutResults_viewer_v2_reachOutTemplate | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const limit = 50;
  const statusFilter = [
    ReachOutRequestStatus.CLICKED,
    ReachOutRequestStatus.COMPLETE,
  ];

  const [getReachOutRequest, { data, refetch, loading, error }] = useLazyQuery<
    CEDash_ReachOutResults,
    CEDash_ReachOutResultsVariables
  >(REACH_OUT_RESULTS, {
    variables: {
      reachOutId,
      limit,
      offset,
      statusFilter,
    },
    onCompleted: () => {
      if (!reachOut) {
        const template = data?.viewer?.v2?.reachOutTemplate;
        setReachOut(template);
      }

      setCeAccountId(data?.viewer?.user?.userId || '');

      const results = data?.viewer?.v2?.reachOutResults || [];

      const stopFetching = results.length < limit;
      if (stopFetching) {
        setHasMore(false);
      }

      if (results.length > 0) {
        const newSet = new Set(Array.from(completedResults));
        results.forEach((result) => {
          newSet.add(result);
        });
        setCompletedResults(newSet);

        // keep setting offset until there are no more rows to be fetched
        if (!stopFetching) {
          setOffset(offset + limit);
        }
      }
    },
  });

  useEffect(() => {
    getReachOutRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadMore = () => {
    refetch({
      limit,
      offset: offset + limit,
      reachOutId,
      statusFilter,
    });
  };

  if (error) return null;
  if (loading && typeof data === 'undefined') {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumb
        page="Results"
        reachOutId={reachOutId}
        previousPage={reachOut?.name || ''}
      />
      <PageContainer>
        <MainHeaderText text="Results" />

        <ViewCounts reachOutId={reachOutId} />

        {completedResults.size > 0 ? (
          <ResultsTable
            ceAccountId={ceAccountId}
            results={Array.from(completedResults)}
            hasMore={hasMore}
            onLoadMore={onLoadMore}
            reachOutId={reachOutId}
          />
        ) : (
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <InviteResultsIcon />
            </Grid>
            <Grid item>
              <BodyText
                text={`You do not have any results for this reach out.`}
              />
            </Grid>
          </Grid>
        )}
      </PageContainer>
    </>
  );
}
