import { DateTime } from 'luxon';
import parser from 'parse-address';
import { FormikErrors } from 'formik';
import Resizer from 'react-image-file-resizer';
import { v4 } from 'uuid';

import { MyFormValues as DetailsFormTypes } from '../../Event/TabContent/Details/FormTypes';
import { MyFormValues as CharityFormTypes } from '../../Event/TabContent/Charity/FormTypes';
import { EventHostingTypeEnum, EventTypeEnum } from '../../EventsV2/eventTypes';

interface HostName {
  firstName: string;
  lastName: string;
}

const isAddressField = (field: string) => {
  if (!field) return false;
  switch (field) {
    case 'addressLineOne': {
      return true;
    }
    case 'city': {
      return true;
    }
    case 'state': {
      return true;
    }
    case 'zip': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const focusErrors = ({
  errors,
  isSubmitting,
  manualAddress,
}: {
  errors: FormikErrors<DetailsFormTypes> | FormikErrors<CharityFormTypes>;
  isSubmitting: boolean;
  manualAddress?: boolean;
}) => {
  if (!isSubmitting) return;
  const firstErrorKey = Object.keys(errors);
  if (firstErrorKey.length) {
    let field = firstErrorKey[0];
    if (!manualAddress && isAddressField(field)) {
      field = 'displayAddress';
    }
    if (!!document) {
      const firstErrorInput = document.getElementById(field);
      if (firstErrorInput) firstErrorInput.focus();
    }
  }
};

function formatDate(
  eventDate: string | undefined,
  eventTime: string | undefined,
  eventTimeLocale: string | undefined,
  timeZone: string | undefined,
  isWineTasting: boolean,
) {
  if (isWineTasting && eventDate && eventTime && eventTimeLocale && timeZone) {
    const full = DateTime.fromFormat(
      `${eventDate} ${eventTime}${eventTimeLocale}`,
      'L/d/yyyy hh:mma',
      { zone: timeZone },
    );
    return full.toUTC();
  }
  return DateTime.now().toUTC();
}

const formattedCloseDate = (date, timeZone) => {
  if (date && date !== '') {
    const full = DateTime.fromFormat(`${date}`, 'L/d/yyyy', {
      zone: timeZone,
    });
    return full.toUTC();
  }
  return DateTime.now()
    .plus({ days: 90 })
    .toUTC();
};

function getHostInfo(values: DetailsFormTypes, ceAccountId?: string) {
  const { hostType } = values;
  if (hostType !== EventHostingTypeEnum.Self) {
    return {
      hostAccountId: values.hostAccountId || null,
      hostFullName: values.hostFullName || '',
      hostFirstName: values.hostFirstName || '',
      hostLastName: values.hostLastName || '',
      hostEmail: values.hostEmail || null,
      hostPhone: values.hostPhone || '',
      hostNonProfitName:
        hostType === EventHostingTypeEnum.NonProfit
          ? values.hostNonProfitName
          : '',
    };
  }
  return {
    hostAccountId: ceAccountId || null,
    hostFullName: null,
    hostFirstName: null,
    hostLastName: null,
    hostEmail: null,
    hostPhone: null,
    hostNonProfitName: null,
  };
}

export function getDisplayPreferences({
  event,
  values,
}: {
  event: any;
  values: DetailsFormTypes & CharityFormTypes;
}) {
  // Default values to match DB at launch
  const preferences = {
    donationGoal: event?.displayPreferences
      ? event.displayPreferences.donationGoal
      : false,
    supporters: event?.displayPreferences
      ? event.displayPreferences.supporters
      : true,
    eventLocation: event?.displayPreferences
      ? event.displayPreferences.eventLocation
      : false,
  } as {
    supporters?: boolean;
    donationGoal?: boolean;
    eventLocation?: boolean;
  };

  if (values?.showSupporters) {
    preferences.supporters = values?.showSupporters === 'true';
  }

  if (values?.eventLocation) {
    preferences.eventLocation = values?.eventLocation === 'true';
  }

  if (values?.showDonationGoal) {
    preferences.donationGoal = values?.showDonationGoal === 'true';
  }

  return preferences;
}

export function formatAddress(
  address: string | undefined,
  addressLineTwo: string | undefined,
) {
  if (!address)
    return {
      addressLineOne: null,
      addressLineTwo: null,
      city: null,
      state: null,
      zip: null,
    };
  const { city, number, state, street, zip, type } = parser.parseLocation(
    address,
  );
  const addressLineOne = `${number || ''} ${street || ''} ${type || ''}`.trim();
  return {
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
  };
}

export function getEventDetailsToSubmit({
  values,
  ceAccountId,
}: {
  values: DetailsFormTypes;
  ceAccountId?: string;
}): any {
  const {
    trinityPartyType,
    eventDate,
    eventTime,
    eventTimeLocale,
    timeZone,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    title,
    eventType,
    hostType,
    eventVirtualLink,
  } = values;
  const hostInfo = getHostInfo(values, ceAccountId);

  const isWineTasting = eventType === EventTypeEnum.WineTasting;
  const isFundraiser = eventType === EventTypeEnum.Fundraiser;
  const partyType = isFundraiser ? 'EPARTY' : trinityPartyType;
  const isVirtualEvent = partyType === 'EPARTY';

  const eventDateFormatted = formatDate(
    eventDate,
    eventTime,
    eventTimeLocale,
    timeZone,
    isWineTasting,
  );
  const closeDate = isFundraiser
    ? formattedCloseDate(eventDate, timeZone)
    : null;
  const eventAddress =
    trinityPartyType === 'EVENT' && !isFundraiser
      ? { addressLineOne, addressLineTwo, city, state, zip }
      : null;

  return {
    trinityPartyType: partyType,
    timeZone,
    eventAddress,
    hostType,
    eventType,
    eventVirtualLink: isVirtualEvent ? eventVirtualLink : null,
    eventDate: eventDateFormatted,
    closeDate,
    ...hostInfo,
    title,
  };
}

export const saveDonation = async ({ values, eventId, donationV2Add }: any) => {
  const {
    nonProfitId,
    lobMemo,
    charityDonationGoal = 0,
    nonProfitEditId,
  } = values;
  const donationGoal =
    typeof charityDonationGoal == 'string'
      ? Number(charityDonationGoal.replace(/,/g, ''))
      : charityDonationGoal;

  const input = {
    eventId,
    nonProfitId: nonProfitId || null,
    donationGoal,
    lobMemo,
    nonProfitEditId: nonProfitEditId || null,
  };
  return await donationV2Add({
    variables: { input },
  })
    .then((res: any) => {
      return res?.data?.donationV2Add?.event;
    })
    .catch((errors: any) => {
      console.log('errors', errors);
      return;
    });
};

const getImageFileNames = (
  groupName: string,
  imageId: string,
  mimeType: string,
) => {
  const config = {} as { [key: string]: string };

  ['original', 'medium'].forEach((size: string) => {
    const key = `${groupName}/${imageId}_${size}.${mimeType}`;
    config[size] = key;
  });

  return config;
};

const resizeFile = (file: Blob, width: number, height: number) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'blob',
    );
  });

const BUCKET_NAME = 'ohw-image-uploads';

export const uploadImage = async ({
  values,
  authorId,
  getPresignedUrl,
  imageAddMutation,
  groupName,
  imageType,
  setLoading,
}: any) => {
  const { imageCanvas, mimeType } = values;

  if (setLoading) setLoading(true);

  const imageId = v4();
  // to keep with the current naming convention
  const key = `${groupName}/${imageId}.${mimeType}`;

  const imageFileNames = getImageFileNames(groupName, imageId, mimeType);
  const keys = Object.values(imageFileNames);

  return await getPresignedUrl({
    variables: { input: { keys, bucketName: BUCKET_NAME } },
  }).then(async (response: any) => {
    const urls = response?.data?.imagePresignedUrlGet?.urls;
    if (window && imageCanvas) {
      await fetch(imageCanvas)
        .then(res => res.blob())
        .then(async (blob: Blob) => {
          const original = blob;
          const medium = await resizeFile(blob, 718, 480);
          Promise.all(
            // upload image(s) to S3
            urls.map((url: string) =>
              fetch(url, {
                method: 'PUT',
                body: url?.includes('medium') ? medium : original,
              }),
            ),
          )
            .then(async () => {
              return await imageAddMutation({
                variables: {
                  input: {
                    imageId,
                    authorId,
                    path: key,
                    groupName,
                    bucketName: BUCKET_NAME,
                    imageType,
                    mimeType,
                    thumbnails: {
                      medium: `${imageId}_medium.${mimeType}`,
                    },
                  },
                },
              }).then((data: any) => {
                if (setLoading) setLoading(false);
                return data?.imageAdd?.event;
              });
            })
            .catch((errors: any) => {
              console.log('errors', errors);
              return;
            });
        });
    }
  });
};
