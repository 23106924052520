import { gql } from '@apollo/client';

const ACCOUNT_CAN_USE_TRINITY_BACK_OFFICE = gql`
  query CEDash_AccountCanUseTrinityBackOfficeQuery {
    viewer {
      id
      user {
        id
        canAccessTrinityBackOffice
      }
    }
  }
`;

export default ACCOUNT_CAN_USE_TRINITY_BACK_OFFICE;
