import React, { useState, useEffect, memo, useContext } from 'react';

import FeedProps from './types/FeedProps';
import Activity from './ActionEngineCard/Activity';
import ActivityEntry from './types/ActivityEntry';
import CardsLoading from './CardsLoading';
import Swiper from './ActionEngineCard/Swiper';
import PlaybookHeader from './ActionEngineCard/PlaybookHeader';
import { cardButtonClicked, cardArchiveClicked } from './utils/helpers';
import { FeedTypes } from './utils/constants';
import { SegmentEvents } from './ActionEngineCard/constants/segmentEvents';
import { getPlaybookSegmentProperties } from './helpers/segment';
import ExternalConfigContext from './types/ExternalConfigContext';
import { ActionEngineFeedContext } from './contexts/ActionEngineFeedContext';

const Feed = ({
  externalConfig,
  openVideoDialog,
  openButtonActionDialog,
  sendToTrackEventV2,
  setHandleCardAction,
  handleCardAction,
  activityId,
  handleSetOpenCardDialog,
  setRewardBadgeAnimationAmount,
  setTriggerRewardBadgeAnimation,
}: FeedProps) => {
  const {
    refetchSaved,
    savedFeed,
    fetchedFeed,
    fetchSavedFeed,
    setSavedFeed,
    setFetchedFeed,
    cardCount,
    getFeedCount,
    cardsLoading,
    savedLimit,
    loadMoreAt,
    savedOffset,
    setSavedOffset,
    dealerId,
    userId,
    apolloClient,
  } = useContext(ActionEngineFeedContext);

  const [newTabSelected, setNewTabSelected] = useState(true);
  const [shouldStopVideo, setShouldStopVideo] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [rewardPointBounty, setRewardPointBounty] = useState(0);
  const [startAnimation, setStartAnimation] = useState(false);

  const setSavedTab = () => {
    setNewTabSelected(false);
  };

  const setNewTab = () => {
    setNewTabSelected(true);
  };

  useEffect(() => {
    if (!newTabSelected && fetchSavedFeed) {
      fetchSavedFeed();
    }
  }, [newTabSelected]);

  const loadMoreSaved = () => {
    refetchSaved &&
      refetchSaved({
        userId,
        dealerId,
        limit: savedLimit,
        offset: savedOffset + savedLimit,
      });

    setSavedOffset(savedOffset + savedLimit);
  };

  const archiveCardMutation = cardArchiveClicked(apolloClient);

  const handleUpdateFeed = (sequences?: ActivityEntry[]) => {
    const feedCopy = newTabSelected ? fetchedFeed : savedFeed;
    const setFeed = newTabSelected ? setFetchedFeed : setSavedFeed;
    feedCopy.pop();

    if (sequences && sequences.length > 0) {
      const feedWithSequences = [...feedCopy, ...sequences];
      setFeed(feedWithSequences);
    } else {
      setFeed([...feedCopy]);
    }

    if (newTabSelected) {
      getFeedCount();
    }
  };

  const onButtonClickedMutation = cardButtonClicked(apolloClient);
  const onCardButtonClicked = async (id: string) => {
    if (!id) {
      return;
    }
    // Handle special db changes on card click
    // e.g. success triggers, mark read
    const res = await onButtonClickedMutation(id);
    const sequenceCardsPublished: ActivityEntry[] =
      res?.data?.cardButtonClicked?.sequenceCardsPublished || [];

    const selectedFeed = newTabSelected ? fetchedFeed : savedFeed;
    const index = selectedFeed?.findIndex(
      (item: ActivityEntry) => item.id === id,
    );

    // hold next and current activity in memory so we can trigger segment event down the line (handleUpdateFeed mutates feeds)
    const nextActivity = newTabSelected
      ? fetchedFeed[index - 1]
      : savedFeed[index - 1];
    const currentActivity = newTabSelected
      ? fetchedFeed[index]
      : savedFeed[index];

    if (
      currentActivity?.rewardPointBounty > 0 &&
      currentActivity?.rewardPointRedemptionMode !== 'displayOnly'
    ) {
      setTriggerAnimation(true);
      setStartAnimation(true);
      setRewardPointBounty(currentActivity?.rewardPointBounty);
      setTimeout(() => {
        handleUpdateFeed(sequenceCardsPublished);
      }, 2000);
    } else {
      handleUpdateFeed(sequenceCardsPublished);
    }

    // send card viewed event to segment
    const type = newTabSelected ? FeedTypes.new : FeedTypes.saved;
    if (sendToTrackEventV2) {
      if (nextActivity) {
        const activityProps = getPlaybookSegmentProperties(nextActivity, type);
        sendToTrackEventV2({
          segmentActivityProps: activityProps,
          activity: nextActivity,
          event: SegmentEvents.cardViewed,
        });
      }
      const currentActivityProps = getPlaybookSegmentProperties(
        currentActivity,
        type,
      );
      sendToTrackEventV2({
        segmentActivityProps: currentActivityProps,
        activity: currentActivity,
        event: SegmentEvents.cardClicked,
      });
    }
  };

  useEffect(() => {
    if (handleCardAction && activityId) {
      onCardButtonClicked(activityId);
    }
  }, [handleCardAction, setHandleCardAction, activityId]);

  if (cardsLoading) {
    return <CardsLoading />;
  }

  const activities =
    fetchedFeed?.map((feedItem: ActivityEntry) => (
      <Activity
        activity={feedItem}
        key={feedItem.id}
        openVideoDialog={openVideoDialog}
        openButtonActionDialog={openButtonActionDialog}
        sendToTrackEventV2={sendToTrackEventV2}
        onCardButtonClicked={onCardButtonClicked}
        archiveCardMutation={archiveCardMutation}
        shouldStopVideo={shouldStopVideo}
      />
    )) || null;

  const savedActivities =
    savedFeed.map((feedItem: ActivityEntry) => (
      <Activity
        activity={feedItem}
        key={feedItem.id}
        openVideoDialog={openVideoDialog}
        openButtonActionDialog={openButtonActionDialog}
        sendToTrackEventV2={sendToTrackEventV2}
        onCardButtonClicked={onCardButtonClicked}
        archiveCardMutation={archiveCardMutation}
        shouldStopVideo={shouldStopVideo}
      />
    )) || null;

  // A populated feed must be passed to the Slider.
  // We trigger the segment track event for "Card Viewed" in the Slider's onInit function.
  // onInit only fires once, so if it doesn't have the feed it will fail and we never get that first Card Viewed event.
  return (
    <ExternalConfigContext.Provider value={externalConfig}>
      <PlaybookHeader
        cardCount={cardCount}
        newTabSelected={newTabSelected}
        setNewTab={setNewTab}
        setSavedTab={setSavedTab}
        handleSetOpenCardDialog={handleSetOpenCardDialog}
      />
      <Swiper
        sendToTrackEventV2={sendToTrackEventV2}
        activities={activities}
        savedActivities={savedActivities}
        apolloClient={apolloClient}
        fetchSavedFeed={fetchSavedFeed}
        newTabSelected={newTabSelected}
        setShouldStopVideo={setShouldStopVideo}
        handleUpdateFeed={handleUpdateFeed}
        loadMoreSaved={loadMoreSaved}
        setSavedOffset={setSavedOffset}
        setNewTabSelected={setNewTabSelected}
        loadMoreAt={loadMoreAt}
        triggerAnimation={triggerAnimation}
        setTriggerAnimation={setTriggerAnimation}
        rewardPointBounty={rewardPointBounty}
        setRewardBadgeAnimationAmount={setRewardBadgeAnimationAmount}
        setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
        startAnimation={startAnimation}
        setStartAnimation={setStartAnimation}
      />
    </ExternalConfigContext.Provider>
  );
};

export default memo(Feed);
