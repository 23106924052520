import * as React from 'react';
import { PageRendererProps } from 'gatsby';
import { styles as s } from '@onehope/design-system';
import styled from '@emotion/styled';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Breadcrumbs from '../../common/Breadcrumbs';
import Layout from '../../layout';
import { FourBottleSubscribers } from '../../components/ContactsV3/FourBottleSubscribersTable';

const { cssConstants } = s;

const Container = styled.div`
  padding: 24px 0 0 0;
  background-color: white;
  min-height: 100%;
  font-family: ${cssConstants.font};
  color: ${cssConstants.textColor};
  background: white;
  @media only screen and (min-width: 768px) {
    padding: 40px 0 0 0;
  }
`;

function ContactsFourBottleSubscribersPage(props: PageRendererProps) {
  return (
    <Layout>
      <Container>
        <Breadcrumbs location="fourBottleSubscribers" />
        <FourBottleSubscribers />
      </Container>
    </Layout>
  );
}

export default withLDProvider({
  clientSideID: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  user: {
    anonymous: true,
  },
})(ContactsFourBottleSubscribersPage);
