import gql from 'graphql-tag';

export default gql`
  query CEDash_ReachOutRequestQuery($reachOutRequestId: String!) {
    viewer {
      id
      user {
        userId
      }
      v2 {
        id
        reachOutResult(reachOutRequestId: $reachOutRequestId) {
          resultsUrl
          simpleContact {
            contactId
            firstName
            lastName
            phoneNumber
            emailAddress
          }
          reachOutRequest {
            requestId
            status
            ceId
            reachOutId
            viewCount
            shareMedium
            lifecycleStage
            lastModifiedDate
            response
          }
        }
      }
    }
  }
`;
