import React, { useState } from 'react';

import { Text, Grid, TEXT_DS, styled } from '@onehope/design-system-v2';

import { CEDash_ReachOutV2_viewer_v2_reachOutTemplate as ReachOutTemplateType } from '../../../queries/generatedTypes/CEDash_ReachOutV2';
import { ShareMedium } from '../../../../generatedTypes/globalTypes';

import ReachOutCarouselPagination from './ReachOutCarouselPagination';
import ReachOutCarousel from './ReachOutCarousel';

const PreviewsContainer = styled(Grid)(() => ({
  overflowX: 'hidden',
  paddingTop: '20px',
}));

const ReachOutPreviews = ({ reachOut }: { reachOut: ReachOutTemplateType }) => {
  const { shareConfigurations } = reachOut;

  const filteredReachOutShareConfig = shareConfigurations
    ?.filter(config => ![ShareMedium.INSTAGRAM_STORY].includes(config.medium))
    .map(config => {
      return {
        ...config,
      };
    });

  const [currentViewIndex, setCurrentViewIndex] = useState(0);

  return (
    <PreviewsContainer container spacing={2} direction="column">
      <Grid item>
        <ReachOutCarousel
          reachOut={reachOut}
          currentViewIndex={currentViewIndex}
          setCurrentViewIndex={setCurrentViewIndex}
        />
      </Grid>
      <Grid item>
        <ReachOutCarouselPagination
          currentViewIndex={currentViewIndex}
          setCurrentViewIndex={setCurrentViewIndex}
          numberOfSlides={filteredReachOutShareConfig?.length}
        />
      </Grid>
      <Grid item>
        <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16} align="center">
          Your message can be customized before sharing. Click each card to
          preview shared link.
        </Text>
      </Grid>
    </PreviewsContainer>
  );
};

export default ReachOutPreviews;
