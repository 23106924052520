import React, { ReactNode, useMemo } from 'react';
import {
  useTheme,
  AppBar,
  ToolBar,
  useMediaQuery,
  AppBarProps,
} from '@onehope/design-system-v2';
import { useQuery } from '@apollo/client';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent/header';

// assets
import { useAppGlobalContextValue } from '../../../contexts/AppGlobalContext/context';
import GET_USER_AUTHORIZED_CE_QUERY_QUERY from '../../../queries/UserAuthorizedCe';
import { CEDash_UserAuthorizedCeQuery } from '../../../queries/generatedTypes/CEDash_UserAuthorizedCeQuery';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  handleDrawerToggle?: () => void;
  rewardBadgeAnimationAmount?: number;
  triggerRewardBadgeAnimation?: boolean;
  setTriggerRewardBadgeAnimation?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const Header = ({
  handleDrawerToggle,
  rewardBadgeAnimationAmount,
  triggerRewardBadgeAnimation,
  setTriggerRewardBadgeAnimation,
}: Props) => {
  const theme = useTheme();
  const [{ drawerOpen }] = useAppGlobalContextValue();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const { data } = useQuery<CEDash_UserAuthorizedCeQuery>(
    GET_USER_AUTHORIZED_CE_QUERY_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const pointsBalance = data?.viewer?.user?.pointBalance || 0;
  const firstName = data?.viewer?.user?.firstName;
  // header content
  const headerContent = useMemo(
    () => (
      <HeaderContent
        firstName={firstName}
        pointBalance={pointsBalance}
        toggle={handleDrawerToggle}
        rewardBadgeAnimationAmount={rewardBadgeAnimationAmount}
        triggerRewardBadgeAnimation={triggerRewardBadgeAnimation}
        setTriggerRewardBadgeAnimation={setTriggerRewardBadgeAnimation}
      />
    ),
    [drawerOpen],
  );

  // common header
  const mainHeader: ReactNode = <ToolBar>{headerContent}</ToolBar>;

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `0.5px solid ${theme.palette.greyMedium.main}`,
      zIndex: 1200,
      padding: {
        xs: '0 8px',
        sm: 0,
      },
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={drawerOpen} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
