import * as React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import ArchiveDialog from '../Dialog/ArchiveWarningDialog';
import BulkArchiveContactsV2Mutation from '../../mutations/ContactsV2/ContactsV2BulkArchiveMutation';
import { useTrackingContextValue } from '../../contexts/TrackingContext';
import { segmentEvents } from '../../utils/segment/constants';

interface ArchiveContactsModalProps {
  contacts: string[];
  isArchiveDialogOpen: boolean;
  setIsArchiveDialogClose: (state: boolean) => void;
}

const ArchiveModalContainer = styled.div`
  width: 100px;
`;

const goToContacts = (e?: React.MouseEvent) => {
  if (e && typeof e.preventDefault === 'function') {
    e.preventDefault();
  }
  window.location.href = '/contacts';
  return null;
};

const ArchiveContactModal = ({
  contacts,
  isArchiveDialogOpen,
  setIsArchiveDialogClose,
}: ArchiveContactsModalProps) => {
  const [bulkArchiveContactsV2, { loading }] = useMutation(
    BulkArchiveContactsV2Mutation,
  );
  const { trackEventV2 } = useTrackingContextValue();

  const DIALOG_TEXT = `Are you sure you want to delete ${
    contacts.length === 1 ? 'this contact' : 'these contacts'
  }?`;

  const DIALOG_CONTENT = `You are about to delete ${
    contacts.length
  } of your contacts. This action will unlink you as the ${
    contacts.length === 1 ? "contact's" : 'their'
  }  Wine Rep. This cannot be undone. Please confirm below.`;

  const saveChange = React.useCallback(() => {
    bulkArchiveContactsV2({ variables: { contacts } }).then(() => {
      trackEventV2({
        event: segmentEvents.contactArchived,
        properties: {
          contactId: contacts.join(','),
        },
      });
      setIsArchiveDialogClose(false);
      goToContacts();
    });
  }, [bulkArchiveContactsV2, contacts, trackEventV2, setIsArchiveDialogClose]);

  return (
    <ArchiveModalContainer>
      <ArchiveDialog
        dialogText={DIALOG_TEXT}
        dialogContent={DIALOG_CONTENT}
        open={isArchiveDialogOpen}
        setClose={setIsArchiveDialogClose}
        saveChange={saveChange}
        mutationLoading={loading}
      />
    </ArchiveModalContainer>
  );
};

export default ArchiveContactModal;
