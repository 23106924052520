import React, { ReactElement, useEffect, useRef } from 'react';
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimation,
} from 'framer-motion';

import useStyles from './helpers/styles';

const translateXForElement = (element?: any) => {
  const transform = element.style.transform;

  if (!transform || transform.indexOf('translateX(') < 0) {
    return 0;
  }

  const extractTranslateX = transform.match(/translateX\((-?\d+)/);

  return extractTranslateX && extractTranslateX.length === 2
    ? parseInt(extractTranslateX[1], 10)
    : 0;
};

const SwipeCard = ({
  cardActivity,
  cardIndex,
  handleSwiped,
  triggerButtonAnimation,
  currentActivityId,
  setSwipeButtonClickedDirection,
}: {
  cardActivity: ReactElement;
  cardIndex: number;
  handleSwiped: (direction: string, index: number) => void;
  triggerButtonAnimation: string | null;
  setSwipeButtonClickedDirection: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  currentActivityId: string | null;
}) => {
  const classes = useStyles();

  // create a card ref for triggering onClick animations
  const dragRef = useRef<HTMLDivElement>(null);
  const activityId = cardActivity?.props?.activity?.id;

  // To move the card as the user drags the cursor set a motion value
  const x = useMotionValue(0);

  // To rotate the card as the card moves on drag
  const rotate = useTransform(x, [-200, 200], [-50, 50]);

  // To decrease opacity of the card when swiped
  // opacity gradually changes to 0
  // and when the card is in center opacity = 1
  const opacity = useTransform(x, [-300, -250, 0, 250, 300], [0, 1, 1, 1, 0]);

  // Framer animation hook
  const animation = useAnimation();

  // for onClick triggered animations
  const variants = {
    swipe: { rotate: [0, -30, 0], transition: { duration: 0.5 } },
  };

  const onLeftClick = () => {
    if (currentActivityId === dragRef?.current?.title) {
      const xPos = translateXForElement(dragRef?.current);
      const newXPosition = xPos - 300;

      animation.start({
        x: newXPosition > 0 ? 0 : newXPosition,
      });
    }
  };

  const onRightClick = () => {
    if (currentActivityId === dragRef?.current?.title) {
      const xPos = translateXForElement(dragRef?.current);
      const newXPosition = xPos + 300;

      animation.start({
        x: newXPosition,
      });
      setSwipeButtonClickedDirection(null);
    }
  };

  useEffect(() => {
    if (triggerButtonAnimation && currentActivityId) {
      const handler =
        triggerButtonAnimation === 'left' ? onLeftClick : onRightClick;
      handler();
    }
  }, [triggerButtonAnimation, currentActivityId]);

  return (
    <motion.div
      // Card can be drag only on x-axis
      drag="x"
      // variants, animate, initial and transition are animations for onClick events
      variants={variants}
      animate={animation}
      initial="swipe"
      transition={{
        type: 'spring',
        damping: 8,
        mass: 0.6,
        stiffness: 70,
      }}
      title={activityId}
      // drag properties bounce cards back in initial position if they have not been swiped far enough
      dragConstraints={{
        right: 0,
        left: 0,
      }}
      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
      dragElastic={0.5}
      key={cardIndex}
      ref={dragRef}
      className={classes.swipe}
      // rotate and opacity are for drag animation
      style={{
        x: x,
        rotate,
        // @ts-ignore opacity is not defined in MotionStyle types but necessary for animation
        opacity,
        backgroundColor: 'white',
        borderRadius: '12px',
      }}
      onDragEnd={(_, info) => {
        const offset = info.offset.x;
        const velocity = info.velocity.x;
        const swipedUpOrDown = info.point.x === 0 && info.point.y === 0;
        const isLeft = velocity < 0;

        const swipedFarEnough = Math.abs(offset) > 200;
        const swipedFastEnough =
          Math.abs(velocity) > 200 && Math.abs(offset) > 55;
        // if conditions are met swipe the card, run mutations, update feed, etc.
        if (!swipedUpOrDown && (swipedFarEnough || swipedFastEnough)) {
          animation.start({ x: isLeft ? -500 : 500 });
          const direction = isLeft ? 'left' : 'right';
          handleSwiped(direction, cardIndex);
        }
      }}
    >
      {cardActivity}
    </motion.div>
  );
};

export default SwipeCard;
