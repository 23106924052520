import React, { Dispatch, SetStateAction } from 'react';

import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import {
  Grid,
  Icons,
  useTheme,
  withStyles,
  useMediaQuery,
  IconButton,
  theme,
} from '@onehope/design-system-v2';

import SearchBar from '../../Events/EventsTable/SearchBar';
import CheckboxToggle from './Toolbar/CheckboxToggle';
import TablePagination from '../../TableComponents/Pagination';
import CardListViewContainer from '../CardListViewContainer';
import { ContactType, useContactsContext } from '../ContactsContext';
import TableBody from './TableBody';
import FilterBar from './FilterBar';

const { cssConstants } = s;
const {
  AppsIcon: MuiAppsIcon,
  FormatListBulletedIcon: MuiFormatListBulletedIcon,
} = Icons;

const Container = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.bodyFontFamily};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding-bottom: 40px;
  }
`;

const GridContainer = styled(Grid)`
  padding: 16px 0 0 0;
  @media all and (min-width: 960px) {
    padding: 0;
  }
`;

const ToggleView = styled.div`
  width: 54px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

export const GlobalCss = withStyles({
  '@global': {
    '.MuiCheckbox-root': {
      marginRight: '5px',
      padding: '2px',
    },
    '.MuiCheckbox-colorSecondary': { color: '#c2c2c2' },
    '.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
})(() => null);

interface ContactsTableProps {
  contacts: ContactType[];
  page: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  handleSortContacts: (newSortBy: string) => void;
  count: number;
  setSearchString: Dispatch<SetStateAction<string>>;
  filter: string;
  handleFilterContacts: (newFilter: string) => void;
}

const ContactsTable = ({
  contacts,
  page,
  setPageNumber,
  rowsPerPage,
  setRowsPerPage,
  handleSortContacts,
  count,
  setSearchString,
  filter,
  handleFilterContacts,
}: ContactsTableProps) => {
  const { isTableView, toggleTableView } = useContactsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchChange = (search: string) => {
    // reset the page number when text search is performed
    setPageNumber(1);
    // fire off the search string
    setSearchString(search);
  };

  const handleRowsPerPageChange = (page: number) => {
    // reset the page number when rows per page change happens
    setPageNumber(1);
    // fire off the state change
    setRowsPerPage(page);
  };

  const handleFilterChange = (newFilter: string) => {
    // reset the page number when filter change happens
    setPageNumber(1);
    // fire off the filter
    handleFilterContacts(newFilter);
  };

  return (
    <>
      <GlobalCss />
      <Container>
        <Toolbar>
          <SearchBar
            handleSearch={handleSearchChange}
            placeHolderText="Search contacts"
          />
          <GridContainer container alignItems="center" spacing={1}>
            <Grid item xs>
              <FilterBar
                filter={filter}
                handleFilterChange={handleFilterChange}
              />
            </Grid>
          </GridContainer>
          {!isMobile && <CheckboxToggle />}
          {!isMobile && (
            <ToggleView>
              <IconButton onClick={toggleTableView}>
                {isTableView ? <AppsIcon /> : <FormatListBulletedIcon />}
              </IconButton>
            </ToggleView>
          )}
        </Toolbar>
        {isTableView && !isMobile ? (
          <TableBody
            handleSortContacts={handleSortContacts}
            contacts={contacts}
          />
        ) : (
          <CardListViewContainer contacts={contacts} />
        )}
        <TablePagination
          page={page}
          count={count}
          rowsOnPage={(contacts && contacts.length) || 0}
          setPageNumber={setPageNumber}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={handleRowsPerPageChange}
          baseRoute={'contacts'}
        />
      </Container>
    </>
  );
};

export default ContactsTable;
