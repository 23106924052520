import React from 'react';

const SvgPlus = (props: any) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6V10H6V6H10V4H6V0H4V4H0V6H4Z"
      fill="#88898A"
    />
  </svg>
);

export default SvgPlus;
