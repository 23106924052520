import React, { useState, Dispatch, SetStateAction } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import {
  Container,
  Grid,
  Text,
  TEXT_DS,
  Input,
  Button,
  Spacer,
  AutoCompleteV2 as AutoComplete,
} from '@onehope/design-system-v2';

import { Account_AddressesGetQuery_viewer_user_shippingAddressDefault as AddressType } from '../../queries/generatedTypes/Account_AddressesGetQuery';

import ADDRESS_AUTOCOMPLETE from '../../mutations/AddressAutoCompleteMutation';
import ADDRESS_ZIP_AUTOCOMPLETE from '../../mutations/AddressFormGooglePlacesZipMutation';
import ADDRESS_EDIT from '../../mutations/AddressEditMutation';
import ADDRESS_ADD from '../../mutations/AddressAddMutation';
import ADDRESS_SET_DEFAULT from '../../mutations/AddressSetDefaultMutation';

import useToggle from '../../utils/useToggleHook';
import { allowedStates } from '../../utils/statesWeCanShipTo';
import validationSchema from './validationSchema';
import {
  useFormStyles,
  CloseIcon,
  ContainerGrid,
  Checkbox,
  ButtonsContainer,
} from '../_shared/formStyles';

interface AddressFormProps {
  open: boolean;
  onClose: () => void;
  address?: AddressType;
  headerText: string;
  isDefault?: boolean;
  isEditing?: boolean;
  hasDefaultShippingAddressId?: boolean;
  setSelectedAddress?: Dispatch<SetStateAction<AddressType | null>>;
  contactId?: string;
}

function getEditInputs(
  formFields: any,
  addressOriginal: AddressType,
  updateSubscriptions: boolean,
  contactId?: string,
) {
  const { address, ...rest } = formFields;
  return {
    address: {
      addressId: addressOriginal.addressId,
      ...rest,
    },
    updateSubscriptions,
    contactId,
  };
}

function getAddInputs(
  formFields: any,
  updateSubscriptions: boolean,
  contactId?: string,
) {
  const { address, ...rest } = formFields;
  return {
    address: {
      ...rest,
    },
    updateSubscriptions,
    contactId,
  };
}

const AddressForm = (props: AddressFormProps) => {
  const {
    onClose,
    address,
    headerText,
    isDefault,
    isEditing,
    hasDefaultShippingAddressId,
    setSelectedAddress,
    contactId,
  } = props;
  const classes = useFormStyles();
  const [editAddressMutation, { loading: editingAddress }] =
    useMutation(ADDRESS_EDIT);
  const [addAddressMutation, { loading: addingAddress }] =
    useMutation(ADDRESS_ADD);
  const [addressSetDefaultMutation, { loading: settingDefaultAddress }] =
    useMutation(ADDRESS_SET_DEFAULT);
  const [addressAutoComplete] = useMutation(ADDRESS_AUTOCOMPLETE);
  const [addressZipMutation] = useMutation(ADDRESS_ZIP_AUTOCOMPLETE);
  const [addressPredictions, setAddressPredictions] = useState([]);
  const loading = editingAddress || addingAddress || settingDefaultAddress;
  const {
    value: menuOpen,
    setTrue: setMenuOpen,
    setFalse: setMenuClosed,
  } = useToggle(false);

  const {
    value: showManualAddress,
    setTrue: setActivateManualAddress,
    setFalse: setDeactivateManualAddress,
  } = useToggle(false);

  const {
    value: makeDefaultAddress,
    setTrue: setMakeDefaultAddress,
    setFalse: setRemoveDefaultAddress,
  } = useToggle(false);
  const {
    value: updateSubscriptions,
    // setTrue: setUpdateSubscriptionsAddress,
    setFalse: setRemoveUpdateSubscriptionsAddress,
  } = useToggle(false);

  const toggleDefaultAddress = () => {
    if (makeDefaultAddress) {
      setRemoveDefaultAddress();
    } else {
      setMakeDefaultAddress();
    }
  };

  // const toggleUpdateSubscriptions = () => {
  //   if (updateSubscriptions) {
  //     setRemoveUpdateSubscriptionsAddress();
  //   } else {
  //     setUpdateSubscriptionsAddress();
  //   }
  // };

  const handleInputChange = async (search: string) => {
    const variables = {
      input: {
        query: search,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setMenuOpen();
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions?.filter((address: AddressType) => {
          return (
            allowedStates.includes(address.state || '') &&
            /\d/.test(address.addressLineOne || '')
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: AddressType) => {
        return !!address;
      });
      setAddressPredictions(truthyList);
    });
  };

  const handleAddressSelected = () => {
    setMenuClosed();
  };

  const handleOnClose = () => {
    if (!loading) {
      setDeactivateManualAddress();
      setRemoveUpdateSubscriptionsAddress();
      setRemoveDefaultAddress();
      setMenuClosed();
      onClose();
    }
  };

  return (
    <Container className={classes.main}>
      <Grid container>
        <CloseIcon onClick={handleOnClose} />
        <ContainerGrid container direction="column" alignItems="flex-start">
          <Text
            variant="custom"
            default={TEXT_DS.TITLE_LIGHT_24}
            tablet={TEXT_DS.TITLE_LIGHT_32}
            laptop={TEXT_DS.TITLE_LIGHT_32}
            desktop={TEXT_DS.TITLE_LIGHT_32}
          >
            {headerText}
          </Text>
          <Formik
            initialValues={{
              nickname: address?.nickname || '',
              firstName: address?.firstName || '',
              lastName: address?.lastName || '',
              address: address?.addressLineOne
                ? `${address?.addressLineOne}, ${address?.city}, ${address?.state}`
                : '',
              addressLineOne: address?.addressLineOne || '',
              city: address?.city || '',
              state: address?.state || '',
              addressLineTwo: address?.addressLineTwo || '',
              specialInstructions: address?.specialInstructions || '',
              companyName: address?.companyName || '',
              zip: address?.zip || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              if (isEditing && address) {
                const variables = {
                  input: getEditInputs(
                    values,
                    address,
                    updateSubscriptions,
                    contactId,
                  ),
                };
                editAddressMutation({ variables })
                  .then((res: any) => {
                    const addressId =
                      res?.data?.addressBookEdit?.address?.addressId;
                    if (setSelectedAddress) {
                      setSelectedAddress(res?.data?.addressBookEdit?.address);
                    }
                    if (!isDefault && makeDefaultAddress) {
                      addressSetDefaultMutation({
                        variables: {
                          input: {
                            shippingAddressIdDefault: addressId,
                            updateAllSubscriptions: true,
                            contactId,
                          },
                        },
                      }).then(() => {
                        setSubmitting(false);
                        handleOnClose();
                      });
                    } else {
                      setSubmitting(false);
                      handleOnClose();
                    }
                  })
                  .catch((error) => {
                    setFieldError('nickname', error.message);
                    setSubmitting(false);
                  });
              } else {
                const variables = {
                  input: getAddInputs(values, updateSubscriptions, contactId),
                };
                addAddressMutation({ variables })
                  .then((res: any) => {
                    const addressId =
                      res?.data?.addressBookAdd?.address?.addressId;
                    if (setSelectedAddress) {
                      setSelectedAddress(res?.data?.addressBookAdd?.address);
                    }
                    if (
                      !isDefault &&
                      (makeDefaultAddress || !hasDefaultShippingAddressId)
                    ) {
                      addressSetDefaultMutation({
                        variables: {
                          input: {
                            shippingAddressIdDefault: addressId,
                            updateAllSubscriptions: true,
                            contactId,
                          },
                        },
                      }).then(() => {
                        setSubmitting(false);
                        handleOnClose();
                      });
                    } else {
                      setSubmitting(false);
                      handleOnClose();
                    }
                  })
                  .catch((error) => {
                    setFieldError('nickname', error.message);
                    setSubmitting(false);
                  });
              }
            }}
          >
            {({
              values,
              submitForm,
              isSubmitting,
              errors,
              touched,
              handleChange,
              setFieldValue,
            }) => (
              <Form className={classes.form}>
                <div className={classes.formItem}>
                  <Input
                    fullWidth
                    label="Address Name"
                    name="nickname"
                    type="nickname"
                    placeholder="My Home"
                    value={values.nickname}
                    onChange={handleChange}
                    error={touched.nickname && Boolean(errors.nickname)}
                    hasError={touched.nickname && Boolean(errors.nickname)}
                    validate
                    helperText={touched.nickname && errors.nickname}
                  />
                </div>
                <div className={classes.formItem}>
                  <Input
                    label="First Name"
                    fullWidth
                    name="firstName"
                    type="firstName"
                    placeholder="Jane"
                    onChange={handleChange}
                    value={values.firstName}
                    error={touched.firstName && Boolean(errors.firstName)}
                    hasError={touched.firstName && Boolean(errors.firstName)}
                    validate
                    helperText={touched.firstName && errors.firstName}
                  />
                </div>
                <div className={classes.formItem}>
                  <Input
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    type="lastName"
                    placeholder="Austen"
                    onChange={handleChange}
                    value={values.lastName}
                    error={touched.lastName && Boolean(errors.lastName)}
                    hasError={touched.lastName && Boolean(errors.lastName)}
                    validate
                    helperText={touched.lastName && errors.lastName}
                  />
                </div>
                <div className={classes.formItem}>
                  <Input
                    fullWidth
                    label="Company Name"
                    name="companyName"
                    type="companyName"
                    placeholder="ONEHOPE"
                    value={values.companyName}
                    onChange={handleChange}
                    error={touched.companyName && Boolean(errors.companyName)}
                    hasError={
                      touched.companyName && Boolean(errors.companyName)
                    }
                    validate
                    helperText={touched.nickname && errors.nickname}
                  />
                </div>
                {!showManualAddress && (
                  <div className={classes.formItem}>
                    <AutoComplete
                      label="Address"
                      id="displayAddressDropDown"
                      inputId="address"
                      isWithinDialog={true}
                      placeholder="Enter address"
                      value={values.address}
                      touched={touched.address}
                      isSubmitting={isSubmitting}
                      isMenuOpen={menuOpen}
                      setFieldValue={setFieldValue}
                      toggleAddManualAddress={setActivateManualAddress}
                      handleAddressSelected={handleAddressSelected}
                      handleChange={handleChange}
                      getAddressZipMutation={addressZipMutation}
                      handleInputChange={handleInputChange}
                      handleOuterClick={setMenuClosed}
                      objectPredictions={addressPredictions}
                      showLocationIcon
                    />
                  </div>
                )}
                {showManualAddress && (
                  <div>
                    <div className={classes.formItem}>
                      <Input
                        fullWidth
                        label="Address"
                        name="addressLineOne"
                        type="addressLineOne"
                        placeholder="Enter address"
                        value={values.addressLineOne}
                        onChange={handleChange}
                        error={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        hasError={
                          touched.addressLineOne &&
                          Boolean(errors.addressLineOne)
                        }
                        validate
                        helperText={
                          touched.addressLineOne && errors.addressLineOne
                        }
                      />
                    </div>
                  </div>
                )}
                <div className={classes.formItem}>
                  <Input
                    fullWidth
                    label="Address 2"
                    placeholder="Apt, Ste, Bldg number"
                    id="addressLineTwo"
                    name="addressLineTwo"
                    value={values.addressLineTwo}
                    onChange={handleChange}
                    error={
                      touched.addressLineTwo && Boolean(errors.addressLineTwo)
                    }
                    helperText={touched.addressLineTwo && errors.addressLineTwo}
                    hasError={
                      touched.addressLineTwo && Boolean(errors.addressLineTwo)
                    }
                    validate
                  />
                </div>
                {showManualAddress && (
                  <div>
                    <div className={classes.formItem}>
                      <Input
                        fullWidth
                        label="City"
                        name="city"
                        type="city"
                        placeholder="Enter city"
                        value={values.city}
                        onChange={handleChange}
                        error={touched.city && Boolean(errors.city)}
                        hasError={touched.city && Boolean(errors.city)}
                        validate
                        helperText={touched.city && errors.city}
                      />
                    </div>
                    <div className={classes.formItem}>
                      <Input
                        fullWidth
                        label="State"
                        name="state"
                        type="state"
                        placeholder="CA"
                        value={values.state}
                        onChange={handleChange}
                        error={touched.state && Boolean(errors.state)}
                        hasError={touched.state && Boolean(errors.state)}
                        validate
                        helperText={touched.state && errors.state}
                      />
                    </div>
                    <div className={classes.formItem}>
                      <Input
                        fullWidth
                        label="Zip code"
                        placeholder="90210"
                        id="zip"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        error={touched.zip && Boolean(errors.zip)}
                        helperText={touched.zip && errors.zip}
                        hasError={touched.zip && Boolean(errors.zip)}
                        validate
                      />
                    </div>
                  </div>
                )}
                <div className={classes.formItem}>
                  <Input
                    fullWidth
                    label="Delivery Instructions"
                    placeholder="Enter text here"
                    id="specialInstructions"
                    name="specialInstructions"
                    value={values.specialInstructions}
                    onChange={handleChange}
                    error={
                      touched.specialInstructions &&
                      Boolean(errors.specialInstructions)
                    }
                    helperText={
                      touched.specialInstructions && errors.specialInstructions
                    }
                    hasError={
                      touched.specialInstructions &&
                      Boolean(errors.specialInstructions)
                    }
                    validate
                  />
                </div>
                {!isDefault && (
                  <Grid container alignItems="center">
                    <Checkbox
                      checked={makeDefaultAddress}
                      onClick={toggleDefaultAddress}
                    />
                    Make this my default shipping address
                    <Spacer xs="11px" sm="11px" lg="11px"></Spacer>
                    <Text
                      variant="custom"
                      default={TEXT_DS.BODY_LIGHT_ITALIC_14}
                    >
                      Setting this address as your default address will update
                      all of your current subscriptions to use this address
                    </Text>
                  </Grid>
                )}
                <Spacer xs="24px" sm="32px" lg="32px" />
                <ButtonsContainer container>
                  <Button
                    type="primary"
                    disabled={isSubmitting || loading}
                    fullWidth
                    loading={isSubmitting || loading}
                    onClick={submitForm}
                  >
                    Save
                  </Button>
                  <Button
                    className={classes.secondaryButton}
                    type="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    onClick={handleOnClose}
                  >
                    Cancel
                  </Button>
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </ContainerGrid>
      </Grid>
    </Container>
  );
};

export default AddressForm;
