import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Text,
  TEXT_DS,
  LoaderDark,
  ThemeType,
} from '@onehope/design-system-v2';

import USER_LIVE_CHAT from '../../queries/UserLiveChat';
import { useQuery } from '@apollo/client';

export const useStyles = makeStyles((theme: ThemeType) => ({
  '@global': {
    iframe: {
      position: 'fixed', // we need to hide the header and sidebar
      top: '0',
      left: '0',
      width: '100%',
      minHeight: '100%',
      border: 'none',
    },
    '#hubspot-messages-iframe-container': {
      width: '100% !important',
      height: '100vh !important',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  header: {
    padding: '25px 16px 0px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 95px 0px 95px',
    },
  },
  subtitle: {
    padding: '10px 16px 25px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '10px 95px 25px 95px',
    },
  },
  form: {
    padding: '0px 16px 0px 16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '0px 95px 0px 95px',
    },
  },
}));

function LiveChat() {
  // Query for retrieving email for chat
  const { data, loading: queryLoading } = useQuery(USER_LIVE_CHAT);
  const user = data?.viewer?.user;
  const identificationEmail = user?.email || '';
  const identificationToken = user?.hubspotIdentificationToken || '';

  // State for loading or displaying widget message
  const [loading, setLoading] = useState(queryLoading || false);
  const [isChatAvailable, setIsChatAvailable] = useState(true);

  // Load styles for iframe and settings for the chat
  const classes = useStyles();

  // Load the HubSpot Live Chat settings and embed the widget
  useEffect(() => {
    let hbsptCheck = 0;

    if (typeof window === 'undefined') {
      setIsChatAvailable(false);
      return;
    }

    const loadHubSpotForm = () => {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window && window.hbspt) {
          const hubSpotObj = {
            portalId: '4349308',
            formId: '55f99152-49dc-4191-9df7-9fc0832c3520',
            target: `#hubspotForm`,
          };
          window.hbspt.forms.create(hubSpotObj);
        }
      });
    };

    const loadHubSpotChat = () => {
      window.HubSpotConversations.widget.load();
      setLoading(true);
      // Set a check to avoid an infinite loop
      if (hbsptCheck >= 5) {
        setIsChatAvailable(false);
        loadHubSpotForm();
        setLoading(false);
        return;
      }
      // Check the status of the widget
      const status = window.HubSpotConversations.widget.status();
      const { loaded, pending } = status;
      if (pending) {
        // If the widget is still loading, recheck its status in 2s
        setTimeout(() => {
          hbsptCheck += 1;
          loadHubSpotChat();
        }, 2000);
      } else {
        if (loaded) {
          setIsChatAvailable(true);
          setLoading(false);
        } else {
          setIsChatAvailable(false);
          loadHubSpotForm();
          setLoading(false);
        }
      }
    };

    // the email and the token will allow us to identify the user in the chat
    if (!queryLoading) {
      if (window.HubSpotConversations) {
        window.hsConversationsSettings = {
          inlineEmbedSelector: '#hubSpotChat',
          identificationEmail,
          identificationToken,
        };
        loadHubSpotChat();
      } else {
        // If HubSpot Conversations SDK is not ready, add a callback
        window.hsConversationsOnReady = [
          () => {
            window.hsConversationsSettings = {
              inlineEmbedSelector: '#hubSpotChat',
              identificationEmail,
              identificationToken,
            };
            loadHubSpotChat();
          },
        ];
      }
    }
  }, [queryLoading, identificationEmail, identificationToken]);

  return (
    <div id="hubSpotChat">
      {loading && (
        <div className={classes.loader}>
          <LoaderDark />
        </div>
      )}
      {!loading && !isChatAvailable && (
        <div className={classes.wrapper}>
          <Text variant="h2" className={classes.header}>
            Contact ONEHOPE Wine Support
          </Text>
          <Text
            variant="custom"
            default={TEXT_DS.BODY_LIGHT_14}
            className={classes.subtitle}
          >
            Sorry, there are no agents available to chat at this time. Please
            fill out this form and we will be in touch as soon as possible.
          </Text>
          <div className={classes.form} id="hubspotForm" />
        </div>
      )}
    </div>
  );
}
export default LiveChat;
