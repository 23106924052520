/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import get from 'lodash/get';
import styled from '@emotion/styled';

import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  theme,
} from '@onehope/design-system-v2';
import { getTrackingLinks } from '@onehope/utils';
import { onHandleTrackOrder } from '../../../utils/utils';
import Columns from '../../../common/Orders/Columns';
import { CEDash_GuestOrdersQuery_viewer_user_orders_edges as OrdersEdges } from '../../../queries/generatedTypes/CEDash_GuestOrdersQuery';
import TableHeader from '../../TableComponents/TableListView/TableHeader';

const TableWrapper = styled.div`
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
  overflow: auto;
  background-color: white;
  margin-bottom: 24px;
`;

const TableRow = styled(MuiTableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  && {
    padding: 8px 16px;
    font-family: ${theme.bodyFontFamily};
    color: ${theme.palette.primary.main};
    border-color: ${theme.palette.veryLightGrey.main};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    max-width: 148px;
  }
`;

interface TableBodyContainerProps {
  orders: (OrdersEdges | null)[] | null;
  handleSortOrders: (sortBy: string) => void;
}

const handleRowClick = (order: OrdersEdges | null) => {
  return () => {
    const GATSBY_HOPECOMMERCE_URL = process.env.GATSBY_HOPECOMMERCE_URL;
    return window.open(
      `${GATSBY_HOPECOMMERCE_URL}/order/${order?.node?.orderId}`,
      '_blank',
    );
  };
};

const noSortByList = {
  eventDetails: true,
  'event.hostFullName': true,
  trackOrder: true,
  menu: true,
};

export default function TableBodyContainer(props: TableBodyContainerProps) {
  const { orders, handleSortOrders } = props;
  if (orders) {
    return (
      <TableWrapper>
        <Table stickyHeader aria-label="orders table">
          <TableHeader
            handleSort={handleSortOrders}
            columns={Columns}
            noSortByList={noSortByList}
          />
          <TableBody>
            {orders.map((order: OrdersEdges | null) => {
              const onRowClick = handleRowClick(order);
              const shipments = order?.node?.shipments;
              const trackingLinks = getTrackingLinks(shipments);
              const handleTrackOrder = onHandleTrackOrder(
                trackingLinks,
                order?.node?.orderId,
              );
              const isAutoShipOrder =
                order?.node?.isAutoShipOrder || order?.node?.isBYOInitialOrder;
              const isHostKitOrder = order?.node?.isHostKitOrder;
              let orderType: string = '';
              switch (true) {
                case isAutoShipOrder:
                  orderType = 'wineClub';
                  break;
                case isHostKitOrder:
                  orderType = 'hostKit';
                  break;
              }

              return (
                <TableRow hover role="checkbox" key={order?.node?.id}>
                  {Columns.map(column => {
                    const value = get(order?.node, `${column.id}`);
                    const onCellClick = !column.render ? onRowClick : () => {};
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        onClick={onCellClick}
                      >
                        {column.format &&
                          column.format(value, order?.node?.orderId)}
                        {column.orderType && column.orderType({ orderType })}
                        {column.render &&
                          column.render({
                            orderId: order?.node?.orderId,
                            ceId: order?.node?.ceoAccountId,
                            customerId: order?.node?.user?.userId,
                            trackingLinks,
                            handleTrackOrder,
                          })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    );
  }
  return null;
}
