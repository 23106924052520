import React from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { ApolloProvider, useQuery } from '@apollo/client';
import { Loader } from '@onehope/design-system-v2';

import { client } from '../../../apollo/client';
import ContactResults from '../../../components/ReachOuts/Results/ContactResults';

import GET_CE_DASHBOARD_INFO_QUERY from '../../../queries/Dashboard';
import { CEDash_DashboardQuery } from '../../../queries/generatedTypes/CEDash_DashboardQuery';

import TrackingProvider from '../../../contexts/TrackingContext';

function ContactResultsContainer(props: { queryStrings: ParsedQuery<string> }) {
  const { data, loading } = useQuery<CEDash_DashboardQuery>(
    GET_CE_DASHBOARD_INFO_QUERY,
  );
  const { reachOutRequestId } = props.queryStrings;

  return (
    <ApolloProvider client={client}>
      {loading ? (
        <Loader />
      ) : (
        <TrackingProvider viewer={data?.viewer}>
          <ContactResults reachOutRequestId={reachOutRequestId.toString()} />
        </TrackingProvider>
      )}
    </ApolloProvider>
  );
}

function ContactResultsPage() {
  let query = {} as ParsedQuery<string>;
  if (typeof window !== 'undefined') {
    query = queryString.parse(window.location.search);
  }

  return (
    <ApolloProvider client={client}>
      <ContactResultsContainer queryStrings={query} />
    </ApolloProvider>
  );
}

export default ContactResultsPage;
