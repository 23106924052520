import React, { useState } from 'react';
import queryString from 'query-string';
import { useQuery } from '@apollo/react-hooks';

import { EventStatusDictionary } from '../../utils/enums';
import EventsWrapper from './EventsWrapper';
import GET_EVENTS_V2_QUERY from '../../queries/EventsPaginated';

function Events() {
  let { page } = queryString.parse(window.location.search);
  if (!page) page = '1';
  const [pageNumber, setPageNumber] = useState(page);
  const [filterBy, setFilterBy] = useState(EventStatusDictionary.OPEN);
  const [sort, setSort] = useState({ sortBy: 'eventDate', desc: true });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState(null);
  const { data, error } = useQuery(GET_EVENTS_V2_QUERY, {
    variables: {
      page: Number(pageNumber),
      filterBy: filterBy,
      sortBy: sort.sortBy,
      desc: sort.desc,
      rowsPerPage,
      searchBy,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSortEvents = (sortBy: string) => {
    setSort({
      sortBy,
      desc: !sort.desc,
    });
  };

  if (error) return null;
  if (data?.viewer?.user?.eventsV2) {
    const { eventsV2, eventsV2Count } = data.viewer.user;
    return (
      <EventsWrapper
        events={eventsV2}
        pageNumber={Number(pageNumber)}
        sortBy={sort.sortBy}
        filterBy={filterBy ? filterBy : EventStatusDictionary.OPEN}
        setPageNumber={setPageNumber}
        setFilterBy={setFilterBy}
        handleSortEvents={handleSortEvents}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={eventsV2Count}
        setSearchBy={setSearchBy}
        user={data?.viewer?.user}
      />
    );
  }
  return null;
}

export default Events;
