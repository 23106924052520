import React from 'react';

export const SvgCorner = (props: any) => (
  <svg width="40" height="41" viewBox="0 0 40 41" {...props}>
    <defs>
      <path
        id="corner__a"
        d="M11.339 11.696v1.143h-5v-1.143h5zm3-3.429V9.41h-11V8.267h11zm3-3.428v1.143h-17V4.839h17z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(22 22.155)">
      <mask id="corner__b" fill="#fff">
        <use href="#corner__a" />
      </mask>
      <use
        fill="#979797"
        fill-rule="nonzero"
        transform="rotate(-45 8.839 8.839)"
        href="#corner__a"
      />
      <g fill="#A9A9A9" mask="url(#corner__b)"></g>
    </g>
  </svg>
);
