import React from 'react';
import { Formik, FormikTouched } from 'formik';

type FormContainerProps = {
  enableReinitialize?: boolean;
  formToUse: any;
  initialValues: any;
  validationSchema: any;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  onSubmit: (values: any, { setErrors }: any) => Promise<void>;
  initialTouched?: FormikTouched<any>;
};

function FormContainer({
  enableReinitialize,
  initialValues,
  onSubmit,
  formToUse,
  validationSchema,
  validateOnBlur,
  validateOnChange,
  initialTouched,
}: FormContainerProps) {
  return (
    <Formik
      enableReinitialize={!!enableReinitialize}
      validateOnChange={!!validateOnChange}
      validateOnBlur={!!validateOnBlur}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      initialTouched={initialTouched}
    >
      {formToUse}
    </Formik>
  );
}

export default FormContainer;
