import gql from 'graphql-tag';
import { CEDash_CauseOfChoiceSelectCause } from '../fragments/SelectedCauseFragment';

const GET_CONTACTS_V2_QUERY = gql`
  query CEDash_ContactsV2Query(
    $limit: Float
    $offset: Float
    $owner: String
    $firstName: String
    $lastName: String
    $fullName: String
    $orderBy: String
    $sortBy: String
    $filterBy: String
  ) {
    viewer {
      id
      v2 {
        contactsV2 {
          id
          contacts(
            limit: $limit
            offset: $offset
            owner: $owner
            firstName: $firstName
            lastName: $lastName
            fullName: $fullName
            orderBy: $orderBy
            sortBy: $sortBy
            filterBy: $filterBy
          ) {
            id
            data {
              ownerAccountId
              contactId
              accountId
              firstName
              lastName
              emailAddress
              phoneNumber
              organizationName
              lastOrder
              pointBalance
              createDate
              shippingAddressIdDefault
              dateOfBirth
              pointsExpireAt
              shippingAddress {
                id
                addressLineOne
                addressLineTwo
                city
                state
                country
                addressId
                zip
              }
              lifetimeSpend
              nextShipmentDate
              isCe
              isHost
              isSubscriber
              canShopFor
              shoppingWithCeoAccountId
              totalCauseDonationAmount
              selectedCause {
                ...CEDash_CauseOfChoiceSelectCause
              }
            }
            count
          }
        }
      }
    }
  }
  ${CEDash_CauseOfChoiceSelectCause}
`;

export default GET_CONTACTS_V2_QUERY;
