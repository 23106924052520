import React from 'react';
import {
  ButtonBase,
  GridV2 as Grid,
  Icons,
  makeStyles,
  Text,
  TEXT_DS,
  ThemeType,
  useMediaQuery,
  useTheme,
} from '@onehope/design-system-v2';

import { impactMenuConfig } from '../ImpactMenu';

const useStyles = makeStyles((theme: ThemeType) => ({
  header: {
    color: theme.palette.greyDark.main,
  },
  tab: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  selectedTab: {
    textDecoration: 'underline',
    color: theme.palette.forestGreen.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  container: {
    marginBottom: 16,
  },
  addText: {
    paddingLeft: 14,
  },
  naText: {
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
}));

interface ImpactStatsProps {
  menuKey: number;
  cause: string | null;

  handleMenuClick: (key: number) => void;
  handleOpenModal: () => void;
}
type ImpactMenuItem = { label: string; key: number };

export default function ImpactStats({
  cause,
  menuKey,
  handleMenuClick,
  handleOpenModal,
}: ImpactStatsProps) {
  const classes = useStyles({ menuKey });

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      className={classes.container}
    >
      <Grid item xs={12} md={6}>
        <Text
          variant="custom"
          default={TEXT_DS.OVERLINE_SEMIBOLD_14}
          className={classes.header}
        >
          Cause of Choice:{' '}
          {!cause && <span className={classes.naText}>N/A</span>}
        </Text>
        {!cause && (
          <ButtonBase onClick={handleOpenModal}>
            <Grid container alignItems="center">
              <Icons.AddCircleIcon />
              <span>
                <Text
                  variant="custom"
                  default={TEXT_DS.BODY_SEMIBOLD_16}
                  className={classes.addText}
                >
                  Select a cause of choice
                </Text>
              </span>
            </Grid>
          </ButtonBase>
        )}
        {cause && (
          <Text variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
            {cause}
          </Text>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          columnSpacing={2}
          justifyContent={isTablet ? 'flex-end' : 'flex-start'}
        >
          {impactMenuConfig.map((item: ImpactMenuItem) => (
            <Grid item key={item.key}>
              <ButtonBase onClick={() => handleMenuClick(item.key)}>
                <Text
                  variant="custom"
                  default={
                    menuKey === item.key
                      ? TEXT_DS.BODY_SEMIBOLD_14
                      : TEXT_DS.BODY_REGULAR_14
                  }
                  className={
                    menuKey === item.key ? classes.selectedTab : classes.tab
                  }
                >
                  {item.label}{' '}
                </Text>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
