/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import IconMenu from './IconMenu';
import { MuiBadge } from '@onehope/design-system-ohw';
import {
  Icons,
  Grid,
  Text,
  ButtonV2 as Button,
  theme,
} from '@onehope/design-system-v2';
const { AlertCircleOutline, Monitor, Globe, InPerson } = Icons;
import { getEventNonProfit } from '../../../utils/utils';
import { EventTypeEnum } from '../../EventsV2/eventTypes';
import { EventManagementTabs } from '../../Event/TabNavigation/tabConstants';

const EventDescription = styled(Text)`
  font-size: 14px;
  line-height: 21px;
`;

const NonProfitNotSelected = styled(Text)`
  font-size: 14px;
  line-height: 21px;
  color: ${theme.palette.greyDark.main};
`;

const AlertIcon = styled(AlertCircleOutline)`
  margin-left: 8px;
  margin-top: 8px;
`;

const IconWrapper = styled.span`
  padding-right: 8px;
`;

const formatStatusDisplayValue = (value: string) =>
  `${value
    .toLocaleString()
    .charAt(0)
    .toUpperCase()}${value.substring(1).toLowerCase()}`;

export const formatDonationStatus = ({
  value,
  eventId,
  eventClosed,
  donationInfo,
  satelliteEvent,
}: {
  value: string;
  eventId?: string | null | undefined;
  eventClosed?: boolean;
  donationInfo?: any;
  satelliteEvent?: boolean | undefined;
}) => {
  if (satelliteEvent) {
    if (eventClosed) {
      return value && value.toLowerCase() !== 'pending' ? 'Open' : 'Closed';
    }
    return 'Open';
  }
  if (eventClosed) {
    return value && value.toLowerCase() !== 'pending'
      ? formatStatusDisplayValue(value)
      : 'Closed';
  }
  if (!eventId) {
    return null;
  }
  return value && getEventNonProfit(donationInfo) ? (
    formatStatusDisplayValue(value)
  ) : (
    <Link to={`/event?eventId=${eventId}&tab=${EventManagementTabs.CHARITY}`}>
      <MuiBadge variant="dot">Select a charity</MuiBadge>
    </Link>
  );
};

function getEventDescription(eventType: string, trinityPartyType: string) {
  switch (eventType) {
    case EventTypeEnum.WineTasting: {
      return trinityPartyType === 'EPARTY' ? (
        <Grid container alignItems="center">
          <IconWrapper>
            <Monitor />
          </IconWrapper>{' '}
          <EventDescription>Virtual Wine Tasting</EventDescription>
        </Grid>
      ) : (
        <Grid container alignItems="center">
          <IconWrapper>
            <InPerson />{' '}
          </IconWrapper>
          <EventDescription> In-person Wine Tasting</EventDescription>
        </Grid>
      );
    }
    case EventTypeEnum.Fundraiser: {
      return (
        <Grid container alignItems="center">
          <IconWrapper>
            <Globe />{' '}
          </IconWrapper>
          <EventDescription>Online Fundraiser Page</EventDescription>
        </Grid>
      );
    }
    default:
      return null;
  }
}

interface RenderProps {
  eventId: string | null | undefined;
  setSelectedEventId: React.Dispatch<React.SetStateAction<string>>;
  handleOrderAdd: (eventId: string | null | undefined) => void;
  toggleEventCopyUrlDialog?: (
    eventId: string | null | undefined,
    exigoPartyId: string | null | undefined,
  ) => void;
  isAcceptingOrders?: boolean | null | undefined;
  exigoPartyId?: string | null | undefined;
}

interface Column {
  id:
    | 'menu'
    | 'id'
    | 'eventType'
    | 'hostFullName'
    | 'title'
    | 'eventDate'
    | 'closeDate'
    | 'orderAdd'
    | 'exigoPartyId'
    | 'donationInfo.ordersSubTotal'
    | 'donationInfo.status'
    | 'donationInfo.donationAmount'
    | 'donationInfo.nonProfitV2.nonProfitName';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right';
  format?: (
    value: string,
    eventId?: string | null | undefined,
    eventClosed?: boolean,
    donationInfo?: any,
    title?: string | null | undefined,
    hostFullName?: string | null | undefined,
    timeZone?: string | undefined,
    satelliteEvent?: boolean,
    eventType?: string | null,
    exigoPartyId?: number | null,
    trinityPartyType?: string | null,
    showNotification?: boolean,
  ) => void;
  render?: (renderProps: RenderProps) => any;
}

const columns: Column[] = [
  {
    id: 'title',
    label: 'Event Title',
    minWidth: 200,
    align: 'left',
    format: (
      value,
      eventId?: string | null | undefined,
      eventClosed?: boolean,
      donationInfo?: any,
      title?: string | null | undefined,
      hostFullName?: string | null | undefined,
      timeZone?: string | undefined,
      satelliteEvent?: boolean,
      eventType?: string | null,
      exigoPartyId?: number | null,
      trinityPartyType?: string | null,
      showNotification?: boolean,
    ) => {
      const showCharityNotification =
        !satelliteEvent && !getEventNonProfit(donationInfo);
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs>
            {title
              ? title
              : value && eventId
              ? value.toLocaleString()
              : hostFullName}
          </Grid>
          {(showCharityNotification || showNotification) && (
            <Grid item>
              <AlertIcon />
            </Grid>
          )}
        </Grid>
      );
    },
    // ? <Link to={`/event?eventId=${eventId}`}>
    //     {value.toLocaleString()}
    //   </Link>
    // : '',
  },
  {
    id: 'hostFullName',
    label: 'Host',
    minWidth: 140,
    align: 'left',
    format: (
      value,
      eventId?: string | null | undefined,
      eventClosed?: boolean,
      donationInfo?: any,
      title?: string | null | undefined,
      hostFullName?: string | null | undefined,
      eventType?: string | null,
    ) => hostFullName,
  },
  {
    id: 'eventType',
    label: 'Event Type',
    minWidth: 160,
    align: 'left',
    format: (
      value,
      eventId?: string | null | undefined,
      eventClosed?: boolean,
      donationInfo?: any,
      title?: string | null | undefined,
      hostFullName?: string | null | undefined,
      timeZone?: string | undefined,
      satelliteEvent?: boolean,
      eventType?: string | null,
      exigoPartyId?: number | null,
      trinityPartyType?: string | null,
    ) => {
      return getEventDescription(eventType, trinityPartyType);
    },
  },
  {
    id: 'exigoPartyId',
    label: 'ID',
    minWidth: 100,
    align: 'left',
    format: (value) => value,
  },
  {
    id: 'eventDate',
    label: 'Event Date',
    minWidth: 130,
    align: 'left',
    format: (
      value,
      eventId?: string | null | undefined,
      eventClosed?: boolean,
      donationInfo?: any,
      title?: string | null | undefined,
      hostFullName?: string | null | undefined,
      timeZone?: string | undefined,
      satelliteEvent?: boolean,
      eventType?: string | null,
    ) => {
      const isFundraiser = eventType === 'FUNDRAISER';
      return isFundraiser
        ? 'ACTIVE'
        : value
        ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
        : '\u2014';
    },
  },
  {
    id: 'closeDate',
    label: 'End Date',
    minWidth: 130,
    align: 'left',
    format: (value: string | null | undefined) =>
      value
        ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
        : '\u2014',
  },
  {
    id: 'donationInfo.ordersSubTotal',
    label: '$ Sales',
    minWidth: 120,
    align: 'left',
    format: (value) => (value ? `$${value.toLocaleString()}` : '$0'),
  },
  {
    id: 'donationInfo.nonProfitV2.nonProfitName',
    label: 'Nonprofit',
    minWidth: 180,
    align: 'left',
    format: (value) =>
      value ? (
        `${value.toLocaleString()}`
      ) : (
        <NonProfitNotSelected>Not Selected</NonProfitNotSelected>
      ),
  },
  // {
  //   id: 'donationInfo.status',
  //   label: 'Donation Status',
  //   minWidth: 170,
  //   align: 'left',
  //   format: (
  //     value,
  //     eventId?: string | null | undefined,
  //     eventClosed?: boolean,
  //     donationInfo?: any,
  //     title?: string | null | undefined,
  //     hostFullName?: string | null | undefined,
  //     timeZone: string | undefined,
  //     satelliteEvent: boolean | undefined,
  //   ) =>
  //     formatDonationStatus({
  //       value,
  //       eventId,
  //       eventClosed,
  //       donationInfo,
  //       satelliteEvent,
  //     }),
  // },
  // {
  //   id: 'donationInfo.donationAmount',
  //   label: 'Donation Amount',
  //   minWidth: 170,
  //   align: 'left',
  //   format: (value) => (value ? `$${value.toLocaleString()}` : '$0'),
  // },
  // {
  //   id: 'donationInfo.event.hostKitStatus',
  //   label: 'Host Kit Status',
  //   minWidth: 170,
  //   align: 'left',
  //   format: value => (value ? value.toLocaleString() : 'Not Yet Ordered'),
  // },
  {
    id: 'orderAdd',
    minWidth: 0,
    align: 'right',
    render: ({ eventId, handleOrderAdd, isAcceptingOrders }: RenderProps) => (
      <Button
        disabled={!isAcceptingOrders}
        type="primary"
        size="small"
        onClick={() => handleOrderAdd(eventId)}
      >
        Order
      </Button>
    ),
  },
  {
    id: 'menu',
    minWidth: 0,
    align: 'right',
    render: ({
      eventId,
      setSelectedEventId,
      handleOrderAdd,
      toggleEventCopyUrlDialog,
      isAcceptingOrders,
      exigoPartyId,
    }: RenderProps) => (
      <IconMenu
        isCardView={false}
        eventId={eventId}
        exigoPartyId={exigoPartyId}
        isAcceptingOrders={isAcceptingOrders}
        toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
        setSelectedEventId={setSelectedEventId}
        handleOrderAdd={handleOrderAdd}
      />
    ),
  },
];

export default columns;
