export const config = [
  {
    label: 'List View',
    value: 0,
    cypressData: 'events-list-view-tab-button',
    disabled: false,
  },
  // {
  //   label: 'Calendar',
  //   value: 1,
  //   disabled: true,
  //   cypressData: 'events-calendar-view-tab-button',
  // },
];
