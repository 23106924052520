//ActionEngineSaveCard

import gql from 'graphql-tag';

const mutation = gql`
  mutation CardSaveMutation($input: ActionEngineSaveCardInput!) {
    actionEngineSaveCard(input: $input) {
      status
    }
  }
`;

export default mutation;
