import React from 'react';
import { get } from 'lodash';

import { EmailIcon, SMSIcon } from '../StyledComponents/ReachOut';

export enum ShareMediumEnum {
  EMAIL = 'EMAIL',
  FACEBOOK_FEED_POST = 'FACEBOOK_FEED_POST',
  FACEBOOK_STORY = 'FACEBOOK_STORY',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  LINKED_IN_POST = 'LINKED_IN_POST',
  TEXT_MESSAGE = 'TEXT_MESSAGE',
}

export type ShareMediumType = {
  name: string;
  urlMethod?: string;
  label?: string;
  icon?: () => React.ReactNode;
};

export const ShareMedium: {
  [key in ShareMediumEnum]: ShareMediumType;
} = {
  EMAIL: {
    name: 'EMAIL',
    urlMethod: 'email',
    label: 'Email',
    icon: () => {
      return <EmailIcon />;
    },
  },
  FACEBOOK_FEED_POST: { name: 'FACEBOOK_FEED_POST' },
  FACEBOOK_STORY: { name: 'FACEBOOK_STORY' },
  INSTAGRAM_STORY: { name: 'INSTAGRAM_STORY' },
  LINKED_IN_POST: { name: 'LINKED_IN_POST' },
  TEXT_MESSAGE: {
    name: 'TEXT_MESSAGE',
    urlMethod: 'sms',
    label: 'Messages',
    icon: () => {
      return <SMSIcon />;
    },
  },
};

export const getMediumUrlMethod = (name: string) => {
  const medium = get(ShareMedium, [name]);
  return medium?.urlMethod || '';
};

export const getMediumLabel = (name: string) => {
  const medium = get(ShareMedium, [name]);
  return medium?.label || '';
};

export const getMediumIcon = (name: string) => {
  const medium = get(ShareMedium, [name]);
  return medium?.icon() || null;
};
